import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import cogoToast from "cogo-toast";
import {
  Alert,
  Card,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { View500 } from "src/sections/error";
import PrismaZoom from "react-prismazoom";
import { Box, Stack } from "@mui/system";
import Iconify from "src/components/iconify";
import { LoadingButton } from "@mui/lab";
import VisitPageReadData from "./VisitPageReadData";
import _ from "lodash";
import AILoader from "../utils/AILoader";
import Grid from "@mui/system/Unstable_Grid/Grid";
import VisitPageParseData from "./VisitPageParseData";

class VisitPagesOfFile extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    pagesOfFile: [],
    content: [],
    readingFilePages: false,
    text: "",
    isEditingContent: false,
    contentGroups: [],
    readingFilePageContents: false,
  };
  componentDidMount() {
    this.getFileByID();
  }
  getFileByID = async () => {
    try {
      let res = await this.props.apiCallPost("/files/getById", {
        cfId: this.props.fileId,
      });
      this.setState({ ...res }, () => {
        this.getPagesByID();
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading File");
      this.setState({ isLoading: false, isError: true });
    }
  };
  getPagesByID = async () => {
    try {
      let res = await this.props.apiCallPost("/files/getPagesOfFile", {
        cfId: this.props.fileId,
      });
      this.setState({ isLoading: false, pagesOfFile: res });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Pages");
      this.setState({ isLoading: false, isError: true });
    }
  };
  classifyDocumentPages = async () => {
    try {
      this.setState({
        readingFilePages: true,
      });
      await this.props.apiCallPostStreamingForAI(
        "/external/pdf/classifyDocumentPages",
        {
          fileId: this.props.fileId,
          stream: true,
        },
        (data) => {
          this.setState({
            text: this.state.text + data,
          });
        },
        () => {
          this.setState(
            {
              readingFilePages: false,
            },
            () => {
              this.massagePageTypeOutput();
            }
          );
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  extractDocumentContent = async () => {
    try {
      this.setState({
        readingFilePageContents: true,
      });
      await this.props.apiCallPostStreamingForAI(
        "/external/pdf/extractDocumentContent",
        {
          fileId: this.props.fileId,
          pageIndices: [],
          pageType: "",
          stream: true,
        },
        (data) => {
          this.setState({
            text: this.state.text + data,
          });
        },
        () => {
          this.setState({
            readingFilePages: false,
          });
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  massagePageTypeOutput = () => {
    let text = this.state.text;
    let textSplit = text.split("\n");
    let content = [];
    textSplit.forEach((x, ix) => {
      if (x.length > 0) {
        let xSplit = x.split("#");
        content.push({
          pageIx: parseInt(xSplit[0]) - 1,
          pageType: xSplit[1],
          provenance: "AI_GENERATED",
        });
      }
    });
    this.setState(
      {
        text: "",
      },
      () => {
        this.editContent(content);
      }
    );
  };
  editContent = async (content) => {
    try {
      this.setState({
        isEditingContent: true,
      });
      let res = await this.props.apiCallPost("/files/editContent", {
        fileId: this.props.fileId,
        content: content,
      });
      this.setState({ ...res, isEditingContent: false }, () => {
        this.massageContentGroups();
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isEditingContent: false,
      });
      cogoToast.error("Error Editing Content");
    }
  };
  renderEachPageImage = (page, idx) => {
    return (
      <div>
        {this.renderPageHeader(idx)}
        <PrismaZoom>
          <img src={page} />
        </PrismaZoom>
      </div>
    );
  };
  onPageTypeChange = (pageType, pageIx) => {
    let content = _.cloneDeep(this.state.content);
    let idx = _.findIndex(content, (x) => x.pageIx === pageIx);
    if (idx > -1) {
      content[idx].pageType = pageType;
      content[idx].provenance = "USER_GENERATED";
    } else {
      content.push({
        pageIx: pageIx,
        pageType: pageType,
        provenance: "USER_GENERATED",
      });
    }
    this.editContent(content);
  };
  renderHeader = () => {
    return (
      <Stack direction="row" spacing={2} alignItems={"center"}>
        <Typography variant="overline">File Details</Typography>
        <LoadingButton
          loading={this.state.readingFilePages}
          size="small"
          startIcon={<Iconify icon="solar:document-add-linear" width={24} />}
          onClick={() => {
            this.classifyDocumentPages();
          }}
        >
          Classify Documents
        </LoadingButton>
        {this.renderIfAIRunning()}
      </Stack>
    );
  };
  renderIfAIRunning = () => {
    if (this.state.readingFilePages) {
      return <AILoader text="CLASSIFYING" />;
    }
  };
  renderText = () => {
    return <div>{this.state.text}</div>;
  };
  renderPageType = (pageIndex) => {
    let content = this.state.content;
    let idx = _.findIndex(content, (x) => x.pageIx === pageIndex);
    let value = null;
    if (idx > -1) {
      value = content[idx].pageType;
    }
    let pageOptions = [
      "INFO_ONLY",
      "X_RAY",
      "LAB_REPORT",
      "SPIROMETRY",
      "SPIROMETRY_AUX",
      "AUDIOMETRY",
      "AUDIOMETRY_AUX",
      "QUESTIONNAIRE",
      "CCF",
      "PHYSICAL_EXAM",
      "VACCINATION",
      "DOT_5875",
      "DOT_5876",
      "OTHER",
    ];

    return (
      <Select
        size="small"
        disabled={this.state.readingFilePages || this.state.isEditingContent}
        value={value}
        onChange={(e) => {
          this.onPageTypeChange(e.target.value, pageIndex);
        }}
      >
        {pageOptions.map((each) => {
          return <MenuItem value={each}>{each}</MenuItem>;
        })}
      </Select>
    );
  };
  massageContentGroups = () => {
    let content = this.state.content;
    if (!content || content.length === 0) return;
    let contentSorted = _.sortBy(content, (x) => x.pageIx);
    let contentGroups = [];
    let localContentGroup = {};
    for (var i = 0; i < contentSorted.length; i++) {
      let currentDoc = contentSorted[i];
      if (i === 0) {
        localContentGroup = {
          pageType: currentDoc.pageType,
          pages: [i],
        };
        continue;
      }
      let prevDoc = contentSorted[i - 1];

      if (currentDoc.pageType === prevDoc.pageType) {
        localContentGroup.pages.push(i);
      } else {
        contentGroups.push(localContentGroup);
        localContentGroup = {
          pageType: currentDoc.pageType,
          pages: [i],
        };
      }
    }
    contentGroups.push(localContentGroup);
    this.editContentGroups(contentGroups);
  };
  splitIntoNewGroup = (pageIndex) => {
    let contentGroups = this.state.contentGroups;
    let idx = _.findIndex(contentGroups, (x) => x.pages.includes(pageIndex));
    if (idx === -1) return;
    let group = contentGroups[idx];
    let indexOfPageInGroup = group.pages.indexOf(pageIndex);
    let firstNewGroup = {
      pageType: group.pageType,
      pages: group.pages.slice(0, indexOfPageInGroup),
    };

    let secondNewGroup = {
      pageType: group.pageType,
      pages: group.pages.slice(indexOfPageInGroup),
    };

    contentGroups.splice(idx, 1, firstNewGroup, secondNewGroup);

    // clean up to have groups with more than 1 page

    let contentGroupsCleaned = [];
    contentGroups.forEach((each) => {
      if (each.pages.length >= 1) {
        contentGroupsCleaned.push(each);
      }
    });

    this.setState({ contentGroups: contentGroupsCleaned });
  };
  renderPageHeader = (pageIndex) => {
    let content = this.state.content;
    let pgIdx = _.findIndex(content, (x) => x.pageIx === pageIndex);
    let pageType = null;
    if (pgIdx > -1) {
      pageType = content[pageIndex].pageType;
    }
    let processAIElem = null;
    if (!pageType) {
      processAIElem = null;
    } else {
      // check if this pageIndex is in one of the content groups, if so which one
      let contentGroups = this.state.contentGroups;
      let idx = _.findIndex(contentGroups, (x) => {
        return x.pages.includes(pageIndex);
      });
      if (idx > -1) {
        processAIElem = (
          <LoadingButton
            disabled={this.state.readingFilePages}
            loading={this.state.isEditingContent}
            size="small"
            onClick={() => {
              this.splitIntoNewGroup(pageIndex);
            }}
            startIcon={<Iconify icon="vaadin:split" width={24} />}
          >
            Split
          </LoadingButton>
        );
      }
    }
    return (
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          mt: 1,
        }}
      >
        <Typography variant="overline">Page {pageIndex + 1} | Type:</Typography>
        {this.renderPageType(pageIndex)}
        {processAIElem}
      </Stack>
    );
  };
  editContentGroups = async (contentGroups) => {
    try {
      this.setState({
        isEditingContent: true,
      });
      let res = await this.props.apiCallPost("/files/editContentGroups", {
        fileId: this.props.fileId,
        contentGroups: contentGroups,
      });
      this.setState({
        ...res,
        isEditingContent: false,
      });
    } catch (err) {
      this.setState({
        isEditingContent: false,
      });
      cogoToast.error("Error Editing Content Groups");
      console.log(err);
    }
  };
  editContentReadOut = (readoutContent, contentGroup, provenance) => {
    let contentGroups = this.state.contentGroups;
    let idx = _.findIndex(contentGroups, (x) => x._id === contentGroup._id);
    if (idx === -1) return;
    contentGroups[idx].readoutContent = readoutContent;
    contentGroups[idx].provenance = provenance;
    this.editContentGroups(contentGroups);
  };
  renderByContentGroups = () => {
    let contentGroups = this.state.contentGroups;
    if (!contentGroups || contentGroups.length === 0) {
      return null;
    }
    return contentGroups.map((eachContentGroup, contentGroupIdx) => {
      return (
        <Card
          key={contentGroupIdx}
          sx={{
            p: 2,
            rounded: 2,
            boxShadow: 3,
            bgcolor: "background.neutral",
            mb: 2,
          }}
        >
          <Typography variant="overline">
            {eachContentGroup.pageType}
          </Typography>
          <Grid container spacing={2}>
            <Grid xs={12} md={4}>
              {eachContentGroup.pages.map((eachPage) => {
                return (
                  <Stack spacing={1} alignItems={"flex-start"}>
                    {this.renderEachPageImage(
                      this.state.pagesOfFile[eachPage],
                      eachPage
                    )}
                  </Stack>
                );
              })}
            </Grid>
            <Grid xs={12} md={4}>
              <VisitPageReadData
                contentGroup={eachContentGroup}
                isEditingContent={
                  this.state.isEditingContent || this.state.readingFilePages
                }
                fileId={this.props.fileId}
                content={this.state.content}
                editContentReadOut={(readoutContent, provenance) => {
                  this.editContentReadOut(
                    readoutContent,
                    eachContentGroup,
                    provenance
                  );
                }}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <VisitPageParseData
                contentGroup={eachContentGroup}
                isEditingContent={
                  this.state.isEditingContent || this.state.readingFilePages
                }
                fileId={this.props.fileId}
                content={this.state.content}
                editContentReadOut={(readoutContent, provenance) => {
                  this.editContentReadOut(
                    readoutContent,
                    eachContentGroup,
                    provenance
                  );
                }}
              />
            </Grid>
          </Grid>
        </Card>
      );
    });
  };
  renderUngroupedPages = () => {
    let pagesOfFile = this.state.pagesOfFile;
    let contentGroups = this.state.contentGroups;
    let ungroupedPages = [];
    for (var i = 0; i < pagesOfFile.length; i++) {
      let idx = _.findIndex(contentGroups, (x) => x.pages.includes(i));
      if (idx === -1) {
        ungroupedPages.push(i);
      }
    }
    if (ungroupedPages.length === 0) return null;
    return (
      <Card
        sx={{
          p: 2,
          rounded: 2,
          boxShadow: 3,
          bgcolor: "background.neutral",
          mb: 2,
        }}
      >
        <Typography variant="overline">Unsorted Pages</Typography>
        {ungroupedPages.map((eachPage) => {
          return (
            <Stack direction="row" spacing={2} alignItems={"flex-start"}>
              {this.renderEachPageImage(pagesOfFile[eachPage], eachPage)}
            </Stack>
          );
        })}
      </Card>
    );
  };
  render() {
    if (this.state.isLoading) return <LinearProgress />;
    if (this.state.isError) return <View500 />;

    return (
      <div>
        {this.renderHeader()}
        {this.renderByContentGroups()}
        {this.renderUngroupedPages()}
      </div>
    );
  }
}

export default WithAPICall(VisitPagesOfFile);
