import {
  Card,
  FormControlLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select,
  FormLabel,
  TextField,
  Input,
  IconButton,
  Button,
  Divider,
  Typography,
  Switch,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Stack } from "@mui/system";
import cogoToast from "cogo-toast";
import React from "react";

import {
  formFoxServices,
  formFoxSampleTypes,
  formFoxLabNames,
  PHYSICAL_EXAM_CERTIFICATION_TYPES,
} from "../../assets/data/formfox";
import { WithAPICall } from "../utils/apiUtil";
import { cloneDeep, isEqual, uniqWith } from "lodash";
import Iconify from "src/components/iconify";
import { ALL_LAB_NAMES } from "src/assets/data/lab-names";

class ServiceFormFoxProfile extends React.Component {
  state = { isLoading: true };
  componentDidMount() {
    this.setState({
      ...this.props.data,
      isLoading: false,
      addNewLabName: "",
      addNewPanelCode: "",
    });
  }

  updateScalarValue = async (key, value) => {
    if (value === null || value === undefined) {
      return;
    }
    if (this.props.data[key] === value) {
      return;
    }
    try {
      await this.props.apiCallPost(
        "/master-service/masterServiceUpdateScalarValue",
        {
          masterServiceId: this.props.id,
          key: key,
          value: value,
        }
      );
    } catch (err) {
      cogoToast.error("Error Updating Service Data");
      console.log(err);
    }
  };
  addLabPanelCode = () => {
    if (this.state.addNewLabName === "") {
      cogoToast.error("Add lab name to proceed.");
      return;
    }
    if (this.state.addNewPanelCode.length === 0) {
      cogoToast.error(`Add panel code for lab: ${this.state.addNewLabName}`);
      return;
    }
    this.setState(
      (prevState) => {
        const newState = cloneDeep(prevState.panelCodeForLab || []);
        newState.push({
          labName: this.state.addNewLabName,
          panelCode: this.state.addNewPanelCode,
        });
        return {
          ...prevState,
          panelCodeForLab: newState,
          addNewLabName: "",
          addNewPanelCode: "",
        };
      },
      () =>
        this.updateScalarValue("panelCodeForLab", this.state.panelCodeForLab)
    );
  };
  removeLabPanelCode = (index) => {
    this.setState(
      (prevState) => {
        const newState = cloneDeep(prevState.panelCodeForLab || []).filter(
          (p, i) => i !== index
        );
        return {
          ...prevState,
          panelCodeForLab: newState,
          addNewLabName: "",
          addNewPanelCode: "",
        };
      },
      () =>
        this.updateScalarValue("panelCodeForLab", this.state.panelCodeForLab)
    );
  };
  renderInstructions = () => {
    return (
      <Stack spacing={3}>
        <ListItemText
          sx={{
            p: 3,
            borderRadius: 2,
            bgcolor: "background.neutral",
          }}
          primary="Instructions"
          secondary="Instructions you enter here will be sent to the provider and the employee."
          primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
          secondaryTypographyProps={{ component: "span" }}
        />{" "}
        <Box
          rowGap={2}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
          }}
        >
          <TextField
            label="Provider Instructions"
            value={this.state.providerInstruction}
            multiline
            onChange={(e) => {
              this.setState({
                providerInstruction: e.target.value,
              });
            }}
            onBlur={() => {
              this.updateScalarValue(
                "providerInstruction",
                this.state.providerInstruction
              );
            }}
          />{" "}
          <TextField
            label="Patient Instructions"
            value={this.state.patientInstruction}
            multiline
            onChange={(e) => {
              this.setState({
                patientInstruction: e.target.value,
              });
            }}
            onBlur={() => {
              this.updateScalarValue(
                "patientInstruction",
                this.state.patientInstruction
              );
            }}
          />{" "}
          <TextField
            disabled={this.state.serviceType !== "QUESTIONNAIRE"}
            label="Questionnaire Instructions"
            value={this.state.questionnairePtInsn}
            multiline
            onChange={(e) => {
              this.setState({
                questionnairePtInsn: e.target.value,
              });
            }}
            onBlur={() => {
              this.updateScalarValue(
                "questionnairePtInsn",
                this.state.questionnairePtInsn
              );
            }}
          />
        </Box>
      </Stack>
    );
  };
  renderNewLabPanel = () => {
    return (
      <>
        <Typography variant="subtitle2">Add a new panel code</Typography>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={3}>
            <FormControlLabel
              label="Lab Name"
              labelPlacement="top"
              control={
                <Select
                  placeholder="Select a Lab"
                  value={this.state.addNewLabName}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    this.setState({
                      addNewLabName: e.target.value,
                    });
                  }}
                >
                  {ALL_LAB_NAMES.map((ln) => (
                    <MenuItem key={ln.key} value={ln.key}>
                      {ln.value}
                    </MenuItem>
                  ))}
                </Select>
              }
              sx={{
                m: 0,
                width: 1,
                justifyContent: "start",
                alignItems: "start",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              label="Panel Number/ID/Code"
              labelPlacement="top"
              control={
                <TextField
                  value={this.state.addNewPanelCode}
                  onChange={(e) => {
                    this.setState({
                      addNewPanelCode: e.target.value,
                    });
                  }}
                  sx={{ width: "100%" }}
                />
              }
              sx={{
                m: 0,
                width: 1,
                justifyContent: "start",
                alignItems: "start",
              }}
            />
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              padding: 0,
            }}
          >
            <Button
              component="label"
              role={undefined}
              variant="contained"
              startIcon={
                <Iconify
                  icon="carbon:add-filled"
                  height="1.2em"
                  width="1.2em"
                />
              }
              color="primary"
              onClick={this.addLabPanelCode}
            >
              Add Code
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };
  renderPanelCodeForLab = ({ labName, panelCode, index }) => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            {labName}
          </Grid>
          <Grid item xs={6}>
            {panelCode}
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              padding: 0,
            }}
          >
            <Button
              component="label"
              role={undefined}
              variant="contained"
              startIcon={
                <Iconify icon="mdi:trash" height="1.2em" width="1.2em" />
              }
              color="error"
              onClick={() => {
                this.removeLabPanelCode(index);
              }}
              size="small"
            >
              Remove
            </Button>
          </Grid>
        </Grid>
        <Divider />
      </>
    );
  };
  renderGridHeaders = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography variant="subtitle2">Lab Name</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2">Panel Code</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">Action</Typography>
          </Grid>
        </Grid>
        <Divider />
      </>
    );
  };

  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <div>
        <Grid container spacing={3}>
          <Grid xs={12} md={6}>
            <Card sx={{ pt: 3, pb: 3, px: 3, mb: 3 }}>
              <Stack spacing={2}>
                <FormControlLabel
                  label="Form Fox Name"
                  labelPlacement="top"
                  control={
                    <Select
                      value={this.state.formFoxService?.id}
                      sx={{
                        minWidth: "200px",
                      }}
                      onChange={(e) => {
                        console.log(e.target.value);
                        const formFoxValue = formFoxServices.find(
                          (f) => f.id === e.target.value
                        );
                        this.setState({ formFoxService: formFoxValue }, () => {
                          this.updateScalarValue(
                            "formFoxService",
                            this.state.formFoxService
                          );
                        });
                      }}
                    >
                      {formFoxServices.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  sx={{
                    m: 0,
                    width: 1,
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                />
                <FormControlLabel
                  label="Sample Type"
                  labelPlacement="top"
                  control={
                    <Select
                      value={this.state.formFoxSampleType || ""}
                      sx={{
                        minWidth: "200px",
                      }}
                      onChange={(e) => {
                        this.setState(
                          { formFoxSampleType: e.target.value },
                          () => {
                            this.updateScalarValue(
                              "formFoxSampleType",
                              this.state.formFoxSampleType
                            );
                          }
                        );
                      }}
                    >
                      {formFoxSampleTypes.map((item) => (
                        <MenuItem key={item.id} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  sx={{
                    m: 0,
                    width: 1,
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                />
                <FormControlLabel
                  label="Hard Expire"
                  labelPlacement="start"
                  control={
                    <Switch
                      checked={this.state.formFoxHardExpire}
                      onChange={() => {
                        this.setState(
                          {
                            formFoxHardExpire: !this.state.formFoxHardExpire,
                          },
                          () => {
                            this.updateScalarValue(
                              "formFoxHardExpire",
                              this.state.formFoxHardExpire
                            );
                          }
                        );
                      }}
                    />
                  }
                  sx={{
                    m: 0,
                    width: 1,
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                />
                <FormControlLabel
                  label="Certification Type"
                  labelPlacement="top"
                  control={
                    <Select
                      sx={{
                        minWidth: "200px",
                      }}
                      value={this.state.formFoxCertificationType}
                      onChange={(e) => {
                        this.setState(
                          {
                            formFoxCertificationType: e.target.value,
                          },
                          () => {
                            this.updateScalarValue(
                              "formFoxCertificationType",
                              this.state.formFoxCertificationType
                            );
                          }
                        );
                      }}
                    >
                      {PHYSICAL_EXAM_CERTIFICATION_TYPES.map((d, idx) => (
                        <MenuItem key={d.id} value={d.value}>
                          {d.name}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  sx={{
                    m: 0,
                    width: 1,
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                />
                {/* <Typography sx={{ mb: 1 }} variant="subtitle1">
                  Panel Code For Labs
                </Typography>
                {(this.state.panelCodeForLab || []).length > 0 && (
                  <>
                    {this.renderGridHeaders()}
                    {(this.state.panelCodeForLab || []).map((d, index) =>
                      this.renderPanelCodeForLab({
                        labName: d.labName,
                        panelCode: d.panelCode,
                        index,
                      })
                    )}
                  </>
                )}
                <Stack>{this.renderNewLabPanel()}</Stack> */}
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default WithAPICall(ServiceFormFoxProfile);
