import React from "react";
import { WithAPICall } from "./apiUtil";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Iconify from "src/components/iconify";
import GMapRenderer from "./GMapRenderer";
import { Stack } from "@mui/system";
import cogoToast from "cogo-toast";

class GMap extends React.Component {
  state = {
    searchResults: [],
    isSearching: false,
    searchInput: "",
    selectedPlace: null,
    placeDetail: null,
    renderSearcher: true,
  };
  componentDidMount() {
    if (this.props.geoObject) {
      this.setState({
        geoObject: this.props.geoObject,
      });
    }
  }
  searchGoogleMaps = async () => {
    if (!this.state.searchInput) return;
    if (this.state.searchInput.length < 3) return;
    if (this.state.searchInput === "undefined") return;
    try {
      let res = await this.props.apiCallPostCancellable(
        "/external/maps/autocomplete",
        {
          text: this.state.searchInput,
        }
      );
      if (res) {
        this.setState({
          isSearching: false,
          searchResults: res.map((each) => {
            return {
              mainText: each.structured_formatting.main_text,
              secondaryText: each.structured_formatting.secondary_text,
              placeId: each.place_id,
            };
          }),
        });
      }
    } catch (err) {}
  };
  getPlaceDetail = async () => {
    try {
      let res = await this.props.apiCallPost("/external/maps/getPlaceDetail", {
        place_id: this.state.selectedPlace.placeId,
      });
      if (!res) {
        cogoToast.error(
          "The selected location did not return a Postal Code. Please try again with a more specific location."
        );
        return;
      }
      this.setState({
        placeDetail: res.result,
        geoObject: res.result.geolocation,
      });
      this.props.onPlace(res.result);
    } catch (err) {}
  };
  renderSearcher = () => {
    return (
      <Autocomplete
        sx={{
          minWidth: "400px",
        }}
        disableClearable
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option?.mainText
        }
        filterOptions={(x) => x}
        options={this.state.isSearching ? [] : this.state.searchResults}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={this.state.selectedPlace}
        noOptionsText={
          this.state.isSearching ? "Searching..." : "No results found"
        }
        onChange={(_, value) => {
          this.setState(
            {
              selectedPlace: value,
              searchResults: [value, ...this.state.searchResults],
            },
            () => {
              this.getPlaceDetail();
            }
          );
        }}
        onInputChange={(_, e) => {
          this.setState(
            {
              searchInput: e,
            },
            () => {
              this.searchGoogleMaps();
            }
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={this.props.name || "Search for a location"}
            fullWidth
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: "flex", width: 44 }}>
                  <Iconify icon="mdi:map-marker" />
                </Grid>
                <Grid
                  item
                  sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                >
                  <Box component="span">{option?.mainText}</Box>
                  <Typography variant="body2" color="text.secondary">
                    {option?.secondaryText}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    );
  };
  renderMap = () => {
    if (this.props.renderMapFalse) {
      return null;
    } else {
      return (
        <GMapRenderer
          geoObject={this.state.geoObject}
          onLatLng={this.props.onLatLng}
        />
      );
    }
  };
  render() {
    return (
      <Stack spacing={2}>
        {!this.props.hideSearcher && this.renderSearcher()}
        {this.renderMap()}
      </Stack>
    );
  }
}

export default WithAPICall(GMap);
