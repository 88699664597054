import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import { Box, Stack } from "@mui/system";
import {
  Alert,
  Button,
  Card,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  LinearProgress,
  TextField,
  Popover,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Tooltip,
  AlertTitle,
} from "@mui/material";
import Iconify from "src/components/iconify";
import cogoToast from "cogo-toast";
import Grid from "@mui/system/Unstable_Grid/Grid";
import GMapMultiple from "../utils/GMapMultiple";
import _, { uniq, flatten, groupBy } from "lodash";
import { LoadingButton } from "@mui/lab";
import {
  DateTimePicker,
  LocalizationProvider,
  TimeField,
} from "@mui/x-date-pickers-pro";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import { niceDate } from "../utils/fn";
import TokenContext from "src/TokenContext";
import VisitEmployeeLocation from "./VisitEmployeeLocation";
import TextareaAutosize from "src/components/text-area-autosize";

const EEOutreachAttempt = ({ isOpen, onDone, onCancel }) => {
  const [notes, setNotes] = React.useState("");
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        console.log("close");
      }}
    >
      <DialogTitle>Employee Outreach Attempt</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Alert severity="info">
            Log an attempt to schedule with the employee.
          </Alert>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Notes"
            fullWidth
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value);
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() => {
            onDone(notes);
          }}
        >
          Log Event
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

class VisitChooseClinic extends React.Component {
  static contextType = TokenContext;
  state = {
    isLoading: true,
    hasSearched: false,
    isSearching: false,
    results: [],
    isEEOutreachAttemptOpen: false,
    chosenPSLs: [],
    searchRadius1: "15",
    searchRadius2: "1",
    openEmployeeLocationModal: false,
    employeeSchedulingPreference: "",
    nEEOutreachAttempts: 0,
  };
  componentDidMount() {
    let chosenPSLs = this.props.visit.providerPSLs;
    if (chosenPSLs) {
      this.setState({
        chosenPSLs,
        isLoading: false,
        employeeSchedulingPreference:
          this.props.visit.employeeSchedulingPreference,
        nEEOutreachAttempts: this.props.visit.nEEOutreachAttempts,
      });
    }
  }
  formatWeeklyTimings = (weeklyTimings) => {
    // Initialize an object with all days of the week set to "No Schedule Specified"
    const formattedSchedule = {
      Monday: "No Schedule Specified",
      Tuesday: "No Schedule Specified",
      Wednesday: "No Schedule Specified",
      Thursday: "No Schedule Specified",
      Friday: "No Schedule Specified",
      Saturday: "No Schedule Specified",
      Sunday: "No Schedule Specified",
    };

    // Helper function to format the schedule string for a single entry
    function formatScheduleEntry(entry) {
      let parts = [];
      if (entry.isOpen24Hrs) {
        parts.push("Open 24 Hours");
      } else {
        parts.push(`${entry.startTime} to ${entry.endTime}`);
      }
      let flags = [];
      if (entry.apptOnly) flags.push("Appointment Only");
      if (entry.allowsWalkIn) flags.push("Allows Walk-Ins");
      if (entry.notes) flags.push(entry.notes);

      return (
        parts.join(", ") + (flags.length ? " (" + flags.join(", ") + ")" : "")
      );
    }

    weeklyTimings.forEach((entry) => {
      const day = entry.dayOfWeek;
      // Check for 24 hours open first
      if (entry.isOpen24Hrs) {
        formattedSchedule[day] = `Open 24 Hours${
          entry.apptOnly ? ", Appointment Only" : ""
        }${entry.allowsWalkIn ? ", Allows Walk-Ins" : ""}`;
      } else if (
        formattedSchedule[day] === "No Schedule Specified" ||
        !formattedSchedule[day].includes("Open 24 Hours")
      ) {
        // If the day already has a schedule, append; otherwise, replace "No Schedule Specified"
        const scheduleString = formatScheduleEntry(entry);
        formattedSchedule[day] =
          formattedSchedule[day] === "No Schedule Specified"
            ? scheduleString
            : formattedSchedule[day] + "; " + scheduleString;
      }
    });

    return formattedSchedule;
  };
  allServicesChosen = () => {
    let services = this.props.services;
    let serviceIds = services.map((x) => x._id);
    let allServicesChosen = serviceIds
      .map((x) => this.isServiceInChosenPSLs(x))
      .every((x) => x === true);
    return allServicesChosen;
  };
  renderServices = () => {
    let services = this.props.services;
    const bookedProtocols = this.props.visit.protocolsInfo;
    return (
      <Stack direction="row" spacing={1} justifyContent="space-between">
        <Stack>
          <Stack direction="row" spacing={1}>
            <Typography variant="body2">Selected Protocol: </Typography>
            <Stack direction="row" spacing={0.5}>
              {bookedProtocols.map((s, idx) => {
                return (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={s.companyProtocolName}
                    key={idx}
                    icon={<Iconify icon="fluent:protocol-handler-16-filled" />}
                  />
                );
              })}
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography variant="body2">Selected Services: </Typography>
            <Typography variant="body2">
              {services.map((s) => s.serviceName).join(", ")}
            </Typography>
          </Stack>
        </Stack>
        {!this.context.currentUser.isExternal && (
          <Stack direction="row" justifyContent={"flex-start"} spacing={2}>
            <Stack spacing={1}>
              <Typography variant="overline">
                Employee Scheduling Preference
              </Typography>
              {this.state.employeeSchedulingPreference && (
                <Typography variant="overline" color={"secondary"}>
                  {this.state.employeeSchedulingPreference}
                </Typography>
              )}
              <TextareaAutosize
                label="Update Preference"
                minRows={2}
                value={this.state.employeeSchedulingPreference}
                onChange={(e) => {
                  this.setState({
                    employeeSchedulingPreference: e.target.value,
                  });
                }}
                onBlur={() => {
                  this.props.updateVisitScalars([
                    {
                      key: "employeeSchedulingPreference",
                      value: this.state.employeeSchedulingPreference,
                    },
                  ]);
                }}
              />
            </Stack>
            <Stack spacing={1}>
              <Button
                variant="outlined"
                onClick={() => {
                  // this.props.openLogEvent();
                  this.setState({
                    isEEOutreachAttemptOpen: true,
                  });
                }}
                startIcon={<Iconify icon="fluent-mdl2:contact-list" />}
                size="small"
              >
                Log Employee Outreach
              </Button>
              <Tooltip title="# of Employee Outreach Attempts Made. For details, see the Timeline tab.">
                <Typography variant="caption">
                  Attempts made: {this.state.nEEOutreachAttempts || 0}
                </Typography>
              </Tooltip>
              <Tooltip title="# of Employee Outreach Attempts required per company SLA.">
                <Typography variant="caption">
                  Outreach SLA:{" "}
                  {this.props.visit.company.slaNumOutreachAttempts ||
                    "<Unknown>"}
                </Typography>
              </Tooltip>
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  };
  renderTimePicker = (psls) => {
    // const psl = psls[0];
    // const providerId = psl.providerId;
    // if (!psl.scheduledOnDate) return null;
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="caption">Time</Typography>
          <TimePicker
            disabled={this.props.visit.isSaving}
            size="small"
            value={
              this.props.visit.providerEncounterOnTime
                ? moment(this.props.visit.providerEncounterOnTime)
                : null
            }
            onAccept={() => {
              window.alert(
                "Not implemented yet -- renderTimePicker -- onAccept"
              );
              // this.props.onFreezeClinic(this.state.chosenPSLs);
            }}
            onChange={(newValue) => {
              window.alert(
                "Not implemented yet -- renderTimePicker -- onChange"
              );
              // const chosenPSLs = this.state.chosenPSLs;
              // for (const cpsl of chosenPSLs) {
              //   if (cpsl.providerId === providerId) {
              //     if (psl.scheduledOnDate) {
              //       let date = moment(psl.scheduledOnDate);
              //       let time = newValue;
              //       date.set({
              //         hour: time.get("hour"),
              //         minute: time.get("minute"),
              //         second: time.get("second"),
              //       });
              //       cpsl["scheduledTime"] = date.toDate();
              //     }
              //   }
              // }
              // this.setState({ chosenPSLs });
            }}
          />
        </div>
      </LocalizationProvider>
    );
  };
  renderDatePicker = (psls) => {
    let today = new Date();
    let todayPlus180 = new Date();
    todayPlus180.setDate(todayPlus180.getDate() + 180);
    const providerId = psls[0].providerId;
    const psl = psls[0];
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="caption">Date</Typography>
        <Chip
          disabled={this.props.visit.isSaving}
          variant="contained"
          onClick={(e) => {
            if (this.props.visit.isSaving) return;
            this.setState({
              anchorEl: e.currentTarget,
              open: true,
            });
          }}
          size="large"
          label={
            psl.scheduledOnDate ? niceDate(psl.scheduledOnDate) : "Not Chosen"
          }
          sx={{
            background: "white",
            border: "1px solid #dadada",
            padding: "1.5rem 0.4rem",
          }}
        />
        <Popover
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          onClose={() => {
            this.setState({
              anchorEl: null,
              open: false,
            });
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <DayPicker
            size="small"
            mode="single"
            captionLayout="dropdown-buttons"
            fromDate={
              this.context.currentUser.scopes.includes("visit:create:backfill")
                ? null
                : today
            }
            toDate={todayPlus180}
            onSelect={(e) => {
              console.log("date change", e);
              const chosenPSLs = this.state.chosenPSLs;
              for (const cpsl of chosenPSLs) {
                if (cpsl.providerId === providerId) {
                  cpsl["scheduledOnDate"] = e;
                }
              }
              this.setState(
                {
                  scheduledOnDate: e,
                  anchorEl: null,
                  open: false,
                  chosenPSLs,
                },
                () => {
                  this.props.onFreezeClinic(this.state.chosenPSLs);
                }
              );
            }}
          />
        </Popover>
      </div>
    );
  };

  renderClinicTable = () => {
    const provider = this.props.visit.provider;
    const psls = this.props.visit.providerPSLsInfo;
    if (!provider) return null;
    return (
      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="overline">Selected Clinic</Typography>
        </Stack>

        {this.state.chosenPSLs.length === 0 ? (
          <Alert severity="info" sx={{ mt: 1 }}>
            Find and select clinic to book appointment time.
          </Alert>
        ) : (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Clinic Name</TableCell>
                <TableCell>Chosen Services</TableCell>
                {/* <TableCell>Select Appointment Time</TableCell> */}
                <TableCell>Notes For Clinic</TableCell>
                {this.context.currentUser.isExternal && (
                  <TableCell>Notes for Workcare</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Stack spacing={0.5}>
                    <Stack direction="row" alignItems="center">
                      <Typography variant="subtitle1">
                        {provider.providerName}
                      </Typography>
                      <Tooltip
                        title={
                          <>
                            {Object.entries(
                              this.formatWeeklyTimings(provider.weeklyTimings)
                            ).map(([day, schedule], idx) => (
                              <Stack
                                key={idx}
                                direction="row"
                                spacing={1}
                                alignContent={"center"}
                              >
                                <Typography variant="caption">{day}</Typography>
                                <Typography variant="caption">
                                  {schedule}
                                </Typography>
                              </Stack>
                            ))}
                          </>
                        }
                      >
                        <Iconify icon="material-symbols:info-outline" />
                      </Tooltip>
                    </Stack>

                    <Stack direction="row" alignItems={"center"} spacing={1}>
                      <Chip
                        variant="outlined"
                        size="small"
                        icon={<Iconify icon="mingcute:time-fill" />}
                        label={`${this.props.visit.providerTimeFromEE.toFixed(0)} min`}
                      />
                      <Tooltip
                        title={
                          <Stack>
                            <Typography variant="caption">
                              {provider.providerAddress}
                            </Typography>
                            <Typography variant="caption">
                              {provider.providerState}
                              {", "}
                              {provider.providerCity}
                              {", "}
                              {provider.providerPostalCode}
                            </Typography>
                          </Stack>
                        }
                      >
                        <Chip
                          variant="outlined"
                          size="small"
                          icon={<Iconify icon="material-symbols:distance" />}
                          label={`${this.props.visit.providerDistanceFromEE} mi`}
                        />
                      </Tooltip>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Button
                        size="small"
                        variant="outlined"
                        startIcon={<Iconify icon="ic:baseline-phone" />}
                        href={`tel:${provider.providerPhone}`}
                      >
                        {provider.providerPhone}
                      </Button>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Iconify icon="material-symbols:fax" />
                      <Typography variant="caption">
                        {provider.providerFax || ""}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Iconify icon="ic:baseline-email" />
                      <Typography variant="caption">
                        {provider.providerEmail ||
                          "No email added for provider"}
                      </Typography>
                    </Stack>
                  </Stack>
                </TableCell>

                <TableCell>
                  <Stack direction="column" spacing={0.5}>
                    {psls.map((psl, pIdx) => (
                      <Chip
                        size="small"
                        label={psl.providerServiceAlias}
                        variant="outlined"
                        key={pIdx}
                      />
                    ))}
                  </Stack>
                </TableCell>

                {/* <TableCell>
                  <Stack spacing={1}>
                    {this.context.currentUser.isExternal && (
                      <Alert severity="info" variant="outlined">
                        Please call the clinic before selecting a time.
                      </Alert>
                    )}
                    <Stack
                      direction="row"
                      spacing={0.5}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePicker
                          value={
                            this.props.visit.providerEncounterOnTime
                              ? moment(this.props.visit.providerEncounterOnTime)
                              : null
                          }
                          onAccept={() => {
                            this.props.onFreezeTime({});
                          }}
                          onChange={(newValue) => {
                            this.props.onFreezeTime({});
                          }}
                        />
                      </LocalizationProvider>
                      <Typography variant="overline">
                        {provider?.providerReadableTimezone || "<No TZ>"}
                      </Typography>
                    </Stack>
                  </Stack>
                </TableCell> */}

                <TableCell>
                  <TextareaAutosize
                    minRows={3}
                    defaultValue={this.props.visit.noteToClinic}
                    onChange={(e) => {
                      this.props.onChangeNoteToClinic(e.target.value);
                    }}
                    onBlur={() => {
                      this.props.updateVisitScalars([
                        {
                          key: "noteToClinic",
                          value: this.props.visit.noteToClinic,
                        },
                      ]);
                    }}
                  />
                </TableCell>
                {this.context.currentUser.isExternal && (
                  <TableCell>
                    <TextareaAutosize
                      minRows={3}
                      defaultValue={this.props.visit.noteToWorkcare}
                      onChange={(e) => {
                        this.props.onChangeNoteToWorkcare(e.target.value);
                      }}
                      onBlur={() => {
                        this.props.updateVisitScalars([
                          {
                            key: "noteToWorkcare",
                            value: this.props.visit.noteToWorkcare,
                          },
                        ]);
                      }}
                    />
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
            {/* <TableBody>
              {Array.from(Object.keys(groupedPsls)).map(
                (providerName, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Stack spacing={0.5}>
                        <Stack direction="row" alignItems="center">
                          <Typography variant="subtitle1">
                            {providerName}
                          </Typography>
                          <Tooltip
                            title={
                              <>
                                {" "}
                                {Object.entries(
                                  this.formatWeeklyTimings(
                                    groupedPsls[providerName][0].providerObj
                                      .weeklyTimings
                                  )
                                ).map(([day, schedule], idx) => (
                                  <Stack
                                    key={idx}
                                    direction="row"
                                    spacing={1}
                                    alignContent={"center"}
                                  >
                                    <Typography variant="caption">
                                      {day}
                                    </Typography>
                                    <Typography variant="caption">
                                      {schedule}
                                    </Typography>
                                  </Stack>
                                ))}
                              </>
                            }
                          >
                            <Iconify icon="material-symbols:info-outline" />
                          </Tooltip>
                        </Stack>

                        <Stack
                          direction="row"
                          alignItems={"center"}
                          spacing={1}
                        >
                          <Chip
                            variant="outlined"
                            size="small"
                            icon={<Iconify icon="mingcute:time-fill" />}
                            label={`${groupedPsls[providerName][0].timeFromEE?.text || "NA"}`}
                          />
                          <Tooltip
                            title={
                              <Stack>
                                <Typography variant="caption">
                                  {
                                    groupedPsls[providerName][0].providerObj
                                      .providerAddress
                                  }
                                </Typography>
                                <Typography variant="caption">
                                  {
                                    groupedPsls[providerName][0].providerObj
                                      .providerState
                                  }
                                  {", "}
                                  {
                                    groupedPsls[providerName][0].providerObj
                                      .providerCity
                                  }
                                  {", "}
                                  {
                                    groupedPsls[providerName][0].providerObj
                                      .providerPostalCode
                                  }
                                </Typography>
                              </Stack>
                            }
                          >
                            <Chip
                              variant="outlined"
                              size="small"
                              icon={
                                <Iconify icon="material-symbols:distance" />
                              }
                              label={`${groupedPsls[providerName][0].distanceFromEE?.text || "NA"}`}
                            />
                          </Tooltip>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={0.5}
                        >
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<Iconify icon="ic:baseline-phone" />}
                            href={`tel:${groupedPsls[providerName][0].providerObj.providerPhone}`}
                          >
                            {
                              groupedPsls[providerName][0].providerObj
                                .providerPhone
                            }
                          </Button>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={0.5}
                        >
                          <Iconify icon="material-symbols:fax" />
                          <Typography variant="caption">
                            {groupedPsls[providerName][0].providerObj
                              .providerFax || ""}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={0.5}
                        >
                          <Iconify icon="ic:baseline-email" />
                          <Typography variant="caption">
                            {groupedPsls[providerName][0].providerObj
                              .providerEmail || "No email added for provider"}
                          </Typography>
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack direction="column" spacing={0.5}>
                        {groupedPsls[providerName].map((psl, pIdx) => (
                          <Chip
                            size="small"
                            label={psl.pslName}
                            variant="outlined"
                            key={pIdx}
                          />
                        ))}
                      </Stack>
                    </TableCell>
                    {this.allServicesChosen() ? (
                      <TableCell>
                        <Stack spacing={1}>
                          {this.context.currentUser.isExternal && (
                            <Alert severity="info" variant="outlined">
                              Please call the clinic before selecting a time.
                            </Alert>
                          )}
                          <Stack
                            direction="row"
                            spacing={0.5}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DateTimePicker
                                value={
                                  groupedPsls[providerName][0]?.scheduledTime
                                    ? moment(
                                        groupedPsls[providerName][0]
                                          ?.scheduledTime
                                      )
                                    : null
                                }
                                onAccept={() => {
                                  this.props.onFreezeClinic(
                                    this.state.chosenPSLs
                                  );
                                }}
                                onChange={(newValue) => {
                                  const chosenPSLs = this.state.chosenPSLs;
                                  const psl = groupedPsls[providerName][0];
                                  const providerId = psl.providerId;
                                  for (const cpsl of chosenPSLs) {
                                    if (cpsl.providerId === providerId) {
                                      cpsl["scheduledOnDate"] = newValue;
                                      cpsl["scheduledTime"] = newValue;
                                    }
                                  }
                                  this.setState({ chosenPSLs });
                                }}
                              />
                            </LocalizationProvider>
                            <Typography variant="overline">
                              {
                                groupedPsls[providerName][0].providerObj
                                  .providerReadableTimezone
                              }
                            </Typography>
                          </Stack>
                        </Stack>
                      </TableCell>
                    ) : (
                      <TableCell></TableCell>
                    )}
                    <TableCell>
                      <TextareaAutosize
                        minRows={3}
                        defaultValue={
                          this.props.visit.noteToClinic
                            ? this.props.visit.noteToClinic[
                                groupedPsls[providerName][0].providerId
                              ]
                            : null
                        }
                        onChange={(e) => {
                          this.props.onChangeNoteToClinic(
                            groupedPsls[providerName][0].providerId,
                            e.target.value
                          );
                        }}
                        onBlur={() => {
                          this.props.updateVisitScalars([
                            {
                              key: "noteToClinic",
                              value: this.props.visit.noteToClinic,
                            },
                          ]);
                        }}
                      />
                    </TableCell>
                    {this.context.currentUser.isExternal && (
                      <TableCell>
                        <TextareaAutosize
                          minRows={3}
                          defaultValue={
                            this.props.visit.noteToWorkcare
                              ? this.props.visit.noteToWorkcare[
                                  groupedPsls[providerName][0].providerId
                                ]
                              : null
                          }
                          onChange={(e) => {
                            this.props.onChangeNoteToWorkcare(
                              groupedPsls[providerName][0].providerId,
                              e.target.value
                            );
                          }}
                          onBlur={() => {
                            this.props.updateVisitScalars([
                              {
                                key: "noteToWorkcare",
                                value: this.props.visit.noteToWorkcare,
                              },
                            ]);
                          }}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                )
              )}
            </TableBody> */}
          </Table>
        )}
      </Stack>
    );
  };
  renderClinicTableER = () => {
    const groupedPsls = groupBy(
      this.state.chosenPSLs,
      (p) => p.providerObj.providerName
    );
    const bookedProtocols = this.props.visit.bookedProtocolIds.map(
      (protocolId) => {
        return this.props.companyProtocols.find((p) => p._id === protocolId);
      }
    );

    return (
      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="overline">Selected Clinic</Typography>
        </Stack>

        {this.state.chosenPSLs.length === 0 ? (
          <Alert severity="info" sx={{ mt: 1 }}>
            Find and select clinic to book appointment time.
          </Alert>
        ) : (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Clinic Name</TableCell>
                <TableCell>Chosen Protocols</TableCell>
                <TableCell>Select Appointment Time</TableCell>
                <TableCell>Notes For Clinic</TableCell>
                {this.context.currentUser.isExternal && (
                  <TableCell>Notes for Workcare</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from(Object.keys(groupedPsls)).map(
                (providerName, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Stack spacing={0.5}>
                        <Stack direction="row" alignItems="center">
                          <Typography variant="subtitle1">
                            {providerName}
                          </Typography>
                          <Tooltip
                            title={
                              <>
                                {" "}
                                {Object.entries(
                                  this.formatWeeklyTimings(
                                    groupedPsls[providerName][0].providerObj
                                      .weeklyTimings
                                  )
                                ).map(([day, schedule], idx) => (
                                  <Stack
                                    key={idx}
                                    direction="row"
                                    spacing={1}
                                    alignContent={"center"}
                                  >
                                    <Typography variant="caption">
                                      {day}
                                    </Typography>
                                    <Typography variant="caption">
                                      {schedule}
                                    </Typography>
                                  </Stack>
                                ))}
                              </>
                            }
                          >
                            <Iconify icon="material-symbols:info-outline" />
                          </Tooltip>
                        </Stack>

                        <Stack
                          direction="row"
                          alignItems={"center"}
                          spacing={1}
                        >
                          <Chip
                            variant="outlined"
                            size="small"
                            icon={<Iconify icon="mingcute:time-fill" />}
                            label={`${groupedPsls[providerName][0].timeFromEE?.text || "NA"}`}
                          />
                          <Tooltip
                            title={
                              <Stack>
                                <Typography variant="caption">
                                  {
                                    groupedPsls[providerName][0].providerObj
                                      .providerAddress
                                  }
                                </Typography>
                                <Typography variant="caption">
                                  {
                                    groupedPsls[providerName][0].providerObj
                                      .providerState
                                  }
                                  {", "}
                                  {
                                    groupedPsls[providerName][0].providerObj
                                      .providerCity
                                  }
                                  {", "}
                                  {
                                    groupedPsls[providerName][0].providerObj
                                      .providerPostalCode
                                  }
                                </Typography>
                              </Stack>
                            }
                          >
                            <Chip
                              variant="outlined"
                              size="small"
                              icon={
                                <Iconify icon="material-symbols:distance" />
                              }
                              label={`${groupedPsls[providerName][0].distanceFromEE?.text || "NA"}`}
                            />
                          </Tooltip>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={0.5}
                        >
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<Iconify icon="ic:baseline-phone" />}
                            href={`tel:${groupedPsls[providerName][0].providerObj.providerPhone}`}
                          >
                            {
                              groupedPsls[providerName][0].providerObj
                                .providerPhone
                            }
                          </Button>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={0.5}
                        >
                          <Iconify icon="material-symbols:fax" />
                          <Typography variant="caption">
                            {groupedPsls[providerName][0].providerObj
                              .providerFax || ""}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={0.5}
                        >
                          <Iconify icon="ic:baseline-email" />
                          <Typography variant="caption">
                            {groupedPsls[providerName][0].providerObj
                              .providerEmail || "No email added for provider"}
                          </Typography>
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack direction="column" spacing={0.5}>
                        {bookedProtocols.map((p, pIdx) => (
                          <Chip
                            size="small"
                            label={p.companyProtocolName}
                            variant="outlined"
                            key={pIdx}
                            icon={
                              <Iconify icon="fluent:protocol-handler-16-filled" />
                            }
                          />
                        ))}
                      </Stack>
                    </TableCell>
                    {this.allServicesChosen() ? (
                      <TableCell>
                        <Stack spacing={1}>
                          {this.context.currentUser.isExternal && (
                            <Alert severity="info" variant="outlined">
                              Please call the clinic before selecting a time.
                            </Alert>
                          )}
                          <Stack
                            direction="row"
                            spacing={0.5}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DateTimePicker
                                value={
                                  groupedPsls[providerName][0]?.scheduledTime
                                    ? moment(
                                        groupedPsls[providerName][0]
                                          ?.scheduledTime
                                      )
                                    : null
                                }
                                onAccept={() => {
                                  this.props.onFreezeClinic(
                                    this.state.chosenPSLs
                                  );
                                }}
                                onChange={(newValue) => {
                                  const chosenPSLs = this.state.chosenPSLs;
                                  const psl = groupedPsls[providerName][0];
                                  const providerId = psl.providerId;
                                  for (const cpsl of chosenPSLs) {
                                    if (cpsl.providerId === providerId) {
                                      cpsl["scheduledOnDate"] = newValue;
                                      cpsl["scheduledTime"] = newValue;
                                    }
                                  }
                                  this.setState({ chosenPSLs });
                                }}
                              />
                            </LocalizationProvider>
                            <Typography variant="overline">
                              {
                                groupedPsls[providerName][0].providerObj
                                  .providerReadableTimezone
                              }
                            </Typography>
                          </Stack>
                        </Stack>
                      </TableCell>
                    ) : (
                      <TableCell></TableCell>
                    )}
                    <TableCell>
                      <TextareaAutosize
                        minRows={3}
                        defaultValue={
                          this.props.visit.noteToClinic
                            ? this.props.visit.noteToClinic[
                                groupedPsls[providerName][0].providerId
                              ]
                            : null
                        }
                        onChange={(e) => {
                          this.props.onChangeNoteToClinic(
                            groupedPsls[providerName][0].providerId,
                            e.target.value
                          );
                        }}
                        onBlur={() => {
                          this.props.updateVisitScalars([
                            {
                              key: "noteToClinic",
                              value: this.props.visit.noteToClinic,
                            },
                          ]);
                        }}
                      />
                    </TableCell>
                    {this.context.currentUser.isExternal && (
                      <TableCell>
                        <TextareaAutosize
                          minRows={3}
                          defaultValue={
                            this.props.visit.noteToWorkcare
                              ? this.props.visit.noteToWorkcare[
                                  groupedPsls[providerName][0].providerId
                                ]
                              : null
                          }
                          onChange={(e) => {
                            this.props.onChangeNoteToWorkcare(
                              groupedPsls[providerName][0].providerId,
                              e.target.value
                            );
                          }}
                          onBlur={() => {
                            this.props.updateVisitScalars([
                              {
                                key: "noteToWorkcare",
                                value: this.props.visit.noteToWorkcare,
                              },
                            ]);
                          }}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        )}
      </Stack>
    );
  };
  isServiceInChosenPSLs = (serviceId) => {
    let chosenPSLs = this.state.chosenPSLs;
    // let masterServices = _.flatten(chosenPSLs.map((x) => x.masterServices));
    for (let psl of chosenPSLs) {
      if (psl.masterService === serviceId) {
        return true;
      }
    }
    return false;
  };
  whoIsProvidingService = (serviceId) => {
    let isIncluded = this.isServiceInChosenPSLs(serviceId);
    if (!isIncluded) {
      return <Alert severity="warning">Clinic Not Selected</Alert>;
    }
    let chosenPSLs = this.state.chosenPSLs;

    let idx = _.findIndex(chosenPSLs, (x) => x.masterService === serviceId);
    let psl = chosenPSLs[idx];

    let provider = psl.providerObj;
    let pslName = psl.pslName;
    return (
      <Stack>
        <Typography variant="overline">{provider.providerName}</Typography>{" "}
        <Typography variant="caption">{pslName}</Typography>
      </Stack>
    );
  };
  findProviders = async () => {
    if (!this.props.visit.employeeGeoLat || !this.props.visit.employeeGeoLong) {
      cogoToast.error("Please add an address with a postal code to proceed.");
      return;
    }

    if (_.isNaN(this.state.searchRadius1)) {
      cogoToast.error("Please enter a valid search radius.");
      return;
    }
    const searchRadiusDist = parseFloat(this.state.searchRadius1);
    if (searchRadiusDist < 1) {
      cogoToast.error("The search radius cannot be less than 1.");
      return;
    }

    let services = this.props.services;
    let serviceIds = services.map((x) => x._id);
    try {
      this.setState({
        results: [],
        chosenProviderId: null,
        isSearching: true,
      });
      const postalCode =
        this.props.visit.employeePostalCode ||
        this.props.visit.employee.employeePostalCode ||
        null;
      if (!postalCode) {
        cogoToast.error("Please add an address with a postal code to proceed.");
        this.setState({
          hasSearched: true,
          results: [],
          isSearching: false,
        });
        return;
      }
      const payload = {
        companyId: this.props.visit.company._id,
        masterServiceIds: serviceIds,
        eeGeoLong: this.props.visit.employeeGeoLong
          ? this.props.visit.employeeGeoLong
          : this.props.visit.employee.employeeGeoLong || 0,
        eeGeoLat: this.props.visit.employeeGeoLat
          ? this.props.visit.employeeGeoLat
          : this.props.visit.employee.employeeGeoLat || 0,
        radiusMeters: parseFloat(this.state.searchRadius1) * 1.6 * 1000,
        employeeId: this.props.visit.employee._id,
        visitId: this.props.visit._id,
        postalCode,
        searchRadius1: this.state.searchRadius1,
        searchRadius2: this.state.searchRadius1,
      };
      let res = [];

      res = await this.props.apiCallPost(
        "/visit/lookupProvidersByServicesBracketed",
        payload
      );

      // res.sort(
      //   (a, b) =>
      //     parseFloat(a.formFoxDetails.Distance) -
      //     parseFloat(b.formFoxDetails.Distance)
      // );

      // check all providers in the returned call
      // if all required services are covered, good to go
      // else also call the native services
      // const total_ls = uniq(flatten(res.map((r) => r.total_ls)));
      // const coverage_ls = uniq(flatten(res.map((r) => r.coverage_ls)));

      // if (res.length === 0 || total_ls.length !== coverage_ls.length) {
      //   const nativeRes = await this.props.apiCallPost(
      //     "/visit/lookupProvidersByServices",
      //     payload
      //   );
      //   res = res.concat(nativeRes);
      // }

      // const nativeRes = await this.props.apiCallPost(
      //   "/visit/lookupProvidersByServices",
      //   payload
      // );

      // res = res.concat(nativeRes);

      this.setState({
        hasSearched: true,
        results: res,
        isSearching: false,
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error In Finding Clinics.");
      this.setState({
        isLoading: false,
        isError: true,
        isSearching: false,
      });
    }
  };
  renderNotCovered = (eachProvider) => {
    let coverage_ls = eachProvider.services.map((x) => x._id);
    let serviceIds = this.props.services.map((x) => x._id);
    let notCovered = serviceIds.filter((x) => !coverage_ls.includes(x));
    // console.log({ serviceIds, coverage_ls });
    let coversAll = notCovered.length === 0;
    if (coversAll) {
      return (
        <Stack direction="row" alignItems={"center"} spacing={1}>
          <Iconify icon="mingcute:check-circle-fill" />
          <Typography variant="caption">
            Covers All Clinic or Collection Services
          </Typography>
        </Stack>
      );
    }
    let nc = [];
    if (!coversAll) {
      nc = notCovered
        .map((x) => {
          return {
            service: _.find(this.props.services, (y) => y._id === x),
          };
        })
        .map((z) => z.service);
    }

    // console.log({ nc, notCovered });

    return (
      <Stack spacing={1}>
        <Typography variant="caption">
          Does not cover {nc.map((x) => x.serviceName).join(", ")}
        </Typography>
      </Stack>
    );
  };
  renderPSLsForEachProvider = (eachProvider) => {
    let psls = eachProvider.services;
    if (!psls || !psls.length) {
      return <Alert severity="warning">No Provider Services Matched.</Alert>;
    }
    return (
      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(2, 1fr)",
          sm: "repeat(3, 1fr)",
          lg: "repeat(5, 1fr)",
        }}
      >
        {psls.map((eachPsl, pslIndex) => {
          let ms = eachPsl.psl.masterService;
          const msMapped = _.find(this.props.services, (x) => x._id === ms);
          // let msMapped = ms
          //   .map((x) => {
          //     return {
          //       service: _.find(this.props.services, (y) => y._id === x),
          //     };
          //   })
          //   .map((z) => z.service);

          let chosenPSLs = this.state.chosenPSLs;
          let isThisPSLChosen = _.findIndex(
            chosenPSLs,
            (x) => x.pslId === eachPsl._id
          );
          let isThisPSLChosenByThisProvider = false;
          if (isThisPSLChosen > -1) {
            let cpsl = chosenPSLs[isThisPSLChosen];
            let currentProviderId = eachProvider.provider._id;
            if (cpsl.providerId === currentProviderId) {
              isThisPSLChosenByThisProvider = true;
            }
          }
          let buttonElem = (
            <Button
              sx={{ marginTop: "auto" }}
              disabled={this.props.visit.isSaving}
              size="small"
              variant="outlined"
              startIcon={<Iconify icon="zondicons:add-solid" />}
              onClick={() => {
                let chosenPSLs = _.cloneDeep(this.state.chosenPSLs);
                for (var i = 0; i < eachPsl.masterServices.length; i++) {
                  let ms = eachPsl.masterServices[i];
                  let isIncludedAlready = this.isServiceInChosenPSLs(ms);
                  if (isIncludedAlready) {
                    cogoToast.error(
                      "Sorry, this service has already been covered."
                    );
                    return;
                  }
                }
                let newObj = {
                  providerName: eachProvider.provider.providerName,
                  providerObj: eachProvider.provider,
                  pslObj: eachPsl,
                  providerId: eachProvider.provider._id,
                  pslId: eachPsl._id,
                  pslName: eachPsl.providerServiceAlias,
                  masterService: eachPsl.masterService,
                  distanceFromEE: {
                    text: eachProvider.distance_txt,
                    value: eachProvider.distance_val,
                  },
                  timeFromEE: {
                    text: eachProvider.duration_txt,
                    value: eachProvider.duration_val,
                  },
                };
                chosenPSLs.push(newObj);
                this.setState({ chosenPSLs });
              }}
            >
              Choose
            </Button>
          );
          if (isThisPSLChosenByThisProvider) {
            buttonElem = (
              <Button
                disabled={this.props.visit.isSaving}
                size="small"
                onClick={() => {
                  let chosenPSLs = _.cloneDeep(this.state.chosenPSLs);

                  let idx = _.findIndex(
                    chosenPSLs,
                    (x) => x.pslId === eachPsl._id
                  );
                  chosenPSLs.splice(idx, 1);

                  this.setState({ chosenPSLs });
                }}
              >
                Remove
              </Button>
            );
          }
          let cardSx = {
            p: 2,
            boxShadow: 3,
            rounded: 1,
            bgcolor: isThisPSLChosenByThisProvider
              ? "success.light"
              : "background.paper",
          };

          return (
            <Card key={pslIndex} sx={cardSx}>
              <Stack sx={{ height: "100%" }} spacing={1}>
                <Typography variant="button">
                  {eachPsl.psl.providerServiceAlias}
                </Typography>
                <Typography variant="caption" key={0}>
                  {msMapped.serviceName}
                </Typography>
                <Typography variant="caption">
                  <Chip
                    size="small"
                    variant="outlined"
                    label={_.startCase(eachPsl.bookingChannel)}
                    key={`svc_${pslIndex}`}
                  />
                </Typography>
                {eachPsl.serviceRequiresProviderType === "Lab" ? (
                  <Typography variant="caption">
                    <Chip
                      size="small"
                      variant="outlined"
                      label={`${_.startCase(eachPsl.destinationLab)}`}
                      key={`svc_lab_${pslIndex}`}
                    />
                  </Typography>
                ) : null}

                {/* {buttonElem} */}
              </Stack>
            </Card>
          );
        })}
      </Box>
    );
  };
  renderEachProvider = (eachProvider) => {
    let coverage_ls = eachProvider.services.map((x) => x._id);
    let serviceIds = this.props.services.map((x) => x._id);
    let notCovered = serviceIds.filter((x) => !coverage_ls.includes(x));
    let coversAll = notCovered.length === 0;
    let nc = [];
    if (!coversAll) {
      nc = notCovered
        .map((x) => {
          return {
            service: _.find(this.props.services, (y) => y._id === x),
          };
        })
        .map((z) => z.service);
    }

    return (
      <Card
        key={eachProvider.provider._id}
        sx={{
          p: 2,
          boxShadow: 3,
          rounded: 1,
        }}
      >
        <Stack spacing={1}>
          <Stack direction="row">
            <Stack direction="row" alignItems="center">
              <Typography variant="subtitle1">
                {eachProvider.provider.providerName}
              </Typography>
              <Tooltip
                title={
                  <>
                    {Object.entries(
                      this.formatWeeklyTimings(
                        eachProvider.provider.weeklyTimings
                      )
                    ).map(([day, schedule]) => (
                      <Stack
                        direction="row"
                        spacing={1}
                        alignContent={"center"}
                      >
                        <Typography variant="caption">{day}</Typography>
                        <Typography variant="caption">{schedule}</Typography>
                      </Stack>
                    ))}
                  </>
                }
              >
                <Iconify icon="material-symbols:info-outline" />
              </Tooltip>
            </Stack>

            {eachProvider.formFoxProvider && (
              <Stack
                sx={{
                  padding: "0.5rem",
                  background: "#f3f3f3",
                  borderRadius: "5px",
                  marginLeft: "auto",
                }}
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Iconify icon="material-symbols:star" />
                <Tooltip title="Electronic workflow allows to automatic results.">
                  <Typography variant="caption">
                    Electronic Workflow Available
                  </Typography>
                </Tooltip>
              </Stack>
            )}
          </Stack>

          <Stack spacing={1} direction="row">
            <Iconify icon="mdi:location" />
            <Stack direction={"column"} spacing={0.5}>
              <Typography variant="caption">
                {eachProvider.provider.providerAddress}
              </Typography>
              <Typography variant="caption">
                {eachProvider.provider.providerState}
                {", "}
                {eachProvider.provider.providerCity}
                {", "}
                {eachProvider.provider.providerPostalCode}
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<Iconify icon="ic:baseline-phone" />}
                  href={`tel:${eachProvider.provider.providerPhone}`}
                >
                  {eachProvider.provider.providerPhone || "NA"}
                </Button>
                {this.context.currentUser.isExternal && (
                  <Alert severity="info" variant="outlined">
                    Please call the clinic before selecting a time.
                  </Alert>
                )}
              </Stack>
            </Stack>
          </Stack>
          {this.renderNotCovered(eachProvider)}
          {this.renderPSLsForEachProvider(eachProvider)}
          <Stack direction="row" alignItems={"center"} spacing={1}>
            <Chip
              variant="outlined"
              size="small"
              icon={<Iconify icon="ic:outline-drive-eta" />}
              label={`${eachProvider.providerMetadata?.duration_txt}`}
            />
            <Chip
              variant="outlined"
              size="small"
              icon={<Iconify icon="material-symbols:distance" />}
              label={`${eachProvider.providerMetadata?.distance_txt}`}
            />
            {!this.context.currentUser.isExternal &&
              (eachProvider.providerMetadata?.providerCash === 0 ? null : (
                <Chip
                  variant="outlined"
                  size="small"
                  icon={<Iconify icon="solar:dollar-bold" />}
                  label={`${eachProvider.providerMetadata?.providerCash === 0 ? "Not available" : eachProvider.providerMetadata?.providerCash}`}
                />
              ))}
          </Stack>
        </Stack>
        <Button
          style={{ marginTop: "1rem", width: "100%" }}
          size="small"
          variant="contained"
          color="primary"
          onClick={() => {
            const serviceBookingInfo = {};
            for (let s of eachProvider.services) {
              serviceBookingInfo[s._id] = {
                bookingChannel: s.bookingChannel,
                destinationLab: s.destinationLab,
              };
            }
            this.setState(
              {
                providerPSLs: eachProvider.services.map((s) => s.psl._id),
                provider: eachProvider.provider,
                providerDistanceFromEE:
                  eachProvider.providerMetadata?.distance_val,
                providerTimeFromEE: eachProvider.providerMetadata?.duration_val,
                providerPSLsInfo: eachProvider.services.map((s) => s.psl),
                clinicFrozen: true,
              },
              () => {
                this.props.onFreezeClinic({
                  provider: eachProvider.provider,
                  providerDistanceFromEE:
                    eachProvider.providerMetadata?.distance_val,
                  providerTimeFromEE:
                    eachProvider.providerMetadata?.duration_val,
                  providerPSLs: eachProvider.services.map((s) => s.psl._id),
                  providerPSLsInfo: eachProvider.services.map((s) => s.psl),
                  serviceBookingInfo,
                });
              }
            );
          }}
        >
          Select Clinic
        </Button>
      </Card>
    );
  };
  renderResults = () => {
    if (this.props.visit.clinicFrozen) {
      return null;
    }
    if (!this.state.hasSearched) {
      return null;
    }
    if (!this.state.results || !this.state.results.length) {
      return <Alert severity="warning">No Providers Found</Alert>;
    }
    let results = this.state.results;

    let markers = this.state.results.map((x) => {
      return {
        lat: x.provider.providerGeoLat,
        lng: x.provider.providerGeoLong,
        label: x.provider.providerName,
        _id: x.provider._id,
      };
    });
    markers.push({
      lat: this.props.visit.employeeGeoLat,
      lng: this.props.visit.employeeGeoLong,
      label: "Employee",
      _id: "EMPLOYEE",
    });
    // let averageLat = markers.reduce((acc, curr) => {
    //   return acc + curr.lat;
    // }, 0);
    // averageLat = averageLat / markers.length;
    // let averageLong = markers.reduce((acc, curr) => {
    //   return acc + curr.lng;
    // }, 0);
    // averageLong = averageLong / markers.length;
    let centre = {
      lat: this.props.visit.employeeGeoLat,
      lng: this.props.visit.employeeGeoLong,
    };
    let chosenProviderIds = _.uniq(
      this.state.chosenPSLs.map((x) => x.providerId)
    );
    return (
      <Grid container spacing={3}>
        <Grid xs={12} md={4}>
          <GMapMultiple
            markers={markers}
            placeDetail={centre}
            chosenMarkerIndices={chosenProviderIds}
            onSelectMarker={(_id) => {}}
          />
        </Grid>{" "}
        <Grid xs={12} md={8}>
          <Stack spacing={2}>
            {results.map((each) => {
              return this.renderEachProvider(each);
            })}
          </Stack>
        </Grid>
      </Grid>
    );
  };
  renderButton = () => {
    if (
      !["REQUESTED", "OUTREACH_FOR_SCHEDULING"].includes(
        this.props.visit.visitStatus
      )
    )
      return "";

    if (this.props.visit.clinicFrozen) {
      return (
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="end"
        >
          <Button
            disabled={this.props.visit.isSaving}
            onClick={() => {
              this.setState(
                {
                  clinicFrozen: false,
                  provider: null,
                  providerDistanceFromEE: null,
                  providerTimeFromEE: null,
                  providerEncounterOnDate: null,
                  providerEncounterOnTime: null,
                  providerPSLs: [],
                  providerPSLsInfo: [],
                },
                () => {
                  this.props.unFreezeClinics();
                }
              );
            }}
            variant="outlined"
            startIcon={<Iconify icon="material-symbols:change-circle" />}
          >
            Choose a different clinic
          </Button>
        </Stack>
      );
    }

    return (
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="end"
      >
        <TextField
          size="small"
          label="Search Radius (in miles)"
          value={this.state.searchRadius1}
          onChange={(e) => {
            // if (!e.target.value) return;
            // if (parseInt(e.target.value) < 1) {
            //   cogoToast.error("Value cannot be less than 1");
            //   return;
            // }
            this.setState({ searchRadius1: e.target.value });
          }}
        />

        <LoadingButton
          disabled={this.props.visit.isSaving}
          loading={this.state.isSearching}
          onClick={() => {
            this.findProviders();
          }}
          variant="contained"
          size="small"
        >
          Search Clinics
        </LoadingButton>
        {/* <Button
          disabled={this.props.visit.isSaving || !this.allServicesChosen()}
          onClick={() => {
            this.props.onFreezeClinic({
              provider
              providerDistanceFromEE
              providerTimeFromEE
              providerEncounterOnDate
              providerEncounterOnTime
              providerPSLs              
            });
          }}
          variant="contained"
          color="primary"
          startIcon={<Iconify icon="material-symbols:save" />}
          size="small"
        >
          Confirm Clinic Providers
        </Button> */}
      </Stack>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <Stack spacing={2}>
        <Paper sx={{ p: 2, rounded: 1, boxShadow: 3 }}>
          <Dialog
            maxWidth="md"
            open={this.state.openEmployeeLocationModal}
            onClose={() => {
              this.setState({ openEmployeeLocationModal: false });
            }}
          >
            <DialogTitle>Employee Location</DialogTitle>
            <DialogContent>
              <VisitEmployeeLocation
                visit={this.props.visit}
                isSaving={this.state.isSaving}
                employee={this.props.visit.employee}
                onUpdateLocation={async (params) => {
                  let payload = [
                    {
                      key: "employeeAddress",
                      value: params.employeeAddress || "",
                    },
                    {
                      key: "employeeAddressQualilfier",
                      value: params.employeeAddressQualilfier || "",
                    },
                    {
                      key: "employeeCity",
                      value: params.employeeCity || "",
                    },
                    {
                      key: "employeeState",
                      value: params.employeeState || "",
                    },
                    {
                      key: "employeePostalCode",
                      value: params.employeePostalCode || "",
                    },
                    {
                      key: "employeeGeoLat",
                      value: params.employeeGeoLat || "",
                    },
                    {
                      key: "employeeGeoLong",
                      value: params.employeeGeoLong || "",
                    },
                  ];
                  await this.props.updateVisitScalars(payload);
                  this.setState({
                    openEmployeeLocationModal: false,
                  });
                }}
              />
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
          <EEOutreachAttempt
            isOpen={this.state.isEEOutreachAttemptOpen}
            onDone={async (notes) => {
              await this.props.apiCallPost(
                "/visit/events/createNewVisitEvent",
                {
                  visitId: this.props.visit._id,
                  statusTo: "OUTREACH_FOR_SCHEDULING",
                  header: "Employee Outreach Attempt - Scheduling",
                  notes: notes,
                  creator: "User",
                }
              );
              this.setState({
                isEEOutreachAttemptOpen: false,
                nEEOutreachAttempts: this.state.nEEOutreachAttempts + 1,
              });
            }}
            onCancel={() => {
              this.setState({ isEEOutreachAttemptOpen: false });
            }}
          />
          <Stack spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h6">Employee Location</Typography>
              <Tooltip title="This is the employee location for this visit. It does not update the employee's location that is already stored.">
                <Iconify icon="lucide:info" />
              </Tooltip>
            </Stack>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Address</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>Zip Code</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle2">
                      {this.props.visit.employeeAddress ||
                        this.props.visit.employee.employeeAddress}{" "}
                      <br />{" "}
                      {this.props.visit.employeeAddressQualilfier ||
                        this.props.visit.employee
                          .employeeAddressQualilfier}{" "}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">
                      {this.props.visit.employeeCity ||
                        this.props.visit.employee.employeeCity}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">
                      {this.props.visit.employeeState ||
                        this.props.visit.employee.employeeState}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">
                      {this.props.visit.employeePostalCode ||
                        this.props.visit.employee.employeePostalCode}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      startIcon={<Iconify icon="material-symbols:edit" />}
                      onClick={() => {
                        this.setState({ openEmployeeLocationModal: true });
                      }}
                    >
                      Edit Location
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
        </Paper>
        <Paper sx={{ p: 2, rounded: 1, boxShadow: 3 }}>
          <Stack spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h6">Clinic Services</Typography>
              <Tooltip title="Search & Choose Clinics">
                <Iconify icon="lucide:info" />
              </Tooltip>
            </Stack>
            {this.renderServices()}
            {this.context.currentUser.isExternal
              ? this.renderClinicTableER()
              : this.renderClinicTable()}
            {this.renderButton()}
            {this.renderResults()}
          </Stack>
        </Paper>
      </Stack>
    );
  }
}

export default WithAPICall(VisitChooseClinic);
