import React from "react";

import { WithAPICall } from "../utils/apiUtil";
import _ from "lodash";
import { Card, Chip, LinearProgress, Tooltip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import Iconify from "src/components/iconify";

class EmployeeTransferProtocol extends React.Component {
  state = { hasCheckedCriteria: false };
  componentDidMount() {
    this.setState({
      ...this.props.data,
    });
    this.checkServiceCriteria();
  }
  checkServiceCriteria = async () => {
    let ms = this.props.data.masterServices;
    let sm = _.cloneDeep(this.props.data.serviceMetadata);
    if (!ms || !ms.length) {
      this.setState({
        hasCheckedCriteria: true,
      });
    }
    let res = await Promise.all(
      sm.map((eachService) => {
        let svcCriteriaType = eachService.serviceCriteriaType;
        if (svcCriteriaType !== "Conditional: Criteria") {
          return {
            satisfies: true,
          };
        }
        let fs = eachService.serviceCriteria.filterSpec;
        if (!fs) {
          return {
            satisfies: true,
          };
        }

        let payload = {
          filterSpec: fs,
        };
        if (this.props.employeeId) {
          payload["employeeId"] = this.props.employeeId;
        } else {
          payload["employeeSpec"] = this.props.employeeSpec;
          payload["companyId"] = this.props.companyId;
        }
        if (this.props.protocolType === "Preplacement") {
          payload["modSpec"] = this.props.modSpec;
        }
        let apiUrl = "/employee/doesHypotheticalEmployeeSatisfyQuery";
        if (this.props.employeeId) {
          apiUrl = "/employee/doesEmployeeSatisfyQuery";
        }
        return this.props.apiCallPost(apiUrl, payload);
      })
    );
    let smMod = sm.map((each, index) => {
      each["satisfiesCriteria"] = res[index].satisfies;
      return each;
    });
    this.setState(
      {
        serviceMetadata: smMod,
        hasCheckedCriteria: true,
      },
      () => {
        let resValues = _.uniq(_.map(res, "satisfies"));
        // if only false, then ignore true
        let resIsIgnorable = resValues.length === 1 && resValues[0] === false;
        this.props.onCheckServiceCriteria(resIsIgnorable);
      }
    );
  };
  renderServices = () => {
    if (!this.state.hasCheckedCriteria) {
      return <LinearProgress />;
    }
    let ms = this.props.data.masterServices;
    let sm = this.props.data.serviceMetadata;
    if (!ms || !ms.length) {
      return null;
    }

    return sm.map((each, index) => {
      let criteriaType = each.serviceCriteriaType;
      let criteriaElem = null;

      if (criteriaType === "Always Required") {
        criteriaElem = (
          <Tooltip title="Always Required">
            <Iconify icon="mdi:required-circle" />
          </Tooltip>
        );
      }
      if (criteriaType === "Conditional: Criteria") {
        let reqd = each.satisfiesCriteria;
        if (reqd) {
          criteriaElem = (
            <Tooltip title="Conditional, required for this employee">
              <Iconify icon="mdi:playlist-tick" />
            </Tooltip>
          );
        } else {
          criteriaElem = (
            <Tooltip title="Conditional, likely not required for this employee">
              <Iconify icon="mdi:playlist-remove" />
            </Tooltip>
          );
        }
      }
      if (criteriaType === "Conditional: NL") {
        let fs = each.serviceCriteria.filterSpec;
        let txt = "This service could be conditional on certain criteria";

        criteriaElem = (
          <Tooltip title={fs || txt}>
            <Iconify icon="mdi:message-unread" />
          </Tooltip>
        );
      }
      if (criteriaType === "Optional") {
        let fs = each.serviceCriteria.filterSpec;
        let txt = "This service could be conditional on certain criteria";
        criteriaElem = (
          <Tooltip title={`Optional: ${fs || txt}`}>
            <Iconify icon="mdi:crosshairs-question" />
          </Tooltip>
        );
      }
      return (
        <Stack direction={"row"} key={index} spacing={1}>
          <Typography variant="overline">{each.serviceName}</Typography>
          {criteriaElem}
        </Stack>
      );
    });
  };

  render() {
    return (
      <Card
        sx={{
          p: 1,
          rounded: 2,
          boxShadow: 2,
        }}
      >
        <Stack spacing={1}>
          <Chip
            color={
              this.props.protocolType === "Preplacement" ? "primary" : "warning"
            }
            label={
              <Typography variant="overline">
                {this.props.data.companyProtocolName}
              </Typography>
            }
          />
          {this.renderServices()}
        </Stack>
      </Card>
    );
  }
}

export default WithAPICall(EmployeeTransferProtocol);
