import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Paper,
  Grid,
  Tooltip,
  Chip,
  IconButton,
} from "@mui/material";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import Iconify from "src/components/iconify";
import { TYPE_OF_DOCUMENT_OPTIONS } from "../../assets/data/service-data-type";

const dataTypes = [...TYPE_OF_DOCUMENT_OPTIONS];

const transformDataTypes = (employeeDataTypes) => {
  return employeeDataTypes.reduce((acc, dt) => {
    acc[dt.dataType] = dt;
    return acc;
  }, {});
};

export const DPS3bSelectDataRange = ({ currentTags, updateTags, psUrl }) => {
  const [selectedTypes, setSelectedTypes] = useState(
    transformDataTypes(currentTags)
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const toggleDataType = (type) => {
    setSelectedTypes((prev) => {
      const newTypes = { ...prev };
      if (type in newTypes) {
        delete newTypes[type];
      } else {
        newTypes[type] = { count: 1, pageIndices: [""] };
      }
      return newTypes;
    });
  };

  const updateInstanceCount = (type, increment) => {
    setSelectedTypes((prev) => {
      const newTypes = { ...prev };
      const currentCount = newTypes[type].count;
      const newCount = increment
        ? currentCount + 1
        : Math.max(1, currentCount - 1);
      newTypes[type] = {
        count: newCount,
        pageIndices: Array(newCount)
          .fill("")
          .map((_, i) => newTypes[type].pageIndices[i] || ""),
      };
      return newTypes;
    });
  };

  const updatePageIndices = (type, index, value) => {
    setSelectedTypes((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        pageIndices: prev[type].pageIndices.map((indices, i) =>
          i === index ? value : indices
        ),
      },
    }));
  };

  const backTransformAndUpdate = () => {
    const arrayForm = Object.entries(selectedTypes).map(([key, value]) => ({
      ...value,
      dataType: key,
    }));
    updateTags(arrayForm);
  };

  useEffect(() => {
    backTransformAndUpdate();
  }, [selectedTypes]);

  return (
    <Box
      sx={{ display: "flex", height: "100vh", bgcolor: "background.default" }}
    >
      <Box sx={{ flexGrow: 1, p: 2, overflow: "hidden" }}>
        <Paper elevation={3} sx={{ height: "100%", overflow: "auto" }}>
          <Box sx={{ height: "100%" }}>
            <Viewer
              fileUrl={psUrl}
              plugins={[defaultLayoutPluginInstance]}
              defaultScale={SpecialZoomLevel.PageFit}
            />
          </Box>
        </Paper>
      </Box>

      <Paper
        elevation={3}
        sx={{
          width: 300,
          p: 2,
          overflow: "auto",
          borderLeft: 1,
          borderColor: "divider",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Data Types
        </Typography>
        <Grid container spacing={2}>
          {dataTypes.map((type) => (
            <Grid item xs={12} key={type}>
              <Chip
                label={type}
                onClick={() => toggleDataType(type)}
                color={type in selectedTypes ? "primary" : "default"}
                sx={{ width: "100%", justifyContent: "flex-start" }}
              />
              {type in selectedTypes && (
                <Box sx={{ mt: 1, ml: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Tooltip title="Decrease instance count">
                      <IconButton
                        size="small"
                        onClick={() => updateInstanceCount(type, false)}
                        disabled={selectedTypes[type].count === 1}
                      >
                        <Iconify icon="mdi:minus-circle-outline" />
                      </IconButton>
                    </Tooltip>
                    <Typography sx={{ mx: 1 }}>
                      {selectedTypes[type].count}
                    </Typography>
                    <Tooltip title="Increase instance count">
                      <IconButton
                        size="small"
                        onClick={() => updateInstanceCount(type, true)}
                      >
                        <Iconify icon="mdi:plus-circle-outline" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  {selectedTypes[type].pageIndices.map((indices, index) => (
                    <Box
                      key={index}
                      sx={{ display: "flex", alignItems: "center", mb: 1 }}
                    >
                      <TextField
                        size="small"
                        placeholder={`Page indices ${index + 1}`}
                        value={indices}
                        onChange={(e) =>
                          updatePageIndices(type, index, e.target.value)
                        }
                        required={selectedTypes[type].count > 1}
                        sx={{ mr: 1, flexGrow: 1 }}
                      />
                      <Tooltip title="Enter hyphen-separated page range (e.g. 1-5)">
                        <IconButton size="small">
                          <Iconify icon="mdi:help-circle-outline" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ))}
                </Box>
              )}
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
};
