import { Alert, Button, Card, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { DocLinkingEach } from "./DocLinkingEach";
import { Stack } from "@mui/system";
import _ from "lodash";

export function DocLinking({ employees, document, updateDocumentToDone }) {
  if (!employees || employees.length === 0) {
    return <Alert severity="info">No employees found</Alert>;
  }

  if (_.get(document, "processStatusTracker[4].verifiedBy") === null) {
    return (
      <Alert severity="info">
        Please verify Extracted Medical Records first before linking against a
        patient visit.
      </Alert>
    );
  }

  const handleConfirm = () => {
    updateDocumentToDone();
  };
  const employeesElem = employees.map((employee, index) => (
    <div key={index}>
      <Card
        sx={{
          p: 1,
          m: 1,
          boxShadow: 1,
          border: "1px solid #e0e0e0",
        }}
      >
        <Typography variant="h6">
          Extracted Name: {employee.employeeFirstName}{" "}
          {employee.employeeLastName}
        </Typography>
        <DocLinkingEach eeIndex={index} ee={employee} document={document} />
      </Card>
    </div>
  ));
  return (
    <Stack direction="column" spacing={1}>
      {employeesElem}
      <Button variant="contained" color="primary" onClick={handleConfirm}>
        Done
      </Button>
    </Stack>
  );
}
