"use client";

import React, { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Progress } from "@/components/ui/progress";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  FileIcon,
  Pencil,
  Download,
  Trash2,
  Upload,
  X,
  Check,
} from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import useVisitStore from "../../../store/useVisitStore";
import { useProtectedApi } from "@/hooks/use-apiCall";

// { id: 1, name: "Medical_Report.pdf", progress: 100 },
// { id: 2, name: "Lab_Results.docx", progress: 100 },

export function ClearanceFileSection() {
  const { callApi, callUploadApi } = useProtectedApi();
  const { visit, status } = useVisitStore();

  const [files, setFiles] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [editingId, setEditingId] = useState(null);
  const { toast } = useToast();

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await callApi("/files/getByEntityId", {
        entityType: "CommentFile",
        entityId: visit._id,
      });
      setFiles(result);
    } catch (error) {
      setError("Failed to fetch data");
    } finally {
      setIsLoading(false);
    }
  };

  const uploadFile = async (file) => {
    if (!file) return;

    // setUploading(true);
    setUploadProgress(0);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("entityType", "CommentFile");
    formData.append("entityId", visit._id);
    formData.append("description", "");

    try {
      const response = await callUploadApi(
        "/files/addToEntity",
        formData,
        (progress) => {
          setUploadProgress(progress);
        }
      );
      const result = response;
      // setUploadedFile(result);
      toast({
        title: "File uploaded successfully",
        description: "Your file has been uploaded.",
      });
    } catch (error) {
      console.error("Upload error:", error);
      toast({
        title: "Upload failed",
        description:
          "There was an error uploading your file. Please try again.",
        variant: "destructive",
      });
    } finally {
      // setUploading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [visit]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const newFile = { id: Date.now(), originalName: file.name, progress: 0 };
      setFiles([...files, newFile]);
      // simulateUpload(newFile.id);
      uploadFile(file);
    }
  };

  const simulateUpload = (id) => {
    let progress = 0;
    const interval = setInterval(() => {
      progress += 10;
      setFiles((files) =>
        files.map((f) => (f.id === id ? { ...f, progress } : f))
      );
      setUploadProgress(progress);
      if (progress >= 100) {
        clearInterval(interval);
        setUploadProgress(0);
        toast({
          title: "File uploaded successfully",
          description: "Your file has been uploaded and is now available.",
        });
      }
    }, 500);
  };

  const handleDelete = (id) => {
    setFiles(files.filter((f) => f.id !== id));
    toast({
      title: "File deleted",
      description: "The file has been removed from the system.",
    });
  };

  const handleEdit = (id, newName) => {
    setFiles(
      files.map((f) => (f.id === id ? { ...f, originalName: newName } : f))
    );
    setEditingId(null);
  };

  return (
    <div className="p-3 max-w-4xl mx-auto bg-white rounded-lg shadow-md">
      <h2 className="text-base font-semibold mb-2">File Management</h2>
      <div className="mb-2">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <label htmlFor="file-upload" className="cursor-pointer">
                <div className="flex items-center space-x-1 text-xs text-primary hover:text-primary-dark">
                  <Upload size={14} />
                  <span>Upload New File</span>
                </div>
                <Input
                  id="file-upload"
                  type="file"
                  className="hidden"
                  onChange={handleFileUpload}
                />
              </label>
            </TooltipTrigger>
            <TooltipContent>
              <p className="text-xs">Click to upload a new file</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      {uploadProgress > 0 && (
        <div className="mb-2">
          <div className="flex justify-between text-xs mb-1">
            <span>Uploading...</span>
            <span>{uploadProgress}%</span>
          </div>
          <Progress value={uploadProgress} className="w-full h-1" />
        </div>
      )}
      <Table className="text-xs">
        <TableHeader>
          <TableRow>
            <TableHead className="w-[200px] py-1">File Name</TableHead>
            <TableHead className="text-right py-1">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {files.map((file) => (
            <TableRow key={file.id}>
              <TableCell className="py-1">
                {editingId === file.id ? (
                  <Input
                    value={file.originalName}
                    onChange={(e) => handleEdit(file.id, e.target.value)}
                    className="w-full text-xs h-6 px-1"
                    autoFocus
                  />
                ) : (
                  <div className="flex items-center space-x-1">
                    <FileIcon size={12} />
                    <span>{file.originalName}</span>
                  </div>
                )}
              </TableCell>
              <TableCell className="text-right space-x-1 py-1">
                <TooltipProvider>
                  {editingId === file.id ? (
                    <>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="ghost"
                            size="icon"
                            className="h-6 w-6"
                            onClick={() =>
                              handleEdit(file.id, file.originalName)
                            }
                          >
                            <Check size={12} />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p className="text-xs">Save changes</p>
                        </TooltipContent>
                      </Tooltip>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="ghost"
                            size="icon"
                            className="h-6 w-6"
                            onClick={() => setEditingId(null)}
                          >
                            <X size={12} />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p className="text-xs">Cancel editing</p>
                        </TooltipContent>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="ghost"
                            size="icon"
                            className="h-6 w-6"
                            onClick={() => setEditingId(file.id)}
                          >
                            <Pencil size={12} />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p className="text-xs">Edit file name</p>
                        </TooltipContent>
                      </Tooltip>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="ghost"
                            size="icon"
                            className="h-6 w-6"
                            onClick={() => {
                              window.open(file.psUrl, "_blank");
                            }}
                          >
                            <Download size={12} />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p className="text-xs">Download file</p>
                        </TooltipContent>
                      </Tooltip>
                      <AlertDialog>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <AlertDialogTrigger asChild>
                              <Button
                                variant="ghost"
                                size="icon"
                                className="h-6 w-6"
                              >
                                <Trash2 size={12} />
                              </Button>
                            </AlertDialogTrigger>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p className="text-xs">Delete file</p>
                          </TooltipContent>
                        </Tooltip>
                        <AlertDialogContent className="max-w-[300px]">
                          <AlertDialogHeader>
                            <AlertDialogTitle className="text-sm">
                              Are you sure?
                            </AlertDialogTitle>
                            <AlertDialogDescription className="text-xs">
                              This action cannot be undone. This will
                              permanently delete the file from the system.
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel className="text-xs h-7">
                              Cancel
                            </AlertDialogCancel>
                            <AlertDialogAction
                              className="text-xs h-7"
                              onClick={() => handleDelete(file.id)}
                            >
                              Delete
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    </>
                  )}
                </TooltipProvider>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
