// 'use client';
import { BarChart, Card } from "@tremor/react";

const data = [
  {
    date: "Jan 23",
    "Total Revenue": 68560,
    "Total Cost": 28560,
  },
  {
    date: "Feb 23",
    "Total Revenue": 70320,
    "Total Cost": 30320,
  },
  {
    date: "Mar 23",
    "Total Revenue": 80233,
    "Total Cost": 70233,
  },
  {
    date: "Apr 23",
    "Total Revenue": 55123,
    "Total Cost": 45123,
  },
  {
    date: "May 23",
    "Total Revenue": 56000,
    "Total Cost": 80600,
  },
  {
    date: "Jun 23",
    "Total Revenue": 100000,
    "Total Cost": 85390,
  },
  {
    date: "Jul 23",
    "Total Revenue": 85390,
    "Total Cost": 45340,
  },
  {
    date: "Aug 23",
    "Total Revenue": 80100,
    "Total Cost": 70120,
  },
  {
    date: "Sep 23",
    "Total Revenue": 75090,
    "Total Cost": 69450,
  },
  {
    date: "Oct 23",
    "Total Revenue": 71080,
    "Total Cost": 63345,
  },
  {
    date: "Nov 23",
    "Total Revenue": 61210,
    "Total Cost": 100330,
  },
  {
    date: "Dec 23",
    "Total Revenue": 60143,
    "Total Cost": 45321,
  },
];

function valueFormatter(number) {
  const formatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
    notation: "compact",
    compactDisplay: "short",
    style: "currency",
    currency: "USD",
  });
  return formatter.format(number);
}

export default function Money() {
  return (
    <>
      {/* <h3 className="font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
        Sales overview
      </h3> */}
      {/* <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr.
      </p> */}
      <ul role="list" className="mt-6 flex gap-10">
        <li>
          <div className="flex items-center space-x-1.5">
            <span
              className="h-2.5 w-2.5 rounded-sm bg-tremor-brand dark:bg-dark-tremor-brand"
              aria-hidden={true}
            />
            <p className="text-tremor-label text-tremor-content dark:text-dark-tremor-content">
              Total Revenue
            </p>
          </div>
          <div className="flex items-center space-x-1.5">
            <p className="mt-0.5 text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              -
            </p>
            <span className="rounded-tremor-small bg-tremor-background-subtle px-1.5 py-0.5 text-tremor-label font-medium text-tremor-content dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content"></span>
          </div>
        </li>
        <li>
          <div className="flex items-center space-x-1.5">
            <span
              className="h-2.5 w-2.5 rounded-sm bg-cyan-500"
              aria-hidden={true}
            />
            <p className="text-tremor-label text-tremor-content dark:text-dark-tremor-content">
              Total Cost
            </p>
          </div>
          <p className="mt-0.5 text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
            -
          </p>
        </li>
      </ul>
      <BarChart
        data={[]}
        index="date"
        categories={["Total Cost", "Total Revenue"]}
        colors={["cyan", "blue"]}
        showLegend={false}
        valueFormatter={valueFormatter}
        yAxisWidth={45}
        className="mt-8 hidden h-56 sm:block"
      />
      {/* <BarChart
        data={data}
        index="date"
        categories={["Last Year", "This Year"]}
        colors={["cyan", "blue"]}
        showLegend={false}
        valueFormatter={valueFormatter}
        showYAxis={false}
        className="mt-8 h-48 sm:hidden"
      /> */}
    </>
  );
}
