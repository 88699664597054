import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Stack,
  Chip,
  Avatar,
  Drawer,
  Button,
  IconButton,
  Card,
  Tooltip,
} from "@mui/material";
import Iconify from "src/components/iconify";
import useDocumentStore from "../../store/useDocumentStore";
import { useProtectedApi } from "@/hooks/use-apiCall";
import { DPS3bSelectDataRange } from "./document-processing-stage-3b-select-data-ranges";

const colorMap = {
  blue: { bg: "#E3F2FD", text: "#1565C0" },
  purple: { bg: "#EDE7F6", text: "#5E35B1" },
  green: { bg: "#E8F5E9", text: "#2E7D32" },
  red: { bg: "#FFEBEE", text: "#C62828" },
  yellow: { bg: "#FFF8E1", text: "#F9A825" },
  indigo: { bg: "#E8EAF6", text: "#3949AB" },
  pink: { bg: "#FCE4EC", text: "#AD1457" },
};

export function DPS3DataTagger() {
  const { callApi } = useProtectedApi();
  const { document, loadDocument } = useDocumentStore();

  const [psUrl, setPsUrl] = useState(document.psUrl);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerEEIndex, setDrawerEEIndex] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const patients = document.extractedEEInfo || [];

  const handlePatientClick = (patient, index) => {
    setSelectedPatient(patient);
    setDrawerEEIndex(index);
    setOpenDrawer(true);
  };

  const updateTags = async (eeIndex, employeeDataTypes) => {
    const updatedExtractedEEInfo = [...patients];
    updatedExtractedEEInfo[eeIndex].employeeDataTypes = employeeDataTypes;
    try {
      const resp = await callApi("/datafiles/updateScalars", {
        dataFileId: document._id,
        uops: [
          {
            key: "extractedEEInfo",
            value: updatedExtractedEEInfo,
          },
        ],
      });
      loadDocument(resp);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (document.psUrl !== psUrl) {
      setPsUrl(document.psUrl);
    }
  }, [document.psUrl]);

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <Typography variant="h6" gutterBottom>
        Tag Document Data Types and Employees
      </Typography>
      <Typography variant="body2" color="text.secondary" mb={2}>
        Select data ranges for each employee, and the types of data that are in
        the file.
      </Typography>
      <Stack spacing={2} sx={{ maxHeight: 350, overflowY: "auto", pr: 2 }}>
        {patients.map((patient, index) => (
          <Paper
            key={index}
            elevation={2}
            onClick={() => handlePatientClick(patient, index)}
            sx={{
              p: 2,
              cursor: "pointer",
              transition: "background-color 0.3s",
              "&:hover": {
                bgcolor: "action.hover",
              },
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1} mb={1}>
              <Avatar
                sx={{ bgcolor: colorMap.blue.bg, color: colorMap.blue.text }}
              >
                <Iconify icon="mdi:account" />
              </Avatar>
              <Typography variant="subtitle1">
                {patient.employeeFirstName} {patient.employeeLastName}
              </Typography>
            </Stack>
            <Tooltip title="Click to add data types">
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {patient.employeeDataTypes.map((dt, dtIndex) => (
                  <Chip
                    key={dtIndex}
                    icon={<Iconify icon="mdi:tag" />}
                    label={dt.dataType}
                    size="small"
                  />
                ))}
                {patient.employeeDataTypes.length === 0 && (
                  <Typography variant="body2" color="text.secondary">
                    No document data types added. Click to add.
                  </Typography>
                )}
              </Stack>
            </Tooltip>
          </Paper>
        ))}
      </Stack>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Card
          sx={{
            p: 2,
            m: 1,
            width: "90vw",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              Employee: {selectedPatient?.employeeFirstName}{" "}
              {selectedPatient?.employeeLastName}
            </Typography>
            <IconButton onClick={() => setOpenDrawer(false)}>
              <Iconify icon="mdi:close" />
            </IconButton>
          </Stack>
          <Typography variant="body2" color="text.secondary" mb={2}>
            Select data ranges for this employee
          </Typography>
          <div>
            <DPS3bSelectDataRange
              eeIndex={drawerEEIndex}
              currentTags={patients[drawerEEIndex]?.employeeDataTypes || []}
              updateTags={(employeeDataTypes) =>
                updateTags(drawerEEIndex, employeeDataTypes)
              }
              psUrl={psUrl}
            />
          </div>
        </Card>
      </Drawer>
    </Box>
  );
}
