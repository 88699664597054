import PropTypes from "prop-types";
import { useState, useCallback, useRef } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Portal from "@mui/material/Portal";
import { alpha } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import { Chip } from "@mui/material";
import { cloneDeep } from "lodash";
import axios from "axios";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { WithAPICall } from "src/micro/utils/apiUtil";
import styles from "../../micro/Emails/style.module.css";

import { useBoolean } from "src/hooks/use-boolean";
import { useResponsive } from "src/hooks/use-responsive";

import Editor from "src/components/editor";
import Iconify from "src/components/iconify";

const ZINDEX = 100;

const POSITION = 24;

const VisuallyHiddenInput = styled("input")({
  display: "none",
  overflow: "hidden",
  position: "absolute",
});

function MailCompose({
  onCloseCompose,
  onEmailSent,
  providerEmail,
  apiCallPost,
  visitId,
  // [{name: "", address: "", type: "Employer"}, {name: "", address: "", type: "Provider"}]
  emailTypes,
  emailTemplates,
}) {
  const smUp = useResponsive("up", "sm");

  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [emailTo, setEmailTo] = useState(providerEmail);
  const [subject, setSubject] = useState("");
  const [sendingEmail, setSendingEmail] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("");

  const inputRef = useRef(null);
  const templateEmailEditorRef = useRef(null);

  const fullScreen = useBoolean();

  const handleChangeMessage = useCallback((value) => {
    setMessage(value);
  }, []);

  const handleFileUpload = (event) => {
    const files = event.target.files;
    // this.setState({ uploadedFilesToSend: Array.from(files) });
    setFiles((prevState) => {
      // eslint-disable-next-line no-undef
      let uploadedFilesToSend = cloneDeep(prevState);
      uploadedFilesToSend = uploadedFilesToSend.concat(Array.from(files));
      return uploadedFilesToSend;
    });
  };

  const handleFileRemove = (fileName) => {
    setFiles((prevState) => prevState.filter((f) => f.name !== fileName));
  };

  const handleSendEmail = async () => {
    setSendingEmail(true);
    const to = { name: "", address: emailTo };
    const formData = new FormData();
    formData.append("to", JSON.stringify([to]));
    formData.append("cc", JSON.stringify([]));

    if (selectedTemplate?.length > 0) {
      if (templateEmailEditorRef.current) {
        formData.append("html", templateEmailEditorRef.current.innerHTML);
      }
    } else {
      formData.append("html", message);
    }

    formData.append("subject", subject);
    if (visitId) {
      formData.append("visitId", visitId);
    }
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    try {
      const res = await apiCallPost("/email/sendEmail", formData);
      const newThread = res.newThread;
      newThread.emails = [res.email];
      onEmailSent(newThread);
      setEmailTo("");
      setSubject("");
      setMessage("");
      setFiles([]);
    } catch (error) {
      console.error("could not send email", error);
    }
    setSendingEmail(false);
  };

  const handleTemplateChange = (event) => {
    const selectedTemplateId = event.target.value;
    const template = emailTemplates.find((t) => t._id === selectedTemplateId);
    setSelectedTemplate(selectedTemplateId);
    setMessage(template.html);
  };

  return (
    <Portal>
      {(fullScreen.value || !smUp) && <Backdrop open sx={{ zIndex: ZINDEX }} />}

      <Paper
        sx={{
          right: 0,
          bottom: 0,
          borderRadius: 2,
          display: "flex",
          position: "fixed",
          zIndex: ZINDEX,
          m: `${POSITION}px`,
          overflow: "hidden",
          flexDirection: "column",
          boxShadow: (theme) => theme.customShadows.dropdown,
          ...(fullScreen.value && {
            m: 0,
            right: POSITION / 2,
            bottom: POSITION / 2,
            width: `calc(100% - ${POSITION}px)`,
            height: `calc(100% - ${POSITION}px)`,
          }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            bgcolor: "background.neutral",
            p: (theme) => theme.spacing(1.5, 1, 1.5, 2),
          }}
        >
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            New Message
          </Typography>

          {/* <IconButton onClick={fullScreen.onToggle}>
            <Iconify
              icon={fullScreen ? "eva:collapse-fill" : "eva:expand-fill"}
            />
          </IconButton> */}

          <IconButton onClick={onCloseCompose}>
            <Iconify icon="mingcute:close-line" />
          </IconButton>
        </Stack>
        {emailTypes && emailTypes.length > 0 && (
          <FormControl
            sx={{
              margin: "0.5rem",
            }}
          >
            <InputLabel id="demo-simple-select-label">Email To</InputLabel>
            <Select
              value={emailTo}
              label="Email To"
              onChange={(e) => {
                setEmailTo(e.target.value);
              }}
            >
              {emailTypes.map((data) => (
                <MenuItem value={data.address}>{data.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <InputBase
          placeholder="To"
          sx={{
            px: 2,
            height: 48,
            borderBottom: (theme) =>
              `solid 1px ${alpha(theme.palette.grey[500], 0.08)}`,
          }}
          defaultValue={emailTo}
          onChange={(event) => {
            setEmailTo(event.target.value);
          }}
          value={emailTo}
        />
        <InputBase
          placeholder="Subject"
          sx={{
            px: 2,
            height: 48,
            borderBottom: (theme) =>
              `solid 1px ${alpha(theme.palette.grey[500], 0.08)}`,
          }}
          defaultValue={""}
          value={subject}
          onChange={(event) => {
            setSubject(event.target.value);
          }}
        />
        <Stack spacing={2} flexGrow={1} sx={{ p: 2 }}>
          <Stack direction="row" spacing={2}>
            <Stack
              sx={{
                width: files.length > 0 ? "70%" : "100%",
              }}
            >
              {selectedTemplate?.length > 0 ? (
                <div className={styles.TemplateEmailEditor}>
                  <div
                    dangerouslySetInnerHTML={{ __html: message }}
                    contentEditable
                    ref={templateEmailEditorRef}
                  />
                </div>
              ) : (
                <Editor
                  simple
                  noteId="compose-mail"
                  value={message}
                  onChange={handleChangeMessage}
                  placeholder="Type a message"
                  sx={{
                    "& .ql-editor": {},
                    ...(fullScreen.value && {
                      height: 1,
                      "& .quill": {
                        height: 1,
                      },
                      "& .ql-editor": {
                        maxHeight: "unset",
                      },
                    }),
                  }}
                />
              )}
            </Stack>

            {files.length > 0 && (
              <Stack sx={{ width: "30%" }} direction="column" spacing={1}>
                <Typography variant="caption">Attachments</Typography>
                {Array.from(files).map((file) => (
                  <Chip
                    label={file.name}
                    icon={<Iconify icon="eva:attach-2-fill" />}
                    onDelete={() => {
                      handleFileRemove(file.name);
                    }}
                  />
                ))}
              </Stack>
            )}
          </Stack>

          <Stack direction="row" alignItems="center" spacing={1} flexGrow={1}>
            <Stack direction="row" alignItems="center" flexGrow={1}>
              <IconButton
                onClick={() => {
                  inputRef.current?.click();
                }}
              >
                <Iconify icon="eva:attach-2-fill" />
                <VisuallyHiddenInput
                  type="file"
                  multiple
                  onChange={handleFileUpload}
                  id="email-file-upload"
                  ref={inputRef}
                />
              </IconButton>
            </Stack>
            <FormControl fullWidth>
              <InputLabel id="email-template-select-label">
                Email Template
              </InputLabel>
              <Select
                labelId="email-template-select-label"
                id="email-template-select"
                value={selectedTemplate}
                onChange={handleTemplateChange}
                label="Email Template"
              >
                {emailTemplates.map((template) => (
                  <MenuItem key={template._id} value={template._id}>
                    {template.templateName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <LoadingButton
              variant="contained"
              color="primary"
              endIcon={<Iconify icon="iconamoon:send-fill" />}
              loading={sendingEmail}
              onClick={handleSendEmail}
            >
              Send
            </LoadingButton>
          </Stack>
        </Stack>
      </Paper>
    </Portal>
  );
}

export default WithAPICall(MailCompose);

MailCompose.propTypes = {
  onCloseCompose: PropTypes.string,
  providerEmail: PropTypes.string,
  onEmailSent: PropTypes.func,
};
