import React, { useState, useEffect } from "react";
import {
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
  Container,
  FormControl,
  InputLabel,
  Card,
} from "@mui/material";
import _ from "lodash";

const DRUG_CLEARANCE_OPTIONS = [
  { key: "MRO_NEGATIVE", value: "MRO negative" },
  { key: "MRO_NEGATIVE_DILUTE", value: "MRO negative, dilute" },
  { key: "MRO_POSITIVE", value: "MRO positive for ____" },
  { key: "MRO_POSITIVE_DILUTE", value: "MRO positive for ____, dilute" },
  {
    key: "MRO_POSITIVE_UNREACHABLE",
    value: "MRO positive for ____ Unable to reach donor to conduct interview.",
  },
  {
    key: "MRO_POSITIVE_DILUTE_UNREACHABLE",
    value:
      "MRO positive for ____, dilute. Unable to reach donor to conduct interview.",
  },
  {
    key: "MRO_TEST_CANCELED_IMMEDIATE_RECOLLECTION",
    value:
      "MRO test canceled. Immediate recollection with direct observation required.",
  },
  {
    key: "MRO_TEST_CANCELED_IMMEDIATE_RECOLLECTION_RECOMMENDED",
    value:
      "MRO test canceled. Immediate recollection with direct observation recommended where permitted by law.",
  },
  {
    key: "MRO_TEST_CANCELED_NO_FURTHER_ACTION",
    value:
      "MRO test canceled. No further action is necessary unless a negative result is required.",
  },
  {
    key: "MRO_TEST_CANCELED_FATAL_FLAW",
    value: "MRO test canceled. Fatal flaw.",
  },
  {
    key: "MRO_TEST_CANCELED_SPECIMEN_REJECTED",
    value: "MRO test canceled. Specimen rejected by lab.",
  },
  {
    key: "MRO_REFUSAL_TO_TEST_SUBSTITUTION",
    value: "MRO refusal to test: substitution.",
  },
  {
    key: "MRO_REFUSAL_TO_TEST_ADULTERATION",
    value: "MRO refusal to test: adulteration.",
  },
  { key: "MRO_REFUSAL_TO_TEST", value: "MRO refusal to test" },
  {
    key: "COLLECTION_SITE_EVENT_FINAL_DETERMINATION",
    value: "Collection site event. Final determination per DER.",
  },
  {
    key: "COLLECTED_UNDER_DIRECT_OBSERVATION",
    value: "Collected under direct observation",
  },
  { key: "SPLIT_SPECIMEN_RECONFIRMED", value: "Split specimen reconfirmed" },
];

const getStdMROOpinion = (groups, service) => {
  const g = _.find(
    groups,
    (g) => g.pageDataType === "LAB_REPORT" && g.service._id === service._id
  );
  if (!g) return "";
  const hasAllNegative = _.every(g.dataStructured, (d) => d.isNormal == true);
  if (hasAllNegative) return "MRO_NEGATIVE";
  return "";
};

const DrugScreenClearer = ({
  visit,
  protocol,
  service,
  groups,
  onClearanceChange,
}) => {
  const savedCO = visit.drugAlcoholClearance[service._id];

  const [selectedMROOpinion, setSelectedMROOpinion] = useState(
    _.get(
      savedCO,
      "selections.selectedMROOpinion",
      getStdMROOpinion(groups, service)
    )
  );
  const [finalClearanceText, setFinalClearanceText] = useState(
    _.get(savedCO, "finalClearanceText", "")
  );

  useEffect(() => {
    let prelim = "";
    for (let i = 0; i < DRUG_CLEARANCE_OPTIONS.length; i++) {
      if (selectedMROOpinion === DRUG_CLEARANCE_OPTIONS[i].key) {
        prelim = DRUG_CLEARANCE_OPTIONS[i].value;
        break;
      }
    }
    setFinalClearanceText(prelim);
  }, [selectedMROOpinion]);

  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="body2">
        Drug Screen Clearance: {service.serviceName}
      </Typography>
      <FormControl margin="normal" size="small">
        <InputLabel id="select-major-opinion">Determination</InputLabel>
        <Select
          labelId="select-mro-opinion"
          value={selectedMROOpinion}
          onChange={(e) => setSelectedMROOpinion(e.target.value)}
          label="Determination"
          disabled={false}
          autoWidth
        >
          {DRUG_CLEARANCE_OPTIONS.map((option, i) => (
            <MenuItem key={i} value={option.key}>
              {option.value}
            </MenuItem>
          ))}
        </Select>
        <Button
          variant="contained"
          onClick={() => {
            onClearanceChange(
              finalClearanceText,
              selectedMROOpinion,
              selectedMROOpinion,
              {
                finalClearanceText,
                selectedMROOpinion,
              }
            );
          }}
        >
          Save & Update Clearance
        </Button>
      </FormControl>
    </Card>
  );
};

export default DrugScreenClearer;
