import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Alert,
  FormControlLabel,
  LinearProgress,
  TextField,
  Typography,
  Switch,
  Select,
  MenuItem,
  Card,
  Popover,
  Chip,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import cogoToast from "cogo-toast";
import _ from "lodash";
import { DayPicker } from "react-day-picker";
import { niceDate } from "../utils/fn";

class EmployeeCSFNLDataField extends React.Component {
  state = {
    isError: false,
    isLoading: true,
    isSaving: false,
    searchInput: "",
    searchResults: [],
    selectedResult: null,
    csfData: null,
    esfObject: {},
    isChanged: false,
  };
  componentDidMount() {
    let keys = this.props.data.fieldDataFields.map(
      (each) => each.fieldDataFieldName
    );
    let initialObject = {};
    keys.forEach((each) => {
      initialObject[each] = this.props.employeeData.esfObject
        ? this.props.employeeData.esfObject[this.props.data.fieldName]
          ? this.props.employeeData.esfObject[this.props.data.fieldName][each]
          : null
        : null;
    });
    this.setState({
      esfObject: initialObject,
      isLoading: false,
    });
  }
  renderTextField = (fdf) => {
    if (this.props.fromTransferPage && !this.props.allowEdit) {
      return (
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          {" "}
          <Typography variant="body2">{fdf.fieldDataFieldName}: </Typography>
          <Typography variant="body3">
            {this.state.esfObject[fdf.fieldDataFieldName]}
          </Typography>
        </Stack>
      );
    }
    return (
      <FormControlLabel
        size={this.props.fromTransferPage ? "small" : "medium"}
        label={fdf.fieldDataFieldName}
        labelPlacement="start"
        control={
          <TextField
            sx={{
              ml: 2,
            }}
            disabled={this.state.isSaving}
            label={this.props.fromTransferPage ? "" : fdf.fieldDataFieldName}
            size={this.props.fromTransferPage ? "small" : "medium"}
            value={this.state.esfObject[fdf.fieldDataFieldName]}
            onChange={(e) => {
              let val = e.target.value;
              let obj = this.state.esfObject;
              obj[fdf.fieldDataFieldName] = val;
              this.setState({
                esfObject: obj,
              });
            }}
            onBlur={() => {
              this.saveESFData(
                fdf.fieldDataFieldName,
                this.state.esfObject[fdf.fieldDataFieldName]
              );
            }}
          />
        }
        sx={{
          m: 0,
          width: 1,
          justifyContent: "start",
        }}
      />
    );
  };
  renderNumberField = (fdf) => {
    if (this.props.fromTransferPage && !this.props.allowEdit) {
      return (
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          {" "}
          <Typography variant="body2">{fdf.fieldDataFieldName}: </Typography>
          <Typography variant="body3">
            {this.state.esfObject[fdf.fieldDataFieldName]}
          </Typography>
        </Stack>
      );
    }
    return (
      <FormControlLabel
        label={fdf.fieldDataFieldName}
        size={this.props.fromTransferPage ? "small" : "medium"}
        labelPlacement="start"
        control={
          <TextField
            sx={{
              ml: 2,
            }}
            disabled={this.state.isSaving}
            label={this.props.fromTransferPage ? "" : fdf.fieldDataFieldName}
            size={this.props.fromTransferPage ? "small" : "medium"}
            value={this.state.esfObject[fdf.fieldDataFieldName]}
            onChange={(e) => {
              let val = e.target.value;
              let obj = this.state.esfObject;
              obj[fdf.fieldDataFieldName] = Number(val);
              this.setState({
                esfObject: obj,
              });
            }}
            onBlur={() => {
              this.saveESFData(
                fdf.fieldDataFieldName,
                this.state.esfObject[fdf.fieldDataFieldName]
              );
            }}
          />
        }
        sx={{
          m: 0,
          width: 1,
          justifyContent: "start",
        }}
      />
    );
  };
  renderBooleanField = (fdf) => {
    if (this.props.fromTransferPage && !this.props.allowEdit) {
      let val = this.state.esfObject[fdf.fieldDataFieldName];
      let txt = val ? "Yes" : "No";
      return (
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          {" "}
          <Typography variant="body2">{fdf.fieldDataFieldName}: </Typography>
          <Typography variant="body3">{txt}</Typography>
        </Stack>
      );
    }
    return (
      <FormControlLabel
        label={fdf.fieldDataFieldName}
        labelPlacement="start"
        control={
          <Switch
            size={this.props.fromTransferPage ? "small" : "medium"}
            sx={{
              ml: 2,
            }}
            disabled={this.state.isSaving}
            checked={this.state.esfObject[fdf.fieldDataFieldName]}
            onChange={() => {
              let currentVal = this.state.esfObject[fdf.fieldDataFieldName];
              let val = currentVal ? false : true;
              let obj = this.state.esfObject;
              obj[fdf.fieldDataFieldName] = val;
              this.setState(
                {
                  esfObject: obj,
                },
                () => {
                  this.saveESFData(fdf.fieldDataFieldName, val);
                }
              );
            }}
          />
        }
        sx={{
          m: 0,
          width: 1,
          justifyContent: "start",
        }}
      />
    );
  };
  renderDateField = (fdf) => {
    if (this.props.fromTransferPage && !this.props.allowEdit) {
      let val = this.state.esfObject[fdf.fieldDataFieldName];
      let txt = val ? niceDate(val) : "Not Entered";
      return (
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          {" "}
          <Typography variant="body2">{fdf.fieldDataFieldName}: </Typography>
          <Typography variant="body3">{txt}</Typography>
        </Stack>
      );
    }
    return (
      <FormControlLabel
        label={fdf.fieldDataFieldName}
        labelPlacement="start"
        disabled={this.state.isSaving}
        control={
          <>
            <Chip
              size={this.props.fromTransferPage ? "small" : "medium"}
              variant="contained"
              onClick={(e) => {
                this.setState({
                  anchorEl: e.currentTarget,
                  [`open_${fdf.fieldDataFieldName}`]: true,
                });
              }}
              label={
                this.state.esfObject[fdf.fieldDataFieldName]
                  ? niceDate(this.state.esfObject[fdf.fieldDataFieldName])
                  : "Not Entered"
              }
            />
            <Popover
              open={this.state[`open_${fdf.fieldDataFieldName}`]}
              anchorEl={this.state.anchorEl}
              onClose={() => {
                this.setState({
                  anchorEl: null,
                  [`open_${fdf.fieldDataFieldName}`]: false,
                });
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <DayPicker
                sx={{
                  ml: 2,
                }}
                disabled={this.state.isSaving}
                selected={
                  new Date(this.state.esfObject[fdf.fieldDataFieldName])
                }
                mode="single"
                captionLayout="dropdown-buttons"
                fromYear={1901}
                toYear={2044}
                onSelect={(e) => {
                  let obj = this.state.esfObject;
                  obj[fdf.fieldDataFieldName] = e;
                  this.setState(
                    {
                      esfObject: obj,
                      [`open_${fdf.fieldDataFieldName}`]: false,
                    },
                    () => {
                      this.saveESFData(fdf.fieldDataFieldName, e);
                    }
                  );
                }}
              />
            </Popover>
          </>
        }
        sx={{
          m: 0,
          width: 1,
          justifyContent: "start",
        }}
      />
    );
  };
  renderArrayField = (fdf) => {
    if (this.props.fromTransferPage && !this.props.allowEdit) {
      let val = this.state.esfObject[fdf.fieldDataFieldName];
      return (
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          {" "}
          <Typography variant="body2">{fdf.fieldDataFieldName}: </Typography>
          <Typography variant="body3">{val}</Typography>
        </Stack>
      );
    }
    let options = fdf.listOfOptions.split(",").map((x) => _.trim(x));
    return (
      <FormControlLabel
        label={fdf.fieldDataFieldName}
        labelPlacement="start"
        size={this.props.fromTransferPage ? "small" : "medium"}
        control={
          <Select
            sx={{
              ml: 2,
            }}
            size={this.props.fromTransferPage ? "small" : "medium"}
            disabled={this.state.isSaving}
            value={this.state.esfObject[fdf.fieldDataFieldName]}
            onChange={(e) => {
              let val = e.target.value;
              let obj = this.state.esfObject;
              obj[fdf.fieldDataFieldName] = val;
              this.setState(
                {
                  esfObject: obj,
                },
                () => {
                  this.saveESFData(fdf.fieldDataFieldName, val);
                }
              );
            }}
          >
            {options.map((each, i) => {
              return (
                <MenuItem value={each} key={i}>
                  {each}
                </MenuItem>
              );
            })}
          </Select>
        }
        sx={{
          m: 0,
          width: 1,
          justifyContent: "start",
        }}
      />
    );
  };
  saveESFData = async (key, val) => {
    if (this.props.fromTransferPage) {
      this.props.onSetESF(key, val);
      return;
    }
    try {
      this.setState({
        isSaving: true,
      });
      let res = await this.props.apiCallPost("/employee/updateScalarValue", {
        employeeId: this.props.employeeId,
        key: key,
        value: val,
        typeOfValue: "ESF",
        csfId: this.props.data._id,
      });
      this.setState({
        esfObject: res.esfObject[this.props.data.fieldName],
        isSaving: false,
        isChanged: false,
      });
    } catch (err) {
      this.setState(
        {
          isSaving: false,
          isLoading: false,
        },
        () => {
          let esfObject = this.state.esfObject;
          esfObject[key] = this.props.employeeData.esfObject
            ? this.props.employeeData.esfObject[this.props.data.fieldName]
              ? this.props.employeeData.esfObject[this.props.data.fieldName][
                  key
                ]
              : null
            : null;
          this.setState({
            esfObject: esfObject,
          });
        }
      );
      cogoToast.error("Error Updating Employee Data");
      console.log(err);
    }
  };
  renderFDFs = () => {
    let fdfs = this.props.data.fieldDataFields;
    if (!fdfs) return null;
    if (!fdfs.length) return null;
    return fdfs.map((fdf, i) => {
      if (fdf.dataType === "string") {
        return this.renderTextField(fdf);
      }
      if (fdf.dataType === "number") {
        return this.renderNumberField(fdf);
      }
      if (fdf.dataType === "boolean") {
        return this.renderBooleanField(fdf);
      }
      if (fdf.dataType === "date") {
        return this.renderDateField(fdf);
      }
      if (fdf.dataType === "array") {
        return this.renderArrayField(fdf);
      }
    });
  };
  renderStructure = () => {
    if (this.props.fromTransferPage && !this.props.allowEdit) {
      return (
        <Stack>
          <Typography variant="overline">
            {this.props.data.fieldName}
          </Typography>
          {this.renderFDFs()}
        </Stack>
      );
    }
    if (this.props.fromTransferPage && this.props.allowEdit) {
      return (
        <Stack>
          <Typography variant="overline">
            {this.props.data.fieldName}
          </Typography>
          {this.renderFDFs()}
        </Stack>
      );
    }
    return (
      <div>
        <Card>
          <Stack
            spacing={3}
            sx={{
              mt: 2,
              mb: 2,
              p: 2,
              borderRadius: 2,
              bgcolor: "background.paper",
            }}
          >
            <Box
              rowGap={1}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
              }}
            >
              <Typography variant="h6">{this.props.data.fieldName}</Typography>
            </Box>
            {this.renderFDFs()}
          </Stack>
        </Card>
      </div>
    );
  };
  render() {
    if (!this.props.data) return null;
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <Alert severity="error">Error Loading Data</Alert>;
    }
    return this.renderStructure();
  }
}

export default WithAPICall(EmployeeCSFNLDataField);
