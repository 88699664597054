import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import { Alert, LinearProgress, TextField, Typography } from "@mui/material";
import { View500 } from "src/sections/error";
import cogoToast from "cogo-toast";
import { Box, Stack } from "@mui/system";
import _ from "lodash";
import EmployeeCSFDataField from "./EmployeeCSFDataField";
import EmployeeCSFNLDataField from "./EmployeeCSFNLDataField";

class EmployeeSpecificFields extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    csf: [],
    fieldsForEmployees: [],
  };
  componentDidMount() {
    this.getCSF();
  }
  getCSF = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost("/company/csf/get", {
        companyId: this.props.companyId,
      });
      let nonLookUpCSFs = _.filter(res, (each) => !each.needsLookUp);
      let lookUpCSFs = _.filter(res, (each) => each.needsLookUp);
      this.setState({
        csf: res,
        nonLookUpCSFs: nonLookUpCSFs,
        lookUpCSFs: lookUpCSFs,
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Employee Specific Fields");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  searchLookUpCSF = async (csfId, query) => {
    let res = this.props.apiCallPostCancellable(
      "/company/csf/searchLookUpCSF",
      {
        csfId: csfId,
        query: query,
      }
    );
  };
  massageCSF = () => {
    let csf = this.state.csf;
    let fieldsForEmployees = [];
    for (let i = 0; i < csf.length; i++) {
      let c = csf[i];
      if (!c.needsLookUp) {
        fieldsForEmployees.push({
          name: c.fieldName,
          helper: "",
          value: "",
        });
      } else {
        let f = c.fieldDataFields;
        for (let j = 0; j < f.length; j++) {
          let ff = f[j];
          if (ff.isLookUpValue) {
            fieldsForEmployees.push({
              name: ff.fieldDataFieldName,
              helper: c.fieldName,
              value: "",
            });
            break;
          }
        }
      }
    }
    this.setState({
      fieldsForEmployees: fieldsForEmployees,
      isLoading: false,
    });
  };
  renderLookUpCSFValues = () => {
    if (!this.state.lookUpCSFs)
      return <Alert severity="info">No fields added.</Alert>;
    if (!this.state.lookUpCSFs.length)
      return <Alert severity="info">No fields added.</Alert>;
    let lcsfs = this.state.lookUpCSFs;
    return lcsfs.map((lcsf, i) => {
      return (
        <Stack spacing={3} key={i}>
          <EmployeeCSFDataField
            employeeData={this.props.data}
            data={lcsf}
            companyId={this.props.companyId}
            employeeId={this.props.id}
          />
        </Stack>
      );
    });
  };

  renderNonLookUpCSFValues = () => {
    if (!this.state.nonLookUpCSFs)
      return <Alert severity="info">No fields added.</Alert>;
    if (!this.state.nonLookUpCSFs.length)
      return <Alert severity="info">No fields added.</Alert>;
    let nlcsfs = this.state.nonLookUpCSFs;
    return nlcsfs.map((nlcsf, i) => {
      return (
        <Stack spacing={3} key={i}>
          <EmployeeCSFNLDataField
            data={nlcsf}
            employeeData={this.props.data}
            companyId={this.props.companyId}
            employeeId={this.props.id}
          />
        </Stack>
      );
    });
  };
  updateScalarValue = async (key, value) => {
    try {
      await this.props.apiCallPost("/employee/updateScalarValue", {
        employeeId: this.props.id,
        key: key,
        value: value,
      });
    } catch (err) {
      cogoToast.error("Error Updating Employee Data");
      console.log(err);
    }
  };
  renderInputs = () => {
    let f = this.state.fieldsForEmployees;
    return (
      <Stack space={3}>
        {f.map((each, i) => {
          return (
            <TextField
              sx={{
                mt: 3,
              }}
              key={i}
              label={each.name}
              helperText={`Part of ${each.helper}`}
              value={each.value}
              onChange={(e) => {
                let fieldsForEmployees = this.state.fieldsForEmployees;
                fieldsForEmployees[i].value = e.target.value;
                this.setState(
                  { fieldsForEmployees: fieldsForEmployees },
                  () => {
                    this.updateScalarValue(each.name, e.target.value);
                  }
                );
              }}
            />
          );
        })}
      </Stack>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <Stack space={4}>
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
          }}
        >
          <Typography variant="h5">Employee Specific Fields</Typography>{" "}
          <Typography variant="h6">Look Up Fields</Typography>
          {this.renderLookUpCSFValues()}{" "}
          <Typography variant="h6">Manually Entered Fields</Typography>
          {this.renderNonLookUpCSFValues()}
        </Box>
      </Stack>
    );
  }
}

export default WithAPICall(EmployeeSpecificFields);
