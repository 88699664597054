import { Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Collapse from "@mui/material/Collapse";
import Link from "@mui/material/Link";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { format } from "date-fns";
import PropTypes from "prop-types";
import EmptyContent from "src/components/empty-content";
import FileThumbnail from "src/components/file-thumbnail";
import Iconify from "src/components/iconify";
import Scrollbar from "src/components/scrollbar";
import { useBoolean } from "src/hooks/use-boolean";

// ----------------------------------------------------------------------

export default function MailDetails({ mail, onProcessDocument, mailIndex }) {
  const showAttachments = useBoolean(true);

  if (!mail) {
    return (
      <EmptyContent
        title="No Conversation Selected"
        description="Select a conversation to read"
        imgUrl="/assets/icons/empty/ic_email_selected.svg"
        sx={{
          borderRadius: 1.5,
          bgcolor: "background.default",
        }}
      />
    );
  }

  const renderSender = (
    <Stack
      flexShrink={0}
      direction="row"
      alignItems="center"
      sx={{
        p: (theme) => theme.spacing(2, 2, 1, 2),
      }}
    >
      <Avatar
        alt={mail.from.name}
        src={mail.from.avatarUrl ? `${mail.from.avatarUrl}` : ""}
        sx={{ mr: 2 }}
      >
        {mail.from.name.charAt(0).toUpperCase()}
      </Avatar>

      <ListItemText
        primary={
          <>
            {mail.from.name}
            <Box
              component="span"
              sx={{ typography: "body2", color: "text.disabled" }}
            >
              {` <${mail.from.address}>`}
            </Box>
          </>
        }
        secondary={
          <>
            {`To: `}
            {mail.to.map((person) => (
              <Link key={person} sx={{ color: "text.secondary" }}>
                {`${person.address}`}
              </Link>
            ))}
          </>
        }
        secondaryTypographyProps={{
          mt: 0.5,
          noWrap: true,
          component: "span",
          typography: "caption",
        }}
      />

      <Typography variant="caption">
        {format(new Date(mail.createdAt), "PPPppp")}
      </Typography>
    </Stack>
  );

  const renderAttachments = (
    <Stack
      spacing={1}
      sx={{
        p: 1,
        borderRadius: 1,
        bgcolor: "background.neutral",
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <ButtonBase
          onClick={showAttachments.onToggle}
          sx={{
            color: "text.secondary",
            typography: "caption",
            borderRadius: 0.5,
          }}
        >
          <Iconify icon="eva:attach-2-fill" sx={{ mr: 0.5 }} />
          {mail.attachments.length}{" "}
          {mail.attachments.length > 1 ? "attachments" : "attachment"}
          <Iconify
            icon={
              showAttachments.value
                ? "eva:arrow-ios-upward-fill"
                : "eva:arrow-ios-downward-fill"
            }
            width={16}
            sx={{ ml: 0.5 }}
          />
        </ButtonBase>
      </Stack>

      <Collapse in={showAttachments.value} unmountOnExit timeout="auto">
        <Stack direction="row" flexWrap="wrap" spacing={1}>
          {mail.attachments.map((attachment, attachmentIndex) => (
            <Stack
              key={attachment.id}
              alignItems="center"
              justifyContent="center"
              sx={{
                // width: 40,
                // height: 40,
                flexShrink: 0,
                borderRadius: 1,
                overflow: "hidden",
                position: "relative",
                backgroundColor: "background.neutral",
              }}
            >
              <FileThumbnail
                tooltip
                imageView
                file={attachment}
                onDownload={() => {}}
                onProcessDocument={async () => {
                  return await onProcessDocument({
                    key: attachment.s3ObjectUrl,
                    name: attachment.name,
                    size: attachment.size,
                    contentType: attachment.type,
                    attachmentIndex,
                    mailIndex,
                  });
                }}
              />
            </Stack>
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );

  const renderContent = (
    <Box
      sx={{
        py: 3,
        flexGrow: 1,
        overflow: { xs: "auto", md: "hidden" },
      }}
    >
      <Scrollbar>
        <div
          style={{
            fontSize: "1rem !important",
            padding: "1rem",
          }}
          dangerouslySetInnerHTML={{ __html: mail.html }}
        ></div>
        {/* <Markdown
          children={mail.message}
          sx={{
            px: 2,
            "& p": {
              typography: "body2",
            },
          }}
        /> */}
      </Scrollbar>
    </Box>
  );

  return (
    <Stack
      flexGrow={1}
      sx={{
        width: 1,
        minWidth: 0,
        borderRadius: 1.5,
        bgcolor: "background.default",
      }}
    >
      {renderSender}
      {renderContent}

      {!!mail.attachments.length && (
        <Stack sx={{ px: 2 }}> {renderAttachments} </Stack>
      )}
    </Stack>
  );
}

MailDetails.propTypes = {
  mail: PropTypes.object,
  renderLabel: PropTypes.func,
};
