import { useParams } from "src/routes/hooks";
import FormFoxAuthorizationLetter from "./FormFoxAuthorizationLetter";

export default function FormFoxAuthorizationLetterById() {
  const params = useParams();
  const { id } = params;
  return (
    <>
      <FormFoxAuthorizationLetter id={id} />
    </>
  );
}
