import PropTypes from "prop-types";
import { format, formatDistanceToNowStrict } from "date-fns";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import { Chip, Tooltip } from "@mui/material";
import { truncate } from "lodash";
import Iconify from "src/components/iconify/iconify";

// ----------------------------------------------------------------------

export default function MailItem({
  mail,
  selected,
  assignedTo,
  client,
  sx,
  lastEmailDate,
  seen,
  ...other
}) {
  return (
    <ListItemButton
      sx={{
        p: 1,
        mb: 0.5,
        borderRadius: 1,
        bgcolor: selected ? "#94e6ff" : seen ? "" : "action.selected",
        ...sx,
      }}
      {...other}
    >
      <Stack direction="column" flexGrow={1}>
        <Stack direction="row">
          <Avatar src={""} sx={{ mr: 2 }}>
            {assignedTo?.charAt(0).toUpperCase() || "NA"}
          </Avatar>
          <Stack direction="column" sx={{ width: "100%" }}>
            {/* <ListItemText
              primary={truncate(mail.subject)}
              primaryTypographyProps={{
                noWrap: true,
                variant: "subtitle2",
              }}
              secondary={lastEmailDate}
              secondaryTypographyProps={{
                noWrap: true,
                component: "span",
                variant: mail.isUnread ? "subtitle2" : "body2",
                color: mail.isUnread ? "text.primary" : "text.secondary",
              }}
            /> */}
            <Stack
              direction="row"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle2" sx={{ width: "70%" }}>
                {truncate(mail.subject)}
              </Typography>
              <Stack direction="column" sx={{ width: "30%" }}>
                <Typography variant="caption">
                  {format(new Date(lastEmailDate), "PP")}
                </Typography>
                <Typography variant="caption">
                  {format(new Date(lastEmailDate), "pp")}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Chip
                label={assignedTo ? assignedTo : "No user assigned"}
                size="small"
                variant={assignedTo ? "filled" : "outlined"}
                color={assignedTo ? "primary" : undefined}
                icon={
                  assignedTo ? (
                    <Iconify icon="material-symbols:person" />
                  ) : (
                    <></>
                  )
                }
              />
              {!seen && (
                <Tooltip title="New Emails">
                  <Iconify icon="material-symbols:mail" />
                </Tooltip>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <>
        {/* <Stack alignItems="flex-end" sx={{ ml: 2, height: 44 }}>
          <Typography
            noWrap
            variant="h6"
            component="span"
            sx={{
              mb: 1.5,
              fontSize: 12,
              color: "text.disabled",
            }}
          ></Typography>

          {!!mail.isUnread && (
            <Box
              sx={{
                bgcolor: "info.main",
                width: 8,
                height: 8,
                borderRadius: "50%",
              }}
            />
          )}
        </Stack> */}
      </>
    </ListItemButton>
  );
}

MailItem.propTypes = {
  mail: PropTypes.object,
  selected: PropTypes.bool,
  sx: PropTypes.object,
  assignedTo: PropTypes.object,
  client: PropTypes.object,
  lastEmailDate: PropTypes.string,
  seen: PropTypes.bool,
};
