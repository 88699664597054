import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { WithAPICall } from "./apiUtil";
import cogoToast from "cogo-toast";
import { LoadingButton } from "@mui/lab";

class ContactCreate extends React.Component {
  state = {
    contactName: "",
    isCreating: false,
  };
  createFromName = async () => {
    this.setState({ isCreating: true });
    try {
      if (this.props.creatingWhat) {
        cogoToast.loading("Creating Employee");
      } else {
        cogoToast.loading("Creating Contact");
      }
      let res;
      if (this.props.creatingWhat) {
        res = await this.props.apiCallPost(this.props.createApiUrl, {
          employeeName: this.state.contactName,
          companyId: this.props.entityId,
        });
        this.props.onCreate(res);
        cogoToast.success("Employee Created");
      } else {
        res = await this.props.apiCallPost(this.props.createApiUrl, {
          contactName: this.state.contactName,
          contactType: "General Contact",
          entityType: this.props.entityType,
          entityId: this.props.entityId,
        });
        this.props.onCreate(res);
        cogoToast.success("Contact Created");
      }
    } catch (err) {
      cogoToast.error("We ran into an error.");
      console.log(err);
    }
    this.setState({ isCreating: false });
  };
  render() {
    return (
      <Dialog open={this.props.isOpen} onClose={() => this.props.handleClose()}>
        <DialogTitle>
          Create A New {this.props.creatingWhat ? "Employee" : "Contact"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the name of the{" "}
            {this.props.creatingWhat ? "employee" : "contact"} to get started.{" "}
            <br />
            <br />
            <br />
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label={this.props.creatingWhat ? "Employee Name" : "Contact Name"}
            fullWidth
            disabled={this.state.isCreating}
            value={this.state.contactName}
            onChange={(e) => {
              this.setState({ contactName: e.target.value });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.handleClose()}
            disabled={this.state.isCreating}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={this.state.isCreating}
            variant="contained"
            disabled={!this.state.contactName}
            onClick={() => this.createFromName()}
          >
            Create {this.props.creatingWhat ? "Employee" : "Contact"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}
export default WithAPICall(ContactCreate);
