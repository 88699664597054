import { Container } from "@mui/system";
import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import cogoToast from "cogo-toast";
import { LoadingScreen } from "src/components/loading-screen";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, Typography } from "@mui/material";
import Iconify from "src/components/iconify/iconify";
import { format } from "date-fns";

class EmployeeVisits extends React.Component {
  state = {
    isLoading: false,
    visits: [],
  };

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    this.setState({
      isLoading: true,
    });
    try {
      const data = await this.props.apiCallPost("/visit/getVisitByEmployeeId", {
        employeeId: this.props.id,
      });
      this.setState({ visits: data });
    } catch (error) {
      cogoToast.error("Could not get employee visits. Please try again.");
      console.error("error in getting employee visits", error);
    }
    this.setState({
      isLoading: false,
    });
  }
  render() {
    if (this.state.isLoading) {
      return <LoadingScreen />;
    }
    return (
      <Container maxWidth={10000}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Visit</TableCell>
              <TableCell>Visit Status</TableCell>
              <TableCell>Visit Outcome</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.visits.map((row) => (
              <TableRow
                key={row._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Button
                    fullWidth
                    href={`/visits/${row._id}`}
                    // target="non_blank"
                    variant="outlined"
                    startIcon={<Iconify icon="mingcute:external-link-line" />}
                  >
                    Visit ID: {row._id}
                  </Button>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">{row.visitStatus}</Typography>{" "}
                </TableCell>
                <TableCell>
                  <Typography variant="caption">{row.visitOutcome}</Typography>
                </TableCell>
                <TableCell>{format(new Date(row.createdAt), "PPpp")}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Container>
    );
  }
}

export default WithAPICall(EmployeeVisits);
