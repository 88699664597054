import { BarChart, Card } from "@tremor/react";
import React from "react";
import _ from "lodash";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import {
  FILTER_UTILS,
  sumAggregation,
  averageAggregation,
  maxAggregation,
  minAggregation,
  countAggregation,
} from "../../utils/grid-utils";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
class DataEntryReviewUserReport extends React.Component {
  state = {
    data: [],
    gridApi: null,
    columnApi: null,
    aggregations: {
      sum: (params) => sumAggregation(params),
      average: (params) => averageAggregation(params),
      max: (params) => maxAggregation(params),
      min: (params) => minAggregation(params),
      count: (params) => countAggregation(params),
    },

    // selectedMode: "user" | "date"
    selectedMode: null,
    selectedUserId: null,
    selectedDate: null,
    userIdToName: {},
  };

  componentDidMount() {
    const userIdToName = {};
    for (let i = 0; i < this.props.data.length; i++) {
      let each = this.props.data[i];
      userIdToName[each.createdBy._id] = each.createdBy.name;
    }
    this.setState({
      userIdToName,
    });
  }

  /******** GRID BEGINS */
  /** COLUMNS */
  COLUMNS = [
    {
      headerName: "Reviewer",
      field: "createdBy.name",
      cellDataType: "text",
    },

    {
      headerName: "Date",
      field: "createdAt",
      cellDataType: "text",
      cellRenderer: (row) => {
        return new Date(row.value).toLocaleDateString("en-US");
      },
    },
    {
      headerName: "Time",
      field: "createdAt",
      cellDataType: "text",
      cellRenderer: (row) => {
        return new Date(row.value).toLocaleTimeString("en-US");
      },
    },
    {
      headerName: "Document Type",
      field: "studyEntitySubType",
      cellDataType: "text",
    },
    {
      headerName: "Time Taken",
      field: "secondsElapsed",
      cellDataType: "text",
      cellRenderer: (row) => {
        return `${row.value} seconds`;
      },
    },
  ];

  onGridReady = (params) => {
    this.setState({ gridApi: params.api, columnApi: params.columnApi });
  };
  /** RENDER GRID */
  renderGrid = () => {
    let cs = "ag-theme-quartz";
    let theme = localStorage.getItem("themeMode");
    if (theme && theme === "dark") {
      cs = "ag-theme-quartz-dark";
    }
    return (
      <div className={cs} style={{ height: "70vh" }}>
        <AgGridReact
          onGridReady={this.onGridReady}
          rowData={this.props.data}
          columnDefs={this.COLUMNS.map((x) => {
            return {
              ...x,
              ...FILTER_UTILS[x.cellDataType],
            };
          })}
          defaultColDef={{
            sortable: true,
          }}
          autoSizeStrategy={{
            type: "fitCellContents",
          }}
          pagination={true}
          rowGroupPanelShow={"always"}
          aggFuncs={this.state.aggregations}
          suppressDragLeaveHidesColumns={true}
          rowSelection="multiple"
          rowDragManaged={true}
          enableCharts={true}
          enableRangeSelection={true}
          enableCellTextSelection={true}
          sideBar={true}
          animateRows={true}
          statusBar={{
            statusPanels: [
              {
                statusPanel: "agTotalAndFilteredRowCountComponent",
                align: "left",
              },
              {
                statusPanel: "agSelectedRowCountComponent",
                align: "left",
              },
              {
                statusPanel: "agAggregationComponent",
                align: "right",
              },
            ],
          }}
          defaultExcelExportParams={{
            author: "WorkCare",
            fileName: `${this.props.reportType} - ${new Date().toLocaleDateString()}`,
            sheetName: `${this.props.reportType} - ${new Date().toLocaleDateString()}`,
          }}
        />
      </div>
    );
  };
  /******** GRID ENDS */
  /*********** CHART BEGINS */
  classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };
  dataMassager = () => {
    let data = this.props.data;
    let categories = _.uniq(data.map((each) => each.createdBy._id));
    let sortedByTime = _.sortBy(data, "createdAt");
    let mappedToDateString = sortedByTime.map((each) => {
      let date = new Date(each.createdAt);
      return {
        ...each,
        date: date.toLocaleDateString("en-US"),
      };
    });
    let groupedByDate = _.groupBy(mappedToDateString, "date");
    let xAxisDates = Object.keys(groupedByDate);
    let massagedData = [];
    for (let i = 0; i < xAxisDates.length; i++) {
      let eachDate = xAxisDates[i];
      let obj = {
        date: eachDate,
      };
      for (let j = 0; j < categories.length; j++) {
        obj[categories[j]] = 0;
      }
      let eachDateData = groupedByDate[eachDate];
      for (let j = 0; j < eachDateData.length; j++) {
        let eachRow = eachDateData[j];
        obj[eachRow.createdBy._id] += 1;
      }
      massagedData.push(obj);
    }
    return massagedData;
  };
  getDataByUser = () => {
    let data = this.props.data.filter(
      (d) => d.createdBy._id === this.state.selectedUserId
    );
    let categories = _.uniq(data.map((each) => each.studyEntitySubType));
    let sortedByTime = _.sortBy(data, "createdAt");
    let mappedToDateString = sortedByTime.map((each) => {
      let date = new Date(each.createdAt);
      return {
        ...each,
        date: date.toLocaleDateString("en-US"),
      };
    });
    let groupedByDate = _.groupBy(mappedToDateString, "date");
    let xAxisDates = Object.keys(groupedByDate);
    let massagedData = [];
    for (let i = 0; i < xAxisDates.length; i++) {
      let eachDate = xAxisDates[i];
      let obj = {
        date: eachDate,
      };
      for (let j = 0; j < categories.length; j++) {
        obj[categories[j]] = 0;
      }
      let eachDateData = groupedByDate[eachDate];
      for (let j = 0; j < eachDateData.length; j++) {
        let eachRow = eachDateData[j];
        obj[eachRow.studyEntitySubType] += 1;
      }
      massagedData.push(obj);
    }
    return massagedData;
  };
  getDataByDate = () => {
    let data = this.props.data.filter((d) => {
      let date = new Date(d.createdAt);
      // check if dd mm yyyy same as selected date
      return format(date, "MM/dd/yyyy") === this.state.selectedDate;
    });
    let categories = _.uniq(data.map((each) => each.studyEntitySubType));
    let users = _.uniq(data.map((each) => each.createdBy._id));
    let groupedByUser = _.groupBy(data, "createdBy._id");
    let massagedData = [];
    for (let i = 0; i < users.length; i++) {
      let eachUser = users[i];
      let obj = {
        user: eachUser,
        userName: this.state.userIdToName[eachUser],
      };
      for (let j = 0; j < categories.length; j++) {
        obj[categories[j]] = 0;
      }
      let eachUserData = groupedByUser[eachUser];
      for (let j = 0; j < eachUserData.length; j++) {
        let eachRow = eachUserData[j];
        obj[eachRow.studyEntitySubType] += 1;
      }
      massagedData.push(obj);
    }
    return massagedData;
  };
  renderChart = () => {
    let data = this.dataMassager();
    let aggregateByUser = _.groupBy(this.props.data, "createdBy._id");

    let users = Object.keys(aggregateByUser);

    let userCount = users.map((user) => {
      return {
        id: user,
        value: aggregateByUser[user].length,
        color: "bg-blue-500",
      };
    });
    userCount = _.sortBy(userCount, "value").reverse();
    let totalCount = this.props.data.length;
    let top_3_users = userCount.slice(0, 3);

    return (
      <Card className="w-full max-w-full">
        <h3 className="font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
          Reviewer Productivity By Date
        </h3>
        <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
          Top 3 Reviewers In Selected Time Period
        </p>
        <ul
          role="list"
          className="mt-6 grid gap-3 sm:grid-cols-2 md:grid-cols-4"
        >
          {top_3_users.map((tab) => (
            <li
              key={tab.id}
              className="rounded-tremor-small border border-tremor-border px-3 py-2 text-left dark:border-dark-tremor-border"
            >
              <div className="flex items-center space-x-1.5">
                <span
                  className={this.classNames(
                    tab.color,
                    "h-2.5 w-2.5 rounded-sm"
                  )}
                  aria-hidden={true}
                />
                <p className="text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                  {this.state.userIdToName[tab.id]}
                </p>
              </div>
              <p className="mt-0.5 font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                {tab.value}
              </p>
            </li>
          ))}
          <li className="rounded-tremor-small border border-tremor-border px-3 py-2 text-left dark:border-dark-tremor-border">
            <div className="flex items-center space-x-1.5">
              <span
                className={this.classNames(
                  "bg-blue-500",
                  "h-2.5 w-2.5 rounded-sm"
                )}
                aria-hidden={true}
              />
              <p className="text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                Total
              </p>
            </div>
            <p className="mt-0.5 font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              {totalCount}
            </p>
          </li>
        </ul>
        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid
            item
            xs={
              this.state.selectedDate || this.state.selectedUserId ? "6" : "12"
            }
          >
            <Stack spacing={1}>
              <Typography variant="overline">All Reviewers</Typography>
              <BarChart
                data={data}
                index="date"
                categories={Object.keys(data[0]).filter(
                  (key) => key !== "date"
                )}
                showLegend={false}
                yAxisWidth={45}
                stack={true}
                className="mt-6 hidden h-96 sm:block"
                customTooltip={(payload) => {
                  if (!payload.active) {
                    return "";
                  }
                  let data = payload.payload;

                  let sum = _.sumBy(data, "value");
                  return (
                    <div
                      className="mt-8 flex justify-center"
                      style={{
                        zIndex: "2001 !important",
                      }}
                      onClick={console.log}
                    >
                      <div className="w-52 rounded-tremor-default border border-tremor-border bg-tremor-background text-tremor-default shadow-tremor-input dark:border-dark-tremor-border dark:bg-dark-tremor-background dark:shadow-dark-tremor-input">
                        <div className="rounded-t-tremor-default border-b border-tremor-border bg-tremor-background-muted px-2.5 py-2 dark:border-dark-tremor-border dark:bg-dark-tremor-background">
                          <p className="font-medium text-tremor-content dark:text-dark-tremor-content">
                            {payload.label}: {sum}
                          </p>
                        </div>
                        {data.map((each, index) => {
                          if (!each.value) {
                            return null;
                          }
                          const classNameStr = each.className;
                          const className = classNameStr.replace(
                            "fill-",
                            "bg-"
                          );
                          return (
                            <div
                              key={index}
                              className="flex w-full items-center justify-between space-x-4 px-2.5 py-2"
                            >
                              <div
                                className="flex items-center space-x-2 truncate"
                                onClick={() => {
                                  console.log("selecting user", each);
                                  this.setState({
                                    selectedMode: "user",
                                    selectedUserId: each.dataKey,
                                  });
                                }}
                              >
                                <span
                                  className={`h-2.5 w-2.5 shrink-0 rounded-full ${className}`}
                                  aria-hidden={true}
                                />
                                <p
                                  className="truncate text-tremor-content dark:text-dark-tremor-content"
                                  onClick={() => {
                                    console.log("selecting user", each);
                                    this.setState({
                                      selectedMode: "user",
                                      selectedUserId: each.dataKey,
                                    });
                                  }}
                                >
                                  {this.state.userIdToName[each.dataKey]}
                                </p>
                              </div>
                              <p className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                                {each.value}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                }}
              />
            </Stack>
          </Grid>
          <Grid
            item
            xs={
              this.state.selectedDate || this.state.selectedUserId ? "6" : "12"
            }
          >
            {this.state.selectedUserId && (
              <Stack spacing={1}>
                <Typography variant="overline">
                  For Reviewer:{" "}
                  {this.state.userIdToName[this.state.selectedUserId]}
                </Typography>
                <BarChart
                  key="user-chart"
                  data={this.getDataByUser()}
                  index="date"
                  categories={Object.keys(this.getDataByUser()[0]).filter(
                    (key) => key !== "date"
                  )}
                  showLegend={false}
                  yAxisWidth={45}
                  stack={true}
                  className="mt-6 hidden h-96 sm:block"
                  customTooltip={(payload) => {
                    if (!payload.active) {
                      return "";
                    }
                    let data = payload.payload;

                    let sum = _.sumBy(data, "value");
                    return (
                      <div
                        className="mt-8 flex justify-center"
                        style={{
                          zIndex: "1001 !important",
                        }}
                      >
                        <div className="w-52 rounded-tremor-default border border-tremor-border bg-tremor-background text-tremor-default shadow-tremor-input dark:border-dark-tremor-border dark:bg-dark-tremor-background dark:shadow-dark-tremor-input">
                          <div className="rounded-t-tremor-default border-b border-tremor-border bg-tremor-background-muted px-2.5 py-2 dark:border-dark-tremor-border dark:bg-dark-tremor-background">
                            <p className="font-medium text-tremor-content dark:text-dark-tremor-content">
                              {payload.label}: {sum}
                            </p>
                          </div>
                          {data.map((each, index) => {
                            if (!each.value) {
                              return null;
                            }
                            const classNameStr = each.className;
                            const className = classNameStr.replace(
                              "fill-",
                              "bg-"
                            );
                            return (
                              <div
                                key={index}
                                className="flex w-full items-center justify-between space-x-4 px-2.5 py-2"
                              >
                                <div className="flex items-center space-x-2 truncate">
                                  <span
                                    className={`h-2.5 w-2.5 shrink-0 rounded-full ${className}`}
                                    aria-hidden={true}
                                  />
                                  <p className="truncate text-tremor-content dark:text-dark-tremor-content">
                                    {each.dataKey}
                                  </p>
                                </div>
                                <p className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                                  {each.value}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }}
                />
              </Stack>
            )}
          </Grid>
          <Grid
            item
            xs={
              this.state.selectedDate || this.state.selectedUserId ? "6" : "12"
            }
          >
            {this.state.selectedDate && (
              <Stack spacing={1}>
                <Typography variant="overline">
                  For Date: {this.state.selectedDate}
                </Typography>
                <BarChart
                  key="user-chart"
                  data={this.getDataByDate()}
                  index="userName"
                  categories={Object.keys(this.getDataByDate()[0]).filter(
                    (key) => key !== "userName" && key !== "user"
                  )}
                  showLegend={false}
                  yAxisWidth={45}
                  stack={true}
                  className="mt-6 hidden h-96 sm:block"
                  customTooltip={(payload) => {
                    if (!payload.active) {
                      return "";
                    }
                    let data = payload.payload;

                    let sum = _.sumBy(data, "value");
                    return (
                      <div
                        className="mt-8 flex justify-center"
                        style={{
                          zIndex: "1001 !important",
                        }}
                      >
                        <div className="w-52 rounded-tremor-default border border-tremor-border bg-tremor-background text-tremor-default shadow-tremor-input dark:border-dark-tremor-border dark:bg-dark-tremor-background dark:shadow-dark-tremor-input">
                          <div className="rounded-t-tremor-default border-b border-tremor-border bg-tremor-background-muted px-2.5 py-2 dark:border-dark-tremor-border dark:bg-dark-tremor-background">
                            <p className="font-medium text-tremor-content dark:text-dark-tremor-content">
                              {payload.label}: {sum}
                            </p>
                          </div>
                          {data.map((each, index) => {
                            if (!each.value) {
                              return null;
                            }
                            const classNameStr = each.className;
                            const className = classNameStr.replace(
                              "fill-",
                              "bg-"
                            );
                            return (
                              <div
                                key={index}
                                className="flex w-full items-center justify-between space-x-4 px-2.5 py-2"
                              >
                                <div className="flex items-center space-x-2 truncate">
                                  <span
                                    className={`h-2.5 w-2.5 shrink-0 rounded-full ${className}`}
                                    aria-hidden={true}
                                  />
                                  <p className="truncate text-tremor-content dark:text-dark-tremor-content">
                                    {each.dataKey}
                                  </p>
                                </div>
                                <p className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                                  {each.value}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }}
                />
              </Stack>
            )}
          </Grid>
        </Grid>
      </Card>
    );
  };

  /********** CHART ENDS */
  render() {
    this.dataMassager();
    return (
      <Stack spacing={2}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          width="100%"
          justifyContent="end"
        >
          <FormControl>
            <InputLabel labelId="select-label">Select User</InputLabel>
            <Select
              labelId="select-label"
              sx={{
                minWidth: "250px",
              }}
              size="small"
              value={this.state.selectedUserId}
              onChange={(e) => {
                this.setState({
                  selectedUserId: e.target.value,
                });
              }}
            >
              {Array.from(Object.keys(this.state.userIdToName)).map(
                (userId, index) => (
                  <MenuItem key={index} value={userId}>
                    {this.state.userIdToName[userId]}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel labelId="select-label">Select Date</InputLabel>
            <Select
              sx={{
                minWidth: "250px",
              }}
              size="small"
              value={this.state.selectedDate}
              onChange={(e) => {
                this.setState({
                  selectedDate: e.target.value,
                });
              }}
            >
              {_.uniq(
                this.props.data.map((d) =>
                  format(new Date(d.createdAt), "MM/dd/yyyy")
                )
              ).map((date, index) => (
                <MenuItem key={index} value={date}>
                  {date}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        {this.renderChart()}
        {this.renderGrid()}
      </Stack>
    );
  }
}
export default DataEntryReviewUserReport;
