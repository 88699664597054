import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import { Stack } from "@mui/system";
import {
  Alert,
  Typography,
  Card,
  Chip,
  LinearProgress,
  Tooltip,
  IconButton,
} from "@mui/material";
import _ from "lodash";
import Iconify from "src/components/iconify";
import AILoader from "../utils/AILoader";
import { LoadingButton } from "@mui/lab";
import VisitProtocolDataEach from "./VisitProtocolDataEach";
import axios from "axios";
import cogoToast from "cogo-toast";
import { DOCUMENT_GENERATOR_URL, TEMPLATE_ID } from "src/config-global";
import DocumentGroup from "../Documents/DocumentGroup";

class VisitProtocolData extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    summary: "",
    isSummarizing: false,
    currentAction: null,
    isSaving: false,
    showDetail: true,
    loadingClearancePdf: false,
    loadingPatientLetter: false,
  };
  componentDidMount() {
    this.setState({
      ...this.props.visitData,
      isLoading: false,
    });
  }

  cleanParameter = (param) => {
    return param;
  };

  renderByProtocol = () => {
    let protocols = this.state.protocolsInfo;
    if (!protocols || protocols.length === 0) {
      return <Alert severity="error">No protocols found.</Alert>;
    }
    return (
      <Stack spacing={2}>
        {protocols.map((each, ix) => {
          return (
            <Card
              key={ix}
              sx={{
                p: 2,
                boxShadow: 3,
                bgcolor: "background.neutral",
              }}
            >
              <VisitProtocolDataEach
                id={this.props.id}
                visitData={this.props.visitData}
                protocolData={each}
                updateVisitScalars={(arr) => this.props.updateVisitScalars(arr)}
                groups={this.props.groups}
              />
            </Card>
          );
        })}
      </Stack>
    );
  };
  // renderCurrentAction = () => {
  //   if (!this.state.currentAction) {
  //     return null;
  //   }
  //   return (
  //     <Stack direction="row" spacing={1} alignItems={"center"}>
  //       <Chip
  //         label={this.state.currentAction}
  //         icon={<Iconify icon="svg-spinners:pulse-3" />}
  //       />
  //     </Stack>
  //   );
  // };
  // renderSummary = () => {
  //   if (this.state.isSummarizing && !this.state.summary) {
  //     return <LinearProgress />;
  //   }
  //   if (!this.state.summary) {
  //     return null;
  //   }
  //   let s = this.state.summary;
  //   let lines = s.split("\n");
  //   return (
  //     <Stack spacing={2}>
  //       <Typography variant="overline">Summary</Typography>
  //       <Stack spacing={0}>
  //         {lines.map((line) => {
  //           return <Typography variant="caption">{line}</Typography>;
  //         })}
  //       </Stack>
  //     </Stack>
  //   );
  // };
  // renderSummaryButton = () => {
  //   if (this.state.isSummarizing) {
  //     return <AILoader />;
  //   }

  //   let serviceIds = _.filter(
  //     this.props.visitData.bookedServices,
  //     (x) => x.parentProtocolId === this.props.protocolData._id
  //   ).map((x) => x.serviceId);

  //   if (!serviceIds.length) {
  //     return null;
  //   }
  //   let summText = "Summarize";
  //   if (this.state.summary) {
  //     summText = "Resummarize";
  //   }
  //   return (
  //     <Stack direction="row" alignItems={"center"} spacing={2}>
  //       <LoadingButton
  //         disabled={this.state.isSaving}
  //         loading={this.state.isSummarizing}
  //         onClick={() => {
  //           this.generateSummary();
  //         }}
  //       >
  //         {summText}
  //       </LoadingButton>
  //       <Tooltip
  //         title={
  //           this.state.showDetail
  //             ? "Click to hide details"
  //             : "Click to show details"
  //         }
  //       >
  //         <IconButton
  //           size="small"
  //           onClick={() => {
  //             this.setState({
  //               showDetail: !this.state.showDetail,
  //             });
  //           }}
  //         >
  //           <Iconify
  //             icon={
  //               this.state.showDetail
  //                 ? "solar:square-double-alt-arrow-up-broken"
  //                 : "solar:square-double-alt-arrow-down-broken"
  //             }
  //           />
  //         </IconButton>
  //       </Tooltip>
  //     </Stack>
  //   );
  // };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    // return <Stack>{this.renderHistoricalGroups()}</Stack>;
    return <Stack>{this.renderByProtocol()}</Stack>;
  }
}

export default WithAPICall(VisitProtocolData);
