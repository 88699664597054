"use client";

import { useEffect, useState } from "react";
import {
  CalendarIcon,
  CheckIcon,
  InfoIcon,
  AlertTriangle,
  CheckCircle,
  XCircle,
  AlertCircle,
} from "lucide-react";
import { format, addMonths, addYears } from "date-fns";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Textarea } from "@/components/ui/textarea";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Label } from "@/components/ui/label";

import { useProtectedApi } from "@/hooks/use-apiCall";
import useVisitStore from "@/store/useVisitStore";
import { ClearanceScreenDone } from "./ClearanceScreenDone";
import moment from "moment";

const statusConfig = {
  disabled: { color: "text-gray-400 bg-gray-100", tooltip: "Not applicable" },
  warning: { color: "text-amber-700 bg-amber-50", tooltip: "Not cleared" },
  success: { color: "text-green-700 bg-green-50", tooltip: "Cleared" },
  waiting: { color: "text-blue-700 bg-blue-50", tooltip: "Not processed yet" },
};

const clearanceCaseIcons = {
  Cleared: <CheckCircle className="w-5 h-5 text-green-500" />,
  "Not Cleared": <XCircle className="w-5 h-5 text-red-500" />,
  "Cleared With Restrictions": (
    <AlertCircle className="w-5 h-5 text-amber-500" />
  ),
  "Cleared Provisionally": <AlertCircle className="w-5 h-5 text-amber-500" />,
  "Not Applicable": <AlertTriangle className="w-5 h-5 text-gray-500" />,
};

const ClearanceStatusSelect = ({ clearanceOptions, value, onChange }) => (
  <div className="space-y-2">
    <Label
      htmlFor="clearance-case"
      className="text-sm font-medium text-gray-700"
    >
      Clearance Status
    </Label>
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Select value={value} onValueChange={onChange}>
            <SelectTrigger id="clearance-case" className="w-full">
              <SelectValue placeholder="Select clearance case" />
            </SelectTrigger>
            <SelectContent>
              {clearanceOptions.map((option, index) => (
                <SelectItem key={index} value={option.case} className="text-xs">
                  [{option.typeOfClearance}] {option.case}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </TooltipTrigger>
        <TooltipContent>
          <p>Select the clearance status for this case</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  </div>
);

const ClearanceAlert = ({ typeOfClearance }) => (
  <Alert variant={typeOfClearance === "Cleared" ? "default" : "destructive"}>
    <div className="flex items-center space-x-2">
      {clearanceCaseIcons[typeOfClearance]}
      <AlertTitle>{typeOfClearance}</AlertTitle>
    </div>
    <AlertDescription>
      {typeOfClearance === "Cleared" &&
        "The employee has been cleared for this category."}
      {typeOfClearance === "Not Cleared" &&
        "The employee has not been cleared for this category."}
      {typeOfClearance === "Cleared With Restrictions" &&
        "The employee has been cleared with certain restrictions."}
    </AlertDescription>
  </Alert>
);

const tryParseDate = (dateStr) => {
  if (!dateStr) {
    return null;
  }
  const date = moment(dateStr, "MM/DD/YYYY");
  if (date.isValid()) {
    return date.toDate();
  }
  return null;
};

export function MedicalClearanceScreen({
  currentStatus,
  protocolId,
  medicalRecords,
}) {
  const { callApi } = useProtectedApi();
  const { visit, loadVisit } = useVisitStore();

  const protocol = visit.protocolsInfo.find((p) => p._id === protocolId);

  const thisProtocolMR = medicalRecords.filter(
    (mr) => mr.protocolId === protocolId && mr.dataType === "DOT_CARD"
  );

  const dotExpirationDate = tryParseDate(
    thisProtocolMR?.[0]?.dotExpirationDate
  );

  const [clearanceCase, setClearanceCase] = useState(
    currentStatus?.medicalClearanceStatus?.clearanceCase || ""
  );
  const [typeOfClearance, setTypeOfClearance] = useState(
    currentStatus?.medicalClearanceStatus?.typeOfClearance || ""
  );

  const [clinicDate, setClinicDate] = useState(
    currentStatus?.medicalClearanceStatus?.clinicDate ||
      dotExpirationDate ||
      null
  );
  const [recommendedDate, setRecommendedDate] = useState(
    currentStatus?.medicalClearanceStatus?.recommendedDate || null
  );
  const [finalClearanceText, setFinalClearanceText] = useState(
    currentStatus?.medicalClearanceStatus?.finalClearanceText || null
  );

  const [isCleared, setIsCleared] = useState(
    currentStatus?.medicalClearanceStatus?.isCleared || false
  );

  const [templates, setTemplates] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);

  const onSaveClearance = async () => {
    try {
      const resp = await callApi("/visit/updateVisitClearance", {
        visitId: visit._id,
        entityId: protocolId,
        entity: "Medical",
        medicalClearance: {
          clearanceCase,
          typeOfClearance,

          clinicDate,
          recommendedDate,

          finalClearanceText,
        },
      });
      loadVisit(resp);
      setIsCleared(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (clearanceCase) {
      const clearance = protocol.clearanceOptions.find(
        (c) => c.case === clearanceCase
      );
      setTypeOfClearance(clearance.typeOfClearance);
      setTemplates(
        (clearance.textTemplates || []).map((t) => ({
          id: t.template,
          label: t.text,
        }))
      );
      setFinalClearanceText(clearance.text);
    }
  }, [clearanceCase]);

  const addTemplate = (template) => {
    setFinalClearanceText((prev) => `${prev}\n${template}`);
  };

  const dateShortcuts = [
    { label: "3m", getValue: (date) => addMonths(date, 3) },
    { label: "6m", getValue: (date) => addMonths(date, 6) },
    { label: "1y", getValue: (date) => addYears(date, 1) },
    { label: "2y", getValue: (date) => addYears(date, 2) },
  ];

  if (isCleared) {
    return (
      <ClearanceScreenDone
        mode="medical"
        entityId={protocolId}
        onChangeClearance={() => {
          setIsCleared(false);
        }}
      />
    );
  }

  return (
    <TooltipProvider>
      <div className="p-6 max-w-2xl mx-auto bg-white rounded-xl shadow-md space-y-4">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          Medical Clearance
        </h2>

        <ClearanceStatusSelect
          clearanceOptions={protocol.clearanceOptions}
          value={clearanceCase}
          onChange={(value) => setClearanceCase(value)}
        />
        <ClearanceAlert typeOfClearance={typeOfClearance} />

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Clearance Language
          </label>
          <Textarea
            value={finalClearanceText}
            onChange={(e) => setFinalClearanceText(e.target.value)}
            className="mt-1 block w-full"
            rows={4}
          />
        </div>

        <div className="flex space-x-4">
          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Clinic Provided Date
            </label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={"outline"}
                  className={cn(
                    "w-full justify-start text-left font-normal",
                    !clinicDate && "text-muted-foreground"
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {clinicDate ? (
                    format(clinicDate, "PPP")
                  ) : (
                    <span>Pick a date</span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  selected={clinicDate}
                  onSelect={setClinicDate}
                  initialFocus
                />
                <div className="flex justify-end space-x-2 p-2 border-t">
                  {dateShortcuts.map((shortcut) => (
                    <Tooltip key={shortcut.label}>
                      <TooltipTrigger asChild>
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() =>
                            setClinicDate(shortcut.getValue(new Date()))
                          }
                        >
                          {shortcut.label}
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Set date to {shortcut.label} from today</p>
                      </TooltipContent>
                    </Tooltip>
                  ))}
                </div>
              </PopoverContent>
            </Popover>
          </div>

          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              MD Recommended Follow-up Date
            </label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={"outline"}
                  className={cn(
                    "w-full justify-start text-left font-normal",
                    !recommendedDate && "text-muted-foreground"
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {recommendedDate ? (
                    format(recommendedDate, "PPP")
                  ) : (
                    <span>Pick a date</span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  selected={recommendedDate}
                  onSelect={setRecommendedDate}
                  initialFocus
                />
                <div className="flex justify-end space-x-2 p-2 border-t">
                  {dateShortcuts.map((shortcut) => (
                    <Tooltip key={shortcut.label}>
                      <TooltipTrigger asChild>
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() =>
                            setRecommendedDate(shortcut.getValue(new Date()))
                          }
                        >
                          {shortcut.label}
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Set date to {shortcut.label} from today</p>
                      </TooltipContent>
                    </Tooltip>
                  ))}
                </div>
              </PopoverContent>
            </Popover>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Template Language
          </label>
          <div className="space-y-2">
            {templates.map((template) => (
              <div key={template.id} className="flex items-center space-x-2">
                <Checkbox
                  id={template.id}
                  checked={selectedTemplates.includes(template.id)}
                  onCheckedChange={(checked) => {
                    setSelectedTemplates((prev) =>
                      checked
                        ? [...prev, template.id]
                        : prev.filter((id) => id !== template.id)
                    );
                    if (checked) {
                      addTemplate(`${template.label}`);
                    }
                  }}
                />
                <label
                  htmlFor={template.id}
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {template.id}
                </label>
              </div>
            ))}
          </div>
        </div>

        <Tooltip>
          <TooltipTrigger asChild>
            <Button className="w-full mt-4" onClick={onSaveClearance}>
              Save Clearance
              <InfoIcon className="ml-2 h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Save the current clearance information</p>
          </TooltipContent>
        </Tooltip>
      </div>
    </TooltipProvider>
  );
}
