import { m } from "framer-motion";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useClerk } from "@clerk/clerk-react";
import { ForbiddenIllustration } from "src/assets/illustrations";

import { varBounce, MotionContainer } from "src/components/animate";

// ----------------------------------------------------------------------

export default function View403() {
  const { signOut } = useClerk();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
        textAlign: "center",
        height: "100dvh",
      }}
    >
      <MotionContainer>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            Your account is not configured for access.
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: "text.secondary" }}>
            The page you&apos;re trying access has restricted access.
            <br />
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
        <Button variant="contained" onClick={() => signOut()}>
          Sign Out
        </Button>
      </MotionContainer>
    </div>
  );
}
