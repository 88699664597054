// 'use client';
import { Typography } from "@mui/material";
import { BarList, LineChart } from "@tremor/react";
import { WithAPICall } from "../utils/apiUtil";
import { useContext, useEffect, useState } from "react";
import structureData from "./utils/massage-data";
import { shuffle } from "lodash";
import HOCContext from "./contexts/HOCContext";

const valueFormatter = (number) =>
  `${Intl.NumberFormat("us").format(number).toString()}`;

export default WithAPICall(function ProviderVisits({ apiCallPost }) {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const { from, to, companyIds } = useContext(HOCContext);

  useEffect(() => {
    const init = async () => {
      try {
        const { massagedData, categories } = await structureData(
          "/dashboard/visitActivityByProviderDate",
          apiCallPost,
          from,
          to
        );
        setData(massagedData);
        setCategories(categories);
      } catch (error) {
        console.error("could not get data", error);
      }
    };
    init();
  }, [from, to, companyIds]);

  return (
    <>
      <LineChart
        data={data}
        index="date"
        categories={categories}
        colors={shuffle([
          "red",
          "orange",
          "yellow",
          "cyan",
          "violet",
          "rose",
          "purple",
          "teal",
          "emerald",
          "neutral",
        ])}
        valueFormatter={valueFormatter}
        showLegend={false}
        showYAxis={false}
        startEndOnly={true}
        className="mt-6 h-32"
      />
      {/* <Typography variant="subtitle2" sx={{ mb: 1 }}>
        Top 3 Providers
      </Typography> */}
      {/* <BarList data={providers} valueFormatter={valueFormatter} /> */}
    </>
  );
});
