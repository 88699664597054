import { BarChart, Card } from "@tremor/react";
import React from "react";
import _ from "lodash";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import {
  FILTER_UTILS,
  sumAggregation,
  averageAggregation,
  maxAggregation,
  minAggregation,
  countAggregation,
} from "../../utils/grid-utils";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Button,
  //   Card,
  Stack,
  Chip,
  Drawer,
  LinearProgress,
  MenuItem,
  Select,
  Skeleton,
  Table,
  TableBody,
  TextField,
  Tooltip,
  Typography,
  InputLabel,
  FormControl,
  Box,
  Checkbox,
  Avatar,
  TableRow,
  TableCell,
  List,
  ListItem,
  Paper,
  IconButton,
  Badge,
  ListItemText,
  Divider,
} from "@mui/material";
import { formatTime } from "../utils/fn";

class BillingPurchaseReport extends React.Component {
  state = {
    data: [],
    filterUser: [],
    gridApi: null,
    columnApi: null,
    aggregations: {
      sum: (params) => sumAggregation(params),
      average: (params) => averageAggregation(params),
      max: (params) => maxAggregation(params),
      min: (params) => minAggregation(params),
      count: (params) => countAggregation(params),
    },
  };

  componentDidMount() {
    this.setState({ data: this.props.data });
  }

  /***** User Filter Render */
  renderUserSelectionFilter = () => {
    const filtUniq = _.uniq(this.props.data.map((item) => item.createdBy.name));
    return (
      <FormControl>
        <InputLabel id="label" size="small">
          Filter By User
        </InputLabel>
        <Select
          size="small"
          labelId="label"
          placeholder="Status"
          multiple
          value={this.state.filterUser}
          onChange={(e) => {
            let v = e.target.value;
            const fUser = typeof v === "string" ? v.split(",") : v;

            this.setState({
              filterUser: fUser,
              data: this.props.data.filter((item) => {
                return fUser.length === 0
                  ? true
                  : fUser.includes(item.createdBy.name);
              }),
            });
          }}
          sx={{
            minWidth: "200px",
          }}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip size="small" key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {filtUniq.map((item) => (
            <MenuItem key={item} value={item} size="small">
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  /******** GRID BEGINS */
  /** COLUMNS */
  COLUMNS = [
    { headerName: "DONOTIMPORT", field: "DONOTIMPORT", cellDataType: "text" },
    { headerName: "DOCUMENTNO", field: "DOCUMENTNO", cellDataType: "text" },
    {
      headerName: "TRANSACTIONTYPE",
      field: "TRANSACTIONTYPE",
      cellDataType: "text",
    },
    { headerName: "DATE", field: "DATE", cellDataType: "text" },
    {
      headerName: "GLPOSTINGDATE",
      field: "GLPOSTINGDATE",
      cellDataType: "text",
    },
    { headerName: "VENDOR_ID", field: "VENDOR_ID", cellDataType: "text" },
    { headerName: "DATEDUE", field: "DATEDUE", cellDataType: "text" },
    { headerName: "REFERENCENO", field: "REFERENCENO", cellDataType: "text" },
    { headerName: "EXCHRATEDATE", field: "EXCHRATEDATE", cellDataType: "text" },
    { headerName: "STATE", field: "STATE", cellDataType: "text" },
    { headerName: "PATIENT_NAME", field: "PATIENT_NAME", cellDataType: "text" },
    {
      headerName: "OHM_REFERENCE_NO",
      field: "OHM_REFERENCE_NO",
      cellDataType: "text",
    },
    { headerName: "SERVICE_DATE", field: "SERVICE_DATE", cellDataType: "text" },
    { headerName: "LINE", field: "LINE", cellDataType: "text" },
    { headerName: "ITEMID", field: "ITEMID", cellDataType: "text" },
    { headerName: "ITEMDESC", field: "ITEMDESC", cellDataType: "text" },
    { headerName: "QUANTITY", field: "QUANTITY", cellDataType: "text" },
    { headerName: "UNIT", field: "UNIT", cellDataType: "text" },
    { headerName: "PRICE", field: "PRICE", cellDataType: "text" },
    { headerName: "DEPARTMENTID", field: "DEPARTMENTID", cellDataType: "text" },
    {
      headerName: "PODOCUMENTENTRY_CLASSID",
      field: "PODOCUMENTENTRY_CLASSID",
      cellDataType: "text",
    },
    { headerName: "LOCATIONID", field: "LOCATIONID", cellDataType: "text" },
    { headerName: "MEMO", field: "MEMO", cellDataType: "text" },
    {
      headerName: "NONCONTRACTED",
      field: "NONCONTRACTED",
      cellDataType: "text",
    },
    { headerName: "EXAM_COMMENT", field: "EXAM_COMMENT", cellDataType: "text" },
  ];

  onGridReady = (params) => {
    this.setState({ gridApi: params.api, columnApi: params.columnApi });
  };
  /** RENDER GRID */
  renderGrid = () => {
    let cs = "ag-theme-quartz";
    let theme = localStorage.getItem("themeMode");
    if (theme && theme === "dark") {
      cs = "ag-theme-quartz-dark";
    }
    return (
      <div className={cs} style={{ height: "70vh" }}>
        <AgGridReact
          onGridReady={this.onGridReady}
          rowData={this.state.data}
          columnDefs={this.COLUMNS.map((x) => {
            return { ...FILTER_UTILS[x.cellDataType], ...x };
          })}
          defaultColDef={{
            sortable: true,
          }}
          autoSizeStrategy={{
            type: "fitCellContents",
          }}
          pagination={true}
          rowGroupPanelShow={"always"}
          aggFuncs={this.state.aggregations}
          suppressDragLeaveHidesColumns={true}
          rowSelection="multiple"
          rowDragManaged={true}
          enableCharts={true}
          enableRangeSelection={true}
          enableCellTextSelection={true}
          sideBar={true}
          animateRows={true}
          statusBar={{
            statusPanels: [
              {
                statusPanel: "agTotalAndFilteredRowCountComponent",
                align: "left",
              },
              {
                statusPanel: "agSelectedRowCountComponent",
                align: "left",
              },
              {
                statusPanel: "agAggregationComponent",
                align: "right",
              },
            ],
          }}
          defaultExcelExportParams={{
            author: "WorkCare",
            fileName: `${this.props.reportType} - ${new Date().toLocaleDateString()}`,
            sheetName: `${this.props.reportType} - ${new Date().toLocaleDateString()}`,
          }}
        />
      </div>
    );
  };
  /******** GRID ENDS */

  render() {
    return <div>{this.renderGrid()}</div>;
  }
}
export default BillingPurchaseReport;
