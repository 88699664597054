import React from "react";
import cogoToast from "cogo-toast";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  timelineOppositeContentClasses,
} from "@mui/lab";
import { Stack, Typography, Alert, AlertTitle } from "@mui/material";
import Iconify from "src/components/iconify/iconify";
import { niceDateTime } from "../utils/fn";
import { WithAPICall } from "../utils/apiUtil";

class PoolTimeline extends React.Component {
  state = {
    isLoading: true,
    isError: false,
  };
  EVENT_MAPS = {
    FREEZE: {
      color: "info",
      icon: "material-symbols-light:room-preferences-outline-rounded",
    },
    UNFREEZE: {
      color: "success",
      icon: "ri:folder-received-line",
    },
  };
  render() {
    let events = this.props.data?.poolChangelog;
    if (!events.length) {
      return <Alert severity="info">No events to display</Alert>;
    }
    return (
      <Timeline
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0.2,
          },
        }}
      >
        {events.map((event) => {
          let obj = this.EVENT_MAPS[event.changeType];
          return (
            <TimelineItem>
              <TimelineOppositeContent
                align="right"
                variant="body2"
                color="text.secondary"
                sx={{
                  pt: 1,
                }}
              >
                <Stack spacing={0}>
                  <Typography variant="overline">
                    {niceDateTime(event.ts)}
                  </Typography>{" "}
                  <Typography variant="caption">
                    {event.updatedBy?.name}
                  </Typography>
                </Stack>
              </TimelineOppositeContent>{" "}
              <TimelineSeparator>
                <TimelineDot color={obj.color}>
                  <Iconify icon={obj.icon} />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Alert severity={obj.color} icon={false}>
                  <AlertTitle> {event.changeType}</AlertTitle>
                  {/* {event.notes} */}
                </Alert>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    );
  }
}

export default WithAPICall(PoolTimeline);
