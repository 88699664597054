import { Grid, Select, MenuItem } from "@mui/material";
import { DonutChart } from "@tremor/react";
import { useState } from "react";

const datahero = [
  {
    name: "Past Due < 3 Days",
    value: 9800,
  },
  {
    name: "Past Due + 3 Days",
    value: 4567,
  },
  {
    name: "Past Due + 7 Days",
    value: 3908,
  },
  {
    name: "Past Due + 14 Days",
    value: 2400,
  },
  {
    name: "Past Due > 14 Days",
    value: 2174,
  },
];

const dataFormatter = (number) =>
  `${Intl.NumberFormat("us").format(number).toString()} Tasks`;

export const RealTimeTasks = () => {
  const [taskType, setTaskType] = useState("tt1");

  return (
    <>
      {/* <Grid container>
        <Grid item xs={12}>
          <Select
            value={taskType}
            onChange={(e) => {
              setTaskType(e.target.value);
            }}
            label="Task Type"
          >
            <MenuItem value="tt1">VisitSchedule</MenuItem>
            <MenuItem value="tt2">VisitDocumentChase</MenuItem>
            <MenuItem value="tt3">VisitClose</MenuItem>
          </Select>
        </Grid>
      </Grid> */}
      <div className="space-y-3">
        <div className="flex justify-center">
          <DonutChart
            data={[]}
            variant="pie"
            valueFormatter={dataFormatter}
            onValueChange={(v) => console.log(v)}
          />
        </div>
      </div>
    </>
  );
};
