import axios from "axios";
import _ from "lodash";
import { TokenConsumer } from "src/TokenContext";
const apiUrl = process.env.REACT_APP_API_URL;

export const WithAPICall = (WrappedComponent) => {
  return (props) => (
    <TokenConsumer>
      {(context) => {
        const { provideToken } = context;
        const apiCallGet = async (path) => {
          const token = await provideToken();
          const resp = await axios.get(apiUrl + path, {
            credentials: "include",
            headers: { Authorization: `Bearer ${token}` },
          });
          return resp.data;
        };
        const apiCallPost = async (path, datum) => {
          const token = await provideToken();

          const resp = await axios.post(apiUrl + path, datum, {
            credentials: "include",
            headers: { Authorization: `Bearer ${token}` },
          });
          return resp.data;
        };
        const CancelToken = axios.CancelToken;
        let cancel;
        const apiCallPostCancellable = async (path, datum) => {
          if (cancel !== undefined) {
            cancel();
          }
          const token = await provideToken();
          try {
            const res = await axios.post(apiUrl + path, datum, {
              credentials: "include",
              headers: { Authorization: token },
              cancelToken: new CancelToken(function executor(c) {
                cancel = c;
              }),
            });
            return res.data;
          } catch (err) {
            if (axios.isCancel(err)) {
              // pass
            } else return err;
          }
        };
        const apiCallPostUpload = async (path, fd, callback) => {
          const token = await provideToken();
          try {
            const res = await axios.post(apiUrl + path, fd, {
              credentials: "include",
              headers: {
                Authorization: token,
                "content-type": "multipart/form-data",
              },
              onUploadProgress: (progressEvent) => {
                callback(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                );
              },
            });
            return res.data;
          } catch (err) {
            if (axios.isCancel(err)) {
              // pass
            } else return err;
          }
        };
        const apiCallPostStreaming = async (path, datum, onData, onEnd) => {
          const token = await provideToken();
          const resp = await fetch(apiUrl + path, {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify(datum),
          });
          const reader = resp.body.getReader();
          let incompleteFragment = "";
          reader.read().then(function pump({ done, value }) {
            if (done) {
              onEnd();
              return;
            }
            let utf8decoder = new window.TextDecoder();
            let receivedChunkOfData = utf8decoder.decode(value);
            let workingChunkOfData = incompleteFragment + receivedChunkOfData;
            let delimiter = String.fromCharCode(30);
            //let delimiter = "%";
            let splitsInChunk = workingChunkOfData.split(delimiter);
            let arrayToSend = [];
            for (let i = 0; i < splitsInChunk.length; i++) {
              let potentialJSON = splitsInChunk[i];
              try {
                let sendJSON = JSON.parse(potentialJSON);
                arrayToSend.push(sendJSON);
              } catch (err) {
                incompleteFragment = potentialJSON;
              }
            }
            onData(arrayToSend);

            return reader.read().then(pump);
          });
        };
        const apiCallPostStreamingForAI = async (
          path,
          datum,
          onData,
          onEnd
        ) => {
          const token = await provideToken();
          const resp = await fetch(apiUrl + path, {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify(datum),
          });
          const reader = resp.body.getReader();
          reader.read().then(function pump({ done, value }) {
            if (done) {
              onEnd();
              return;
            }
            let utf8decoder = new window.TextDecoder();
            let receivedChunkOfData = utf8decoder.decode(value);
            onData(receivedChunkOfData);

            return reader.read().then(pump);
          });
        };

        return (
          <>
            <WrappedComponent
              apiCallPost={apiCallPost}
              apiCallPostCancellable={apiCallPostCancellable}
              apiCallPostUpload={apiCallPostUpload}
              apiCallPostStreaming={apiCallPostStreaming}
              apiCallPostStreamingForAI={apiCallPostStreamingForAI}
              apiCallGet={apiCallGet}
              {...props}
            />
          </>
        );
      }}
    </TokenConsumer>
  );
};
