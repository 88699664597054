export const PROTOCOL_STATUS = [
  "REVIEW_STARTED",
  "PENDING_DOCUMENTS_FROM_CLINIC",
  "PENDING_INFORMATION_FROM_EMPLOYEE",
  "REVIEW_COMPLETE",
];

export const PROTOCOL_STATUS_DRUG = [
  "REVIEW_STARTED",
  "PENDING_DOCUMENTS_FROM_CLINIC",
  "PENDING_INFORMATION_FROM_EMPLOYEE",

  "REVIEW_COMPLETE",
];
