export const PROTOCOL_ADDON_FEE_TYPES = [
  { key: "Scheduling Fee", value: "SCHEDULING_FEE" },
  { key: "Re-Scheduling Fee", value: "RESCHEDULING_FEE" },
  { key: "No-show Fee", value: "NO_SHOW_FEE" },
  { key: "Cancellation Fee", value: "CANCELLATION_FEE" },
  { key: "Paperwork Fee", value: "PAPERWORK_FEE" },
  { key: "Vaccine Declination Fee", value: "VACCINE_DECLINATION_FEE" },
  {
    key: "Split Specimen Collection Fee",
    value: "SPLIT_SPECIMEN_COLLECTION_FEE",
  },
  { key: "Blood Collection Fee", value: "BLOOD_COLLECTION_FEE" },
  { key: "Urine Collection Fee", value: "URINE_COLLECTION_FEE" },
  { key: "Blood-Urine Collection Fee", value: "BLOOD_URINE_COLLECTION_FEE" },
  { key: "Venipuncture Fee", value: "VENIPUNCTURE_FEE" },
];

export const COMPANY_ADDON_FEE_TYPES = [...PROTOCOL_ADDON_FEE_TYPES];
