import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import Iconify from "src/components/iconify";
import { UploadBox } from "src/components/upload";
import GenericFileListItem from "./GenericFileListItem";
import { WithAPICall } from "./apiUtil";
import cogoToast from "cogo-toast";
import { LoadingButton } from "@mui/lab";
import UploadSnack from "./UploadSnack";

class GenericFileUploadHeader extends React.Component {
  state = { isLoading: true, files: [], uploadCount: 0, uploadProgress: null };
  componentDidMount() {
    this.getByEntityId();
  }
  getByEntityId = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost("/files/getByEntityId", {
        entityId: this.props.entityId,
        entityType: this.props.entityType,
      });
      this.setState({ files: res, isLoading: false }, () => {
        if (this.props.entityType === "VISIT_OUTPUT_FILE") {
          this.props.onFilesLoad(res);
        }
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Files");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  uploadSingle = async (file, index) => {
    try {
      const fd = new FormData();
      fd.append("file", file);
      fd.append("entityType", this.props.entityType);
      fd.append("entityId", this.props.entityId);
      fd.append("description", file.name);
      const res = await this.props.apiCallPostUpload(
        "/files/addToEntity",
        fd,
        (e) => {
          this.setState({
            uploadProgress: e,
            uploadCount: index,
          });
        }
      );

      this.setState(
        {
          uploadProgress: null,
          files: [res, ...this.state.files],
        },
        () => {
          if (this.props.entityType === "VISIT_OUTPUT_FILE") {
            this.props.onFilesAdd(res);
          }
        }
      );
    } catch (err) {
      cogoToast.error("Error Uploading File");
      console.log(err);
    }
  };
  uploadMultiple = async (files) => {
    this.setState({
      isLoading: true,
    });
    for (let i = 0; i < files.length; i++) {
      await this.uploadSingle(files[i], i);
    }
    this.setState({
      isLoading: false,
    });
  };
  dissociateFile = async (fileId) => {
    try {
      this.setState({
        isLoading: true,
      });
      await this.props.apiCallPost("/files/setAssociation", {
        cfId: fileId,
        associatedEntityId: null,
      });
      this.setState(
        {
          files: this.state.files.filter((each) => each._id !== fileId),
          isLoading: false,
        },
        () => {
          if (this.props.entityType === "VISIT_OUTPUT_FILE") {
            this.props.onFilesRemove(fileId);
          }
        }
      );
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Dissociating File");
      this.setState({
        isLoading: false,
      });
    }
  };
  renderFiles = () => {
    let list = this.state.files;
    if (!list) return null;
    if (list.length === 0) return null;
    return list.map((each) => {
      return (
        <GenericFileListItem
          key={each._id}
          data={each}
          onSelectFile={this.props.onSelectFile}
          onDescriptionChange={(desc) => {
            if (this.props.onDescriptionChange) {
              this.props.onDescriptionChange(desc, each._id);
            }
          }}
          dissociateFile={() => this.dissociateFile(each._id)}
        />
      );
    });
  };
  render() {
    return (
      <div>
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
          }}
        >
          <UploadSnack
            uploadProgress={this.state.uploadProgress}
            uploadCount={this.state.uploadCount}
          />
          <Box>
            <Stack direction="row" alignItems="center" spacing={1} flexGrow={1}>
              <Typography variant="h6"> Files</Typography>{" "}
              <UploadBox
                disabled={this.state.isLoading}
                onDrop={(e) => this.uploadMultiple(e)}
                placeholder={
                  <Stack alignItems="center" sx={{ color: "text.disabled" }}>
                    <LoadingButton loading={this.state.isLoading}>
                      <Iconify icon="eva:cloud-upload-fill" width={30} />
                    </LoadingButton>
                  </Stack>
                }
              />
            </Stack>
            <Stack spacing={2}>{this.renderFiles()}</Stack>
          </Box>
        </Box>
      </div>
    );
  }
}

export default WithAPICall(GenericFileUploadHeader);
