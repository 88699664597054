import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import { usePathname } from "src/routes/hooks";
import { useResponsive } from "src/hooks/use-responsive";
import Logo from "src/components/logo";
import Scrollbar from "src/components/scrollbar";
import { NavSectionVertical } from "src/components/nav-section";
import { NAV } from "../config-layout";
import { useNavData } from "./config-navigation";
import NavToggleButton from "../common/nav-toggle-button";
import { useUser } from "@clerk/clerk-react";
import { Avatar, Typography } from "@mui/material";
import TokenContext from "src/TokenContext";
import { WithAPICall } from "src/micro/utils/apiUtil";

export default WithAPICall(function NavVertical({
  openNav,
  onCloseNav,
  apiCallPost,
}) {
  const { user } = useUser();
  const { currentUser } = useContext(TokenContext);
  const [companyLogo, setCompanyLogo] = useState(
    currentUser.company?.companyLogoUrl
  );

  const pathname = usePathname();

  const lgUp = useResponsive("up", "lg");

  const navData = useNavData();

  useEffect(() => {
    setCompanyLogo(currentUser.company?.companyLogoUrl);
  }, [currentUser.company]);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack spacing={1} direction="row" alignItems="center">
        <Logo sx={{ mt: 1, ml: 4 }} />
        {currentUser.isExternal && <img src={companyLogo} width="100" />}
      </Stack>
      <NavSectionVertical
        data={navData}
        slotProps={{
          currentRole: user?.role,
        }}
      />
      <Box sx={{ flexGrow: 1 }} />{" "}
      <Stack
        sx={{
          px: 2,
          py: 5,
          textAlign: "center",
        }}
      >
        <Stack alignItems="center">
          <Box sx={{ position: "relative" }}>
            <Avatar
              src={user?.imageUrl}
              alt={user?.fullName}
              sx={{ width: 48, height: 48 }}
            >
              {user?.fullName?.charAt(0).toUpperCase()}
            </Avatar>
          </Box>

          <Stack spacing={0.5} sx={{ mb: 2, mt: 1.5, width: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {user?.fullName}
            </Typography>

            <Typography variant="body2" noWrap sx={{ color: "text.disabled" }}>
              {user?.primaryEmailAddress?.emailAddress}
            </Typography>
          </Stack>
        </Stack>{" "}
        <Typography variant="subtitle2" noWrap>
          WorkCare ME & T Portal
        </Typography>
      </Stack>
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: "fixed",
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
});

// NavVertical.propTypes = {
//   openNav: PropTypes.bool,
//   onCloseNav: PropTypes.func,
// };
