import React from "react";

import { WithAPICall } from "../../utils/apiUtil";
import cogoToast from "cogo-toast";
import {
  Alert,
  LinearProgress,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  TableBody,
  Button,
  Drawer,
  Tooltip,
} from "@mui/material";
import { View500 } from "src/sections/error";
import { Stack } from "@mui/system";
import Iconify from "src/components/iconify";
import { niceDateTime, niceFileSize } from "src/micro/utils/fn";
import DocClassify from "./DocClassify";
import _ from "lodash";

class DocTabManualClassification extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    fileList: [],
    currentlyOpenedFileId: null,
  };
  componentDidMount() {
    this.getFiles();
  }
  getFiles = async () => {
    try {
      let res = await this.props.apiCallPost("/files/getByEntityType", {
        entityType: "VISIT_OUTPUT_FILE",
        statusIn: ["NEEDS_MANUAL_CLASSIFICATION", "CLASSIFYING"],
      });
      this.setState({
        isLoading: false,
        fileList: res,
      });
    } catch (err) {
      cogoToast.error("Error getting file groups");
      this.setState({
        isError: true,
        isLoading: false,
      });
    }
  };
  startBackgroundReadout = async (id) => {
    try {
      await this.props.apiCallPost("/files/groups/cfpgRunTranscribeParse", {
        cfId: id,
      });
      cogoToast.success("Background readout started");
    } catch (err) {
      cogoToast.error("Error starting background readout");
    }
  };
  onConfirmClassification = async (id) => {
    let files = this.state.fileList;
    let fileIdx = _.findIndex(files, (f) => f._id === id);
    let file = files[fileIdx];
    file.isProcessing = true;
    files[fileIdx] = file;
    this.setState({
      fileList: files,
    });
    try {
      let res = await this.props.apiCallPost("/files/setStatus", {
        cfId: file._id,
        processStatus: "CLASSIFIED",
      });
      cogoToast.success(
        "File classified successfully. Has been added to the queue for further processing."
      );
      file = res;
      file.isProcessing = false;
      files[fileIdx] = file;
      this.setState(
        {
          fileList: files,
          currentlyOpenedFileId: null,
        },
        () => {
          this.startBackgroundReadout(id);
        }
      );
    } catch (err) {
      cogoToast.error("Error classifying file");
      file.isProcessing = false;
      files[fileIdx] = file;
      this.setState({
        fileList: files,
        currentlyOpenedFileId: null,
      });
    }
  };
  renderDocumentClassifyDrawer = () => {
    return (
      <Drawer
        open={this.state.currentlyOpenedFileId !== null}
        anchor="right"
        onClose={() => {
          this.setState({
            currentlyOpenedFileId: null,
          });
        }}
      >
        <div
          style={{
            width: "92vw",
            padding: "1rem",
            overflowY: "auto",
          }}
        >
          <DocClassify
            id={this.state.currentlyOpenedFileId}
            onConfirm={() => {
              this.onConfirmClassification(this.state.currentlyOpenedFileId);
            }}
          />
        </div>
      </Drawer>
    );
  };
  renderErrorFileList = () => {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.fileList.length === 0) {
      return <Alert severity="info">No files for classification found</Alert>;
    }
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>File Name</TableCell>
            <TableCell>Provenance</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.fileList.map((file, index) => {
            let provenanceElem = null;
            if (file.provenance === "User") {
              provenanceElem = (
                <Typography variant="overline">
                  Uploaded by {file.createdBy?.name || "User"}
                </Typography>
              );
            } else if (file.isTakenFromEmail) {
              provenanceElem = (
                <Typography variant="overline">Taken from email</Typography>
              );
            } else {
              provenanceElem = (
                <Typography variant="overline">Automation</Typography>
              );
            }

            let actionElem = null;
            if (file.isProcessing) {
              actionElem = (
                <Stack direction="column" spacing={0.5}>
                  <LinearProgress />
                  <Typography variant="overline">Processing...</Typography>
                </Stack>
              );
            } else if (file.processStatus === "CLASSIFYING") {
              actionElem = (
                <Stack direction="column" spacing={0.5}>
                  <LinearProgress />
                  <Tooltip title="This file is currently being classified automatically. Please check back in a minute or so.">
                    <Typography variant="overline">Classifying...</Typography>
                  </Tooltip>
                </Stack>
              );
            } else if (file.processStatus === "CLASSIFIED") {
              actionElem = (
                <Stack direction="row" spacing={0.5}>
                  <Iconify icon="akar-icons:check" />
                  <Tooltip title="This file is currently being read out automatically. Please check back in a minute or so.">
                    <Typography variant="overline">Classified</Typography>
                  </Tooltip>
                </Stack>
              );
            } else {
              if (file.isProcessing) {
                actionElem = (
                  <Stack direction="column" spacing={0.5}>
                    <LinearProgress />
                    <Typography variant="overline">Processing...</Typography>
                  </Stack>
                );
              } else {
                actionElem = (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      this.setState({
                        currentlyOpenedFileId: file._id,
                      });
                    }}
                  >
                    Classify Document
                  </Button>
                );
              }
            }

            return (
              <TableRow>
                <TableCell>
                  <Stack direction="column" spacing={0.5}>
                    <Stack direction="row" alignItems={"center"} spacing={0.5}>
                      <Typography variant="overline">
                        {" "}
                        {file.description ||
                          file.originalName ||
                          "Unknown File Name"}
                      </Typography>
                      <IconButton href={file.psUrl} target="_blank">
                        <Iconify icon="quill:link-out" />
                      </IconButton>
                    </Stack>
                    <Typography variant="caption">
                      {niceFileSize(file.sizeBytes)}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Stack direction="column" spacing={0.5}>
                    {provenanceElem}
                    <Typography variant="caption">
                      Uploaded: {niceDateTime(file.createdAt) || "Unknown Date"}
                    </Typography>
                    <Typography variant="caption">
                      Last Updated:{" "}
                      {niceDateTime(file.updatedAt) || "Unknown Date"}{" "}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>{actionElem}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <Stack>
        {this.renderDocumentClassifyDrawer()}
        {this.renderErrorFileList()}
      </Stack>
    );
  }
}

export default WithAPICall(DocTabManualClassification);
