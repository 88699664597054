import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import { Box, Stack } from "@mui/system";
import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  ListItemText,
  TextField,
  Typography,
  LinearProgress,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import cogoToast from "cogo-toast";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { LoadingButton } from "@mui/lab";
import Iconify from "src/components/iconify";
import VisitChooseProtocols from "./VisitChooseProtocols";
import _ from "lodash";
import VisitChooseProtocolsForRequest from "./VisitChooseProtocolsForRequest";
import { maskSSN } from "../utils/fn";

class RequestVisit extends React.Component {
  state = {
    isLoading: true,
    isError: false,

    // company
    isSearchingCompany: false,
    companySearchResults: [],
    currentlyInCompany: null,

    // employee
    isSearchingEmployee: false,
    employeeSearchResults: [],
    currentlyInEmployee: null,
    //
    isCreatingVisit: false,
    visitId: null,

    externalAuthzCode: null,
  };
  async componentDidMount() {
    this.getCurrentUser();
    // check if url params has an employeeId, if yes
    // search for the employee and set it
    const urlParams = new URLSearchParams(window.location.search);
    const employeeId = urlParams.get("employeeId");
    if (employeeId) {
      cogoToast.loading("Loading Employee Data");
      try {
        const res = await this.props.apiCallPost("/employee/getById", {
          _id: employeeId,
        });
        this.setState({
          currentlyInEmployee: {
            data: res,
            mainText: res.employeeName,
            secondaryText: `${res.employeeNumber} | ${res.employeeOfficeEmail}`,
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
  updateVisitScalars = async (arr, showNotification = true) => {
    this.setState({
      isSaving: true,
    });
    try {
      let res = await this.props.apiCallPost("/visit/updateVisitScalars", {
        visitId: this.state.visitId,
        uops: arr,
      });
      if (showNotification) {
        cogoToast.success("Visit Data Saved");
      }
      this.setState({
        isSaving: false,
        ...res,
      });
    } catch (err) {
      cogoToast.error("Error Saving Visit Data");
      console.log(err);
      this.setState({
        isSaving: false,
      });
    }
  };
  getCurrentUser = async () => {
    try {
      let res = await this.props.apiCallPost("/user/getCurrentUser");
      if (res.isExternal) {
        this.setState({
          currentlyInCompany: {
            mainText: res.company.companyName,
            secondaryText: `${res.company.companyInformalName} ${res.companyWebsite}`,
            data: res.company,
            _id: res.company._id,
          },
          isExternal: true,
          isLoading: false,
        });
      } else {
        this.setState({
          currentlyInCompany: null,
          isExternal: false,
          isLoading: false,
        });
      }
    } catch (err) {
      cogoToast.error("Error Loading User Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  searchCompaniesFTS = async (t) => {
    try {
      let res = await this.props.apiCallPostCancellable("/company/search", {
        query: t,
      });
      if (res) {
        this.setState({
          isSearchingCompany: false,
          companySearchResults: res.map((each) => {
            return {
              mainText: each.companyName,
              secondaryText: `${each.companyInformalName} ${each.companyWebsite || ""}`,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingCompany: false,
        companySearchResults: [],
      });
      cogoToast.error("Error Searching Companies");
    }
  };
  searchEmployeesFTS = async (t) => {
    try {
      let res = await this.props.apiCallPostCancellable(
        "/employee/data/searchAugmentedFts",
        {
          companyId: this.state.currentlyInCompany.data._id,
          query: t,
        }
      );
      if (res) {
        this.setState({
          isSearchingEmployee: false,
          employeeSearchResults: res.map((each) => {
            return {
              mainText: each.employeeName,
              secondaryText: `${each.employeeNumber ? each.employeeNumber : "No Employee Number"} | ${each.employeeOfficeEmail || "No Employee Email"}`,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingEmployee: false,
        employeeSearchResults: [],
      });
      cogoToast.error("Error Searching Employees");
    }
  };
  createVisit = async () => {
    this.setState({
      isCreatingVisit: true,
      isLoading: true,
    });
    try {
      let res = await this.props.apiCallPost("/visit/createNewVisit", {
        companyId: this.state.currentlyInCompany.data._id,
        employeeId: this.state.currentlyInEmployee.data._id,
        externalAuthzCode: this.state.externalAuthzCode || "",
      });
      this.setState({
        visitId: res._id,
        ...res,
      });
      let protocolRes = await this.props.apiCallPost(
        "/company-protocol/companyProtocolGetAll",
        {
          companyId: this.state.currentlyInCompany._id,
        }
      );
      protocolRes = protocolRes.filter(
        (x) =>
          x.activeStatus === "ACTIVE" && (x.masterServices || []).length > 0
      );
      this.setState({
        isLoading: false,
        isError: false,
        companyProtocols: protocolRes,
        isCreatingVisit: false,
      });
    } catch (err) {
      this.setState({
        isCreatingVisit: false,
      });
      cogoToast.error("Error Creating Visit");
    }
  };
  renderCompanySearcher = () => {
    if (this.state.isExternal) {
      return null;
    }
    return (
      <Autocomplete
        disableClearable
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option?.mainText
        }
        filterOptions={(x) => x}
        options={
          this.state.isSearchingCompany ? [] : this.state.companySearchResults
        }
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={this.state.currentlyInCompany}
        noOptionsText={
          this.state.isSearchingCompany ? "Searching..." : "No results found"
        }
        onChange={(_, value) => {
          this.setState({
            currentlyInCompany: value,
            companySearchResults: [value, ...this.state.companySearchResults],
          });
        }}
        onInputChange={(_, e) => {
          if (e === "") return null;
          if (!e) return null;
          if (e.length < 4) return null;
          this.setState(
            {
              isSearchingCompany: true,
            },
            () => {
              this.searchCompaniesFTS(e);
            }
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label="Search Employers" fullWidth />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ wordWrap: "break-word" }}>
                  <Box component="span">{option?.mainText}</Box>
                  <Typography variant="body2" color="text.secondary">
                    {option?.secondaryText || ""}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    );
  };
  renderEmployeeSearcher = () => {
    if (
      !this.state.currentlyInCompany ||
      !this.state.currentlyInCompany.data ||
      !this.state.currentlyInCompany.data._id
    ) {
      return null;
    }

    return (
      <Stack>
        <Autocomplete
          disableClearable
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option?.mainText
          }
          filterOptions={(x) => x}
          options={
            this.state.isSearchingEmployee
              ? []
              : this.state.employeeSearchResults
          }
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={this.state.currentlyInEmployee}
          noOptionsText={
            this.state.isSearchingEmployee ? "Searching..." : "No results found"
          }
          onChange={(_, value) => {
            this.setState({
              currentlyInEmployee: value,
              employeeSearchResults: [
                value,
                ...this.state.employeeSearchResults,
              ],
            });
          }}
          onInputChange={(_, e) => {
            if (e === "") return null;
            if (!e) return null;
            // if (e.length < 4) return null;
            this.setState(
              {
                isSearchingEmployee: true,
              },
              () => {
                this.searchEmployeesFTS(e);
              }
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Employees (Use Name, SSN, Personnel Number or Applicant ID)"
              fullWidth
            />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item sx={{ wordWrap: "break-word" }}>
                    <Box component="span">{option?.mainText || ""}</Box>
                    <Typography variant="body2" color="text.secondary">
                      {option?.secondaryText}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
        <Alert severity="info" sx={{ mt: 1, mb: 1 }}>
          You can search by employee name, applicant ID, personnel number, SSN
          or other identifiers.
        </Alert>
      </Stack>
    );
  };
  renderPageHeader = () => {
    return (
      <Stack spacing={3}>
        <ListItemText
          sx={{
            p: 3,
            borderRadius: 2,
            bgcolor: "background.neutral",
          }}
          primary={
            <Stack direction="row" alignItems="center" spacing={3}>
              <Typography variant="h6" id="STEP_1">
                Request A New Visit
              </Typography>
            </Stack>
          }
          secondary="Choose an employee for the visit."
          primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
          secondaryTypographyProps={{ component: "span" }}
        />
      </Stack>
    );
  };
  renderCreateVisitButton = () => {
    if (
      !this.state.currentlyInEmployee ||
      !this.state.currentlyInEmployee.data ||
      !this.state.currentlyInEmployee.data._id
    ) {
      return null;
    }

    if (!this.state.visitId) {
      return (
        <Stack direction="row" width="100%" justifyContent="end">
          <Box>
            <LoadingButton
              variant="contained"
              loading={this.state.isCreatingVisit}
              onClick={() => {
                this.createVisit();
              }}
            >
              Request Visit For Employee
            </LoadingButton>
          </Box>
        </Stack>
      );
    }
  };

  renderEmployeeTable = () => {
    if (!this.state.currentlyInEmployee) return;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Employee Name</TableCell>
            <TableCell>Employee ID</TableCell>
            <TableCell>Employee SSN</TableCell>
            <TableCell>Employee Sex</TableCell>
            <TableCell>Employee Address</TableCell>
            <TableCell>Employee Phone</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              {this.state.currentlyInEmployee.data.employeeName}
            </TableCell>
            <TableCell>
              {this.state.currentlyInEmployee.data.employeeNumber}
            </TableCell>
            <TableCell>
              {this.state.currentlyInEmployee.data.employeeSSN}
            </TableCell>
            <TableCell>
              {maskSSN(this.state.currentlyInEmployee.data.employeeSex)}
            </TableCell>
            <TableCell>
              {this.state.currentlyInEmployee.data.employeeAddress}
            </TableCell>
            <TableCell>
              <Button
                variant="outlined"
                href={`tel:${this.state.currentlyInEmployee.data.employeePersonalPhone}`}
                startIcon={<Iconify icon="ic:baseline-phone" />}
              >
                {this.state.currentlyInEmployee.data.employeePersonalPhone}
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  render() {
    return (
      <Stack spacing={2}>
        {this.renderPageHeader()}
        <Card sx={{ padding: "1rem", height: "100%" }}>
          <Stack spacing={1}>
            {this.renderEmployeeSearcher()}
            {this.renderEmployeeTable()}
            {this.renderCreateVisitButton()}
          </Stack>
        </Card>
        {this.state.isLoading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        {!this.state.isLoading && this.state.visitId && (
          <VisitChooseProtocolsForRequest
            isSaving={this.state.isSaving}
            employee={this.state.employee}
            companyProtocols={this.state.companyProtocols}
            bookedProtocolIds={this.state.bookedProtocolIds}
            bookedServices={this.state.bookedServices}
            visit={this.state}
            updateVisitScalars={this.updateVisitScalars}
            addProtocol={(protocolId, bookedServices) => {
              let newBookedProtocolIds = this.state.bookedProtocolIds;
              newBookedProtocolIds.push(protocolId);

              let newBookedServices = this.state.bookedServices;
              let add = bookedServices.map((each) => {
                return {
                  parentProtocolId: protocolId,
                  serviceId: each,
                };
              });
              newBookedServices = newBookedServices.filter(
                (each) => each.parentProtocolId !== protocolId
              );
              newBookedServices = newBookedServices.concat(add);
              this.setState(
                {
                  bookedProtocolIds: _.uniq(newBookedProtocolIds),
                  bookedServices: newBookedServices,
                },
                () => {
                  let payload = [
                    {
                      key: "bookedProtocolIds",
                      value: this.state.bookedProtocolIds,
                    },
                    {
                      key: "bookedServices",
                      value: this.state.bookedServices,
                    },
                  ];
                  this.updateVisitScalars(payload);
                }
              );
            }}
            removeProtocol={(protocolId) => {
              let newBookedProtocolIds = this.state.bookedProtocolIds;
              newBookedProtocolIds = newBookedProtocolIds.filter(
                (each) => each !== protocolId
              );

              let newBookedServices = this.state.bookedServices;
              newBookedServices = newBookedServices.filter(
                (each) => each.parentProtocolId !== protocolId
              );
              this.setState(
                {
                  bookedProtocolIds: newBookedProtocolIds,
                  bookedServices: newBookedServices,
                },
                () => {
                  let payload = [
                    {
                      key: "bookedProtocolIds",
                      value: this.state.bookedProtocolIds,
                    },
                    {
                      key: "bookedServices",
                      value: this.state.bookedServices,
                    },
                  ];
                  this.updateVisitScalars(payload);
                }
              );
            }}
          />
        )}
      </Stack>
    );
  }
}

export default WithAPICall(RequestVisit);
