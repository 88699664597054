import React from "react";
import { Alert } from "@mui/material";

export const DPS1UploadPDF = ({ status = "inProgress" }) => {
  const renderCardNew = () => {
    if (status === "success") {
      return (
        <Alert severity="success">
          AI has successfully validated that the uploaded file is a valid PDF,
          can be read effectively and AI will attempt to extract data from it.
          It is now ready for the next stage in the datafile processing
          pipeline.
        </Alert>
      );
    }
    if (status === "error") {
      return (
        <Alert severity="error">
          The uploaded file could not be read by AI. This may be due to some
          form of file corruption or an unsupported format (Eg. not a valid
          PDF).
        </Alert>
      );
    }
    if (status === "inProgress") {
      return (
        <Alert severity="info">
          AI is validating the uploaded document. This process ensures the
          document is readable and meets our system and pipeline requirements.
        </Alert>
      );
    }
    return (
      <Alert severity="info">
        We can't currently fetch the status for this uploaded document. Please
        try again later.
      </Alert>
    );
  };

  return <div className="grid grid-cols-1 bg-gray-50">{renderCardNew()}</div>;
};
