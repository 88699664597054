import React, { useState } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
  TableRow,
  TextField,
  Typography,
  useTheme,
  Box,
  Tooltip,
  Stack,
  Button,
} from "@mui/material";
import Iconify from "src/components/iconify";
import { SaveIcon } from "lucide-react";
import cogoToast from "cogo-toast";

const parameterLabels = {
  smokerStatus: "Smoker Status",
  asthmaStatus: "Asthma Status",
  sessionQuality: "Session Quality",
  sessionQualityNotes: "Session Quality Notes",
  interpretation: "PFT Interpretation",
  numberOfTrials: "Number of Trials",
  fvc: "Forced Vital Capacity (FVC)",
  fev1: "Forced Expiratory Volume (FEV1)",
  fev1fvcRatio: "FEV1/FVC Ratio",
  pfm: "Peak Flow Measurement (PFM)",
};

export const PFTDataViewer = ({ data_, onUpdate }) => {
  const theme = useTheme();
  const [data, setData] = useState(data_);
  const [abnormalFields, setAbnormalFields] = useState(data.hasAbnormal || []);
  const [hasChange, setHasChange] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const handleSave = () => {
    setIsSaving(true);
    onUpdate(data);
    setIsSaving(false);
    setHasChange(false);
  };
  let saveElem = null;
  if (hasChange && !isSaving) {
    saveElem = (
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="overline">Unsaved Changes</Typography>
        <Button
          variant="contained"
          onClick={handleSave}
          startIcon={<Iconify icon="bx:save" />}
        >
          Save
        </Button>
      </Stack>
    );
  }
  if (isSaving) {
    saveElem = (
      <Stack direction="row" spacing={2}>
        <Typography variant="overline">Saving...</Typography>
        <Iconify icon="pulse-3" />
      </Stack>
    );
  }

  const handleChange = (field, value) => {
    if (!onUpdate) {
      cogoToast.error("Updates are restricted.");
      return;
    }
    const updatedData = { ...data, [field]: value };
    setData(updatedData);
    setHasChange(true);
    setIsSaving(false);
  };

  const toggleAbnormal = (field) => {
    const newAbnormalFields = abnormalFields.includes(field)
      ? abnormalFields.filter((f) => f !== field)
      : [...abnormalFields, field];
    setAbnormalFields(newAbnormalFields);
    onUpdate({ ...data, hasAbnormal: newAbnormalFields });
  };

  const isAbnormal = (field) => abnormalFields.includes(field);

  const getTooltipText = (field) => {
    return isAbnormal(field)
      ? "This result is potentially abnormal. Click to mark it as normal."
      : "This result is normal. Click to mark it as potentially abnormal.";
  };

  const renderFirstTable = () => (
    <Table size="small">
      <TableBody>
        {[
          "smokerStatus",
          "asthmaStatus",
          "sessionQuality",
          "sessionQualityNotes",
          "interpretation",
          "numberOfTrials",
        ].map((key) => (
          <TableRow key={key}>
            <TableCell>{parameterLabels[key]}</TableCell>
            <TableCell
              sx={{
                bgcolor: isAbnormal(key)
                  ? theme.palette.warning.light
                  : "inherit",
              }}
            >
              <Box display="flex" alignItems="center">
                <TextField
                  fullWidth
                  size="small"
                  value={data[key] || ""}
                  onChange={(e) => handleChange(key, e.target.value)}
                  multiline={
                    key === "interpretation" || key === "sessionQualityNotes"
                  }
                  rows={
                    key === "interpretation" || key === "sessionQualityNotes"
                      ? 2
                      : 1
                  }
                />
                <Tooltip title={getTooltipText(key)} arrow>
                  <Box
                    component="span"
                    sx={{ display: "inline-flex", ml: 1, cursor: "pointer" }}
                  >
                    <Iconify
                      icon={isAbnormal(key) ? "ep:warning-filled" : "mdi:tick"}
                      onClick={() => toggleAbnormal(key)}
                    />
                  </Box>
                </Tooltip>
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  const renderSecondTable = () => (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Measurement</TableCell>
          <TableCell>Best</TableCell>
          <TableCell>Predicted</TableCell>
          <TableCell>% of Predicted</TableCell>
          <TableCell>Variance</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {["fvc", "fev1", "fev1fvcRatio", "pfm"].map((measurement) => (
          <TableRow key={measurement}>
            <TableCell>{parameterLabels[measurement]}</TableCell>
            {["Best", "Pred", "PredPercentage", "Variance"].map((suffix) => (
              <TableCell
                key={suffix}
                sx={{
                  bgcolor: isAbnormal(`${measurement}${suffix}`)
                    ? theme.palette.warning.light
                    : "inherit",
                }}
              >
                <Box display="flex" alignItems="center">
                  <TextField
                    fullWidth
                    size="small"
                    value={data[`${measurement}${suffix}`] || ""}
                    onChange={(e) =>
                      handleChange(`${measurement}${suffix}`, e.target.value)
                    }
                  />
                  <Tooltip
                    title={getTooltipText(`${measurement}${suffix}`)}
                    arrow
                  >
                    <Box
                      component="span"
                      sx={{ display: "inline-flex", ml: 1, cursor: "pointer" }}
                    >
                      <Iconify
                        icon={
                          isAbnormal(`${measurement}${suffix}`)
                            ? "ep:warning-filled"
                            : "mdi:tick"
                        }
                        onClick={() =>
                          toggleAbnormal(`${measurement}${suffix}`)
                        }
                      />
                    </Box>
                  </Tooltip>
                </Box>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  const renderHeader = () => {
    if (abnormalFields.length === 0) {
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">PFT</Typography>
          {saveElem}
        </Box>
      );
    }

    const abnormalFieldNames = abnormalFields
      .map((field) => {
        const [base, suffix] = field
          .match(/^(.+?)(Best|Pred|PredPercentage|Variance)?$/)
          .slice(1);
        return `${parameterLabels[base] || base}${suffix ? ` (${suffix})` : ""}`;
      })
      .join(", ");

    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{
          breakAfter: "avoid",
        }}
      >
        <Box display="flex" alignItems="center">
          <Iconify
            icon="mdi:warning"
            sx={{ mr: 1, color: theme.palette.error.main }}
          />
          <Typography variant="subtitle1">
            Pulmonary Function Test (PFT) | Red Flags: {abnormalFieldNames}
          </Typography>
        </Box>
        {saveElem}
      </Box>
    );
  };

  return (
    <div
      style={{
        backgroundColor: hasChange ? theme.palette.warning.lighter : "inherit",
      }}
    >
      <Card
        sx={{
          p: 2,
          m: 1,
          bgcolor:
            abnormalFields.length > 0
              ? theme.palette.error.light
              : theme.palette.info.lighter,
        }}
      >
        {renderHeader()}
      </Card>
      <Stack spacing={2}>
        <Box>
          <Typography variant="subtitle1" gutterBottom>
            Session Information
          </Typography>
          <TableContainer>{renderFirstTable()}</TableContainer>
        </Box>
        <Box>
          <Typography variant="subtitle1" gutterBottom>
            PFT Measurements
          </Typography>
          <TableContainer>{renderSecondTable()}</TableContainer>
        </Box>
      </Stack>
    </div>
  );
};
