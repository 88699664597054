"use client";

import React, { useState, useEffect } from "react";
import {
  PlusCircle,
  Clock,
  Phone,
  Mail,
  AlertCircle,
  Send,
  Plus,
  CheckCircle2,
  XCircle,
  MessageCircle,
  FileText,
  UserCircle2,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";

import { useDebounce } from "@uidotdev/usehooks";

import { useProtectedApi } from "@/hooks/use-apiCall";
import useVisitStore from "@/store/useVisitStore";

const MroOutreachTab = ({
  timeline,
  setTimeline,
  showAttemptModal,
  setShowAttemptModal,
  showDERNotification,
  setShowDERNotification,
  newAttempt,
  setNewAttempt,
  derEmail,
  setDerEmail,
  derMessage,
  setDerMessage,
}) => {
  const addAttempt = () => {
    setTimeline([
      ...timeline,
      { outreachType: "attempt", timestamp: new Date(), ...newAttempt },
    ]);
    setNewAttempt({ status: "", comments: "" });
    setShowAttemptModal(false);
  };

  const notifyDER = () => {
    setTimeline([
      ...timeline,
      {
        outreachType: "der_notification",
        timestamp: new Date(),
        email: derEmail,
        message: derMessage,
      },
    ]);
    setShowDERNotification(false);
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "connected":
        return <CheckCircle2 className="w-3 h-3 text-green-500" />;
      case "not_reached":
        return <XCircle className="w-3 h-3 text-red-500" />;
      case "voicemail":
        return <MessageCircle className="w-3 h-3 text-yellow-500" />;
      default:
        return <Clock className="w-3 h-3 text-blue-500" />;
    }
  };

  return (
    <div className="w-full p-4 bg-white rounded-lg shadow-md text-sm">
      <div className="mb-4 p-3 bg-gray-50 rounded-md">
        <h3 className="text-base font-medium mb-2 text-gray-700">
          Employee Contact
        </h3>
        <div className="flex items-center space-x-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="outline" size="sm" className="h-8 text-xs">
                  <Phone className="w-3 h-3 mr-1" />
                  <span>555-123-4567</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Click to call employee</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="outline" size="sm" className="h-8 text-xs">
                  <Mail className="w-3 h-3 mr-1" />
                  <span>employee@example.com</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Click to email employee</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
      <div className="mb-4">
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-base font-medium text-gray-700">
            Outreach Attempts
          </h3>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  onClick={() => setShowAttemptModal(true)}
                  size="sm"
                  className="h-7 text-xs"
                >
                  <Plus className="w-3 h-3 mr-1" />
                  <span>Add Attempt</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Log a new outreach attempt</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
      {timeline.filter((item) => item.outreachType === "attempt").length >=
        2 && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={() => setShowDERNotification(true)}
                size="sm"
                className="mb-4 h-7 text-xs"
              >
                <AlertCircle className="w-3 h-3 mr-1" />
                Notify DER
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Send notification to Designated Employer Representative</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
      <Dialog open={showAttemptModal} onOpenChange={setShowAttemptModal}>
        <DialogContent className="max-w-md">
          <DialogHeader>
            <DialogTitle className="text-base">
              Add Outreach Attempt
            </DialogTitle>
            <DialogDescription className="text-sm">
              Log a new outreach attempt to the employee.
            </DialogDescription>
          </DialogHeader>
          <div className="mt-2 space-y-2">
            <Select
              value={newAttempt.status}
              onValueChange={(value) =>
                setNewAttempt({ ...newAttempt, status: value })
              }
            >
              <SelectTrigger className="w-full h-7 text-xs">
                <SelectValue placeholder="Select status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="connected">Connected</SelectItem>
                <SelectItem value="not_reached">Not able to reach</SelectItem>
                <SelectItem value="voicemail">Left voicemail</SelectItem>
              </SelectContent>
            </Select>
            <Textarea
              placeholder="Add comments..."
              value={newAttempt.comments}
              onChange={(e) =>
                setNewAttempt({ ...newAttempt, comments: e.target.value })
              }
              className="w-full text-xs"
            />
          </div>
          <DialogFooter>
            <Button onClick={addAttempt} size="sm" className="h-7 text-xs">
              Add Attempt
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={showDERNotification} onOpenChange={setShowDERNotification}>
        <DialogContent className="max-w-md">
          <DialogHeader>
            <DialogTitle className="text-base">
              Notify Designated Employer Representative
            </DialogTitle>
            <DialogDescription className="text-sm">
              Review and send the email to the DER.
            </DialogDescription>
          </DialogHeader>
          <div className="mt-2">
            <Input
              value={derEmail}
              onChange={(e) => setDerEmail(e.target.value)}
              className="mb-2 text-xs"
            />
            <Textarea
              value={derMessage}
              onChange={(e) => setDerMessage(e.target.value)}
              rows={4}
              className="mb-2 text-xs"
            />
            <Button
              onClick={notifyDER}
              size="sm"
              className="w-full h-7 text-xs"
            >
              <Send className="w-3 h-3 mr-1" />
              Send Notification
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <div>
        <h3 className="text-base font-medium mb-2 text-gray-700">Timeline</h3>
        <div className="space-y-2">
          {timeline.map((item, index) => (
            <div key={index} className="flex items-start">
              <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-100 flex items-center justify-center">
                {item.outreachType === "attempt" ? (
                  getStatusIcon(item.status)
                ) : (
                  <UserCircle2 className="w-3 h-3 text-purple-500" />
                )}
              </div>
              <div className="ml-2">
                <p className="text-xs font-medium text-gray-900">
                  {item.outreachType === "attempt"
                    ? item.status || "Outreach Attempt"
                    : "DER Notified"}
                </p>
                <p className="text-xs text-gray-500">
                  {item.timestamp.toLocaleString()}
                </p>
                {item.outreachType === "attempt" && item.comments && (
                  <p className="text-xs text-gray-600 mt-1">{item.comments}</p>
                )}
                {item.outreachType === "der_notification" && (
                  <p className="text-xs text-gray-600 mt-1">
                    Email sent to: {item.email}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const MroInterviewTab = ({
  agreesToInterview,
  setAgreesToInterview,
  takesPrescriptionMeds,
  setTakesPrescriptionMeds,
  mroNotes,
  newNote,
  setNewNote,
  handleAddNote,
}) => {
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 gap-6">
        <RadioButtonGroup
          label="Agrees to MRO Interview"
          value={agreesToInterview}
          onChange={setAgreesToInterview}
          options={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
        />
        <RadioButtonGroup
          label="Takes Prescription Medications"
          value={takesPrescriptionMeds}
          onChange={setTakesPrescriptionMeds}
          options={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
        />
      </div>

      <div className="space-y-4">
        <Label className="text-sm font-medium text-gray-700">MRO Notes</Label>
        <div className="space-y-4 max-h-96 overflow-y-auto pr-2">
          {mroNotes.map((note, index) => (
            <NoteCard key={index} note={note} />
          ))}
        </div>
        <div className="flex space-x-2">
          <Textarea
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
            placeholder="Enter new MRO note"
            className="text-sm min-h-[120px]"
          />
          <AddNoteButton onClick={handleAddNote} />
        </div>
      </div>
    </div>
  );
};

const SplitTestingTab = ({
  wantsSplitSample,
  setWantsSplitSample,
  splitSampleLab,
  setSplitSampleLab,
  splitSampleDetails,
  setSplitSampleDetails,
  splitSampleComments,
  setSplitSampleComments,
}) => {
  return (
    <div className="space-y-6">
      <RadioButtonGroup
        label="Wants Split Sample Tested"
        value={wantsSplitSample}
        onChange={setWantsSplitSample}
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
      />

      {wantsSplitSample === true && (
        <SplitSampleForm
          splitSampleLab={splitSampleLab}
          setSplitSampleLab={setSplitSampleLab}
          splitSampleDetails={splitSampleDetails}
          setSplitSampleDetails={setSplitSampleDetails}
          splitSampleComments={splitSampleComments}
          setSplitSampleComments={setSplitSampleComments}
        />
      )}
    </div>
  );
};

const RadioButtonGroup = ({ label, value, onChange, options }) => (
  <div className="space-y-2">
    <Label className="text-sm font-medium text-gray-700">{label}</Label>
    <RadioGroup
      value={value}
      onValueChange={onChange}
      className="flex space-x-4"
    >
      {options.map((option) => (
        <div key={option.value} className="flex items-center space-x-2">
          <RadioGroupItem
            value={option.value}
            id={`${label}-${option.value}`}
          />
          <Label htmlFor={`${label}-${option.value}`} className="text-sm">
            {option.label}
          </Label>
        </div>
      ))}
    </RadioGroup>
  </div>
);

const NoteCard = ({ note }) => (
  <div className="p-4 bg-white rounded-md text-sm text-gray-700 shadow-sm">
    <div className="flex justify-between items-center mb-2">
      <span className="text-gray-500 text-xs">{note.timestamp}</span>
      <Clock className="w-4 h-4 text-gray-400" />
    </div>
    {note.text}
  </div>
);

const AddNoteButton = ({ onClick }) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          onClick={onClick}
          size="lg"
          variant="outline"
          className="h-full px-4"
        >
          <PlusCircle className="w-6 h-6" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        <p className="text-sm">Add new MRO note</p>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

const SplitSampleForm = ({
  splitSampleLab,
  setSplitSampleLab,
  splitSampleDetails,
  setSplitSampleDetails,
  splitSampleComments,
  setSplitSampleComments,
}) => (
  <div className="space-y-6 p-6 bg-white rounded-lg shadow-inner">
    <div className="space-y-2">
      <Label className="text-sm font-medium text-gray-700">
        Split Sample Lab
      </Label>
      <Select value={splitSampleLab} onValueChange={setSplitSampleLab}>
        <SelectTrigger className="w-full text-sm">
          <SelectValue placeholder="Select lab" />
        </SelectTrigger>
        <SelectContent>
          {["CRL", "LabCorp", "Quest", "PacTox", "National Jewish"].map(
            (lab) => (
              <SelectItem key={lab} value={lab}>
                {lab}
              </SelectItem>
            )
          )}
        </SelectContent>
      </Select>
    </div>

    <div className="space-y-2">
      <Label className="text-sm font-medium text-gray-700">
        Split Sample Testing Details
      </Label>
      <Textarea
        value={splitSampleDetails}
        onChange={(e) => setSplitSampleDetails(e.target.value)}
        placeholder="Enter split sample testing details"
        className="text-sm min-h-[120px]"
      />
    </div>

    <div className="space-y-2">
      <Label className="text-sm font-medium text-gray-700">
        Split Sample Comments
      </Label>
      <Textarea
        value={splitSampleComments}
        onChange={(e) => setSplitSampleComments(e.target.value)}
        placeholder="Enter comments on split sample results"
        className="text-sm min-h-[120px]"
      />
    </div>
  </div>
);

const derOptionsPositive = [
  { value: "MRO_POSITIVE", text: "MRO positive for ____" },
  { value: "MRO_POSITIVE_DILUTE", text: "MRO positive for ____, dilute" },
  {
    value: "MRO_POSITIVE_UNREACHABLE",
    text: "MRO positive for ____ Unable to reach donor to conduct interview.",
  },
  {
    value: "MRO_POSITIVE_DILUTE_UNREACHABLE",
    text: "MRO positive for ____, dilute. Unable to reach donor to conduct interview.",
  },
  {
    value: "MRO_TEST_CANCELED_IMMEDIATE_RECOLLECTION",
    text: "MRO test canceled. Immediate recollection with direct observation required.",
  },
  {
    value: "MRO_TEST_CANCELED_IMMEDIATE_RECOLLECTION_RECOMMENDED",
    text: "MRO test canceled. Immediate recollection with direct observation recommended where permitted by law.",
  },
  {
    value: "MRO_TEST_CANCELED_NO_FURTHER_ACTION",
    text: "MRO test canceled. No further action is necessary unless a negative result is required.",
  },
  {
    value: "MRO_TEST_CANCELED_FATAL_FLAW",
    text: "MRO test canceled. Fatal flaw.",
  },
  {
    value: "MRO_TEST_CANCELED_SPECIMEN_REJECTED",
    text: "MRO test canceled. Specimen rejected by lab.",
  },
  {
    value: "MRO_REFUSAL_TO_TEST_SUBSTITUTION",
    text: "MRO refusal to test: substitution.",
  },
  {
    value: "MRO_REFUSAL_TO_TEST_ADULTERATION",
    text: "MRO refusal to test: adulteration.",
  },
  { value: "MRO_REFUSAL_TO_TEST", text: "MRO refusal to test" },
  {
    value: "COLLECTION_SITE_EVENT_FINAL_DETERMINATION",
    text: "Collection site event. Final determination per DER.",
  },
  {
    value: "COLLECTED_UNDER_DIRECT_OBSERVATION",
    text: "Collected under direct observation",
  },
  { value: "SPLIT_SPECIMEN_RECONFIRMED", text: "Split specimen reconfirmed" },
];

const ClearanceStatusSelect = ({ value, onChange }) => {
  return (
    <div className="space-y-2">
      <Label
        htmlFor="clearance-case"
        className="text-sm font-medium text-gray-700"
      >
        Clearance Status
      </Label>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Select value={value} onValueChange={onChange}>
              <SelectTrigger id="clearance-case" className="w-full">
                <SelectValue placeholder="Select clearance case" />
              </SelectTrigger>
              <SelectContent>
                {derOptionsPositive.map((option, index) => (
                  <SelectItem
                    key={index}
                    value={option.text}
                    className="text-xs"
                  >
                    {option.text}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </TooltipTrigger>
          <TooltipContent>
            <p>Select the clearance status for this case</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

export function DrugScreenClearancePositive({
  currentStatus,
  drugService,
  onClear,
}) {
  //// MRO Outreach Tab

  const { callApi } = useProtectedApi();
  const { visit, loadVisit } = useVisitStore();

  const drugProcessStatus = visit.drugProcessStatus[drugService._id];
  const drugAlcoholClearance = visit.drugAlcoholClearance[drugService._id];

  const [timeline, setTimeline] = useState(drugProcessStatus?.timeline || []);
  const [showAttemptModal, setShowAttemptModal] = useState(false);
  const [showDERNotification, setShowDERNotification] = useState(false);
  const [newAttempt, setNewAttempt] = useState({ status: "", comments: "" });
  const [derEmail, setDerEmail] = useState("der@company.com");
  const [derMessage, setDerMessage] = useState(
    `Dear DER,\n\nWe have made multiple attempts to reach the employee regarding their non-negative drug screen result. Please advise on next steps.\n\nBest regards,\nOccupational Health Team`
  );
  //// MRO Interview Tab
  const [mroNotes, setMroNotes] = useState(drugProcessStatus?.mroNotes || []);
  const [newNote, setNewNote] = useState("");
  const [agreesToInterview, setAgreesToInterview] = useState(
    drugProcessStatus?.agreesToInterview || false
  );
  const [takesPrescriptionMeds, setTakesPrescriptionMeds] = useState(
    drugProcessStatus?.takesPrescriptionMeds || false
  );
  //// Split Testing Tab
  const [wantsSplitSample, setWantsSplitSample] = useState(
    drugProcessStatus?.wantsSplitSample || false
  );
  const [splitSampleLab, setSplitSampleLab] = useState(
    drugProcessStatus?.splitSampleLab || ""
  );
  const [splitSampleDetails, setSplitSampleDetails] = useState(
    drugProcessStatus?.splitSampleDetails || ""
  );
  const [splitSampleComments, setSplitSampleComments] = useState(
    drugProcessStatus?.splitSampleComments || ""
  );

  const deb_splitSampleDetails = useDebounce(splitSampleDetails, 1000);
  const deb_splitSampleComments = useDebounce(splitSampleComments, 1000);

  const [finalClearanceText, setFinalClearanceText] = useState(
    drugAlcoholClearance?.finalClearanceText || null
  );

  const updateDrugProcessStatus = async () => {
    try {
      await callApi("/visit/updateDrugProcessStatus", {
        visitId: visit._id,
        entityId: drugService._id,
        payload: {
          timeline,
          //
          mroNotes,
          agreesToInterview,
          takesPrescriptionMeds,
          //
          wantsSplitSample,
          splitSampleLab,
          deb_splitSampleDetails,
          deb_splitSampleComments,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onSaveClearance = async () => {
    try {
      const resp = await callApi("/visit/updateVisitClearance", {
        visitId: visit._id,
        entityId: drugService._id,
        entity: "Drug",
        drugClearance: {
          finalClearanceText,
        },
      });
      onClear(resp);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    updateDrugProcessStatus();
  }, [
    timeline,
    mroNotes,
    agreesToInterview,
    takesPrescriptionMeds,
    wantsSplitSample,
    splitSampleLab,
    deb_splitSampleDetails,
    deb_splitSampleComments,
  ]);

  const handleAddNote = () => {
    if (newNote.trim()) {
      const now = new Date();
      setMroNotes([
        ...mroNotes,
        {
          text: newNote.trim(),
          timestamp: now.toLocaleString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }),
        },
      ]);
      setNewNote("");
    }
  };

  return (
    <Card className="w-full shadow-lg bg-gradient-to-br from-gray-50 to-gray-100">
      <Tabs defaultValue="mro-outreach" className="w-full">
        <TabsList className="grid w-full grid-cols-3">
          <TabsTrigger value="mro-outreach" className="text-sm font-medium">
            MRO Outreach
          </TabsTrigger>
          <TabsTrigger value="mro-interview" className="text-sm font-medium">
            MRO Interview
          </TabsTrigger>
          <TabsTrigger value="split-testing" className="text-sm font-medium">
            Split Testing
          </TabsTrigger>
        </TabsList>
        <CardContent className="pt-6">
          <TabsContent value="mro-outreach">
            <MroOutreachTab
              timeline={timeline}
              setTimeline={setTimeline}
              showAttemptModal={showAttemptModal}
              setShowAttemptModal={setShowAttemptModal}
              showDERNotification={showDERNotification}
              setShowDERNotification={setShowDERNotification}
              newAttempt={newAttempt}
              setNewAttempt={setNewAttempt}
              derEmail={derEmail}
              setDerEmail={setDerEmail}
              derMessage={derMessage}
              setDerMessage={setDerMessage}
            />
          </TabsContent>
          <TabsContent value="mro-interview">
            <MroInterviewTab
              agreesToInterview={agreesToInterview}
              setAgreesToInterview={setAgreesToInterview}
              takesPrescriptionMeds={takesPrescriptionMeds}
              setTakesPrescriptionMeds={setTakesPrescriptionMeds}
              mroNotes={mroNotes}
              newNote={newNote}
              setNewNote={setNewNote}
              handleAddNote={handleAddNote}
            />
          </TabsContent>
          <TabsContent value="split-testing">
            <SplitTestingTab
              wantsSplitSample={wantsSplitSample}
              setWantsSplitSample={setWantsSplitSample}
              splitSampleLab={splitSampleLab}
              setSplitSampleLab={setSplitSampleLab}
              splitSampleDetails={splitSampleDetails}
              setSplitSampleDetails={setSplitSampleDetails}
              splitSampleComments={splitSampleComments}
              setSplitSampleComments={setSplitSampleComments}
            />
          </TabsContent>
        </CardContent>
      </Tabs>
      <div>
        <div className="p-4">
          <ClearanceStatusSelect
            value={finalClearanceText}
            onChange={(value) => setFinalClearanceText(value)}
          />
        </div>
      </div>
      <CardFooter className="flex justify-end">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={() => {
                  onSaveClearance();
                }}
                className="bg-blue-600 hover:bg-blue-700 text-white mt-4"
              >
                <FileText className="mr-2 h-4 w-4" />
                Preview & Process Clearance
              </Button>
            </TooltipTrigger>
            <TooltipContent side="top" className="text-xs max-w-xs">
              <p>
                Preview and process the drug screen clearance based on the
                current information
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </CardFooter>
    </Card>
  );
}
