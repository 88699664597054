export function addUrlParams(params) {
  const currentUrl = new URL(window.location.href);
  const searchParams = currentUrl.searchParams;

  // Add or update each parameter
  for (const [key, value] of Object.entries(params)) {
    searchParams.set(key, value);
  }

  // Update the URL with the new search params
  const newUrl = currentUrl.toString();

  // Update the browser URL without reloading the page
  window.history.pushState({}, "", newUrl);
}

export function getUrlParams() {
  const urlParams = new URLSearchParams(window.location.search);
  const params = {};

  // Loop through each parameter and add it to the params object
  for (const [key, value] of urlParams) {
    params[key] = value;
  }

  return params;
}
