import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { WithAPICall } from "../utils/apiUtil";
import cogoToast from "cogo-toast";
import { LoadingButton } from "@mui/lab";

class ProviderServiceCreate extends React.Component {
  state = {
    providerServiceAlias: "",
    isCreating: false,
  };
  createFromName = async () => {
    this.setState({ isCreating: true });
    try {
      cogoToast.loading(
        `Creating Provider${
          this.props.doesApplyToNetwork ? " Network " : " "
        }Service Link`
      );
      let payload = {
        doesApplyToNetwork: this.props.doesApplyToNetwork,
        providerServiceAlias: this.state.providerServiceAlias,
      };
      if (this.props.doesApplyToNetwork) {
        payload["networkId"] = this.props.id;
      } else {
        payload["providerId"] = this.props.id;
        let d = this.props.data;
        if (d && d.providerNetwork && d.providerNetwork._id) {
          payload["networkId"] = d.providerNetwork._id;
        }
      }
      const res = await this.props.apiCallPost(
        "/provider-service-link/createProviderServiceLinkFromName",
        payload
      );
      this.props.onCreate(res);
      cogoToast.success(
        `Provider${
          this.props.doesApplyToNetwork ? " Network " : " "
        }Service Link Created`
      );
    } catch (err) {
      cogoToast.error("Error Creating Service Link");
      console.log(err);
    }
    this.setState({ isCreating: false });
  };
  render() {
    return (
      <Dialog open={this.props.isOpen} onClose={() => this.props.handleClose()}>
        <DialogTitle>
          Create A New Provider
          {this.props.doesApplyToNetwork ? " Network " : " "}Service Link
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the name of the Provider
            {this.props.doesApplyToNetwork ? " Network " : " "}Service to get
            started. <br />
            <br />
            <br />
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label={`Provider${
              this.props.doesApplyToNetwork ? " Network " : " "
            }Service Name`}
            fullWidth
            disabled={this.state.isCreating}
            value={this.state.providerServiceAlias}
            onChange={(e) => {
              this.setState({ providerServiceAlias: e.target.value });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.handleClose()}
            disabled={this.state.isCreating}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={this.state.isCreating}
            variant="contained"
            disabled={!this.state.providerServiceAlias}
            onClick={() => this.createFromName()}
          >
            Create Provider
            {this.props.doesApplyToNetwork ? " Network " : " "}Service Link
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}
export default WithAPICall(ProviderServiceCreate);
