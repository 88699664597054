import React from "react";

import { WithAPICall } from "../../utils/apiUtil";
import {
  Alert,
  Button,
  Drawer,
  IconButton,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { View500 } from "src/sections/error";
import cogoToast from "cogo-toast";
import { Box, Stack } from "@mui/system";
import Iconify from "src/components/iconify";
import DocumentGroup from "../DocumentGroup";
import _ from "lodash";
import {
  FILTER_UTILS,
  sumAggregation,
  averageAggregation,
  maxAggregation,
  minAggregation,
  countAggregation,
} from "../../../utils/grid-utils";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

class DocTabReviewFileGroups extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    showLinkedToVisit: false,
    showReviewed: false,
    groupList: [],
    currentlyOpenedGroupId: null,
    gridApi: null,
    columnApi: null,
    quickSearchText: "",
    advancedMode: false,
    startTime: null,
    endTime: null,
    aggregations: {
      sum: (params) => sumAggregation(params),
      average: (params) => averageAggregation(params),
      max: (params) => maxAggregation(params),
      min: (params) => minAggregation(params),
      count: (params) => countAggregation(params),
    },
  };
  componentDidMount() {
    this.getGroups();
  }
  getGroups = async () => {
    try {
      let res = await this.props.apiCallPost("/files/groups/search", {
        isLinkedToVisit: [false],
        createdIn: [],
      });
      this.setState({
        isLoading: false,
        groupList: res,
      });
    } catch (err) {
      cogoToast.error("Error getting file groups");
      this.setState({
        isError: true,
        isLoading: false,
      });
    }
  };
  startTimer = () => {
    this.setState({
      startTime: new Date(),
    });
  };
  endTimer = (id) => {
    this.setState(
      {
        endTime: new Date(),
      },
      () => {
        this.telemetryTime(id);
      }
    );
  };
  telemetryTime = async (id) => {
    if (!this.state.startTime || !this.state.endTime) return;
    let timeTaken = this.state.endTime - this.state.startTime;
    if (timeTaken < 0) return;
    if (timeTaken > 15 * 60 * 1000) {
      return;
    }
    let secondsElapsed = Math.round(timeTaken / 1000);
    try {
      let groups = this.state.groupList;
      let group = groups[_.findIndex(groups, { _id: id })];
      let pageDataType = group.pageDataType;
      await this.props.apiCallPost("/analytics/timelog", {
        studyEntityType: "DATA_ENTRY_REVIEW",
        studyEntitySubType: pageDataType,
        studyEntityId: id,
        secondsElapsed: secondsElapsed,
      });
      this.setState({
        startTime: null,
        endTime: null,
      });
    } catch (err) {
      console.error(err);
    }
  };
  renderGroupDrawer = () => {
    if (!this.state.currentlyOpenedGroupId) return null;
    let group = _.find(this.state.groupList, {
      _id: this.state.currentlyOpenedGroupId,
    });
    let idx = _.findIndex(this.state.groupList, {
      _id: this.state.currentlyOpenedGroupId,
    });
    let groupId = this.state.currentlyOpenedGroupId;
    return (
      <Drawer
        anchor="right"
        open={this.state.currentlyOpenedGroupId !== null}
        onClose={() => {
          this.setState(
            {
              currentlyOpenedGroupId: null,
            },
            () => {
              this.endTimer(groupId);
            }
          );
        }}
      >
        <div
          style={{
            width: "93vw",
            overflow: "auto",
            padding: "1rem",
            backgroundColor: "white",
          }}
        >
          <DocumentGroup
            data={group}
            markAsIrrelevant={() => {
              this.reportIrrelevant(idx);
            }}
            markAsReclassify={() => {
              this.reportMisclassified(idx);
            }}
          />
        </div>
      </Drawer>
    );
  };
  reportMisclassified = async (index) => {
    let groups = this.state.groupList;
    let group = groups[index];
    let groupId = group._id;
    let parentFile = group.parentFile;
    let newGroupList = groups;
    let allGroups = _.filter(groups, { parentFile: parentFile });
    allGroups.forEach((g) => {
      g.isAPICall = true;
      newGroupList[_.findIndex(groups, { _id: g._id })] = g;
    });
    this.setState(
      {
        groupList: newGroupList,
        currentlyOpenedGroupId: null,
      },
      () => {
        this.endTimer(groupId);
      }
    );

    try {
      await this.props.apiCallPost("/files/setStatus", {
        cfId: parentFile._id,
        processStatus: "NEEDS_MANUAL_CLASSIFICATION",
      });
      await this.props.apiCallPost("/files/groups/setAllArchived", {
        cfId: parentFile._id,
      });
      cogoToast.success(
        "File has been pushed to the queue for classification."
      );

      allGroups.forEach((g) => {
        g.isAPICall = false;
        g.processStatus = "NEEDS_MANUAL_CLASSIFICATION";
        newGroupList[_.findIndex(groups, { _id: g._id })] = g;
      });
      this.setState({
        groupList: newGroupList,
      });
    } catch (err) {
      cogoToast.error("Error in putting file in reclassification queue");
      allGroups.forEach((g) => {
        g.isAPICall = false;
        newGroupList[_.findIndex(groups, { _id: g._id })] = g;
      });
      this.setState({
        groupList: newGroupList,
      });
    }
  };
  reportIrrelevant = async (index) => {
    let groups = this.state.groupList;
    let group = groups[index];
    let groupId = group._id;
    group.isAPICall = true;
    groups[index] = group;
    this.setState(
      {
        currentlyOpenedGroupId: null,
        groupList: groups,
      },
      () => {
        this.endTimer(groupId);
      }
    );

    try {
      await this.props.apiCallPost("/files/groups/updateInfo", {
        groupId: group._id,
        uops: [
          {
            key: "isArchived",
            value: true,
          },
        ],
      });
      cogoToast.success("File marked as irrelevant.");
      group.isAPICall = false;
      group.processStatus = "IRRELEVANT";
      groups[index] = group;
      this.setState({
        groupList: groups,
      });
      cogoToast.success("File has been marked as irrelevant.");
    } catch (err) {
      cogoToast.error("Error in marking file as irrelevant");
      group.isAPICall = false;
      groups[index] = group;
      this.setState({
        groupList: groups,
      });
    }
  };
  renderGridSearcher = () => {
    return (
      <Stack direction="row" spacing={1}>
        <TextField
          label="Search"
          variant="outlined"
          value={this.state.quickSearchText}
          onChange={(e) => {
            this.setState({
              quickSearchText: e.target.value,
            });
          }}
        />
        <Tooltip
          title={
            this.state.advancedMode
              ? "Currently In Reporting Mode"
              : "Currently In Document Review Mode"
          }
        >
          <IconButton
            onClick={() => {
              this.setState({
                advancedMode: !this.state.advancedMode,
              });
            }}
          >
            <Iconify
              icon={
                this.state.advancedMode
                  ? "mdi:report-multiple"
                  : "fontisto:preview"
              }
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Refresh">
          <IconButton
            onClick={() => {
              this.getGroups();
            }}
          >
            <Iconify icon="mdi:refresh" />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };
  COLUMNS = [
    {
      headerName: "File Type",
      field: "pageDataType",
      cellDataType: "text",
      valueGetter: (params) => {
        return params.data.pageDataType;
      },
      cellRenderer: (row) => {
        if (this.state.advancedMode) {
          return row.data.pageDataType;
        }
        let proceedToHumanReviewButton = (
          <Box>
            <Button
              size="small"
              onClick={() => {
                let id = row.data._id;
                this.setState(
                  {
                    currentlyOpenedGroupId: id,
                  },
                  () => {
                    this.startTimer();
                  }
                );
              }}
              startIcon={<Iconify icon="mdi:arrow-right" />}
            >
              {row.data.pageDataType}
            </Button>
          </Box>
        );
        return proceedToHumanReviewButton;
      },
    },
    {
      headerName: "Employee Name (Extracted)",
      field: "extractedEEName",
      cellDataType: "text",
    },
    {
      headerName: "Status",
      cellDataType: "text",
      valueGetter: (params) => {
        let processStatusElem = params.data.processStatus;
        if (processStatusElem === "IN_PROGRESS") {
          processStatusElem = "Queued";
        }
        if (processStatusElem === "READING") {
          processStatusElem = "Processing";
        }
        if (processStatusElem === "DONE") {
          processStatusElem = "Ready For Human Review";
        }
        if (processStatusElem === "ERROR") {
          processStatusElem = "AI Processing Error";
        }
        if (processStatusElem === "HUMAN_REVIEWED") {
          processStatusElem = "Human Reviewed";
        }
        if (params.data.isAPICall) {
          return "Updating...";
        }
        return _.startCase(_.lowerCase(processStatusElem));
      },
    },
    {
      headerName: "File Uploaded At",
      field: "parentCreatedAt",
      cellDataType: "time",
    },
    {
      headerName: "Hours Since File Receipt",
      cellDataType: "number",
      valueGetter: (params) => {
        return (
          (new Date().getTime() -
            new Date(params.data.parentCreatedAt).getTime()) /
          1000 /
          60 /
          60
        );
      },
      valueFormatter: (params) => {
        return params.value.toFixed(2);
      },
    },
    {
      headerName: "AI Processed At",
      field: "dataStructuredAt",
      cellDataType: "time",
    },
    {
      headerName: "Hours Waiting For Human Review",
      cellDataType: "number",
      valueGetter: (params) => {
        return (
          (new Date().getTime() -
            new Date(params.data.dataStructuredAt).getTime()) /
          1000 /
          60 /
          60
        );
      },
      valueFormatter: (params) => {
        return params.value.toFixed(2);
      },
    },
  ];
  onGridReady = (params) => {
    this.setState({ gridApi: params.api, columnApi: params.columnApi });
  };
  renderGroupList = () => {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.groupList.length === 0) {
      return <Alert severity="info">No File Groups to Review</Alert>;
    }
    let cs = "ag-theme-quartz";
    let theme = localStorage.getItem("themeMode");
    if (theme && theme === "dark") {
      cs = "ag-theme-quartz-dark";
    }
    return (
      <Stack>
        {this.renderGridSearcher()}
        <div className={cs} style={{ height: "70vh" }}>
          <AgGridReact
            onGridReady={this.onGridReady}
            rowData={this.state.groupList}
            columnDefs={this.COLUMNS.map((x) => {
              return {
                ...FILTER_UTILS[x.cellDataType],
                ...x,
              };
            })}
            defaultColDef={{
              sortable: true,
            }}
            autoSizeStrategy={{
              type: "fitCellContents",
            }}
            quickFilterText={this.state.quickSearchText}
            pagination={true}
            rowGroupPanelShow={this.state.advancedMode ? "always" : "never"}
            aggFuncs={this.state.aggregations}
            suppressDragLeaveHidesColumns={true}
            rowSelection="multiple"
            rowDragManaged={true}
            enableCharts={true}
            enableRangeSelection={true}
            enableCellTextSelection={true}
            nableAdvancedFilter={this.state.advancedMode}
            sideBar={true}
            animateRows={true}
            statusBar={{
              statusPanels: [
                {
                  statusPanel: "agTotalAndFilteredRowCountComponent",
                  align: "left",
                },
                {
                  statusPanel: "agSelectedRowCountComponent",
                  align: "left",
                },
                {
                  statusPanel: "agAggregationComponent",
                  align: "right",
                },
              ],
            }}
            defaultExcelExportParams={{
              author: "WorkCare",
              fileName: `Document Review - ${new Date().toLocaleDateString()}`,
              sheetName: `${new Date().toLocaleDateString()}`,
            }}
          />
        </div>
      </Stack>
    );
    // return (
    //   <Table size="small">
    //     <TableHead>
    //       <TableRow>
    //         <TableCell>File Name</TableCell>
    //         <TableCell>Status</TableCell>
    //         <TableCell>Action</TableCell>
    //         <TableCell>Last Updated</TableCell>
    //       </TableRow>
    //     </TableHead>
    //     <TableBody>
    //       {this.state.groupList.map((group, index) => {
    //         let processStatusElem = null;
    //         let ps = group.processStatus;
    //         let proceedToHumanReviewButton = (
    //           <Box>
    //             <Button
    //               size="small"
    //               onClick={() => {
    //                 let id = group._id;
    //                 this.setState({
    //                   currentlyOpenedGroupId: id,
    //                 });
    //               }}
    //               variant="contained"
    //               color="secondary"
    //               startIcon={<Iconify icon="mdi:arrow-right" />}
    //             >
    //               Proceed to Human Review
    //             </Button>
    //           </Box>
    //         );
    //         let actionBar = (
    //           <Stack direction="row" spacing={0.5}>
    //             <LoadingButton
    //               size="small"
    //               loading={group.isAPICall}
    //               onClick={() => {
    //                 this.reportMisclassified(index);
    //               }}
    //             >
    //               This file is misclassified
    //             </LoadingButton>
    //             <LoadingButton
    //               size="small"
    //               variant="outlined"
    //               color="error"
    //               startIcon={<Iconify icon="mdi:trash-can-outline" />}
    //               loading={group.isAPICall}
    //               onClick={() => {
    //                 this.reportIrrelevant(index);
    //               }}
    //             >
    //               This file is irrelevant
    //             </LoadingButton>
    //           </Stack>
    //         );
    //         if (["IRRELEVANT", "NEEDS_MANUAL_CLASSIFICATION"].includes(ps)) {
    //           actionBar = null;
    //         }
    //         if (group.isAPICall) {
    //           processStatusElem = (
    //             <Stack>
    //               <LinearProgress />
    //             </Stack>
    //           );
    //         } else if (ps === "IRRELEVANT") {
    //           processStatusElem = (
    //             <Stack direction="row" spacing={0.5}>
    //               <Iconify icon="solar:trash-bin-minimalistic-broken" />
    //               <Typography variant="overline">Irrelevant</Typography>
    //             </Stack>
    //           );
    //         } else {
    //           if (ps === "NEEDS_MANUAL_CLASSIFICATION") {
    //             processStatusElem = (
    //               <Stack direction="row" spacing={0.5}>
    //                 <Iconify icon="mingcute:classify-2-fill" />
    //                 <Typography variant="overline">
    //                   Needs Manual Classification
    //                 </Typography>
    //               </Stack>
    //             );
    //           } else if (ps === "IN_PROGRESS") {
    //             processStatusElem = (
    //               <Stack>
    //                 <LinearProgress />
    //                 <Typography variant="overline">Queued</Typography>
    //               </Stack>
    //             );
    //           } else if (ps === "READING") {
    //             processStatusElem = (
    //               <Stack>
    //                 <LinearProgress />
    //                 <Typography variant="overline">Processing</Typography>
    //               </Stack>
    //             );
    //           } else if (ps === "DONE") {
    //             processStatusElem = (
    //               <Stack direction="column" spacing={0.5}>
    //                 <Stack direction="row" spacing={0.5}>
    //                   <Iconify icon="mdi:check" />
    //                   <Typography variant="overline" color="success">
    //                     Ready For Human Review
    //                   </Typography>
    //                 </Stack>
    //                 {proceedToHumanReviewButton}
    //               </Stack>
    //             );
    //           } else if (ps === "ERROR") {
    //             processStatusElem = (
    //               <Stack direction="column" spacing={0.5}>
    //                 <Stack direction="row" spacing={0.5}>
    //                   <Iconify icon="fluent-emoji-flat:warning" />
    //                   <Typography variant="overline" color={"warning"}>
    //                     AI Processing Error
    //                   </Typography>
    //                 </Stack>
    //                 {proceedToHumanReviewButton}
    //               </Stack>
    //             );
    //           } else if (ps === "HUMAN_REVIEWED") {
    //             processStatusElem = (
    //               <Stack direction="row" spacing={0.5}>
    //                 <Iconify icon="mdi:tick-decagram" />
    //                 <Typography variant="overline" color="success">
    //                   Human Reviewed
    //                 </Typography>
    //               </Stack>
    //             );
    //           }
    //         }
    //         return (
    //           <TableRow>
    //             <TableCell>
    //               <Stack direction="row" spacing={0.5}>
    //                 <Stack>
    //                   <Typography variant="overline">
    //                     {group.pageDataType}
    //                   </Typography>
    //                   <Typography variant="overline" color="secondary">
    //                     {group.extractedEEName}
    //                   </Typography>
    //                 </Stack>
    //               </Stack>
    //             </TableCell>
    //             <TableCell>{processStatusElem}</TableCell>
    //             <TableCell>{actionBar}</TableCell>
    //             <TableCell>
    //               <Typography variant="overline">
    //                 {new Date(group.updatedAt).toLocaleString()}
    //               </Typography>
    //             </TableCell>
    //           </TableRow>
    //         );
    //       })}
    //     </TableBody>
    //   </Table>
    // );
  };
  renderHeader = () => {
    return (
      <Stack
        direction="row"
        spacing={1}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h5">Documents For Data Entry Review</Typography>
        <Stack direction="row" spacing={1} alignItems={"center"}>
          <Button
            size="small"
            onClick={() => {
              this.setState({
                showLinkedToVisit: !this.state.showLinkedToVisit,
              });
            }}
          >
            {this.state.showLinkedToVisit
              ? "Showing Files Already Linked To Visit"
              : "Showing Files Not Linked To Visits"}
          </Button>
          <Button
            size="small"
            onClick={() => {
              this.setState({
                showReviewed: !this.state.showReviewed,
              });
            }}
          >
            {this.state.showReviewed
              ? "Showing Files Already Reviewed"
              : "Showing Files Not Reviewed"}
          </Button>
          <IconButton
            onClick={() => {
              this.getGroups();
            }}
          >
            <Iconify icon="material-symbols:search" />
          </IconButton>
        </Stack>
      </Stack>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <Stack spacing={1}>
        {this.renderGroupDrawer()}
        {this.renderGroupList()}
      </Stack>
    );
  }
}

export default WithAPICall(DocTabReviewFileGroups);
