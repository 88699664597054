import { useState, useEffect } from "react";

import { paths } from "src/routes/paths";

import Iconify from "src/components/iconify";
import { useAuth } from "@clerk/clerk-react";
import axios from "axios";

// ----------------------------------------------------------------------

const apiUrl = process.env.REACT_APP_API_URL;
// ----------------------------------------------------------------------

export function useNavData() {
  const { getToken } = useAuth();
  const provideToken = async () => {
    return await getToken();
  };

  const apiCallPost = async (path, datum) => {
    const token = await provideToken();
    if (!token) {
      setCurrentUser(null);
      setLoading(false);
      throw new Error("No token");
    }
    const resp = await axios.post(apiUrl + path, datum, {
      credentials: "include",
      headers: { Authorization: `Bearer ${token}` },
    });
    return resp.data;
  };
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(null);

  const getCurrentUser = async () => {
    const response = await apiCallPost("/user/getCurrentUser", {});
    return response;
  };

  useEffect(() => {
    setLoading(true);
    const load = async () => {
      try {
        let r = await getCurrentUser();
        setCurrentUser(r);
        setLoading(false);
      } catch (error) {
        setCurrentUser(null);
        setLoading(false);
      }
    };
    load();
  }, []);
  if (loading) return [];
  let cu = currentUser;
  if (!cu) return [];
  let isExternal = cu.isExternal;
  let isEmployee = cu.isEmployee;
  if (isExternal && !isEmployee) {
    return [
      {
        items: [
          {
            title: "Dashboard",
            path: paths.dashboard.root,
            icon: <Iconify icon="mdi:home" />,
          },
          {
            title: "Employee Roster",
            path: `/employee-roster`,
            icon: <Iconify icon="bi:people-fill" />,
          },
          {
            title: "All Visits / Clearances",
            path: paths.dashboard.visits,
            icon: <Iconify icon="solar:medical-kit-broken" />,
          },
          {
            title: "Requested Visits",
            path: "/visits/requested",
            icon: <Iconify icon="ic:outline-add-task" />,
          },
          {
            title: "Request Visit",
            path: "/visits/request",
            icon: <Iconify icon="ion:person-add" />,
          },
          {
            title: "Schedule Visit",
            path: "/visits/new",
            icon: <Iconify icon="material-symbols:schedule-outline" />,
          },
        ],
      },
    ];
  } else if (isExternal && isEmployee) {
    return [
      {
        subheader: "overview", // STAGING
        items: [
          {
            title: "Visits",
            path: paths.dashboard.visits,
            icon: <Iconify icon="solar:medical-kit-broken" />,
          },
          {
            title: "Requests",
            path: paths.dashboard.transfers,
            icon: <Iconify icon="solar:transfer-horizontal-bold-duotone" />,
          },
        ],
      },
    ];
  } else if (!isExternal && isEmployee) {
    return [
      {
        subheader: "overview", // STAGING
        items: [
          {
            title: "All Visits",
            path: "/",
            icon: <Iconify icon="solar:medical-kit-broken" />,
          },
          {
            title: "Upcoming Visits",
            path: "/upcoming",
            icon: <Iconify icon="material-symbols:event-upcoming-rounded" />,
          },
          {
            title: "Completed Visits",
            path: "/completed",
            icon: <Iconify icon="ic:round-download-done" />,
          },
          {
            title: "In-Process Visits",
            path: "/in-process",
            icon: <Iconify icon="uil:process" />,
          },
        ],
      },
    ];
  } else {
    return [
      {
        subheader: "overview", // STAGING
        items: [
          {
            title: "Dashboard",
            path: paths.dashboard.root,
            icon: <Iconify icon="mdi:home" />,
          },
          {
            title: "Employers",
            path: paths.dashboard.employers,
            icon: <Iconify icon="mdi:account-group" />,
          },
          {
            title: "Networks",
            path: paths.dashboard.networks,
            icon: <Iconify icon="solar:hospital-bold-duotone" />,
          },
          {
            title: "Providers",
            path: paths.dashboard.providers,
            icon: <Iconify icon="streamline:medical-cross-symbol-solid" />,
          },
          {
            title: "Services",
            path: paths.dashboard.services,
            icon: <Iconify icon="la:file-medical-alt" />,
          },
          {
            title: "Lab Pricing",
            path: paths.dashboard.labpricings,
            icon: <Iconify icon="entypo:lab-flask" />,
          },
          {
            title: "Protocols",
            path: paths.dashboard.masterprotocols,
            icon: <Iconify icon="solar:document-medicine-outline" />,
          },
          {
            title: "Med. Surveillance",
            path: paths.dashboard.surveillance,
            icon: <Iconify icon="solar:clock-circle-broken" />,
          },
          {
            title: "Visits",
            path: paths.dashboard.visits,
            icon: <Iconify icon="solar:medical-kit-broken" />,
          },
          // {
          //   title: "Documents",
          //   path: paths.dashboard.groups,
          //   icon: <Iconify icon="solar:cloud-file-line-duotone" />,
          // },
          {
            title: "Documents (New)",
            path: paths.dashboard.datafiles,
            icon: <Iconify icon="solar:cloud-file-line-duotone" />,
          },
          {
            title: "Tasks",
            path: paths.dashboard.tasks.root,
            icon: <Iconify icon="fluent:task-list-square-16-regular" />,
          },
          {
            title: "Chaser",
            path: paths.dashboard.tasks.chaser,
            icon: <Iconify icon="solar:document-broken" />,
          },
          {
            title: "Clearances",
            path: paths.dashboard.tasks.clearances,
            icon: <Iconify icon="icon-park-twotone:safe-retrieval" />,
          },
          {
            title: "Users",
            path: paths.dashboard.users,
            icon: <Iconify icon="mdi:users-outline" />,
          },
          // {
          //   title: "Emails",
          //   path: paths.dashboard.emails,
          //   icon: <Iconify icon="mdi:email" />,
          // },
          {
            title: "Reports",
            path: paths.dashboard.reports,
            icon: <Iconify icon="carbon:report-data" />,
          },
          // {
          //   title: "Incident Intervention",
          //   path: paths.dashboard.ii,
          //   icon: <Iconify icon="mdi:account-injury-outline" />,
          // },
        ],
      },
    ];
  }
}
