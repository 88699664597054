import { Button, Card, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import { LoadingScreen } from "src/components/loading-screen";
import DocumentGroup from "../Documents/DocumentGroup";
import cogoToast from "cogo-toast";
import { cloneDeep } from "lodash";
import Iconify from "src/components/iconify";
import { format } from "date-fns";
import _ from "lodash";

class EmployeeData extends React.Component {
  state = {
    groups: [],
    isLoading: false,
    addingNewAudiogram: false,
  };

  componentDidMount() {
    this.loadEmployeeData();
  }

  loadEmployeeData = async () => {
    this.setState({ isLoading: true });
    try {
      const res = await this.props.apiCallPost("/files/getGroupsForEmployee", {
        employeeId: this.props.id,
      });
      this.setState({
        isLoading: false,
        groups: _.reverse(_.sortBy(res, (g) => g.updatedAt)),
      });
    } catch (error) {
      console.error("could not load data", error);
    }
    this.setState({
      isLoading: false,
    });
  };

  addNewAudioGram = async () => {
    this.setState({ addingNewAudiogram: true });
    try {
      const res = await this.props.apiCallPost("/files/generateNewAudiogram", {
        employeeName: this.props.data.employeeName,
        companyId: this.props.data.companyId,
        employeeId: this.props.id,
      });
      const groups = cloneDeep(this.state.groups);
      this.setState({ groups: [res, ...groups] });
    } catch (error) {}
  };

  saveNewAudiogram = () => {
    this.setState({ addingNewAudioGram: false });
  };

  cancelNewAudiogram = () => {
    this.setState({ addingNewAudiogram: false });
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingScreen />;
    }
    return (
      <Container maxWidth={1000}>
        <Stack spacing={2}>
          <Button
            onClick={() => {
              this.addNewAudioGram();
            }}
            variant="contained"
            sx={{ mb: 1, ml: "auto" }}
            startIcon={<Iconify icon="material-symbols:add" />}
          >
            Add New Audiogram
          </Button>
          {this.state.groups.map((g, index) => {
            return (
              <Card
                elevation={2}
                sx={{
                  padding: "1rem",
                }}
                key={index}
              >
                <Stack spacing={1}>
                  <Typography variant="h6">
                    [{format(new Date(g.updatedAt), "PP")}] {g.pageDataType}
                  </Typography>
                  <DocumentGroup
                    showDocumentHeader={false}
                    data={g}
                    groupIx={index}
                    hideActions={true}
                    key={g._id}
                    cfId={g._id}
                    employeeId={this.props.id}
                  />
                </Stack>
              </Card>
            );
          })}
        </Stack>
      </Container>
    );
  }
}

export default WithAPICall(EmployeeData);
