import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { WithAPICall } from "../utils/apiUtil";
import Money from "./Money";
import {
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography,
  Stack,
  FormControl,
  InputLabel,
} from "@mui/material";
import CompanyVisits from "./CompanyVisits";
import Visits from "./Visits";
import CompanyRevenue from "./CompanyRevenue";
import ModalHOC from "./ModalHOC";
import TasksAiSystem from "./TasksAiSystem";
import TaskStatus from "./TaskStatus";
import ProviderVisits from "./ProviderVisits";
import ProviderCost from "./ProviderCost";
import Clearances from "./Clearances";
import { RealTimeTasks } from "./RealTimeTasks";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import HOCContext from "./contexts/HOCContext";
import cogoToast from "cogo-toast";
import { LoadingScreen } from "src/components/loading-screen";

const shortcutsItems = [
  {
    label: "Today",
    getValue: () => {
      const today = moment();
      return [today, today];
    },
  },
  {
    label: "Last 3 Days",
    getValue: () => {
      const today = moment();
      return [today.clone().subtract(3, "days"), today];
    },
  },
  {
    label: "Next 3 Days",
    getValue: () => {
      const today = moment();
      return [today, today.clone().add(3, "days")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = moment();
      return [today.clone().subtract(7, "days"), today];
    },
  },
  {
    label: "Next 7 Days",
    getValue: () => {
      const today = moment();
      return [today, today.clone().add(7, "days")];
    },
  },
  {
    label: "Last 30 Days",
    getValue: () => {
      const today = moment();
      return [today.clone().subtract(30, "days"), today];
    },
  },
  {
    label: "Next 30 Days",
    getValue: () => {
      const today = moment();
      return [today, today.clone().add(30, "days")];
    },
  },
  {
    label: "This Month",
    getValue: () => {
      const today = moment();
      return [today.clone().startOf("month"), today.clone().endOf("month")];
    },
  },
  {
    label: "Next Month",
    getValue: () => {
      const today = moment();
      return [
        today.clone().add(1, "month").startOf("month"),
        today.clone().add(1, "month").endOf("month"),
      ];
    },
  },
  {
    label: "Last Month",
    getValue: () => {
      const today = moment();
      return [
        today.clone().subtract(1, "month").startOf("month"),
        today.clone().subtract(1, "month").endOf("month"),
      ];
    },
  },
  {
    label: "This Week",
    getValue: () => {
      const today = moment();
      return [today.clone().startOf("week"), today.clone().endOf("week")];
    },
  },
  {
    label: "Next Week",
    getValue: () => {
      const today = moment();
      return [
        today.clone().add(1, "week").startOf("week"),
        today.clone().add(1, "week").endOf("week"),
      ];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = moment();
      return [
        today.clone().subtract(1, "week").startOf("week"),
        today.clone().subtract(1, "week").endOf("week"),
      ];
    },
  },
  {
    label: "Reset",
    getValue: () => [null, null],
  },
];

export default WithAPICall(function Dashboard({ apiCallPost }) {
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState(moment());
  const [to, setTo] = useState(moment().add(7, "days"));
  const [companyIds, setCompanyIds] = useState([]);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const res = await apiCallPost("/company/search", {
          query: "",
        });
        setCompanies(res);
      } catch (error) {
        cogoToast.error(
          "Could not load employers. Please refresh and try again."
        );
      }
      setLoading(false);
    };
    init();
  }, []);

  if (loading) return <LoadingScreen />;

  return (
    <Container maxWidth={10000}>
      <HOCContext.Provider value={{ from, to, companyIds }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h4" sx={{ mb: 3 }}>
              Dashboard
            </Typography>
          </Grid>
          <Grid item xs={6} alignItems="end">
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              display="flex"
              justifyContent="end"
            >
              <FormControl>
                <InputLabel id="label">Search For Employer</InputLabel>
                <Select
                  sx={{ width: 200 }}
                  labelId="label"
                  multiple
                  onChange={(e, _) => {
                    setCompanyIds(e.target.value);
                  }}
                  value={companyIds}
                >
                  {companies.map((c, index) => (
                    <MenuItem key={index} value={c._id}>
                      {c.companyName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateRangePicker
                  sx={{ width: 200 }}
                  slotProps={{
                    shortcuts: {
                      changeImportance: "accept",
                      dense: true,
                      subheader: (
                        <Typography variant="caption" sx={{ p: 2 }}>
                          Shortcuts
                        </Typography>
                      ),
                      items: shortcutsItems,
                    },
                    actionBar: { actions: [] },
                  }}
                  value={[from, to]}
                  localeText={{
                    start: "From",
                    end: "To",
                  }}
                  onChange={(newValue) => {
                    // this.setState({
                    //   dates: newValue,
                    // });
                    setFrom(newValue[0]);
                    setTo(newValue[1]);
                  }}
                />
              </LocalizationProvider>
            </Stack>
          </Grid>
        </Grid>

        <Typography variant="h5" sx={{ mb: 1 }}>
          Top Management
        </Typography>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={4}>
            <ModalHOC header="Revenue">
              <Money />
            </ModalHOC>
          </Grid>
          <Grid item xs={4}>
            <ModalHOC header="Visits">
              <Visits />
            </ModalHOC>
          </Grid>
          <Grid item xs={4}>
            <ModalHOC header="Visits by Employer">
              <CompanyVisits />
            </ModalHOC>
          </Grid>
          <Grid item xs={4}>
            <ModalHOC header="Revenue by Employer">
              <CompanyRevenue />
            </ModalHOC>
          </Grid>
          <Grid item xs={6}>
            <ModalHOC header="Tasks">
              <TasksAiSystem />
            </ModalHOC>
          </Grid>
        </Grid>
        <Divider sx={{ mb: 2, mt: 4 }} />
        <Typography variant="h5" sx={{ mb: 1 }}>
          Operations
        </Typography>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={4}>
            <ModalHOC header="Task Status">
              <TaskStatus />
            </ModalHOC>
          </Grid>
          <Grid item xs={4}>
            <ModalHOC header="Provider Visits">
              <ProviderVisits />
            </ModalHOC>
          </Grid>
          <Grid item xs={4}>
            <ModalHOC header="Provider Costs">
              <ProviderCost />
            </ModalHOC>
          </Grid>
        </Grid>
        <Divider sx={{ mb: 2, mt: 4 }} />
        <Typography variant="h5" sx={{ mb: 1 }}>
          MD
        </Typography>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={4}>
            <ModalHOC header="Clearance Details">
              <Clearances />
            </ModalHOC>
          </Grid>
        </Grid>
        <Typography variant="h5" sx={{ mb: 1 }}>
          Team Leads
        </Typography>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={4}>
            <ModalHOC header="Task Details">
              <RealTimeTasks />
            </ModalHOC>
          </Grid>
        </Grid>
      </HOCContext.Provider>
    </Container>
  );
});
