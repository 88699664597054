import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React from "react";
import EmailEditor from "react-email-editor";
import Iconify from "src/components/iconify";

import { WithAPICall } from "../utils/apiUtil";
import { LoadingButton } from "@mui/lab";

class EmailBuilder extends React.Component {
  state = {
    error: false,
    openTemplateModal: false,
    createTemplateName: "",
    editEmailMode: false,
    selectedTemplate: {},
    creatingTemplateLoader: false,
    savingTemplateLoader: false,
  };

  componentDidMount() {
    this.editorRef = React.createRef();
  }

  handleCreateModalBtnClick = () => {
    this.setState({ openTemplateModal: true });
  };

  handleCreateTemplateNameIn = (e) => {
    this.setState({
      createTemplateName: e.target.value,
    });
  };

  handleSaveTemplate = () => {
    this.setState({
      savingTemplateLoader: true,
    });
    const unlayer = this.editorRef.current?.editor;

    unlayer?.exportHtml(async (data) => {
      const { design, html } = data;
      console.log("exportHtml", { html, design });
      await this.props.handleTemplateSave({
        id: this.state.selectedTemplate._id,
        html,
        json: design,
      });
      this.setState({
        savingTemplateLoader: false,
      });
    });
  };

  handleCreateNewTemplate = async () => {
    this.setState({ creatingTemplateLoader: true });
    await this.props.handleCreateNewTemplate({
      createTemplateName: this.state.createTemplateName,
    });
    this.setState({
      openTemplateModal: false,
      createTemplateName: "",
    });
    this.setState({ creatingTemplateLoader: false });
  };

  unLayerReady = (unlayer) => {
    console.log("loading", this.state.selectedTemplate);
    unlayer.loadDesign(this.state.selectedTemplate.unlayerJson);
    unlayer.setMergeTags({
      first_name: {
        name: "First Name",
        value: "{{first_name}}",
        sample: "employee_first_name",
      },
      last_name: {
        name: "Last Name",
        value: "{{last_name}}",
        sample: "employee_last_name",
      },
      visit_date: {
        name: "Visit Date",
        value: "{{visit_date}}",
        sample: "visit_date",
      },
      visit_time: {
        name: "Visit Time",
        value: "{{visit_time}}",
        sample: "visit_time",
      },
      provider: {
        name: "Provider",
        mergeTags: {
          address: {
            name: "Provider Address",
            value: "{{provider.address}}",
            sample: "provider_address",
          },
          city: {
            name: "Provider City",
            value: "{{provider.city}}",
            sample: "provider_city",
          },
          state: {
            name: "Provider State",
            value: "{{provider.state}}",
            sample: "provider_state",
          },
          zipCode: {
            name: "Provider Zip Code",
            value: "{{provider.zipCode}}",
            sample: "provider_zip_code",
          },
          phone: {
            name: "Provider Phone",
            value: "{{provider.phone}}",
            sample: "provider_phone",
          },
        },
      },
      sender_name: {
        name: "Sender Name",
        value: "{{sender_name}}",
        sample: "sender_name",
      },
    });
  };

  renderTable = () => {
    return (
      <TableContainer component={Paper}>
        <colgroup>
          <col style={{ width: "5%" }} />
          <col style={{ width: "90%" }} />
          <col style={{ width: "5%" }} />
        </colgroup>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "10%" }}>#</TableCell>
              <TableCell sx={{ width: "70%" }} align="left">
                Template Name
              </TableCell>
              <TableCell align="left">Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.templates.map((row, index) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                hover
                onClick={() => {
                  this.setState({
                    editEmailMode: true,
                    selectedTemplate: row,
                  });
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="caption">{index + 1}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle2">
                    {row.templateName}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="caption">
                    {format(new Date(row.createdAt), "PPpp")}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  render() {
    return (
      <>
        <Dialog
          open={this.state.openTemplateModal}
          onClose={() => {
            this.setState({ openTemplateModal: false });
          }}
        >
          <DialogTitle>Create New Email Template</DialogTitle>
          <DialogContent>
            <Box>
              <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                name="name"
                label="Template Name"
                fullWidth
                value={this.state.createTemplateName}
                onChange={(e) => {
                  this.setState({ createTemplateName: e.target.value });
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={this.handleCreateNewTemplate}
              sx={{
                width: "50%",
              }}
            >
              Create
            </Button>
            <Button
              onClick={() => {
                this.setState({ openTemplateModal: false });
              }}
              sx={{
                width: "50%",
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {!this.state.editEmailMode ? (
          <>
            <Grid container sx={{ mb: 3 }}>
              <Grid item xs={10}></Grid>
              <Grid item xs={2} alignItems={"end"}>
                <LoadingButton
                  variant="contained"
                  onClick={this.handleCreateModalBtnClick}
                  startIcon={<Iconify icon="mingcute:add-line" />}
                  loading={this.state.creatingTemplateLoader}
                  sx={{
                    marginLeft: "auto",
                    width: "100%",
                  }}
                >
                  Create New Template
                </LoadingButton>
              </Grid>
            </Grid>
            {this.renderTable()}
          </>
        ) : (
          <>
            <Grid container>
              <Grid
                item
                xs={10}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => {
                    this.setState({
                      createTemplateName: "",
                      editEmailMode: false,
                      selectedTemplate: {},
                    });
                  }}
                  startIcon={<Iconify icon="ic:baseline-arrow-back-ios" />}
                >
                  Back
                </Button>
                <Typography variant="subtitle1">
                  {this.state.selectedTemplate.templateName}
                </Typography>
              </Grid>
              <Grid item xs={2} sx={{ mb: 3 }}>
                <LoadingButton
                  variant="contained"
                  sx={{
                    width: "100%",
                  }}
                  startIcon={<Iconify icon="material-symbols:save" />}
                  onClick={this.handleSaveTemplate}
                  loading={this.state.savingTemplateLoader}
                >
                  Save Template
                </LoadingButton>
              </Grid>
              <Grid item xs={12}>
                <EmailEditor ref={this.editorRef} onReady={this.unLayerReady} />
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  }
}

export default WithAPICall(EmailBuilder);
