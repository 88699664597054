import React, { useState, useEffect } from "react";
import {
  Typography,
  Select,
  MenuItem,
  TextField,
  Box,
  Container,
  Button,
  FormControl,
  InputLabel,
  Card,
} from "@mui/material";
import { Stack } from "@mui/system";
import _, { set } from "lodash";

const AlcoholClearer = ({
  name,
  visit,
  protocol,
  groups,
  onClearanceChange,
}) => {
  const savedCO = visit.protocolClearance[protocol._id];

  const [alcoholTestResult, setAlcoholTestResult] = useState(
    _.get(savedCO, "selections.alcoholTestResult", "")
  );
  const [alcoholTestConfirmationResult, setAlcoholTestConfirmationResult] =
    useState("");
  const [alcoholTestType, setAlcoholTestType] = useState(
    _.get(savedCO, "selections.alcoholTestType", "")
  );

  const [alcoholTestFinalResult, setAlcoholTestFinalResult] = useState(
    _.get(savedCO, "selections.alcoholTestFinalResult", "")
  );

  const [alcoholComments, setAlcoholComments] = useState(
    _.get(savedCO, "selections.alcoholComments", "")
  );

  const needsConfirmation = parseFloat(alcoholTestResult) > 0.0;

  return (
    <Card sx={{ p: 3 }}>
      <Stack spacing={1}>
        <Typography variant="body2">Alcohol Clearance: {name}</Typography>
        <FormControl margin="normal" size="small">
          <InputLabel id="select-major-opinion">BAC</InputLabel>
          <Select
            labelId="select-alc-test-type"
            value={alcoholTestType}
            onChange={(e) => setAlcoholTestType(e.target.value)}
            disabled={false}
          >
            <MenuItem value="Breath">Breath</MenuItem>
            <MenuItem value="Blood">Blood</MenuItem>
          </Select>
        </FormControl>
        <FormControl margin="normal" size="small">
          <TextField
            label="Alcohol Test Result"
            value={alcoholTestResult}
            onChange={(e) => setAlcoholTestResult(e.target.value)}
            disabled={false}
            autoWidth
          />
        </FormControl>
        <FormControl margin="normal" size="small">
          <TextField
            label="BAC (Confirmation)"
            value={alcoholTestConfirmationResult}
            onChange={(e) => setAlcoholTestConfirmationResult(e.target.value)}
            disabled={needsConfirmation == false}
            autoWidth
          />
        </FormControl>
        <FormControl margin="normal" size="small">
          <InputLabel id="select-major-opinion">Alcohol Test Result</InputLabel>
          <Select
            labelId="select-alc-test-result"
            value={alcoholTestFinalResult}
            onChange={(e) => setAlcoholTestFinalResult(e.target.value)}
            disabled={false}
          >
            <MenuItem value="Breath">Positive</MenuItem>
            <MenuItem value="Blood">Negative</MenuItem>
          </Select>
        </FormControl>

        <FormControl margin="normal" size="small">
          <TextField
            label="Comments"
            value={alcoholComments}
            onChange={(e) => setAlcoholComments(e.target.value)}
            disabled={false}
            autoWidth
          />
        </FormControl>
        <Button
          variant="contained"
          onClick={() => {
            onClearanceChange(
              `${alcoholTestFinalResult} ${alcoholComments}`,
              `ALCOHOL_${alcoholTestFinalResult.toUpperCase()}`,
              `ALCOHOL_${alcoholTestFinalResult.toUpperCase()}`,
              {
                alcoholTestResult,
                alcoholTestConfirmationResult,
                alcoholTestType,
                alcoholTestFinalResult,
                alcoholComments,
              }
            );
          }}
        >
          Save & Update Clearance
        </Button>
      </Stack>
    </Card>
  );
};

export default AlcoholClearer;
