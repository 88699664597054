import React from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Iconify from "src/components/iconify";
import { WithAPICall } from "../utils/apiUtil";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import { Box } from "@mui/system";
import { Button, LinearProgress } from "@mui/material";
import cogoToast from "cogo-toast";
import { View500 } from "src/sections/error";
import ProviderProfile from "./ProviderProfile";
import ProviderContacts from "./ProviderContacts";
import ProviderNotes from "./ProviderNotes";
import ProviderSettings from "./ProviderSettings";
import ProviderSchedules from "./ProviderSchedules";
import ProviderServices from "./ProviderServices";
import ProviderSuppliesRecord from "./ProviderSuppliesRecord";
import ProviderServicesNew from "./ProviderServicesNew";
class ProviderDetails extends React.Component {
  state = {
    currentTab: "INFO",
    providerData: null,
    companyNotes: [],
    isLoading: true,
    isError: false,
  };
  componentDidMount() {
    this.getById();
  }
  getById = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost("/provider/getProviderById", {
        providerId: this.props.id,
      });
      this.setState({ providerData: res, isLoading: false });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Provider Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };

  tabs = [
    {
      value: "INFO",
      label: "Info",
      icon: <Iconify icon="solar:info-circle-broken" width={24} />,
    },
    {
      value: "SETTINGS",
      label: "Settings",
      icon: <Iconify icon="solar:settings-broken" width={24} />,
    },
    {
      value: "SCHEDULE",
      label: "Schedule",
      icon: <Iconify icon="solar:calendar-broken" width={24} />,
    },
    // {
    //   value: "SERVICES",
    //   label: "Services",
    //   icon: <Iconify icon="solar:money-bag-broken" width={24} />,
    // },
    {
      value: "SERVICES_2",
      label: "Services (New)",
      icon: <Iconify icon="solar:money-bag-broken" width={24} />,
    },
    {
      value: "SUPPLIES",
      label: "Supply Records & Inventory",
      icon: <Iconify icon="solar:box-minimalistic-broken" width={24} />,
    },
    {
      value: "NOTES",
      label: "Notes & Documents",
      icon: <Iconify icon="solar:document-add-linear" width={24} />,
    },
    {
      value: "CONTACTS",
      label: "Contacts",
      icon: <Iconify icon="solar:user-circle-broken" width={24} />,
    },
  ];
  renderTabs = () => {
    return (
      <Tabs
        value={this.state.currentTab}
        onChange={(_, value) => this.setState({ currentTab: value })}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        {this.tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            icon={tab.icon}
            value={tab.value}
          />
        ))}
      </Tabs>
    );
  };
  renderTab = () => {
    switch (this.state.currentTab) {
      case "INFO":
        return (
          <ProviderProfile
            id={this.props.id}
            data={this.state.providerData}
            onUpdate={(key, value) => {
              let providerData = this.state.providerData;
              providerData[key] = value;
              this.setState({ providerData });
            }}
          />
        );

      case "CONTACTS":
        return (
          <ProviderContacts
            id={this.props.id}
            data={this.state.providerData}
            onUpdate={(key, value) => {
              let providerData = this.state.providerData;
              providerData[key] = value;
              this.setState({ providerData });
            }}
          />
        );
      case "NOTES":
        return (
          <ProviderNotes
            id={this.props.id}
            data={this.state.providerData}
            onUpdate={(key, value) => {
              let providerData = this.state.providerData;
              providerData[key] = value;
              this.setState({ providerData });
            }}
          />
        );
      case "SETTINGS":
        return (
          <ProviderSettings
            id={this.props.id}
            data={this.state.providerData}
            onUpdate={(key, value) => {
              let providerData = this.state.providerData;
              providerData[key] = value;
              this.setState({ providerData });
            }}
          />
        );
      case "SCHEDULE":
        return (
          <ProviderSchedules
            id={this.props.id}
            data={this.state.providerData}
            onUpdate={(key, value) => {
              let providerData = this.state.providerData;
              providerData[key] = value;
              this.setState({ providerData });
            }}
          />
        );

      // case "SERVICES":
      //   return (
      //     <ProviderServices
      //       doesApplyToNetwork={false}
      //       id={this.props.id}
      //       data={this.state.providerData}
      //       onUpdate={(key, value) => {
      //         let providerData = this.state.providerData;
      //         providerData[key] = value;
      //         this.setState({ providerData });
      //       }}
      //     />
      //   );
      case "SERVICES_2":
        return (
          <ProviderServicesNew
            doesApplyToNetwork={false}
            id={this.props.id}
            data={this.state.providerData}
            onUpdate={(key, value) => {
              let providerData = this.state.providerData;
              providerData[key] = value;
              this.setState({ providerData });
            }}
          />
        );

      case "SUPPLIES":
        return (
          <ProviderSuppliesRecord
            id={this.props.id}
            data={this.state.providerData}
          />
        );
      default:
        return <div>Not Live</div>;
    }
  };
  renderHeader = () => {
    let name =
      this.state.providerData.providerInformalName ||
      this.state.providerData.providerName ||
      "Provider Details";
    return (
      <CustomBreadcrumbs
        heading={name}
        links={[
          { name: "Dashboard", href: "/" },
          { name: "Providers", href: "/providers" },
          { name: `Details: ${name}` },
        ]}
        action={
          <Box>
            <Button
              disabled={this.state.isLoading}
              onClick={() => this.getById()}
              variant="contained"
              startIcon={
                <Iconify icon="mingcute:refresh-anticlockwise-1-line" />
              }
            >
              Refresh Data
            </Button>
          </Box>
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <>
        {this.renderHeader()}
        {this.renderTabs()}
        {this.renderTab()}
      </>
    );
  }
}

export default WithAPICall(ProviderDetails);
