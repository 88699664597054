import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import { Button, Card, Chip, Drawer, Switch, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import ListProviders from "../Provider/ListProviders";
import cogoToast from "cogo-toast";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { NoResultView, View500 } from "src/sections/error";
import _ from "lodash";
import Iconify from "src/components/iconify";
import { LoadingButton } from "@mui/lab";

class CompanyLinkedProviders extends React.Component {
  state = {
    isError: false,
    enableAdvancedFilter: false,
    isLoading: true,
    hasLoadingStarted: false,
    list: [],
    openProviderDrawer: false,
    quickSearchText: "",
    isInRemoveMode: false,
    selectedRows: [],
    aggregations: {
      sum: (params) => this.sumAggregation(params),
      average: (params) => this.averageAggregation(params),
      max: (params) => this.maxAggregation(params),
      min: (params) => this.minAggregation(params),
      count: (params) => this.countAggregation(params),
    },
  };
  buffer = [];
  timer = null;
  COLUMNS = [
    {
      headerName: "Provider",
      field: "provider.providerName",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
      cellRenderer: (row) => this.renderName(row),
      headerCheckboxSelection: () => {
        return this.state.isInRemoveMode;
      },
      checkboxSelection: () => {
        return this.state.isInRemoveMode;
      },
    },
    {
      headerName: "Type",
      field: "provider.providerType",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Network",
      field: "provider.providerNetwork",
      enableRowGroup: true,
      enablePivot: true,
      valueGetter: (params) => {
        // if providerNetwork then providerNetwork.networkName
        if (params?.data?.provider?.providerNetwork) {
          return params?.data?.provider?.providerNetwork?.networkName;
        }
        return null;
      },
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Preferred",
      field: "isPreferredProvider",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
      cellRenderer: (row) => {
        if (this.state.enableAdvancedFilter) {
          return row.data.isPreferredProvider;
        }
        return (
          <Switch
            disabled={this.state.isLoading}
            checked={row.data.isPreferredProvider}
            onChange={() => {
              this.companyAssociatedProvidersUpdateScalarValue(
                row.data._id,
                "isPreferredProvider",
                !row.value
              );
            }}
          />
        );
      },
    },
    /*  {
      headerName: "Notes",
      field: "notes",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
      cellRenderer: (row) => {
        if (this.state.enableAdvancedFilter) {
          return row.data.notes;
        }
        return (
          <TextField
            size="small"
            disabled={this.state.isLoading}
            value={row.data.notes}
            onChange={(e) => {
              // set in state
              let list = _.cloneDeep(this.state.list);
              let index = _.findIndex(
                list,
                (each) => each._id === row.data._id
              );
              if (index > -1) {
                list[index].notes = e.target.value;
              }
              this.setState({
                list: list,
              });
            }}
            onBlur={() => {
              this.companyAssociatedProvidersUpdateScalarValue(
                row.data._id,
                "notes",
                row.value
              );
            }}
          />
        );
      },
    }, */
    {
      headerName: "Phone",
      field: "provider.providerPhone",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.renderPhone(row),
    },
    {
      headerName: "Email",
      field: "provider.providerEmail",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.renderSendEmail(row),
    },
    {
      headerName: "Website",
      field: "provider.providerWebsite",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.renderWebsite(row),
    },
    {
      headerName: "Country",
      field: "provider.providerCountry",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.renderCountry(row),
    },
    {
      headerName: "State",
      field: "provider.providerState",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
    },
    {
      headerName: "City",
      field: "provider.providerCity",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
    },
  ];
  componentDidMount() {
    this.search();
  }
  search = async () => {
    this.setState({ isLoading: true });
    cogoToast.loading("Loading data, this might take a while!");
    this.setState({
      list: [],
      isLoading: true,
      hasLoadingStarted: false,
    });
    this.buffer = [];
    this.timer = setInterval(() => {
      let bufferLength = this.buffer.length;
      let listLength = this.state.list.length;
      let diff = bufferLength - listLength;

      if (diff > 0) {
        this.setState({
          list: [
            ...this.state.list,
            ...this.buffer.slice(listLength, bufferLength),
          ],
        });
      }
    }, 250);
    let payload = {
      companyId: this.props.id,
    };

    await this.props.apiCallPostStreaming(
      "/company-associated-provider/capGetAllStreaming",
      payload,
      (data) => {
        if (!this.state.hasLoadingStarted) {
          this.setState({ hasLoadingStarted: true });
        }
        this.buffer.push(...data);
      },
      () => {
        this.setState(
          { isLoading: false, hasLoadingStarted: false },
          async () => {
            // wait 4 seconds
            await new Promise((resolve) => setTimeout(resolve, 4000));
            clearInterval(this.timer);
          }
        );
      }
    );
  };
  createCompanyAssociatedProviders = async (data, isPreferred) => {
    try {
      this.setState({
        isLoading: true,
        openProviderDrawer: false,
      });
      let res = await this.props.apiCallPost(
        "/company-associated-provider/createCompanyAssociatedProviders",
        {
          companyId: this.props.id,
          providerIds: data.map((each) => each._id),
          isPreferredProvider: isPreferred,
        }
      );
      let list = _.cloneDeep(this.state.list);

      // if list already contains _id of element in res, update it. else, append to list.
      for (var i = 0; i < res.length; i++) {
        let index = _.findIndex(list, (each) => each._id === res[i]._id);
        if (index > -1) {
          list[index] = res[i];
        } else {
          list.push(res[i]);
        }
      }

      this.setState({
        isLoading: false,
        list: list,
        openProviderDrawer: false,
      });
    } catch (err) {
      this.setState({
        isLoading: false,
        openProviderDrawer: false,
      });
      cogoToast.error("Error Creating Company Associated Providers");
      console.log(err);
    }
  };
  removeCompanyAssociatedProviders = async (data) => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost(
        "/company-associated-provider/removeCompanyAssociatedProviders",
        { capIds: data.map((each) => each._id) }
      );
      let list = _.cloneDeep(this.state.list);

      for (var i = 0; i < data.length; i++) {
        _.remove(list, (each) => each._id === data[i]._id);
      }
      this.setState({
        isLoading: false,
        list: list,
        selectedRows: [],
        isInRemoveMode: false,
      });
    } catch (err) {}
  };
  companyAssociatedProvidersUpdateScalarValue = async (capId, key, value) => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost(
        "/company-associated-provider/companyAssociatedProvidersUpdateScalarValue",
        {
          capId: capId,
          key: key,
          value: value,
        }
      );
      let list = _.cloneDeep(this.state.list);

      let index = _.findIndex(list, (each) => each._id === capId);
      if (index > -1) {
        list[index] = res;
      }
      this.setState({
        isLoading: false,
        list: list,
      });
    } catch (err) {
      this.setState({
        isLoading: false,
      });
      cogoToast.error("Error Updating Company Associated Providers");
      console.log(err);
    }
  };
  onGridReady = (params) => {
    this.setState({ gridApi: params.api, columnApi: params.columnApi });
  };
  sumAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));

    return _.sum(arr);
  };
  averageAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.mean(arr);
  };
  maxAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.max(arr);
  };
  minAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.min(arr);
  };
  countAggregation = (params) => {
    return params.values.length;
  };
  renderCountry = (row) => {
    return (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Iconify
          icon={`circle-flags:${row?.data?.providerCountryShortCode?.toLowerCase()}`}
          sx={{ mr: 1 }}
        />
        {row.value}
      </span>
    );
  };
  renderName = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    return (
      <span
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          fullWidth
          target="non_blank noopener noreferrer"
          href={`/providers/${row.data.provider._id}`}
          variant="outlined"
          startIcon={<Iconify icon="mingcute:external-link-line" />}
        >
          {row.value}
        </Button>
      </span>
    );
  };
  renderGoToPage = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    return (
      <span
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          fullWidth
          href={`/providers/${row.data._id}`}
          variant="outlined"
          startIcon={<Iconify icon="mingcute:external-link-line" />}
        ></Button>
      </span>
    );
  };
  renderWebsite = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    if (!row.value) return null;
    return (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Button
          fullWidth
          target="non_blank noopener noreferrer"
          href={row.value}
          variant="outlined"
          startIcon={<Iconify icon="mingcute:external-link-line" />}
        >
          {row.value}
        </Button>
      </span>
    );
  };
  renderSendEmail = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    if (!row.value) return null;
    return (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Button
          fullWidth
          target="non_blank noopener noreferrer"
          href={`mailto:${row.value}`}
          variant="outlined"
          startIcon={<Iconify icon="mingcute:mail-send-line" />}
        >
          {row.value}
        </Button>
      </span>
    );
  };
  renderPhone = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    if (!row.value) return null;
    return (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Button
          fullWidth
          target="non_blank noopener noreferrer"
          href={`tel:${row.value}`}
          variant="outlined"
          startIcon={<Iconify icon="mingcute:phone-outgoing-line" />}
        >
          {row.value}
        </Button>
      </span>
    );
  };
  renderIfSelectedRows = () => {
    if (!this.state.selectedRows) {
      return null;
    }
    if (this.state.selectedRows.length === 0) {
      return null;
    }
    return (
      <Button
        variant="contained"
        startIcon={<Iconify icon="mingcute:link-3-line" />}
        onClick={() => {
          this.removeCompanyAssociatedProviders(this.state.selectedRows);
        }}
      >
        Remove {this.state.selectedRows.length} Provider{" "}
        {this.state.selectedRows.length === 1 ? " Link" : " Links"}
      </Button>
    );
  };
  renderLoadedSoFar = () => {
    if (!this.state.hasLoadingStarted) return null;
    return (
      <Chip
        icon={<Iconify icon="carbon:progress-ring" />}
        label={`Loaded ${this.state.list.length} records`}
        variant="outlined"
      />
    );
  };
  renderControlBox = () => {
    let list = this.state.list;
    if (!list) return null;
    return (
      <Stack direction="row" spacing={1}>
        <TextField
          disabled={this.state.isLoading}
          value={this.state.quickSearchText}
          label="Quick Search"
          onChange={(e) =>
            this.setState({
              quickSearchText: e.target.value,
            })
          }
        />
        <Button
          disabled={this.state.isLoading}
          variant="contained"
          startIcon={<Iconify icon="mingcute:filter-line" />}
          onClick={() => {
            this.setState({
              enableAdvancedFilter: !this.state.enableAdvancedFilter,
            });
          }}
        >
          {this.state.enableAdvancedFilter
            ? "Advanced Filters"
            : "Simple Filters"}
        </Button>{" "}
        <LoadingButton
          loading={this.state.isLoading}
          onClick={() => this.search()}
          variant="contained"
          startIcon={<Iconify icon="mingcute:refresh-anticlockwise-1-line" />}
        >
          Refresh Data
        </LoadingButton>
        {this.renderLoadedSoFar()}
        <Button
          disabled={this.state.isLoading || this.state.isInRemoveMode}
          variant="contained"
          startIcon={<Iconify icon="mingcute:add-line" />}
          onClick={() => {
            this.setState({
              openProviderDrawer: !this.state.openProviderDrawer,
            });
          }}
        >
          Add Providers
        </Button>{" "}
        <Button
          disabled={this.state.isLoading}
          variant="contained"
          startIcon={<Iconify icon="mingcute:minus-circle-line" />}
          onClick={() => {
            this.setState({
              isInRemoveMode: !this.state.isInRemoveMode,
              selectedRows: [],
            });
          }}
        >
          {this.state.isInRemoveMode ? "Cancel Removals" : "Remove Providers"}
        </Button>
        {this.renderIfSelectedRows()}
      </Stack>
    );
  };
  renderGrid = () => {
    let list = this.state.list;
    if (!list || list.length === 0) {
      return <NoResultView />;
    }
    let cs = "ag-theme-quartz";
    let theme = localStorage.getItem("themeMode");
    if (theme && theme === "dark") {
      cs = "ag-theme-quartz-dark";
    }
    return (
      <div className={cs} style={{ height: "70vh" }}>
        <AgGridReact
          onGridReady={this.onGridReady}
          rowData={this.state.list}
          columnDefs={this.COLUMNS}
          defaultColDef={{
            sortable: true,
          }}
          autoSizeStrategy={{
            type: "fitCellContents",
          }}
          pagination={true}
          rowGroupPanelShow={
            this.state.enableAdvancedFilter ? "always" : "never"
          }
          groupSelectsChildren={true}
          aggFuncs={this.state.aggregations}
          suppressDragLeaveHidesColumns={true}
          rowSelection="multiple"
          rowDragManaged={true}
          enableCharts={true}
          checkboxSelection={this.state.isInRemoveMode}
          enableRangeSelection={true}
          enableCellTextSelection={true}
          quickFilterText={this.state.quickSearchText}
          sideBar={true}
          animateRows={true}
          enableAdvancedFilter={this.state.enableAdvancedFilter}
          onSelectionChanged={() => {
            let rows = this.state.gridApi.getSelectedRows();
            if (!rows || rows.length === 0) {
              this.setState({
                selectedRows: [],
              });
            } else {
              this.setState({
                selectedRows: rows,
              });
            }
          }}
        />
      </div>
    );
  };
  renderProviderList = () => {
    return (
      <Drawer
        anchor="right"
        open={this.state.openProviderDrawer}
        onClose={() => {
          this.setState({
            openProviderDrawer: false,
            currentlySelectedLinkId: null,
          });
        }}
      >
        <div
          style={{
            width: "80vw",
          }}
        >
          <Card sx={{ p: 2 }}>
            <ListProviders
              fromCompanyPage={true}
              selectedData={this.state.list}
              companyName={this.props.data.companyName}
              onSelectData={(data, isPreferred) => {
                this.createCompanyAssociatedProviders(data, isPreferred);
              }}
            />
          </Card>
        </div>
      </Drawer>
    );
  };
  render() {
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <Stack spacing={2}>
        {this.renderProviderList()}
        {this.renderControlBox()}
        {this.renderGrid()}
      </Stack>
    );
  }
}

export default WithAPICall(CompanyLinkedProviders);
