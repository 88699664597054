import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import AILoader from "../utils/AILoader";
import { Stack } from "@mui/system";
import { IconButton, TextField, Typography } from "@mui/material";
import _ from "lodash";
import { LoadingButton } from "@mui/lab";
import Iconify from "src/components/iconify";

class VisitPageReadData extends React.Component {
  state = {
    isReadingPage: false,
    pageIx: null,
    pageType: null,
    pageContent: null,
    provenance: null,
    readoutContent: "",
    isEditing: false,
    hasBeenChangedByHuman: false,
  };
  componentDidMount() {
    let contentGroup = this.props.contentGroup;
    if (contentGroup) {
      if (contentGroup.readoutContent) {
        this.setState({
          readoutContent: contentGroup.readoutContent,
        });
      }
    }
  }
  readPagesOfFile = async () => {
    try {
      this.setState({
        isReadingPage: true,
        isEditing: false,
      });
      await this.props.apiCallPostStreamingForAI(
        "/external/pdf/extractDocumentContent",
        {
          fileId: this.props.fileId,
          pageType: this.props.contentGroup.pageType,
          stream: true,
          pageIndices: this.props.contentGroup.pages,
        },
        (data) => {
          this.setState({
            readoutContent: this.state.readoutContent + data,
          });
        },
        () => {
          this.setState(
            {
              isReadingPage: false,
            },
            () => {
              // check if props had read out data
              if (this.props.contentGroup.readoutContent) {
                this.setState({
                  isEditing: true,
                  hasBeenChangedByHuman: false,
                });
              } else {
                this.props.editContentReadOut(
                  this.state.readoutContent,
                  "AI_GENERATED"
                );
              }
            }
          );
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  renderPageContent = () => {
    let readoutContent = this.state.readoutContent;
    if (!readoutContent) {
      return null;
    }
    if (this.state.isEditing) {
      return (
        <TextField
          multiline
          value={readoutContent}
          onChange={(e) => {
            this.setState({
              readoutContent: e.target.value,
              hasBeenChangedByHuman: true,
            });
          }}
        />
      );
    }
    return readoutContent.split("\n").map((each) => {
      return <Typography variant="caption">{each}</Typography>;
    });
  };
  renderButton = () => {
    if (this.state.isReadingPage) {
      return <AILoader text="READING" />;
    }
    if (this.state.readoutContent) {
      if (this.state.isEditing) {
        return (
          <Stack direction="row" alignItems={"center"} spacing={2}>
            <Typography variant="overline">Editing</Typography>
            <IconButton
              size="small"
              variant="contained"
              color="success"
              onClick={() => {
                // if no edit --> nothing.
                if (!this.state.hasBeenChangedByHuman) {
                  this.setState({
                    isEditing: false,
                  });
                } else {
                  this.props.editContentReadOut(
                    this.state.readoutContent,
                    "USER_GENERATED"
                  );
                  this.setState({
                    isEditing: false,
                  });
                }
              }}
            >
              <Iconify icon="mingcute:check-circle-fill" />
            </IconButton>
          </Stack>
        );
      } else {
        return (
          <Stack direction="row" alignItems={"center"} spacing={2}>
            <Typography variant="overline">Content</Typography>
            <IconButton
              size="small"
              variant="outlined"
              onClick={() => {
                this.setState({ isEditing: true });
              }}
            >
              <Iconify icon="mingcute:edit-2-line" />
            </IconButton>
          </Stack>
        );
      }
    }
    return (
      <LoadingButton
        loading={this.state.isReadingPage}
        onClick={this.readPagesOfFile}
      >
        Read Page Data
      </LoadingButton>
    );
  };
  render() {
    return (
      <Stack>
        {this.renderButton()}
        {this.renderPageContent()}
      </Stack>
    );
  }
}

export default WithAPICall(VisitPageReadData);
