import { useRoutes } from "react-router";
import VisitServiceClearanceByID from "src/micro/Prints/VisitServiceClearanceByID";
import VisitProtocolClearanceByID from "src/micro/Prints/VisitProtocolClearanceByID";
import LoginView from "src/micro/utils/LoginView";
import AuthorizationLetterById from "src/micro/Prints/AuthorizationLetterById";
import FormFoxAuthorizationLetterById from "src/micro/Prints/FormFoxAuthorizationLetterById";
import PatientLetterByID from "src/micro/Prints/PatientLetterByID";
import VisitDrugClearanceByID from "src/micro/Prints/VisitDrugClearanceByID";
import VisitAlcoholClearanceByID from "../../micro/Prints/VisitAlcoholClearanceByID";

export default function ExtRouter() {
  return useRoutes([
    {
      path: "/",
      children: [{ index: true, element: <LoginView /> }],
    },
    // {
    //   path: "prints/visit-service-clearance",
    //   children: [
    //     {
    //       path: ":id",
    //       element: <VisitServiceClearanceByID />,
    //     },
    //   ],
    // },
    {
      path: "prints/visit-protocol-clearance",
      children: [
        {
          path: ":id",
          element: <VisitProtocolClearanceByID />,
        },
      ],
    },
    {
      path: "prints/visit-protocol-drug-clearance",
      children: [
        {
          path: ":id",
          element: <VisitDrugClearanceByID />,
        },
      ],
    },
    {
      path: "prints/visit-protocol-alcohol-clearance",
      children: [
        {
          path: ":id",
          element: <VisitAlcoholClearanceByID />,
        },
      ],
    },

    {
      path: "prints/authorization-letter-clinic",
      children: [
        {
          path: ":id",
          element: <AuthorizationLetterById />,
          index: true,
        },
      ],
    },
    {
      path: "prints/authorization-letter-formfox",
      children: [
        {
          path: ":id",
          element: <FormFoxAuthorizationLetterById />,
          index: true,
        },
      ],
    },
    {
      path: "prints/patient-letter",
      children: [
        {
          path: ":id",
          element: <PatientLetterByID />,
          index: true,
        },
      ],
    },
    {
      path: "*",
      element: <LoginView />,
    },
  ]);
}
