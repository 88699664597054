import LoadingButton from "@mui/lab/LoadingButton";
import {
  Autocomplete,
  Button,
  Chip,
  Container,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box, Stack } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";
import cogoToast from "cogo-toast";
import _, { cloneDeep, truncate } from "lodash";
import React from "react";
import Editor from "src/components/editor";
import Iconify from "src/components/iconify/iconify";
import { LoadingScreen } from "src/components/loading-screen";
import Scrollbar from "src/components/scrollbar";
import TextMaxLine from "src/components/text-max-line";
import MailCompose from "src/sections/mail/mail-compose";
import MailDetails from "src/sections/mail/mail-details";
import MailItem from "src/sections/mail/mail-item";
import styles from "./style.module.css";

import { WithAPICall } from "../utils/apiUtil";

const VisuallyHiddenInput = styled("input")({
  display: "none",
  overflow: "hidden",
  position: "absolute",
});

function extractCurrentEmailText(emailText) {
  // Split the email text into lines
  let lines = emailText.split("\n");

  // Find the index of the first line that doesn't start with a '>'
  const previousEmailStartIndex = lines.findIndex((line) =>
    line.startsWith(">")
  );

  if (previousEmailStartIndex !== -1)
    lines = lines.slice(3, previousEmailStartIndex);

  return truncate(lines.join("\n").trim());
}

class EmailThreads extends React.Component {
  state = {
    isLoading: true,
    emailThreads: [],
    value: "",
    selectedTemplate: "",
    selectedEmailThread: -1,
    userSearchResults: [],
    currentlyChosenUser: null,
    isSearchingUser: false,
    isSendingEmail: false,
    uploadedFilesToSend: [],
    openCompose: false,
    providerEmail: "",
    emailTemplates: this.props.templates,
  };
  templateEmailEditorRef = React.createRef();
  async componentDidMount() {
    await this.loadEmailThreads();
    // Get the full URL
    const currentUrl = window.location.href;

    // Create a new URL object
    const url = new URL(currentUrl);

    // Get the "providerId" parameter
    const providerId = url.searchParams.get("providerId");

    // Get the "sendNewEmail" parameter
    const sendNewEmail = url.searchParams.get("sendNewEmail");

    // Check if the parameters exist
    if (providerId) {
      console.log("Provider ID:", providerId);
      try {
        const res = await this.props.apiCallPost("/contacts/getByEntityId", {
          entityId: providerId,
          entityType: "Provider",
        });
        this.setState({
          providerEmail: res[0].emailAddress,
        });
      } catch (error) {}
    }

    if (sendNewEmail) {
      console.log("Send New Email:", sendNewEmail);
      this.setState({ openCompose: true });
    } else {
      console.log("Send New Email parameter not found in the URL.");
    }

    // Remove the "providerId" and "sendNewEmail" parameters from the URL
    url.searchParams.delete("providerId");
    url.searchParams.delete("sendNewEmail");

    // Update the URL in the browser history
    window.history.replaceState({}, "", url.toString());
  }

  updateEmailThreadScalarValue = async (id, key, value) => {
    if (value === null || value === undefined) {
      return;
    }
    try {
      await this.props.apiCallPost("/email/updateScalarValue", {
        id: id,
        key: key,
        value: value,
      });
    } catch (err) {
      cogoToast.error("Error Updating Employer Data");
      console.log(err);
    }
  };
  updateEmailScalarValue = async (id, key, value) => {
    if (value === null || value === undefined) {
      return;
    }
    try {
      await this.props.apiCallPost("/email/updateEmailScalarValue", {
        id: id,
        key: key,
        value: value,
      });
    } catch (err) {
      cogoToast.error("Error Updating Employer Data");
      console.log(err);
    }
  };
  loadEmailThreads = async () => {
    this.setState({ isLoading: true });
    try {
      let data = {};
      if (this.props.visitId) {
        data = {
          visitId: this.props.visitId,
        };
      }
      const res = await this.props.apiCallPost("/email/getAllThreads", data);
      if (res.length > 0) {
        // sort the res document
        // TODO: move this sort to backend
        // Sort the EmailThreads documents by the latest email's createdAt date
        const sortedEmailThreads = res.sort((a, b) => {
          const aLatestEmail = a.emails.reduce((latest, email) => {
            return latest.createdAt > email.createdAt ? latest : email;
          }, a.emails[0]);

          const bLatestEmail = b.emails.reduce((latest, email) => {
            return latest.createdAt > email.createdAt ? latest : email;
          }, b.emails[0]);

          return (
            new Date(bLatestEmail.createdAt) - new Date(aLatestEmail.createdAt)
          );
        });
        this.setState({
          emailThreads: sortedEmailThreads,
          selectedEmailThread: 0,
        });
      }
    } catch (error) {
      console.error("error loadEmailThreads", error);
    }
    this.setState({ isLoading: false });
  };
  handleProcessDocument = async (params) => {
    try {
      const res = await this.props.apiCallPost(
        "/email/processDocument",
        params
      );
      const numEmails =
        this.state.emailThreads[this.state.selectedEmailThread].emails.length;
      const updateScalarKey = `attachments.${params.attachmentIndex}.cfId`;
      const usvRes = await this.updateEmailScalarValue(
        this.state.emailThreads[this.state.selectedEmailThread].emails[
          numEmails - 1 - params.mailIndex
        ]._id,
        updateScalarKey,
        res.cfio._id
      );
      return res.cfio;
    } catch (error) {
      console.error("could not process document", error);
    }
  };
  searchUsersFTS = async (t) => {
    try {
      let res = await this.props.apiCallPostCancellable("/user/searchUser", {
        query: t,
        company: null,
        showDeactivatedAlso: false,
      });
      if (res) {
        this.setState({
          isSearchingUser: false,
          userSearchResults: res.map((each) => {
            return {
              mainText: each.name,
              secondaryText: each.email,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingUser: false,
        userSearchResults: [],
      });
      cogoToast.error("Error Searching Users");
    }
  };
  handleTemplateChange = (event) => {
    const selectedTemplateId = event.target.value;
    const template = this.state.emailTemplates.find(
      (t) => t._id === selectedTemplateId
    );
    this.setState({
      selectedTemplate: selectedTemplateId,
      value: template.html,
    });
  };
  handleSendEmail = async () => {
    const currentThread = cloneDeep(
      this.state.emailThreads[this.state.selectedEmailThread]
    );
    const lastEmail = cloneDeep(
      currentThread.emails[currentThread.emails.length - 1]
    );
    const from = lastEmail.from;
    let to = (lastEmail.to || []).filter(
      (t) => t.address !== "shubham@radicalhealth.in"
    );
    to.push(from);
    const cc = (lastEmail.cc || []).filter(
      (t) => t.address !== "shubham@radicalhealth.in"
    );
    let html = "";
    if (this.state.selectedTemplate?.length > 0) {
      if (this.templateEmailEditorRef) {
        html = this.templateEmailEditorRef.current.innerHTML;
      }
    } else {
      html = this.state.value;
    }
    const formData = new FormData();
    console.log("emailsend", { to, cc });
    formData.append("to", JSON.stringify(to));
    formData.append("cc", JSON.stringify(cc));
    formData.append("html", html);
    formData.append("subject", currentThread.subject);
    for (let i = 0; i < this.state.uploadedFilesToSend.length; i++) {
      formData.append("files", this.state.uploadedFilesToSend[i]);
    }
    this.setState({
      isSendingEmail: true,
      uploadedFilesToSend: [],
      value: "",
      selectedTemplate: "",
    });
    try {
      const res = await this.props.apiCallPost("/email/sendEmail", formData);
      currentThread.emails.push(res.email);
      this.setState((prevState) => ({
        ...prevState,
        emailThreads: prevState.emailThreads.map((thread, i) =>
          thread._id === currentThread._id ? currentThread : thread
        ),
      }));
    } catch (error) {
      console.error("[sendemailerror", error);
    }
    this.setState({ isSendingEmail: false });
  };
  handleFileUpload = (event) => {
    const files = event.target.files;
    // this.setState({ uploadedFilesToSend: Array.from(files) });
    this.setState((prevState) => {
      let uploadedFilesToSend = cloneDeep(prevState.uploadedFilesToSend);
      uploadedFilesToSend = uploadedFilesToSend.concat(Array.from(files));
      return { ...prevState, uploadedFilesToSend };
    });
  };
  handleFileRemove = (fileName) => {
    this.setState((prevState) => ({
      ...prevState,
      uploadedFilesToSend: prevState.uploadedFilesToSend.filter(
        (f) => f.name !== fileName
      ),
    }));
  };
  handleUploadBtnClick = () => {
    const el = document.getElementById("email-file-upload");
    if (el) el.click();
  };
  handleMailItemClick = (index) => {
    this.updateEmailThreadScalarValue(
      this.state.emailThreads[index]._id,
      "seen",
      true
    );
    this.setState((prevState) => {
      const threads = cloneDeep(prevState.emailThreads);
      threads[index].seen = true;
      return { ...prevState, emailThreads: threads };
    });
    this.updateEmailThreadScalarValue(
      this.state.emailThreads[index]._id,
      "seenAt",
      Date.now()
    );
    this.setState({ selectedEmailThread: index });
  };
  handleNewComposeEmailAdd = (thread) => {
    this.setState((prevState) => {
      const threads = [thread, ...cloneDeep(prevState.emailThreads)];
      return { ...prevState, emailThreads: threads, openCompose: false };
    });
    cogoToast.success("Email sent.");
    if (this.props.onEmailSent) {
      this.props.onEmailSent(thread);
    }
  };
  renderLoading = () => {
    return (
      <LoadingScreen
        sx={{
          borderRadius: 1.5,
          bgcolor: "background.default",
        }}
      />
    );
  };

  render() {
    if (this.state.isLoading) {
      return this.renderLoading();
    }

    return (
      <>
        <Stack
          spacing={1}
          sx={{
            p: 1,
            borderRadius: 2,
            overflow: "hidden",
            position: "relative",
            bgcolor: "background.neutral",
          }}
        >
          <Stack
            spacing={1}
            direction="row"
            sx={{
              minHeight: { md: 720 },
              height: { xs: 800, md: "72vh" },
            }}
          >
            {/* {renderMailNav} */}

            {/* {mailsEmpty ? renderEmpty : renderMailList} */}
            <Stack
              sx={{
                width: "30%",
                flexShrink: 0,
                borderRadius: 1.5,
                bgcolor: "background.default",
              }}
            >
              <Stack sx={{ p: 2 }} direction="row" spacing={1}>
                {/* <TextField
                    placeholder="Search..."
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon="eva:search-fill"
                            sx={{ color: "text.disabled" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  /> */}
                <Typography variant="h5">Email Threads</Typography>
                <Button
                  onClick={() => {
                    this.setState({ openCompose: true });
                  }}
                  startIcon={<Iconify icon="mdi:email-edit" />}
                  sx={{
                    marginLeft: "auto",
                  }}
                >
                  Compose
                </Button>
              </Stack>

              <Scrollbar sx={{ px: 2 }}>
                {this.state.emailThreads.map((thread, index) => (
                  <>
                    <MailItem
                      key={index}
                      mail={{
                        subject: thread.subject,
                      }}
                      lastEmailDate={
                        thread.emails[thread.emails.length - 1].createdAt
                      }
                      assignedTo={thread?.taskAssignee || null}
                      selected={this.state.selectedEmailThread === index}
                      onClick={() => {
                        this.handleMailItemClick(index);
                      }}
                      seen={thread.seen}
                    />
                  </>
                ))}
              </Scrollbar>
            </Stack>

            <Stack
              direction="column"
              flexGrow={1}
              sx={{
                width: 1,
                minWidth: 0,
                borderRadius: 1.5,
                bgcolor: "background.default",
              }}
            >
              <Stack spacing={2} direction="row" flexShrink={0} sx={{ p: 2 }}>
                <TextMaxLine variant="h5" sx={{ flexGrow: 1 }}>
                  {this.state.emailThreads[this.state.selectedEmailThread]
                    ? this.state.emailThreads[this.state.selectedEmailThread]
                        .subject
                    : ""}
                </TextMaxLine>
                <Autocomplete
                  sx={{
                    minWidth: "200px",
                  }}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option?.mainText
                  }
                  filterOptions={(x) => x}
                  options={
                    this.state.isSearchingUser
                      ? []
                      : this.state.userSearchResults
                  }
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  disabled={this.state.isDoingAction}
                  value={this.state.currentlyChosenUser}
                  noOptionsText={
                    this.state.isSearchingUser
                      ? "Searching..."
                      : "No results found"
                  }
                  onChange={(_, value) => {
                    const emailThreads = cloneDeep(this.state.emailThreads);

                    console.log(
                      "current:thread",
                      emailThreads,
                      emailThreads[this.state.selectedEmailThread]
                    );

                    emailThreads[this.state.selectedEmailThread].taskAssignee =
                      value.mainText;
                    emailThreads[
                      this.state.selectedEmailThread
                    ].taskAssigneeId = value.data._id;
                    this.updateEmailThreadScalarValue(
                      this.state.emailThreads[this.state.selectedEmailThread]
                        ._id,
                      "taskAssignee",
                      value.mainText
                    );
                    this.updateEmailThreadScalarValue(
                      this.state.emailThreads[this.state.selectedEmailThread]
                        ._id,
                      "taskAssigneeId",
                      value.data._id
                    );
                    this.setState({
                      emailThreads,
                      currentlyChosenUser: value,
                      userSearchResults: [
                        value,
                        ...this.state.userSearchResults,
                      ],
                    });
                  }}
                  onInputChange={(_, e) => {
                    if (e === "") return null;
                    if (!e) return null;
                    if (e.length < 4) return null;
                    this.setState(
                      {
                        isSearchingUser: true,
                      },
                      () => {
                        this.searchUsersFTS(e);
                      }
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Users"
                      fullWidth
                      defaultValue={
                        this.state.emailThreads[this.state.selectedEmailThread]
                          ?.taskAssignee
                      }
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      <li {...props}>
                        <Grid container alignItems="center">
                          <Grid item sx={{ wordWrap: "break-word" }}>
                            <Box component="span">{option?.mainText}</Box>
                            <Typography variant="body2" color="text.secondary">
                              {option?.secondaryText}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                />
              </Stack>
              <Divider sx={{ borderStyle: "solid" }} />
              <Stack
                direction="column"
                sx={{
                  overflowY: "scroll",
                  paddingBottom: "1rem",
                }}
              >
                {this.state.emailThreads[this.state.selectedEmailThread] &&
                  this.state.emailThreads[this.state.selectedEmailThread].emails
                    .slice()
                    .reverse()
                    .map((email, index) => {
                      return (
                        <MailDetails
                          key={index}
                          onProcessDocument={this.handleProcessDocument}
                          mailIndex={index}
                          mail={{
                            subject: email.subject,
                            labelIds: [],
                            attachments: email.attachments.map((a) => ({
                              name: a.fileName,
                              size: a.size,
                              path: "",
                              type: a.contentType,
                              s3ObjectUrl: a.s3ObjectUrl,
                              cfId: a.cfId,
                            })),
                            isStarred: false,
                            isImportant: false,
                            createdAt: email.createdAt,
                            from: email.from,
                            to: email.to,
                            message: extractCurrentEmailText(email.body),
                            html: email.html,
                          }}
                        />
                      );
                    })}
              </Stack>

              <Stack
                spacing={2}
                sx={{
                  p: (theme) => theme.spacing(0, 2, 2, 2),
                  marginTop: "auto",
                }}
              >
                <Stack direction="row" spacing={1}>
                  <Stack
                    sx={{
                      width:
                        this.state.uploadedFilesToSend.length > 0
                          ? "70%"
                          : "100%",
                    }}
                  >
                    {this.state.selectedTemplate?.length > 0 ? (
                      <div className={styles.TemplateEmailEditor}>
                        <div
                          dangerouslySetInnerHTML={{ __html: this.state.value }}
                          contentEditable
                          ref={this.templateEmailEditorRef}
                        />
                      </div>
                    ) : (
                      <Editor
                        simple
                        noteId="reply-mail"
                        value={this.state.value}
                        onChange={(value) => {
                          this.setState({ value });
                        }}
                        onEditorBlur={(x) => {
                          console.log("[editor:text]", x);
                        }}
                      />
                    )}
                  </Stack>
                  {this.state.uploadedFilesToSend.length > 0 && (
                    <Stack sx={{ width: "30%" }} direction="column" spacing={1}>
                      <Typography variant="caption">Attachments</Typography>
                      {Array.from(this.state.uploadedFilesToSend).map(
                        (file) => (
                          <Chip
                            label={file.name}
                            icon={<Iconify icon="eva:attach-2-fill" />}
                            onDelete={() => {
                              this.handleFileRemove(file.name);
                            }}
                          />
                        )
                      )}
                    </Stack>
                  )}
                </Stack>

                <Stack direction="row" spacing={1}>
                  <Stack direction="row">
                    <IconButton onClick={this.handleUploadBtnClick}>
                      <Iconify icon="eva:attach-2-fill" />
                      <VisuallyHiddenInput
                        type="file"
                        multiple
                        onChange={this.handleFileUpload}
                        id="email-file-upload"
                      />
                    </IconButton>
                  </Stack>

                  <FormControl fullWidth>
                    <InputLabel id="email-template-select-label">
                      Email Template
                    </InputLabel>
                    <Select
                      labelId="email-template-select-label"
                      id="email-template-select"
                      value={this.state.selectedTemplate}
                      onChange={this.handleTemplateChange}
                      label="Email Template"
                    >
                      {this.state.emailTemplates.map((template) => (
                        <MenuItem key={template._id} value={template._id}>
                          {template.templateName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    endIcon={<Iconify icon="iconamoon:send-fill" />}
                    onClick={this.handleSendEmail}
                    loading={this.state.isSendingEmail}
                  >
                    Send
                  </LoadingButton>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        {this.state.openCompose && (
          <MailCompose
            onCloseCompose={() => {
              this.setState({ openCompose: false });
            }}
            onEmailSent={this.handleNewComposeEmailAdd}
            providerEmail={
              this.state.providerEmail || this.props.defaultTo || ""
            }
            visitId={this.props.visitId}
            emailTypes={this.props.emailTypes}
            emailTemplates={
              this.state.emailTemplates.concat({
                _id: "",
                templateName: "No Template",
                html: "",
              }) || []
            }
          />
        )}
      </>
    );
  }
}

export default WithAPICall(EmailThreads);
