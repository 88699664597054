import { SignInButton } from "@clerk/clerk-react";
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  Chip,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import cogoToast from "cogo-toast";
import React from "react";
import Iconify from "src/components/iconify";
import Logo from "src/components/logo";

class LoginView extends React.Component {
  renderSection = () => {
    return (
      <Stack
        component="main"
        direction="row"
        sx={{
          minHeight: "100vh",
          position: "relative",
          "&:before": {
            width: 1,
            height: 1,
            zIndex: -1,
            content: "''",
            position: "absolute",
            backgroundSize: "cover",
            opacity: { xs: 0.24, md: 0 },
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundImage: "url(/assets/background/overlay_4.jpg)",
          },
        }}
      >
        {this.renderContent()}
        {this.renderPhoto()}
      </Stack>
    );
  };
  renderContent = () => {
    return (
      <Stack
        sx={{
          width: 1,
          mx: "auto",
          maxWidth: 480,
          px: { xs: 2, md: 8 },
        }}
      >
        <Logo
          sx={{
            mt: { xs: 2, md: 8 },
            mb: { xs: 10, md: 8 },
          }}
        />

        <Card
          sx={{
            py: { xs: 5, md: 0 },
            px: { xs: 3, md: 0 },
            boxShadow: { md: "none" },
            overflow: { md: "unset" },
            bgcolor: { md: "background.default" },
          }}
        >
          <Stack spacing={4}>
            <Typography variant="h4">Sign in to WorkCare ME & T</Typography>
            <Alert severity="info">
              <AlertTitle> Information</AlertTitle>
              Note: You are about to sign in to to the WorkCare ME & T Portal.
              Your usage indicates your agreement with our{" "}
              <Button
                href=""
                onClick={() => {
                  cogoToast.info("We haven't updated the policy yet.");
                }}
              >
                Privacy Policy
              </Button>{" "}
              and{" "}
              <Button
                href=""
                onClick={() => {
                  cogoToast.info("We haven't updated the ToS yet.");
                }}
              >
                Terms of Service
              </Button>
              -- if you have questions, please reach out.
            </Alert>
            <SignInButton>
              <Button
                variant="contained"
                fullWidth
                color="inherit"
                size="large"
                type="submit"
                endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
                sx={{ justifyContent: "space-between", pl: 2, pr: 1.5 }}
              >
                Sign In
              </Button>
            </SignInButton>

            <Chip label="WorkCare, Inc." color="secondary" />
          </Stack>
        </Card>
      </Stack>
    );
  };
  renderPhoto = () => {
    return (
      <Stack flexGrow={1} sx={{ position: "relative" }}>
        <Box
          component="img"
          alt="auth"
          src={"/assets/background/overlay_3.jpg"}
          sx={{
            top: 16,
            left: 16,
            objectFit: "cover",
            position: "absolute",
            width: "calc(100% - 32px)",
            height: "calc(100% - 32px)",
          }}
        />
      </Stack>
    );
  };
  render() {
    return <div>{this.renderSection()}</div>;
  }
}

export default LoginView;
