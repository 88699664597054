import {
  Button,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import React from "react";
import Iconify from "src/components/iconify";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { WithAPICall } from "../utils/apiUtil";
import { Box, Stack } from "@mui/system";
import { View500 } from "src/sections/error";

import cogoToast from "cogo-toast";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";

class LabPricingTable extends React.Component {
  state = {
    isSearching: false,
    list: [],
    page: 0,
    rowsPerPage: 10,
  };
  componentDidMount() {
    this.search();
  }
  search = async () => {
    this.setState({ isSearching: true });
    cogoToast.loading("Loading data, this might take a while!");
    this.setState({
      list: [],
      isSearching: true,
    });

    let res = await this.props.apiCallPost(
      "/master-service/masterServiceGetAllLabServices",
      {}
    );
    this.setState({
      list: res,
      isSearching: false,
    });
  };

  renderHeader = () => {
    return (
      <CustomBreadcrumbs
        heading="Lab Pricing Table"
        links={[
          { name: "Dashboard", href: "/" },
          { name: "Lab Pricing Table" },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
    );
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value });
    this.setState({ page: 0 });
  };
  handleValueChange = (index, value, name) => {
    const newRows = [...this.state.list];

    newRows[index][name] = value;

    this.setState({ list: newRows });
  };
  updateValue = async (value, id, name) => {
    console.log("updateValue", value, id, name);
    let res = await this.props.apiCallPost(
      "/master-service/masterServiceUpdateScalarValue",
      {
        masterServiceId: id,
        key: name,
        value: value,
      }
    );
  };

  renderTable = () => {
    const columns = [
      { id: "labService", label: "Lab Service", minWidth: 170, maxWidth: 170 },
      { id: "crl", label: "CRL", minWidth: 100 },
      {
        id: "quest",
        label: "Quest",
        minWidth: 100,
      },
      {
        id: "labCorp",
        label: "Lab Corp",
        minWidth: 100,
      },
      {
        id: "pactox",
        label: "Pactox",
        minWidth: 100,
      },

      {
        id: "nationalJewish",
        label: "National Jewish",
        minWidth: 100,
      },
    ];

    return (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.list
                .slice(
                  this.state.page * this.state.rowsPerPage,
                  this.state.page * this.state.rowsPerPage +
                    this.state.rowsPerPage
                )
                .map((row, page_index) => {
                  const index =
                    page_index + this.state.page * this.state.rowsPerPage;
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell
                        style={{
                          maxWidth: 150,
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                        }}
                      >
                        {row?.serviceName}
                      </TableCell>
                      <TableCell>
                        <TextField
                          placeholder="Panel Code"
                          value={row?.crlPanelCode}
                          onChange={(e) =>
                            this.handleValueChange(
                              index,
                              e.target.value,
                              "crlPanelCode"
                            )
                          }
                          onBlur={(e) => {
                            this.updateValue(
                              e.target.value,
                              row._id,
                              "crlPanelCode"
                            );
                          }}
                          fullWidth
                        />
                        <TextField
                          placeholder="Panel Cost"
                          value={row?.crlBaseCostUSD || ""}
                          onChange={(e) =>
                            this.handleValueChange(
                              index,
                              e.target.value,
                              "crlBaseCostUSD"
                            )
                          }
                          onBlur={(e) => {
                            this.updateValue(
                              e.target.value,
                              row._id,
                              "crlBaseCostUSD"
                            );
                          }}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          placeholder="Panel Code"
                          value={row?.questPanelCode}
                          onChange={(e) =>
                            this.handleValueChange(
                              index,
                              e.target.value,
                              "questPanelCode"
                            )
                          }
                          onBlur={(e) => {
                            this.updateValue(
                              e.target.value,
                              row._id,
                              "questPanelCode"
                            );
                          }}
                          fullWidth
                        />
                        <TextField
                          placeholder="Panel Cost"
                          value={row?.questBaseCostUSD || ""}
                          onChange={(e) =>
                            this.handleValueChange(
                              index,
                              e.target.value,
                              "questBaseCostUSD"
                            )
                          }
                          onBlur={(e) => {
                            this.updateValue(
                              e.target.value,
                              row._id,
                              "questBaseCostUSD"
                            );
                          }}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          placeholder="Panel Code"
                          value={row?.pactoxPanelCode}
                          onChange={(e) =>
                            this.handleValueChange(
                              index,
                              e.target.value,
                              "pactoxPanelCode"
                            )
                          }
                          onBlur={(e) => {
                            this.updateValue(
                              e.target.value,
                              row._id,
                              "pactoxPanelCode"
                            );
                          }}
                          fullWidth
                        />
                        <TextField
                          placeholder="Panel Cost"
                          value={row?.pactoxBaseCostUSD || ""}
                          onChange={(e) =>
                            this.handleValueChange(
                              index,
                              e.target.value,
                              "pactoxBaseCostUSD"
                            )
                          }
                          onBlur={(e) => {
                            this.updateValue(
                              e.target.value,
                              row._id,
                              "pactoxBaseCostUSD"
                            );
                          }}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          placeholder="Panel Code"
                          value={row?.labCorpPanelCode}
                          onChange={(e) =>
                            this.handleValueChange(
                              index,
                              e.target.value,
                              "labCorpPanelCode"
                            )
                          }
                          onBlur={(e) => {
                            this.updateValue(
                              e.target.value,
                              row._id,
                              "labCorpPanelCode"
                            );
                          }}
                          fullWidth
                        />
                        <TextField
                          placeholder="Panel Cost"
                          value={row?.labCorpBaseCostUSD || ""}
                          onChange={(e) =>
                            this.handleValueChange(
                              index,
                              e.target.value,
                              "labCorpBaseCostUSD"
                            )
                          }
                          onBlur={(e) => {
                            this.updateValue(
                              e.target.value,
                              row._id,
                              "labCorpBaseCostUSD"
                            );
                          }}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          placeholder="Panel Code"
                          value={row?.njPanelCode}
                          onChange={(e) =>
                            this.handleValueChange(
                              index,
                              e.target.value,
                              "njPanelCode"
                            )
                          }
                          fullWidth
                          onBlur={(e) => {
                            this.updateValue(
                              e.target.value,
                              row._id,
                              "njPanelCode"
                            );
                          }}
                        />
                        <TextField
                          placeholder="Panel Cost"
                          value={row?.njBaseCostUSD || ""}
                          onChange={(e) =>
                            this.handleValueChange(
                              index,
                              e.target.value,
                              "njBaseCostUSD"
                            )
                          }
                          onBlur={(e) => {
                            this.updateValue(
                              e.target.value,
                              row._id,
                              "njBaseCostUSD"
                            );
                          }}
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={this.state.list.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  };
  render() {
    if (this.state.isSearching) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <div>
        <Stack spacing={3}>
          {this.renderHeader()}
          {this.renderTable()}
        </Stack>
      </div>
    );
  }
}
export default WithAPICall(LabPricingTable);
