"use client";

import { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Upload, File, CheckCircle } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import { Card, CardContent } from "@/components/ui/card";
import { toast } from "@/hooks/use-toast";
import { useProtectedApi } from "@/hooks/use-apiCall";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";

import { DataFileProcessingTimeline } from "./datafile-processing-timeline";
export function FileUploadPage() {
  const { callUploadApi } = useProtectedApi();

  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(null);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles[0].type !== "application/pdf") {
      toast({
        title: "Invalid file type",
        description: "Please upload a PDF file.",
        variant: "destructive",
      });
      return;
    }
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: { "application/pdf": [".pdf"] },
    multiple: false,
    noClick: true, // Prevent opening on click, we'll handle it manually
  });

  const uploadFile = async () => {
    if (!file) return;

    setUploading(true);
    setProgress(0);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await callUploadApi(
        "/datafiles/upload",
        formData,
        (progress) => {
          setProgress(progress);
        }
      );
      const result = response;
      setUploadedFile(result);
      toast({
        title: "File uploaded successfully",
        description: "Your file has been uploaded.",
      });
    } catch (error) {
      console.error("Upload error:", error);
      toast({
        title: "Upload failed",
        description:
          "There was an error uploading your file. Please try again.",
        variant: "destructive",
      });
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 w-full">
      <h1 className="text-3xl font-bold mb-8 text-center">File Upload</h1>
      {!uploadedFile ? (
        <Card>
          <CardContent className="pt-6">
            <div
              {...getRootProps()}
              className={`border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-colors ${
                isDragActive
                  ? "border-primary bg-primary/10"
                  : "border-muted-foreground"
              }`}
              // This will open the file picker when clicked
              onClick={open}
            >
              <input {...getInputProps()} />
              {file ? (
                <div className="flex items-center justify-center space-x-4">
                  <File className="h-8 w-8 text-primary" />
                  <span className="text-lg font-medium">{file.name}</span>
                </div>
              ) : (
                <div>
                  <Upload className="h-12 w-12 text-muted-foreground mx-auto mb-4" />
                  <p className="text-lg mb-2">
                    Drag & drop a PDF file here, or click to select
                  </p>
                  <p className="text-sm text-muted-foreground">
                    Only PDF files are accepted
                  </p>
                </div>
              )}
            </div>
            {file && !uploading && (
              <Button onClick={uploadFile} className="w-full mt-4">
                Upload File
              </Button>
            )}
            {file && !uploading && (
              <div className="mt-4 space-y-2">
                <Viewer
                  fileUrl={URL.createObjectURL(file)}
                  plugins={[defaultLayoutPlugin]}
                  // defaultScale={SpecialZoomLevel.PageFit}
                />
              </div>
            )}
            {uploading && (
              <div className="mt-4 space-y-2">
                <Progress value={progress} className="w-full" />
                <p className="text-sm text-center text-muted-foreground">
                  Uploading... {progress}%
                </p>
              </div>
            )}
          </CardContent>
        </Card>
      ) : (
        <UploadSuccessComponent file={uploadedFile} />
      )}
    </div>
  );
}

function UploadSuccessComponent({ file }) {
  return <DataFileProcessingTimeline _id={file._id} refresh={true} />;
}
