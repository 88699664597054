export default async function structureData(
  url,
  apiCallPost,
  from,
  to,
  companyIds = []
) {
  const massagedData = [];
  let categories = [];
  try {
    const res = await apiCallPost(url, {
      from: from.toDate(),
      to: to.toDate(),
      companyIds,
    });
    console.log({ res });
    for (const date of Array.from(Object.keys(res))) {
      let total = 0;
      const dayDate = {
        date,
      };
      for (const company of Array.from(Object.keys(res[date]))) {
        if (!categories.includes(company)) categories.push(company);
        dayDate[company] = res[date][company].count;
        total += res[date][company].count;
      }
      dayDate.Total = total;
      massagedData.push(dayDate);
    }
    for (const category of categories) {
      for (const dayDate of massagedData) {
        if (!(category in dayDate)) dayDate[category] = 0;
      }
    }
  } catch (error) {
    console.error("could not get data", error);
  }
  return { massagedData, categories };
}
