const TASK_STATUS = [
  "AWAITING_DOCUMENTS_PROCESSING",
  "DOCUMENT_PROCESSED",
  "SERVICE_NOT_DONE",
  "POSSIBLE_NO_SHOW",
  "MEDICAL_HOLD",
  "PENDING",
  "REJECTED",
  "NOT_AVAILABLE_YET",
  "B_READ_IMAGE_RECEIVED",
  "COMPLETED",
];

export default TASK_STATUS;
