"use client";
import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Badge } from "@/components/ui/badge";
import {
  CheckCircle,
  AlertCircle,
  RefreshCw,
  UserX,
  HelpCircle,
  Check,
  AlertTriangle,
  FileText,
  Send,
} from "lucide-react";
import { Label } from "@/components/ui/label";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { ClearanceScreenDone } from "@/micro/Visit/ClearanceArea/ClearanceScreenDone";

import { useProtectedApi } from "@/hooks/use-apiCall";
import useVisitStore from "@/store/useVisitStore";

const alcoholClearanceOptions = [
  { value: "Negative", text: "Negative" },
  { value: "Positive", text: "Positive" },
  { value: "Return to Clinic", text: "Return to Clinic" },
  { value: "Refused Test", text: "Refused Test" },
  { value: "Refer to Company", text: "Refer to Company" },
];

const ClearanceStatusSelect = ({ value, onChange }) => {
  return (
    <div className="space-y-2">
      <Label
        htmlFor="clearance-case"
        className="text-sm font-medium text-gray-700"
      >
        Clearance Status
      </Label>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Select value={value} onValueChange={onChange}>
              <SelectTrigger id="clearance-case" className="w-full">
                <SelectValue placeholder="Select clearance case" />
              </SelectTrigger>
              <SelectContent>
                {alcoholClearanceOptions.map((option, index) => (
                  <SelectItem
                    key={index}
                    value={option.value}
                    className="text-xs"
                  >
                    {option.text}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </TooltipTrigger>
          <TooltipContent>
            <p>Select the clearance status for this case</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

const AlcoholTestSingle = ({
  currentStatus,
  alcoholService,
  medicalRecords,
  onClear,
}) => {
  const { visit, loadVisit } = useVisitStore();
  const { callApi } = useProtectedApi();

  const getStatusColor = (value) => {
    if (value === 0) return "bg-green-100 text-green-800";
    if (value > 0 && value < 0.04) return "bg-yellow-100 text-yellow-800";
    return "bg-red-100 text-red-800";
  };

  const onSaveClearance = async () => {
    try {
      const resp = await callApi("/visit/updateVisitClearance", {
        visitId: visit._id,
        entityId: alcoholService._id,
        entity: "Alcohol",
        alcoholClearance: {
          finalClearanceText,
        },
      });
      onClear(resp);
    } catch (err) {
      console.log(err);
    }
  };

  const thisServiceMR = medicalRecords.filter(
    (mr) => mr.serviceId === alcoholService._id && mr.dataType === "ALCOHOL"
  );

  const initialResult =
    thisServiceMR?.[0]?.bloodAlcoholConcentrationInitial || "<None>";
  const airBlank = thisServiceMR?.[0]?.wasAirBlankDone || "<None>";
  const confirmationResult =
    thisServiceMR?.[0]?.bloodAlcoholConcentrationConfirmatory || "<None>";
  const notes = thisServiceMR?.[0]?.notes || "<None>";

  const [isCleared, setIsCleared] = useState(currentStatus?.isCleared || false);
  const [finalClearanceText, setFinalClearanceText] = useState(
    currentStatus?.finalClearanceText || ""
  );

  if (isCleared) {
    return (
      <ClearanceScreenDone
        mode="alcohol"
        entityId={alcoholService._id}
        onChangeClearance={() => setIsCleared(false)}
      />
    );
  }

  return (
    <Card className="w-full mx-auto shadow-lg mb-6">
      <CardHeader className="pb-2">
        <CardTitle className="text-lg font-medium">Alcohol Clearance</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <span className="text-sm font-medium">Initial Result:</span>
            <Badge className={getStatusColor(initialResult)}>
              {initialResult}
            </Badge>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-sm font-medium">Air Blank:</span>
            <Badge className={getStatusColor(airBlank)}>{airBlank}</Badge>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-sm font-medium">Confirmation Result:</span>
            <Badge className={getStatusColor(confirmationResult)}>
              {confirmationResult}
            </Badge>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-sm font-medium">Addl. Notes:</span>
            <Badge>{notes}</Badge>
          </div>
        </div>
      </CardContent>
      <CardFooter className="flex flex-col items-start w-full">
        <div className="w-full">
          <ClearanceStatusSelect
            value={finalClearanceText}
            onChange={(value) => setFinalClearanceText(value)}
          />
        </div>
        <div className="w-full flex justify-end mt-4">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  onClick={() => {
                    onSaveClearance();
                  }}
                  className="bg-blue-600 hover:bg-blue-700 text-white"
                >
                  <FileText className="mr-2 h-4 w-4" />
                  Preview & Process Clearance
                </Button>
              </TooltipTrigger>
              <TooltipContent side="top" className="text-xs max-w-xs">
                <p>
                  Preview and process the alcohol clearance based on the current
                  information
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </CardFooter>
    </Card>
  );
};

export const AlcoholTestMother = ({
  currentStatus,
  protocolId,
  medicalRecords,
  onClear,
}) => {
  const { callApi } = useProtectedApi();
  const { visit, loadVisit } = useVisitStore();

  const alcoholServices = currentStatus.childAlcoholServices;
  const alcoholClearanceStatus = currentStatus.alcoholClearanceStatus;

  return (
    <div className="space-y-6">
      {alcoholServices.map((alcoholService, index) => {
        const currentStatus = alcoholClearanceStatus[alcoholService._id];
        return (
          <AlcoholTestSingle
            key={index}
            currentStatus={currentStatus}
            alcoholService={alcoholService}
            medicalRecords={medicalRecords}
            onClear={(updatedVisit) => {
              loadVisit(updatedVisit);
            }}
          />
        );
      })}
    </div>
  );
};
