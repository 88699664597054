import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { WithAPICall } from "../utils/apiUtil";
import cogoToast from "cogo-toast";
import { LoadingButton } from "@mui/lab";
import { Alert, AlertTitle, FormControlLabel, Switch } from "@mui/material";

class CSFCreate extends React.Component {
  state = {
    fieldName: "",
    fieldDescription: "",
    fieldTag: "",
    needsLookUp: false,
    employeeSourceKey: "",
    isCreating: false,
  };
  createFromName = async () => {
    this.setState({ isCreating: true });
    try {
      cogoToast.loading("Creating Field");
      let fname = this.state.fieldName;
      // replace dots with underscore
      fname = fname.replace(/\./g, "_");
      const res = await this.props.apiCallPost("/company/csf/add", {
        fieldName: fname,
        fieldDescription: this.state.fieldDescription,
        fieldTag: this.state.fieldTag.replace(/\s/g, ""),
        employeeSourceKey: this.state.employeeSourceKey,
        needsLookUp: this.state.needsLookUp,
        companyId: this.props.companyId,
      });
      this.props.onCreate(res);
      cogoToast.success("Field Created");
    } catch (err) {
      cogoToast.error("Error Creating Field");
      console.log(err);
    }
    this.setState({ isCreating: false });
  };
  renderIfLookUp = () => {
    if (!this.state.needsLookUp) return null;
    return (
      <>
        <TextField
          margin="dense"
          id="description"
          name="name"
          label="Field Tag"
          fullWidth
          disabled={this.state.isCreating}
          value={this.state.fieldTag}
          onChange={(e) => {
            this.setState({ fieldTag: e.target.value });
          }}
        />{" "}
        <Alert severity="info">
          Field Tags are database-specific tags, so if you're unsure, contact
          the technology support team. Important: Field Tags can't have spaces.
          If you leave spaces while typing the tag, they will be removed
          automatically.
        </Alert>
        <TextField
          margin="dense"
          id="description"
          name="name"
          label="Primary Key"
          fullWidth
          disabled={this.state.isCreating}
          value={this.state.employeeSourceKey}
          onChange={(e) => {
            this.setState({ employeeSourceKey: e.target.value });
          }}
        />{" "}
        <Alert severity="info">
          What do you want to call the primary key for this field? For example,
          if you're creating a field called "Business Division", and the primary
          key is "Business Division ID", then enter "Business Division ID" here.
          If you're creating a field called "Job", then consider adding "Job
          Code" or "Job ID" here. If you're unsure, contact the technology
          support team.
        </Alert>{" "}
        <Alert severity="warning">
          Across all the fields you define (or that are part of the standard
          data model), the names of the primary keys must be unique. For
          example, if you have a field called "Business Division" and another
          field called "Job", you can't have the same primary key called "Code"
          for both of them. If you're unsure, contact the technology support
          team.
        </Alert>
      </>
    );
  };
  render() {
    return (
      <Dialog open={this.props.isOpen} onClose={() => this.props.handleClose()}>
        <DialogTitle>Create A New Field</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity="warning">
              Careful while adding fields - these can impact operations
              directly.
            </Alert>{" "}
            <Alert severity="error">
              You will not be able to edit the field tag and whether it needs a
              look up table after creation.
            </Alert>
            <br />
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Field Name"
            fullWidth
            disabled={this.state.isCreating}
            value={this.state.fieldName}
            onChange={(e) => {
              this.setState({ fieldName: e.target.value });
            }}
          />{" "}
          <Alert severity="info">
            For example, if a company has a property called "Business Division",
            or maintains against employees some data called "Job", the field
            name would be "Business Division" or "Job". No dots or periods
            allowed.
          </Alert>
          <FormControlLabel
            label="This field is looked up from a company database."
            labelPlacement="start"
            control={
              <Switch
                checked={this.state.needsLookUp}
                onChange={() => {
                  this.setState({
                    needsLookUp: !this.state.needsLookUp,
                    employeeSourceKey: this.state.needsLookUp
                      ? ""
                      : this.state.fieldTag,
                    fieldTag: this.state.needsLookUp ? "" : this.state.fieldTag,
                  });
                }}
              />
            }
            sx={{
              m: 0,
              width: 1,
              justifyContent: "space-between",
            }}
          />
          <Alert severity="info">
            Indicate here whether this field sources its values from a lookup
            table, such as a direct integration with a company that sends us
            documents or a company that sends us Excel/CSVs that we upload.
            Otherwise, leave this unchecked.
          </Alert>
          {this.renderIfLookUp()}
          <TextField
            multiline
            margin="dense"
            id="description"
            name="name"
            label="Field Description"
            fullWidth
            disabled={this.state.isCreating}
            value={this.state.fieldDescription}
            onChange={(e) => {
              this.setState({ fieldDescription: e.target.value });
            }}
          />{" "}
          <Alert severity="info">
            Enter a brief description of this data field, so that other users
            are aware.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.handleClose()}
            disabled={this.state.isCreating}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={this.state.isCreating}
            variant="contained"
            disabled={!this.state.fieldName}
            onClick={() => this.createFromName()}
          >
            Create Field
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}
export default WithAPICall(CSFCreate);
