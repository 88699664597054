import { CircularProgress, Snackbar, SnackbarContent } from "@mui/material";
import cogoToast from "cogo-toast";
import React from "react";

class UploadSnack extends React.Component {
  render() {
    if (this.props.uploadProgress === null) return null;
    if (this.props.uploadProgress === undefined) return null;
    if (this.props.uploadProgress === 0) {
      cogoToast.loading("Uploading", {
        position: "bottom-center",
      });
      return null;
    }
    if (this.props.uploadProgress === 100) {
      cogoToast.success("Upload Complete, Processing File Now...", {
        position: "bottom-center",
      });
      return null;
    }
    let message = "";
    if (this.props.uploadCount) {
      message = `Uploading File ${this.props.uploadCount + 1}`;
      message += `: ${this.props.uploadProgress}%`;
    } else {
      message = `Uploading ${this.props.uploadProgress}%`;
    }
    return (
      <div>
        <Snackbar
          open={true}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "centre",
          }}
        >
          <SnackbarContent
            message={message}
            action={
              <CircularProgress
                variant="determinate"
                value={this.props.uploadProgress}
              />
            }
          />
        </Snackbar>
      </div>
    );
  }
}

export default UploadSnack;
