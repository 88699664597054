import React from "react";
import { WithAPICall } from "../../utils/apiUtil";
import {
  Alert,
  Chip,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { UploadBox } from "src/components/upload";
import { LoadingButton } from "@mui/lab";
import Iconify from "src/components/iconify";
import cogoToast from "cogo-toast";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { niceFileSize } from "../../utils/fn";
import { nanoid } from "nanoid";

class DocTabUpload extends React.Component {
  state = {
    isUploading: false,
    filesToBeUploaded: [],
    resultsCount: [],
    uploadCount: 0,
    uploadProgress: null,
  };

  uploadSingle = async (file, index) => {
    try {
      let ftbu = this.state.filesToBeUploaded;
      ftbu[index].status = "UPLOADING";
      this.setState({
        filesToBeUploaded: ftbu,
      });
      const fd = new FormData();
      fd.append("file", file.file);
      fd.append("entityType", "VISIT_OUTPUT_FILE");
      fd.append("entityId", "");
      fd.append("description", file.name);
      let res = await this.props.apiCallPostUpload(
        "/files/addToEntity",
        fd,
        (e) => {
          this.setState({
            uploadProgress: e,
          });
        }
      );

      ftbu[index].status = res.processStatus;
      ftbu[index].processErrorStatus = res.processErrorStatus;
      this.setState({
        filesToBeUploaded: ftbu,
      });
      return;
    } catch (err) {
      cogoToast.error("Error Uploading File");
      console.log(err);
    }
  };
  uploadMultiple = async (filesToBeUploaded) => {
    let ftbu = filesToBeUploaded.map((each) => {
      return {
        file: each,
        name: each.name,
        size: each.size,
        type: each.type,
        nanoid: nanoid(),
        status: "PENDING",
      };
    });
    let ftbu2 = this.state.filesToBeUploaded;
    ftbu2 = ftbu2.concat(ftbu);
    this.setState(
      {
        isUploading: true,
        filesToBeUploaded: ftbu2,
      },
      () => {
        this.uploadHelper();
      }
    );
  };
  uploadHelper = async () => {
    let ftbu = this.state.filesToBeUploaded;
    for (let i = 0; i < ftbu.length; i++) {
      if (ftbu[i].status !== "PENDING") {
        continue;
      }
      await this.uploadSingle(ftbu[i], i);
    }
    this.setState({
      isUploading: false,
    });
  };
  renderUploader = () => {
    return (
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="subtitle1">Upload Documents</Typography>
        <Alert severity="info">
          <Typography variant="caption">
            You can upload multiple files at once. Each file will be processed
            individually.
          </Typography>
        </Alert>
        <UploadBox
          disabled={this.state.isUploading}
          onDrop={(e) => this.uploadMultiple(e)}
          placeholder={
            <Stack alignItems="center" sx={{ color: "text.disabled" }}>
              <LoadingButton loading={this.state.isUploading}>
                <Iconify icon="eva:cloud-upload-fill" width={30} />
              </LoadingButton>
            </Stack>
          }
        />
      </Stack>
    );
  };
  renderFileList = () => {
    if (!this.state.filesToBeUploaded.length) {
      return null;
    }
    let totalCount = this.state.filesToBeUploaded.length;
    let totalPending = this.state.filesToBeUploaded.filter(
      (each) => each.status === "PENDING"
    ).length;
    let progressChip = (
      <Stack direction="row" spacing={0.5}>
        {" "}
        <Chip
          label={`${totalCount} Total`}
          size="large"
          icon={<Iconify icon="ph:files-bold" />}
        />
        <Chip
          label={`${totalPending} Pending`}
          size="large"
          icon={<Iconify icon="ic:twotone-pending-actions" />}
        />
        {this.state.isUploading && (
          <Chip
            label={`1 Uploading`}
            size="large"
            icon={<Iconify icon="svg-spinners:pulse-rings-3" />}
          />
        )}
      </Stack>
    );
    return (
      <Stack spacing={2} direction="column">
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="subtitle1">Upload Status</Typography>
          {progressChip}
        </Stack>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>File Type</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.filesToBeUploaded.map((file, idx) => {
              let uploadingElem = null;
              if (file.status === "PENDING") {
                uploadingElem = (
                  <Stack direction="column">
                    <Typography variant="overline">Pending Upload</Typography>
                  </Stack>
                );
              } else if (file.status === "UPLOADING") {
                let uploadProgress = this.state.uploadProgress;
                if (uploadProgress > 99) {
                  uploadingElem = (
                    <Stack direction="column">
                      <LinearProgress variant="indeterminate" />
                      <Typography variant="overline">Processing...</Typography>
                    </Stack>
                  );
                } else {
                  uploadingElem = (
                    <Stack direction="column">
                      <LinearProgress
                        variant="determinate"
                        value={this.state.uploadProgress}
                      />
                      <Typography variant="overline">
                        {this.state.uploadProgress}%
                      </Typography>
                    </Stack>
                  );
                }
              } else if (file.status === "UPLOADED") {
                uploadingElem = (
                  <Stack direction="row">
                    <Iconify
                      icon="eva:checkmark-circle-2-fill"
                      color="success"
                    />
                    <Tooltip title="This file has been scheduled for further automatic processing">
                      {" "}
                      <Typography variant="overline">Uploaded</Typography>
                    </Tooltip>
                  </Stack>
                );
              } else {
                uploadingElem = (
                  <Stack direction="column">
                    <Stack direction="row">
                      <Iconify icon="eva:close-circle-fill" color="error" />
                      <Tooltip title="This file needs manual intervention. It has been posted to the list of files that need further review.">
                        <Typography variant="overline">
                          Error: {file.status}
                        </Typography>
                      </Tooltip>
                    </Stack>
                    <Typography variant="caption">
                      {file.processErrorStatus}
                    </Typography>
                  </Stack>
                );
              }
              return (
                <TableRow key={idx}>
                  <TableCell>{file.name}</TableCell>
                  <TableCell>{niceFileSize(file.size)}</TableCell>
                  <TableCell>{file.type}</TableCell>
                  <TableCell>{uploadingElem}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Stack>
    );
  };
  render() {
    return (
      <Stack spacing={2}>
        {this.renderUploader()}
        {this.renderFileList()}
      </Stack>
    );
  }
}
export default WithAPICall(DocTabUpload);
