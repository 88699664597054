import { Card, CategoryBar } from "@tremor/react";
import { RiErrorWarningLine } from "@remixicon/react";
import React from "react";
import _ from "lodash";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import {
  FILTER_UTILS,
  sumAggregation,
  averageAggregation,
  maxAggregation,
  minAggregation,
  countAggregation,
} from "../../utils/grid-utils";
import { formatTime } from "../utils/fn";
import { Stack } from "@mui/system";
class DocumentClassificationReport extends React.Component {
  state = {
    data: [],
    gridApi: null,
    columnApi: null,
    aggregations: {
      sum: (params) => sumAggregation(params),
      average: (params) => averageAggregation(params),
      max: (params) => maxAggregation(params),
      min: (params) => minAggregation(params),
      count: (params) => countAggregation(params),
    },
  };
  /******** GRID BEGINS */
  /** COLUMNS */
  COLUMNS = [
    {
      headerName: "Visit ID",
      field: "visit.humanReadableId",
      cellDataType: "text",
    },
    {
      headerName: "Employee",
      field: "employee.employeeName",
      cellDataType: "text",
    },
    {
      headerName: "Employer",
      field: "company.companyInformalName",
      cellDataType: "text",
    },
    {
      headerName: "Protocol",
      field: "protocol.companyProtocolName",
      cellDataType: "text",
    },
    {
      headerName: "Service",
      field: "psl.providerServiceAlias",
      cellDataType: "text",
    },
    {
      headerName: "Preference Recorded",
      field: "visit.employeeSchedulingPreference",
      cellDataType: "BOOLEAN",
    },
    {
      headerName: "Visit Requested At",
      field: "visit.createdAt",
      cellDataType: "date",
    },
    {
      headerName: "Visit Requested At (Time)",
      field: "visit.createdAt",
      cellDataType: "time",
    },
    {
      headerName: "Requested By",
      field: "visitCreatedBy.name",
      cellDataType: "text",
    },
    {
      headerName: "Requester User Type ",
      field: "visitCreatedBy.isExternal",
      cellDataType: "text",
      valueFormatter: (params) => {
        return params?.value ? "External" : "Internal";
      },
    },
    {
      headerName: "Scheduling Confirmed At",
      field: "createdAt",
      cellDataType: "date",
    },
    {
      headerName: "Scheduling Confirmed At (Time)",
      field: "createdAt",
      cellDataType: "time",
    },
    {
      headerName: "Scheduled By",
      field: "createdBy.name",
      cellDataType: "text",
    },
    {
      headerName: "Scheduler User Type ",
      field: "createdBy.isExternal",
      cellDataType: "text",
      valueFormatter: (params) => {
        return params?.value ? "External" : "Internal";
      },
    },
    {
      headerName: "Date of Visit",
      field: "scheduledTime",
      cellDataType: "date",
    },
    {
      headerName: "Date of Visit (Time)",
      field: "scheduledTime",
      cellDataType: "time",
    },

    {
      headerName: "Provider",
      field: "provider.providerName",
      cellDataType: "text",
    },

    {
      headerName: "Request to Schedule (Cal. Days)",
      valueGetter: (params) => {
        let visitRequestedAt = new Date(params.data.visit.createdAt);
        let schedulingConfirmedAt = new Date(params.data.createdAt);
        let diff = Math.abs(
          visitRequestedAt.getTime() - schedulingConfirmedAt.getTime()
        );
        return Math.round(diff / (1000 * 60 * 60 * 24));
      },
      cellDataType: "number",
    },
    {
      headerName: "Schedule to Visit (Cal. Days)",
      valueGetter: (params) => {
        let visitRequestedAt = new Date(params.data.scheduledTime);
        let schedulingConfirmedAt = new Date(params.data.createdAt);
        let diff = Math.abs(
          visitRequestedAt.getTime() - schedulingConfirmedAt.getTime()
        );
        return Math.round(diff / (1000 * 60 * 60 * 24));
      },
      cellDataType: "number",
    },
  ];

  onGridReady = (params) => {
    this.setState({ gridApi: params.api, columnApi: params.columnApi });
  };
  /** RENDER GRID */
  renderGrid = () => {
    let cs = "ag-theme-quartz";
    let theme = localStorage.getItem("themeMode");
    if (theme && theme === "dark") {
      cs = "ag-theme-quartz-dark";
    }
    return (
      <div className={cs} style={{ height: "70vh" }}>
        <AgGridReact
          onGridReady={this.onGridReady}
          rowData={this.props.data}
          columnDefs={this.COLUMNS.map((x) => {
            return { ...FILTER_UTILS[x.cellDataType], ...x };
          })}
          defaultColDef={{
            sortable: true,
          }}
          autoSizeStrategy={{
            type: "fitCellContents",
          }}
          pagination={true}
          rowGroupPanelShow={"always"}
          aggFuncs={this.state.aggregations}
          suppressDragLeaveHidesColumns={true}
          rowSelection="multiple"
          rowDragManaged={true}
          enableCharts={true}
          enableRangeSelection={true}
          enableCellTextSelection={true}
          sideBar={true}
          animateRows={true}
          statusBar={{
            statusPanels: [
              {
                statusPanel: "agTotalAndFilteredRowCountComponent",
                align: "left",
              },
              {
                statusPanel: "agSelectedRowCountComponent",
                align: "left",
              },
              {
                statusPanel: "agAggregationComponent",
                align: "right",
              },
            ],
          }}
          defaultExcelExportParams={{
            author: "WorkCare",
            fileName: `${this.props.reportType} - ${new Date().toLocaleDateString()}`,
            sheetName: `${this.props.reportType} - ${new Date().toLocaleDateString()}`,
          }}
        />
      </div>
    );
  };
  /******** GRID ENDS */
  /*********** CHART BEGINS */
  classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };
  dataMassager = () => {
    let dd = this.props.data;
    let errorStateCount = dd.filter((each) =>
      ["PDF_ERROR"].includes(each.processStatus)
    ).length;
    let manualClassificationCount = dd.filter((each) =>
      ["NEEDS_MANUAL_CLASSIFICATION"].includes(each.processStatus)
    ).length;
    let classifiedCount = dd.filter((each) =>
      ["CLASSIFIED"].includes(each.processStatus)
    ).length;
    let uploadedCount = dd.filter((each) =>
      ["UPLOADED", "CLASSIFYING"].includes(each.processStatus)
    ).length;
    let discardedCount = dd.filter((each) =>
      ["DISCARDED"].includes(each.processStatus)
    ).length;
    let totalCount =
      errorStateCount +
      manualClassificationCount +
      classifiedCount +
      uploadedCount +
      discardedCount;
    let details = [
      {
        name: "Queued",
        value: uploadedCount,
      },
      {
        name: "Errors",
        value: errorStateCount,
      },
      {
        name: "Awaiting Manual Classification",
        value: manualClassificationCount,
      },
      {
        name: "Classified",
        value: classifiedCount,
      },
      {
        name: "Discarded",
        value: discardedCount,
      },
    ];
    let split = details.map((each) => {
      let total = totalCount;
      let value = each.value;
      let percentage = Math.round((value / total) * 100);
      return percentage;
    });
    let massagedData = {
      name: "Document Status",
      total: totalCount,
      split: split,
      details: details,
    };
    return massagedData;
  };
  legendColor = {
    Queued: "bg-sky-500",
    Errors: "bg-violet-500",
    "Awaiting Manual Classification": "bg-red-500",
    Classified: "bg-green-500",
    Discarded: "bg-slate-500",
  };
  renderChart = () => {
    let data = this.dataMassager();
    let dd = this.props.data;
    let sysTimeToClassify = _.mean(dd, "sysTimeToClassify").toFixed(2);
    let errorCases = dd.filter((each) =>
      ["PDF_ERROR"].includes(each.processStatus)
    );
    let errorCasesTimes = errorCases.map((each) => {
      let updatedAt = new Date(each.updatedAt);
      let timeNow = new Date();
      let timeElapsedInMilliseconds = timeNow - updatedAt;
      return timeElapsedInMilliseconds / 1000;
    });
    let worstErrorWaitingTime = _.max(errorCasesTimes);
    let averageErrorWaitingTime = _.mean(errorCasesTimes);

    let manualClassificationCases = dd.filter((each) =>
      ["NEEDS_MANUAL_CLASSIFICATION"].includes(each.processStatus)
    );
    let manualClassificationTimes = manualClassificationCases.map((each) => {
      let updatedAt = new Date(each.updatedAt);
      let timeNow = new Date();
      let timeElapsedInMilliseconds = timeNow - updatedAt;
      return timeElapsedInMilliseconds / 1000;
    });
    let worstManualWaitingTime = _.max(manualClassificationTimes);
    let averageManualWaitingTime = _.mean(manualClassificationTimes);

    return (
      <Stack spacing={2}>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-1">
          <Card>
            <p className="truncate text-tremor-default text-tremor-content dark:text-dark-tremor-content">
              {data.name}
            </p>
            <p className="mt-1 text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              {data.total}
            </p>
            <CategoryBar
              values={data.split}
              colors={["sky", "violet", "red", "green", "slate"]}
              showLabels={false}
              className="mt-6"
            />
            <ul
              role="list"
              className="mt-4 flex flex-wrap items-center gap-x-4 gap-y-2"
            >
              {data.details.map((category) => (
                <li key={category.name} className="flex items-center space-x-2">
                  <span
                    className={this.classNames(
                      this.legendColor[category.name],
                      "h-3 w-3 shrink-0 rounded-sm"
                    )}
                    aria-hidden={true}
                  />
                  <span className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                    <span className="font-medium text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis">
                      {category.value}
                    </span>{" "}
                    {category.name}
                  </span>
                </li>
              ))}
            </ul>
          </Card>
        </div>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          <Card>
            <p className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
              Mean AI Time
            </p>
            <div className="mt-2 flex items-baseline space-x-2.5">
              <p className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                {sysTimeToClassify}
              </p>
              <span
                className={this.classNames(
                  "text-emerald-700 dark:text-emerald-500",
                  "text-tremor-default font-medium"
                )}
              >
                seconds
              </span>
            </div>
          </Card>
          <Card>
            <p className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
              Average Waiting Time: Error Cases
            </p>
            <p className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              {formatTime(averageErrorWaitingTime)}
            </p>
            <span
              className={this.classNames(
                "bg-red-100 text-red-800 dark:bg-red-400/10 dark:text-red-500",
                "mt-4 inline-flex items-center gap-x-1.5 rounded-tremor-small px-2 py-1.5 text-tremor-label font-medium"
              )}
            >
              <RiErrorWarningLine
                className="h-4 w-4 shrink-0"
                aria-hidden={true}
              />
              Worst Case: {formatTime(worstErrorWaitingTime)}
            </span>
          </Card>
          <Card>
            <p className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
              Average Waiting Time: Manual Cases
            </p>
            <p className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              {formatTime(averageManualWaitingTime)}
            </p>
            <span
              className={this.classNames(
                "bg-red-100 text-red-800 dark:bg-red-400/10 dark:text-red-500",
                "mt-4 inline-flex items-center gap-x-1.5 rounded-tremor-small px-2 py-1.5 text-tremor-label font-medium"
              )}
            >
              <RiErrorWarningLine
                className="h-4 w-4 shrink-0"
                aria-hidden={true}
              />
              Worst Case: {formatTime(worstManualWaitingTime)}
            </span>
          </Card>
        </div>
      </Stack>
    );
  };
  /********** CHART ENDS */
  render() {
    this.dataMassager();
    return (
      <div>
        {this.renderChart()}
        {/*   {this.renderGrid()} */}
      </div>
    );
  }
}
export default DocumentClassificationReport;
