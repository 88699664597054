// 'use client';
import { Typography } from "@mui/material";
import { BarList, LineChart } from "@tremor/react";

const valueFormatter = (number) =>
  `$ ${Intl.NumberFormat("us").format(number).toString()}`;

export default function ProviderCost() {
  return (
    <>
      <LineChart
        data={[]}
        index="date"
        categories={["CRL", "LabCorp", "Aegis"]}
        colors={["blue", "violet", "fuchsia"]}
        valueFormatter={valueFormatter}
        showLegend={false}
        showYAxis={false}
        startEndOnly={true}
        className="mt-6 h-32"
      />
      {/* <Typography variant="subtitle2" sx={{ mb: 1 }}>
        Top 3 Providers
      </Typography> */}
      {/* <BarList data={providers} valueFormatter={valueFormatter} /> */}
    </>
  );
}
