import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Alert,
  Card,
  Chip,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { View500 } from "src/sections/error";
import cogoToast from "cogo-toast";
import { Box, Stack } from "@mui/system";
import { niceDateTime, niceFileSize } from "../utils/fn";
import _ from "lodash";
import Iconify from "src/components/iconify";
import { LoadingButton } from "@mui/lab";
import AILoader from "../utils/AILoader";
import DocumentGroup from "./DocumentGroup";
import Magnifier from "react-magnifier";
import { SERVICE_DATA_TYPE_OPTIONS } from "src/assets/data/service-data-type";

class DocumentDetails extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    isSaving: false,
    pdfPages: [],
    pagesOfFile: [],
    aiOutput: "",
    aiRunning: false,
    currentAction: null,
  };
  componentDidMount() {
    this.getById();
  }
  getById = async () => {
    try {
      let res = await this.props.apiCallPost("/files/getById", {
        cfId: this.props.id,
      });
      this.setState({ ...res }, () => {
        let p = res.pdfPages;
        if (p && p.length) {
          this.getPages();
        } else {
          this.setState({
            isLoading: false,
            isError: false,
          });
        }
      });
    } catch (error) {
      cogoToast.error("Error fetching document details");
      this.setState({ isLoading: false, isError: true });
    }
  };
  getPages = async () => {
    try {
      let res = await this.props.apiCallPost("/files/getPagesOfFile", {
        cfId: this.props.id,
      });
      this.setState({ pagesOfFile: res }, () => {
        this.getGroups();
      });
    } catch (error) {
      cogoToast.error("Error fetching document pages");
      this.setState({ isLoading: false, isError: true });
    }
  };
  rotatePage = async (pageId, degrees) => {
    try {
      let pagesOfFile = this.state.pagesOfFile;
      let ix = _.findIndex(pagesOfFile, { _id: pageId });
      if (ix === -1) {
        cogoToast.error("Error editing page");
        return false;
      }
      let pageObject = pagesOfFile[ix];
      let pageIx = pageObject.pageIx;
      cogoToast.loading(`Rotating Page ${pageIx + 1}`);
      this.setState({ isSaving: true });
      let res = await this.props.apiCallPost(
        "/files/pages/editCfioPageRotation",
        {
          pageId: pageId,
          rotateDegrees: degrees,
        }
      );

      pagesOfFile[ix] = res;
      this.setState({ pagesOfFile: pagesOfFile, isSaving: false });
      return true;
    } catch (error) {
      this.setState({ isSaving: false });
      cogoToast.error("Error rotating page");
      return false;
    }
  };
  editPage = async (pageId, uops) => {
    try {
      let pagesOfFile = this.state.pagesOfFile;
      let ix = _.findIndex(pagesOfFile, { _id: pageId });
      if (ix === -1) {
        cogoToast.error("Error editing page");
        return false;
      }
      let pageObject = pagesOfFile[ix];
      let pageIx = pageObject.pageIx;
      cogoToast.loading(`Editing Page ${pageIx + 1}`);
      this.setState({ isSaving: true });
      let res = await this.props.apiCallPost("/files/pages/editPage", {
        pageId: pageId,
        uops: uops,
      });

      pagesOfFile[ix] = res;
      this.setState({ pagesOfFile: pagesOfFile, isSaving: false });
      return true;
    } catch (error) {
      this.setState({ isSaving: false });
      cogoToast.error("Error editing page");
      return false;
    }
  };
  classifyAndExtractName = async () => {
    this.setAllArchived(false);
    try {
      this.setState({
        aiOutput: "",
        aiRunning: true,
        currentAction: "Pre-Processing Document",
        groups: [],
      });
      await this.props.apiCallPostStreamingForAI(
        "/external/pdf/classifyAndExtractName",
        {
          fileId: this.props.id,
          stream: true,
        },
        (data) => {
          this.setState({
            aiOutput: this.state.aiOutput + data,
          });
        },
        () => {
          this.onEnd();
        }
      );
    } catch (err) {
      cogoToast.error("Error Pre-Processing File");
      console.log(err);
    }
  };
  onEnd = async () => {
    // wait 2 seconds
    await new Promise((resolve) => setTimeout(resolve, 2000));
    this.massagePreprocessingOutput();
  };
  massagePreprocessingOutput = async () => {
    let aiOutput = this.state.aiOutput;
    if (!aiOutput) {
      return null;
    }
    let lines = aiOutput.split("\n");
    if (!lines || !lines.length) {
      return null;
    }
    let reRunFlag = false;
    console.log({ lines });
    for (var i = 0; i < lines.length; i++) {
      let line = lines[i];
      let lineParts = line.split("#");
      let lineIndex = lineParts[0];
      let lineIndexTrue = parseInt(lineIndex) - 1;
      let lineDataType = lineParts[1];
      let pof = this.state.pagesOfFile;
      let pageIdIdx = _.findIndex(pof, (x) => x.pageIx === lineIndexTrue);
      let pageId = null;
      if (pageIdIdx === -1) {
        continue;
      } else {
        pageId = pof[pageIdIdx]._id;
      }
      if (lineDataType.includes("ERR_PAGE_ROT_")) {
        this.setState({
          currentAction: `Rotating Page ${lineIndex}`,
        });
        let degrees = 90;
        if (lineDataType.includes("ERR_PAGE_ROT_LEFT")) {
          degrees = 90;
        } else if (lineDataType.includes("ERR_PAGE_ROT_RIGHT")) {
          degrees = -90;
        } else if (lineDataType.includes("ERR_PAGE_ROT_INVERTED")) {
          degrees = 180;
        }
        await this.rotatePage(pageId, degrees);
        reRunFlag = true;
      } else {
        let employeeName = lineParts[2];
        this.setState({
          currentAction: `Editing Page ${lineIndex}`,
        });
        await this.editPage(pageId, [
          { key: "pageDataType", value: lineDataType },
          { key: "extractedEEName", value: employeeName },
        ]);
      }
    }
    if (reRunFlag) {
      this.classifyAndExtractName();
    } else {
      this.setState(
        {
          aiRunning: false,
          currentAction: "Creating Groups",
          aiOutput: "",
        },
        () => {
          this.massageIntoGroups();
        }
      );
    }
  };
  setAllArchived = async (callMassage = true) => {
    if (!this.state.groups || !this.state.groups.length) {
      if (callMassage) {
        this.massageIntoGroups();
      } else {
        this.setState({
          isSaving: false,
        });
      }
    }
    try {
      this.setState({
        isSaving: true,
        currentAction: "Archiving Groups",
      });
      await this.props.apiCallPost("/files/groups/setAllArchived", {
        cfId: this.props.id,
      });
      this.setState(
        {
          groups: [],
          isSaving: true,
        },
        () => {
          if (callMassage) {
            this.massageIntoGroups();
          } else {
            this.setState({
              isSaving: false,
            });
          }
        }
      );
    } catch (err) {
      this.setState({
        isSaving: false,
      });
      cogoToast.error("Error archiving groups");
      console.log(err);
    }
  };
  massageIntoGroups = async () => {
    //  // first archive all current groups
    this.setState({
      isSaving: true,
      currentAction: "Massaging Groups",
    });
    let pagesOfFile = this.state.pagesOfFile;
    let eeNamesArr = _.map(pagesOfFile, "extractedEEName");
    let dataTypeArr = _.map(pagesOfFile, "pageDataType");
    let groups = [];
    for (var i = 0; i < eeNamesArr.length; i++) {
      for (var j = 0; j < dataTypeArr.length; j++) {
        let eeName = eeNamesArr[i];
        let dataType = dataTypeArr[j];

        let alreadyInGroup = _.filter(
          groups,
          (x) => x.extractedEEName === eeName && x.pageDataType === dataType
        );
        if (alreadyInGroup.length) {
          continue;
        }

        let pagesMatchingCriteria = _.filter(
          pagesOfFile,
          (x) => x.extractedEEName === eeName && x.pageDataType === dataType
        );
        if (pagesMatchingCriteria.length) {
          let obj = {
            pageIds: _.map(pagesMatchingCriteria, "_id"),
            pageDataType: dataType,
            extractedEEName: eeName,
          };
          groups.push(obj);
        }
      }
    }
    this.setState({
      currentAction: "Creating Groups",
      isSaving: true,
    });
    for (var i = 0; i < groups.length; i++) {
      await this.createGroups(groups[i]);
    }
    this.setState({ currentAction: "Updating Groups" }, () => {
      this.getGroups();
    });
  };
  createGroups = async (group) => {
    try {
      await this.props.apiCallPost("/files/groups/createGroup", {
        ...group,
      });
    } catch (err) {
      cogoToast.error("Error creating group");
      console.log(err);
      return null;
    }
  };
  getGroups = async () => {
    try {
      let res = await this.props.apiCallPost("/files/groups/getFromCloudFile", {
        cfId: this.props.id,
      });
      this.setState({
        currentAction: null,
        groups: res,
        isSaving: false,
        isLoading: false,
        isError: false,
      });
    } catch (err) {
      cogoToast.error("Error fetching groups");
      console.log(err);
      return null;
    }
  };
  renderFileDetails = () => {
    return (
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography variant="overline">{this.state.originalName}</Typography>{" "}
        <Typography variant="caption">
          {niceFileSize(this.state.sizeBytes)}
        </Typography>{" "}
        <Typography variant="caption">
          Uploaded: {niceDateTime(this.state.createdAt)} by{" "}
          {this.state.createdBy.name}
        </Typography>
        {this.renderAIPreProcessButton()}
        {this.renderCurrentAction()}
        {this.renderPreProcessingLoader()}
      </Stack>
    );
  };
  renderPreProcessingLoader = () => {
    if (!this.state.aiRunning) {
      return null;
    }
    return <AILoader text="THINKING" />;
  };
  renderCurrentAction = () => {
    if (!this.state.currentAction) {
      return null;
    }
    return (
      <Chip
        label={this.state.currentAction}
        icon={<Iconify icon="svg-spinners:pulse-multiple" />}
      />
    );
  };
  renderAIPreProcessButton = () => {
    return (
      <LoadingButton
        disabled={this.state.aiRunning || this.state.isSaving}
        size="small"
        loading={this.state.aiRunning}
        onClick={() => {
          this.classifyAndExtractName();
        }}
      >
        Pre-Process File
      </LoadingButton>
    );
  };
  renderGroupsButton = () => {
    if (this.state.aiRunning) {
      return null;
    }
    return (
      <LoadingButton
        size="small"
        loading={this.state.isSaving}
        onClick={() => {
          this.setAllArchived();
        }}
      >
        Update Groups
      </LoadingButton>
    );
  };
  renderPagesOfFile = () => {
    let pagesOfFile = this.state.pagesOfFile;
    if (!pagesOfFile || !pagesOfFile.length) {
      return <Alert severity="info">No pages found</Alert>;
    }
    let pp = _.sortBy(pagesOfFile, "pageIx");
    return (
      <Card sx={{ pt: 3, pb: 3, px: 3 }}>
        <Stack spacing={2}>
          <Stack spacing={2} direction="row" alignItems="center">
            <Typography variant="button">Individual Pages</Typography>
            {this.renderGroupsButton()}
          </Stack>
          <Box
            rowGap={2}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(4, 1fr)",
              lg: "repeat(6, 1fr)",
              xl: "repeat(8, 1fr)",
            }}
          >
            {pp.map((page) => {
              return this.renderEachPage(page);
            })}
          </Box>
        </Stack>
      </Card>
    );
  };
  renderEachPage = (page) => {
    let eeNameElem = (
      <TextField
        size="small"
        disabled={this.state.isSaving || this.state.aiRunning}
        label="EE Name/Placeholder"
        InputLabelProps={{
          shrink: page.extractedEEName ? true : false,
        }}
        value={page.extractedEEName}
        onChange={(e) => {
          let v = e.target.value;
          // set in state
          let pagesOfFile = this.state.pagesOfFile;
          let ix = _.findIndex(pagesOfFile, { _id: page._id });
          if (ix === -1) {
            cogoToast.error("Error editing page");
            return false;
          }
          let pageObject = pagesOfFile[ix];
          pageObject.extractedEEName = v;
          pagesOfFile[ix] = pageObject;
          this.setState({ pagesOfFile: pagesOfFile });
        }}
        onBlur={() => {
          this.editPage(page._id, [
            { key: "extractedEEName", value: page.extractedEEName },
          ]);
        }}
      />
    );

    let pageTypeElem = (
      <Stack
        spacing={2}
        sx={{
          m: 0,
          width: 1,
          justifyContent: "space-between",
        }}
      >
        <Typography variant="caption">Page Type</Typography>
        <Select
          disabled={this.state.isSaving || this.state.aiRunning}
          sx={{
            minWidth: "50px",
          }}
          size="small"
          value={page.pageDataType}
          onChange={(e) => {
            let v = e.target.value;
            this.editPage(page._id, [{ key: "pageDataType", value: v }]);
          }}
        >
          {SERVICE_DATA_TYPE_OPTIONS.map((opt, idx) => {
            return (
              <MenuItem value={opt} size="small" key={idx}>
                {opt}
              </MenuItem>
            );
          })}
        </Select>
      </Stack>
    );
    let rotateElem = (
      <Stack
        spacing={2}
        sx={{
          m: 0,
          width: 1,
          justifyContent: "space-between",
        }}
      >
        <Stack direction="row" alignItems={"center"} justifyContent={"center"}>
          <IconButton
            onClick={() => this.rotatePage(page._id, 90)}
            disabled={this.state.isSaving || this.state.aiRunning}
          >
            <Iconify icon="ic:outline-rotate-90-degrees-cw" />
          </IconButton>{" "}
          <IconButton
            onClick={() => this.rotatePage(page._id, -90)}
            disabled={this.state.isSaving || this.state.aiRunning}
          >
            <Iconify icon="ic:baseline-rotate-90-degrees-ccw" />
          </IconButton>
          <IconButton
            onClick={() => this.rotatePage(page._id, 180)}
            disabled={this.state.isSaving || this.state.aiRunning}
          >
            <Iconify icon="eva:flip-fill" />
          </IconButton>
        </Stack>
      </Stack>
    );
    return (
      <Stack spacing={2}>
        <Typography variant="caption">Page {page.pageIx + 1}</Typography>
        <Magnifier
          onClick={() => {
            window.open(page.psUrl, "_blank");
          }}
          src={page.psUrl}
          width={250}
          mgWidth={150}
          mgHeight={150}
          zoomFactor={2}
        />
        {eeNameElem}
        {pageTypeElem}
        {rotateElem}
      </Stack>
    );
  };
  renderAIOutputSection = () => {
    if (this.state.aiRunning && !this.state.aiOutput) {
      return <LinearProgress />;
    }
    if (!this.state.aiOutput) {
      return null;
    }
    let lines = this.state.aiOutput.split("\n");
    return (
      <Card sx={{ p: 3, mt: 3 }}>
        <Stack spacing={2}>
          {lines.map((line) => {
            return (
              <Typography variant="caption" sx={{ whiteSpace: "pre-line" }}>
                {line}
              </Typography>
            );
          })}
        </Stack>
      </Card>
    );
  };
  renderGroups = () => {
    if (!this.state.groups || !this.state.groups.length) {
      return (
        <Alert severity="info">
          No groups created. Start pre-processing or check your file.
        </Alert>
      );
    }

    let gg = this.state.groups;
    let ggSorted = _.sortBy(gg, "extractedEEName");
    return (
      <Card sx={{ pt: 3, pb: 3, px: 3, boxShadow: 3 }}>
        <Stack spacing={2}>
          <Typography variant="button">Processed Groups</Typography>
          <Box
            rowGap={2}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(1, 1fr)",
              md: "repeat(1, 1fr)",
              lg: "repeat(1, 1fr)",
              xl: "repeat(1, 1fr)",
            }}
          >
            {ggSorted.map((g, index) => {
              return (
                <DocumentGroup
                  showDocumentHeader={true}
                  data={g}
                  groupIx={index}
                  key={index}
                  cfId={g._id}
                />
              );
            })}
          </Box>
        </Stack>
      </Card>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <Stack spacing={2}>
        {this.renderFileDetails()}
        {this.renderAIOutputSection()}
        {this.renderGroups()}
        {this.renderPagesOfFile()}
      </Stack>
    );
  }
}

export default WithAPICall(DocumentDetails);
