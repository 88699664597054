import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import _ from "lodash";
import cogoToast from "cogo-toast";
import {
  Button,
  Card,
  Chip,
  FormControlLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { View500 } from "src/sections/error";
import { Box, Stack } from "@mui/system";
import { niceDate } from "../utils/fn";
import EmployeeCSFDataField from "./EmployeeCSFDataField";
import EmployeeCSFNLDataField from "./EmployeeCSFNLDataField";
import { DayPicker } from "react-day-picker";
import { LoadingButton } from "@mui/lab";
import EmployeeTransferProtocol from "./EmployeeTransferProtocol";
import Grid from "@mui/system/Unstable_Grid/Grid";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import CountrySelector from "../utils/CountrySelector";
import GMap from "../utils/GMap";
import Iconify from "src/components/iconify";
class EmployeeTransfer extends React.Component {
  state = {
    isLoading: false,
    isError: false,
    listOfFields: [],
    csf: [],
    nonLookUpCSFs: [],
    lookUpCSFs: [],
    changedKeys: {},
    newValues: {},
    isCheckingProtocols: false,
    isCheckingExitProtocols: false,
    hasCheckedProtocols: false,
    hasCheckedExitProtocols: false,
    exitProtocols: [],
    preplacementProtocols: [],
    showPropertyTypes: ["CSF"],
    isCreatingNewCandidate: false,
  };
  LIST_OF_RELEVANT_ROOT_FIELDS = [
    {
      label: "Employee Name",
      id: "employeeName",
      type: "TEXT",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Address Line 1",
      id: "employeeAddress",
      type: "TEXT",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Address Line 2",
      id: "employeeAddressQualilfier",
      type: "TEXT",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "City",
      id: "employeeCity",
      type: "TEXT",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Employee Name",
      id: "employeeState",
      type: "TEXT",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Employee Name",
      id: "employeeName:",
      type: "TEXT",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Respirable Crystalline Silica",
      id: "hasRespirableCrystallineSilicaExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Physical Exam Data Capture",
      id: "hasPhysicalExamDataCaptureExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Methylene Chloride",
      id: "hasMethyleneChlorideExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Lead",
      id: "hasLeadExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Inorganic Arsenic",
      id: "hasInorganicArsenicExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "First Exposure To Arsenic",
      id: "arsenicExposureInitialDate",
      type: "DATE",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Hazwoper",
      id: "hasHazwoperExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Formaldehyde",
      id: "hasFormaldehydeExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Chromium (VI) Hexavalent",
      id: "hasChromiumVIHexavalentExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Cadmium",
      id: "hasCadmiumExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Bloodborne Pathogen",
      id: "hasBloodbornePathogenExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Beryllium",
      id: "hasBerylliumExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Benzene",
      id: "hasBenzeneExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Asbestos",
      id: "hasAsbestosExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "First Exposure To Asbestos",
      id: "asbestosExposureInitialDate",
      type: "DATE",
      from: "ROOT",
      hasChanged: false,
    },

    {
      label: "DOT Status",
      id: "dotStatus",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
  ];
  componentDidMount() {
    this.getCSFDefinitions();
  }
  getCSFDefinitions = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost("/company/csf/get", {
        companyId: this.props.companyId,
      });
      let nonLookUpCSFs = _.filter(res, (each) => !each.needsLookUp);
      let lookUpCSFs = _.filter(res, (each) => each.needsLookUp);
      this.setState(
        {
          csf: res,
          nonLookUpCSFs: nonLookUpCSFs,
          lookUpCSFs: lookUpCSFs,
        },
        () => {
          this.populateListOfFieldsInState();
        }
      );
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Employee Specific Fields");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  populateListOfFieldsInState = () => {
    let listOfFields = [];
    //  listOfFields = [...this.LIST_OF_RELEVANT_ROOT_FIELDS];
    for (var i = 0; i < this.state.lookUpCSFs.length; i++) {
      let each = this.state.lookUpCSFs[i];
      let field = {
        label: each.fieldName,
        id: each._id,
        data: each,
        from: "CSF",
        hasChanged: false,
      };
      listOfFields.push(field);
    }
    for (var i = 0; i < this.state.nonLookUpCSFs.length; i++) {
      let each = this.state.nonLookUpCSFs[i];
      let field = {
        label: each.fieldName,
        id: each._id,
        data: each,
        from: "ESF",
        hasChanged: false,
      };
      listOfFields.push(field);
    }
    this.setState({
      listOfFields: listOfFields,
      isLoading: false,
    });
  };
  renderTable = () => {
    let lf = this.state.listOfFields;
    if (!lf.length) return null;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell> <TableCell>Data</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow></TableRow>
          {lf.map((eachField, index) => {
            return (
              <TableRow>
                <TableCell>{this.renderFieldNameTag(eachField)}</TableCell>{" "}
                <TableCell>
                  {this.renderProposedValueOfField(eachField, index)}
                </TableCell>
              </TableRow>
            );
          })}
          {this.renderCheckedProtocols()}
        </TableBody>
      </Table>
    );
  };
  renderCheckedProtocols = () => {
    let exitProtocols = this.state.exitProtocols;
    let preplacementProtocols = this.state.preplacementProtocols;
    let exitProtocolsLength = exitProtocols.length;
    let preplacementProtocolsLength = preplacementProtocols.length;
    let totalProtocols = exitProtocolsLength + preplacementProtocolsLength;
    if (!this.state.hasCheckedProtocols) {
      return null;
    }

    return (
      <TableRow>
        <TableCell>
          <Stack alignItems={"flex-start"}>
            <Typography variant="overline">Protocols Applicable</Typography>
            <Typography variant="button">
              Total: {totalProtocols}
            </Typography>{" "}
          </Stack>
        </TableCell>{" "}
        <TableCell>
          <Stack spacing={1} alignItems={"flex-start"}>
            {" "}
            <Typography variant="overline">
              Preplacement Protocols Applicable: {preplacementProtocolsLength}
            </Typography>
            {preplacementProtocols.map((each, i) => {
              return (
                <EmployeeTransferProtocol
                  key={i}
                  data={each}
                  companyId={this.props.companyId}
                  modSpec={this.massageChanges()}
                  protocolType="Preplacement"
                  employeeSpec={this.state}
                  //  employeeId={this.props.id}
                  onCheckServiceCriteria={(protocolIsIgnorable) => {
                    let pp = this.state.preplacementProtocols;
                    pp[i].isIgnoreable = protocolIsIgnorable;
                    this.setState({
                      preplacementProtocols: pp,
                    });
                  }}
                />
              );
            })}
          </Stack>
        </TableCell>
      </TableRow>
    );
  };
  renderFieldNameTag = (eachField) => {
    let helper = "Core";
    if (eachField.from === "ESF") {
      helper = "Employee Specific";
    }
    if (eachField.from === "CSF") {
      helper = "Linked To Database";
    }

    return (
      <Stack>
        <Typography variant="button">{eachField.label}</Typography>{" "}
      </Stack>
    );
  };
  renderProposedValueOfField = (eachField, index) => {
    if (eachField.from === "ROOT") {
      return this.renderProposedRootField(eachField, index);
    }
    if (eachField.from === "CSF") {
      return (
        <EmployeeCSFDataField
          onSetCSF={(e) => {
            let newValues = this.state.newValues;
            let esk = eachField.data.employeeSourceKey;
            newValues[esk] = e;
            let hasChanged = false;
            if (e !== this.state[esk]) {
              hasChanged = true;
            }
            let list = this.state.listOfFields;
            list[index].hasChanged = hasChanged;
            this.setState({
              newValues,
              listOfFields: list,
              hasCheckedProtocols: false,
              hasCheckedExitProtocols: false,
            });
          }}
          fromTransferPage={true}
          allowEdit={true}
          employeeData={this.state}
          data={eachField.data}
          companyId={this.props.companyId}
          employeeId={this.props.id}
        />
      );
    }
    if (eachField.from === "ESF") {
      return (
        <EmployeeCSFNLDataField
          onSetESF={(k, v) => {
            let newValues = _.cloneDeep(this.state.newValues);

            let esfObject = newValues.esfObject;

            if (!esfObject) {
              esfObject = {};
            }

            let edfn = eachField.data.fieldName;

            if (!esfObject[edfn]) {
              esfObject[edfn] = {};
            }

            let currentObject = _.cloneDeep(
              esfObject[eachField.data.fieldName]
            );

            currentObject[k] = v;

            let originalObject = esfObject[eachField.data.fieldName];

            esfObject[eachField.data.fieldName] = currentObject;

            newValues.esfObject = esfObject;

            let hasChanged = !_.isEqual(currentObject, originalObject);
            let list = this.state.listOfFields;
            list[index].hasChanged = hasChanged;

            this.setState({
              newValues,
              listOfFields: list,
              hasCheckedProtocols: false,
              hasCheckedExitProtocols: false,
            });
          }}
          fromTransferPage={true}
          allowEdit={true}
          employeeData={this.state}
          data={eachField.data}
          companyId={this.props.companyId}
          employeeId={this.props.id}
        />
      );
    }
  };
  renderCurrentRootField = (eachField) => {
    let value = this.state[eachField.id];
    let type = eachField.type;
    return this.renderCurrentPrimitiveType(type, value);
  };
  renderProposedRootField = (eachField, index) => {
    return this.renderProposedPrimitiveType(eachField, index);
  };
  renderCurrentPrimitiveType = (type, value) => {
    if (type === "SWITCH") {
      if (value) {
        return "Yes";
      } else {
        return "No";
      }
    }
    if (type === "DATE") {
      if (value) {
        return niceDate(value);
      } else {
        return "Not Entered";
      }
    }
  };
  renderProposedPrimitiveType = (eachField, index) => {
    let type = eachField.type;
    let originalValue = this.state[eachField.id];
    let currentValue = this.state.newValues[eachField.id];
    if (type === "SWITCH") {
      return (
        <Switch
          checked={currentValue}
          onChange={() => {
            let newValues = this.state.newValues;
            newValues[eachField.id] = !newValues[eachField.id];
            let hasChanged = false;
            if (newValues[eachField.id] !== originalValue) {
              hasChanged = true;
            }
            let list = this.state.listOfFields;
            list[index].hasChanged = hasChanged;
            this.setState({
              newValues,
              listOfFields: list,
              hasCheckedProtocols: false,
              hasCheckedExitProtocols: false,
            });
          }}
        />
      );
    }
    if (type === "DATE") {
      return (
        <>
          <Chip
            variant="contained"
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                [`open_${eachField.id}_${eachField.type}_${eachField.from}`]: true,
              });
            }}
            label={currentValue ? niceDate(currentValue) : "Not Entered"}
          />
          <Popover
            open={
              this.state[
                `open_${eachField.id}_${eachField.type}_${eachField.from}`
              ]
            }
            anchorEl={this.state.anchorEl}
            onClose={() => {
              this.setState({
                anchorEl: null,
                [`open_${eachField.id}_${eachField.type}_${eachField.from}`]: false,
              });
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <DayPicker
              mode="single"
              captionLayout="dropdown-buttons"
              fromYear={1901}
              toYear={2035}
              onSelect={(e) => {
                let newValues = this.state.newValues;
                newValues[eachField.id] = e;
                let hasChanged = false;
                if (e !== originalValue) {
                  hasChanged = true;
                }
                let list = this.state.listOfFields;
                list[index].hasChanged = hasChanged;
                this.setState({
                  newValues,
                  anchorEl: null,
                  [`open_${eachField.id}_${eachField.type}_${eachField.from}`]: false,
                  listOfFields: list,
                  hasCheckedProtocols: false,
                  hasCheckedExitProtocols: false,
                });
              }}
            />
          </Popover>
        </>
      );
    }
  };
  massageChanges = () => {
    let modSpec = {};
    for (var i = 0; i < this.state.listOfFields.length; i++) {
      let each = this.state.listOfFields[i];
      let hasChanged = each.hasChanged;
      if (hasChanged) {
        let from = each.from;
        if (from === "ROOT") {
          modSpec[each.id] = this.state.newValues[each.id];
        }
        if (from === "CSF") {
          let esk = each.data.employeeSourceKey;
          modSpec[esk] = this.state.newValues[esk];
        }
        if (from === "ESF") {
          let edfn = each.data.fieldName;
          let esfObject = this.state.newValues.esfObject;
          let currentObject = esfObject[edfn];
          for (var k in currentObject) {
            let v = currentObject[k];
            if (v === "") {
              continue;
            }
            if (v === undefined) {
              continue;
            }
            if (v === null) {
              continue;
            }
            modSpec[`esfObject.${each.data.fieldName}.${k}`] = v;
          }
        }
      }
    }
    return modSpec;
  };
  doesModifiedEmployeeSatisfyAnyProtocols = async (modSpec) => {
    try {
      this.setState({
        isCheckingProtocols: true,
        hasCheckedProtocols: false,
        preplacementProtocols: [],
      });
      let res = await this.props.apiCallPost(
        "/employee/doesModifiedEmployeeSatisfyAnyProtocols",
        {
          employeeId: this.props.id,
          companyProtocolType: "Preplacement",
          modSpec: modSpec,
        }
      );
      this.setState({
        preplacementProtocols: res,
        hasCheckedProtocols: true,
        isCheckingProtocols: false,
      });
    } catch (err) {
      this.setState({
        isCheckingProtocols: false,
        hasCheckedProtocols: false,
        preplacementProtocols: [],
      });
      console.log(err);
      cogoToast.error("Error Checking Against Preplacement Protocols");
    }
  };
  doesModifiedEmployeeSatisfyAnyExitProtocols = async (modSpec) => {
    try {
      this.setState({
        isCheckingExitProtocols: true,
        hasCheckedExitProtocols: false,
        exitProtocols: [],
      });
      let res = await this.props.apiCallPost(
        "/employee/doesModifiedEmployeeSatisfyAnyExitProtocols",
        {
          employeeId: this.props.id,
          modSpec: modSpec,
        }
      );

      this.setState({
        exitProtocols: res,
        hasCheckedExitProtocols: true,
        isCheckingExitProtocols: false,
      });
    } catch (err) {
      this.setState({
        isCheckingProtocols: false,
        hasCheckedExitProtocols: false,
        isCheckingExitProtocols: [],
      });
      console.log(err);
      cogoToast.error("Error Checking Against Exit Protocols");
    }
  };
  doesHypotheticalEmployeeSatisfyAnyProtocols = async (modSpec) => {
    try {
      this.setState({
        isCheckingProtocols: true,
        hasCheckedProtocols: false,
        preplacementProtocols: [],
      });
      let res = await this.props.apiCallPost(
        "/employee/doesHypotheticalEmployeeSatisfyAnyProtocols",
        {
          companyId: this.props.companyId,
          employeeSpec: {
            ...this.state,
          },
          companyProtocolType: "Preplacement",
          modSpec: modSpec,
        }
      );
      this.setState({
        preplacementProtocols: res,
        hasCheckedProtocols: true,
        isCheckingProtocols: false,
      });
    } catch (err) {
      this.setState({
        isCheckingProtocols: false,
        hasCheckedProtocols: false,
        preplacementProtocols: [],
      });
      console.log(err);
      cogoToast.error("Error Checking Against Preplacement Protocols");
    }
  };
  createNewCandidateAndScreenRequest = async () => {
    let keys = [
      "employeeName",
      "employeeSex",
      "employeeDoB",
      "isCandidate",
      "applicantId",
      "employeeNumber",
      "employeeOfficeEmail",
      "employeePersonalEmail",
      "employeeOfficePhone",
      "employeePersonalPhone",
      "isPartOfGroupHiring",
      "isExecutiveHire",
      "employeeCity",
      "employeeCountry",
      "employeeCountryCallingCode",
      "employeeCountryShortCode",
      "employeeState",
      "employeeAddress",
      "employeeGeoLat",
      "employeeGeoLong",
      "employeePostalCode",
      "employeeAddressQualifier",
      "employeeHomeCountry",
      "employeeHomeCountryCallingCode",
      "employeeHomeCountryShortCode",
    ];
    let uops = [];
    for (var i = 0; i < keys.length; i++) {
      let k = keys[i];
      let v = this.state[k];
      if (v === null || v === undefined || v === "") {
        continue;
      } else {
        uops.push({
          key: k,
          value: v,
          typeOfValue: "ROOT",
        });
      }
    }
    for (var i = 0; i < this.state.listOfFields.length; i++) {
      let each = this.state.listOfFields[i];
      let hasChanged = each.hasChanged;
      let from = each.from;
      if (hasChanged) {
        if (from === "CSF") {
          let esk = each.data.employeeSourceKey;
          uops.push({
            key: esk,
            value: this.state.newValues[esk],
            typeOfValue: "CSF",
            csfId: each.data._id,
          });
        }
        if (from === "ESF") {
          let esfObject = this.state.newValues.esfObject;
          let edfn = each.data.fieldName;
          let currentObject = esfObject[edfn];
          for (var k in currentObject) {
            let v = currentObject[k];
            if (v === "") {
              continue;
            }
            if (v === undefined) {
              continue;
            }
            if (v === null) {
              continue;
            }
            uops.push({
              key: k,
              value: v,
              typeOfValue: "ESF",
              csfId: each.data._id,
            });
          }
        }
      }
    }
    if (!uops.length) {
      cogoToast.error("No Changes Made");
      return;
    }
    let protocols = this.state.preplacementProtocols;
    if (!protocols.length) {
      cogoToast.error("No Protocols Checked");
      return;
    }
    try {
      this.setState({
        isCreatingNewCandidate: true,
      });
      let res = await this.props.apiCallPost(
        "/ext-admin/createNewCandidateAndScreenRequest",
        {
          companyId: this.props.companyId,
          employeeFields: uops,
          taskMetadata: {
            protocols: protocols,
          },
        }
      );
      this.setState({
        taskId: res._id,
        hasSetTask: true,
        isCreatingNewCandidate: false,
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Creating New Candidate");
      this.setState({
        isCreatingNewCandidate: false,
      });
    }
  };
  renderToggleButtonGroup = () => {
    return (
      <ToggleButtonGroup
        value={this.state.showPropertyTypes}
        onChange={(_, fmts) => {
          this.setState({
            showPropertyTypes: fmts,
          });
        }}
        color="primary"
      >
        <ToggleButton value="ROOT" variant="contained">
          Core Properties
        </ToggleButton>{" "}
        <ToggleButton value="CSF" variant="contained">
          Look Up Fields
        </ToggleButton>{" "}
        <ToggleButton value="ESF" variant="contained">
          Employee Specific Fields
        </ToggleButton>
      </ToggleButtonGroup>
    );
  };
  renderCheckProtocols = () => {
    if (this.state.hasSetTask) {
      return null;
    }
    let modSpec = this.massageChanges();
    let isEmptyModSpec = _.isEmpty(modSpec);
    return (
      <LoadingButton
        disabled={isEmptyModSpec}
        variant="contained"
        loading={
          this.state.isCheckingProtocols || this.state.isCheckingExitProtocols
        }
        onClick={() => {
          this.doesHypotheticalEmployeeSatisfyAnyProtocols(modSpec);
        }}
      >
        Check Against Protocols
      </LoadingButton>
    );
  };
  renderButtonToSetTask = () => {
    if (!this.state.hasCheckedProtocols) {
      return null;
    }
    let exitProtocols = this.state.exitProtocols;
    let preplacementProtocols = this.state.preplacementProtocols;
    let exitProtocolsLength = exitProtocols.length;
    let preplacementProtocolsLength = preplacementProtocols.length;
    let totalProtocols = exitProtocolsLength + preplacementProtocolsLength;
    if (totalProtocols === 0) {
      return (
        <Button variant="contained" color="success">
          No Tests Required. Transfer Employee Directly.
        </Button>
      );
    } else {
      // check if all protocols are ignorable.
      let allProtocolsAreIgnorable = true;
      let ignoreableCount = 0;
      let dataRecdCount = 0;
      for (var i = 0; i < exitProtocols.length; i++) {
        if (exitProtocols[i].isIgnoreable === true) {
          ignoreableCount++;
          dataRecdCount++;
        } else if (exitProtocols[i].isIgnoreable === false) {
          dataRecdCount++;
        }
      }
      for (var i = 0; i < preplacementProtocols.length; i++) {
        if (preplacementProtocols[i].isIgnoreable === true) {
          ignoreableCount++;
          dataRecdCount++;
        } else if (preplacementProtocols[i].isIgnoreable === false) {
          dataRecdCount++;
        }
      }
      if (dataRecdCount < totalProtocols) {
        return (
          <LoadingButton
            variant="contained"
            color="info"
            loading
            loadingPosition="start"
          >
            Checking Service Conditions Under Protocol
          </LoadingButton>
        );
      }
      let totalApplicable = totalProtocols - ignoreableCount;
      if (totalApplicable > 0) {
        allProtocolsAreIgnorable = false;
      }
      if (allProtocolsAreIgnorable) {
        if (this.state.hasSetTask) {
          return (
            <Stack spacing={2}>
              <Chip label="No Test Required." color="success" />
              <Button variant="contained" onClick={() => this.props.onReset()}>
                Start Over For Another Employee.
              </Button>
            </Stack>
          );
        }
        return (
          <Button variant="contained" color="success">
            No Tests Required. Transfer Employee Directly.
          </Button>
        );
      }

      // subtract ignoreable from total.

      let txt = totalApplicable === 1 ? "Protocol" : "Protocols";
      if (this.state.hasSetTask) {
        let id = this.state.taskId;
        return (
          <Stack spacing={2}>
            <Chip label="Visit Scheduling Request Received." color="success" />
            <Chip
              onClick={() => {
                navigator.clipboard.writeText(id);
                cogoToast.info("Request ID Copied to Clipboard");
              }}
              label={`Request ID: ${id} `}
              color="info"
              icon={<Iconify icon="solar:copy-broken" />}
            />
            <Button variant="contained" onClick={() => this.props.onReset()}>
              Start Over
            </Button>
          </Stack>
        );
      }
      return (
        <LoadingButton
          variant="contained"
          color="secondary"
          loading={this.state.isCreatingNewCandidate}
          onClick={() => {
            this.createNewCandidateAndScreenRequest();
          }}
        >
          {totalApplicable} {txt} Applicable. Set Task.
        </LoadingButton>
      );
    }
  };
  renderHeader = () => {
    return (
      <Stack spacing={3}>
        <ListItemText
          sx={{
            p: 3,
            borderRadius: 2,
            bgcolor: "background.neutral",
          }}
          primary={
            <Stack direction="row" alignItems="center" spacing={3}>
              <Typography variant="h6">Transfer An Employee</Typography>
              {this.renderToggleButtonGroup()}
            </Stack>
          }
          secondary="The left column has the current information of the employee. Make proposed changes in the right column. After making changes, click the button to check against protocols."
          primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
          secondaryTypographyProps={{ component: "span" }}
        />
      </Stack>
    );
  };
  EMPLOYEE_FIELDS = [
    {
      subheader: "Basic Details",
      caption: "Enter Basic Personal Information",
      items: [
        {
          id: "employeeName",
          label: "Full Name",
          type: "TEXT",
        },
        {
          id: "employeeSex",
          label: "Sex",
          type: "OPTIONS",
          options: ["Male", "Female", "Other"],
        },
        {
          id: "employeeDoB",
          label: "Date of Birth",
          type: "DATE",
        },
        {
          id: "isCandidate",
          label: "Is This A New Candidate?",
          type: "SWITCH",
        },
        {
          id: "applicantId",
          label: "Applicant ID",
          type: "TEXT",
        },
        {
          id: "employeeNumber",
          label: "Employee Number",
          type: "TEXT",
        },
        {
          id: "employeeOfficeEmail",
          label: "Employee Email",
          type: "TEXT",
        },
        {
          id: "employeePersonalEmail",
          label: "Personal Email",
          type: "TEXT",
        },
        {
          id: "employeeOfficePhone",
          label: "Employee Phone",
          type: "PHONE",
        },
        {
          id: "employeePersonalPhone",
          label: "Personal Phone",
          type: "PHONE",
        },
        {
          id: "isPartOfGroupHiring",
          label: "Part of Group Hiring?",
          type: "SWITCH",
        },
        {
          id: "isExecutiveHire",
          label: "Executive Hire?",
          type: "SWITCH",
        },
        {
          id: "country",
          label: "Current Country",
          type: "COUNTRY",
        },
        {
          id: "homeCountry",
          label: "Home Country",
          type: "HOME_COUNTRY",
        },
        {
          id: "address",
          label: "Address",
          type: "G_MAP",
        },
      ],
    },
  ];
  setPlace = (place) => {
    this.setState({
      employeeCity: place.city,
      employeeCountry: place.country,
      employeeCountryCallingCode: place.country === "United States" ? "1" : "",
      employeeCountryShortCode: place.country === "United States" ? "US" : "",
      employeeState: place.state,
      employeeAddress: place.formatted_address,
      employeeGeoLat: place.geolocation.lat,
      employeeGeoLong: place.geolocation.lng,
      employeePostalCode: place.postal_code,
    });
  };
  renderControl = (type, item) => {
    switch (type) {
      case "G_MAP":
        return (
          <Stack spacing={2}>
            <GMap
              renderMapFalse={true}
              onPlace={(r) => this.setPlace(r)}
              geoObject={{
                lat: this.state.employeeGeoLat,
                lng: this.state.employeeGeoLong,
              }}
              onLatLng={(geo) => {
                this.setState({
                  employeeGeoLat: geo.lat,
                  employeeGeoLong: geo.lng,
                });
              }}
            />{" "}
            <TextField
              InputLabelProps={{
                shrink: this.state.employeeAddress,
              }}
              label="Address Line 1"
              multiline
              value={this.state.employeeAddress}
              onChange={(e) => {
                this.setState({
                  employeeAddress: e.target.value,
                });
              }}
            />
            <TextField
              InputLabelProps={{
                shrink: this.state.employeeAddressQualilfier,
              }}
              label="Address Line 2"
              multiline
              value={this.state.employeeAddressQualilfier}
              onChange={(e) => {
                this.setState({
                  employeeAddressQualilfier: e.target.value,
                });
              }}
            />
            <TextField
              InputLabelProps={{
                shrink: this.state.employeeCity,
              }}
              label="City"
              value={this.state.employeeCity}
              onChange={(e) => {
                this.setState({ employeeCity: e.target.value });
              }}
            />
            <TextField
              InputLabelProps={{
                shrink: this.state.employeeState,
              }}
              label="State"
              value={this.state.employeeState}
              onChange={(e) => {
                this.setState({ employeeState: e.target.value });
              }}
            />
            <TextField
              InputLabelProps={{
                shrink: this.state.employeePostalCode,
              }}
              label="Postal Code"
              value={this.state.employeePostalCode}
              onChange={(e) => {
                this.setState({ employeePostalCode: e.target.value });
              }}
            />
          </Stack>
        );
      case "HOME_COUNTRY":
        return (
          <CountrySelector
            sx={{
              minWidth: "400px",
            }}
            value={{
              label: this.state.employeeHomeCountry || "",
              code: this.state.employeeHomeCountryShortCode || "",
              phone: this.state.employeeHomeCountryCallingCode || "",
            }}
            onChange={(e) => {
              this.setState({
                homeCountry: e,
                employeeHomeCountry: e.label,
                employeeHomeCountryShortCode: e.code,
                employeeHomeCountryCallingCode: e.phone,
              });
            }}
          />
        );
      case "COUNTRY":
        return (
          <CountrySelector
            value={{
              label: this.state.employeeCountry || "",
              code: this.state.employeeCountryShortCode || "",
              phone: this.state.employeeCountryCallingCode || "",
            }}
            onChange={(e) => {
              this.setState({
                country: e,
                employeeCountry: e.label,
                employeeCountryShortCode: e.code,
                employeeCountryCallingCode: e.phone,
              });
            }}
          />
        );
      case "PHONE":
        return (
          <PhoneInput
            defaultCountry="US"
            placeholder={"Enter phone number"}
            value={this.state[item.id]}
            onChange={(e) => {
              this.setState({ [item.id]: e });
            }}
          />
        );
      case "SWITCH":
        return (
          <Switch
            checked={this.state[item.id]}
            onChange={() => {
              this.setState({
                [item.id]: !this.state[item.id],
              });
            }}
          />
        );
      case "OPTIONS":
        return (
          <Select
            sx={{ minWidth: 400 }}
            value={this.state[item.id]}
            onChange={(e) => {
              this.setState({
                [item.id]: e.target.value,
              });
            }}
          >
            {item.options.map((each) => (
              <MenuItem value={each}>{each}</MenuItem>
            ))}
          </Select>
        );
      case "TEXT":
        return (
          <TextField
            label={item.label}
            sx={{ minWidth: 400 }}
            value={this.state[item.id]}
            onChange={(e) => {
              this.setState({
                [item.id]: e.target.value,
              });
            }}
          />
        );
      case "DATE":
        return (
          <>
            <Chip
              variant="contained"
              onClick={(e) => {
                this.setState({
                  anchorEl: e.currentTarget,
                  [`open_${item.label}`]: true,
                });
              }}
              label={
                this.state[item.id]
                  ? niceDate(this.state[item.id])
                  : "Not Entered"
              }
            />
            <Popover
              open={this.state[`open_${item.label}`]}
              anchorEl={this.state.anchorEl}
              onClose={() => {
                this.setState({
                  anchorEl: null,
                  [`open_${item.label}`]: false,
                });
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <DayPicker
                mode="single"
                captionLayout="dropdown-buttons"
                fromYear={1901}
                toYear={2024}
                onSelect={(e) => {
                  this.setState({
                    [item.id]: e,
                    anchorEl: null,
                    [`open_${item.label}`]: false,
                  });
                  //   this.updateScalarValue(item.id, e);
                }}
              />
            </Popover>
          </>
        );
    }
  };
  renderEmployeeForm = () => {
    return (
      <Stack component={Card} spacing={3} sx={{ p: 3 }}>
        {this.EMPLOYEE_FIELDS.map((setting, index) => {
          return (
            <Grid key={setting.subheader} container spacing={3}>
              <Grid xs={12} md={4}>
                <ListItemText
                  primary={setting.subheader}
                  secondary={setting.caption}
                  primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
                  secondaryTypographyProps={{ component: "span" }}
                />
              </Grid>
              <Grid xs={12} md={8}>
                <Stack
                  spacing={1}
                  sx={{
                    p: 3,
                    borderRadius: 2,
                    bgcolor: "background.neutral",
                  }}
                >
                  <>
                    {setting.items.map((item, subIndex) => {
                      if (
                        this.state.isCandidate === null ||
                        this.state.isCandidate === undefined
                      ) {
                        if (item.id === "employeeNumber") {
                          return null;
                        }

                        if (item.id === "applicantId") {
                          return null;
                        }

                        if (item.id === "employeeOfficeEmail") {
                          return null;
                        }
                        if (item.id === "employeePersonalEmail") {
                          return null;
                        }
                        if (item.id === "employeePersonalPhone") {
                          return null;
                        }
                        if (item.id === "employeeOfficePhone") {
                          return null;
                        }
                        if (item.id === "isPartOfGroupHiring") {
                          return null;
                        }
                        if (item.id === "isExecutiveHire") {
                          return null;
                        }
                      }
                      if (this.state.isCandidate) {
                        if (item.id === "employeeNumber") {
                          return null;
                        }
                        if (item.id === "employeeOfficeEmail") {
                          return null;
                        }
                        if (item.id === "employeeOfficePhone") {
                          return null;
                        }
                      } else {
                        if (item.id === "applicantId") {
                          return null;
                        }
                        if (item.id === "employeePersonalEmail") {
                          return null;
                        }
                        if (item.id === "employeePersonalPhone") {
                          return null;
                        }
                        if (item.id === "isPartOfGroupHiring") {
                          return null;
                        }
                        if (item.id === "isExecutiveHire") {
                          return null;
                        }
                      }

                      return (
                        <FormControlLabel
                          key={item.id}
                          label={item.label}
                          labelPlacement="start"
                          control={this.renderControl(item.type, item)}
                          sx={{
                            m: 0,
                            width: 1,
                            justifyContent: "space-between",
                          }}
                        />
                      );
                    })}
                  </>
                </Stack>
              </Grid>
            </Grid>
          );
        })}
      </Stack>
    );
  };
  render() {
    if (this.state.isError) {
      return <View500 />;
    }
    if (this.state.isLoading) {
      return <LinearProgress />;
    }

    return (
      <Stack spacing={2}>
        {this.renderEmployeeForm()}
        {this.renderTable()}
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
          }}
        >
          {this.renderCheckProtocols()}
          {this.renderButtonToSetTask()}
        </Box>
      </Stack>
    );
  }
}

export default WithAPICall(EmployeeTransfer);
