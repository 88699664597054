import { uniq } from "lodash";

const moment = require("moment-timezone");

export function offsetToTimezone(offset) {
  // Get timezone identifier from Moment Timezone
  // const timezone = moment.tz.zone(offsetMilliseconds);
  const zones = moment.tz.zonesForCountry("US", true);
  const zone = zones.find((z) => z.offset === Math.abs(offset));
  return moment.tz(zone.name).format("z");
}

export function convertUTCtoTimeZone(utcDateTime, targetTimeZoneAbbr) {
  const timeZoneMappings = getZoneMappings();

  const targetTimeZone = timeZoneMappings[targetTimeZoneAbbr];
  if (!targetTimeZone) {
    return "Invalid timezone abbreviation";
  }

  const utcDate = new Date(utcDateTime);
  const formatter = new Intl.DateTimeFormat("en-US", {
    timeZone: targetTimeZone,
    hour12: false,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return formatter.format(utcDate);
}

function getZoneMappings() {
  const timeZoneMappings = {};

  // Iterate over all timezone data
  for (const zoneName of moment.tz.zonesForCountry("US")) {
    const zoneAbbrs = uniq(moment.tz.zone(zoneName).abbrs);

    // Iterate over abbreviations for each timezone
    zoneAbbrs.forEach((abbr) => {
      // Map abbreviation to timezone identifier
      timeZoneMappings[abbr] = zoneName;
    });
  }

  return timeZoneMappings;
}

export const US_TIMEZONES = [
  "HDT",
  "AKDT",
  "MDT",
  "CDT",
  "EDT",
  "PDT",
  "MST",
  "HST",
];
