import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Autocomplete,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import cogoToast from "cogo-toast";
import _, { cloneDeep, debounce } from "lodash";
import moment from "moment";
import React from "react";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import Iconify from "src/components/iconify";
import { WithAPICall } from "../../utils/apiUtil";
import { niceDateTime } from "../../utils/fn";
import VisitElementEmployee from "../VisitElementEmployee";
import TokenContext from "src/TokenContext";
import Walktour from "src/components/walktour/walktour";
import { ACTIONS, EVENTS, STATUS } from "react-joyride";

class VisitListER extends React.Component {
  static contextType = TokenContext;
  state = {
    isLoading: true,
    // cu
    currentUser: {
      isEmployee: true,
      isExternal: true,
    },
    // company
    isSearchingCompany: false,
    companySearchResults: [],
    currentlyInCompany: null,
    // other filters
    filterStatus: [],
    filterEmployee: "",
    filterDateRange: [null, null],
    filterMasterServiceIds: [],
    filterProtocolIds: [],
    // search results
    isSearchingVisits: false,
    hasSearched: false,
    visits: [],
    options: [],
    loadingOptions: false,
    protocolOptions: [],
    loadingCompanyProtocols: false,
    //for joyride
    steps: [
      {
        target: "#STEP_0",
        title: "Requested Visits",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            This page shows all visits that have been requested to be scheduled
            by WorkCare. These will be scheduled in due course. Once they have
            been scheduled, they will appear under the "All Visits / Clearances"
            tab.
          </Typography>
        ),
      },
      {
        target: "#STEP_1",
        title: "Visit Status",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            You can use this dropdown to filter visits by their current status
          </Typography>
        ),
      },
      {
        target: "#STEP_2",
        title: "Search Employees",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            You can search for employees by name.
          </Typography>
        ),
      },
      {
        target: "#STEP_3",
        title: "Search Service",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            You can use this dropdown to filter for visits containing certain
            tests or exams.
          </Typography>
        ),
      },
      {
        target: "#STEP_4",
        title: "Date Range",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            You can use this date range picker to filter visits by their
            scheduled dates.
          </Typography>
        ),
      },
      {
        target: "#STEP_5",
        title: "Create New Visit",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            You can create a new visit by clicking here.
          </Typography>
        ),
      },
      {
        target: "#STEP_6",
        title: "Request New Visit",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            You can request WorkCare to schedule a new visit by clicking here.
          </Typography>
        ),
      },
      {
        target: "#STEP_7",
        title: "All Visits",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            This table shows all the visits matching the criteria selected
            above.
          </Typography>
        ),
      },

      {
        target: "#STEP_8",
        title: "Employee Column",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            This column shows the employee name.
          </Typography>
        ),
      },
      {
        target: "#STEP_9",
        title: "Protocol(s) Column",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            This column shows the selected protocol(s) for the visit.
          </Typography>
        ),
      },
      {
        target: "#STEP_10",
        title: "Clinic(s) Column",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            This column shows the clinic(s) where the visit was scheduled to
            happen.
          </Typography>
        ),
      },
      {
        target: "#STEP_11",
        title: "Status Column",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            This column shows the current status of the visit.
          </Typography>
        ),
      },
      {
        target: "#STEP_12",
        title: "Clearances(s) Column",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            You can view and download the clearances(s) issued against the visit
            here.
          </Typography>
        ),
      },

      {
        target: "#STEP_13",
        title: "Revisit This Walkthrough",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            That concludes the quick walkthrough. You can view this anytime by
            clicking here.
          </Typography>
        ),
      },
    ],
    runJoyRide: false,
    stepIndex: 0,
  };

  componentDidMount() {
    this.getCurrentUser();
    this.debouncedFetchOptions = debounce(this.fetchOptions, 500);
  }
  handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ runJoyRide: false, stepIndex: 0 });
    }
  };
  fetchOptions = async (inputValue) => {
    this.setState({
      loadingOptions: true,
    });
    if (inputValue) {
      try {
        const res = await this.props.apiCallPost(
          "/master-service/masterServiceFts",
          {
            limit: 1000,
            query: inputValue,
            skip: 0,
          }
        );
        this.setState({ options: res });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      this.setState({ options: [] });
    }
    this.setState({
      loadingOptions: true,
    });
  };
  getCurrentUser = async () => {
    try {
      const res = await this.props.apiCallPost("/user/getCurrentUser", {});
      let cu = res;
      let isEmployee = cu.isEmployee;
      let isExternal = cu.isExternal;
      this.setState({
        currentUser: cu,
      });
      if (isEmployee) {
        this.setState(
          {
            currentUser: cu,
            currentlyInCompany: {
              mainText: res.company.companyName,
              secondaryText: `${res.company.companyInformalName} ${res.companyWebsite}`,
              data: res.company,
            },
            isLoading: false,
          },
          () => {
            this.getVisitsByEmployeeId();
          }
        );
      } else if (isExternal) {
        this.setState(
          {
            currentUser: cu,
            currentlyInCompany: {
              mainText: res.company.companyName,
              secondaryText: `${res.company.companyInformalName} ${res.companyWebsite}`,
              data: res.company,
            },
            filterDateRange: [
              moment().subtract(7, "days"),
              moment().add(7, "days"),
            ],
            isLoading: false,
          },
          () => {
            this.searchVisits();
          }
        );
      } else if (this.props.fromAssociationPage && this.props.extractedEEName) {
        this.setState(
          {
            isLoading: false,
            filterEmployee: this.props.extractedEEName,
            filterDateRange: [null, null],
          },
          () => {
            this.searchVisits();
          }
        );
      } else {
        this.setState(
          {
            isLoading: false,
            filterDateRange: [
              moment().subtract(7, "days"),
              moment().add(7, "days"),
            ],
          },
          () => {
            this.searchVisits();
          }
        );
      }
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading User Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  STATUS_OPTIONS = [
    "REQUESTED",
    "SCHEDULING_IN_PROGRESS",
    "SCHEDULED",
    "AWAITING_DOCUMENTS",
    "DOCUMENTS_RECEIVED",
    "DOCUMENTS_PROCESSED",
    "DOCUMENTS_IN_REVIEW",
    "DOCUMENTS_REVIEWED",
    "COMPLETED",
    "CANCELLED",
    "NO_SHOW",
    "RESCHEDULING_REQUESTED",
  ];
  searchCompaniesFTS = async (t) => {
    try {
      let res = await this.props.apiCallPostCancellable("/company/search", {
        query: t,
      });
      if (res) {
        this.setState({
          isSearchingCompany: false,
          companySearchResults: res.map((each) => {
            return {
              mainText: each.companyName,
              secondaryText: `${each.companyInformalName} ${each.companyWebsite}`,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingCompany: false,
        companySearchResults: [],
      });
      cogoToast.error("Error Searching Companies");
    }
  };
  searchVisits = async () => {
    let payload = {
      visitOutcome: [],
      visitStatus: this.state.filterStatus,
      companyId: this.state.currentlyInCompany?.data?._id || null,
      employeeName: this.state.filterEmployee || null,
      masterServiceIds: this.state.filterMasterServiceIds.map((s) => s._id),
      protocolIds: this.state.filterProtocolIds.map((s) => s?._id),
    };
    // if date range has both filled, convert to date from moment
    if (this.state.filterDateRange.filter(Boolean).length === 2) {
      payload.scheduledIn = this.state.filterDateRange.map((each) =>
        each.toDate()
      );
    } else {
      payload.scheduledIn = [];
    }

    try {
      this.setState({
        isSearchingVisits: true,
      });
      let res = await this.props.apiCallPost("/visit/searchVisits", payload);

      if (window.location.href.includes("requested")) {
        res = res.filter((each) => {
          return each.requestedByEmployer === true;
        });
      } else {
        this.setState({ steps: this.state.steps.slice(1) });
      }

      this.setState({
        isSearchingVisits: false,
        hasSearched: true,
        visits: res,
      });
    } catch (err) {
      this.setState({
        isSearchingVisits: false,
      });
      cogoToast.error("Error Searching Visits");
    }
  };
  getVisitsByEmployeeId = async () => {
    try {
      this.setState({
        isSearchingVisits: true,
      });
      let res = await this.props.apiCallPost(
        "/ext-employee/getVisitsByEmployeeId",
        {
          employeeId: this.state.currentUser.employee,
        }
      );
      this.setState({
        isSearchingVisits: false,
        hasSearched: true,
        visits: res,
      });
    } catch (err) {
      this.setState({
        isSearchingVisits: false,
      });
      cogoToast.error("Error Loading Visits");
    }
  };
  loadCompanyProtocols = async () => {
    cogoToast.loading("Loading company protocols.");
    try {
      const res = await this.props.apiCallPost(
        "/company-protocol/companyProtocolGetAll",
        {
          companyId: this.state.currentlyInCompany.data._id,
        }
      );
      this.setState({
        protocolOptions: res,
      });
      cogoToast.success("Company protocols loaded.");
    } catch (error) {
      console.error("could not get company protocols", error);
    }
  };
  renderCompanySearcher = () => {
    if (
      this.state.currentUser.isExternal ||
      this.state.currentUser.isEmployee
    ) {
      return null;
    }
    return (
      <Autocomplete
        sx={{
          minWidth: "200px",
        }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option?.mainText
        }
        filterOptions={(x) => x}
        options={
          this.state.isSearchingCompany ? [] : this.state.companySearchResults
        }
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={this.state.currentlyInCompany}
        noOptionsText={
          this.state.isSearchingCompany ? "Searching..." : "No results found"
        }
        onChange={(_, value) => {
          this.setState(
            {
              currentlyInCompany: value,
              companySearchResults: [value, ...this.state.companySearchResults],
              protocolOptions: [],
              filterMasterServiceIds: [],
              filterProtocolIds: [],
            },
            () => {
              if (value) {
                this.loadCompanyProtocols();
              }
            }
          );
        }}
        onInputChange={(_, e) => {
          if (e === "") return null;
          if (!e) return null;
          if (e.length < 4) return null;
          this.setState(
            {
              isSearchingCompany: true,
            },
            () => {
              this.searchCompaniesFTS(e);
            }
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label="Search Employers" fullWidth />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ wordWrap: "break-word" }}>
                  <Box component="span">{option?.mainText}</Box>
                  <Typography variant="body2" color="text.secondary">
                    {option?.secondaryText}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    );
  };
  renderStatusSelection = () => {
    return (
      <FormControl id="STEP_1">
        <InputLabel id="label">Select Visit Status</InputLabel>
        <Select
          labelId="label"
          placeholder="Status"
          multiple
          value={this.state.filterStatus}
          onChange={(e) => {
            let v = e.target.value;
            this.setState({
              filterStatus: typeof v === "string" ? v.split(",") : v,
            });
          }}
          sx={{
            minWidth: "200px",
          }}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip size="small" key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {this.STATUS_OPTIONS.map((item) => (
            <MenuItem key={item} value={item} size="small">
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  renderEmployeeSearch = () => {
    return (
      <TextField
        id="STEP_2"
        sx={{
          minWidth: "200px",
        }}
        InputLabelProps={{
          shrink: this.state.filterEmployee,
        }}
        label="Search Employees"
        onChange={(e) => {
          let v = e.target.value;
          this.setState({
            filterEmployee: v,
          });
        }}
        value={this.state.filterEmployee}
      />
    );
  };
  renderDateRangeSearch = () => {
    const shortcutsItems = [
      {
        label: "Today",
        getValue: () => {
          const today = moment();
          return [today, today];
        },
      },
      {
        label: "Last 3 Days",
        getValue: () => {
          const today = moment();
          return [today.clone().subtract(3, "days"), today];
        },
      },
      {
        label: "Next 3 Days",
        getValue: () => {
          const today = moment();
          return [today, today.clone().add(3, "days")];
        },
      },
      {
        label: "Last 7 Days",
        getValue: () => {
          const today = moment();
          return [today.clone().subtract(7, "days"), today];
        },
      },
      {
        label: "Next 7 Days",
        getValue: () => {
          const today = moment();
          return [today, today.clone().add(7, "days")];
        },
      },
      {
        label: "Last 30 Days",
        getValue: () => {
          const today = moment();
          return [today.clone().subtract(30, "days"), today];
        },
      },
      {
        label: "Next 30 Days",
        getValue: () => {
          const today = moment();
          return [today, today.clone().add(30, "days")];
        },
      },
      {
        label: "This Month",
        getValue: () => {
          const today = moment();
          return [today.clone().startOf("month"), today.clone().endOf("month")];
        },
      },
      {
        label: "Next Month",
        getValue: () => {
          const today = moment();
          return [
            today.clone().add(1, "month").startOf("month"),
            today.clone().add(1, "month").endOf("month"),
          ];
        },
      },
      {
        label: "Last Month",
        getValue: () => {
          const today = moment();
          return [
            today.clone().subtract(1, "month").startOf("month"),
            today.clone().subtract(1, "month").endOf("month"),
          ];
        },
      },
      {
        label: "This Week",
        getValue: () => {
          const today = moment();
          return [today.clone().startOf("week"), today.clone().endOf("week")];
        },
      },
      {
        label: "Next Week",
        getValue: () => {
          const today = moment();
          return [
            today.clone().add(1, "week").startOf("week"),
            today.clone().add(1, "week").endOf("week"),
          ];
        },
      },
      {
        label: "Last Week",
        getValue: () => {
          const today = moment();
          return [
            today.clone().subtract(1, "week").startOf("week"),
            today.clone().subtract(1, "week").endOf("week"),
          ];
        },
      },
      {
        label: "Reset",
        getValue: () => [null, null],
      },
    ];

    return (
      <div id="STEP_4">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateRangePicker
            slotProps={{
              shortcuts: {
                changeImportance: "accept",
                dense: true,
                subheader: (
                  <Typography variant="caption" sx={{ p: 2 }}>
                    Shortcuts
                  </Typography>
                ),
                items: shortcutsItems,
              },
              actionBar: { actions: [] },
            }}
            value={this.state.filterDateRange}
            size="small"
            localeText={{
              start: "From",
              end: "To",
            }}
            onChange={(newValue) => {
              this.setState({
                filterDateRange: newValue,
              });
            }}
          />
        </LocalizationProvider>
      </div>
    );
  };
  renderSearchButton = () => {
    return (
      <LoadingButton
        loading={this.state.isSearchingVisits}
        startIcon={<Iconify icon="lets-icons:search-alt-light" />}
        onClick={this.searchVisits}
        variant="contained"
        sx={{
          height: "100%",
          minWidth: "150px",
        }}
      >
        Search
      </LoadingButton>
    );
  };
  handleInputChange = (event, newInputValue) => {
    this.setState({ inputValue: newInputValue }, () => {
      this.debouncedFetchOptions(newInputValue);
    });
  };
  handleSetServiceFilter = (serviceDetails) => {
    this.setState((prevState) => {
      const serviceFilters = cloneDeep(this.state.filterMasterServiceIds);
      serviceFilters.push(serviceDetails);
      return { ...prevState, filterMasterServiceIds: serviceFilters };
    });
  };
  handleSetProtocolFilter = (data) => {
    this.setState((prevState) => {
      const protocolDetails = cloneDeep(this.state.filterProtocolIds);
      protocolDetails.push(data);
      return { ...prevState, filterProtocolIds: protocolDetails };
    });
  };
  renderFilterBox = () => {
    if (this.state.currentUser.isEmployee) return null;
    return (
      <>
        <Stack direction="row" spacing={1} alignItems={"center"}>
          {this.renderCompanySearcher()}
          {this.renderStatusSelection()}
          {this.renderEmployeeSearch()}
          <div id="STEP_3">
            <Autocomplete
              sx={{
                minWidth: "200px",
              }}
              options={this.state.options}
              getOptionLabel={(option) => option.serviceName}
              onInputChange={this.handleInputChange}
              onChange={(_, value) => {
                console.log({ value });
                if (value) {
                  this.handleSetServiceFilter(value);
                } else {
                  this.setState({
                    filterMasterServiceIds: [],
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  onClick={() => {
                    console.log({ params });
                  }}
                  label="Search for a Service"
                  {...params}
                  variant="outlined"
                />
              )}
            />
          </div>
          {!this.context.currentUser.isExternal && (
            <Autocomplete
              sx={{
                minWidth: "200px",
              }}
              options={this.state.protocolOptions}
              getOptionLabel={(option) => option.companyProtocolName}
              // onInputChange={this.handleInputChange}
              onChange={(_, value) => {
                console.log({ value });
                if (value) {
                  this.handleSetProtocolFilter(value);
                } else {
                  this.setState({ filterProtocolIds: [] });
                }
              }}
              disabled={!this.state.currentlyInCompany}
              renderInput={(params) => (
                <TextField
                  onClick={() => {
                    console.log({ params });
                  }}
                  label="Search for a Protocol"
                  {...params}
                  variant="outlined"
                />
              )}
            />
          )}
        </Stack>
        <Stack direction="row" spacing={1} alignItems={"center"}>
          {this.renderDateRangeSearch()}
          {this.renderSearchButton()}
        </Stack>
      </>
    );
  };
  renderSearchResults = () => {
    if (!this.state.hasSearched) return null;
    if (this.state.isSearchingVisits) return <LinearProgress />;
    if (!this.state.visits || this.state.visits.length === 0) {
      return (
        <Alert severity="info">
          No Visits Found. Try modifying search criteria.
        </Alert>
      );
    }
    if (
      this.state.currentUser.isEmployee &&
      !this.state.currentUser.isExternal
    ) {
      return this.renderVisitListForEmployee();
    }
    return this.renderTable();
  };
  renderVisitListForEmployee = () => {
    let v = this.state.visits;
    if (!v || !v.length) {
      return (
        <Alert severity="info">
          No Visits are recorded for you at this point.
        </Alert>
      );
    }
    return (
      <Stack spacing={2}>
        {v.map((each) => {
          return <VisitElementEmployee data={each} />;
        })}
      </Stack>
    );
  };
  renderTable = () => {
    return (
      <Table size="small" id="STEP_7">
        <TableHead>
          <TableRow>
            <TableCell id="STEP_8">Employee</TableCell>
            <TableCell id="STEP_9">Protocol(s)</TableCell>
            <TableCell id="STEP_10">Clinic(s)</TableCell>
            <TableCell id="STEP_11">Status</TableCell>
            <TableCell id="STEP_12">Clearances(s)</TableCell>
            {/* <TableCell>Outcome</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.visits.map((each) => {
            return (
              <TableRow
                key={each._id}
                sx={{
                  borderBottom: "1px solid #C5CBD3 !important",
                }}
              >
                <TableCell>{this.renderEmployee(each)}</TableCell>{" "}
                <TableCell>{this.renderProtocols(each)}</TableCell>{" "}
                <TableCell>{this.renderClinics(each)}</TableCell>{" "}
                <TableCell>
                  <Stack spacing={1}>
                    {this.renderNiceText(each, "visitStatus")}
                    {/* {each.requestedByEmployer && (
                      <Chip size="small" color="info" label="Requested" />
                    )} */}
                  </Stack>
                </TableCell>
                <TableCell>{this.renderClearances(each)}</TableCell>
                {/* <TableCell>
                  {this.renderNiceText(each, "visitOutcome")}
                </TableCell> */}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };
  renderNiceText = (each, key) => {
    if (!each[key]) return "N/A";
    return _.startCase(_.lowerCase(each[key]));
  };
  renderEmployee = (each) => {
    let elem = (
      <IconButton
        disabled={this.state.isSearchingVisits}
        size="small"
        color="primary"
        href={`/visits/${each._id}`}
        // target="non_blank"
        sx={{
          width: 24,
          height: 24,
          bgcolor: "primary.main",
          color: "primary.contrastText",
          "&:hover": {
            bgcolor: "primary.dark",
          },
        }}
      >
        <Iconify icon="mynaui:external-link" />
      </IconButton>
    );
    if (this.state.currentUser.isExternal) {
      elem = null;
    }
    if (this.props.choosingVisit) {
      elem = (
        <IconButton
          disabled={this.state.isSearchingVisits}
          size="small"
          color="primary"
          onClick={() => {
            this.props.onVisitChosen(each);
          }}
          sx={{
            width: 24,
            height: 24,
            bgcolor: "primary.main",
            color: "primary.contrastText",
            "&:hover": {
              bgcolor: "primary.dark",
            },
          }}
        >
          <Iconify icon="teenyicons:tick-circle-solid" />
        </IconButton>
      );
    }
    return (
      <Stack direction="row" spacing={1} alignItems={"center"}>
        {" "}
        <Typography variant="overline">{each.employee.employeeName}</Typography>
        {elem}
      </Stack>
    );
  };
  renderProtocols = (each) => {
    if (!each.bookedProtocols || !each.bookedProtocols.length) {
      return "Not Chosen";
    }
    if (this.props.fromAssociationPage) {
      return (
        <Stack spacing={1}>
          {each.bookedProtocols.map((x) => {
            let bookedServices = each.bookedServices;
            let bookedServicesInThisProtocol = bookedServices.filter(
              (y) => y.parentProtocolId === x._id
            );
            let bookedServicesInThisProtocolIds =
              bookedServicesInThisProtocol.map((z) => z.serviceId);

            let serviceMetadataInThisProtocol = x.serviceMetadata;
            let masterServideIdsInThisProtocol = x.masterServices;
            let servicesToRender = [];

            for (var i = 0; i < bookedServicesInThisProtocol.length; i++) {
              let bookedServicesInThisProtocolIdx =
                bookedServicesInThisProtocolIds[i];
              let currentIdx = _.findIndex(
                masterServideIdsInThisProtocol,
                (w) => w === bookedServicesInThisProtocolIdx
              );
              if (currentIdx === -1) continue;
              servicesToRender.push({
                ...serviceMetadataInThisProtocol[currentIdx],
                serviceId: bookedServicesInThisProtocolIdx,
                hasReceivedAllData:
                  bookedServicesInThisProtocol[i].hasReceivedAllData,
                linkedGroups: bookedServicesInThisProtocol[i].linkedGroups
                  ? bookedServicesInThisProtocol[i].linkedGroups
                  : [],
              });
            }
            let servicesHeader = (
              <Typography variant="overline">Services</Typography>
            );

            if (!servicesToRender || !servicesToRender.length) {
              servicesHeader = null;
            }
            return (
              <Stack spacing={1}>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Typography variant="overline">
                    {x.companyProtocolName}
                  </Typography>
                  <Typography variant="caption">
                    {x.companyProtocolType}
                  </Typography>
                </Stack>
                <Stack spacing={2}>
                  {servicesHeader}
                  {servicesToRender.map((s) => {
                    let lg = s.linkedGroups;
                    let groupsElem = null;
                    if (lg && lg.length) {
                      groupsElem = (
                        <Stack>
                          <Typography variant="caption">Linked Data</Typography>
                          {lg.map((eachLg) => {
                            let eachLgButton = (
                              <IconButton
                                size="small"
                                color="primary"
                                href={`/documents/${eachLg.cfId}`}
                                // target="non_blank"
                                sx={{
                                  width: 24,
                                  height: 24,
                                  // bgcolor: "primary.error",
                                  // color: "primary.contrastText",
                                  // "&:hover": {
                                  //   bgcolor: "primary.dark",
                                  // },
                                }}
                              >
                                <Iconify icon="akar-icons:link-out" />
                              </IconButton>
                            );
                            return (
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                              >
                                {_.startCase(_.lowerCase(eachLg.pageDataType))}
                                {eachLgButton}
                              </Stack>
                            );
                          })}
                        </Stack>
                      );
                    }
                    let addButton = (
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => {
                          this.props.onAssociation(
                            s.serviceId,
                            each._id,
                            false
                          );
                        }}
                        sx={{
                          width: 24,
                          height: 24,
                          // bgcolor: "primary.error",
                          // color: "primary.contrastText",
                          // "&:hover": {
                          //   bgcolor: "primary.dark",
                          // },
                        }}
                      >
                        <Iconify icon="akar-icons:folder-add" />
                      </IconButton>
                    );
                    let doneButton = (
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => {
                          this.props.onAssociation(s.serviceId, each._id, true);
                        }}
                        sx={{
                          width: 24,
                          height: 24,
                          // bgcolor: "primary.error",
                          // color: "primary.contrastText",
                          // "&:hover": {
                          //   bgcolor: "primary.dark",
                          // },
                        }}
                      >
                        <Iconify icon="akar-icons:circle-check-fill" />
                      </IconButton>
                    );
                    return (
                      <Stack spacing={1}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          {s.serviceName}
                          {addButton}
                          {doneButton}
                        </Stack>
                        {groupsElem}
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      );
    }
    return (
      <Stack spacing={1}>
        {each.bookedProtocols.map((x) => {
          return (
            <Stack spacing={0}>
              <Typography variant="overline">
                {x.companyProtocolName}
              </Typography>
              <Typography variant="caption">{x.companyProtocolType}</Typography>
            </Stack>
          );
        })}
      </Stack>
    );
  };
  renderLabs = (each) => {
    if (!each.labFrozen) {
      return "Not Chosen";
    }
    if (!each.labPSLs) {
      return "N/A";
    }
    if (!each.labPSLs.length) {
      return "N/A";
    }
    return (
      <Stack spacing={1}>
        {each.labPSLs.map((x) => {
          return (
            <Stack spacing={0}>
              <Typography variant="overline">{x.providerName}</Typography>
              <Typography variant="caption">{x.pslName}</Typography>{" "}
              <Typography variant="caption">
                Collected At: {x.collectingClinicName || "NA"}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    );
  };
  renderClinics = (each) => {
    if (!each.clinicFrozen) {
      return "Not Chosen";
    }
    if (!each.clinicPSLs) {
      return "N/A";
    }
    if (!each.clinicPSLs.length) {
      return "N/A";
    }
    const groupedClinics = _.groupBy(each.clinicPSLs, "providerName");
    return (
      <Stack spacing={2}>
        {Object.keys(groupedClinics).map((x, index) => {
          const y = groupedClinics[x][0];
          let dt = "NA";
          if (!y.scheduledOnDate) {
            dt = null;
          } else {
            dt = new Date(y.scheduledOnDate);
            let tm = new Date(y.scheduledTime);
            dt.setHours(tm.getHours());
            dt.setMinutes(tm.getMinutes());
            dt =
              niceDateTime(dt) +
              " " +
              (y.providerObj.providerReadableTimezone || "");
          }
          return (
            <Stack spacing={0.5} key={index}>
              <Typography variant="overline">{x}</Typography>
              {groupedClinics[x].map((y, yIdx) => {
                return (
                  <Stack spacing={0} key={yIdx}>
                    <Typography variant="caption">{y.pslName}</Typography>{" "}
                  </Stack>
                );
              })}
              {dt && (
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Iconify icon="lucide:calendar" />
                  <Typography variant="caption">
                    Scheduled At: <b>{dt || "NA"}</b>
                  </Typography>
                </Stack>
              )}
            </Stack>
          );
        })}
      </Stack>
    );
  };
  renderClearances = (each) => {
    const clearances = [];

    for (let ps of each.protocolSummaries) {
      if (ps.clearancePsUrl) {
        const psDetailed = _.find(
          each.bookedProtocols,
          (psd) => psd._id === ps.protocolId
        );
        clearances.push(
          <Stack spacing={0}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="caption">
                {psDetailed.companyProtocolName}
              </Typography>
              <IconButton href={ps.clearancePsUrl} target="_blank">
                <Iconify icon="codicon:file-symlink-file" />
              </IconButton>
            </Stack>
          </Stack>
        );
      }
    }

    for (let bs of each.bookedServices) {
      if (bs.clearancePsUrl) {
        const bsDetailed = _.find(
          each.bookedServicesDetailed,
          (bsd) => bsd._id === bs.serviceId
        );
        clearances.push(
          <Stack spacing={0}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="caption">
                {bsDetailed.serviceName}
              </Typography>
              <IconButton href={bs.clearancePsUrl} target="_blank">
                <Iconify icon="codicon:file-symlink-file" />
              </IconButton>
              {/* <Button
                size="small"
                href={bs.clearancePsUrl}
                target="_blank"
                variant="outlined"
                startIcon={<Iconify icon="ion:download" />}
              ></Button> */}
            </Stack>
          </Stack>
        );
      }
    }

    if (clearances.length === 0) {
      clearances.push(
        <Stack spacing={0}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="caption">No Clearances Issued</Typography>
          </Stack>
        </Stack>
      );
    }

    return <Stack spacing={1}>{clearances}</Stack>;
  };
  renderHeader = () => {
    if (this.state.currentUser.isEmployee) {
      return (
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Typography variant="h6">Your Visits</Typography>
          {/* <Button
            variant="contained"
            startIcon={<Iconify icon="mingcute:refresh-anticlockwise-1-line" />}
            onClick={() => this.getVisitsByEmployeeId()}
          >
            Reload
          </Button> */}
          <Button
            variant="contained"
            startIcon={<Iconify icon="mingcute:refresh-anticlockwise-1-line" />}
            onClick={() => this.getVisitsByEmployeeId()}
          >
            Past Visits
          </Button>
          <Button
            variant="contained"
            startIcon={<Iconify icon="mingcute:refresh-anticlockwise-1-line" />}
            onClick={() => this.getVisitsByEmployeeId()}
          >
            Current Visits
          </Button>
        </Stack>
      );
    }
    if (this.context.currentUser.isExternal) {
      return (
        <Stack
          spacing={1}
          direction={"row"}
          alignItems="end"
          justifyContent="end"
        >
          <Button
            id="STEP_5"
            size="small"
            disabled={this.state.isSearchingVisits}
            href="/visits/new"
            variant="contained"
            startIcon={<Iconify icon="mingcute:add-line" />}
          >
            Create a New Visit
          </Button>
          <Button
            id="STEP_6"
            size="small"
            disabled={this.state.isSearchingVisits}
            href="/visits/request"
            variant="contained"
            startIcon={<Iconify icon="ion:person-add" />}
          >
            Request a New Visit
          </Button>
          {/* <Button
            size="small"
            variant="contained"
            startIcon={<Iconify icon="mingcute:question-fill" />}
            id="STEP_13"
            onClick={() => {
              this.setState({
                runJoyRide: true,
              });
            }}
          >
            Quick walkthrough
          </Button> */}
        </Stack>
      );
    }
    return (
      <CustomBreadcrumbs
        heading="List of Visits"
        links={[{ name: "Visits Dashboard" }]}
        action={
          <Stack spacing={1} direction={"row"}>
            <Button
              size="small"
              disabled={this.state.isSearchingVisits}
              href="/visits/new"
              // target="non_blank"
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              New Visit
            </Button>{" "}
            <Button
              size="small"
              disabled={this.state.isSearchingVisits}
              onClick={() => {
                let momentToday = new Date();
                let m = moment(momentToday);
                this.setState(
                  {
                    filterDateRange: [m, m],
                  },
                  () => {
                    this.searchVisits();
                  }
                );
              }}
              variant="contained"
            >
              Today's Visits
            </Button>{" "}
            <Button
              size="small"
              disabled={this.state.isSearchingVisits}
              onClick={() => {
                this.setState(
                  {
                    filterStatus: ["AWAITING_DOCUMENTS"],
                  },
                  () => {
                    this.searchVisits();
                  }
                );
              }}
              variant="contained"
            >
              Pending Documents
            </Button>
          </Stack>
        }
      />
    );
  };

  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    const { runJoyRide, stepIndex, steps } = this.state;
    return (
      <Stack spacing={2} id="STEP_0">
        <Walktour
          key={this.state.steps.length}
          continuous
          showProgress
          showSkipButton
          disableOverlayClose
          callback={this.handleJoyrideCallback}
          run={runJoyRide}
          stepIndex={stepIndex}
          steps={steps}
        />
        {this.renderHeader()}
        {this.renderFilterBox()}
        {this.renderSearchResults()}
      </Stack>
    );
  }
}

export default WithAPICall(VisitListER);
