import React from "react";
import { WithAPICall } from "../../utils/apiUtil";
import { View500 } from "src/sections/error";
import {
  Alert,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  TextField,
  Button,
  Tooltip,
} from "@mui/material";
import cogoToast from "cogo-toast";
import _ from "lodash";
import { Stack } from "@mui/system";
import { niceDateTime, niceFileSize } from "src/micro/utils/fn";
import Iconify from "src/components/iconify";
import { LoadingButton } from "@mui/lab";

class DocTabErrorList extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    fileList: [],
  };
  componentDidMount() {
    this.getErrorFiles();
  }
  getErrorFiles = async () => {
    try {
      let res = await this.props.apiCallPost("/files/getByEntityType", {
        entityType: "VISIT_OUTPUT_FILE",
        statusIn: ["PDF_ERROR"],
      });
      this.setState({
        isLoading: false,
        isError: false,
        fileList: res,
      });
    } catch (e) {
      cogoToast.error("Error fetching file list");
      this.setState({
        isError: true,
      });
    }
  };
  tryPassword = async (index) => {
    try {
      let file = this.state.fileList[index];
      file.passwordWarning = null;
      file.isTryingPassword = true;
      let files = this.state.fileList;
      files[index] = file;
      this.setState({
        fileList: files,
      });
      let res = await this.props.apiCallPost("/files/checkFilePassword", {
        fileId: file._id,
        password: file.password,
      });
      let isCorrect = res.isCorrect;
      if (isCorrect) {
        cogoToast.success("Password is correct. Reprocessing file.");
        this.reprocessWithPassword(index);
      } else {
        file.isTryingPassword = false;
        file.passwordWarning = "Entered password is incorrect";
        files[index] = file;
        this.setState({
          fileList: files,
        });
        cogoToast.error("Password is incorrect");
      }
    } catch (err) {
      cogoToast.error("Error trying password");
      let file = this.state.fileList[index];
      file.isTryingPassword = false;
      let files = this.state.fileList;
      files[index] = file;
      this.setState({
        fileList: files,
      });
    }
  };
  reprocessWithPassword = async (index) => {
    try {
      let file = this.state.fileList[index];
      file.passwordWarning = null;
      file.isTryingPassword = false;
      file.isProcessing = true;
      let files = this.state.fileList;
      files[index] = file;
      this.setState({
        fileList: files,
      });
      let res = await this.props.apiCallPost("/files/reprocessWithPassword", {
        fileId: file._id,
        password: file.password,
      });
      if (res.processStatus === "UPLOADED") {
        cogoToast.success("File reprocessed successfully");
        file.isProcessing = false;
        files.isProcessed = true;
        this.setState({
          fileList: files,
        });
      } else {
        cogoToast.error("Error reprocessing file");
        file.isProcessing = false;
        files[index] = res;
        this.setState({
          fileList: files,
        });
      }
    } catch (err) {
      cogoToast.error("Error reprocessing file.");
      let file = this.state.fileList[index];
      file.isProcessing = false;
      let files = this.state.fileList;
      files[index] = file;
      this.setState({
        fileList: files,
      });
    }
  };
  discardedFile = async (index) => {
    let files = this.state.fileList;
    let file = files[index];
    file.isDiscarding = true;
    files[index] = file;
    this.setState({
      fileList: files,
    });
    try {
      let res = await this.props.apiCallPost("/files/setStatus", {
        cfId: file._id,
        processStatus: "DISCARDED",
      });
      cogoToast.success("File discarded successfully");
      file.isDiscarding = false;
      file.processStatus = res.processStatus;
      files[index] = file;
      this.setState({
        fileList: files,
      });
    } catch (err) {
      cogoToast.error("Error discarding file");
      let file = this.state.fileList[index];
      file.isDiscarding = false;
      let files = this.state.fileList;
      files[index] = file;
      this.setState({
        fileList: files,
      });
    }
  };
  renderErrorFileList = () => {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.fileList.length === 0) {
      return <Alert severity="info">No error files found</Alert>;
    }
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>File Name</TableCell>
            <TableCell>Provenance</TableCell>
            <TableCell>Error</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.fileList.map((file, index) => {
            let provenanceElem = null;
            if (file.provenance === "User") {
              provenanceElem = (
                <Typography variant="overline">
                  Uploaded by {file.createdBy?.name || "User"}
                </Typography>
              );
            } else if (file.isTakenFromEmail) {
              provenanceElem = (
                <Typography variant="overline">Taken from email</Typography>
              );
            } else {
              provenanceElem = (
                <Typography variant="overline">Automation</Typography>
              );
            }

            let actionElem = null;
            if (file.isProcessed) {
              actionElem = (
                <Stack direction="row" spacing={0.5}>
                  <Iconify icon="akar-icons:check" />
                  <Typography variant="overline">
                    Processed: ${file.processStatus}
                  </Typography>
                </Stack>
              );
            } else if (file.isProcessing) {
              actionElem = (
                <Stack direction="column" spacing={0.5}>
                  <LinearProgress />
                  <Typography variant="overline">Processing...</Typography>
                </Stack>
              );
            } else if (file.isDiscarding) {
              actionElem = (
                <Stack direction="column" spacing={0.5}>
                  <LinearProgress />
                  <Typography variant="overline">Discarding...</Typography>
                </Stack>
              );
            } else {
              let processErrorStatus = file.processErrorStatus || "";
              let processStatus = file.processStatus || "";
              if (processErrorStatus === "No password given") {
                actionElem = (
                  <Stack direction="column" spacing={0.5}>
                    <Stack direction="row">
                      <TextField
                        disabled={file.isTryingPassword}
                        label="Password"
                        value={file.password || ""}
                        onChange={(e) => {
                          let fileList = this.state.fileList;
                          fileList[index].password = e.target.value;
                          this.setState({
                            fileList: fileList,
                          });
                        }}
                      />
                      <LoadingButton
                        variant="outlined"
                        color="primary"
                        disabled={!file.password}
                        loading={file.isTryingPassword}
                        onClick={() => {
                          this.tryPassword(index);
                        }}
                      >
                        Try Password
                      </LoadingButton>
                    </Stack>
                    {file.passwordWarning && (
                      <Typography variant="caption" color="error">
                        {file.passwordWarning}
                      </Typography>
                    )}
                  </Stack>
                );
              } else if (processStatus === "DISCARDED") {
                actionElem = (
                  <Stack direction="row" spacing={0.5}>
                    <Iconify icon="akar-icons:cross" />
                    <Tooltip title="This file is in the queue for being deleted.">
                      <Typography variant="overline">Discarded</Typography>
                    </Tooltip>
                  </Stack>
                );
              } else {
                actionElem = (
                  <Button
                    onClick={() => {
                      this.discardedFile(index);
                    }}
                    variant="outlined"
                    color="error"
                  >
                    Discard File
                  </Button>
                );
              }
            }
            return (
              <TableRow>
                <TableCell>
                  <Stack direction="column" spacing={0.5}>
                    <Stack direction="row" alignItems={"center"} spacing={0.5}>
                      <Typography variant="overline">
                        {" "}
                        {file.description ||
                          file.originalName ||
                          "Unknown File Name"}
                      </Typography>
                      <IconButton href={file.psUrl} target="_blank">
                        <Iconify icon="quill:link-out" />
                      </IconButton>
                    </Stack>
                    <Typography variant="caption">
                      {niceFileSize(file.sizeBytes)}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Stack direction="column" spacing={0.5}>
                    {provenanceElem}
                    <Typography variant="caption">
                      {niceDateTime(file.createdAt) || "Unknown Date"}{" "}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Stack direction="column" spacing={0.5}>
                    <Typography variant="overline">
                      {file.processStatus || "Unknown Error"}
                    </Typography>
                    <Typography variant="caption">
                      {file.processErrorStatus || "Unknown Error"}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>{actionElem}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };
  render() {
    if (this.state.isError) {
      return <View500 />;
    }
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return <Stack>{this.renderErrorFileList()}</Stack>;
  }
}

export default WithAPICall(DocTabErrorList);
