import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Alert,
  Autocomplete,
  Card,
  Chip,
  FormControlLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { View500 } from "src/sections/error";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Box, Stack } from "@mui/system";
import cogoToast from "cogo-toast";
import { LoadingButton } from "@mui/lab";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import _ from "lodash";
import { niceDate } from "../utils/fn";

class ProviderSuppliesRecord extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    quantity: 0,
    shippingCost: 0,
    supplyType: null,
    // network
    isSearchingNetwork: false,
    networkSearchResults: [],
    selectedNetwork: null,

    // company
    isSearchingCompany: false,
    companySearchResults: [],
    selectedCompany: null,
    //
    isCreatingSupplyRecord: false,
    //
    list: [],
    isLoadingList: true,
    aggregations: {
      sum: (params) => this.sumAggregation(params),
      average: (params) => this.averageAggregation(params),
      max: (params) => this.maxAggregation(params),
      min: (params) => this.minAggregation(params),
      count: (params) => this.countAggregation(params),
    },
    isLoadingInventory: true,
    inventoryList: [],
  };
  componentDidMount() {
    this.supplyRecordsGetAll();
    this.supplyInventoryGetAll();
    this.setState({
      ...this.props.data,
      isLoading: false,
    });
  }
  searchCompaniesFTS = async (t) => {
    try {
      let res = await this.props.apiCallPostCancellable("/company/search", {
        query: t,
      });
      if (res) {
        this.setState({
          isSearchingCompany: false,
          companySearchResults: res.map((each) => {
            return {
              mainText: each.companyName,
              secondaryText: `${each.companyInformalName} ${each.companyWebsite}`,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingCompany: false,
        companySearchResults: [],
      });
      cogoToast.error("Error Searching Companies");
    }
  };
  searchNetworks = async (e) => {
    try {
      let res = await this.props.apiCallPostCancellable(
        "/provider/providerNetworkSearch",
        {
          query: e,
          networkProviderType: "Lab",
        }
      );

      if (res) {
        this.setState({
          isSearchingNetwork: false,
          networkSearchResults: res.map((each) => {
            return {
              mainText: each.networkName,
              secondaryText: each.networkState,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingNetwork: false,
        networkSearchResults: [],
      });
      cogoToast.error("Error Searching Networks");
    }
  };
  createSupplyRecord = async () => {
    if (!this.state.selectedNetwork) {
      cogoToast.error("Please select a network");
      return;
    }
    if (!this.state.supplyType) {
      cogoToast.error("Please select a supply type");
      return;
    }

    let st = this.state.supplyType;
    let stsp = this.state.selectedNetwork.data.suppliesProvided;
    let idx = stsp.findIndex((x) => x.supplyType === st);
    let obj = stsp[idx];
    let isC = obj.isCompanySpecific;
    if (isC && !this.state.selectedCompany) {
      cogoToast.error("Please select an employer");
      return;
    }
    let costPerUnit = obj.costPerUnitUSD;
    if (!costPerUnit) {
      cogoToast.error("Please define the cost per unit for this supply type");
      return;
    }
    if (!this.state.quantity) {
      cogoToast.error("Please define the quantity");
      return;
    }
    let costPerUnitUSD = parseFloat(obj.costPerUnitUSD);
    let costOne = costPerUnitUSD * parseInt(this.state.quantity);
    let shippingCost = parseFloat(this.state.shippingCost);
    let totalCost = costOne + shippingCost;
    let payload = {
      vendorId: this.state.selectedNetwork.data._id,
      providerId: this.props.id,
      quantity: this.state.quantity,
      shippingCost: this.state.shippingCost,
      supplyType: this.state.supplyType,
      costUsd: costPerUnit,
      companyId: this.state.selectedCompany
        ? this.state.selectedCompany.data._id
        : null,
      shippingCostUsd: this.state.shippingCost,
      totalCostUsd: totalCost,
    };
    try {
      this.setState({
        isCreatingSupplyRecord: true,
      });
      await this.props.apiCallPost(
        "/supply-record/createSupplyRecord",
        payload
      );

      this.setState(
        {
          isCreatingSupplyRecord: false,
          selectedNetwork: null,
          selectedCompany: null,
          quantity: 0,
          shippingCost: 0,
        },
        () => {
          this.supplyRecordsGetAll();
        }
      );

      cogoToast.success("Supply Record Created");
    } catch (err) {
      this.setState({
        isCreatingSupplyRecord: false,
      });
      cogoToast.error("Error Creating Supply Record");
      console.log(err);
    }
  };
  supplyRecordsGetAll = async () => {
    try {
      let res = await this.props.apiCallPost(
        "/supply-record/supplyRecordsGetAll",
        { providerId: this.props.id }
      );
      this.setState({ list: res, isLoadingList: false });
    } catch (err) {
      this.setState({
        list: [],
        isLoadingList: false,
      });
      console.log(err);
    }
  };
  supplyInventoryGetAll = async () => {
    try {
      let res = await this.props.apiCallPost(
        "/supply-record/supplyInventoryGetAll",
        { providerId: this.props.id }
      );
      this.setState({ inventoryList: res, isLoadingInventory: false });
    } catch (err) {
      this.setState({
        inventoryList: [],
        isLoadingInventory: false,
      });
      console.log(err);
    }
  };
  updateRow = async (idx, field, api, keyName) => {
    let l =
      keyName === "supplyRecordId" ? this.state.list : this.state.inventoryList;
    let row = l[idx];
    let payload = {
      [keyName]: row._id,
    };
    if (keyName === "supplyInventoryId") {
      payload["quantity"] = parseInt(row["quantity"]);
    } else if (keyName === "supplyRecordId") {
      payload["key"] = field;
      payload["value"] = row[field];
    }

    try {
      await this.props.apiCallPost(`/supply-record/${api}`, payload);
      this.supplyInventoryGetAll();
    } catch (err) {
      cogoToast.error("Error Updating Data");
      console.log(err);
    }
  };
  renderNetworkSearcher = () => {
    return (
      <FormControlLabel
        label="Lab Network (Supplier)"
        labelPlacement="start"
        control={
          <Autocomplete
            sx={{ width: "300px" }}
            disableClearable
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option?.mainText
            }
            filterOptions={(x) => x}
            options={
              this.state.isSearchingNetwork
                ? []
                : this.state.networkSearchResults
            }
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={this.state.selectedNetwork}
            noOptionsText={
              this.state.isSearchingNetwork
                ? "Searching..."
                : "No results found"
            }
            onChange={(_, value) => {
              this.setState({
                selectedNetwork: value,
                networkSearchResults: [
                  value,
                  ...this.state.networkSearchResults,
                ],
              });
            }}
            onInputChange={(_, e) => {
              if (e === "") return null;
              if (!e) return null;
              if (e.length < 3) return null;
              this.setState(
                {
                  isSearchingNetwork: true,
                },
                () => {
                  this.searchNetworks(e);
                }
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="Search for a network" fullWidth />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  <Grid container alignItems="center">
                    <Grid
                      item
                      sx={{
                        wordWrap: "break-word",
                      }}
                    >
                      <Box component="span">{option?.mainText}</Box>
                      <Typography variant="body2" color="text.secondary">
                        {option?.secondaryText}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              );
            }}
          />
        }
        sx={{
          m: 0,
          width: 1,
          justifyContent: "space-between",
        }}
      />
    );
  };
  renderCompanySearcher = () => {
    if (!this.state.selectedNetwork) return null;
    if (!this.state.supplyType) return null;

    let st = this.state.supplyType;
    let stsp = this.state.selectedNetwork.data.suppliesProvided;
    let idx = stsp.findIndex((x) => x.supplyType === st);
    let obj = stsp[idx];
    let isC = obj.isCompanySpecific;
    if (!isC) return null;

    return (
      <FormControlLabel
        label="Employer (This supply is Company-Specific)"
        labelPlacement="start"
        control={
          <Autocomplete
            sx={{ width: "300px" }}
            disableClearable
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option?.mainText
            }
            filterOptions={(x) => x}
            options={
              this.state.isSearchingCompany
                ? []
                : this.state.companySearchResults
            }
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={this.state.selectedCompany}
            noOptionsText={
              this.state.isSearchingCompany
                ? "Searching..."
                : "No results found"
            }
            onChange={(_, value) => {
              this.setState({
                selectedCompany: value,
                companySearchResults: [
                  value,
                  ...this.state.companySearchResults,
                ],
              });
            }}
            onInputChange={(_, e) => {
              if (e === "") return null;
              if (!e) return null;
              if (e.length < 3) return null;
              this.setState(
                {
                  isSearchingCompany: true,
                },
                () => {
                  this.searchCompaniesFTS(e);
                }
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="Search Employers" fullWidth />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  <Grid container alignItems="center">
                    <Grid item sx={{ wordWrap: "break-word" }}>
                      <Box component="span">{option?.mainText}</Box>
                      <Typography variant="body2" color="text.secondary">
                        {option?.secondaryText}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              );
            }}
          />
        }
        sx={{
          m: 0,
          width: 1,
          justifyContent: "space-between",
        }}
      />
    );
  };
  renderFormForNewSupplyRecord = () => {
    return (
      <Card sx={{ pt: 3, pb: 3, px: 3, boxShadow: 3 }}>
        <Stack spacing={2}>
          {this.renderNetworkSearcher()}
          {this.renderChooseTypeOfSupply()}
          {this.renderCompanySearcher()}
          {this.renderQuantity()}
          {this.renderShippingCost()}
          {this.renderTotalCost()}
          {this.renderConfirmButton()}
        </Stack>
      </Card>
    );
  };
  renderChooseTypeOfSupply = () => {
    if (!this.state.selectedNetwork) return null;
    let nw = this.state.selectedNetwork.data;
    let sp = nw.suppliesProvided;
    if (!sp || !sp.length) {
      return (
        <Alert severity="warning">
          There are no supplies defined for this network.
        </Alert>
      );
    }
    let options = sp.map((each) => each.supplyType);
    return (
      <FormControlLabel
        label="Type of Supply"
        labelPlacement="start"
        control={
          <Select
            sx={{
              minWidth: "200px",
            }}
            value={this.state.supplyType}
            onChange={(e) => {
              this.setState({
                supplyType: e.target.value,
              });
            }}
          >
            {options.map((x) => (
              <MenuItem key={x} value={x}>
                {x}
              </MenuItem>
            ))}
          </Select>
        }
        sx={{
          m: 0,
          width: 1,
          justifyContent: "space-between",
        }}
      />
    );
  };
  renderQuantity = () => {
    if (!this.state.selectedNetwork) return null;
    if (!this.state.supplyType) return null;
    return (
      <TextField
        label="Quantity (# of Units)"
        type="number"
        value={this.state.quantity}
        onChange={(e) => {
          this.setState({
            quantity: e.target.value,
          });
        }}
        onBlur={() => {
          this.setState({
            quantity: parseInt(this.state.quantity),
          });
        }}
      />
    );
  };
  renderShippingCost = () => {
    if (!this.state.selectedNetwork) return null;
    if (!this.state.supplyType) return null;
    return (
      <TextField
        label="Shipping Cost (USD)"
        type="number"
        value={this.state.shippingCost}
        onChange={(e) => {
          this.setState({
            shippingCost: e.target.value,
          });
        }}
        onBlur={() => {
          this.setState({
            shippingCost: parseFloat(this.state.shippingCost),
          });
        }}
      />
    );
  };
  renderTotalCost = () => {
    if (!this.state.selectedNetwork) return null;
    if (!this.state.supplyType) return null;
    let st = this.state.supplyType;
    let stsp = this.state.selectedNetwork.data.suppliesProvided;
    let idx = stsp.findIndex((x) => x.supplyType === st);
    let obj = stsp[idx];
    let costPerUnitUSD = parseFloat(obj.costPerUnitUSD);
    let costOne = costPerUnitUSD * parseInt(this.state.quantity);
    let shippingCost = this.state.shippingCost
      ? parseFloat(this.state.shippingCost)
      : 0;
    let totalCost = costOne + shippingCost;

    let totalCostFormatted = totalCost.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });

    return (
      <Chip label={`Total Cost: ${totalCostFormatted}`} variant="outlined" />
    );
  };
  renderConfirmButton = () => {
    if (!this.state.selectedNetwork) return null;
    if (!this.state.supplyType) return null;
    if (!this.state.quantity) return null;
    let st = this.state.supplyType;
    let stsp = this.state.selectedNetwork.data.suppliesProvided;
    let idx = stsp.findIndex((x) => x.supplyType === st);
    let obj = stsp[idx];
    let isC = obj.isCompanySpecific;
    if (isC && !this.state.selectedCompany) return null;

    return (
      <LoadingButton
        loading={this.state.isCreatingSupplyRecord}
        onClick={() => this.createSupplyRecord()}
        variant="contained"
      >
        Create Supply Record
      </LoadingButton>
    );
  };
  onGridReady = (params) => {
    this.setState({ gridApi: params.api, columnApi: params.columnApi });
  };
  sumAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));

    return _.sum(arr);
  };
  averageAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.mean(arr);
  };
  maxAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.max(arr);
  };
  minAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.min(arr);
  };
  countAggregation = (params) => {
    return params.values.length;
  };
  COLUMNS = [
    {
      headerName: "Vendor",
      field: "vendor.networkName",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
      aggFunc: "count",
    },
    {
      headerName: "Type",
      field: "supplyType",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
      aggFunc: "count",
    },
    {
      headerName: "Employer",
      field: "company.companyName",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
      valueGetter: (params) => {
        if (params?.data?.company) {
          return params?.data?.company?.companyName;
        }
        return null;
      },
      aggFunc: "count",
    },
    {
      headerName: "Cost/Unit (USD)",
      field: "costUsd",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agNumberColumnFilter",
      aggFunc: "average",
    },
    {
      headerName: "Quantity",
      field: "quantity",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agNumberColumnFilter",
      aggFunc: "sum",
    },
    {
      headerName: "Shipping Cost (USD)",
      field: "shippingCostUsd",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agNumberColumnFilter",
      aggFunc: "sum",
    },
    {
      headerName: "Total Cost (USD)",
      field: "totalCostUsd",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agNumberColumnFilter",
      aggFunc: "sum",
    },
    {
      headerName: "Status",
      field: "status",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
      aggFunc: "count",
      cellRenderer: (row) => this.renderSelectorFieldInGrid(row, "status"),
    },
    {
      headerName: "Tracking Details",
      field: "tracking",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
      aggFunc: "count",
      cellRenderer: (row) => this.renderTextFieldInGrid(row, "tracking"),
    },
    {
      headerName: "Notes",
      field: "notes",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
      aggFunc: "count",
      cellRenderer: (row) => this.renderTextFieldInGrid(row, "notes"),
    },
    {
      headerName: "Created",
      field: "createdAt",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agDateColumnFilter",
      aggFunc: "count",
      valueFormatter: (params) => {
        return params.value ? niceDate(params.value) : null;
      },
    },
    {
      headerName: "Last Updated",
      field: "updatedAt",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agDateColumnFilter",
      aggFunc: "count",
      valueFormatter: (params) => {
        return params.value ? niceDate(params.value) : null;
      },
    },
  ];
  INVENTORY_COLUMNS = [
    {
      headerName: "Vendor",
      field: "vendor.networkName",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
      aggFunc: "count",
    },
    {
      headerName: "Type",
      field: "supplyType",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
      aggFunc: "count",
    },
    {
      headerName: "Employer",
      field: "company.companyName",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
      valueGetter: (params) => {
        if (params?.data?.company) {
          return params?.data?.company?.companyName;
        }
        return null;
      },
      aggFunc: "count",
    },

    {
      headerName: "Quantity",
      field: "quantity",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agNumberColumnFilter",
      aggFunc: "sum",
      cellRenderer: (row) => this.renderNumberFieldInGrid(row, "quantity"),
    },
  ];
  STATUS_OPTIONS = [
    "DRAFT",
    "SHIPPED",
    "RECEIVED",
    "CANCELLED",
    "LOST",
    "RETURNED",
    "REFUSED",
    "DAMAGED",
    "OTHER",
  ];
  renderSupplyRecordsGrid = () => {
    if (this.state.isLoadingList) return <LinearProgress />;
    if (!this.state.list || !this.state.list.length) {
      return <Alert severity="info">No Supply Records Found</Alert>;
    }
    let cs = "ag-theme-quartz";
    let theme = localStorage.getItem("themeMode");
    if (theme && theme === "dark") {
      cs = "ag-theme-quartz-dark";
    }
    return (
      <div
        className={cs}
        style={{ height: "70vh" }}
        key={this.state.list.length}
      >
        <AgGridReact
          onGridReady={this.onGridReady}
          rowData={this.state.list}
          columnDefs={this.COLUMNS}
          defaultColDef={{
            sortable: true,
          }}
          autoSizeStrategy={{
            type: "fitCellContents",
          }}
          pagination={true}
          rowGroupPanelShow={
            this.state.enableAdvancedFilter ? "always" : "never"
          }
          checkboxSelection={false}
          groupSelectsChildren={true}
          aggFuncs={this.state.aggregations}
          suppressDragLeaveHidesColumns={true}
          rowSelection="multiple"
          rowDragManaged={true}
          enableCharts={true}
          enableRangeSelection={true}
          enableCellTextSelection={true}
          quickFilterText={this.state.quickSearchText}
          sideBar={true}
          animateRows={true}
          enableAdvancedFilter={this.state.enableAdvancedFilter}
        />
      </div>
    );
  };
  renderSupplyInventoryGrid = () => {
    if (this.state.isLoadingInventory) return <LinearProgress />;
    if (!this.state.inventoryList || !this.state.inventoryList.length) {
      return <Alert severity="info">No Inventory Found</Alert>;
    }
    let cs = "ag-theme-quartz";
    let theme = localStorage.getItem("themeMode");
    if (theme && theme === "dark") {
      cs = "ag-theme-quartz-dark";
    }
    return (
      <div
        className={cs}
        style={{ height: "70vh" }}
        key={this.state.inventoryList.map((each) => each.quantity).join("_")}
      >
        <AgGridReact
          rowData={this.state.inventoryList}
          columnDefs={this.INVENTORY_COLUMNS}
          defaultColDef={{
            sortable: true,
          }}
          autoSizeStrategy={{
            type: "fitCellContents",
          }}
          pagination={true}
          rowGroupPanelShow={
            this.state.enableAdvancedFilter ? "always" : "never"
          }
          checkboxSelection={false}
          groupSelectsChildren={true}
          aggFuncs={this.state.aggregations}
          suppressDragLeaveHidesColumns={true}
          rowSelection="multiple"
          rowDragManaged={true}
          enableCharts={true}
          enableRangeSelection={true}
          enableCellTextSelection={true}
          quickFilterText={this.state.quickSearchText}
          sideBar={true}
          animateRows={true}
          enableAdvancedFilter={this.state.enableAdvancedFilter}
        />
      </div>
    );
  };
  renderTextFieldInGrid = (row, field) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    return (
      <TextField
        fullWidth
        size="small"
        variant="standard"
        value={row.data[field]}
        onChange={(e) => {
          let list = this.state.list;
          list[row.rowIndex][field] = e.target.value;
          this.setState({ list });
        }}
        onBlur={() => {
          this.updateRow(
            row.rowIndex,
            field,
            "supplyRecordsUpdateScalarValue",
            "supplyRecordId"
          );
        }}
      />
    );
  };
  renderSelectorFieldInGrid = (row, field) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    return (
      <Select
        disabled={row.value === "RECEIVED"}
        fullWidth
        size="small"
        variant="standard"
        value={row.data[field]}
        onChange={(e) => {
          let list = this.state.list;
          list[row.rowIndex][field] = e.target.value;
          this.setState({ list }, () => {
            this.updateRow(
              row.rowIndex,
              field,
              "supplyRecordsUpdateScalarValue",
              "supplyRecordId"
            );
            this.supplyInventoryGetAll();
          });
        }}
      >
        {this.STATUS_OPTIONS.map((x) => (
          <MenuItem key={x} value={x}>
            {x}
          </MenuItem>
        ))}
      </Select>
    );
  };
  renderNumberFieldInGrid = (row, field) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    return (
      <TextField
        fullWidth
        size="small"
        variant="standard"
        value={row.data[field]}
        onChange={(e) => {
          let inventoryList = this.state.inventoryList;
          inventoryList[row.rowIndex][field] = e.target.value;
          this.setState({ inventoryList });
        }}
        onBlur={() => {
          this.updateRow(
            row.rowIndex,
            field,
            "supplyInventoryUpdateScalarValue",
            "supplyInventoryId"
          );
        }}
      />
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    if (this.state.providerType !== "Clinic") {
      return (
        <Alert severity="warning">
          Kits are only sent to clinics. If you want to define types of supplies
          or their costs, please use the Network Page for the lab.
        </Alert>
      );
    }
    return (
      <Stack spacing={3}>
        <Typography variant="h6">Record New Supply</Typography>
        {this.renderFormForNewSupplyRecord()}{" "}
        <Typography variant="h6">Current Inventory</Typography>
        {this.renderSupplyInventoryGrid()}{" "}
        <Typography variant="h6">Supply Records</Typography>{" "}
        {this.renderSupplyRecordsGrid()}
      </Stack>
    );
  }
}

export default WithAPICall(ProviderSuppliesRecord);
