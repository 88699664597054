import React, { useEffect, useState } from "react";
import { useAuth, useUser } from "@clerk/clerk-react";
import TokenContext from "./TokenContext";
import axios from "axios";
import Page403 from "./pages/403";
import { LinearProgress } from "@mui/material";
import * as Sentry from "@sentry/react";
const apiUrl = process.env.REACT_APP_API_URL;

export const TokenProvider = ({ children }) => {
  const { getToken } = useAuth();
  const { user } = useUser();
  const provideToken = async () => {
    return await getToken();
  };
  const userEmail = user?.primaryEmailAddress?.emailAddress || "";
  Sentry.setUser({ email: userEmail });
  const apiCallPost = async (path, datum) => {
    const token = await provideToken();
    if (!token) {
      setCurrentUser(null);
      setLoading(false);
      throw new Error("No token");
    }
    const resp = await axios.post(apiUrl + path, datum, {
      credentials: "include",
      headers: { Authorization: `Bearer ${token}` },
    });
    return resp.data;
  };
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(null);

  const getCurrentUser = async () => {
    const response = await apiCallPost("/user/getCurrentUser", {});
    return response;
  };

  useEffect(() => {
    setLoading(true);
    const load = async () => {
      try {
        let r = await getCurrentUser();
        setCurrentUser(r);
        setLoading(false);
      } catch (error) {
        setCurrentUser(null);
        setLoading(false);
      }
    };
    load();
  }, []);
  if (loading) return <LinearProgress />;
  if (!currentUser) return <Page403 />;

  return (
    <TokenContext.Provider
      value={{
        provideToken,
        userEmail,
        clerkUserObject: user,
        currentUser,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};
