import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import { Alert, LinearProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import cogoToast from "cogo-toast";

class VisitChooseLabSupplyStatus extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    supplyInventory: [],
  };
  componentDidMount() {
    this.supplyInventoryGetAllIgnoringCompany();
  }
  supplyInventoryGetAllIgnoringCompany = async () => {
    try {
      let res = await this.props.apiCallPost(
        "/supply-record/supplyInventoryGetAllIgnoringCompany",
        {
          companyId: this.props.visit.company._id,
          vendorId: this.props.chosenPSLForThisService.pslObj.network,
          providerId: this.props.chosenPSLForThisService.collectingClinicId,
          supplyType: this.props.supply.supplyType,
        }
      );

      this.setState({
        isLoading: false,
        isError: false,
        supplyInventory: res,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        isError: true,
      });
      cogoToast.error("Error Fetching Supply Inventory");
    }
  };
  renderInventory = () => {
    if (!this.state.supplyInventory.length) {
      return <Alert severity="error">No Inventory Found</Alert>;
    }
    let qty = this.state.supplyInventory[0].quantity;
    let needed = this.props.supply.quantity;
    if (!qty) {
      return <Alert severity="error">No Inventory Found</Alert>;
    }
    if (qty < needed) {
      return <Alert severity="warning">Insufficient Inventory</Alert>;
    }

    let sufficientQty = needed * 5;

    if (qty < sufficientQty) {
      return (
        <Alert
          severity="info"
          sx={{
            p: 1,
          }}
        >
          Sufficient For This Visit; Consider Restocking
        </Alert>
      );
    } else {
      return (
        <Alert
          severity="success"
          sx={{
            p: 0,
          }}
        >
          Sufficient Supplies In Stock
        </Alert>
      );
    }
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <Alert severity="error">Error Fetching Supply Inventory</Alert>;
    }
    return (
      <Stack spacing={1}>
        <Typography variant="overline">
          {this.props.supply.supplyType} (Needed: {this.props.supply.quantity})
        </Typography>
        {this.renderInventory()}
      </Stack>
    );
  }
}
export default WithAPICall(VisitChooseLabSupplyStatus);
