import {
  Button,
  LinearProgress,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import React from "react";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import Iconify from "src/components/iconify";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { WithAPICall } from "../utils/apiUtil";
import { Box, Stack } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import ImageLoaderForGrid from "../utils/ImageLoaderForGrid";
import { NoResultView, View500 } from "src/sections/error";
import ProviderCreate from "./ProviderCreate";
import cogoToast from "cogo-toast";
import _ from "lodash";

class ListProviders extends React.Component {
  state = {
    query: "",
    isSearching: true,
    quickSearchText: "",
    enableAdvancedFilter: false,
    hasLoadingStarted: false,
    list: [],
    openProviderCreate: false,
    selectedRows: [],
    aggregations: {
      sum: (params) => this.sumAggregation(params),
      average: (params) => this.averageAggregation(params),
      max: (params) => this.maxAggregation(params),
      min: (params) => this.minAggregation(params),
      count: (params) => this.countAggregation(params),
    },
  };
  buffer = [];
  timer = null;
  componentDidMount() {
    this.search();
  }
  search = async () => {
    this.setState({ isSearching: true });
    cogoToast.loading("Loading data, this might take a while!");
    this.setState({
      list: [],
      isSearching: true,
      hasLoadingStarted: false,
    });
    this.buffer = [];
    this.timer = setInterval(() => {
      let bufferLength = this.buffer.length;
      let listLength = this.state.list.length;
      let diff = bufferLength - listLength;

      if (diff > 0) {
        this.setState({
          list: [
            ...this.state.list,
            ...this.buffer.slice(listLength, bufferLength),
          ],
        });
      }
    }, 250);
    let payload = {};
    if (this.props.networkId) {
      payload = {
        providerNetworkId: this.props.networkId,
      };
    }
    await this.props.apiCallPostStreaming(
      "/provider/providerGetAllStreaming",
      payload,
      (data) => {
        if (!this.state.hasLoadingStarted) {
          this.setState({ hasLoadingStarted: true });
        }
        this.buffer.push(...data);
      },
      () => {
        this.setState(
          { isSearching: false, hasLoadingStarted: false },
          async () => {
            // wait 4 seconds
            await new Promise((resolve) => setTimeout(resolve, 4000));
            clearInterval(this.timer);
            if (this.props.fromCompanyPage && this.props.selectedData) {
              const preSelectedProviders = new Set(
                this.props.selectedData.map((each) => each.provider._id)
              );
              const remainingProviders = this.state.list.filter(
                (each) => !preSelectedProviders.has(each._id)
              );
              this.setState({
                list: remainingProviders,
              });
            }
          }
        );
      }
    );
  };
  COLUMNS = [
    {
      headerName: "Provider",
      field: "providerInformalName",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
      cellRenderer: (row) => this.renderName(row),
      headerCheckboxSelection: this.props.fromCompanyPage ? true : false,
      checkboxSelection: (params) => {
        if (!this.props.fromCompanyPage) return true;
        let selectedData = this.props.selectedData;
        if (!selectedData) return true;
        if (selectedData.length === 0) return true;
        let _id = params.data._id;
        let dd = new Set(selectedData.map((each) => each.provider._id));
        return !dd.has(_id);
      },
      showDisabledCheckboxes: this.props.fromCompanyPage ? true : false,
    },
    {
      headerName: "Legal Entity",
      field: "providerName",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Type",
      field: "providerType",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Country",
      field: "providerCountry",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.renderCountry(row),
    },
    {
      headerName: "State",
      field: "providerState",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
    },
    {
      headerName: "City",
      field: "providerCity",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
    },
    {
      headerName: "Postal Code",
      field: "providerPostalCode",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
    },
    {
      headerName: "Network",
      field: "providerNetwork",
      enableRowGroup: true,
      enablePivot: true,
      valueGetter: (params) => {
        // if providerNetwork then providerNetwork.networkName
        if (params?.data?.providerNetwork) {
          return params?.data?.providerNetwork?.networkName;
        }
        return null;
      },
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "WorkCare Preferred",
      field: "isInternalPreferred",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
      cellRenderer: (row) => this.booleanGetter(row),
    },
    {
      headerName: "Phone",
      field: "providerPhone",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.renderPhone(row),
    },
    {
      headerName: "Email",
      field: "providerEmail",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.renderSendEmail(row),
    },
    {
      headerName: "Website",
      field: "providerWebsite",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.renderWebsite(row),
    },

    {
      headerName: "DOT Examiner",
      field: "isDOTCertifiedExaminerAvailable",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.booleanGetter(row),
    },
    {
      headerName: "NIOSH Technician",
      field: "isNIOSHCertifiedTechnicianAvailable",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.booleanGetter(row),
    },

    {
      headerName: "OGUK Examiner",
      field: "isOGUKCertifiedExaminerAvailable",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.booleanGetter(row),
    },

    {
      headerName: "Nor. Sea. Examiner",
      field: "isCertifiedNorwegianSeafarerExaminerAvailable",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.booleanGetter(row),
    },

    {
      headerName: "Injury Capable",
      field: "isInjuryCapable",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.booleanGetter(row),
    },

    {
      headerName: "DA Testing - Regular Hours",
      field: "hasBusinessHoursDATesting",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.booleanGetter(row),
    },

    {
      headerName: "Regular DA: Requires Preschedule",
      field: "requiresBusinessHoursDATestingPreschedule",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.booleanGetter(row),
    },

    {
      headerName: "ME - Regular Hours",
      field: "hasBusinessHoursMedicalTesting",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.booleanGetter(row),
    },
    {
      headerName: "Regular ME: Requires Preschedule",
      field: "requiresBusinessHoursMedicalTestingPreschedule",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.booleanGetter(row),
    },

    {
      headerName: "DA Testing - After Hours",
      field: "hasAfterHoursDATesting",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.booleanGetter(row),
    },

    {
      headerName: "After Hours DA: Requires Preschedule",
      field: "requiresAfterHoursDATestingPreschedule",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.booleanGetter(row),
    },

    {
      headerName: "ME - After Hours",
      field: "hasAfterHoursMedicalTesting",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.booleanGetter(row),
    },
    {
      headerName: "After Hours ME: Requires Preschedule",
      field: "requiresAfterHoursMedicalTestingPreschedule",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.booleanGetter(row),
    },
  ];
  booleanGetter = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    return (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        {row.value ? "Yes" : "No"}
      </span>
    );
  };
  renderCountry = (row) => {
    return (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Iconify
          icon={`circle-flags:${row?.data?.providerCountryShortCode?.toLowerCase()}`}
          sx={{ mr: 1 }}
        />
        {row.value}
      </span>
    );
  };
  renderName = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }

    /**
     * providerAddress
: 
"1101 Nott St, , NY , USA"
providerCity
: 
"Schenectady"
providerCountry
: 
"United States"
providerCountryCallingCode
: 
"1"
providerCountryShortCode
: 
"US"
     */
    const label = `${row.data.providerAddress}, ${row.data.providerCity}, ${row.data.providerState}, ${row.data.providerPostalCode}`;
    return (
      <span
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ImageLoaderForGrid
          apiPrefix="provider"
          value={row.data.providerLogo}
          onLoad={() => {
            this.sizeToFit();
          }}
        />

        <Tooltip title={label} placement="left">
          <Button>{row.value}</Button>
        </Tooltip>
        <IconButton
          fullWidth
          href={`/providers/${row.data._id}`}
          variant="outlined"
        >
          <Iconify icon="mingcute:external-link-line" />
        </IconButton>
      </span>
    );
  };
  renderGoToPage = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    return (
      <span
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          fullWidth
          href={`/providers/${row.data._id}`}
          variant="outlined"
          startIcon={<Iconify icon="mingcute:external-link-line" />}
        ></Button>
      </span>
    );
  };
  renderWebsite = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    if (!row.value) return null;
    return (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Button
          fullWidth
          target="non_blank noopener noreferrer"
          href={row.value}
          variant="outlined"
          startIcon={<Iconify icon="mingcute:external-link-line" />}
        >
          {row.value}
        </Button>
      </span>
    );
  };
  renderSendEmail = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    if (!row.value) return null;
    return (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Button
          fullWidth
          target="non_blank noopener noreferrer"
          href={`mailto:${row.value}`}
          variant="outlined"
          startIcon={<Iconify icon="mingcute:mail-send-line" />}
        >
          {row.value}
        </Button>
      </span>
    );
  };
  renderPhone = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    if (!row.value) return null;
    return (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Button
          fullWidth
          target="non_blank noopener noreferrer"
          href={`tel:${row.value}`}
          variant="outlined"
          startIcon={<Iconify icon="mingcute:phone-outgoing-line" />}
        >
          {row.value}
        </Button>
      </span>
    );
  };
  sizeToFit = () => {
    const allColumnIds = [];
    this.state.gridApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    this.state.gridApi.autoSizeColumns(allColumnIds, false);
  };
  onGridReady = (params) => {
    this.setState({ gridApi: params.api, columnApi: params.columnApi }, () => {
      if (
        this.props.fromCompanyPage &&
        this.props.selectedData &&
        this.props.selectedData.length
      ) {
        console.log("--- on grid ready ---");
        let selectedProvider = new Set(
          this.props.selectedData.map((each) => each.provider._id)
        );
        const nodesToSelect = new Array(selectedProvider.size);
        console.log("--- selectedProvider ---", selectedProvider);
        let index = 0;
        // this.state.gridApi.forEachNode((node) => {
        //   // console.log("--- node ---", node.data._id);
        //   if (node.data && selectedProvider.has(node.data._id)) {
        //     node.setSelected(true, false);
        //     // nodesToSelect.push(node);
        //     nodesToSelect[index] = node;
        //     index++;
        //   }
        // });
        // console.log("--- nodesToSelect ---", nodesToSelect);
        // this.state.gridApi.setNodesSelected({
        //   nodes: nodesToSelect.filter((each) => !!each),
        //   newValue: true,
        // });
      }
    });
  };
  sumAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));

    return _.sum(arr);
  };
  averageAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.mean(arr);
  };
  maxAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.max(arr);
  };
  minAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.min(arr);
  };
  countAggregation = (params) => {
    return params.values.length;
  };
  renderGrid = () => {
    let list = this.state.list;
    if (!list || list.length === 0) {
      return <NoResultView />;
    }
    let cs = "ag-theme-quartz";
    let theme = localStorage.getItem("themeMode");
    if (theme && theme === "dark") {
      cs = "ag-theme-quartz-dark";
    }
    return (
      <div className={cs} style={{ height: "70vh" }}>
        <AgGridReact
          onGridReady={this.onGridReady}
          rowData={this.state.list}
          columnDefs={this.COLUMNS}
          defaultColDef={{
            sortable: true,
          }}
          autoSizeStrategy={{
            type: "fitCellContents",
          }}
          pagination={true}
          rowGroupPanelShow={
            this.state.enableAdvancedFilter ? "always" : "never"
          }
          checkboxSelection={this.props.fromCompanyPage ? true : false}
          groupSelectsChildren={true}
          aggFuncs={this.state.aggregations}
          suppressDragLeaveHidesColumns={true}
          rowSelection="multiple"
          rowDragManaged={true}
          enableCharts={true}
          enableRangeSelection={true}
          enableCellTextSelection={true}
          quickFilterText={this.state.quickSearchText}
          sideBar={this.props.fromCompanyPage ? false : true}
          animateRows={true}
          enableAdvancedFilter={this.state.enableAdvancedFilter}
          onSelectionChanged={() => {
            let rows = this.state.gridApi.getSelectedRows();

            if (!rows || rows.length === 0) {
              this.setState({
                selectedRows: [],
              });
            } else {
              this.setState({
                selectedRows: rows,
              });
            }
          }}
        />
      </div>
    );
  };
  renderHeader = () => {
    if (this.props.networkId) {
      return null;
    }
    if (this.props.fromCompanyPage) {
      return null;
    }
    return (
      <CustomBreadcrumbs
        heading="List of Providers"
        links={[
          { name: "Dashboard", href: "/" },
          { name: "Providers" },
          { name: "List" },
        ]}
        action={
          <Box>
            <Button
              disabled={this.state.isSearching}
              onClick={() => this.setState({ openProviderCreate: true })}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              New Provider
            </Button>
          </Box>
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
    );
  };
  renderIfSelectedRows = () => {
    if (!this.props.fromCompanyPage) {
      return null;
    }
    if (!this.state.selectedRows) {
      return null;
    }
    if (this.state.selectedRows.length === 0) {
      return null;
    }
    return (
      <Stack direction="row" spacing={1}>
        {" "}
        <Button
          variant="contained"
          startIcon={<Iconify icon="mingcute:link-3-line" />}
          onClick={() => {
            this.props.onSelectData(this.state.selectedRows, true);
          }}
        >
          Link {this.state.selectedRows.length}
          {this.state.selectedRows.length === 1
            ? " Provider"
            : " Providers"}{" "}
        </Button>
        <Button
          color="info"
          variant="contained"
          startIcon={<Iconify icon="mingcute:link-3-line" />}
          onClick={() => {
            this.props.onSelectData(this.state.selectedRows, true);
          }}
        >
          Link {this.state.selectedRows.length}
          {this.state.selectedRows.length === 1 ? " Provider" : " Providers"} as
          Preferred{" "}
          {this.state.selectedRows.length === 1 ? " Provider" : " Providers"}
        </Button>
      </Stack>
    );
  };
  renderControlBox = () => {
    let list = this.state.list;
    if (!list) return null;
    if (list.length === 0) return null;
    return (
      <Stack direction="row" spacing={1}>
        <TextField
          disabled={this.state.isSearching}
          value={this.state.quickSearchText}
          label="Quick Search"
          onChange={(e) =>
            this.setState({
              quickSearchText: e.target.value,
            })
          }
        />
        <Button
          disabled={this.state.isSearching}
          variant="contained"
          startIcon={<Iconify icon="mingcute:filter-line" />}
          onClick={() => {
            this.setState({
              enableAdvancedFilter: !this.state.enableAdvancedFilter,
            });
          }}
        >
          {this.state.enableAdvancedFilter
            ? "Advanced Filters"
            : "Simple Filters"}
        </Button>{" "}
        <LoadingButton
          loading={this.state.isSearching}
          onClick={() => this.search()}
          variant="contained"
          startIcon={<Iconify icon="mingcute:refresh-anticlockwise-1-line" />}
        >
          Refresh Data
        </LoadingButton>
        {this.renderIfSelectedRows()}
      </Stack>
    );
  };
  render() {
    if (this.state.isSearching) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <div>
        <ProviderCreate
          isOpen={this.state.openProviderCreate}
          handleClose={() => {
            this.setState({ openProviderCreate: false });
          }}
          onCreate={(provider) => {
            this.setState({
              list: [provider, ...this.state.list],
              openProviderCreate: false,
            });
            window.open(`/providers/${provider._id}`, "non_blank");
          }}
        />
        <Stack spacing={3}>
          {this.renderHeader()}
          {this.renderControlBox()}
          {this.renderGrid()}
        </Stack>
      </div>
    );
  }
}
export default WithAPICall(ListProviders);
