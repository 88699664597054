import {
  Badge,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import Iconify from "src/components/iconify";
import AudioBox from "./AudioBox";
import cogoToast from "cogo-toast";
import AILoader from "./AILoader";
import { WithAPICall } from "./apiUtil";

class DictaBox extends React.Component {
  state = {
    isLoading: true,
    text: "",
    isAudio: false,
    isCleaningUp: false,
    cleanedUpTranscript: "",
  };
  componentDidMount() {
    this.setState({
      text: this.props.text,
      isLoading: false,
    });
  }
  cleanUp = async () => {
    this.setState({
      isCleaningUp: true,
    });
    try {
      await this.props.apiCallPostStreamingForAI(
        "/external/audio/cleanUpTranscript",
        { text: this.state.text, stream: true },
        (data) => {
          this.setState({
            cleanedUpTranscript: this.state.cleanedUpTranscript + data,
          });
        },
        () => {
          this.onCleanUpEnd();
        }
      );
    } catch (err) {
      cogoToast.error("Failed to clean up transcript.");
      console.log(err);
      this.props.onAction(this.state.text);
    }
  };
  onCleanUpEnd = async () => {
    // wait 1 second
    await new Promise((resolve) => setTimeout(resolve, 1000));
    this.setState(
      {
        isCleaningUp: false,
        text: this.state.cleanedUpTranscript,
        cleanedUpTranscript: "",
      },
      () => {
        this.props.onAction(this.state.text);
      }
    );
  };
  renderTextArea = () => {
    if (this.state.isCleaningUp) {
      return (
        <Paper
          sx={{
            padding: 2,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "background.neutral",
          }}
        >
          <Stack spacing={2}>
            <Typography variant="overline">Harley</Typography>
            <AILoader />
            <Typography variant="caption">
              {this.state.cleanedUpTranscript}
            </Typography>
          </Stack>
        </Paper>
      );
    }
    if (this.props.disabled) {
      return (
        <Paper
          sx={{
            padding: 2,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "background.neutral",
          }}
        >
          <Stack spacing={2}>
            <Typography variant="overline">Harley</Typography>
            <AILoader />
            <LinearProgress />
          </Stack>
        </Paper>
      );
    }
    if (this.state.isAudio) {
      return (
        <Paper
          sx={{
            padding: 2,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "background.neutral",
          }}
        >
          <AudioBox
            entityType={this.props.entityType}
            entityId={this.props.entityId}
            threadId={this.props.threadId}
            onError={() => {
              this.setState({ isAudio: false });
            }}
            onTranscription={(transcription) => {
              console.log({ transcription });
              this.setState({ text: transcription, isAudio: false }, () => {
                this.cleanUp();
              });
            }}
          />
        </Paper>
      );
    }
    return (
      <TextField
        InputLabelProps={{
          shrink: this.state.text,
        }}
        disabled={this.state.isAddingMessage || this.props.disabled}
        fullWidth
        multiline
        minRows={4}
        label={this.props.label || "Type a message"}
        value={this.state.text}
        onChange={(e) => {
          this.setState({ text: e.target.value });
        }}
      />
    );
  };
  renderAudioButton = () => {
    return (
      <Box>
        <IconButton
          disabled={this.state.isAddingMessage || this.props.disabled}
          onClick={() => {
            this.setState({
              isAudio: !this.state.isAudio,
            });
          }}
        >
          <Iconify
            icon={!this.state.isAudio ? "bi:mic-fill" : "gridicons:cross"}
          />
        </IconButton>
      </Box>
    );
  };
  renderSendButton = () => {
    if (this.state.isAudio) {
      return null;
    }
    if (this.props.disabled) {
      return (
        <Box>
          <IconButton
            sx={{
              bgcolor: "primary.main",
              color: "primary.contrastText",
              "&:hover": {
                bgcolor: "primary.dark",
              },
            }}
          >
            <Iconify icon={"svg-spinners:blocks-wave"} />
          </IconButton>
        </Box>
      );
    }

    return (
      <Box>
        <IconButton
          sx={{
            bgcolor: "primary.main",
            color: "primary.contrastText",
            "&:hover": {
              bgcolor: "primary.dark",
            },
          }}
          disabled={!this.state.text || this.props.disabled}
          onClick={() => {
            this.props.onAction(this.state.text);
          }}
        >
          <Iconify icon={"guidance:send"} />
        </IconButton>
      </Box>
    );
  };
  renderCleanUpButton = () => {
    if (this.state.isAudio) {
      return null;
    }
    if (this.props.disabled) {
      return null;
    }
    return (
      <Box>
        <IconButton
          disabled={this.props.disabled || !this.state.text}
          onClick={() => {
            this.cleanUp();
          }}
        >
          <Iconify icon={"mdi:magic"} />
        </IconButton>
      </Box>
    );
  };

  renderButtons = () => {
    if (this.props.disabled) {
      return (
        <Stack>
          <Iconify icon={"svg-spinners:blocks-wave"} />
        </Stack>
      );
    }
    return (
      <Stack>
        {this.renderAudioButton()}
        {this.renderCleanUpButton()}
        {this.renderSendButton()}
      </Stack>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <Stack direction="row" spacing={0.5}>
        {this.renderTextArea()}
        {this.renderButtons()}
      </Stack>
    );
  }
}

export default WithAPICall(DictaBox);
