// 'use client';
import { Typography } from "@mui/material";
import { BarList, LineChart } from "@tremor/react";
import { groupBy } from "lodash";
import { useContext, useEffect, useState } from "react";
import { WithAPICall } from "../utils/apiUtil";
import HOCContext from "./contexts/HOCContext";

const valueFormatter = (number) =>
  `${Intl.NumberFormat("us").format(number).toString()}`;

export default WithAPICall(function CompanyVisits({ apiCallPost }) {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const { from, to, companyIds } = useContext(HOCContext);

  const customTooltip = (props) => {
    const { payload, active } = props;
    if (!active || !payload) return null;
    return (
      <div className="rounded-tremor-default border border-tremor-border bg-tremor-background p-2 text-tremor-default shadow-tremor-dropdown">
        {payload.map((category, idx) => (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            />
            <div
              className="space-y-1"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <p className="text-tremor-content">{category.dataKey}</p>
              <p className="font-medium text-tremor-content-emphasis">
                {category.value}
              </p>
            </div>
          </>
        ))}
      </div>
    );
  };

  useEffect(() => {
    const init = async () => {
      const massagedData = [];
      let categories = [];
      try {
        const res = await apiCallPost("/dashboard/visitActivityByCompanyDate", {
          from: from.toDate(),
          to: to.toDate(),
          companyIds,
        });
        for (const date of Array.from(Object.keys(res))) {
          let total = 0;
          const dayDate = {
            date,
          };
          for (const company of Array.from(Object.keys(res[date]))) {
            if (!categories.includes(company)) categories.push(company);
            dayDate[company] = res[date][company].count;
            total += res[date][company].count;
          }
          dayDate.Total = total;
          massagedData.push(dayDate);
        }
        for (const category of categories) {
          for (const dayDate of massagedData) {
            if (!(category in dayDate)) dayDate[category] = 0;
          }
        }
      } catch (error) {
        console.error("could not get data", error);
      }
      setData(massagedData);
      setCategories(categories);
    };
    init();
  }, [from, to, companyIds]);
  return (
    <>
      <LineChart
        data={data}
        index="date"
        categories={categories}
        colors={[
          "red",
          "orange",
          "yellow",
          "cyan",
          "violet",
          "rose",
          "purple",
          "teal",
          "emerald",
          "neutral",
        ]}
        valueFormatter={valueFormatter}
        showLegend={false}
        showYAxis={false}
        startEndOnly={true}
        className="mt-6 h-32"
        customTooltip={customTooltip}
      />
      {/* <Typography variant="subtitle2" sx={{ mb: 1 }}>
        Top 4 companies
      </Typography>
      <BarList data={country} valueFormatter={valueFormatter} /> */}
    </>
  );
});
