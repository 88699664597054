import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import _ from "lodash";
import cogoToast from "cogo-toast";
import {
  Button,
  Chip,
  LinearProgress,
  ListItemText,
  Popover,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { View500 } from "src/sections/error";
import { Box, Stack } from "@mui/system";
import { niceDate } from "../utils/fn";
import EmployeeCSFDataField from "./EmployeeCSFDataField";
import EmployeeCSFNLDataField from "./EmployeeCSFNLDataField";
import { DayPicker } from "react-day-picker";
import { LoadingButton } from "@mui/lab";
import EmployeeTransferProtocol from "./EmployeeTransferProtocol";

import Iconify from "src/components/iconify";

class EmployeeTransfer extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    listOfFields: [],
    csf: [],
    nonLookUpCSFs: [],
    lookUpCSFs: [],
    changedKeys: {},
    newValues: {},
    isCheckingProtocols: false,
    isCheckingExitProtocols: false,
    hasCheckedProtocols: false,
    hasCheckedExitProtocols: false,
    exitProtocols: [],
    preplacementProtocols: [],
    showPropertyTypes: ["CSF"],
    hasSetTask: false,
    isCreatingTask: false,
  };
  LIST_OF_RELEVANT_ROOT_FIELDS = [
    {
      label: "Needs Respirator Fit Test",
      id: "needsRespiratorFitTest",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Respirable Crystalline Silica",
      id: "hasRespirableCrystallineSilicaExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Physical Exam Data Capture",
      id: "hasPhysicalExamDataCaptureExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Methylene Chloride",
      id: "hasMethyleneChlorideExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Lead",
      id: "hasLeadExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Inorganic Arsenic",
      id: "hasInorganicArsenicExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "First Exposure To Arsenic",
      id: "arsenicExposureInitialDate",
      type: "DATE",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Hazwoper",
      id: "hasHazwoperExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Formaldehyde",
      id: "hasFormaldehydeExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Chromium (VI) Hexavalent",
      id: "hasChromiumVIHexavalentExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Cadmium",
      id: "hasCadmiumExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Bloodborne Pathogen",
      id: "hasBloodbornePathogenExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Beryllium",
      id: "hasBerylliumExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Benzene",
      id: "hasBenzeneExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "Exposure to Asbestos",
      id: "hasAsbestosExposure",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
    {
      label: "First Exposure To Asbestos",
      id: "asbestosExposureInitialDate",
      type: "DATE",
      from: "ROOT",
      hasChanged: false,
    },

    {
      label: "DOT Status",
      id: "dotStatus",
      type: "SWITCH",
      from: "ROOT",
      hasChanged: false,
    },
  ];
  componentDidMount() {
    this.getCSFDefinitions();
  }
  getCSFDefinitions = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost("/company/csf/get", {
        companyId: this.props.companyId,
      });
      let nonLookUpCSFs = _.filter(res, (each) => !each.needsLookUp);
      let lookUpCSFs = _.filter(res, (each) => each.needsLookUp);
      this.setState(
        {
          csf: res,
          nonLookUpCSFs: nonLookUpCSFs,
          lookUpCSFs: lookUpCSFs,
        },
        () => {
          this.getEmployeeDataById();
        }
      );
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Employee Specific Fields");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  getEmployeeDataById = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost("/employee/getById", {
        _id: this.props.id,
      });
      this.setState({ ...res, newValues: res }, () => {
        this.populateListOfFieldsInState();
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Employee Data");
      this.setState({
        isError: true,
      });
    }
  };
  populateListOfFieldsInState = () => {
    let listOfFields = [];
    listOfFields = [...this.LIST_OF_RELEVANT_ROOT_FIELDS];
    for (var i = 0; i < this.state.lookUpCSFs.length; i++) {
      let each = this.state.lookUpCSFs[i];
      let field = {
        label: each.fieldName,
        id: each._id,
        data: each,
        from: "CSF",
        hasChanged: false,
      };
      listOfFields.push(field);
    }

    for (var i = 0; i < this.state.nonLookUpCSFs.length; i++) {
      let each = this.state.nonLookUpCSFs[i];
      let field = {
        label: each.fieldName,
        id: each._id,
        data: each,
        from: "ESF",
        hasChanged: false,
      };
      listOfFields.push(field);
    }
    this.setState({
      listOfFields: listOfFields,
      isLoading: false,
    });
  };
  createTaskForEmployee = async () => {
    try {
      this.setState({
        isCreatingTask: true,
      });
      let protocols = _.uniqBy(
        [...this.state.exitProtocols, ...this.state.preplacementProtocols],
        "_id"
      );
      if (protocols.length === 0) {
        cogoToast.error("No Protocols Selected. Cannot Create Task");
        this.setState({
          isCreatingTask: false,
        });
        return;
      }
      let res = await this.props.apiCallPost(
        "/ext-admin/createCandidateScreenRequest",
        {
          companyId: this.props.companyId,
          employeeId: this.props.id,
          taskMetadata: {
            protocols: _.uniqBy(
              [
                ...this.state.exitProtocols,
                ...this.state.preplacementProtocols,
              ],
              "_id"
            ),
          },
        }
      );
      cogoToast.success("Task Created");
      this.setState({
        isCreatingTask: false,
        hasSetTask: true,
        taskId: res._id,
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Creating Task");
      this.setState({
        isCreatingTask: false,
      });
    }
  };
  renderTable = () => {
    let lf = this.state.listOfFields;
    if (!lf.length) return null;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Parameter</TableCell> <TableCell>Current</TableCell>
            <TableCell>Proposed Change</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lf.map((eachField, index) => {
            let sx = {};
            if (eachField.hasChanged) {
              sx = { backgroundColor: "rgba(0,0,0,0.1)" };
            }
            let from = eachField.from;
            if (!this.state.showPropertyTypes.includes(from)) {
              return null;
            }
            return (
              <TableRow sx={sx}>
                <TableCell>{this.renderFieldNameTag(eachField)}</TableCell>{" "}
                <TableCell>
                  {this.renderCurrentValueOfField(eachField)}
                </TableCell>{" "}
                <TableCell>
                  {this.renderProposedValueOfField(eachField, index)}
                </TableCell>
              </TableRow>
            );
          })}
          {this.renderSummaryOfChanges()}
          {this.renderCheckedProtocols()}
        </TableBody>
      </Table>
    );
  };
  renderSummaryOfChanges = () => {
    let modSpec = this.massageChanges();
    if (_.isEmpty(modSpec)) {
      return null;
    }
    let lf = this.state.listOfFields;
    let changedFields = _.filter(lf, (x) => x.hasChanged);
    let changeSummary = [];
    for (var i = 0; i < changedFields.length; i++) {
      let f = changedFields[i];
      if (f.from === "ROOT") {
        let obj = {
          changeField: f.label,
          type: f.type,
          oldValue: this.state[f.id],
          newValue: this.state.newValues[f.id],
        };
        changeSummary.push(obj);
      } else if (f.from === "CSF") {
      }
    }
  };
  renderCheckedProtocols = () => {
    let exitProtocols = this.state.exitProtocols;
    let preplacementProtocols = this.state.preplacementProtocols;
    let exitProtocolsLength = exitProtocols.length;
    let preplacementProtocolsLength = preplacementProtocols.length;
    let totalProtocols = exitProtocolsLength + preplacementProtocolsLength;
    if (
      !(this.state.hasCheckedProtocols && this.state.hasCheckedExitProtocols)
    ) {
      return null;
    }

    return (
      <TableRow>
        <TableCell>
          <Stack alignItems={"flex-start"}>
            <Typography variant="overline">Protocols Applicable</Typography>
            <Typography variant="button">
              Total: {totalProtocols}
            </Typography>{" "}
          </Stack>
        </TableCell>{" "}
        <TableCell>
          <Stack spacing={1} alignItems={"flex-start"}>
            <Typography variant="overline">
              Exit Protocols Applicable: {exitProtocolsLength}
            </Typography>
            {exitProtocols.map((each, i) => {
              return (
                <EmployeeTransferProtocol
                  key={i}
                  data={each}
                  modSpec={this.massageChanges()}
                  protocolType="Exit"
                  employeeId={this.props.id}
                  onCheckServiceCriteria={(protocolIsIgnorable) => {
                    let pp = this.state.exitProtocols;
                    pp[i].isIgnoreable = protocolIsIgnorable;
                    this.setState({
                      exitProtocols: pp,
                    });
                  }}
                />
              );
            })}
          </Stack>
        </TableCell>{" "}
        <TableCell>
          <Stack spacing={1} alignItems={"flex-start"}>
            {" "}
            <Typography variant="overline">
              Preplacement Protocols Applicable: {preplacementProtocolsLength}
            </Typography>
            {preplacementProtocols.map((each, i) => {
              return (
                <EmployeeTransferProtocol
                  key={i}
                  data={each}
                  modSpec={this.massageChanges()}
                  protocolType="Preplacement"
                  employeeId={this.props.id}
                  onCheckServiceCriteria={(protocolIsIgnorable) => {
                    let pp = this.state.preplacementProtocols;
                    pp[i].isIgnoreable = protocolIsIgnorable;
                    this.setState({
                      preplacementProtocols: pp,
                    });
                  }}
                />
              );
            })}
          </Stack>
        </TableCell>
      </TableRow>
    );
  };
  renderFieldNameTag = (eachField) => {
    let helper = "Core";
    if (eachField.from === "ESF") {
      helper = "Employee Specific";
    }
    if (eachField.from === "CSF") {
      helper = "Linked To Database";
    }

    return (
      <Stack>
        {" "}
        {/*   <Typography variant="overline">{helper}</Typography>
         */}{" "}
        <Typography variant="button">{eachField.label}</Typography>{" "}
      </Stack>
    );
  };
  renderCurrentValueOfField = (eachField) => {
    if (eachField.from === "ROOT") {
      return this.renderCurrentRootField(eachField);
    }
    if (eachField.from === "CSF") {
      return (
        <EmployeeCSFDataField
          fromTransferPage={true}
          allowEdit={false}
          employeeData={this.state}
          data={eachField.data}
          companyId={this.props.companyId}
          employeeId={this.props.id}
        />
      );
    }
    if (eachField.from === "ESF") {
      return (
        <EmployeeCSFNLDataField
          fromTransferPage={true}
          allowEdit={false}
          employeeData={this.state}
          data={eachField.data}
          companyId={this.props.companyId}
          employeeId={this.props.id}
        />
      );
    }
  };
  renderProposedValueOfField = (eachField, index) => {
    if (eachField.from === "ROOT") {
      return this.renderProposedRootField(eachField, index);
    }
    if (eachField.from === "CSF") {
      return (
        <EmployeeCSFDataField
          onSetCSF={(e) => {
            let newValues = this.state.newValues;
            let esk = eachField.data.employeeSourceKey;
            newValues[esk] = e;
            let hasChanged = false;
            if (e !== this.state[esk]) {
              hasChanged = true;
            }
            let list = this.state.listOfFields;
            list[index].hasChanged = hasChanged;
            this.setState({
              newValues,
              listOfFields: list,
              hasCheckedProtocols: false,
              hasCheckedExitProtocols: false,
            });
          }}
          fromTransferPage={true}
          allowEdit={true}
          employeeData={this.state}
          data={eachField.data}
          companyId={this.props.companyId}
          employeeId={this.props.id}
        />
      );
    }
    if (eachField.from === "ESF") {
      return (
        <EmployeeCSFNLDataField
          onSetESF={(k, v) => {
            let newValues = _.cloneDeep(this.state.newValues);

            let esfObject = newValues.esfObject;
            let currentObject = _.cloneDeep(
              esfObject[eachField.data.fieldName]
            );

            currentObject[k] = v;
            let originalObject = this.state.esfObject[eachField.data.fieldName];
            esfObject[eachField.data.fieldName] = currentObject;
            newValues.esfObject = esfObject;

            let hasChanged = !_.isEqual(currentObject, originalObject);
            let list = this.state.listOfFields;
            list[index].hasChanged = hasChanged;

            this.setState({
              newValues,
              listOfFields: list,
              hasCheckedProtocols: false,
              hasCheckedExitProtocols: false,
            });
          }}
          fromTransferPage={true}
          allowEdit={true}
          employeeData={this.state}
          data={eachField.data}
          companyId={this.props.companyId}
          employeeId={this.props.id}
        />
      );
    }
  };
  renderCurrentRootField = (eachField) => {
    let value = this.state[eachField.id];
    let type = eachField.type;
    return this.renderCurrentPrimitiveType(type, value);
  };
  renderProposedRootField = (eachField, index) => {
    return this.renderProposedPrimitiveType(eachField, index);
  };
  renderCurrentPrimitiveType = (type, value) => {
    if (type === "SWITCH") {
      if (value) {
        return "Yes";
      } else {
        return "No";
      }
    }
    if (type === "DATE") {
      if (value) {
        return niceDate(value);
      } else {
        return "Not Entered";
      }
    }
  };
  renderProposedPrimitiveType = (eachField, index) => {
    let type = eachField.type;
    let originalValue = this.state[eachField.id];
    let currentValue = this.state.newValues[eachField.id];
    if (type === "SWITCH") {
      return (
        <Switch
          checked={currentValue}
          onChange={() => {
            let newValues = this.state.newValues;
            newValues[eachField.id] = !newValues[eachField.id];
            let hasChanged = false;
            if (newValues[eachField.id] !== originalValue) {
              hasChanged = true;
            }
            let list = this.state.listOfFields;
            list[index].hasChanged = hasChanged;
            this.setState({
              newValues,
              listOfFields: list,
              hasCheckedProtocols: false,
              hasCheckedExitProtocols: false,
            });
          }}
        />
      );
    }
    if (type === "DATE") {
      return (
        <>
          <Chip
            variant="contained"
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                [`open_${eachField.id}_${eachField.type}_${eachField.from}`]: true,
              });
            }}
            label={currentValue ? niceDate(currentValue) : "Not Entered"}
          />
          <Popover
            open={
              this.state[
                `open_${eachField.id}_${eachField.type}_${eachField.from}`
              ]
            }
            anchorEl={this.state.anchorEl}
            onClose={() => {
              this.setState({
                anchorEl: null,
                [`open_${eachField.id}_${eachField.type}_${eachField.from}`]: false,
              });
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <DayPicker
              mode="single"
              captionLayout="dropdown-buttons"
              fromYear={1901}
              toYear={2035}
              onSelect={(e) => {
                let newValues = this.state.newValues;
                newValues[eachField.id] = e;
                let hasChanged = false;
                if (e !== originalValue) {
                  hasChanged = true;
                }
                let list = this.state.listOfFields;
                list[index].hasChanged = hasChanged;
                this.setState({
                  newValues,
                  anchorEl: null,
                  [`open_${eachField.id}_${eachField.type}_${eachField.from}`]: false,
                  listOfFields: list,
                  hasCheckedProtocols: false,
                  hasCheckedExitProtocols: false,
                });
              }}
            />
          </Popover>
        </>
      );
    }
  };
  massageChanges = () => {
    let modSpec = {};
    for (var i = 0; i < this.state.listOfFields.length; i++) {
      let each = this.state.listOfFields[i];
      let hasChanged = each.hasChanged;
      if (hasChanged) {
        let from = each.from;
        if (from === "ROOT") {
          modSpec[each.id] = this.state.newValues[each.id];
        }
        if (from === "CSF") {
          let esk = each.data.employeeSourceKey;

          modSpec[esk] = this.state.newValues[esk];
        }
        if (from === "ESF") {
          let esfObjectInNew = this.state.newValues.esfObject;
          let currentObject = esfObjectInNew[each.data.fieldName];
          let keys = Object.keys(currentObject);
          for (var j = 0; j < keys.length; j++) {
            let k = keys[j];
            let v = currentObject[k];
            let originalObject = _.cloneDeep(
              this.state.esfObject[each.data.fieldName]
            );
            let originalValue = originalObject[k];
            if (v !== originalValue) {
              modSpec[`esfObject.${each.data.fieldName}.${k}`] = v;
            }
          }
        }
      }
    }
    return modSpec;
  };
  doesModifiedEmployeeSatisfyAnyProtocols = async (modSpec) => {
    try {
      this.setState({
        isCheckingProtocols: true,
        hasCheckedProtocols: false,
        preplacementProtocols: [],
      });
      let res = await this.props.apiCallPost(
        "/employee/doesModifiedEmployeeSatisfyAnyProtocols",
        {
          employeeId: this.props.id,
          companyProtocolType: "Preplacement",
          modSpec: modSpec,
        }
      );
      this.setState({
        preplacementProtocols: res,
        hasCheckedProtocols: true,
        isCheckingProtocols: false,
      });
    } catch (err) {
      this.setState({
        isCheckingProtocols: false,
        hasCheckedProtocols: false,
        preplacementProtocols: [],
      });
      console.log(err);
      cogoToast.error("Error Checking Against Preplacement Protocols");
    }
  };
  doesModifiedEmployeeSatisfyAnyExitProtocols = async (modSpec) => {
    try {
      this.setState({
        isCheckingExitProtocols: true,
        hasCheckedExitProtocols: false,
        exitProtocols: [],
      });
      let res = await this.props.apiCallPost(
        "/employee/doesModifiedEmployeeSatisfyAnyExitProtocols",
        {
          employeeId: this.props.id,
          modSpec: modSpec,
        }
      );

      this.setState({
        exitProtocols: res,
        hasCheckedExitProtocols: true,
        isCheckingExitProtocols: false,
      });
    } catch (err) {
      this.setState({
        isCheckingProtocols: false,
        hasCheckedExitProtocols: false,
        isCheckingExitProtocols: [],
      });
      console.log(err);
      cogoToast.error("Error Checking Against Exit Protocols");
    }
  };
  renderToggleButtonGroup = () => {
    return (
      <ToggleButtonGroup
        value={this.state.showPropertyTypes}
        onChange={(_, fmts) => {
          this.setState({
            showPropertyTypes: fmts,
          });
        }}
        color="primary"
      >
        <ToggleButton value="ROOT" variant="contained">
          Core Properties
        </ToggleButton>{" "}
        <ToggleButton value="CSF" variant="contained">
          Look Up Fields
        </ToggleButton>{" "}
        <ToggleButton value="ESF" variant="contained">
          Employee Specific Fields
        </ToggleButton>
      </ToggleButtonGroup>
    );
  };
  renderCheckProtocols = () => {
    let modSpec = this.massageChanges();
    //   options: ["Preplacement", "Periodic", "Exit", "Targeted"],
    let isEmptyModSpec = _.isEmpty(modSpec);
    return (
      <LoadingButton
        disabled={isEmptyModSpec || this.state.hasSetTask}
        variant="contained"
        loading={
          this.state.isCheckingProtocols || this.state.isCheckingExitProtocols
        }
        onClick={() => {
          this.doesModifiedEmployeeSatisfyAnyProtocols(modSpec);
          this.doesModifiedEmployeeSatisfyAnyExitProtocols(modSpec);
        }}
      >
        Check Against Protocols
      </LoadingButton>
    );
  };
  renderButtonToSetTask = () => {
    if (!this.state.hasCheckedProtocols) {
      return null;
    }

    let exitProtocols = this.state.exitProtocols;
    let preplacementProtocols = this.state.preplacementProtocols;
    let exitProtocolsLength = exitProtocols.length;
    let preplacementProtocolsLength = preplacementProtocols.length;
    let totalProtocols = exitProtocolsLength + preplacementProtocolsLength;
    if (totalProtocols === 0) {
      return (
        <Button variant="contained" color="success">
          No Tests Required. Transfer Employee Directly.
        </Button>
      );
    } else {
      // check if all protocols are ignorable.
      let allProtocolsAreIgnorable = true;
      let ignoreableCount = 0;
      let dataRecdCount = 0;
      for (var i = 0; i < exitProtocols.length; i++) {
        if (exitProtocols[i].isIgnoreable === true) {
          ignoreableCount++;
          dataRecdCount++;
        } else if (exitProtocols[i].isIgnoreable === false) {
          dataRecdCount++;
        }
      }
      for (var i = 0; i < preplacementProtocols.length; i++) {
        if (preplacementProtocols[i].isIgnoreable === true) {
          ignoreableCount++;
          dataRecdCount++;
        } else if (preplacementProtocols[i].isIgnoreable === false) {
          dataRecdCount++;
        }
      }
      if (dataRecdCount < totalProtocols) {
        return (
          <LoadingButton
            variant="contained"
            color="info"
            loading
            loadingPosition="start"
          >
            Checking Service Conditions Under Protocol
          </LoadingButton>
        );
      }
      let totalApplicable = totalProtocols - ignoreableCount;
      if (totalApplicable > 0) {
        allProtocolsAreIgnorable = false;
      }
      if (allProtocolsAreIgnorable) {
        if (this.state.hasSetTask) {
          return (
            <Stack spacing={2}>
              <Chip label="No Test Required." color="success" />
              <Button variant="contained" onClick={() => this.props.onReset()}>
                Start Over For Another Employee.
              </Button>
            </Stack>
          );
        }
        return (
          <Button variant="contained" color="success">
            No Tests Required. Transfer Employee Directly.
          </Button>
        );
      }

      // subtract ignoreable from total.

      let txt = totalApplicable === 1 ? "Protocol" : "Protocols";
      if (this.state.hasSetTask) {
        let id = this.state.taskId;
        return (
          <Stack spacing={2}>
            <Chip label="Visit Scheduling Request Received." color="success" />
            <Chip
              onClick={() => {
                navigator.clipboard.writeText(id);
                cogoToast.info("Request ID Copied to Clipboard");
              }}
              label={`Request ID: ${id} `}
              color="info"
              icon={<Iconify icon="solar:copy-broken" />}
            />
            <Button variant="contained" onClick={() => this.props.onReset()}>
              Start Over
            </Button>
          </Stack>
        );
      }
      return (
        <LoadingButton
          variant="contained"
          color="secondary"
          loading={this.state.isCreatingTask}
          onClick={() => {
            console.log("Set Task", this.state);
            this.createTaskForEmployee();
          }}
        >
          {totalApplicable} {txt} Applicable. Set Task.
        </LoadingButton>
      );
    }
  };
  renderHeader = () => {
    return (
      <Stack spacing={3}>
        <ListItemText
          sx={{
            p: 3,
            borderRadius: 2,
            bgcolor: "background.neutral",
          }}
          primary={
            <Stack direction="row" alignItems="center" spacing={3}>
              <Typography variant="h6">Transfer An Employee</Typography>
              {this.renderToggleButtonGroup()}
            </Stack>
          }
          secondary="The left column has the current information of the employee. Make proposed changes in the right column. After making changes, click the button to check against protocols."
          primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
          secondaryTypographyProps={{ component: "span" }}
        />
      </Stack>
    );
  };
  render() {
    if (this.state.isError) {
      return <View500 />;
    }
    if (this.state.isLoading) {
      return <LinearProgress />;
    }

    return (
      <Stack spacing={2}>
        {this.renderHeader()}
        {this.renderTable()}
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
          }}
        >
          {this.renderCheckProtocols()}
          {this.renderButtonToSetTask()}
        </Box>
      </Stack>
    );
  }
}

export default WithAPICall(EmployeeTransfer);
