import "src/utils/highlight";
import ReactQuill from "react-quill";

import { alpha } from "@mui/material/styles";

import { StyledEditor } from "./styles";
import Toolbar, { formats } from "./toolbar";

// ----------------------------------------------------------------------

export default function Editor({
  noteId,
  value,
  onChange,
  onEditorBlur,
  error,
  simple = false,
  helperText,
  sx,
  ...other
}) {
  const modules = {
    toolbar: {
      container: `#${noteId}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: true,
    clipboard: {
      matchVisual: false,
    },
  };
  return (
    <>
      <StyledEditor
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
            "& .ql-editor": {
              bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
            },
          }),
          ...sx,
        }}
      >
        <Toolbar id={noteId} simple={false} />
        <ReactQuill
          modules={modules}
          formats={formats}
          placeholder="You can type here."
          value={value}
          onChange={onChange}
          onBlur={(_, __, quill) => {
            let x = quill.getHTML();
            onEditorBlur(x);
          }}
          {...other}
        />
      </StyledEditor>

      {helperText && helperText}
    </>
  );
}
