import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import cogoToast from "cogo-toast";
import { Alert, Card, LinearProgress, Typography } from "@mui/material";
import { View500 } from "src/sections/error";
import _ from "lodash";
import { Stack } from "@mui/system";
import VisitServiceDataEach from "./VisitServiceDataEach";

class VisitServicesData extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    bookedServices: [],
    bookedProtocolIds: [],
    companyProtocols: [],
    groups: [],
    serviceDetails: [],
  };
  componentDidMount() {
    this.setState(
      {
        ...this.props.visitData,
      },
      () => {
        this.getServiceDetails();
      }
    );
  }
  getServiceDetails = async () => {
    let protocolData = this.props.protocolData;
    let protocolId = protocolData._id;
    let services = _.filter(
      this.props.visitData.servicesInfo,
      (x) => this.props.visitData.serviceParentProtocols[x._id] === protocolId
    );

    try {
      this.setState(
        {
          serviceDetails: services,
        },
        () => this.getGroupsByVisit()
      );
      // let res = await this.props.apiCallPost(
      //   "/master-service/getMasterServiceById",
      //   {
      //     masterServiceId: services,
      //   }
      // );
      // this.setState(
      //   {
      //     serviceDetails: res,
      //   },
      //   () => {
      //     this.getGroupsByVisit();
      //   }
      // );
    } catch (err) {
      cogoToast.error("Error Loading Service Data");
      console.log(err);
      this.setState({
        isError: true,
        isLoading: false,
      });
    }
  };
  getGroupsByVisit = async () => {
    try {
      let res = await this.props.apiCallPost("/files/groups/search", {
        visitId: this.props.id,
      });
      this.setState({
        groups: res,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      cogoToast.error("Error getting data");
      this.setState({
        isError: true,
        isLoading: false,
      });
    }
  };
  renderByService = () => {
    let serviceDetails = this.state.serviceDetails;
    if (!serviceDetails || serviceDetails.length === 0) {
      return <Alert severity="error">No services found.</Alert>;
    }
    return (
      <Stack spacing={2}>
        <Typography variant="subtitle2">Protocol Services</Typography>
        {serviceDetails.map((each) => {
          let serviceId = each._id;
          let bs = each;
          return (
            <Card
              sx={{
                p: 2,
                boxShadow: 3,
              }}
            >
              <VisitServiceDataEach
                serviceData={each}
                groups={this.state.groups}
                visitData={this.props.visitData}
                bookedService={bs}
                isDotProtocol={this.props.protocolData.isDot}
                key={`${each._id}_${this.state.groups.length}`}
              />
            </Card>
          );
        })}
      </Stack>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return <Stack spacing={2}>{this.renderByService()}</Stack>;
  }
}

export default WithAPICall(VisitServicesData);
