import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Alert,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import moment from "moment";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import Iconify from "src/components/iconify";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import useDocumentStore from "../../store/useDocumentStore";
import { useProtectedApi } from "@/hooks/use-apiCall";
import { Stack } from "@mui/system";
import cogoToast from "cogo-toast";

export function DPS2SplitByEE() {
  const { callApi } = useProtectedApi();
  const { document, loadDocument } = useDocumentStore();

  const [employeesData, setEmployeesData] = useState(
    document.extractedEEInfo || []
  );
  const [editedFields, setEditedFields] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const addEmployee = () => {
    const newEmployee = {
      id: Date.now().toString(),
      employeeFirstName: "",
      employeeMiddleName: "",
      employeeLastName: "",
      employeeSSN: "",
      employerName: "",
      employeeDoB: null,
      firstPage: "",
      lastPage: "",
    };
    setEmployeesData([...employeesData, newEmployee]);
  };
  const updateEmployee = (employeeIndex, field, value) => {
    const newData = [...employeesData];
    newData[employeeIndex][field] = value;
    setEmployeesData(newData);
    setEditedFields({ ...editedFields, [`${employeeIndex}`]: true });
  };
  const removeEmployee = (employeeIndex) => {
    const newEmployeesData = employeesData.filter(
      (_, index) => index !== employeeIndex
    );
    setEmployeesData(newEmployeesData);
  };

  const handleSaveData = async () => {
    try {
      setIsSaving(true);
      cogoToast.loading("Saving data...");
      const resp = await callApi("/datafiles/updateScalars", {
        dataFileId: document._id,
        uops: [
          {
            key: "extractedEEInfo",
            value: employeesData,
          },
        ],
      });
      cogoToast.success("Data saved successfully!");
      setEditedFields({});
      loadDocument(resp);
    } catch (err) {
      cogoToast.error("Error saving data.");
      console.error("Error saving data:", err);
    } finally {
      setIsSaving(false);
    }
  };
  const handleDateChange = (employeeIndex, date) => {
    updateEmployee(employeeIndex, "employeeDoB", date ? date.toDate() : null);
  };
  return (
    <Box sx={{ p: 0, bgcolor: "grey.100" }}>
      <Card elevation={2} sx={{ p: 2 }}>
        <Stack spacing={1} direction="column">
          <Typography variant="h6">Employees detected by AI</Typography>
          <Typography variant="caption">
            You can edit the data for each employee below, delete employees that
            are not in the document, or add employees that are in the document
            but have not been detected by AI.
          </Typography>
          <Alert severity="info">
            Your edits will only be saved when you click "Save Data For All
            Employees" below. This page does not auto-save. An employee card
            colored yellow means unsaved changes. Please verify and save in that
            case.
          </Alert>
        </Stack>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              {" "}
              {employeesData.length === 0 ? (
                <Alert severity="info">
                  No employees were detected in this document.
                </Alert>
              ) : (
                <Grid container spacing={1}>
                  {employeesData.map((employee, employeeIndex) => (
                    <Grid item xs={12} key={employee.id}>
                      <Card
                        variant="outlined"
                        sx={{
                          p: 0,
                          bgcolor: editedFields[`${employeeIndex}`]
                            ? "warning.light"
                            : "grey.50",
                          transition: "background-color 0.3s",
                        }}
                      >
                        <CardContent>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              mb: 1,
                            }}
                          >
                            <Typography variant="overline">
                              Employee {employeeIndex + 1}
                            </Typography>
                            <Tooltip title="Remove employee">
                              <IconButton
                                size="small"
                                onClick={() => removeEmployee(employeeIndex)}
                                sx={{ color: "error.main" }}
                              >
                                <Iconify icon="fluent:delete-16-filled" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <Stack spacing={1} direction="column">
                            <Stack spacing={1} direction="row">
                              {" "}
                              <TextField
                                size="small"
                                label="First Name"
                                value={employee.employeeFirstName}
                                onChange={(e) =>
                                  updateEmployee(
                                    employeeIndex,
                                    "employeeFirstName",
                                    e.target.value
                                  )
                                }
                              />
                              <TextField
                                size="small"
                                label="Middle Name"
                                value={employee.employeeMiddleName}
                                onChange={(e) =>
                                  updateEmployee(
                                    employeeIndex,
                                    "employeeMiddleName",
                                    e.target.value
                                  )
                                }
                              />{" "}
                              <TextField
                                size="small"
                                label="Last Name"
                                value={employee.employeeLastName}
                                onChange={(e) =>
                                  updateEmployee(
                                    employeeIndex,
                                    "employeeLastName",
                                    e.target.value
                                  )
                                }
                              />{" "}
                              <TextField
                                size="small"
                                label="SSN"
                                value={employee.employeeSSN}
                                onChange={(e) =>
                                  updateEmployee(
                                    employeeIndex,
                                    "employeeSSN",
                                    e.target.value
                                  )
                                }
                              />{" "}
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                  disableFuture
                                  label="Date of Birth"
                                  value={
                                    employee.employeeDoB
                                      ? moment(employee.employeeDoB)
                                      : null
                                  }
                                  onChange={(date) =>
                                    handleDateChange(employeeIndex, date)
                                  }
                                  size="small"
                                />
                              </LocalizationProvider>
                            </Stack>
                            <Stack spacing={1} direction="row">
                              <TextField
                                size="small"
                                label="Employer"
                                value={employee.employerName}
                                onChange={(e) =>
                                  updateEmployee(
                                    employeeIndex,
                                    "employerName",
                                    e.target.value
                                  )
                                }
                              />{" "}
                              <TextField
                                size="small"
                                label="First Page"
                                value={employee.firstPage}
                                onChange={(e) =>
                                  updateEmployee(
                                    employeeIndex,
                                    "firstPage",
                                    e.target.value
                                  )
                                }
                              />
                              <TextField
                                size="small"
                                label="Last Page"
                                value={employee.lastPage}
                                onChange={(e) =>
                                  updateEmployee(
                                    employeeIndex,
                                    "lastPage",
                                    e.target.value
                                  )
                                }
                              />
                            </Stack>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )}
              <Box
                sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}
              >
                <Box>
                  <Button
                    variant="outlined"
                    startIcon={<Iconify icon="ri:user-add-line" />}
                    onClick={addEmployee}
                    disabled={isSaving}
                    sx={{ mr: 1 }}
                  >
                    Add Employee
                  </Button>

                  <Button
                    disabled={isSaving}
                    variant="contained"
                    startIcon={<Iconify icon="ri:save-line" />}
                    onClick={handleSaveData}
                    color="primary"
                  >
                    Save Data For All Employees
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box sx={{ height: "60vh" }}>
                <Viewer
                  fileUrl={document.psUrl}
                  plugins={[defaultLayoutPluginInstance]}
                  defaultScale={SpecialZoomLevel.PageWidth}
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}
