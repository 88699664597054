import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Alert,
  Button,
  Chip,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import Iconify from "src/components/iconify";
import _ from "lodash";
import { LoadingButton } from "@mui/lab";
import cogoToast from "cogo-toast";

class DataComponentGeneric extends React.Component {
  state = {
    showData: this.props.showData || false,
    isLoading: true,
    isError: false,
    isSaving: false,
    data: [],
    groupData: {},
    useAgeCorrection: true,
    historicalData: [],
    dataDefinitions: {
      PFT_DATA_DEFINITION: [
        {
          tableHeader: "Clinical Assessment",
          dataType: "Scalar",
          dataSubtypeList: [
            "Smoker Status",
            "Asthma Status",
            "Session Quality",
            "Interpretation",
          ],
          fieldArr: ["value", "notes"],
          fieldArrLabels: ["Value", "Notes"],
        },
        {
          tableHeader: "Spirometry Measurements",
          dataType: "Tabular",
          dataSubtypeList: ["FVC", "FEV1", "FEV1/FVC Ratio", "PFM"],
          fieldArr: ["best", "pred", "pred%", "variance", "notes"],
          fieldArrLabels: ["Best", "Pred", "Pred%", "Variance", "Notes"],
        },
      ],
      LAB_REPORT_DATA_DEFINITION: [
        {
          tableHeader: "Lab Results",
          columnKey: "parameter",
          dataSubtypeList: [],
          fieldArr: ["parameter", "result", "unit", "referenceRange"],
          fieldArrLabels: ["Parameter", "Result", "Unit", "Reference Range"],
          dataAliases: {},
        },
      ],
      CHAIN_OF_CUSTODY_DATA_DEFINITION: [
        {
          tableHeader: "Custody Control Form",
          dataSubtypeList: [],
          fieldArr: ["key", "value", "notes"],
          fieldArrLabels: ["Data", "Value", "Notes"],
          columnKey: "key",
          dataAliases: {
            donorName: "Donor Name",
            donorId: "Donor ID",
            hasDonorSign: "Donor Signature",
            derName: "DER Name",
            derContact: "DER Contact",
            mroName: "MRO Name",
            mroContact: "MRO Contact",
            isDOT: "DOT?",
            dotAgency: "DOT Agency",
            reasonForTest: "Reason for Test",
            labAccount: "Lab Account",
            specimenType: "Specimen Type",
            specimenId: "Specimen ID",
            isSplitSpecimen: "Split Specimen",
            isObservedCollection: "Observed Collection",
            hasCollectorSign: "Collector Signature",
            specimenTempOk: "Specimen Temp OK",
            collectionDateMMDDYYYY: "Collection Date",
            collectionSite: "Collection Site",
          },
        },
      ],
      MEDICAL_HISTORY_QUESTIONNAIRE_DATA_DEFINITION: [
        {
          tableHeader: "Medical History Questionnaire",
          columnKey: "question",
          dataSubtypeList: [],
          fieldArr: ["question", "answer", "notes"],
          fieldArrLabels: ["Question", "Answer", "Notes"],
          dataAliases: {},
        },
      ],
      RESPIRATOR_QUESTIONNAIRE_DATA_DEFINITION: [
        {
          tableHeader: "Respirator Questionnaire",
          dataSubtypeList: [],
          columnKey: "question",
          fieldArr: ["question", "answer", "notes"],
          fieldArrLabels: ["Question", "Answer", "Notes"],
          dataAliases: {},
        },
      ],
      BAT_DATA_DEFINITION: [
        {
          tableHeader: "Breath Alcohol Test",
          dataSubtypeList: [],
          columnKey: "BAC",
          fieldArr: ["BAC", "wasAirBlankDone", "notes"],
          fieldArrLabels: [
            "Breath Alcohol Concentration",
            "Air Blank Done?",
            "Notes",
          ],
          dataAliases: {},
        },
      ],
      MEDICAL_EXAM_DATA_DEFINITION: [
        {
          tableHeader: "Vitals and Summaries",
          dataType: "Scalar",
          dataSubtypeList: [
            "Height",
            "Weight",
            "Temperature",
            "Pulse",
            "Blood Pressure",
            "Chest X-Ray",
            "ECG",
            "Audiogram",
            "PFT",
            "Medical Notes",
          ],
          fieldArr: ["value", "unit", "notes"],
          fieldArrLabels: ["Value", "Unit", "Notes"],
        },
        {
          tableHeader: "Vision",
          dataType: "Tabular",
          dataSubtypeList: [
            "Near - Corrected",
            "Near - Uncorrected",
            "Far - Corrected",
            "Far - Uncorrected",
            "Colour Vision",
            "Peripheral Vision",
          ],
          fieldArr: ["laterality", "result", "notes"],
          fieldArrLabels: ["Laterality", "Result", "Notes"],
        },
        {
          tableHeader: "Urine Analysis",
          dataType: "Tabular",
          dataSubtypeList: [
            "Specific Gravity",
            "Sugar",
            "Albumin",
            "Blood",
            "Female LMP",
          ],
          fieldArr: ["value", "unit", "notes"],
          fieldArrLabels: ["Value", "Unit", "Notes"],
        },
      ],
      CXR_DATA_DEFINITION: [
        {
          tableHeader: "Chest X-Ray",
          dataType: "Scalar",
          dataSubtypeList: ["View", "Radiology Notes", "Interpretation"],
          fieldArr: ["value", "notes"],
          fieldArrLabels: ["Result", "Notes"],
        },
      ],
      ECG_DATA_DEFINITION: [
        {
          tableHeader: "ECG",
          dataType: "Scalar",
          dataSubtypeList: [
            "Heart Rate",
            "PR Interval",
            "QRS Duration",
            "QT Interval",
            "P-R-T Axes",
            "Interpretation",
          ],
          fieldArr: ["value", "unit", "notes"],
          fieldArrLabels: ["Value", "Unit", "Notes"],
        },
      ],
      AUDIOGRAM_DATA_DEFINITION: [
        {
          tableHeader: "Clinical Assessment",
          dataType: "Scalar",
          dataSubtypeList: [
            "Audio Equipment Information",
            "Relevant History",
            "Otoscopic Evaluation",
            "Interpretation",
          ],
          fieldArr: ["value"],
          fieldArrLabels: ["Value"],
        },
        {
          tableHeader: "Audiometry Measurements",
          dataType: "Tabular",
          dataSubtypeList: ["1000", "2000", "3000"],
          fieldArr: ["decibel", "laterality"],
          fieldArrLabels: ["Decibel", "Laterality"],
        },
      ],
      DOT_FORM_DATA_DEFINITION: [
        {
          tableHeader: "Vitals and Summaries",
          dataType: "Scalar",
          dataSubtypeList: [
            "Height",
            "Weight",
            "Temperature",
            "Pulse",
            "Blood Pressure",
            "Chest X-Ray",
            "ECG",
            "Audiogram",
            "PFT",
            "Medical Notes",
          ],
          fieldArr: ["value", "unit", "notes"],
          fieldArrLabels: ["Value", "Unit", "Notes"],
        },
        {
          tableHeader: "Vision",
          dataType: "Tabular",
          dataSubtypeList: [
            "Near - Corrected",
            "Near - Uncorrected",
            "Far - Corrected",
            "Far - Uncorrected",
            "Colour Vision",
            "Peripheral Vision",
          ],
          fieldArr: ["laterality", "result", "notes"],
          fieldArrLabels: ["Laterality", "Result", "Notes"],
        },
        {
          tableHeader: "Urine Analysis",
          dataType: "Tabular",
          dataSubtypeList: [
            "Specific Gravity",
            "Sugar",
            "Albumin",
            "Blood",
            "Female LMP",
          ],
          fieldArr: ["value", "unit", "notes"],
          fieldArrLabels: ["Value", "Unit", "Notes"],
        },
        {
          tableHeader: "Determination",
          dataType: "Tabular",
          dataSubtypeList: [
            "Driver's License Number",
            "Issuing State/Province",
            "Zip Code",
            "Whether CLP/CDL Applicant/Holder",
            "Final Determination",
            "Periodic Monitoring Required, if any",
            "Duration Qualified For",
            "Medical Examiner's Name",
          ],
          fieldArr: ["value", "unit", "notes"],
          fieldArrLabels: ["Value", "Unit", "Notes"],
        },
      ],
      DOT_CARD_DATA_DEFINITION: [
        {
          tableHeader: "DOT Card",
          dataSubtypeList: [],
          fieldArr: ["key", "value", "notes"],
          fieldArrLabels: ["Data", "Value", "Notes"],
          columnKey: "key",
          dataAliases: {
            dotExpirationDate: "Expiration Date",
            dotMedicalExaminerDetermination: "Medical Examiner Determination",
            dotMedicalExamWaivers: "Medical Exam Waivers",
            driverLicenseNumber: "Driver License Number",
            issuingStateProvince: "Issuing State/Province",
            zipCode: "Zip Code",
          },
        },
      ],
    },
  };

  AUDIO_AGE_CORRECTION = [
    { age: 20, 1000: 5, 2000: 3, 3000: 4, 4000: 5 },
    { age: 21, 1000: 5, 2000: 3, 3000: 4, 4000: 5 },
    { age: 22, 1000: 5, 2000: 3, 3000: 4, 4000: 5 },
    { age: 23, 1000: 5, 2000: 3, 3000: 4, 4000: 5 },
    { age: 24, 1000: 5, 2000: 3, 3000: 5, 4000: 6 },
    { age: 25, 1000: 6, 2000: 3, 3000: 5, 4000: 6 },
    { age: 26, 1000: 6, 2000: 4, 3000: 5, 4000: 6 },
    { age: 27, 1000: 6, 2000: 4, 3000: 5, 4000: 7 },
    { age: 28, 1000: 6, 2000: 4, 3000: 6, 4000: 7 },
    { age: 29, 1000: 6, 2000: 4, 3000: 6, 4000: 7 },
    { age: 30, 1000: 6, 2000: 5, 3000: 6, 4000: 8 },
    { age: 31, 1000: 7, 2000: 5, 3000: 6, 4000: 8 },
    { age: 32, 1000: 7, 2000: 5, 3000: 7, 4000: 8 },
    { age: 33, 1000: 7, 2000: 5, 3000: 7, 4000: 9 },
    { age: 34, 1000: 7, 2000: 5, 3000: 7, 4000: 9 },
    { age: 35, 1000: 7, 2000: 5, 3000: 8, 4000: 10 },
    { age: 36, 1000: 7, 2000: 6, 3000: 8, 4000: 10 },
    { age: 37, 1000: 8, 2000: 6, 3000: 8, 4000: 10 },
    { age: 38, 1000: 8, 2000: 6, 3000: 9, 4000: 11 },
    { age: 39, 1000: 8, 2000: 6, 3000: 9, 4000: 11 },
    { age: 40, 1000: 8, 2000: 6, 3000: 9, 4000: 12 },
    { age: 41, 1000: 8, 2000: 7, 3000: 10, 4000: 12 },
    { age: 42, 1000: 9, 2000: 7, 3000: 10, 4000: 12 },
    { age: 43, 1000: 9, 2000: 7, 3000: 10, 4000: 13 },
    { age: 44, 1000: 9, 2000: 7, 3000: 11, 4000: 13 },
    { age: 45, 1000: 9, 2000: 7, 3000: 11, 4000: 14 },
    { age: 46, 1000: 9, 2000: 8, 3000: 11, 4000: 14 },
    { age: 47, 1000: 10, 2000: 8, 3000: 12, 4000: 14 },
    { age: 48, 1000: 10, 2000: 8, 3000: 12, 4000: 15 },
    { age: 49, 1000: 10, 2000: 8, 3000: 12, 4000: 15 },
    { age: 50, 1000: 10, 2000: 8, 3000: 13, 4000: 16 },
    { age: 51, 1000: 10, 2000: 9, 3000: 13, 4000: 16 },
    { age: 52, 1000: 11, 2000: 9, 3000: 13, 4000: 16 },
    { age: 53, 1000: 11, 2000: 9, 3000: 14, 4000: 17 },
    { age: 54, 1000: 11, 2000: 9, 3000: 14, 4000: 17 },
    { age: 55, 1000: 11, 2000: 9, 3000: 14, 4000: 18 },
    { age: 56, 1000: 11, 2000: 10, 3000: 15, 4000: 18 },
    { age: 57, 1000: 12, 2000: 10, 3000: 15, 4000: 18 },
    { age: 58, 1000: 12, 2000: 10, 3000: 15, 4000: 19 },
    { age: 59, 1000: 12, 2000: 10, 3000: 16, 4000: 19 },
    { age: 60, 1000: 12, 2000: 10, 3000: 16, 4000: 20 },
    { age: 61, 1000: 12, 2000: 11, 3000: 16, 4000: 20 },
    { age: 62, 1000: 13, 2000: 11, 3000: 17, 4000: 20 },
    { age: 63, 1000: 13, 2000: 11, 3000: 17, 4000: 21 },
    { age: 64, 1000: 13, 2000: 11, 3000: 17, 4000: 21 },
    { age: 65, 1000: 13, 2000: 11, 3000: 18, 4000: 22 },
    { age: 66, 1000: 13, 2000: 12, 3000: 18, 4000: 22 },
    { age: 67, 1000: 14, 2000: 12, 3000: 18, 4000: 22 },
    { age: 68, 1000: 14, 2000: 12, 3000: 19, 4000: 23 },
    { age: 69, 1000: 14, 2000: 12, 3000: 19, 4000: 23 },
    { age: 70, 1000: 14, 2000: 12, 3000: 19, 4000: 24 },
    { age: 71, 1000: 14, 2000: 13, 3000: 20, 4000: 24 },
    { age: 72, 1000: 15, 2000: 13, 3000: 20, 4000: 24 },
    { age: 73, 1000: 15, 2000: 13, 3000: 20, 4000: 25 },
    { age: 74, 1000: 15, 2000: 13, 3000: 21, 4000: 25 },
    { age: 75, 1000: 15, 2000: 13, 3000: 21, 4000: 26 },
    { age: 76, 1000: 15, 2000: 14, 3000: 21, 4000: 26 },
    { age: 77, 1000: 16, 2000: 14, 3000: 22, 4000: 26 },
    { age: 78, 1000: 16, 2000: 14, 3000: 22, 4000: 27 },
    { age: 79, 1000: 16, 2000: 14, 3000: 22, 4000: 27 },
    { age: 80, 1000: 16, 2000: 14, 3000: 23, 4000: 28 },
    { age: 81, 1000: 16, 2000: 15, 3000: 23, 4000: 28 },
    { age: 82, 1000: 17, 2000: 15, 3000: 23, 4000: 28 },
    { age: 83, 1000: 17, 2000: 15, 3000: 24, 4000: 29 },
    { age: 84, 1000: 17, 2000: 15, 3000: 24, 4000: 29 },
    { age: 85, 1000: 17, 2000: 15, 3000: 24, 4000: 30 },
    { age: 86, 1000: 17, 2000: 16, 3000: 25, 4000: 30 },
    { age: 87, 1000: 18, 2000: 16, 3000: 25, 4000: 30 },
    { age: 88, 1000: 18, 2000: 16, 3000: 25, 4000: 31 },
    { age: 89, 1000: 18, 2000: 16, 3000: 26, 4000: 31 },
    { age: 90, 1000: 18, 2000: 16, 3000: 26, 4000: 32 },
  ];

  componentDidMount() {
    let dataType = this.props.dataType;
    console.log({ dataType });
    const ddNew = _.cloneDeep(this.state.dataDefinitions);
    ddNew["DOT Exam_DATA_DEFINITION"] =
      this.state.dataDefinitions["MEDICAL_EXAM_DATA_DEFINITION"];
    if (
      dataType === "AUDIOGRAM" &&
      this.props.showMaxButton &&
      !this.props.isHistorical
    ) {
      this.setState(
        {
          data: this.props.data,
          groupData: this.props.groupData,
        },
        () => {
          this.getPreviousData();
        }
      );
    } else {
      this.setState({
        dataDefinitions: ddNew,
        data: this.props.data,
        isLoading: false,
      });
    }
  }
  getPreviousData = async () => {
    if (!this.state.groupData || !this.state.groupData.employee) return;
    let eeId = this.state.groupData.employee._id;
    try {
      cogoToast.loading("Fetching historical data...");
      this.setState({
        isFetchingHistorical: true,
        isLoading: true,
      });
      let res = await this.props.apiCallPost("/files/getGroupDataForEmployee", {
        employeeId: eeId,
        serviceDataType: this.props.dataType,
      });
      let resFiltered = _.filter(
        res,
        (x) => x._id !== this.state.groupData._id
      );

      let histData = resFiltered.map((x) => {
        let vDate = new Date(x.visit.visitAt);
        let data = x.dataStructured;
        let isAudiogramBaselineLE = x.isAudiogramBaselineLE;
        let isAudiogramBaselineRE = x.isAudiogramBaselineRE;
        return {
          vDate: vDate,
          data: data,
          isAudiogramBaselineLE: isAudiogramBaselineLE,
          isAudiogramBaselineRE: isAudiogramBaselineRE,
        };
      });
      let sortedByDateHistData = _.reverse(_.sortBy(histData, (x) => x.vDate));
      this.setState({
        historicalData: sortedByDateHistData,
        isFetchingHistorical: false,
        isLoading: false,
      });
    } catch (err) {
      cogoToast.error("Failed to fetch historical data.");
      this.setState({
        isFetchingHistorical: false,
        isLoading: false,
      });
      console.log(err);
    }
  };
  renderTextField = (
    field,
    fieldArr,
    hasSubtype = false,
    data = null,
    dataIdx = null,
    dataType = null,
    dataSubtype = null,
    alias = null
  ) => {
    let d = {};
    if (hasSubtype) {
      d = this.state.data.find((each) => each.dataSubtype === dataSubtype);
      if (!d) {
        d = {};

        if (dataType) {
          d["dataType"] = dataType;
        }
        if (dataSubtype) {
          d["dataSubtype"] = dataSubtype;
        }
        for (let i = 0; i < fieldArr.length; i++) {
          d[fieldArr[i]] = "";
        }
      }
    } else {
      d = data;
    }
    if (this.props.isHistorical) {
      return (
        <Typography variant="caption">{alias ? alias : d[field]}</Typography>
      );
    }
    return (
      <TextField
        multiline
        fullWidth={this.props.dataType === "AUDIOGRAM"}
        disabled={this.props.isSaving}
        size="small"
        value={alias ? alias : d[field]}
        onChange={(e) => {
          let temp = this.state.data;
          if (hasSubtype) {
            let index = temp.findIndex(
              (each) => each.dataSubtype === d.dataSubtype
            );
            if (index !== -1) {
              temp[index][field] = e.target.value;
              this.setState({
                hasChanges: true,
                data: temp,
              });
            } else {
              d[field] = e.target.value;
              temp.push(d);
              this.setState({
                hasChanges: true,
                data: temp,
              });
            }
          } else {
            temp[dataIdx][field] = e.target.value;
            this.setState({
              hasChanges: true,
              data: temp,
            });
          }
        }}
      />
    );
  };
  renderAddParamButton = (eachDef, dataDefIdx) => {
    return (
      <IconButton
        disabled={this.props.isHistorical}
        size="small"
        color="primary"
        onClick={() => {
          let temp = this.state.data;
          let d = {};
          if (eachDef.dataSubtypeList && eachDef.dataSubtypeList.length > 0) {
            let dataDefinitions = this.state.dataDefinitions;
            let currentKey = `${this.props.dataType}_DATA_DEFINITION`;
            let currentDataDefinition = dataDefinitions[currentKey];
            let currentIdxObj = currentDataDefinition[dataDefIdx];
            let currentDstl = currentIdxObj.dataSubtypeList;
            currentDstl.push("New Parameter");
            currentIdxObj.dataSubtypeList = currentDstl;
            dataDefinitions[currentKey] = currentDataDefinition;
            let d = {
              dataType: currentIdxObj.dataType,
              dataSubtype: "NEW_PARAMETER",
            };
            for (let i = 0; i < eachDef.fieldArr.length; i++) {
              d[eachDef.fieldArr[i]] = "";
            }
            temp.push(d);
            this.setState({
              data: temp,
              hasChanges: true,
            });
          } else {
            for (let i = 0; i < eachDef.fieldArr.length; i++) {
              d[eachDef.fieldArr[i]] = "";
            }
            temp.push(d);
            this.setState({
              hasChanges: true,
              data: temp,
            });
          }
        }}
        sx={{
          width: 30,
          height: 30,
          bgcolor: "primary.success",
          color: "primary.contrastText",
          "&:hover": {
            bgcolor: "primary.dark",
          },
        }}
      >
        <Iconify icon="carbon:add-filled" />
      </IconButton>
    );
  };
  renderHeaders = (eachDef, dataDefIdx) => {
    if (!eachDef.dataSubtypeList || eachDef.dataSubtypeList.length === 0) {
      return (
        <TableRow>
          {eachDef.fieldArrLabels.map((field, idx) => {
            if (idx === 0) {
              return (
                <TableCell>
                  {field} {this.renderAddParamButton(eachDef, dataDefIdx)}
                </TableCell>
              );
            }
            return <TableCell>{field}</TableCell>;
          })}
          <TableCell>Is Normal?</TableCell>
        </TableRow>
      );
    } else {
      return (
        <TableRow>
          <TableCell>
            Parameter {this.renderAddParamButton(eachDef, dataDefIdx)}
          </TableCell>
          {eachDef.fieldArrLabels.map((field) => {
            return <TableCell>{field}</TableCell>;
          })}
          {this.props.renderType === "patientLetter" ? null : (
            <TableCell>Is Normal?</TableCell>
          )}
        </TableRow>
      );
    }
  };
  renderBody = (eachDef) => {
    if (!eachDef.dataSubtypeList || eachDef.dataSubtypeList.length === 0) {
      let data = this.state.data;
      return data.map((each, dataIdx) => {
        let dataKeys = Object.keys(each);
        let isNormalExists = dataKeys.indexOf("isNormal") !== -1;
        let style = {};
        if (isNormalExists) {
          style = {
            bgcolor: each.isNormal ? "" : "#FFAC82 !important",
          };
        }
        return (
          <TableRow sx={style}>
            {eachDef.fieldArr.map((field, ix) => {
              if (ix === 0) {
                return (
                  <TableCell>
                    {this.renderTextField(
                      field,
                      eachDef.fieldArr,
                      false,
                      each,
                      dataIdx,
                      null,
                      null,
                      eachDef.dataAliases[each[eachDef.columnKey]]
                    )}
                  </TableCell>
                );
              }
              return (
                <TableCell>
                  {this.renderTextField(
                    field,
                    eachDef.fieldArr,
                    false,
                    each,
                    dataIdx
                  )}
                </TableCell>
              );
            })}
            {this.props.renderType === "patientLetter" ? null : (
              <TableCell>
                <IconButton
                  onClick={() => {
                    let temp = this.state.data;
                    temp[dataIdx]["isNormal"] = !temp[dataIdx]["isNormal"];
                    this.setState({
                      hasChanges: true,
                      data: temp,
                    });
                  }}
                >
                  {each.isNormal ? (
                    <Iconify icon="ic:baseline-check-circle" />
                  ) : (
                    <Iconify icon="ic:baseline-radio-button-unchecked" />
                  )}
                </IconButton>
              </TableCell>
            )}
          </TableRow>
        );
      });
    } else {
      let data = this.state.data;
      let subtypeList = eachDef.dataSubtypeList;
      let subtypeListFromData = _.uniq(
        _.filter(data, (each) => each.dataType === eachDef.dataType).map(
          (each) => each.dataSubtype
        )
      );
      let union = _.union(subtypeList, subtypeListFromData);
      return union.map((dataSubtype) => {
        let isNew = false;
        if (subtypeList.indexOf(dataSubtype) === -1) {
          isNew = true;
        }
        let dataForSubType = _.find(
          data,
          (each) =>
            each.dataSubtype === dataSubtype &&
            each.dataType === eachDef.dataType
        );
        let style = {};
        if (dataForSubType && dataForSubType.isNormal !== undefined) {
          if (!dataForSubType.isNormal) {
            style = {
              bgcolor: "#FFAC82 !important",
            };
          }
        }
        return (
          <TableRow sx={style}>
            <TableCell>
              {isNew ? (
                <>
                  <TextField
                    size="small"
                    value={dataSubtype}
                    onChange={(e) => {
                      // find all data and update there.
                      let temp = this.state.data;
                      let filteredData = _.filter(
                        temp,
                        (each) =>
                          each.dataSubtype === dataSubtype &&
                          each.dataType === eachDef.dataType
                      );
                      filteredData.forEach((each) => {
                        each.dataSubtype = e.target.value;
                      });
                      this.setState({
                        hasChanges: true,
                        data: temp,
                      });
                    }}
                  />
                </>
              ) : (
                dataSubtype
              )}
            </TableCell>
            {eachDef.fieldArr.map((field) => {
              return (
                <TableCell>
                  {this.renderTextField(
                    field,
                    eachDef.fieldArr,
                    true,
                    null,
                    null,
                    eachDef.dataType,
                    dataSubtype
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        );
      });
    }
  };
  calculateSTS = () => {
    let historicalData = this.state.historicalData;
    let hasHistory = false;
    if (historicalData.length > 0) {
      hasHistory = true;
    }
    if (!hasHistory) {
      return [null, null];
    }
    let baselineDataRE = _.find(historicalData, (x) => x.isAudiogramBaselineRE);
    let baselineDataLE = _.find(historicalData, (x) => x.isAudiogramBaselineLE);
    let currentDataRE = [];
    let currentDataLE = [];
    let baseline2k3k4kLeft = [];
    let baseline2k3k4kRight = [];
    let current2k3k4kLeft = [];
    let current2k3k4kRight = [];

    if (!baselineDataLE && !baselineDataRE) {
      return [null, null];
    }
    let leftInvalid = false;
    let rightInvalid = false;
    if (!baselineDataLE) {
      leftInvalid = true;
    }
    if (!baselineDataRE) {
      rightInvalid = true;
    }
    // left pipeline
    if (!leftInvalid) {
      let bbDataLE = baselineDataLE.data;
      currentDataLE = _.filter(
        this.state.data,
        (x) =>
          x.dataType === "AUDIOGRAM" &&
          x.dataSubtype === "EVALUATION" &&
          x.laterality === "LEFT"
      );
      baseline2k3k4kLeft = _.filter(bbDataLE, (x) =>
        [2000, 3000, 4000].includes(x.frequency)
      ).map((x) => x.decibel);
      current2k3k4kLeft = _.filter(currentDataLE, (x) =>
        [2000, 3000, 4000].includes(x.frequency)
      ).map((x) => x.decibel);
      if (
        baseline2k3k4kLeft.includes(undefined) ||
        current2k3k4kLeft.includes(undefined)
      ) {
        leftInvalid = true;
      }
    }
    // right pipeline
    if (!rightInvalid) {
      let bbDataRE = baselineDataRE.data;
      currentDataRE = _.filter(
        this.state.data,
        (x) =>
          x.dataType === "AUDIOGRAM" &&
          x.dataSubtype === "EVALUATION" &&
          x.laterality === "RIGHT"
      );
      baseline2k3k4kRight = _.filter(bbDataRE, (x) =>
        [2000, 3000, 4000].includes(x.frequency)
      ).map((x) => x.decibel);
      current2k3k4kRight = _.filter(currentDataRE, (x) =>
        [2000, 3000, 4000].includes(x.frequency)
      ).map((x) => x.decibel);
      if (
        baseline2k3k4kRight.includes(undefined) ||
        current2k3k4kRight.includes(undefined)
      ) {
        rightInvalid = true;
      }
    }
    if (leftInvalid && rightInvalid) {
      return [null, null];
    }
    let dobExists = this.state.groupData.employee.employeeDoB;
    let useAgeCorrection = this.state.useAgeCorrection;
    let ageCorrectionToBeDone = dobExists && useAgeCorrection;
    if (!dobExists && this.state.useAgeCorrection) {
      return [null, null];
    }
    if (ageCorrectionToBeDone) {
      let dob = new Date(this.state.groupData.employee.employeeDoB);
      let ageNow = new Date().getFullYear() - dob.getFullYear();
      let currentAgeCorrection = _.find(
        this.AUDIO_AGE_CORRECTION,
        (x) => x.age === ageNow
      );
      // left pipeline
      if (!leftInvalid) {
        let ageAtBaselineLE =
          new Date(baselineDataLE.vDate).getFullYear() - dob.getFullYear();
        let baselineAgeCorrectionLE = _.find(
          this.AUDIO_AGE_CORRECTION,
          (x) => x.age === ageAtBaselineLE
        );
        baseline2k3k4kLeft = baseline2k3k4kLeft.map((x, idx) => {
          let corrected = x - baselineAgeCorrectionLE[2000 + 1000 * idx];
          if (corrected < 0) {
            corrected = 0;
          }
          return corrected;
        });
        current2k3k4kLeft = current2k3k4kLeft.map((x, idx) => {
          let corrected = x - currentAgeCorrection[2000 + 1000 * idx];
          if (corrected < 0) {
            corrected = 0;
          }
          return corrected;
        });
      }
      if (!rightInvalid) {
        let ageAtBaselineRE =
          new Date(baselineDataRE.vDate).getFullYear() - dob.getFullYear();

        let baselineAgeCorrectionRE = _.find(
          this.AUDIO_AGE_CORRECTION,
          (x) => x.age === ageAtBaselineRE
        );
        baseline2k3k4kRight = baseline2k3k4kRight.map((x, idx) => {
          let corrected = x - baselineAgeCorrectionRE[2000 + 1000 * idx];
          if (corrected < 0) {
            corrected = 0;
          }
          return corrected;
        });
        current2k3k4kRight = current2k3k4kRight.map((x, idx) => {
          let corrected = x - currentAgeCorrection[2000 + 1000 * idx];
          if (corrected < 0) {
            corrected = 0;
          }
          return corrected;
        });
      }

      if (
        baseline2k3k4kLeft.includes(undefined) ||
        current2k3k4kLeft.includes(undefined)
      ) {
        leftInvalid = true;
      }

      if (
        baseline2k3k4kRight.includes(undefined) ||
        current2k3k4kRight.includes(undefined)
      ) {
        rightInvalid = true;
      }
    }

    let sts2k3k4kLeft = [];
    let sts2k3k4kRight = [];
    for (let i = 0; i < 3; i++) {
      if (!leftInvalid) {
        let left = current2k3k4kLeft[i] - baseline2k3k4kLeft[i];
        if (left < 0) {
          left = 0;
        }
        sts2k3k4kLeft.push(left);
      }
      if (!rightInvalid) {
        let right = current2k3k4kRight[i] - baseline2k3k4kRight[i];
        if (right < 0) {
          right = 0;
        }
        sts2k3k4kRight.push(right);
      }
    }
    let stsLeft = _.mean(sts2k3k4kLeft);
    let stsRight = _.mean(sts2k3k4kRight);
    if (stsLeft < 0) {
      stsLeft = 0;
    }
    if (stsRight < 0) {
      stsRight = 0;
    }
    if (isNaN(stsLeft)) {
      stsLeft = undefined;
    }
    if (isNaN(stsRight)) {
      stsRight = undefined;
    }
    stsLeft = leftInvalid ? undefined : stsLeft;
    stsRight = rightInvalid ? undefined : stsRight;
    return [stsLeft, stsRight];
  };
  setCurrentAsBaseline = async (laterality) => {
    let stateKey = `settingBaseline${laterality}`;
    try {
      this.setState({
        [stateKey]: true,
      });
      let res = await this.props.apiCallPost("/files/setAudiogramBaseline", {
        groupId: this.state.groupData._id,
        key: laterality,
      });
      let data = res.dataStructured;
      let groupData = {
        ...res,
        visit: this.state.groupData.visit,
        employee: this.state.groupData.employee,
      };
      this.setState(
        {
          [stateKey]: false,
          data: data,
          groupData: groupData,
        },
        () => {
          this.getPreviousData();
        }
      );
      cogoToast.success("Baseline set successfully.");
    } catch (err) {
      cogoToast.error("Failed to set baseline.");
      this.setState({
        [stateKey]: false,
      });
    }
  };
  renderAudiometryTable = () => {
    let aliases = {
      ".5k": 500,
      "1k": 1000,
      "2k": 2000,
      "3k": 3000,
      "4k": 4000,
      "6k": 6000,
      "8k": 8000,
    };
    let headerAliases = Object.keys(aliases);
    let dataArr = _.filter(
      this.state.data,
      (x) => x.dataType === "AUDIOGRAM" && x.dataSubtype === "EVALUATION"
    );
    let historicalData = this.state.historicalData;
    let hasHistory = false;
    if (historicalData.length > 0) {
      hasHistory = true;
    }
    let stsElemLE = null;
    let stsElemRE = null;
    let sts = this.calculateSTS();

    stsElemLE = sts[0] ? (
      <Stack direction="row" alignItems={"center"}>
        <Chip
          size="small"
          label={`STS LE: ${sts[0].toFixed(2)}`}
          color={sts[0] > 10 ? "error" : "success"}
        />
        {sts[0] >= 10 ? (
          <LoadingButton
            size="small"
            loading={this.state.settingBaselineLE}
            onClick={() => {
              this.setCurrentAsBaseline("LE");
            }}
          >
            Set Current as LE Baseline
          </LoadingButton>
        ) : null}
      </Stack>
    ) : null;
    stsElemRE = sts[1] ? (
      <Stack direction="row" alignItems={"center"}>
        <Chip
          label={`STS RE: ${sts[1].toFixed(2)}`}
          size="small"
          color={sts[1] > 10 ? "error" : "success"}
        />
        {sts[1] >= 10 ? (
          <LoadingButton
            size="small"
            loading={this.state.settingBaselineRE}
            onClick={() => {
              this.setCurrentAsBaseline("RE");
            }}
          >
            Set Current as RE Baseline
          </LoadingButton>
        ) : null}
      </Stack>
    ) : null;
    let currentGroup = this.state.groupData;
    if (currentGroup.isAudiogramBaselineLE) {
      stsElemLE = (
        <Chip size="small" label="Current is baseline for LE" color="primary" />
      );
    }
    if (currentGroup.isAudiogramBaselineRE) {
      stsElemRE = (
        <Chip size="small" label="Current is baseline for RE" color="primary" />
      );
    }
    let currentElem = <Typography variant="overline">Current</Typography>;
    if (
      currentGroup.isAudiogramBaselineLE &&
      currentGroup.isAudiogramBaselineRE
    ) {
      currentElem = (
        <Typography variant="overline">Current (Baseline)</Typography>
      );
    } else if (currentGroup.isAudiogramBaselineLE) {
      currentElem = (
        <Typography variant="overline">Current (Baseline LE)</Typography>
      );
    } else if (currentGroup.isAudiogramBaselineRE) {
      currentElem = (
        <Typography variant="overline">Current (Baseline RE)</Typography>
      );
    }
    let dob = null;
    let ageCorrectionElem = null;
    if (
      this.state.groupData.employee &&
      this.state.groupData.employee.employeeDoB
    ) {
      dob = new Date(this.state.groupData.employee.employeeDoB);
      ageCorrectionElem = (
        <Button
          disabled={this.props.isHistorical}
          size="small"
          variant="outlined"
          onClick={() => {
            this.setState({
              useAgeCorrection: !this.state.useAgeCorrection,
            });
          }}
        >
          {this.state.useAgeCorrection ? "Using" : "Not Using"} OSHA Age
          Correction
        </Button>
      );
      let age = new Date().getFullYear() - dob.getFullYear();
      if (age < 20 || age > 90) {
        ageCorrectionElem = null;
      }
    } else {
      ageCorrectionElem = null;
    }

    let inReviewMode = this.props.showMaxButton;

    return (
      <Table size="small" className="artbl">
        <TableHead>
          {ageCorrectionElem || stsElemLE || stsElemRE ? (
            <TableRow>
              <TableCell>{ageCorrectionElem}</TableCell>
              <TableCell colSpan={7}>{stsElemLE}</TableCell>
              <TableCell colSpan={7}>{stsElemRE}</TableCell>
            </TableRow>
          ) : null}
          <TableRow>
            {this.props.renderType === "patientLetter" ? null : hasHistory ? (
              <TableCell rowSpan={2}>Data</TableCell>
            ) : null}
            <TableCell colSpan={7}>Left Ear</TableCell>
            <TableCell colSpan={7}>Right Ear</TableCell>
          </TableRow>
          <TableRow>
            {headerAliases.map((alias) => {
              return <TableCell>{alias}</TableCell>;
            })}
            {headerAliases.map((alias) => {
              return <TableCell>{alias}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {hasHistory ? <TableCell>{currentElem}</TableCell> : null}
            {headerAliases.map((alias) => {
              let data = _.find(
                dataArr,
                (x) =>
                  parseInt(x.frequency) === parseInt(aliases[alias]) &&
                  x.laterality === "LEFT"
              );
              if (data == null) {
                data = {
                  dataType: "AUDIOGRAM",
                  dataSubtype: "EVALUATION",
                  laterality: "LEFT",
                  frequency: aliases[alias],
                  decibel: 0,
                  notes: "<Not Recorded>",
                  isNormal: true,
                };
              }
              console.log({ alias, data });

              let isNormal = data ? data.isNormal : true;

              let sx = {
                bgcolor: isNormal
                  ? currentGroup.isAudiogramBaselineLE
                    ? "#61F3F3 !important"
                    : ""
                  : "#FFAC82 !important",
              };
              if (inReviewMode) {
                let dataMod = _.cloneDeep(data);
                let hasAgeCorrection =
                  ageCorrectionElem !== null && this.state.useAgeCorrection;
                if (hasAgeCorrection) {
                  let age = new Date().getFullYear() - dob.getFullYear();
                  if (age < 20 || age > 90) {
                    dataMod = data;
                  } else {
                    let ageCorrectionRow = _.find(
                      this.AUDIO_AGE_CORRECTION,
                      (x) => x.age === age
                    );

                    dataMod.decibel =
                      data.decibel - (ageCorrectionRow[aliases[alias]] || 0);
                    if (dataMod.decibel < 0) {
                      dataMod.decibel = 0;
                    }
                  }
                }
                return (
                  <TableCell sx={sx}>
                    <Tooltip
                      title={`Original: ${data.decibel} | Age Correction: ${data.decibel - dataMod.decibel}`}
                    >
                      {dataMod ? dataMod.decibel : ""}
                    </Tooltip>
                  </TableCell>
                );
              }
              return (
                <TableCell sx={sx}>
                  <TextField
                    fullWidth
                    size="small"
                    value={data.decibel}
                    onChange={(e) => {
                      let temp = this.state.data;
                      let index = temp.findIndex(
                        (each) =>
                          each.dataType === "AUDIOGRAM" &&
                          each.dataSubtype === "EVALUATION" &&
                          each.laterality === "LEFT" &&
                          each.frequency === aliases[alias]
                      );
                      if (index !== -1) {
                        temp[index].decibel = e.target.value;
                        this.setState({
                          hasChanges: true,
                          data: temp,
                        });
                      } else {
                        let d = {
                          dataType: "AUDIOGRAM",
                          dataSubtype: "EVALUATION",
                          laterality: "LEFT",
                          frequency: aliases[alias],
                          decibel: e.target.value,
                          notes: "",
                          isNormal: true,
                        };
                        temp.push(d);
                        this.setState({
                          hasChanges: true,
                          data: temp,
                        });
                      }
                    }}
                  />
                </TableCell>
              );
            })}
            {headerAliases.map((alias) => {
              let data = _.find(
                dataArr,
                (x) =>
                  parseInt(x.frequency) === parseInt(aliases[alias]) &&
                  x.laterality === "RIGHT"
              );
              if (data == null) {
                data = {
                  dataType: "AUDIOGRAM",
                  dataSubtype: "EVALUATION",
                  laterality: "RIGHT",
                  frequency: aliases[alias],
                  decibel: 0,
                  notes: "<Not Recorded>",
                  isNormal: true,
                };
              }
              let isNormal = data ? data.isNormal : true;
              let sx = {
                bgcolor: isNormal
                  ? currentGroup.isAudiogramBaselineRE
                    ? "#61F3F3 !important"
                    : ""
                  : "#FFAC82 !important",
              };
              if (inReviewMode) {
                let dataMod = _.cloneDeep(data);
                let hasAgeCorrection =
                  ageCorrectionElem !== null && this.state.useAgeCorrection;
                if (hasAgeCorrection) {
                  let age = new Date().getFullYear() - dob.getFullYear();
                  if (age < 20 || age > 90) {
                    dataMod = data;
                  } else {
                    let ageCorrectionRow = _.find(
                      this.AUDIO_AGE_CORRECTION,
                      (x) => x.age === age
                    );

                    dataMod.decibel =
                      data.decibel - (ageCorrectionRow[aliases[alias]] || 0);
                    if (dataMod.decibel < 0) {
                      dataMod.decibel = 0;
                    }
                  }
                }
                return (
                  <TableCell sx={sx}>
                    <Tooltip
                      title={`Original: ${data.decibel} | Age Correction: ${data.decibel - dataMod.decibel}`}
                    >
                      {dataMod ? dataMod.decibel : ""}
                    </Tooltip>
                  </TableCell>
                );
              }
              return (
                <TableCell sx={sx}>
                  <TextField
                    size="small"
                    value={data ? data.decibel : ""}
                    onChange={(e) => {
                      let temp = this.state.data;
                      let index = temp.findIndex(
                        (each) =>
                          each.dataType === "AUDIOGRAM" &&
                          each.dataSubtype === "EVALUATION" &&
                          each.laterality === "RIGHT" &&
                          each.frequency === aliases[alias]
                      );
                      if (index !== -1) {
                        temp[index].decibel = e.target.value;
                        this.setState({
                          hasChanges: true,
                          data: temp,
                        });
                      } else {
                        let d = {
                          dataType: "AUDIOGRAM",
                          dataSubtype: "EVALUATION",
                          laterality: "RIGHT",
                          frequency: aliases[alias],
                          decibel: e.target.value,
                          notes: "",
                          isNormal: true,
                        };
                        temp.push(d);
                        this.setState({
                          hasChanges: true,
                          data: temp,
                        });
                      }
                    }}
                  />
                </TableCell>
              );
            })}
          </TableRow>
          {hasHistory ? (
            <>
              {historicalData.map((each) => {
                let dateElem = (
                  <Typography variant="overline">
                    {each.vDate.toLocaleDateString()}
                  </Typography>
                );

                if (each.isAudiogramBaselineLE && each.isAudiogramBaselineRE) {
                  dateElem = (
                    <Stack justifyContent={"center"} direction="column">
                      <Typography variant="overline">
                        {each.vDate.toLocaleDateString()}
                      </Typography>{" "}
                      <Typography variant="overline" color={"seconary"}>
                        Baseline: Both
                      </Typography>
                    </Stack>
                  );
                } else if (each.isAudiogramBaselineRE) {
                  dateElem = (
                    <Stack justifyContent={"center"} direction="column">
                      <Typography variant="overline">
                        {each.vDate.toLocaleDateString()}
                      </Typography>{" "}
                      <Typography variant="overline" color={"seconary"}>
                        Baseline: RE
                      </Typography>
                    </Stack>
                  );
                } else if (each.isAudiogramBaselineLE) {
                  dateElem = (
                    <Stack justifyContent={"center"} direction="column">
                      <Typography variant="overline">
                        {each.vDate.toLocaleDateString()}
                      </Typography>{" "}
                      <Typography variant="overline" color={"seconary"}>
                        Baseline: LE
                      </Typography>
                    </Stack>
                  );
                }
                return (
                  <TableRow>
                    <TableCell>{dateElem}</TableCell>
                    {headerAliases.map((alias) => {
                      let data = _.find(
                        each.data,
                        (x) =>
                          x.dataType === "AUDIOGRAM" &&
                          x.dataSubtype === "EVALUATION" &&
                          x.laterality === "LEFT" &&
                          x.frequency === aliases[alias]
                      );
                      let isNormal = data ? data.isNormal : true;
                      let sx = {
                        bgcolor: isNormal
                          ? each.isAudiogramBaselineLE
                            ? "#61F3F3 !important"
                            : ""
                          : "#FFAC82 !important",
                      };
                      let dataMod = _.cloneDeep(data);
                      let hasAgeCorrection =
                        ageCorrectionElem !== null &&
                        this.state.useAgeCorrection;
                      if (hasAgeCorrection) {
                        let age =
                          new Date(each.vDate).getFullYear() -
                          dob.getFullYear();
                        if (age < 20 || age > 90) {
                          dataMod = data;
                        } else {
                          let ageCorrectionRow = _.find(
                            this.AUDIO_AGE_CORRECTION,
                            (x) => x.age === age
                          );

                          dataMod.decibel =
                            data.decibel -
                            (ageCorrectionRow[aliases[alias]] || 0);
                          if (dataMod.decibel < 0) {
                            dataMod.decibel = 0;
                          }
                        }
                      }
                      return (
                        <TableCell sx={sx}>
                          <Tooltip
                            title={`Original: ${data.decibel} | Age Correction: ${data.decibel - dataMod.decibel}`}
                          >
                            {dataMod ? dataMod.decibel : ""}
                          </Tooltip>
                        </TableCell>
                      );
                    })}
                    {headerAliases.map((alias) => {
                      let data = _.find(
                        each.data,
                        (x) =>
                          x.dataType === "AUDIOGRAM" &&
                          x.dataSubtype === "EVALUATION" &&
                          x.laterality === "RIGHT" &&
                          x.frequency === aliases[alias]
                      );
                      let isNormal = data ? data.isNormal : true;
                      let sx = {
                        bgcolor: isNormal
                          ? each.isAudiogramBaselineRE
                            ? "#61F3F3 !important"
                            : ""
                          : "#FFAC82 !important",
                      };
                      let dataMod = _.cloneDeep(data);
                      let hasAgeCorrection =
                        ageCorrectionElem !== null &&
                        this.state.useAgeCorrection;
                      if (hasAgeCorrection) {
                        let age =
                          new Date(each.vDate).getFullYear() -
                          dob.getFullYear();
                        if (age < 20 || age > 90) {
                          dataMod = data;
                        } else {
                          let ageCorrectionRow = _.find(
                            this.AUDIO_AGE_CORRECTION,
                            (x) => x.age === age
                          );

                          dataMod.decibel =
                            data.decibel -
                            (ageCorrectionRow[aliases[alias]] || 0);
                          if (dataMod.decibel < 0) {
                            dataMod.decibel = 0;
                          }
                        }
                      }
                      return (
                        <TableCell sx={sx}>
                          <Tooltip
                            title={`Original: ${data.decibel} | Age Correction: ${data.decibel - dataMod.decibel}`}
                          >
                            {dataMod ? dataMod.decibel : ""}
                          </Tooltip>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </>
          ) : null}
        </TableBody>
      </Table>
    );
  };
  renderData = () => {
    if (!this.state.showData) {
      return null;
    }
    let dataType = this.props.dataType;
    let dataDefinition =
      this.state.dataDefinitions[dataType + "_DATA_DEFINITION"];
    return dataDefinition.map((each, dataDefIdx) => {
      if (
        dataType === "AUDIOGRAM" &&
        each.tableHeader === "Audiometry Measurements"
      ) {
        return (
          <Stack>
            <Typography variant="button">{each.tableHeader}</Typography>
            {this.renderAudiometryTable()}
          </Stack>
        );
      }
      return (
        <Stack>
          <Typography variant="button">{each.tableHeader}</Typography>
          <Table size="small" className="artbl">
            <TableHead>{this.renderHeaders(each, dataDefIdx)}</TableHead>
            <TableBody>
              {this.renderBMI(each.tableHeader)}
              {this.renderBody(each)}
            </TableBody>
          </Table>
        </Stack>
      );
    });
  };
  renderBMI = (tableHeader) => {
    if (tableHeader === "Vitals and Summaries") {
      let data = this.state.data;
      let heightObj = _.find(data, (each) => each.dataSubtype === "Height");
      if (!heightObj) return;
      let weightObj = _.find(data, (each) => each.dataSubtype === "Weight");
      if (!weightObj) return;
      let heightUnit = heightObj?.unit;
      let weightUnit = weightObj?.unit;
      // height units = cm, m, in
      let heightInMetres = 0;
      if (heightUnit === "cm") {
        heightInMetres = heightObj?.value / 100;
      } else if (heightUnit === "inches") {
        heightInMetres = heightObj?.value * 0.0254;
      } else {
        heightInMetres = heightObj?.value;
      }
      // weight in lbs and kg
      let weightInKg = 0;
      if (weightUnit === "lbs") {
        weightInKg = weightObj?.value * 0.453592;
      } else {
        weightInKg = weightObj?.value;
      }
      if (heightInMetres === 0 || weightInKg === 0) return;
      let bmi = (weightInKg / (heightInMetres * heightInMetres)).toFixed(2);
      let bmiNum = parseFloat(bmi);
      let bmiNotes = "";
      let style = {};
      if (bmiNum < 18.5) {
        bmiNotes = "Underweight";
        style = {
          bgcolor: "#FFAC82 !important",
        };
      } else if (bmiNum >= 18.5 && bmiNum < 24.9) {
        bmiNotes = "Normal";
      } else if (bmiNum >= 25 && bmiNum < 29.9) {
        bmiNotes = "Overweight";
        style = {
          bgcolor: "#FFAC82 !important",
        };
      } else {
        bmiNotes = "Obese";
        style = {
          bgcolor: "#FFAC82 !important",
        };
      }
      return (
        <TableRow sx={style}>
          <TableCell>BMI</TableCell>
          <TableCell>{bmi}</TableCell>
          <TableCell>kg/m²</TableCell>
          <TableCell>{bmiNotes}</TableCell>
        </TableRow>
      );
    }
  };
  renderUnsavedChangesRow = () => {
    if (this.state.hasChanges) {
      return (
        <Stack
          direction="row"
          spacing={0.5}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            width: "100%",
          }}
        >
          {this.props.renderType === "patientLetter" ? null : (
            <Typography variant="h6">Data</Typography>
          )}
          <Stack direction="row" spacing={0.5} alignItems={"center"}>
            {this.props.isSaving ? (
              <Chip
                label="Saving Changes"
                size="large"
                icon={<Iconify icon="svg-spinners:pulse-rings-3" />}
              />
            ) : (
              <Chip
                label="There are unsaved changes"
                color="error"
                size="large"
                icon={<Iconify icon="ep:warning-filled" />}
              />
            )}
            <LoadingButton
              loading={this.props.isSaving}
              variant="contained"
              color="warning"
              onClick={() => {
                this.props.onSave(this.state.data);
              }}
            >
              Save Changes
            </LoadingButton>
          </Stack>
        </Stack>
      );
    }
    let icb = (
      <IconButton
        onClick={() => {
          this.setState({
            showData: true,
          });
        }}
      >
        <Iconify icon="mingcute:arrows-down-fill" />
      </IconButton>
    );
    if (this.state.showData) {
      icb = (
        <IconButton
          onClick={() => {
            this.setState({
              showData: false,
            });
          }}
        >
          <Iconify icon="mingcute:arrows-up-fill" />
        </IconButton>
      );
    }
    return (
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent="space-between"
      >
        {this.props.renderType === "patientLetter" ? null : (
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="flex-start"
          >
            <Typography variant="h6">Data</Typography> {icb}
          </Stack>
        )}
      </Stack>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <Alert severity="error">Error processing data</Alert>;
    }

    return (
      <Stack spacing={1}>
        {this.renderUnsavedChangesRow()}
        {this.renderData()}
      </Stack>
    );
  }
}

export default WithAPICall(DataComponentGeneric);
