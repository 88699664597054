import { Container, Typography, Stack, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card } from "@tremor/react";
import { WithAPICall } from "../utils/apiUtil";
import { use } from "i18next";
import { COMPANY_ADDON_FEE_TYPES } from "src/statics/addon-fees";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  TextField,
  Snackbar,
  Grid,
  Switch,
} from "@mui/material";
import cogoToast from "cogo-toast";
import _ from "lodash";
import { useProtectedApi } from "@/hooks/use-apiCall";

const VisitFinance = ({ visit, updateAddonFees }) => {
  const { callApi } = useProtectedApi();

  const [serviceBookingInfo, setServiceBookingInfo] = useState(
    visit.serviceBookingInfo
  );

  const [providerCosts, setProviderCosts] = useState(
    visit.providerPSLsInfo.map((psl) => ({
      providerServiceAlias: psl.providerServiceAlias,
      costModel: psl.costModel,
      baseCostUSD: psl.baseCostUsd || 0,
      bookingChannel:
        serviceBookingInfo[psl.masterService]?.bookingChannel || "INTERNAL",
      isMarkedCancelled:
        serviceBookingInfo[psl.masterService]?.isMarkedCancelled || false,
    }))
  );

  const updateServiceBookingInfo = async () => {
    try {
      await callApi("/visit/updateVisitScalars", {
        visitId: visit._id,
        uops: [{ key: "serviceBookingInfo", value: serviceBookingInfo }],
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error updating service booking info.");
    }
  };

  useEffect(() => {
    updateServiceBookingInfo();
  }, [serviceBookingInfo]);

  if (
    visit.wasScheduledWithFormFox &&
    visit.formFoxOrderData.MarketplaceOrderNumber
  ) {
    providerCosts.push({
      providerServiceAlias: "FormFox Marketplace Cost",
      costModel: "FFMP",
      baseCostUSD: visit.formFoxOrderData.TotalPrice[0],
      bookingChannel: "FORMFOX",
    });
  }

  if (
    visit.wasScheduledWithFormFox &&
    visit.formFoxOrderData.MarketplaceOrderNumber == null
  ) {
    providerCosts.push({
      providerServiceAlias: "FormFox (Non-Marketplace) Order",
      costModel: "FF",
      baseCostUSD: 0,
      bookingChannel: "FORMFOX",
    });
  }

  const [rows, setRows] = useState(visit.addOnFees || []);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const addRow = () => {
    // Check if there's any row with an empty feeType
    const hasEmptyFeeType = rows.some((row) => row.addOnFeeType === "");
    if (hasEmptyFeeType) {
      setOpenSnackbar(true);
      return;
    }
    setRows([...rows, { addOnFeeType: "", addOnFeeUSD: "" }]);
  };
  const saveFees = async () => {
    try {
      cogoToast.loading("Updating addon fees...");
      setRows(
        rows.filter((row) => row.addOnFeeType !== "" && row.addOnFeeUSD > 0)
      );
      await updateAddonFees(rows);
      setOpenSnackbar(false);
    } catch (err) {
      console.log(err);
      cogoToast.error("Error updating addon fees.");
    }
  };

  const handleFeeTypeChange = (index, value) => {
    // Prevent adding a fee type that already exists
    if (rows.some((row) => row.addOnFeeType === value)) {
      setOpenSnackbar(true);
      return;
    }
    const newRows = [...rows];
    newRows[index].addOnFeeType = value;
    if (newRows[index].addOnFeeUSD == "") {
      const stdRate = _.find(
        visit.company.addOnFees || [],
        (f) => f.addOnFeeType === value
      );
      newRows[index].addOnFeeUSD = stdRate?.addOnFeeUSD || 0;
    }
    setRows(newRows);
  };

  const handleValueChange = (index, value) => {
    const newRows = [...rows];
    newRows[index].addOnFeeUSD = parseFloat(value);
    setRows(newRows);
  };

  const handleRemove = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const [companyRevenue, setCompanyRevenue] = useState(
    visit.protocolsInfo.flatMap(
      (pr) =>
        (visit.protocolServiceMetadata || {})[pr._id]?.map((svc) => ({
          componentName: `${pr.companyProtocolName} - ${svc.serviceName}`,
          costModel: svc.serviceCostModel,
          baseCostUSD: svc.serviceBaseCostUsd || 0,
          isCompleted: true, // Default to true, you may want to adjust this based on your data
        })) || []
    )
  );

  const handleToggleCompanyService = (index) => {
    const newCompanyRevenue = [...companyRevenue];
    newCompanyRevenue[index].isCompleted =
      !newCompanyRevenue[index].isCompleted;
    setCompanyRevenue(newCompanyRevenue);
  };

  const totalRevenue =
    _.sum(
      companyRevenue.filter((c) => c.isCompleted).map((c) => c.baseCostUSD || 0)
    ) + _.sum(rows.map((r) => parseFloat(r.addOnFeeUSD) || 0));
  const totalCosts = _.sum(
    providerCosts.filter((c) => !c.isMarkedCancelled).map((c) => c.baseCostUSD)
  );
  const profit = totalRevenue - totalCosts;

  const handleToggleService = (index) => {
    const newProviderCosts = [...providerCosts];
    newProviderCosts[index].isMarkedCancelled =
      !newProviderCosts[index].isMarkedCancelled;

    setServiceBookingInfo((sbi) => {
      const newSbi = { ...sbi };
      const ms = visit.providerPSLsInfo[index].masterService;
      newSbi[ms] = {
        ...newSbi[ms],
        isMarkedCancelled: newProviderCosts[index].isMarkedCancelled,
      };
      return newSbi;
    });
    setProviderCosts(newProviderCosts);
  };

  return (
    <Stack spacing={2}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="overline" gutterBottom>
          Summary
        </Typography>
        <TableContainer>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>
                  <strong>Total Costs:</strong>
                </TableCell>
                <TableCell align="right">${totalCosts.toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Total Revenue:</strong>
                </TableCell>
                <TableCell align="right">${totalRevenue.toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography variant="body2" color="textSecondary">
                    Revenue Breakdown:
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ paddingLeft: 4 }}>Company Revenue:</TableCell>
                <TableCell align="right">
                  $
                  {_.sum(
                    companyRevenue
                      .filter((c) => c.isCompleted)
                      .map((c) => c.baseCostUSD || 0)
                  ).toFixed(2)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ paddingLeft: 4 }}>Addon Fees:</TableCell>
                <TableCell align="right">
                  $
                  {_.sum(
                    rows.map((r) => parseFloat(r.addOnFeeUSD) || 0)
                  ).toFixed(2)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Profit:</strong>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    color={profit >= 0 ? "success.main" : "error.main"}
                  >
                    ${profit.toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="overline" gutterBottom>
          Provider Costs: {visit.provider.providerName}
        </Typography>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Network</TableCell>
                <TableCell>Line Item</TableCell>
                <TableCell>Cost Model</TableCell>
                <TableCell>Base Cost (USD)</TableCell>
                <TableCell>Service Completed</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {providerCosts.map((psl, ix) => (
                <TableRow key={ix}>
                  <TableCell>{psl.bookingChannel}</TableCell>
                  <TableCell>{psl.providerServiceAlias}</TableCell>
                  <TableCell>{psl.costModel}</TableCell>
                  <TableCell>${psl.baseCostUSD}</TableCell>
                  <TableCell>
                    <Switch
                      checked={!psl.isMarkedCancelled}
                      onChange={() => handleToggleService(ix)}
                    />
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={3} align="right">
                  <strong>Total Provider Costs:</strong>
                </TableCell>
                <TableCell colSpan={2}>
                  <strong>${totalCosts.toFixed(2)}</strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="overline" gutterBottom>
          Revenue: {visit.company.companyName}
        </Typography>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Component Name</TableCell>
                <TableCell>Cost Model</TableCell>
                <TableCell>Base Cost (USD)</TableCell>
                {/* <TableCell>Service Completed</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {companyRevenue.map((c, ix) => (
                <TableRow key={ix}>
                  <TableCell>{c.componentName}</TableCell>
                  <TableCell>{c.costModel}</TableCell>
                  <TableCell>${c.baseCostUSD}</TableCell>
                  {/* <TableCell>
                    <Switch
                      checked={c.isCompleted}
                      onChange={() => handleToggleCompanyService(ix)}
                    />
                  </TableCell> */}
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={2} align="right">
                  <strong>Total Revenue:</strong>
                </TableCell>
                <TableCell colSpan={2}>
                  <strong>
                    $
                    {_.sum(
                      companyRevenue.map((c) => c.baseCostUSD || 0)
                    ).toFixed(2)}
                  </strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Paper elevation={3} sx={{ p: 3 }}>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message="Cannot add duplicate or empty Fee Type"
        />
        <Typography variant="overline" gutterBottom>
          Additional / Administrative Fees
        </Typography>
        <TableContainer component={Paper} className="mb-4">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Fee Type</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Select
                      value={row.addOnFeeType}
                      onChange={(e) =>
                        handleFeeTypeChange(index, e.target.value)
                      }
                      fullWidth
                    >
                      {COMPANY_ADDON_FEE_TYPES.map((f) => (
                        <MenuItem key={f.key} value={f.value}>
                          {f.key}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={row.addOnFeeUSD}
                      onChange={(e) => handleValueChange(index, e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => handleRemove(index)}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={1} align="right">
                  <strong>Total Additional Fees:</strong>
                </TableCell>
                <TableCell>
                  <strong>
                    $
                    {_.sum(
                      rows.map((row) => parseFloat(row.addOnFeeUSD) || 0)
                    ).toFixed(2)}
                  </strong>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container spacing={2} className="mt-4">
          <Grid item>
            <Button variant="contained" size="small" onClick={addRow}>
              Add Row
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" size="small" onClick={saveFees}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Stack>
  );
};
export default VisitFinance;
