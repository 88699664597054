import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Container,
  Typography,
  Paper,
  Button,
  Stack,
  Select,
  MenuItem,
  Grid,
  FormControlLabel,
  Chip,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { format } from "date-fns";
import { Card, ProgressBar } from "@tremor/react";
import { LoadingScreen } from "src/components/loading-screen";
import Iconify from "src/components/iconify/iconify";

class CompanyPools extends React.Component {
  state = {
    year: "2024",
    isLoading: false,
    selectedProtocolId: "",
  };
  async componentDidMount() {
    this.setState({ isLoading: true });
    await this.getAllPoolProtocols();
    await this.getPoolDraws();
    this.setState({ isLoading: false });
  }

  getPoolDraws = async () => {
    try {
      const res = await this.props.apiCallPost(
        "/company-protocol/pool/getPoolDrawsForCompany",
        {
          companyId: this.props.id,
          year: "2024",
        }
      );
      // find only the
      this.setState({ ...res });
    } catch (error) {
      console.error("could not get pool draws", error);
    }
  };

  getAllPoolProtocols = async () => {
    try {
      const res = await this.props.apiCallPost(
        "/company-protocol/pool/companyProtocolGetAllPools",
        {
          companyId: this.props.id,
        }
      );
      // get draw details for each active protocol
      for (const protocol of res) {
        try {
          let dRes = await this.props.apiCallPost(
            "/company-protocol/pool/getPoolDrawHelper",
            {
              poolId: protocol._id,
              // TODO: dropdown for this
              effectiveYear: "2024",
            }
          );
          protocol.drawDetails = dRes;
        } catch (error) {
          protocol.drawDetails = [];
          console.error("could not get draw details", error);
        }
      }
      this.setState({
        protocols: res,
        selectedProtocolId: res[res.length - 1]._id,
      });
    } catch (error) {}
  };

  getProtocolNameFromId = (protocolId) => {
    return this.state.protocols.find((p) => p._id === protocolId)
      ?.companyProtocolName;
  };

  renderDrawDetails = () => {
    if (this.state.draws?.length === 0) return <>No Draws</>;
    return (
      <Stack spacing={2} direction="column">
        {this.state.protocols
          ?.filter((d) => d.activeStatus === "ACTIVE")
          ?.map((protocol, index) => (
            <Paper elevation={2} sx={{ mb: 2, p: 1 }} key={index}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Button
                  href={`/protocols/${protocol._id}/?currentTab=POOLS`}
                  variant="outlined"
                  startIcon={<Iconify icon="mingcute:external-link-line" />}
                >
                  {protocol.companyProtocolName}
                </Button>
                {/* <Typography variant="subtitle2">Draw {index + 1}</Typography> */}
                {/* {
                    "canonicalPopulationSize": 104,
                    "drawRequirementDrugs": 10,
                    "drawRequirementAlcohol": 20,
                    "poolCanonicalDrugTestsRequired": 11,
                    "poolCanonicalAlcoholTestsRequired": 21,
                    "poolCanonicalDrugTestsRequiredPerPeriod": 1,
                    "poolCanonicalAlcoholTestsRequiredPerPeriod": 2,
                    "poolDrawsMadeSoFar": 5,
                    "poolDrugTestsDoneSoFar": 1,
                    "poolAlcoholTestsDoneSoFar": 0,
                    "currentPopulationSize": 104,
                    "numDrawsRequiredAnnually": 12
                } */}
                <Chip
                  size="small"
                  variant="outlined"
                  label={`Drug Tests Required ${protocol.drawDetails.poolCanonicalDrugTestsRequired}`}
                />
                <Chip
                  size="small"
                  variant="outlined"
                  label={`Alcohol Tests Required ${protocol.drawDetails.poolCanonicalAlcoholTestsRequired}`}
                />
                <Chip
                  size="small"
                  variant="outlined"
                  label={`Drug Tests Done ${protocol.drawDetails.poolDrugTestsDoneSoFar}`}
                />
                <Chip
                  size="small"
                  variant="outlined"
                  label={`Alcohol Tests Done ${protocol.drawDetails.poolAlcoholTestsDoneSoFar}`}
                />
                {/* <Typography variant="caption">
                  {protocol.drawDetails.drawRequirementDrugs}
                </Typography> */}
              </Stack>
            </Paper>
          ))}
      </Stack>
    );
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingScreen />;
    }
    return (
      <Container maxWidth={10000}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              Pool Information
            </Typography>
          </Grid>
          <Grid item xs={2} justifyContent="end">
            <Stack direction="row" spacing={2}>
              <FormControlLabel
                label="Year"
                labelPlacement="top"
                fullWidth
                xs={{ width: "100%" }}
                control={
                  <Select
                    fullWidth
                    value={this.state.year}
                    onChange={(e) => {
                      this.setState({ year: e.target.value });
                    }}
                  >
                    <MenuItem value={"2024"} key={2024}>
                      2024
                    </MenuItem>
                  </Select>
                }
              />
            </Stack>
          </Grid>
        </Grid>
        {this.renderDrawDetails()}
      </Container>
    );
  }
}

export default WithAPICall(CompanyPools);
