import React from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Iconify from "src/components/iconify";
import { WithAPICall } from "../utils/apiUtil";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import { Box } from "@mui/system";
import { Button, LinearProgress } from "@mui/material";
import cogoToast from "cogo-toast";
import { View500 } from "src/sections/error";
import MasterProtocolSettings from "./MasterProtocolSettings";
import MasterProtocolClearances from "./MasterProtocolClearances";
import MasterProtocolNotes from "./MasterProtocolNotes";
import MasterProtocolServices from "./MasterProtocolServices";

class MasterProtocolDetails extends React.Component {
  state = {
    currentTab: "INFO",
    protocolData: null,
    isLoading: true,
    isError: false,
  };
  componentDidMount() {
    this.getById();
  }
  getById = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost(
        "/master-protocol/getMasterProtocolById",
        {
          masterProtocolId: this.props.protocolId,
        }
      );
      this.setState({ protocolData: res, isLoading: false });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Protocol Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };

  tabs = [
    {
      value: "INFO",
      label: "Info",
      icon: <Iconify icon="solar:info-circle-broken" width={24} />,
    },
    {
      value: "SERVICES",
      label: "Services In Scope",
      icon: <Iconify icon="solar:document-medicine-outline" width={24} />,
    },
    {
      value: "CLEARANCE",
      label: "Clearances",
      icon: <Iconify icon="solar:clipboard-broken" width={24} />,
    },
    {
      value: "NOTES",
      label: "Notes & Documents",
      icon: <Iconify icon="solar:document-add-linear" width={24} />,
    },
  ];
  renderTabs = () => {
    return (
      <Tabs
        value={this.state.currentTab}
        onChange={(_, value) => this.setState({ currentTab: value })}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        {this.tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            icon={tab.icon}
            value={tab.value}
          />
        ))}
      </Tabs>
    );
  };
  renderTab = () => {
    if (!this.state.protocolData) return null;

    switch (this.state.currentTab) {
      case "INFO":
        return (
          <MasterProtocolSettings
            protocolId={this.props.protocolId}
            data={this.state.protocolData}
            onUpdate={(key, value) => {
              let protocolData = this.state.protocolData;
              protocolData[key] = value;
              this.setState({ protocolData });
            }}
          />
        );

      case "SERVICES":
        return (
          <MasterProtocolServices
            protocolId={this.props.protocolId}
            data={this.state.protocolData}
            onUpdate={(key, value) => {
              let protocolData = this.state.protocolData;
              protocolData[key] = value;
              this.setState({ protocolData });
            }}
          />
        );
      case "NOTES":
        return (
          <MasterProtocolNotes
            protocolId={this.props.protocolId}
            data={this.state.protocolData}
          />
        );
      case "CLEARANCE":
        return (
          <MasterProtocolClearances
            protocolId={this.props.protocolId}
            data={this.state.protocolData}
            onUpdate={(key, value) => {
              let protocolData = this.state.protocolData;
              protocolData[key] = value;
              this.setState({ protocolData });
            }}
          />
        );

      default:
        return <div>Not Live</div>;
    }
  };
  renderHeader = () => {
    let protocolName =
      this.state.protocolData?.masterProtocolName || "Protocol";

    return (
      <CustomBreadcrumbs
        heading={protocolName}
        links={[
          { name: "Dashboard", href: "/" },
          { name: `Master Protocol`, href: `/masterprotocols` },
          { name: `Details: ${protocolName}` },
        ]}
        action={
          <Box>
            <Button
              disabled={this.state.isLoading}
              onClick={() => this.getById()}
              variant="contained"
              startIcon={
                <Iconify icon="mingcute:refresh-anticlockwise-1-line" />
              }
            >
              Refresh Data
            </Button>
          </Box>
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <>
        {this.renderHeader()}
        {this.renderTabs()}
        {this.renderTab()}
      </>
    );
  }
}

export default WithAPICall(MasterProtocolDetails);
