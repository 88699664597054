import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Alert,
  Button,
  Card,
  Chip,
  Drawer,
  LinearProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { NoResultView, View500 } from "src/sections/error";
import cogoToast from "cogo-toast";
import { Box, Stack } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Iconify from "src/components/iconify";
import { UploadBox } from "src/components/upload";
import { readString } from "react-papaparse";
import _ from "lodash";
import moment from "moment";
import { niceDate } from "../utils/fn";

function trimKeysAndRemoveDuplicates(originalObj) {
  const newObj = {};

  for (const key in originalObj) {
    if (originalObj.hasOwnProperty(key)) {
      const trimmedKey = key.trim();
      if (!newObj.hasOwnProperty(trimmedKey)) {
        newObj[trimmedKey] = originalObj[key];
      }
    }
  }

  return newObj;
}

class CompanySpecificData extends React.Component {
  state = {
    csf: [],
    isLoading: true,
    hasLoadingStarted: false,
    hasSetColumns: false,
    isError: false,
    isLoadingData: false,
    loadedData: [],
    columns: [],
    selectedCSF: "",
    isOpen: false,
    csvErrors: [],
    csvData: [],
    csvMeta: {},
    dateMappers: {},
    processingCSV: false,
    csvProcessingErrors: [],
    csvDoneCount: 0,
    processingDone: false,
    aggregations: {
      sum: (params) => this.sumAggregation(params),
      average: (params) => this.averageAggregation(params),
      max: (params) => this.maxAggregation(params),
      min: (params) => this.minAggregation(params),
      count: (params) => this.countAggregation(params),
    },
  };
  buffer = [];
  timer = null;
  componentDidMount() {
    this.getById();
  }
  getById = async () => {
    try {
      let res = await this.props.apiCallPost("/company/csf/get", {
        companyId: this.props.id,
      });
      this.setState({ csf: res, isLoading: false }, () => {
        console.log("saved csf", this.state.csf);
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Employer Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  loadData = async () => {
    try {
      cogoToast.loading("Loading data, this might take a while!");
      this.setState({
        loadedData: [],
        columns: [],
        isLoadingData: true,
        hasLoadingStarted: false,
        hasSetColumns: false,
      });
      this.buffer = [];
      this.timer = setInterval(() => {
        let bufferLength = this.buffer.length;
        let loadedDataLength = this.state.loadedData.length;
        let diff = bufferLength - loadedDataLength;
        if (diff > 0) {
          this.setState(
            {
              loadedData: [
                ...this.state.loadedData,
                ...this.buffer.slice(loadedDataLength, bufferLength),
              ],
            },
            () => {
              if (!this.state.hasSetColumns) {
                this.setColumns();
              }
            }
          );
        }
      }, 100);
      await this.props.apiCallPostStreaming(
        "/company/data/search",
        {
          companyId: this.props.id,
          fieldName: this.state.selectedCSF,
          query: "",
        },
        (data) => {
          if (!this.state.hasLoadingStarted) {
            this.setState({ hasLoadingStarted: true });
          }
          this.buffer.push(...data);
        },
        () => {
          this.setState(
            { isLoadingData: false, hasLoadingStarted: false },
            async () => {
              // wait 4 seconds
              await new Promise((resolve) => setTimeout(resolve, 4000));
              clearInterval(this.timer);
            }
          );
        }
      );
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Employer Data");
      this.setState({
        isLoadingData: false,
        isError: true,
      });
    }
  };
  setColumns = () => {
    let selectedCSF = this.state.selectedCSF;
    let sIndex = this.state.csf.findIndex(
      (each) => each.fieldName === selectedCSF
    );
    let fdf = this.state.csf[sIndex].fieldDataFields;
    let fdfNames = fdf.map((each) => each.fieldDataFieldName);
    let columns = fdfNames.map((each) => {
      return {
        headerName: each,
        field: each,
        enableRowGroup: true,
        enablePivot: true,
        filter: "agMultiColumnFilter",
      };
    });
    this.setState({ columns: columns, hasSetColumns: true });
  };
  renderChooseCSF = () => {
    let csf = this.state.csf;
    // if (!csf || csf.length === 0) {
    //   return (
    //     <div>
    //       <NoResultView />
    //     </div>
    //   );
    // }
    let cc = csf.filter((x) => x.needsLookUp);
    let filteredCSFList = cc.filter(
      (each) => each.fieldDataFields && each.fieldDataFields.length > 0
    );
    let csfList = filteredCSFList.map((each) => each.fieldName);
    // if (!csfList || csfList.length === 0) {
    //   return (
    //     <div>
    //       <NoResultView />
    //     </div>
    //   );
    // }
    return (
      <Stack direction="row" spacing={3}>
        <Button disabled>Choose Field</Button>
        <Select
          disabled={this.state.isLoadingData}
          sx={{ minWidth: 200 }}
          value={this.state.selectedCSF}
          onChange={(e) => {
            this.setState({
              selectedCSF: e.target.value,
              hasLoadingStarted: false,
              hasSetColumns: false,
              isError: false,
              isLoadingData: false,
              loadedData: [],
              columns: [],
            });
          }}
        >
          {csfList.map((each) => (
            <MenuItem value={each}>{each}</MenuItem>
          ))}
        </Select>
        <LoadingButton
          loading={this.state.isLoadingData}
          variant="contained"
          disabled={!this.state.selectedCSF}
          onClick={() => {
            this.loadData();
          }}
        >
          Load Data
        </LoadingButton>
        {this.renderLoadedSoFar()}
        {this.renderUploadBox()}
      </Stack>
    );
  };
  renderLoadedSoFar = () => {
    if (!this.state.hasLoadingStarted) return null;
    return (
      <Chip
        icon={<Iconify icon="carbon:progress-ring" />}
        label={`Loaded ${this.state.loadedData.length} records`}
        variant="outlined"
      />
    );
  };
  uploadCSV = (e) => {
    const file = e[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      readString(content, {
        header: true,
        complete: (results) => {
          let selectedCSF = this.state.selectedCSF;
          let sIndex = this.state.csf.findIndex(
            (each) => each.fieldName === selectedCSF
          );
          let fdf = this.state.csf[sIndex].fieldDataFields;
          let dateFdfs = fdf.filter((each) => each.fieldDataType === "date");
          let dateFdfNames = dateFdfs.map((each) => each.fieldDataFieldName);
          let obj = {};
          for (let i = 0; i < dateFdfNames.length; i++) {
            obj[dateFdfNames[i]] = "";
          }
          this.setState({
            csvErrors: results.errors,
            csvData: results.data,
            csvMeta: results.meta,
            sampleData: _.sampleSize(results.data, 6),
            dateMappers: obj,
            isOpen: true,
          });
        },
      });
    };
    try {
      reader.readAsText(file);
    } catch (err) {
      cogoToast.error("Error Reading File");
    }
  };
  renderUploadBox = () => {
    return (
      <UploadBox
        disabled={!this.state.selectedCSF}
        onDrop={(e) => this.uploadCSV(e)}
        placeholder={
          <Stack alignItems="center" sx={{ color: "text.disabled" }}>
            <LoadingButton
              loading={this.state.isLoading}
              disabled={!this.state.selectedCSF}
            >
              <Iconify icon="eva:cloud-upload-fill" width={30} />
            </LoadingButton>
          </Stack>
        }
      />
    );
  };
  renderControlBox = () => {
    if (this.state.isLoadingData) return null;
    let list = this.state.loadedData;
    if (!list) return null;
    if (list.length === 0) return null;
    return (
      <Stack direction="row" spacing={1}>
        <TextField
          disabled={this.state.isSearching}
          value={this.state.quickSearchText}
          label="Quick Search"
          onChange={(e) =>
            this.setState({
              quickSearchText: e.target.value,
            })
          }
        />
        <Button
          disabled={this.state.isSearching}
          variant="contained"
          startIcon={<Iconify icon="mingcute:filter-line" />}
          onClick={() => {
            this.setState({
              enableAdvancedFilter: !this.state.enableAdvancedFilter,
            });
          }}
        >
          {this.state.enableAdvancedFilter
            ? "Advanced Filters"
            : "Simple Filters"}
        </Button>
      </Stack>
    );
  };
  sumAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));

    return _.sum(arr);
  };
  averageAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.mean(arr);
  };
  maxAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.max(arr);
  };
  minAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.min(arr);
  };
  countAggregation = (params) => {
    return params.values.length;
  };
  renderGrid = () => {
    if (this.state.isLoadingData && !this.state.hasLoadingStarted)
      return <LinearProgress />;
    let list = this.state.loadedData;
    if (!list || list.length === 0) {
      return <NoResultView />;
    }
    if (!this.state.columns || this.state.columns.length === 0) {
      return <NoResultView />;
    }
    let cs = "ag-theme-quartz";
    let theme = localStorage.getItem("themeMode");
    if (theme && theme === "dark") {
      cs = "ag-theme-quartz-dark";
    }
    return (
      <div className={cs} style={{ height: "60vh" }}>
        <AgGridReact
          getRowId={(params) => params.data._id}
          onGridReady={this.onGridReady}
          rowData={this.state.loadedData}
          columnDefs={this.state.columns}
          defaultColDef={{
            sortable: true,
          }}
          autoSizeStrategy={{
            type: "fitCellContents",
          }}
          pagination={true}
          rowGroupPanelShow={
            this.state.enableAdvancedFilter ? "always" : "never"
          }
          aggFuncs={this.state.aggregations}
          suppressDragLeaveHidesColumns={true}
          rowSelection="multiple"
          rowDragManaged={true}
          enableCharts={true}
          enableRangeSelection={true}
          enableCellTextSelection={true}
          quickFilterText={this.state.quickSearchText}
          sideBar={true}
          animateRows={true}
          enableAdvancedFilter={this.state.enableAdvancedFilter}
          defaultExcelExportParams={{
            author: "WorkCare",
            fileName: `Employee Data`,
            sheetName: `Employee Data`,
          }}
          statusBar={{
            statusPanels: [
              {
                statusPanel: "agTotalAndFilteredRowCountComponent",
                align: "left",
              },
              {
                statusPanel: "agSelectedRowCountComponent",
                align: "left",
              },
              {
                statusPanel: "agAggregationComponent",
                align: "right",
              },
            ],
          }}
        />
      </div>
    );
  };
  renderErrors = (err) => {
    if (!err || err.length === 0)
      return <Alert severity="success">No errors in reading this file.</Alert>;
    return (
      <Stack spacing={2}>
        {err.map((each, i) => {
          return (
            <Alert severity="error" key={i}>
              Row {each.row}: {each.message}
            </Alert>
          );
        })}
      </Stack>
    );
  };
  renderCSVNumbers = (csvData) => {
    if (!csvData || csvData.length === 0) return null;
    return (
      <Stack direction="row" spacing={2}>
        <Chip label={`Rows: ${csvData.length}`} />
        <Chip label={`Columns: ${Object.keys(csvData[0]).length}`} />
      </Stack>
    );
  };
  renderSample = (sampleData) => {
    let sample = sampleData;
    let keys = Object.keys(sample[0]);
    return (
      <div
        style={{
          overflowX: "scroll",
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              {keys.map((each, i) => {
                return (
                  <TableCell key={i} padding="none">
                    {each}
                  </TableCell>
                );
              })}
            </TableRow>{" "}
          </TableHead>{" "}
          <TableBody>
            {sample.map((each, i) => {
              return (
                <TableRow key={i}>
                  {keys.map((key, j) => {
                    return (
                      <TableCell key={j} padding="none">
                        {each[key]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  };
  renderMapper = (sampleData) => {
    let selectedCSF = this.state.selectedCSF;
    let sIndex = this.state.csf.findIndex(
      (each) => each.fieldName === selectedCSF
    );
    let fdf = this.state.csf[sIndex].fieldDataFields;
    let dateFdfs = fdf.filter((each) => each.dataType === "date");
    let dateFdfNames = dateFdfs.map((each) => each.fieldDataFieldName);
    let sample = sampleData;
    if (!dateFdfNames || dateFdfNames.length === 0) {
      return (
        <Alert severity="info">
          No date fields found in the definition of this data.
        </Alert>
      );
    }
    return dateFdfNames.map((each, i) => {
      return (
        <Stack spacing={2} key={i}>
          {" "}
          <Box
            rowGap={2}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(2, 1fr)",
              sm: "repeat(3, 1fr)",
            }}
          >
            <Chip label={`Column: ${each}`} />
            <TextField
              label="Date Format"
              value={this.state.dateMappers[each]}
              onChange={(e) => {
                let dateMappers = this.state.dateMappers;
                dateMappers[each] = e.target.value;
                this.setState({ dateMappers });
              }}
            />
          </Box>
          <Box
            rowGap={2}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(2, 1fr)",
              sm: "repeat(3, 1fr)",
            }}
          >
            {sample.map((eachSample, j) => {
              return (
                <div key={`${i}_${j}`}>
                  Input: {eachSample[each]} is understood as:
                  {niceDate(
                    new Date(
                      moment(eachSample[each], this.state.dateMappers[each])
                    )
                  )}
                </div>
              );
            })}
          </Box>
        </Stack>
      );
    });
  };
  renderButton = () => {
    if (this.state.processingDone) {
      let errorRows = this.state.csvProcessingErrors
        .map((each) => each.row)
        .join(", ");
      return (
        <Alert severity="success">
          Processing Done! {this.state.csvDoneCount} rows done.{" "}
          {this.state.csvProcessingErrors.length} error(s).{" "}
          {errorRows.length > 0 ? `Error rows: ${errorRows}` : ""}
        </Alert>
      );
    }
    return (
      <Stack>
        {" "}
        {this.renderWhileProcessing()}
        <LoadingButton
          loading={this.state.processingCSV}
          variant="contained"
          onClick={() => {
            this.processCSV();
          }}
        >
          Process This File
        </LoadingButton>
      </Stack>
    );
  };
  renderDrawerContent = () => {
    if (!this.state.isOpen) return null;
    let csvErrors = this.state.csvErrors;
    let csvData = this.state.csvData;
    let sampleData = this.state.sampleData;
    return (
      <div
        style={{
          width: "80vw",
        }}
      >
        <Card
          sx={{
            p: 2,
            boxShadow: 3,
          }}
        >
          <Stack spacing={4}>
            <Typography variant="h6">CSV Parsing Results</Typography>
            {this.renderErrors(csvErrors)}
            {this.renderCSVNumbers(csvData)}{" "}
            <Typography variant="h6">Samples</Typography>
            {this.renderSample(sampleData)}
            <Typography variant="h6">Formatting Dates</Typography>
            {this.renderMapper(sampleData)}
            {this.renderButton()}
          </Stack>
        </Card>
      </div>
    );
  };
  renderWhileProcessing = () => {
    if (!this.state.processingCSV) return null;
    return (
      <Stack spacing={2}>
        <Alert severity="info">
          Processing CSV, {this.state.csvDoneCount} rows done.{" "}
          {this.state.csvProcessingErrors.length} error(s) so far.
        </Alert>
        <LinearProgress
          variant="determinate"
          value={Math.round(
            (this.state.csvDoneCount / this.state.csvData.length) * 100
          )}
        />
      </Stack>
    );
  };
  processCSV = async () => {
    let data = this.state.csvData;
    let selectedCSF = this.state.selectedCSF;
    let sIndex = this.state.csf.findIndex(
      (each) => each.fieldName === selectedCSF
    );
    let dataUpload = data.map((each) => {
      let obj = trimKeysAndRemoveDuplicates(each);
      let dateMapperKeys = Object.keys(this.state.dateMappers);
      for (let i = 0; i < dateMapperKeys.length; i++) {
        let key = dateMapperKeys[i].trim;
        obj[key] = moment(obj[key], this.state.dateMappers[key]).toDate();
      }

      let fdf = this.state.csf[sIndex].fieldDataFields;
      let numberFields = fdf.filter((each) => each.dataType === "number");
      let booleanFields = fdf.filter((each) => each.dataType === "boolean");
      let numberFieldNames = numberFields.map(
        (each) => each.fieldDataFieldName
      );
      let booleanFieldNames = booleanFields.map(
        (each) => each.fieldDataFieldName
      );
      for (let i = 0; i < numberFieldNames.length; i++) {
        let key = numberFieldNames[i];
        obj[key] = Number(obj[key]);
      }
      for (let i = 0; i < booleanFieldNames.length; i++) {
        let key = booleanFieldNames[i];
        obj[key] = obj[key] === "true";
      }
      return obj;
    });
    this.setState({
      processingCSV: true,
    });

    for (var i = 0; i < dataUpload.length; i++) {
      let each = dataUpload[i];
      try {
        await this.props.apiCallPost("/company/data/addRow", {
          companyId: this.props.id,
          csfId: this.state.csf[sIndex]._id,
          doc: each,
        });
        this.setState({ csvDoneCount: this.state.csvDoneCount + 1 });
      } catch (err) {
        console.log(err);
        this.setState({
          csvProcessingErrors: [
            ...this.state.csvProcessingErrors,
            {
              row: i,
            },
          ],
        });
      }
    }
    this.setState({
      processingCSV: false,
      processingDone: true,
    });
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <div>
        <Drawer
          anchor="right"
          open={this.state.isOpen}
          onClose={() => {
            this.setState({ isOpen: false });
          }}
        >
          {this.renderDrawerContent()}
        </Drawer>
        <Stack spacing={3}>
          <Stack spacing={2} direction="row">
            {this.renderChooseCSF()} {this.renderControlBox()}
          </Stack>
          {this.renderGrid()}
        </Stack>
      </div>
    );
  }
}

export default WithAPICall(CompanySpecificData);
