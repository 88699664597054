import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { WithAPICall } from "../utils/apiUtil";
import cogoToast from "cogo-toast";
import { LoadingButton } from "@mui/lab";
import { FormControlLabel, MenuItem, Select } from "@mui/material";
import { Stack } from "@mui/system";

class VisitTimelineEventCreate extends React.Component {
  state = {
    header: "",
    notes: "",
    isCreating: false,
  };
  STATUS_OPTIONS = [
    "REQUESTED",
    "SCHEDULING_IN_PROGRESS",
    "SCHEDULED",
    "AWAITING_DOCUMENTS",
    "DOCUMENTS_RECEIVED",
    "DOCUMENTS_PROCESSED",
    "DOCUMENTS_IN_REVIEW",
    "DOCUMENTS_REVIEWED",
    "COMPLETED",
    "CANCELLED",
    "NO_SHOW",
    "RESCHEDULING_REQUESTED",
  ];
  EVENT_OPTIONS = [
    { event: "Employee Scheduling Preference Updated", showEmployer: true },
    { event: "Data Received", showEmployer: false },
    { event: "Data Removed", showEmployer: false },
    { event: "Employee Outreach Attempt - Scheduling", showEmployer: true },
    { event: "Employee Outreach Attempt - Documents", showEmployer: true },
    { event: "Provider Outreach Attempt", showEmployer: true },
    { event: "Rescheduling Request Received", showEmployer: true },
    { event: "Documents Received", showEmployer: true },
    { event: "Documents Processed", showEmployer: true },
    { event: "Documents In Review", showEmployer: true },
    { event: "Documents Reviewed", showEmployer: true },
    { event: "Service Clearance", showEmployer: false },
    { event: "Protocol Clearance", showEmployer: false },
    { event: "Visit Scheduled", showEmployer: true },
    { event: "Visit Cancelled", showEmployer: true },
    { event: "Visit Completed", showEmployer: true },
    { event: "Visit No Show", showEmployer: true },
    { event: "Medical Hold", showEmployer: true },
  ];

  createFromName = async () => {
    this.setState({ isCreating: true });
    try {
      let payload = {
        visitId: this.props.visitId,
        header: this.state.header,
        notes: this.state.notes,
        creator: "User",
      };
      if (
        this.state.header.includes("Employee Outreach Attempt - Scheduling")
      ) {
        payload = {
          ...payload,
          statusTo: "OUTREACH_FOR_SCHEDULING",
        };
      }
      if (this.state.header.includes("Employee Outreach Attempt - Documents")) {
        payload = {
          ...payload,
          statusTo: "PENDING_INFORMATION_FROM_EMPLOYEE",
        };
      }
      if (this.state.header.includes("Provider Outreach")) {
        payload = {
          ...payload,
          statusTo: "AWAITING_DOCUMENTS",
        };
      }
      if (this.state.header.includes("Rescheduling Request Received")) {
        payload = {
          ...payload,
          statusTo: "RESCHEDULING_REQUESTED",
        };
      }
      if (this.state.header.includes("Documents Received")) {
        payload = {
          ...payload,
          statusTo: "DOCUMENTS_RECEIVED",
        };
      }
      if (this.state.header.includes("Documents Processed")) {
        payload = {
          ...payload,
          statusTo: "DOCUMENTS_PROCESSED",
        };
      }
      if (this.state.header.includes("Documents In Review")) {
        payload = {
          ...payload,
          statusTo: "DOCUMENTS_IN_REVIEW",
        };
      }
      if (this.state.header.includes("Documents Reviewed")) {
        payload = {
          ...payload,
          statusTo: "DOCUMENTS_REVIEWED",
        };
      }
      if (this.state.header.includes("Visit Scheduled")) {
        payload = {
          ...payload,
          statusTo: "SCHEDULED",
        };
      }
      if (this.state.header.includes("Visit Cancelled")) {
        payload = {
          ...payload,
          statusTo: "CANCELLED",
        };
      }
      if (this.state.header.includes("Visit Completed")) {
        payload = {
          ...payload,
          statusTo: "COMPLETED",
        };
      }
      if (this.state.header.includes("Visit No Show")) {
        payload = {
          ...payload,
          statusTo: "NO_SHOW",
        };
      }

      cogoToast.loading("Logging Event");
      const res = await this.props.apiCallPost(
        "/visit/events/createNewVisitEvent",
        payload
      );
      this.props.onCreate(res);
      cogoToast.success("Event Logged");
    } catch (err) {
      cogoToast.error("Error Logging Event");
      console.log(err);
    }
    this.setState({ isCreating: false });
  };
  renderTypeOfEvent = () => {
    return (
      <FormControlLabel
        label="Type of Event"
        labelPlacement="start"
        control={
          <Select
            disabled={this.state.isCreating}
            sx={{ minWidth: 200 }}
            value={this.state.header}
            onChange={(e) => {
              this.setState({
                header: e.target.value,
              });
            }}
          >
            {this.EVENT_OPTIONS.map((each, idx) => (
              <MenuItem key={idx} value={each.event}>
                {each.event}
              </MenuItem>
            ))}
          </Select>
        }
        sx={{
          m: 0,
          width: 1,
          justifyContent: "space-between",
        }}
      />
    );
  };
  render() {
    return (
      <Dialog open={this.props.isOpen} onClose={() => this.props.handleClose()}>
        <DialogTitle>Log A New Event</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose the type of event <br />
            <br />
            <br />
          </DialogContentText>
          <Stack spacing={2}>
            {" "}
            {this.renderTypeOfEvent()}{" "}
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              name="name"
              label="Notes"
              fullWidth
              disabled={this.state.isCreating}
              value={this.state.notes}
              onChange={(e) => {
                this.setState({ notes: e.target.value });
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.handleClose()}
            disabled={this.state.isCreating}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={this.state.isCreating}
            disabled={!this.state.header}
            variant="contained"
            onClick={() => this.createFromName()}
          >
            Log Event
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}
export default WithAPICall(VisitTimelineEventCreate);
