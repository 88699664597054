import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import { Button, Card, LinearProgress, Typography } from "@mui/material";
import { View500 } from "src/sections/error";
import cogoToast from "cogo-toast";
import { Box, Stack } from "@mui/system";
import Iconify from "src/components/iconify";
import Grid from "@mui/system/Unstable_Grid/Grid";

class VisitProviderInfoBox extends React.Component {
  state = {
    isLoading: true,
    contacts: [],
  };
  componentDidMount() {
    this.getById();
  }
  getById = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost("/provider/getProviderById", {
        providerId: this.props.providerId,
      });
      this.setState({ ...res }, () => {
        this.getContacts();
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Provider Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  getContacts = async () => {
    try {
      const res = await this.props.apiCallPost("/contacts/getByEntityId", {
        entityType: "Provider",
        entityId: this.props.providerId,
      });
      this.setState({ contacts: res, isLoading: false });
    } catch (err) {
      cogoToast.error("Error Loading Provider Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
      console.log(err);
    }
  };
  renderAddress = () => {
    let keys = [
      "providerAddress",
      "providerAddressQualilfier",
      "providerCity",
      "providerState",
      "providerPostalCode",
      "providerCountry",
    ];
    let vals = keys
      .map((key) => {
        return this.state[key];
      })
      .filter(Boolean);
    if (vals.length === 0) {
      return (
        <Stack spacing={1}>
          <Typography variant="overline">No Address On File</Typography>
        </Stack>
      );
    }
    let geoElem = null;
    if (this.state.providerGeoLat && this.state.providerGeoLong) {
      let lat = this.state.providerGeoLat;
      let long = this.state.providerGeoLong;
      let url = `https://www.google.com/maps/@${lat},${long},15z?hl=en`;
      geoElem = (
        <Box>
          {" "}
          <Button
            href={url}
            // target="non_blank"
            size="small"
            startIcon={<Iconify icon="flowbite:map-pin-outline" />}
          >
            Open in Google Maps
          </Button>
        </Box>
      );
    }
    return (
      <Stack spacing={1}>
        <Typography variant="overline">Location</Typography>{" "}
        <Typography variant="caption">{vals.join(", ")}</Typography>
        {geoElem}
      </Stack>
    );
  };
  renderContactInfo = () => {
    let keys = ["providerPhone", "providerEmail", "providerFax"];
    let vals = keys.map((key) => {
      return {
        type: key.substring(8),
        value: this.state[key] || "Not On File",
      };
    });

    return (
      <Stack spacing={1}>
        <Typography variant="overline">Contact Info</Typography>
        <Stack spacing={1}>
          {vals.map((each) => {
            return (
              <Stack direction="row" spacing={1} alignContent={"center"}>
                <Typography variant="caption">{each.type}</Typography>{" "}
                <Typography variant="caption">{each.value}</Typography>{" "}
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    );
  };
  renderContacts = () => {
    if (!this.state.contacts.length) {
      return (
        <Stack spacing={1}>
          <Typography variant="overline">No Contacts Added</Typography>
        </Stack>
      );
    }
    return (
      <Stack spacing={1}>
        <Typography variant="overline">Contacts</Typography>{" "}
        <Stack spacing={1}>
          {this.state.contacts.map((contact) => {
            return (
              <Card
                sx={{
                  p: 1,
                }}
              >
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1} alignContent={"center"}>
                    <Typography variant="overline">
                      {contact.contactName}{" "}
                    </Typography>{" "}
                    <Typography variant="caption">
                      {contact.contactType}{" "}
                    </Typography>{" "}
                  </Stack>{" "}
                  <Stack direction="row" spacing={1} alignContent={"center"}>
                    <Typography variant="caption">Office Phone</Typography>
                    <Typography variant="caption">
                      {contact.officePhone || "Not On File"}{" "}
                    </Typography>
                  </Stack>{" "}
                  <Stack direction="row" spacing={1} alignContent={"center"}>
                    <Typography variant="caption">Cell Phone</Typography>
                    <Typography variant="caption">
                      {contact.cellPhone || "Not On File"}{" "}
                    </Typography>
                  </Stack>{" "}
                  <Stack direction="row" spacing={1} alignContent={"center"}>
                    <Typography variant="caption">Email</Typography>
                    <Typography variant="caption">
                      {contact.email || "Not On File"}{" "}
                    </Typography>
                  </Stack>
                </Stack>
              </Card>
            );
          })}
        </Stack>
      </Stack>
    );
  };
  renderSchedulingNotes = () => {
    return (
      <Stack spacing={1}>
        <Typography variant="overline">Scheduling Notes</Typography>{" "}
        <Typography variant="caption">
          {this.summarizeTestingAvailability()[0]}
        </Typography>{" "}
        <Typography variant="caption">
          {this.summarizeTestingAvailability()[1]}
        </Typography>
        {this.state.schedulingNotes ? (
          <Typography variant="caption">
            {this.state.schedulingNotes}
          </Typography>
        ) : null}
      </Stack>
    );
  };
  summarizeTestingAvailability = (testingInfo) => {
    // Helper function to format the availability and preschedule requirement
    function formatAvailability(
      hasBusinessHours,
      requiresBusinessHoursPreschedule,
      hasAfterHours,
      requiresAfterHoursPreschedule,
      testingType
    ) {
      let availability = `${testingType}: `;
      let parts = [];

      if (hasBusinessHours) {
        parts.push(
          `Business Hours${
            requiresBusinessHoursPreschedule ? " (Requires Preschedule)" : ""
          }`
        );
      }
      if (hasAfterHours) {
        parts.push(
          `After Hours${
            requiresAfterHoursPreschedule ? " (Requires Preschedule)" : ""
          }`
        );
      }

      if (parts.length === 0) {
        return `${availability}Not Available`;
      }

      availability += parts.join(" & ");
      return availability;
    }

    // Construct the strings for DA Testing and Medical Testing
    const daTestingAvailability = formatAvailability(
      this.state.hasBusinessHoursDATesting,
      this.state.requiresBusinessHoursDATestingPreschedule,
      this.state.hasAfterHoursDATesting,
      this.state.requiresAfterHoursDATestingPreschedule,
      "DA Testing"
    );

    const medicalTestingAvailability = formatAvailability(
      this.state.hasBusinessHoursMedicalTesting,
      this.state.requiresBusinessHoursMedicalTestingPreschedule,
      this.state.hasAfterHoursMedicalTesting,
      this.state.requiresAfterHoursMedicalTestingPreschedule,
      "Medical Testing"
    );

    return [daTestingAvailability, medicalTestingAvailability];
  };
  formatWeeklyTimings = (weeklyTimings) => {
    // Initialize an object with all days of the week set to "No Schedule Specified"
    const formattedSchedule = {
      Monday: "No Schedule Specified",
      Tuesday: "No Schedule Specified",
      Wednesday: "No Schedule Specified",
      Thursday: "No Schedule Specified",
      Friday: "No Schedule Specified",
      Saturday: "No Schedule Specified",
      Sunday: "No Schedule Specified",
    };

    // Helper function to format the schedule string for a single entry
    function formatScheduleEntry(entry) {
      let parts = [];
      if (entry.isOpen24Hrs) {
        parts.push("Open 24 Hours");
      } else {
        parts.push(`${entry.startTime} to ${entry.endTime}`);
      }
      let flags = [];
      if (entry.apptOnly) flags.push("Appointment Only");
      if (entry.allowsWalkIn) flags.push("Allows Walk-Ins");
      if (entry.notes) flags.push(entry.notes);

      return (
        parts.join(", ") + (flags.length ? " (" + flags.join(", ") + ")" : "")
      );
    }

    weeklyTimings.forEach((entry) => {
      const day = entry.dayOfWeek;
      // Check for 24 hours open first
      if (entry.isOpen24Hrs) {
        formattedSchedule[day] = `Open 24 Hours${
          entry.apptOnly ? ", Appointment Only" : ""
        }${entry.allowsWalkIn ? ", Allows Walk-Ins" : ""}`;
      } else if (
        formattedSchedule[day] === "No Schedule Specified" ||
        !formattedSchedule[day].includes("Open 24 Hours")
      ) {
        // If the day already has a schedule, append; otherwise, replace "No Schedule Specified"
        const scheduleString = formatScheduleEntry(entry);
        formattedSchedule[day] =
          formattedSchedule[day] === "No Schedule Specified"
            ? scheduleString
            : formattedSchedule[day] + "; " + scheduleString;
      }
    });

    return formattedSchedule;
  };
  renderWeeklySchedule = () => {
    const formattedSchedule = this.formatWeeklyTimings(
      this.state.weeklyTimings
    );
    return (
      <Stack spacing={1}>
        <Typography variant="overline">Weekly Schedule</Typography>
        <Stack spacing={1}>
          {Object.entries(formattedSchedule).map(([day, schedule]) => (
            <Stack direction="row" spacing={1} alignContent={"center"}>
              <Typography variant="caption">{day}</Typography>
              <Typography variant="caption">{schedule}</Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <div>
        {" "}
        <Box
          rowGap={2}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(2, 1fr)",
            sm: "repeat(3, 1fr)",
            md: "repeat(4, 1fr)",
          }}
        >
          {this.renderAddress()}
          {this.renderContactInfo()}
          {this.renderContacts()}
          {this.renderSchedulingNotes()}
          {this.renderWeeklySchedule()}
        </Box>{" "}
      </div>
    );
  }
}

export default WithAPICall(VisitProviderInfoBox);
