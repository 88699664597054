import { Helmet } from "react-helmet-async";

import { View403 } from "src/sections/error";

// ----------------------------------------------------------------------

export default function Page403() {
  return (
    <>
      <Helmet>
        <title> WorkCare | ME & T</title>
      </Helmet>

      <View403 />
    </>
  );
}
