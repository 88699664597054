import React from "react";
import { WithAPICall } from "./apiUtil";

class ImageLoaderForGrid extends React.Component {
  state = {
    isLoading: true,
  };
  componentDidMount() {
    setTimeout(this.getFile, 3000); // Delay execution for 3 seconds
  }
  getFile = async () => {
    if (!this.props.value) {
      this.setState({ isLoading: false });
      return;
    }

    try {
      let which = this.props.apiPrefix;
      const res = await this.props.apiCallPost(`/${which}/getFile`, {
        fileId: this.props.value,
      });
      this.setState({ isLoading: false, psUrl: res.psUrl }, async () => {
        await new Promise((resolve) => setTimeout(resolve, 3000));
        this.props.onLoad();
      });
    } catch (err) {
      console.log(err);
    }
  };
  render() {
    if (this.state.isLoading) return null;
    if (this.state.psUrl === undefined) return null;
    if (this.state.psUrl === null) return null;

    return (
      <img
        src={this.state.psUrl}
        style={{
          display: "block",
          width: "30px",
          height: "auto",
          marginRight: "5px",
          filter: "brightness(1.1)",
        }}
      ></img>
    );
  }
}

export default WithAPICall(ImageLoaderForGrid);
