import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import { Box, Stack } from "@mui/system";
import {
  Button,
  Chip,
  FormControlLabel,
  IconButton,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Iconify from "src/components/iconify";
import cogoToast from "cogo-toast";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

class ServiceClearances extends React.Component {
  state = {
    clearanceOptions: [],
    isLoading: true,
  };
  componentDidMount() {
    this.setState({
      ...this.props.data,
      isLoading: false,
    });
  }
  CLEARANCE_TYPE_OPTIONS = [
    "Cleared",
    "Cleared Provisionally",
    "Cleared With Restriction",
    "Not Cleared",
    "Null Clearance",
  ];
  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(this.state.clearanceOptions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    this.setState(
      {
        clearanceOptions: items,
      },
      () => {
        this.updateScalarValue("clearanceOptions", this.state.clearanceOptions);
      }
    );
  };
  updateScalarValue = async (key, value) => {
    try {
      this.setState({
        isSaving: true,
      });
      let res = await this.props.apiCallPost(
        "/master-service/masterServiceUpdateScalarValue",
        {
          masterServiceId: this.props.id,
          key: key,
          value: value,
        }
      );
      this.setState({
        ...res,
        isSaving: false,
      });
    } catch (err) {
      this.setState({
        isSaving: false,
        clearanceOptions: this.props.data.clearanceOptions,
      });
      cogoToast.error("Error Updating Service Data");
      console.log(err);
    }
  };
  renderClearanceHeader = () => {
    return (
      <Stack spacing={3}>
        <ListItemText
          sx={{
            p: 3,
            borderRadius: 2,
            bgcolor: "background.neutral",
          }}
          primary={
            <Stack direction="row" alignItems="center" spacing={3}>
              <Typography variant="h6">Clearances</Typography>
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  let newObj = {
                    criteria: "",
                    text: "",
                    case: "",
                    typeOfClearance: "",
                  };
                  this.setState(
                    {
                      clearanceOptions: [
                        ...this.state.clearanceOptions,
                        newObj,
                      ],
                    },
                    () => {
                      this.updateScalarValue(
                        "clearanceOptions",
                        this.state.clearanceOptions
                      );
                    }
                  );
                }}
                sx={{
                  width: 24,
                  height: 24,
                  bgcolor: "primary.main",
                  color: "primary.contrastText",
                  "&:hover": {
                    bgcolor: "primary.dark",
                  },
                }}
              >
                <Iconify icon="mingcute:add-line" />
              </IconButton>
            </Stack>
          }
          secondary="Set clearange language defaults and criteria here."
          primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
          secondaryTypographyProps={{ component: "span" }}
        />
      </Stack>
    );
  };
  renderClearances = () => {
    if (!this.state.clearanceOptions.length) {
      return null;
    }
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="clearances">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {this.state.clearanceOptions.map((clearance, i) => (
                <Draggable
                  key={clearance._id}
                  draggableId={clearance._id}
                  index={i}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {this.renderEachClearance(clearance, i)}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };
  renderEachClearance = (clearance, i) => {
    return (
      <Stack
        spacing={2}
        sx={{
          mt: 2,
          p: 3,
          borderRadius: 2,
          bgcolor: "background.neutral",
        }}
      >
        <Box>
          <Button
            variant="outlined"
            startIcon={<Iconify icon="mdi:drag" />}
            sx={{
              cursor: "grab", // Change cursor to indicate draggable
            }}
            {...this.props.dragHandleProps}
          >
            Clearance Template {i + 1}
          </Button>
        </Box>

        <Box
          rowGap={2}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
          }}
        >
          {" "}
          <FormControlLabel
            label="Type of Clearance"
            labelPlacement="start"
            disabled={this.state.isSaving}
            control={
              <Select
                disabled={this.state.isSaving}
                value={clearance.typeOfClearance}
                sx={{
                  minWidth: "300px",
                }}
                onChange={(e) => {
                  let clearanceOptions = this.state.clearanceOptions;
                  clearanceOptions[i].typeOfClearance = e.target.value;
                  this.setState(
                    {
                      clearanceOptions,
                    },
                    () => {
                      this.updateScalarValue(
                        "clearanceOptions",
                        this.state.clearanceOptions
                      );
                    }
                  );
                }}
              >
                {this.CLEARANCE_TYPE_OPTIONS.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            }
            sx={{
              m: 0,
              width: 1,
              justifyContent: "space-between",
            }}
          />
          <TextField
            disabled={this.state.isSaving}
            placeholder="Case"
            label="Case"
            value={clearance.case}
            multiline
            onChange={(e) => {
              let clearanceOptions = this.state.clearanceOptions;
              clearanceOptions[i].case = e.target.value;
              this.setState({
                clearanceOptions,
              });
            }}
            onBlur={() => {
              this.updateScalarValue(
                "clearanceOptions",
                this.state.clearanceOptions
              );
            }}
          />
          <TextField
            disabled={this.state.isSaving}
            label="Clearance Language"
            value={clearance.text}
            multiline
            onChange={(e) => {
              let clearanceOptions = this.state.clearanceOptions;
              clearanceOptions[i].text = e.target.value;
              this.setState({
                clearanceOptions,
              });
            }}
            onBlur={() => {
              this.updateScalarValue(
                "clearanceOptions",
                this.state.clearanceOptions
              );
            }}
          />{" "}
          <TextField
            placeholder="Use this for clearances that are easy to auto-clear from data perspectives; eg a Drug Screen that has all negative results can be auto-cleared."
            disabled={this.state.isSaving}
            label="Clearance Criteria"
            value={clearance.criteria}
            multiline
            onChange={(e) => {
              let clearanceOptions = this.state.clearanceOptions;
              clearanceOptions[i].criteria = e.target.value;
              this.setState({
                clearanceOptions,
              });
            }}
            onBlur={() => {
              this.updateScalarValue(
                "clearanceOptions",
                this.state.clearanceOptions
              );
            }}
          />
        </Box>
        <Box>
          {" "}
          <Chip
            disabled={this.state.isSaving}
            color="error"
            label="Delete"
            variant="outlined"
            onDelete={() => {
              let clearanceOptions = this.state.clearanceOptions;
              clearanceOptions.splice(i, 1);
              this.setState(
                {
                  clearanceOptions,
                },
                () => {
                  this.updateScalarValue(
                    "clearanceOptions",
                    this.state.clearanceOptions
                  );
                }
              );
            }}
          />
        </Box>
      </Stack>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <div>
        {" "}
        <Stack spacing={4}>
          {this.renderClearanceHeader()}
          {this.renderClearances()}
        </Stack>
      </div>
    );
  }
}

export default WithAPICall(ServiceClearances);
