import Card from "@mui/material/Card";
import FormControlLabel from "@mui/material/FormControlLabel";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Unstable_Grid2";

import { TextField } from "@mui/material";
import cogoToast from "cogo-toast";
import React from "react";
import { WithAPICall } from "../utils/apiUtil";

class CompanyFormFox extends React.Component {
  state = {
    hasDemographicIntegration: false,
  };
  componentDidMount() {
    this.setState({ ...this.props.data });
  }
  SETTINGS = [
    {
      subheader: "FormFox Details",
      caption: "Set parameters required to create FormFox Orders",
      items: [
        {
          id: "disableFormFox",
          label: "Disables FormFox integration for this Company",
          isSwitch: true,
        },

        {
          id: "labCorpDotAccountNumber",
          label: "Lab Corp Account/Facility Number (DOT)",
          isSwitch: false,
        },
        {
          id: "labCorpNonDotAccountNumber",
          label: "Lab Corp Account/Facility Number (NON-DOT)",
          isSwitch: false,
        },
        {
          id: "crlDotAccountNumber",
          label: "CRL Account/Facility Number (DOT)",
          isSwitch: false,
        },
        {
          id: "crlNonDotAccountNumber",
          label: "CRL Account/Facility Number (NON-DOT)",
          isSwitch: false,
        },
        {
          id: "crlPoctAccountNumber",
          label: "CRL Account/Facility Number (POCT)",
          isSwitch: false,
        },
      ],
    },
  ];
  updateScalarValue = async (key, value) => {
    if (value === null || value === undefined) {
      return;
    }
    if (this.props.data[key] === value) {
      return;
    }
    try {
      const res = await this.props.apiCallPost("/company/updateScalarValue", {
        companyId: this.props.data._id,
        key: key,
        value: value,
      });
      this.setState({ ...res });
    } catch (err) {
      cogoToast.error("Error Updating Employer Data");
      console.log(err);
    }
  };
  render() {
    return (
      <div>
        <Stack component={Card} spacing={3} sx={{ p: 3 }}>
          {this.SETTINGS.map((setting, index) => {
            if (index === 2 && !this.state.hasPersonalIdentifierOnClearance)
              return null;
            return (
              <Grid key={setting.subheader} container spacing={3}>
                <Grid xs={12} md={4}>
                  <ListItemText
                    primary={setting.subheader}
                    secondary={setting.caption}
                    primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
                    secondaryTypographyProps={{ component: "span" }}
                  />
                </Grid>
                <Grid xs={12} md={8}>
                  <Stack
                    spacing={1}
                    sx={{
                      p: 3,
                      borderRadius: 2,
                      bgcolor: "background.neutral",
                    }}
                  >
                    <>
                      {setting.items.map((item) => (
                        <FormControlLabel
                          key={item.id}
                          label={item.label}
                          labelPlacement="start"
                          control={
                            item.isSwitch ? (
                              <Switch
                                disabled={item.nonEditable}
                                checked={this.state[item.id]}
                                onChange={() => {
                                  this.setState(
                                    {
                                      [item.id]: !this.state[item.id],
                                    },
                                    () => {
                                      this.updateScalarValue(
                                        item.id,
                                        this.state[item.id]
                                      );
                                    }
                                  );
                                }}
                              />
                            ) : (
                              <TextField
                                value={this.state[item.id]}
                                onChange={(e) => {
                                  this.setState({
                                    [item.id]: e.target.value,
                                  });
                                }}
                                onBlur={() => {
                                  this.updateScalarValue(
                                    item.id,
                                    this.state[item.id]
                                  );
                                }}
                              />
                            )
                          }
                          sx={{
                            m: 0,
                            width: 1,
                            justifyContent: "space-between",
                          }}
                        />
                      ))}
                    </>
                  </Stack>
                </Grid>
              </Grid>
            );
          })}
        </Stack>
      </div>
    );
  }
}
export default WithAPICall(CompanyFormFox);
