import { LoadingButton } from "@mui/lab";
import { RiErrorWarningLine } from "@remixicon/react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Box, Stack } from "@mui/system";
import cogoToast from "cogo-toast";
import _, { assign, cloneDeep, debounce } from "lodash";
import moment from "moment";
import React from "react";
import Iconify from "src/components/iconify";
import { WithAPICall } from "../utils/apiUtil";
import { formatTime, niceDateTime } from "../utils/fn";
import TokenContext from "src/TokenContext";
import { format } from "date-fns";
import VisitClearanceDetails from "./VisitClearanceDetails";
import { Card, DonutChart, List, ListItem } from "@tremor/react";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class ClearanceList extends React.Component {
  static contextType = TokenContext;
  state = {
    isLoading: true,
    // cu
    currentUser: {
      isEmployee: true,
      isExternal: true,
    },
    //user
    isSearchingUser: false,
    userSearchResults: [],
    currentlyChosenUser: null,

    // company
    isSearchingCompany: false,
    companySearchResults: [],
    currentlyInCompany: null,
    // other filters
    filterStatus: [],
    filterEmployee: "",
    filterDateRange: [null, null],
    filterMasterServiceIds: [],
    filterProtocolIds: [],
    // search results
    isSearchingVisits: false,
    hasSearched: false,
    visits: [],
    options: [],
    loadingOptions: false,
    protocolOptions: [],
    loadingCompanyProtocols: false,
    assignedTo: [],

    // assignment
    isAssigning: false,
    selectedVisitIds: [],

    // users

    currentlyChosenRN: null,
    currentlyChosenMD: null,
    //
    isLoadingRNandMD: false,
    rnAndMDUsers: [],
    showRNandMDFilter: ["RN", "MD"],
    isBulkSetting: false,
    tasksFilteredForMe: false,
  };
  VISIT_STATUS = [
    "PARTIAL_DOCUMENTS_RECEIVED",
    "REVIEW_NOT_STARTED",
    "IN_RN_REVIEW",
    "IN_MD_REVIEW",
    "PENDING_INFORMATION_FROM_EMPLOYEE",
    "PENDING_AFFIDAVIT",
    "CLEARED",
    "CLEARED_WITH_RESTRICTIONS",
    "NOT_CLEARED",
  ];
  componentDidMount() {
    this.getCurrentUser();
    this.debouncedFetchOptions = debounce(this.fetchOptions, 500);
  }
  fetchOptions = async (inputValue) => {
    this.setState({
      loadingOptions: true,
    });
    if (inputValue) {
      try {
        const res = await this.props.apiCallPost(
          "/master-service/masterServiceFts",
          {
            limit: 1000,
            query: inputValue,
            skip: 0,
          }
        );
        this.setState({ options: res });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      this.setState({ options: [] });
    }
    this.setState({
      loadingOptions: true,
    });
  };
  getCurrentUser = async () => {
    try {
      const res = await this.props.apiCallPost("/user/getCurrentUser", {});
      let cu = res;
      let isEmployee = cu.isEmployee;
      let isExternal = cu.isExternal;
      this.setState({
        currentUser: cu,
      });
      if (isEmployee) {
        this.setState(
          {
            currentUser: cu,
            currentlyInCompany: {
              mainText: res.company.companyName,
              secondaryText: `${res.company.companyInformalName} ${res.companyWebsite}`,
              data: res.company,
            },
            isLoading: false,
          },
          () => {
            this.getVisitsByEmployeeId();
          }
        );
      } else if (isExternal) {
        this.setState(
          {
            currentUser: cu,
            currentlyInCompany: {
              mainText: res.company.companyName,
              secondaryText: `${res.company.companyInformalName} ${res.companyWebsite}`,
              data: res.company,
            },
            filterDateRange: [
              moment().subtract(7, "days"),
              moment().add(7, "days"),
            ],
            isLoading: false,
          },
          () => {
            this.searchVisits();
          }
        );
      } else if (this.props.fromAssociationPage && this.props.extractedEEName) {
        this.setState(
          {
            isLoading: false,
            filterEmployee: this.props.extractedEEName,
            filterDateRange: [null, null],
          },
          () => {
            this.searchVisits();
          }
        );
      } else {
        this.setState(
          {
            isLoading: false,
            filterDateRange: [
              moment().subtract(7, "days"),
              moment().add(7, "days"),
            ],
            assignedTo: [res._id],
            tasksFilteredForMe: true,
          },
          () => {
            this.searchVisits();
          }
        );
      }
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading User Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  STATUS_OPTIONS = [
    "REQUESTED",
    "SCHEDULING_IN_PROGRESS",
    "SCHEDULED",
    "AWAITING_DOCUMENTS",
    "DOCUMENTS_RECEIVED",
    "DOCUMENTS_PROCESSED",
    "DOCUMENTS_IN_REVIEW",
    "DOCUMENTS_REVIEWED",
    "COMPLETED",
    "CANCELLED",
    "NO_SHOW",
    "RESCHEDULING_REQUESTED",
  ];
  getRNandMDUsers = async () => {
    this.setState({
      isLoadingRNandMD: true,
    });
    try {
      let res = await this.props.apiCallPost("/visit/getUserWorkload", {});
      // sort by workload - lowest first. if no key, assyme 0, within same workload, sort by name
      res = res.sort((a, b) => {
        let aWorkload = a.workload || 0;
        let bWorkload = b.workload || 0;
        if (aWorkload === bWorkload) {
          return a.name.localeCompare(b.name);
        }
        return aWorkload - bWorkload;
      });

      this.setState({
        isLoadingRNandMD: false,
        rnAndMDUsers: res,
      });
    } catch (err) {
      this.setState({
        isLoadingRNandMD: false,
      });
      cogoToast.error("Error Loading RN and MD Users");
    }
  };
  searchUsersFTS = async (t) => {
    try {
      let res = await this.props.apiCallPostCancellable("/user/searchUser", {
        query: t,
        company: null,
        showDeactivatedAlso: false,
      });
      if (res) {
        this.setState({
          isSearchingUser: false,
          userSearchResults: res.map((each) => {
            return {
              mainText: each.name,
              secondaryText: each.email,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingUser: false,
        userSearchResults: [],
      });
      cogoToast.error("Error Searching Users");
    }
  };
  searchCompaniesFTS = async (t) => {
    try {
      let res = await this.props.apiCallPostCancellable("/company/search", {
        query: t,
      });
      if (res) {
        this.setState({
          isSearchingCompany: false,
          companySearchResults: res.map((each) => {
            return {
              mainText: each.companyName,
              secondaryText: `${each.companyInformalName} ${each.companyWebsite}`,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingCompany: false,
        companySearchResults: [],
      });
      cogoToast.error("Error Searching Companies");
    }
  };
  searchVisits = async () => {
    let allStatus = [
      "PARTIAL_DOCUMENTS_RECEIVED",
      "REVIEW_NOT_STARTED",
      "IN_RN_REVIEW",
      "IN_MD_REVIEW",
      "PENDING_INFORMATION_FROM_EMPLOYEE",
      "PENDING_AFFIDAVIT",
      "CLEARED",
      "CLEARED_WITH_RESTRICTIONS",
      "NOT_CLEARED",
    ];
    let visitStatusParam = this.state.filterStatus;
    if (!visitStatusParam.length) {
      visitStatusParam = allStatus;
    }
    let assignedTo = this.state.assignedTo;
    if (this.state.tasksFilteredForMe) {
      assignedTo = [this.context.currentUser._id];
    } else if (this.state.currentlyChosenUser) {
      assignedTo = [this.state.currentlyChosenUser.data._id];
    } else {
      assignedTo = null;
    }
    let payload = {
      visitOutcome: [],
      visitStatus: visitStatusParam,
      companyId: this.state.currentlyInCompany?.data?._id || null,
      employeeName: this.state.filterEmployee || null,
      masterServiceIds: this.state.filterMasterServiceIds.map((s) => s._id),
      protocolIds: this.state.filterProtocolIds.map((s) => s?._id),
      assignedTo: assignedTo,
      scheduledIn: [],
    };

    try {
      this.setState({
        isSearchingVisits: true,
      });
      let res = await this.props.apiCallPost("/visit/searchVisits", payload);
      let sortedVisits = res.sort((a, b) => {
        let d1 = new Date(a.firstDocumentReceivedAt);
        let d2 = new Date(b.firstDocumentReceivedAt);
        let diff = d2 - d1;
        return diff;
      });

      this.setState({
        isSearchingVisits: false,
        hasSearched: true,
        visits: sortedVisits,
      });
    } catch (err) {
      this.setState({
        isSearchingVisits: false,
      });
      cogoToast.error("Error Searching Visits");
    }
  };
  getVisitsByEmployeeId = async () => {
    try {
      this.setState({
        isSearchingVisits: true,
      });
      let res = await this.props.apiCallPost(
        "/ext-employee/getVisitsByEmployeeId",
        {
          employeeId: this.state.currentUser.employee,
        }
      );
      this.setState({
        isSearchingVisits: false,
        hasSearched: true,
        visits: res,
      });
    } catch (err) {
      this.setState({
        isSearchingVisits: false,
      });
      cogoToast.error("Error Loading Visits");
    }
  };
  loadCompanyProtocols = async () => {
    cogoToast.loading("Loading company protocols.");
    try {
      const res = await this.props.apiCallPost(
        "/company-protocol/companyProtocolGetAll",
        {
          companyId: this.state.currentlyInCompany.data._id,
        }
      );
      this.setState({
        protocolOptions: res,
      });
      cogoToast.success("Company protocols loaded.");
    } catch (error) {
      console.error("could not get company protocols", error);
    }
  };
  bulkSetVisitAssignee = async (assigneeId, key, userName) => {
    try {
      this.setState({
        isBulkSetting: true,
      });
      let res = await this.props.apiCallPost("/visit/bulkSetVisitAssignee", {
        visitIds: this.state.selectedVisitIds,
        assigneeId: assigneeId,
        key: key,
      });

      let statusTo = key === "assignedMD" ? "IN_MD_REVIEW" : "IN_RN_REVIEW";

      let updatedVisits = this.state.visits.map((x) => {
        if (this.state.selectedVisitIds.includes(x._id)) {
          x.visitStatus = statusTo;
          if (key === "assignedMD") {
            x.assignedMD = {
              _id: assigneeId,
              name: userName,
            };
          } else if (key === "assignedRN") {
            x.assignedRN = {
              _id: assigneeId,
              name: userName,
            };
          }
          return x;
        } else {
          return x;
        }
      });

      let len = this.state.selectedVisitIds.length;
      let name = userName;
      let str = len > 1 ? "s" : "";
      cogoToast.success(`Assigned ${len} visit${str} to ${name}`);
      this.setState({
        selectedVisitIds: [],
        isAssigning: false,
        isBulkSetting: false,
        rnAndMDUsers: [],
        showRNandMDFilter: ["RN", "MD"],
        visits: updatedVisits,
      });
    } catch (err) {
      this.setState({
        isBulkSetting: false,
      });
      cogoToast.error("Error Assigning RN/MD");
    }
  };
  renderCompanySearcher = () => {
    if (
      this.state.currentUser.isExternal ||
      this.state.currentUser.isEmployee
    ) {
      return null;
    }
    return (
      <Autocomplete
        size="small"
        sx={{
          minWidth: "200px",
        }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option?.mainText
        }
        filterOptions={(x) => x}
        options={
          this.state.isSearchingCompany ? [] : this.state.companySearchResults
        }
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={this.state.currentlyInCompany}
        noOptionsText={
          this.state.isSearchingCompany ? "Searching..." : "No results found"
        }
        onChange={(_, value) => {
          this.setState(
            {
              currentlyInCompany: value,
              companySearchResults: [value, ...this.state.companySearchResults],
              protocolOptions: [],
              filterMasterServiceIds: [],
              filterProtocolIds: [],
            },
            () => {
              if (value) {
                this.loadCompanyProtocols();
              }
            }
          );
        }}
        onInputChange={(_, e) => {
          if (e === "") return null;
          if (!e) return null;
          if (e.length < 4) return null;
          this.setState(
            {
              isSearchingCompany: true,
            },
            () => {
              this.searchCompaniesFTS(e);
            }
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label="Search Employers" fullWidth />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ wordWrap: "break-word" }}>
                  <Box component="span">{option?.mainText}</Box>
                  <Typography variant="body2" color="text.secondary">
                    {option?.secondaryText}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    );
  };
  renderStatusSelection = () => {
    return (
      <FormControl>
        <InputLabel id="label" size="small">
          Visit Status
        </InputLabel>
        <Select
          size="small"
          labelId="label"
          placeholder="Status"
          multiple
          value={this.state.filterStatus}
          onChange={(e) => {
            let v = e.target.value;
            this.setState({
              filterStatus: typeof v === "string" ? v.split(",") : v,
            });
          }}
          sx={{
            minWidth: "200px",
          }}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip size="small" key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {this.VISIT_STATUS.map((item) => (
            <MenuItem key={item} value={item} size="small">
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  renderEmployeeSearch = () => {
    return (
      <TextField
        size="small"
        sx={{
          minWidth: "200px",
        }}
        InputLabelProps={{
          shrink: this.state.filterEmployee,
        }}
        label="Search Employees"
        onChange={(e) => {
          let v = e.target.value;
          this.setState({
            filterEmployee: v,
          });
        }}
        value={this.state.filterEmployee}
      />
    );
  };
  renderSearchButton = () => {
    return (
      <LoadingButton
        size="small"
        loading={this.state.isSearchingVisits}
        startIcon={<Iconify icon="lets-icons:search-alt-light" />}
        onClick={this.searchVisits}
        variant="contained"
      >
        Search
      </LoadingButton>
    );
  };
  handleInputChange = (event, newInputValue) => {
    this.setState({ inputValue: newInputValue }, () => {
      this.debouncedFetchOptions(newInputValue);
    });
  };
  handleSetServiceFilter = (serviceDetails) => {
    this.setState((prevState) => {
      const serviceFilters = cloneDeep(this.state.filterMasterServiceIds);
      serviceFilters.push(serviceDetails);
      return { ...prevState, filterMasterServiceIds: serviceFilters };
    });
  };
  handleSetProtocolFilter = (data) => {
    this.setState((prevState) => {
      const protocolDetails = cloneDeep(this.state.filterProtocolIds);
      protocolDetails.push(data);
      return { ...prevState, filterProtocolIds: protocolDetails };
    });
  };
  renderRNSearcher = () => {
    return (
      <Autocomplete
        size="small"
        sx={{
          minWidth: "200px",
        }}
        getOptionLabel={(option) =>
          option ? (typeof option === "string" ? option : option?.mainText) : ""
        }
        filterOptions={(x) => x}
        options={this.state.isSearchingUser ? [] : this.state.userSearchResults}
        autoComplete
        includeInputInList
        filterSelectedOptions
        disabled={this.state.isDoingAction}
        value={this.state.currentlyChosenRN}
        noOptionsText={
          this.state.isSearchingUser ? "Searching..." : "No results found"
        }
        onChange={(_, value) => {
          if (value === null) {
            this.setState({
              currentlyChosenRN: null,
              userSearchResults: [],
              isSearchingUser: false,
            });
          }
          this.setState({
            currentlyChosenRN: value,
            userSearchResults: [value, ...this.state.userSearchResults],
            isSearchingUser: false,
          });
        }}
        onInputChange={(_, e) => {
          if (e === "") return null;
          if (!e) return null;
          if (e.length < 4) return null;
          this.setState(
            {
              isSearchingUser: true,
            },
            () => {
              this.searchUsersFTS(e);
            }
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label="Search Users" fullWidth />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ wordWrap: "break-word" }}>
                  <Box component="span">{option?.mainText}</Box>
                  <Typography variant="body2" color="text.secondary">
                    {option?.secondaryText}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    );
  };
  renderUserSearcher = () => {
    return (
      <Autocomplete
        size="small"
        sx={{
          minWidth: "200px",
        }}
        getOptionLabel={(option) =>
          option ? (typeof option === "string" ? option : option?.mainText) : ""
        }
        filterOptions={(x) => x}
        options={this.state.isSearchingUser ? [] : this.state.userSearchResults}
        autoComplete
        includeInputInList
        filterSelectedOptions
        disabled={this.state.isDoingAction}
        value={this.state.currentlyChosenUser}
        noOptionsText={
          this.state.isSearchingUser ? "Searching..." : "No results found"
        }
        onChange={(_, value) => {
          if (value === null) {
            this.setState({
              currentlyChosenUser: null,
              userSearchResults: [],
              isSearchingUser: false,
            });
          }
          this.setState({
            currentlyChosenUser: value,
            userSearchResults: [value, ...this.state.userSearchResults],
            isSearchingUser: false,
          });
        }}
        onInputChange={(_, e) => {
          if (e === "") return null;
          if (!e) return null;
          if (e.length < 4) return null;
          this.setState(
            {
              isSearchingUser: true,
            },
            () => {
              this.searchUsersFTS(e);
            }
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label="Search Users" fullWidth />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ wordWrap: "break-word" }}>
                  <Box component="span">{option?.mainText}</Box>
                  <Typography variant="body2" color="text.secondary">
                    {option?.secondaryText}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    );
  };
  renderFilterBox = () => {
    if (this.state.currentUser.isEmployee) return null;
    let txt = "Viewing All Pending Visits";
    let color = "info";
    if (this.state.tasksFilteredForMe) {
      txt = "Viewing My Assigned Visits";
      color = "primary";
    }
    let userSearcher = <>{this.renderUserSearcher()}</>;
    if (this.state.tasksFilteredForMe) {
      userSearcher = null;
    }
    const myAssignedTasks = (
      <Button
        size="small"
        variant="contained"
        color={color}
        onClick={() => {
          if (this.state.tasksFilteredForMe) {
            this.setState(
              {
                assignedTo: null,
                tasksFilteredForMe: false,
              },
              () => {
                this.searchVisits();
              }
            );
          } else {
            this.setState(
              {
                assignedTo: [this.context.currentUser._id],
                tasksFilteredForMe: true,
              },
              () => {
                this.searchVisits();
              }
            );
          }
        }}
      >
        {txt}
      </Button>
    );
    let selectAllButton = (
      <Tooltip
        title={
          this.state.selectedVisitIds.length === this.state.visits.length
            ? "Deselect All"
            : "Select All"
        }
      >
        <IconButton
          size="small"
          onClick={() => {
            if (
              this.state.selectedVisitIds.length === this.state.visits.length
            ) {
              this.setState({
                selectedVisitIds: [],
              });
            } else {
              this.setState({
                selectedVisitIds: this.state.visits.map((x) => x._id),
              });
            }
          }}
        >
          <Iconify
            icon={
              this.state.selectedVisitIds.length === this.state.visits.length
                ? "radix-icons:view-none"
                : "fluent:select-all-on-24-filled"
            }
          />
        </IconButton>
      </Tooltip>
    );
    if (!this.state.isAssigning) {
      selectAllButton = null;
    }
    return (
      <Stack
        spacing={2}
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          {this.renderCompanySearcher()}
          {this.renderStatusSelection()}
          {this.renderEmployeeSearch()}
          {userSearcher}
          {myAssignedTasks}
          {this.renderSearchButton()}
        </Stack>{" "}
        <Stack
          direction="row"
          spacing={1}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          {selectAllButton}
          <Button
            color={this.state.isAssigning ? "secondary" : "info"}
            size="small"
            onClick={() => {
              this.setState({ isAssigning: !this.state.isAssigning }, () => {
                if (this.state.isAssigning) {
                  this.getRNandMDUsers();
                }
              });
            }}
          >
            Current Mode: {this.state.isAssigning ? "Assigning" : "Reviewing"}
          </Button>
        </Stack>
      </Stack>
    );
  };
  renderSearchResults = () => {
    if (!this.state.hasSearched) return null;
    if (this.state.isSearchingVisits)
      return (
        <TableRow>
          <TableCell colSpan={5}>
            <LinearProgress />
          </TableCell>
        </TableRow>
      );
    if (!this.state.visits || this.state.visits.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={5}>
            <Alert severity="info">
              No Visits Found. Try modifying the search criteria.
            </Alert>
          </TableCell>
        </TableRow>
      );
    }
    if (
      this.state.currentUser.isEmployee &&
      !this.state.currentUser.isExternal
    ) {
      return this.renderClearanceList();
    }
    return this.renderClearanceList();
  };
  renderClearanceList = () => {
    let v = this.state.visits;
    if (!v || !v.length) {
      return (
        <TableRow>
          <TableCell colSpan={5}>
            <Alert severity="info">
              No Visits are recorded for you at this point.
            </Alert>
          </TableCell>
        </TableRow>
      );
    }
    return v.map((each, index) => {
      return (
        <VisitClearanceDetails
          isBulkSetting={this.state.isBulkSetting}
          isAssigning={this.state.isAssigning}
          key={index}
          data={each}
          isSelected={this.state.selectedVisitIds.includes(each._id)}
          onSelectVisitForAssigning={() => {
            this.setState({
              selectedVisitIds: [...this.state.selectedVisitIds, each._id],
            });
          }}
          onUnselectVisitForAssigning={() => {
            this.setState({
              selectedVisitIds: this.state.selectedVisitIds.filter(
                (x) => x !== each._id
              ),
            });
          }}
        />
      );
    });
  };
  renderTable = () => {
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Employee</TableCell> <TableCell>Employer</TableCell>
            <TableCell>Protocol(s)</TableCell> <TableCell>Clinic(s)</TableCell>
            <TableCell>Lab(s)</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Outcome</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.visits.map((each) => {
            return (
              <TableRow
                key={each._id}
                sx={{
                  borderBottom: "1px solid #C5CBD3 !important",
                }}
              >
                <TableCell>{this.renderEmployee(each)}</TableCell>{" "}
                <TableCell>{each.company.companyName}</TableCell>{" "}
                <TableCell>{this.renderProtocols(each)}</TableCell>{" "}
                <TableCell>{this.renderClinics(each)}</TableCell>{" "}
                <TableCell>{this.renderLabs(each)}</TableCell>
                <TableCell>
                  <Stack spacing={1}>
                    {this.renderNiceText(each, "visitStatus")}
                    {each.requestedByEmployer && (
                      <Chip size="small" color="info" label="Requested" />
                    )}
                  </Stack>
                </TableCell>
                <TableCell>
                  {this.renderNiceText(each, "visitOutcome")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };
  renderNiceText = (each, key) => {
    if (!each[key]) return "N/A";
    return _.startCase(_.lowerCase(each[key]));
  };
  renderEmployee = (each) => {
    let elem = (
      <IconButton
        disabled={this.state.isSearchingVisits}
        size="small"
        color="primary"
        href={`/visits/${each._id}`}
        // target="non_blank"
        sx={{
          width: 24,
          height: 24,
          bgcolor: "primary.main",
          color: "primary.contrastText",
          "&:hover": {
            bgcolor: "primary.dark",
          },
        }}
      >
        <Iconify icon="mynaui:external-link" />
      </IconButton>
    );
    if (this.props.choosingVisit) {
      elem = (
        <IconButton
          disabled={this.state.isSearchingVisits}
          size="small"
          color="primary"
          onClick={() => {
            this.props.onVisitChosen(each);
          }}
          sx={{
            width: 24,
            height: 24,
            bgcolor: "primary.main",
            color: "primary.contrastText",
            "&:hover": {
              bgcolor: "primary.dark",
            },
          }}
        >
          <Iconify icon="teenyicons:tick-circle-solid" />
        </IconButton>
      );
    }
    return (
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <Stack spacing={1} direction="column" alignItems="left">
          <Typography variant="overline">
            {each.employee.employeeName}
          </Typography>
          <Typography variant="caption">
            EID: {each.employee.employeeNumber || "<No EID on file>"}
          </Typography>
          <Typography variant="caption">
            DoB: {format(new Date(each.employee.employeeDoB), "PP")}
          </Typography>
          <Typography variant="caption">
            SSN: {each.employee.employeeSSN || "<No SSN on file>"}
          </Typography>
        </Stack>
        {elem}
      </Stack>
    );
  };
  renderProtocols = (each) => {
    console.log({ each });
    if (!each.bookedProtocols || !each.bookedProtocols.length) {
      return "Not Chosen";
    }
    return (
      <Stack spacing={1}>
        {each.bookedProtocols.map((x) => {
          return (
            <Stack spacing={0}>
              <Typography variant="overline">
                {x.companyProtocolName}
              </Typography>
              <Typography variant="caption">{x.companyProtocolType}</Typography>
            </Stack>
          );
        })}
      </Stack>
    );
  };
  renderLabs = (each) => {
    if (!each.labFrozen) {
      return "Not Chosen";
    }
    if (!each.labPSLs) {
      return "N/A";
    }
    if (!each.labPSLs.length) {
      return "N/A";
    }
    return (
      <Stack spacing={1}>
        {each.labPSLs.map((x) => {
          return (
            <Stack spacing={0}>
              <Typography variant="overline">{x.providerName}</Typography>
              <Typography variant="caption">{x.pslName}</Typography>{" "}
              <Typography variant="caption">
                Collected At: {x.collectingClinicName || "NA"}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    );
  };
  renderClinics = (each) => {
    if (!each.clinicFrozen) {
      return "Not Chosen";
    }
    if (!each.clinicPSLs) {
      return "N/A";
    }
    if (!each.clinicPSLs.length) {
      return "N/A";
    }
    const groupedClinics = _.groupBy(each.clinicPSLs, "providerName");
    return (
      <Stack spacing={2}>
        {Object.keys(groupedClinics).map((x, index) => {
          const y = groupedClinics[x][0];
          let dt = "NA";
          if (!y.scheduledOnDate) {
            dt = null;
          } else {
            dt = new Date(y.scheduledOnDate);
            let tm = new Date(y.scheduledTime);
            dt.setHours(tm.getHours());
            dt.setMinutes(tm.getMinutes());
            dt =
              niceDateTime(dt) +
              " " +
              (y.providerObj.providerReadableTimezone || "");
          }
          return (
            <Stack spacing={0.5} key={index}>
              <Typography variant="overline">{x}</Typography>
              {groupedClinics[x].map((y, yIdx) => {
                return (
                  <Stack spacing={0} key={yIdx}>
                    <Typography variant="caption">{y.pslName}</Typography>{" "}
                  </Stack>
                );
              })}
              {dt && (
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Iconify icon="lucide:calendar" />
                  <Typography variant="caption">
                    Scheduled At: <b>{dt || "NA"}</b>
                  </Typography>
                </Stack>
              )}
            </Stack>
          );
        })}
      </Stack>
    );
  };
  renderHeader = () => {
    return (
      <Stack direction="row" spacing={2} alignItems={"center"}>
        <Typography variant="h4">Clearances</Typography>
      </Stack>
    );
  };
  renderRNandMDUsers = () => {
    if (!this.state.isAssigning) {
      return null;
    }
    if (this.state.isLoadingRNandMD) {
      return <LinearProgress />;
    }
    if (!this.state.rnAndMDUsers || !this.state.rnAndMDUsers.length) {
      return <Alert severity="warning">No RN or MD Users Found</Alert>;
    }
    let RNMDFilterButton = (
      <ToggleButtonGroup
        size="small"
        value={this.state.showRNandMDFilter}
        onChange={(e, v) => {
          this.setState({
            showRNandMDFilter: v,
          });
        }}
        aria-label="text alignment"
      >
        <ToggleButton size="small" value="RN">
          RN
        </ToggleButton>
        <ToggleButton size="small" value="MD">
          MD
        </ToggleButton>
      </ToggleButtonGroup>
    );

    return (
      <Stack spacing={2}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>User {RNMDFilterButton}</TableCell>
              <TableCell>Workload</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.rnAndMDUsers.map((each, idx) => {
              let reviewerType = each.reviewerType;
              if (!this.state.showRNandMDFilter.includes(reviewerType))
                return null;
              let renderAssignButton = null;
              let selectedVisitIds = this.state.selectedVisitIds;
              let numSelectedVisitIds = selectedVisitIds.length;
              if (numSelectedVisitIds > 0) {
                renderAssignButton = (
                  <LoadingButton
                    loading={this.state.isBulkSetting}
                    size="small"
                    sx={{
                      color: "primary.contrastText",
                      bgcolor: "primary.dark",
                    }}
                    onClick={() => {
                      this.bulkSetVisitAssignee(
                        each._id,
                        reviewerType === "RN" ? "assignedRN" : "assignedMD",
                        each.name
                      );
                    }}
                  >
                    Assign {numSelectedVisitIds} Visit
                    {numSelectedVisitIds > 1 && "s"}
                  </LoadingButton>
                );
              }
              let assignElem = (
                <Stack spacing={0.5}>
                  <Typography variant="overline">
                    {" "}
                    Currently Assigned: {each.workload || 0}
                  </Typography>
                  {renderAssignButton}
                </Stack>
              );
              let nameElem = (
                <Stack spacing={0.5}>
                  <Typography variant="overline">{each.name}</Typography>
                  <Typography
                    variant="overline"
                    fontWeight={600}
                    color={reviewerType === "RN" ? "info" : "secondary"}
                  >
                    {reviewerType}
                  </Typography>
                </Stack>
              );

              return (
                <TableRow key={each._id}>
                  <TableCell>{nameElem}</TableCell>
                  <TableCell>{assignElem}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Stack>
    );
  };
  renderGrid = () => {
    if (this.state.isAssigning) {
      return (
        <Grid container spacing={0.5}>
          <Grid item xs={8}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Employee</TableCell>
                  <TableCell>Assigned To</TableCell>
                  <TableCell>
                    Waiting For{" "}
                    <IconButton
                      onClick={() => {
                        let sortedVisits = _.reverse(this.state.visits);
                        this.setState({
                          visits: sortedVisits,
                        });
                      }}
                    >
                      <Iconify icon="icon-park-outline:sort-two"></Iconify>
                    </IconButton>
                  </TableCell>
                  <TableCell>Latest Comment</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{this.renderSearchResults()}</TableBody>
            </Table>
          </Grid>
          <Grid item xs={4}>
            {this.renderRNandMDUsers()}
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={0.5}>
          <Grid item xs={8}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Employee</TableCell>
                  <TableCell>Assigned To</TableCell>
                  <TableCell>
                    Waiting For{" "}
                    <IconButton
                      onClick={() => {
                        let sortedVisits = _.reverse(this.state.visits);
                        this.setState({
                          visits: sortedVisits,
                        });
                      }}
                    >
                      <Iconify icon="icon-park-outline:sort-two"></Iconify>
                    </IconButton>
                  </TableCell>
                  <TableCell>Latest Comment</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{this.renderSearchResults()}</TableBody>
            </Table>
          </Grid>
          <Grid item xs={4}>
            {this.renderChart()}
          </Grid>
        </Grid>
      );
    }
  };
  renderChart = () => {
    if (this.state.isLoading || this.state.isSearchingVisits) {
      return <LinearProgress />;
    }
    let dd = this.state.visits;
    if (!dd || !dd.length) return null;
    let groupByStatus = _.groupBy(dd, "visitStatus");
    let data = Object.keys(groupByStatus).map((key) => {
      return {
        name: key,
        amount: groupByStatus[key].length,
        share: `${((groupByStatus[key].length / dd.length) * 100).toFixed(1)}%`,
      };
    });
    let waitingTimes = dd
      .map((x) => {
        if (!x.firstDocumentReceivedAt) {
          return null;
        }
        let d1 = new Date(x.firstDocumentReceivedAt);
        let d2 = new Date();
        let diff = d2 - d1;
        return Math.round(diff / 1000);
      })
      .filter(Boolean);
    let averageWaitTime = _.mean(waitingTimes);
    let worstWaitTime = _.max(waitingTimes);
    return (
      <>
        <Card className="sm:mx-auto sm:max-w-lg">
          <h3 className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
            Visit Status Distribution
          </h3>
          <DonutChart
            className="mt-8"
            data={data}
            category="amount"
            index="name"
            showTooltip={true}
            colors={["cyan", "blue", "indigo", "violet", "fuchsia", "pink"]}
          />
          <p className="mt-8 flex items-center justify-between text-tremor-label text-tremor-content dark:text-dark-tremor-content">
            <span>Status</span>
            <span>Amount / Share</span>
          </p>
          <List className="mt-2">
            {data.map((item) => (
              <ListItem key={item.name} className="space-x-6">
                <div className="flex items-center space-x-2.5 truncate">
                  <span
                    className={classNames("h-2.5 w-2.5 shrink-0 rounded-sm")}
                    aria-hidden={true}
                  />
                  <span className="truncate dark:text-dark-tremor-content-emphasis">
                    {item.name}
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="font-medium tabular-nums text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    {item.amount}
                  </span>
                  <span className="rounded-tremor-small bg-tremor-background-subtle px-1.5 py-0.5 text-tremor-label font-medium tabular-nums text-tremor-content-emphasis dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis">
                    {item.share}
                  </span>
                </div>
              </ListItem>
            ))}
          </List>
        </Card>
        <Card className="sm:mx-auto sm:max-w-lg">
          <p className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
            Average Waiting Time
          </p>
          <p className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
            {formatTime(averageWaitTime)}
          </p>
          <span
            className={classNames(
              "bg-red-100 text-red-800 dark:bg-red-400/10 dark:text-red-500",
              "mt-4 inline-flex items-center gap-x-1.5 rounded-tremor-small px-2 py-1.5 text-tremor-label font-medium"
            )}
          >
            <RiErrorWarningLine
              className="h-4 w-4 shrink-0"
              aria-hidden={true}
            />
            Worst Case: {formatTime(worstWaitTime)}
          </span>
        </Card>
      </>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <Stack spacing={2}>
        {this.renderHeader()}
        {this.renderFilterBox()}
        {this.renderGrid()}
      </Stack>
    );
  }
}

export default WithAPICall(ClearanceList);
