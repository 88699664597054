import { Helmet } from "react-helmet-async";
import { useParams } from "src/routes/hooks";
import VisitAlcoholClearance from "./VisitAlcoholClearance";

export default function VisitAlcoholClearanceByID() {
  const params = useParams();
  const { id } = params;
  return (
    <>
      <Helmet>
        <title> Protocol Clearance - Alcohol </title>
      </Helmet>
      <VisitAlcoholClearance id={id} />
    </>
  );
}
