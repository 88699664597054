import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { UploadAvatar } from "src/components/upload";
import CountrySelector from "../utils/CountrySelector";
import { WithAPICall } from "../utils/apiUtil";
import GMap from "../utils/GMap";
import UploadSnack from "../utils/UploadSnack";
import cogoToast from "cogo-toast";
import { US_TIMEZONES, offsetToTimezone } from "src/utils/timezone";

class ProviderProfile extends React.Component {
  state = {
    isLoading: true,
  };
  componentDidMount() {
    this.setState(
      {
        ...this.props.data,
        isLoading: false,
      },
      () => {
        if (this.state.providerLogo) {
          this.getFile(this.state.providerLogo);
        }
      }
    );
  }
  updateScalarValue = async (key, value) => {
    if (value === null || value === undefined) {
      return;
    }
    if (this.props.data[key] === value) {
      return;
    }
    try {
      await this.props.apiCallPost("/provider/providerUpdateScalarValue", {
        providerId: this.props.id,
        key: key,
        value: value,
      });
    } catch (err) {
      cogoToast.error("Error Updating Provider Data");
      console.log(err);
    }
  };
  setLogo = async (file) => {
    try {
      const fd = new FormData();
      fd.append("file", file[0]);
      fd.append("providerId", this.props.id);
      fd.append("purpose", "providerLogo");
      const res = await this.props.apiCallPostUpload(
        "/provider/providerSetLogo",
        fd,
        (e) => {
          this.setState({
            uploadProgress: e,
          });
        }
      );
      this.setState({
        uploadProgress: null,
        providerLogoUrl: res,
      });
    } catch (err) {
      cogoToast.error("Error Uploading Logo");
      console.log(err);
    }
  };
  getFile = async (id) => {
    try {
      const res = await this.props.apiCallPost("/provider/getFile", {
        fileId: id,
      });
      this.setState({
        providerLogoUrl: res.psUrl,
      });
    } catch (err) {
      console.log(err);
    }
  };
  setPlace = (place) => {
    console.log("setting place", place);
    this.setState(
      {
        providerCity: place.city,
        providerCountry: place.country,
        providerCountryCallingCode:
          place.country === "United States" ? "1" : "",
        providerCountryShortCode: place.country === "United States" ? "US" : "",
        providerState: place.state,
        providerAddress: place.formatted_address,
        providerGeoLat: place.geolocation.lat,
        providerGeoLong: place.geolocation.lng,
        providerPostalCode: place.postal_code,
        providerUtcOffset: place.utc_offset,
        providerReadableTimezone: offsetToTimezone(place.utc_offset),
      },
      () => {
        console.log(this.state.providerReadableTimezone);
        this.updateScalarValue("providerCity", place.city);
        this.updateScalarValue("providerCountry", place.country);
        this.updateScalarValue(
          "providerCountryCallingCode",
          place.country === "United States" ? "1" : ""
        );
        this.updateScalarValue(
          "providerCountryShortCode",
          place.country === "United States" ? "US" : ""
        );
        this.updateScalarValue("providerState", place.state);
        this.updateScalarValue("providerAddress", place.formatted_address);
        this.updateScalarValue("providerGeoLat", place.geolocation.lat);
        this.updateScalarValue("providerGeoLong", place.geolocation.lng);
        this.updateScalarValue("providerPostalCode", place.postal_code);
        this.updateScalarValue("providerUtcOffset", place.utc_offset);
        this.updateScalarValue(
          "providerReadableTimezone",
          this.state.providerReadableTimezone
        );
      }
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <div>
        <Grid container spacing={3}>
          <Grid xs={12} md={4}>
            <Card sx={{ pt: 3, pb: 3, px: 3, textAlign: "center" }}>
              <Stack spacing={2}>
                <UploadSnack uploadProgress={this.state.uploadProgress} />
                <UploadAvatar
                  onDrop={(e) => this.setLogo(e)}
                  file={this.state.providerLogoUrl}
                />
                <TextField
                  label="Provider Name"
                  value={this.state.providerName}
                  onChange={(e) => {
                    this.setState({ providerName: e.target.value }, () => {
                      this.updateScalarValue(
                        "providerName",
                        this.state.providerName
                      );
                      this.props.onUpdate(
                        "providerName",
                        this.state.providerName
                      );
                    });
                  }}
                  onBlur={() => {
                    // this.updateScalarValue(
                    //   "providerName",
                    //   this.state.providerName
                    // );
                    // this.props.onUpdate(
                    //   "providerName",
                    //   this.state.providerName
                    // );
                  }}
                />
                <TextField
                  label="Informal Name"
                  value={this.state.providerInformalName}
                  onChange={(e) => {
                    this.setState(
                      { providerInformalName: e.target.value },
                      () => {
                        this.updateScalarValue(
                          "providerInformalName",
                          this.state.providerInformalName
                        );
                        this.props.onUpdate(
                          "providerInformalName",
                          this.state.providerInformalName
                        );
                      }
                    );
                  }}
                  onBlur={() => {
                    // this.updateScalarValue(
                    //   "providerInformalName",
                    //   this.state.providerInformalName
                    // );
                    // this.props.onUpdate(
                    //   "providerInformalName",
                    //   this.state.providerInformalName
                    // );
                  }}
                />
                <TextField
                  label="About"
                  multiline
                  value={this.state.providerAbout}
                  onChange={(e) => {
                    this.setState({ providerAbout: e.target.value }, () => {
                      this.updateScalarValue(
                        "providerAbout",
                        this.state.providerAbout
                      );
                    });
                  }}
                  onBlur={() => {
                    // this.updateScalarValue(
                    //   "providerAbout",
                    //   this.state.providerAbout
                    // );
                  }}
                />
              </Stack>
            </Card>
          </Grid>
          <Grid xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={2}>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  }}
                >
                  <PhoneInput
                    defaultCountry="US"
                    placeholder="Enter phone number"
                    value={this.state.providerPhone}
                    onChange={(e) => {
                      this.setState({ providerPhone: e }, () => {
                        this.updateScalarValue(
                          "providerPhone",
                          this.state.providerPhone
                        );
                      });
                    }}
                    onBlur={() => {
                      // this.updateScalarValue(
                      //   "providerPhone",
                      //   this.state.providerPhone
                      // );
                    }}
                  />
                  <PhoneInput
                    defaultCountry="US"
                    placeholder="Enter fax number"
                    value={this.state.providerFax}
                    onChange={(e) => {
                      this.setState({ providerFax: e }, () => {
                        this.updateScalarValue(
                          "providerFax",
                          this.state.providerFax
                        );
                      });
                    }}
                    onBlur={() => {
                      // this.updateScalarValue(
                      //   "providerFax",
                      //   this.state.providerFax
                      // );
                    }}
                  />
                  <TextField
                    label="Website"
                    value={this.state.providerWebsite}
                    onChange={(e) => {
                      this.setState({ providerWebsite: e.target.value }, () => {
                        let ws = this.state.providerWebsite;
                        if (
                          ws.startsWith("http://") ||
                          ws.startsWith("https://")
                        ) {
                          // Do nothing
                        } else {
                          ws = "https://" + ws;
                        }
                        if (this.state.providerWebsite) {
                          this.updateScalarValue("providerWebsite", ws);
                          this.setState({ providerWebsite: ws });
                        }
                      });
                    }}
                    onBlur={() => {
                      // let ws = this.state.providerWebsite;
                      // if (
                      //   ws.startsWith("http://") ||
                      //   ws.startsWith("https://")
                      // ) {
                      //   // Do nothing
                      // } else {
                      //   ws = "https://" + ws;
                      // }
                      // if (this.state.providerWebsite) {
                      //   this.updateScalarValue("providerWebsite", ws);
                      //   this.setState({ providerWebsite: ws });
                      // }
                    }}
                  />
                  <TextField
                    label="Email"
                    value={this.state.providerEmail}
                    onChange={(e) => {
                      this.setState({ providerEmail: e.target.value }, () => {
                        if (this.state.providerEmail) {
                          this.updateScalarValue(
                            "providerEmail",
                            this.state.providerEmail
                          );
                        }
                      });
                    }}
                    onBlur={() => {
                      // if (this.state.providerEmail) {
                      //   this.updateScalarValue(
                      //     "providerEmail",
                      //     this.state.providerEmail
                      //   );
                      // }
                    }}
                  />
                </Box>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  }}
                ></Box>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  }}
                >
                  <Box
                    rowGap={3}
                    columnGap={2}
                    display="grid"
                    gridTemplateColumns={{
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(1, 1fr)",
                    }}
                  >
                    <GMap
                      onPlace={(r) => this.setPlace(r)}
                      geoObject={{
                        lat: this.state.providerGeoLat,
                        lng: this.state.providerGeoLong,
                      }}
                      onLatLng={(geo) => {
                        this.setState({
                          providerGeoLat: geo.lat,
                          providerGeoLong: geo.lng,
                        });
                        this.updateScalarValue("providerGeoLat", geo.lat);
                        this.updateScalarValue("providerGeoLong", geo.lng);
                      }}
                    />
                  </Box>
                  <Box
                    rowGap={1}
                    columnGap={1}
                    display="grid"
                    gridTemplateColumns={{
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(1, 1fr)",
                    }}
                  >
                    <CountrySelector
                      value={{
                        label: this.state.providerCountry || "",
                        code: this.state.providerCountryShortCode || "",
                        phone: this.state.providerCountryCallingCode || "",
                      }}
                      onChange={(e) => {
                        this.setState({
                          country: e,
                          providerCountry: e.label,
                          providerCountryShortCode: e.code,
                          providerCountryCallingCode: e.phone,
                        });
                        this.updateScalarValue("providerCountry", e.label);
                        this.updateScalarValue(
                          "providerCountryShortCode",
                          e.code
                        );
                        this.updateScalarValue(
                          "providerCountryCallingCode",
                          e.phone
                        );
                      }}
                    />

                    <TextField
                      InputLabelProps={{ shrink: this.state.providerAddress }}
                      label="Address Line 1"
                      multiline
                      value={this.state.providerAddress}
                      onChange={(e) => {
                        this.setState(
                          {
                            providerAddress: e.target.value,
                          },
                          () => {
                            this.updateScalarValue(
                              "providerAddress",
                              this.state.providerAddress
                            );
                          }
                        );
                      }}
                      onBlur={() => {
                        // this.updateScalarValue(
                        //   "providerAddress",
                        //   this.state.providerAddress
                        // );
                      }}
                    />
                    <TextField
                      InputLabelProps={{
                        shrink: this.state.providerAddressQualilfier,
                      }}
                      label="Address Line 2"
                      multiline
                      value={this.state.providerAddressQualilfier}
                      onChange={(e) => {
                        this.setState(
                          {
                            providerAddressQualilfier: e.target.value,
                          },
                          () => {
                            this.updateScalarValue(
                              "providerAddressQualilfier",
                              this.state.providerAddressQualilfier
                            );
                          }
                        );
                      }}
                      onBlur={() => {
                        // this.updateScalarValue(
                        //   "providerAddressQualilfier",
                        //   this.state.providerAddressQualilfier
                        // );
                      }}
                    />
                    <TextField
                      InputLabelProps={{ shrink: this.state.providerCity }}
                      label="City"
                      value={this.state.providerCity}
                      onChange={(e) => {
                        this.setState({ providerCity: e.target.value }, () => {
                          this.updateScalarValue(
                            "providerCity",
                            this.state.providerCity
                          );
                        });
                      }}
                      onBlur={() => {
                        // this.updateScalarValue(
                        //   "providerCity",
                        //   this.state.providerCity
                        // );
                      }}
                    />
                    <TextField
                      InputLabelProps={{ shrink: this.state.providerState }}
                      label="State"
                      value={this.state.providerState}
                      onChange={(e) => {
                        this.setState({ providerState: e.target.value }, () => {
                          this.updateScalarValue(
                            "providerState",
                            this.state.providerState
                          );
                        });
                      }}
                      onBlur={() => {
                        // this.updateScalarValue(
                        //   "providerState",
                        //   this.state.providerState
                        // );
                      }}
                    />
                    <TextField
                      InputLabelProps={{
                        shrink: this.state.providerPostalCode,
                      }}
                      label="Postal Code"
                      value={this.state.providerPostalCode}
                      onChange={(e) => {
                        this.setState(
                          { providerPostalCode: e.target.value },
                          () => {
                            this.updateScalarValue(
                              "providerPostalCode",
                              this.state.providerPostalCode
                            );
                          }
                        );
                      }}
                      onBlur={() => {
                        // this.updateScalarValue(
                        //   "providerPostalCode",
                        //   this.state.providerPostalCode
                        // );
                      }}
                    />
                    <FormControl>
                      <InputLabel id="timezone-label">Timezone</InputLabel>
                      <Select
                        labelId="timezone-label"
                        onChange={(e) => {
                          this.setState(
                            { providerReadableTimezone: e.target.value },
                            () => {
                              this.updateScalarValue(
                                "providerReadableTimezone",
                                this.state.providerReadableTimezone
                              );
                            }
                          );
                        }}
                        value={this.state.providerReadableTimezone}
                      >
                        {US_TIMEZONES.map((zone, index) => (
                          <MenuItem value={zone} key={index}>
                            {zone}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default WithAPICall(ProviderProfile);
