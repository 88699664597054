import { useParams } from "src/routes/hooks";

import PatientLetter from "./PatientLetter";

export default function PatientLetterByID() {
  const params = useParams();
  const { id } = params;
  return (
    <>
      <PatientLetter id={id} />
    </>
  );
}
