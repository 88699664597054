import JsBarcode from "jsbarcode";

function generateBarcode(referenceTestId) {
  const canvas = document.createElement("canvas");
  canvas.width = 300;
  canvas.height = 150;
  JsBarcode(canvas, referenceTestId);
  const ctx = canvas.getContext("2d");

  // Get the canvas data as a PNG buffer
  const trimLength = "data:image/png;base64,".length;
  let pngBase64 = canvas.toDataURL("image/png");
  pngBase64 = pngBase64.slice(trimLength, pngBase64.length);

  // Write the PNG buffer to a file
  //   const fs = require("fs");
  //   fs.writeFileSync("./temp/output.png", pngBuffer);
  //   console.log("PNG file exported successfully!");
  //   return "./temp/output.png";
  return pngBase64;
}

export default generateBarcode;
