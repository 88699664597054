import React from "react";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { Alert, Button, LinearProgress } from "@mui/material";
import Iconify from "src/components/iconify";
import { WithAPICall } from "../utils/apiUtil";
import cogoToast from "cogo-toast";
import { View500 } from "src/sections/error";
import CSFCreate from "./CSFCreate";
import ReactList from "react-list";
import CSFIndividual from "./CSFIndividual";
import { cloneDeep } from "lodash";

class CompanySpecificFields extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    isCreateOpen: false,
    companySpecificFields: [],
  };
  componentDidMount() {
    this.getById();
  }
  getById = async () => {
    try {
      let res = await this.props.apiCallPost("/company/csf/get", {
        companyId: this.props.id,
      });
      this.setState({ companySpecificFields: res, isLoading: false });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Employer Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };

  deleteCsf = async ({ csfId }) => {
    try {
      /**
       *  companyId: string;
      csfId: string;
       */
      const payload = {
        companyId: this.props.id,
        csfId,
      };
      const res = await this.props.apiCallPost(`/company/csf/delete`, payload);
      this.setState((prevState) => {
        const csfs = cloneDeep(prevState.companySpecificFields).filter(
          (f) => f._id !== csfId
        );
        return { ...prevState, companySpecificFields: csfs };
      });
      cogoToast.success("Deleted field successfully.");
    } catch (error) {
      console.error("error in csf delete", error);
      cogoToast.error("Could not delete field. Please try again.");
    }
  };

  renderCompanySpecificFields = () => {
    return (
      <Stack spacing={3}>
        <ReactList
          pageSize={64}
          itemRenderer={(index, key) => {
            let each = this.state.companySpecificFields[index];
            return (
              <div
                key={`${key}__${each.updatedAt}`}
                style={{ marginBottom: "10px" }}
              >
                <Card sx={{ p: 3, bgcolor: "background.neutral" }}>
                  <CSFIndividual
                    data={each}
                    companyId={this.props.id}
                    deleteCsf={this.deleteCsf}
                  />
                </Card>
                <hr />
              </div>
            );
          }}
          length={this.state.companySpecificFields.length}
          type="simple"
        />
      </Stack>
    );
  };
  renderAddCSF = () => {
    return (
      <Stack direction="row" spacing={4}>
        <Alert severity="error">
          The data in this tab handles extremely critical data operations.
          Please be careful!
        </Alert>
        <Button
          size="medium"
          variant="contained"
          startIcon={<Iconify icon="solar:text-field-focus-outline" />}
          onClick={() => {
            this.setState({ isCreateOpen: true });
          }}
        >
          Add A New Field
        </Button>
      </Stack>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <div>
        <CSFCreate
          companyId={this.props.id}
          isOpen={this.state.isCreateOpen}
          onCreate={(csf) => {
            let companySpecificFields = this.state.companySpecificFields;
            companySpecificFields.push(csf);
            this.setState({ companySpecificFields, isCreateOpen: false });
          }}
          handleClose={() => {
            this.setState({ isCreateOpen: false });
          }}
        />
        <Stack spacing={2} sx={{ p: 1 }}>
          {this.renderAddCSF()}
          {this.renderCompanySpecificFields()}
        </Stack>
      </div>
    );
  }
}
export default WithAPICall(CompanySpecificFields);
