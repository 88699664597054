import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import { Container } from "@mui/system";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  LinearProgress,
  Stack,
} from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import _ from "lodash";
import { LoadingButton } from "@mui/lab";
import { NoResultView } from "src/sections/error";
import cogoToast from "cogo-toast";
import ChaserEfficiencyReport from "./ChaserEfficiencyReport";
import SchedulerEfficiencyReport from "./SchedulingEfficiencyReport";
import DocumentClassificationReport from "./DocumentClassificationReport";
import DataEntryReviewUserReport from "./DataEntryReviewUserReport";
import DocumentGroupStatusReport from "./DocumentGroupStatusReport";

import ReviewerEfficiencyReport from "./ReviewerEfficiencyReport";
import VisitStatusReport from "./VisitStatusReport";
import ReviewerWorkloadReport from "./ReviewerWorkloadReport";
import BillingInvoiceReport from "./BillingInvoiceReport";
import BillingPurchaseReport from "./BillingPurchaseReport";

class ReportDetails extends React.Component {
  state = {
    reportType: "",
    dateRange: [moment(), moment().add(7, "days")],
    reportData: [],
    isLoading: false,
  };
  REPORT_TYPES = {
    CHASER_EFFICIENCY: {
      title: "Chaser Efficiency",
      url: "chaser/efficiency",
      isExternal: false,
      component: ChaserEfficiencyReport,
    },
    SCHEDULING_EFFICIENCY: {
      title: "Scheduling Efficiency",
      url: "scheduling/efficiency",
      isExternal: false,
      component: SchedulerEfficiencyReport,
    },
    DOCUMENT_CLASSIFICATION: {
      title: "Document Classification",
      url: "document/classification",
      isExternal: false,
      component: DocumentClassificationReport,
    },
    REVIEWER_WORKLOAD_REPORT: {
      title: "Reviewer Workload",
      url: "reviewer/workload",
      isExternal: false,
      component: ReviewerWorkloadReport,
    },
    VISIT_STATUS_REPORT: {
      title: "Visit Status",
      url: "visit/status",
      isExternal: false,
      component: VisitStatusReport,
    },
    REVIEWER_EFFICIENCY_REPORT: {
      title: "Reviewer Productivity",
      url: "reviewer/productivity",
      isExternal: false,
      component: ReviewerEfficiencyReport,
    },
    DATA_ENTRY_REVIEW_USER: {
      title: "Data Entry Review Per User",
      url: "data-entry/review-user",
      isExternal: false,
      component: DataEntryReviewUserReport,
    },
    DOCUMENT_GROUP_STATUS_REPORT: {
      title: "Document Status Report",
      url: "documents/group",
      isExternal: false,
      component: DocumentGroupStatusReport,
    },

    BILLING_INVOICE_REPORT: {
      title: "Billing Invoice Report",
      url: "billing/invoice",
      isExternal: false,
      component: BillingInvoiceReport,
    },
    BILLING_PURCHASE_REPORT: {
      title: "Billing Purchase Report",
      url: "billing/purchase",
      isExternal: false,
      component: BillingPurchaseReport,
    },
  };
  getDataFromAPI = async () => {
    let reportType = this.REPORT_TYPES[this.state.reportType];
    let url = reportType.url;
    try {
      cogoToast.loading("Fetching data");
      this.setState({
        isLoading: true,
      });
      const data = await this.props.apiCallPost(`/reports/${url}`, {
        from: this.state.dateRange[0],
        to: this.state.dateRange[1],
      });
      this.setState({
        reportData: data,
        isLoading: false,
      });
      cogoToast.success("Data fetched successfully");
    } catch (err) {
      this.setState({
        isLoading: false,
      });
      cogoToast.error("Error fetching data");
      console.log(err);
    }
  };
  renderTitleBar = () => {
    let reportTypes = Object.keys(this.REPORT_TYPES);
    const shortcutsItems = [
      {
        label: "Today",
        getValue: () => {
          const today = moment();
          return [today, today];
        },
      },
      {
        label: "Last 3 Days",
        getValue: () => {
          const today = moment();
          return [today.clone().subtract(3, "days"), today];
        },
      },
      {
        label: "Next 3 Days",
        getValue: () => {
          const today = moment();
          return [today, today.clone().add(3, "days")];
        },
      },
      {
        label: "Last 7 Days",
        getValue: () => {
          const today = moment();
          return [today.clone().subtract(7, "days"), today];
        },
      },
      {
        label: "Next 7 Days",
        getValue: () => {
          const today = moment();
          return [today, today.clone().add(7, "days")];
        },
      },
      {
        label: "Last 30 Days",
        getValue: () => {
          const today = moment();
          return [today.clone().subtract(30, "days"), today];
        },
      },
      {
        label: "Next 30 Days",
        getValue: () => {
          const today = moment();
          return [today, today.clone().add(30, "days")];
        },
      },
      {
        label: "This Month",
        getValue: () => {
          const today = moment();
          return [today.clone().startOf("month"), today.clone().endOf("month")];
        },
      },
      {
        label: "Next Month",
        getValue: () => {
          const today = moment();
          return [
            today.clone().add(1, "month").startOf("month"),
            today.clone().add(1, "month").endOf("month"),
          ];
        },
      },
      {
        label: "Last Month",
        getValue: () => {
          const today = moment();
          return [
            today.clone().subtract(1, "month").startOf("month"),
            today.clone().subtract(1, "month").endOf("month"),
          ];
        },
      },
      {
        label: "This Week",
        getValue: () => {
          const today = moment();
          return [today.clone().startOf("week"), today.clone().endOf("week")];
        },
      },
      {
        label: "Next Week",
        getValue: () => {
          const today = moment();
          return [
            today.clone().add(1, "week").startOf("week"),
            today.clone().add(1, "week").endOf("week"),
          ];
        },
      },
      {
        label: "Last Week",
        getValue: () => {
          const today = moment();
          return [
            today.clone().subtract(1, "week").startOf("week"),
            today.clone().subtract(1, "week").endOf("week"),
          ];
        },
      },
      {
        label: "Reset",
        getValue: () => [null, null],
      },
    ];
    return (
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Typography variant="h4">Reports</Typography>
        </Grid>
        <Grid
          item
          xs={2}
          display={"flex"}
          justifyContent={"end"}
          alignItems={"end"}
        >
          <FormControl sx={{ minWidth: 120, width: "100%" }}>
            <InputLabel>Report Type</InputLabel>
            <Select
              placeholder="Select report type"
              value={this.state.reportType}
              label="Select Report Type"
              onChange={(e) => {
                this.setState({ reportType: e.target.value, reportData: [] });
              }}
            >
              {reportTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {this.REPORT_TYPES[type].title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={3}
          display={"flex"}
          justifyContent={"end"}
          alignItems={"end"}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateRangePicker
              slotProps={{
                shortcuts: {
                  changeImportance: "accept",
                  dense: true,
                  subheader: (
                    <Typography variant="caption" sx={{ p: 2 }}>
                      Shortcuts
                    </Typography>
                  ),
                  items: shortcutsItems,
                },
                actionBar: { actions: [] },
              }}
              value={this.state.dateRange}
              localeText={{
                start: "From",
                end: "To",
              }}
              onChange={(newValue) => {
                this.setState({
                  dateRange: newValue,
                  reportData: [],
                });
              }}
            />
          </LocalizationProvider>
          <LoadingButton
            disabled={
              !this.state.dateRange[0] ||
              !this.state.dateRange[1] ||
              !this.state.reportType
            }
            loading={this.state.isLoading}
            onClick={() => {
              this.getDataFromAPI();
            }}
          >
            Get Data
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };
  renderData = () => {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (!this.state.reportData || _.isEmpty(this.state.reportData)) {
      return <NoResultView />;
    }
    let reportType = this.REPORT_TYPES[this.state.reportType];
    let ReportComponent = reportType.component;
    return (
      <ReportComponent
        data={this.state.reportData}
        reportType={this.state.reportType}
      />
    );
  };

  render() {
    return (
      <Container maxWidth={10000}>
        {this.renderTitleBar()}
        <Stack sx={{ mt: 1 }}>{this.renderData()}</Stack>
      </Container>
    );
  }
}

export default WithAPICall(ReportDetails);
