import {
  Button,
  Chip,
  IconButton,
  LinearProgress,
  Popover,
  Typography,
} from "@mui/material";
import React from "react";
import { DayPicker } from "react-day-picker";
import { niceDate } from "../utils/fn";
import { LocalizationProvider, TimeField } from "@mui/x-date-pickers-pro";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { Stack } from "@mui/system";
import Iconify from "src/components/iconify";
import moment from "moment";
import TokenContext from "src/TokenContext";

class VisitApptTimePicker extends React.Component {
  state = { isLoading: true, isError: false, open: false };
  static contextType = TokenContext;
  componentDidMount() {
    this.setState({ ...this.props.clinicPSL, isLoading: false });
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.clinicPSL.scheduledOnDate !==
        this.props.clinicPSL.scheduledOnDate ||
      prevProps.clinicPSL.scheduledTime !== this.props.clinicPSL.scheduledTime
    ) {
      this.setState({ ...this.props.clinicPSL });
    }
  }
  renderTimePicker = () => {
    if (!this.state.scheduledOnDate) return null;
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="caption">Time</Typography>
          <TimePicker
            disabled={this.props.visit.isSaving}
            size="small"
            value={
              this.state.scheduledTime ? moment(this.state.scheduledTime) : null
            }
            onChange={(newValue) => {
              this.setState({ scheduledTime: newValue.toDate() }, () => {
                if (this.state.scheduledTime) {
                  // set the date from scheduledOnDate

                  let date = moment(this.state.scheduledOnDate);
                  let time = moment(this.state.scheduledTime);
                  date.set({
                    hour: time.get("hour"),
                    minute: time.get("minute"),
                    second: time.get("second"),
                  });
                  this.props.onSet("scheduledTime", date);
                }
              });
            }}
            onBlur={() => {
              if (this.state.scheduledTime) {
                // set the date from scheduledOnDate

                let date = moment(this.state.scheduledOnDate);
                let time = moment(this.state.scheduledTime);
                date.set({
                  hour: time.get("hour"),
                  minute: time.get("minute"),
                  second: time.get("second"),
                });
                this.props.onSet("scheduledTime", date);
              }
            }}
          />
        </div>
      </LocalizationProvider>
    );
  };
  renderDatePicker = () => {
    console.log("date time picker", this.context);
    let today = new Date();
    let todayPlus180 = new Date();
    todayPlus180.setDate(todayPlus180.getDate() + 180);
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="caption">Date</Typography>
        <Chip
          disabled={this.props.visit.isSaving}
          variant="contained"
          onClick={(e) => {
            if (this.props.visit.isSaving) return;
            this.setState({
              anchorEl: e.currentTarget,
              open: true,
            });
          }}
          size="large"
          label={
            this.state.scheduledOnDate
              ? niceDate(this.state.scheduledOnDate)
              : "Not Chosen"
          }
          sx={{
            background: "white",
            border: "1px solid #dadada",
            padding: "1.5rem 0.4rem",
          }}
        />
        <Popover
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          onClose={() => {
            this.setState({
              anchorEl: null,
              open: false,
            });
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <DayPicker
            mode="single"
            captionLayout="dropdown-buttons"
            // TODO: clean this up
            fromDate={
              this.context.currentUser.scopes.includes("visit:create:backfill")
                ? null
                : today
            }
            toDate={todayPlus180}
            onSelect={(e) => {
              console.log("date change", e);
              this.setState(
                {
                  scheduledOnDate: e,
                  anchorEl: null,
                  open: false,
                },
                () => {
                  // if scheduledTime, set that too.
                  this.props.onSet("scheduledOnDate", e);
                  // if (this.state.scheduledTime) {
                  //   let date = moment(this.state.scheduledOnDate);
                  //   let time = moment(this.state.scheduledTime);
                  //   date.set({
                  //     hour: time.get("hour"),
                  //     minute: time.get("minute"),
                  //     second: time.get("second"),
                  //   });
                  //   this.props.onSet("scheduledTime", date);
                  // }
                }
              );
            }}
          />
        </Popover>
      </div>
    );
  };
  renderSetForAll = () => {
    return (
      <IconButton
        size="small"
        color="primary"
        onClick={() => {
          // this.props.setAll(
          //   this.state.scheduledOnDate,
          //   moment(this.state.scheduledTime).toDate()
          // );
        }}
        disabled={
          !this.state.scheduledOnDate ||
          !this.state.scheduledTime ||
          this.props.visit.isSaving
        }
        sx={{
          width: 24,
          height: 24,
          bgcolor: "primary.main",
          color: "primary.contrastText",
          "&:hover": {
            bgcolor: "primary.dark",
          },
        }}
      >
        <Iconify icon="gravity-ui:copy-arrow-right" />
      </IconButton>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }

    return (
      <div>
        <Stack direction="row" spacing={1} alignItems="center">
          {this.renderDatePicker()}
          {this.renderTimePicker()}
          {/* {this.renderSetForAll()} */}
        </Stack>
      </div>
    );
  }
}

export default VisitApptTimePicker;
