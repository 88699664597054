import React from "react";
import { ScrollArea } from "@/components/ui/scroll-area";

import { PFTDataViewer } from "./pft-data-viewer";
import { ChainOfCustodyDataViewer } from "./chain-of-custody-data-viewer";
import { ChestXRayDataViewer } from "./cxr-data-viewer";
import { DOTCardViewer } from "./dot-card-data-viewer";
import { ECGDataViewer } from "./ecg-data-viewer";
import { LabReportDataViewer } from "./lab-report-data-viewer";
import { UrinalysisDataViewer } from "./urinanalysis-data-viewer";
import { VitalsDataViewer } from "./vitals-data-viewer";
import { VisualAcuityDataViewer } from "./vision-data-viewer";
import { AudiogramDataViewer } from "./audiogram-data-viewer";
import { BloodAlcoholDataViewer } from "./blood-alcohol-data-viewer";
import { MedicalQuestionnaireDataViewer } from "./questionnaire-data-viewer";
import { VaccinationDataViewer } from "./vaccination-data-viewer";

export function ParentDataRenderer({ listOfData }) {
  return listOfData
    .map((each, index) => {
      if (!each) return null;
      delete each.serviceId;
      delete each.protocolId;
      delete each.isAbnormal; // root level isAbnormal is not needed
      delete each._id;
      switch (each.dataType) {
        case "VACCINATION": {
          return (
            <VaccinationDataViewer key={index} data_={each} onUpdate={null} />
          );
        }
        case "PFT": {
          return <PFTDataViewer key={index} data_={each} onUpdate={null} />;
        }
        case "CHAIN_OF_CUSTODY":
          return (
            <ChainOfCustodyDataViewer
              key={index}
              data_={each}
              onUpdate={null}
            />
          );
        case "CXR":
          return (
            <ChestXRayDataViewer key={index} data_={each} onUpdate={null} />
          );
        case "DOT_CARD":
          return <DOTCardViewer key={index} data_={each} onUpdate={null} />;
        case "ECG":
          return <ECGDataViewer key={index} data_={each} onUpdate={null} />;
        case "LAB_REPORT":
          return (
            <LabReportDataViewer key={index} data_={each} onUpdate={null} />
          );
        case "URINANALYSIS":
          return (
            <UrinalysisDataViewer key={index} data_={each} onUpdate={null} />
          );
        case "VITALS":
          return <VitalsDataViewer key={index} data_={each} onUpdate={null} />;
        case "VISION":
          return (
            <VisualAcuityDataViewer key={index} data_={each} onUpdate={null} />
          );
        case "AUDIOGRAM":
          return (
            <AudiogramDataViewer key={index} data_={each} onUpdate={null} />
          );
        case "BAT":
          return (
            <BloodAlcoholDataViewer key={index} data_={each} onUpdate={null} />
          );
        case "QUESTIONNAIRE":
          return (
            <MedicalQuestionnaireDataViewer
              key={index}
              data_={each}
              onUpdate={null}
            />
          );

        default:
          return <div key={index}>Unknown Data Type {each.dataType}</div>;
      }
    })
    .map((e) => <div className="mb-4">{e}</div>);
}
