import { Button, LinearProgress } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Box } from "@mui/system";
import cogoToast from "cogo-toast";
import React from "react";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import Iconify from "src/components/iconify";
import { View500 } from "src/sections/error";

import { WithAPICall } from "../utils/apiUtil";
import ServiceClearances from "./ServiceClearances";
import ServiceNotes from "./ServiceNotes";
import ServiceProfile from "./ServiceProfile";
import ServiceQuestionnaire from "./ServiceQuestionnaire";
import { addUrlParams, getUrlParams } from "src/utils/url-params";
import ServiceFormFoxProfile from "./ServiceFormFoxProfile";

class ServiceDetails extends React.Component {
  state = {
    currentTab: "INFO",
    masterServiceData: null,
    companyNotes: [],
    isLoading: true,
    isError: false,
  };
  async componentDidMount() {
    await this.getById();
    const params = getUrlParams();
    if (params.currentTab) {
      this.setState({ currentTab: params.currentTab });
    }
  }
  getById = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost(
        "/master-service/getMasterServiceById",
        {
          masterServiceId: this.props.id,
        }
      );
      this.setState({ masterServiceData: res, isLoading: false });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Provider Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };

  tabs = [
    {
      value: "INFO",
      label: "Info",
      icon: <Iconify icon="solar:info-circle-broken" width={24} />,
    },
    // {
    //   value: "CLEARANCE",
    //   label: "Clearances",
    //   icon: <Iconify icon="solar:clipboard-broken" width={24} />,
    // },
    {
      value: "QUESTIONNAIRE",
      label: "Questionnaire",
      icon: <Iconify icon="solar:question-square-broken" width={24} />,
    },
    {
      value: "NOTES",
      label: "Notes & Documents",
      icon: <Iconify icon="solar:document-add-linear" width={24} />,
    },
    {
      value: "FORMFOX",
      label: "Form Fox",
      // TODO: convert this icon on grayscale to maintain design language
      icon: (
        <div>
          <img src="/assets/icons/brands/ic_brand_formfox.png" alt="formfox" />
        </div>
      ),
    },
    /*  {
      value: "OUTPUTS",
      label: "Service Data Outputs",
      icon: <Iconify icon="solar:database-broken" width={24} />,
    }, */
  ];
  renderTabs = () => {
    return (
      <Tabs
        value={this.state.currentTab}
        onChange={(_, value) => {
          this.setState({ currentTab: value });
          addUrlParams({ currentTab: value });
        }}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        {this.tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            icon={tab.icon}
            value={tab.value}
          />
        ))}
      </Tabs>
    );
  };
  renderTab = () => {
    switch (this.state.currentTab) {
      case "INFO":
        return (
          <ServiceProfile
            id={this.props.id}
            data={this.state.masterServiceData}
            onUpdate={(key, value) => {
              let masterServiceData = this.state.masterServiceData;
              masterServiceData[key] = value;
              this.setState({ masterServiceData });
            }}
          />
        );

      // Removed and Deprecated -- Services do not have clearances
      // case "CLEARANCE":
      //   return (
      //     <ServiceClearances
      //       id={this.props.id}
      //       data={this.state.masterServiceData}
      //       onUpdate={(key, value) => {
      //         let masterServiceData = this.state.masterServiceData;
      //         masterServiceData[key] = value;
      //         this.setState({ masterServiceData });
      //       }}
      //     />
      //   );
      case "QUESTIONNAIRE":
        return (
          <ServiceQuestionnaire
            id={this.props.id}
            data={this.state.masterServiceData}
            onUpdate={(key, value) => {
              let masterServiceData = this.state.masterServiceData;
              masterServiceData[key] = value;
              this.setState({ masterServiceData });
            }}
          />
        );
      case "NOTES":
        return (
          <ServiceNotes
            id={this.props.id}
            data={this.state.masterServiceData}
            onUpdate={(key, value) => {
              let masterServiceData = this.state.masterServiceData;
              masterServiceData[key] = value;
              this.setState({ masterServiceData });
            }}
          />
        );
      case "FORMFOX":
        return (
          <ServiceFormFoxProfile
            id={this.props.id}
            data={this.state.masterServiceData}
            onUpdate={(key, value) => {
              let masterServiceData = this.state.masterServiceData;
              masterServiceData[key] = value;
              this.setState({ masterServiceData });
            }}
          />
        );
      /*  case "OUTPUTS":
        return (
          <ServiceOutputs
            id={this.props.id}
            data={this.state.masterServiceData}
            onUpdate={(key, value) => {
              let masterServiceData = this.state.masterServiceData;
              masterServiceData[key] = value;
              this.setState({ masterServiceData });
            }}
          />
        ); */
      default:
        return <div>Not Live</div>;
    }
  };
  renderHeader = () => {
    let name = this.state.masterServiceData?.serviceName || "Service Details";
    return (
      <CustomBreadcrumbs
        heading={name}
        links={[
          { name: "Dashboard", href: "/" },
          { name: "Services", href: "/services" },
          { name: `Details: ${name}` },
        ]}
        action={
          <Box>
            <Button
              disabled={this.state.isLoading}
              onClick={() => this.getById()}
              variant="contained"
              startIcon={
                <Iconify icon="mingcute:refresh-anticlockwise-1-line" />
              }
            >
              Refresh Data
            </Button>
          </Box>
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <>
        {this.renderHeader()}
        {this.renderTabs()}
        {this.renderTab()}
      </>
    );
  }
}

export default WithAPICall(ServiceDetails);
