import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Alert,
  Chip,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  Grid,
  Divider,
  Button,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import DocumentGroup from "../Documents/DocumentGroup";
import cogoToast from "cogo-toast";
import { LoadingButton } from "@mui/lab";
import AILoader from "../utils/AILoader";
import Iconify from "src/components/iconify";
import _ from "lodash";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import axios from "axios";
import { DOCUMENT_GENERATOR_URL } from "src/config-global";
import { format } from "date-fns";
import TokenContext from "src/TokenContext";

class VisitServiceDataEach extends React.Component {
  static contextType = TokenContext;
  state = {
    serviceDetail: null,
    groups: [],
    isLoading: true,
    isSummarizing: false,
    summary: "",
    currentAction: null,
    isSaving: false,
    showDetail: true,
    isEvaling: false,
    evaluation: "",
    clearance: "",
    typeOfClearance: "",
    clearanceCase: "",
    clearanceDetails: {},
  };
  componentDidMount() {
    this.setState({
      serviceDetail: this.props.serviceData,
      groups: this.props.groups,
      isLoading: false,
      summary: this.props.bookedService.summary || "",
      evaluation: this.props.bookedService.evaluation || "",
      // showDetail: this.props.bookedService.summary ? false : true,
      clearance: this.props.bookedService.clearance,
      typeOfClearance: this.props.bookedService.typeOfClearance,
      clearanceCase: this.props.bookedService.clearanceCase,
    });
  }
  generateSummary = async () => {
    try {
      this.setState({
        summary: "",
        isSummarizing: true,
        currentAction: "Summarizing",
      });
      await this.props.apiCallPostStreamingForAI(
        "/external/pdf/generateSummary",
        {
          visitId: this.props.visitData._id,
          serviceId: this.props.serviceData._id,
          stream: true,
        },
        (data) => {
          this.setState({
            summary: this.state.summary + data,
          });
        },
        () => {
          this.onEndSummarization();
        }
      );
    } catch (err) {
      cogoToast.error("Error Summarizing Service");
      console.log(err);
    }
  };
  onEndSummarization = async () => {
    // wait 2 seconds
    await new Promise((resolve) => setTimeout(resolve, 2000));
    this.setState({
      isSummarizing: false,
      currentAction: null,
    });
  };
  evalClearance = async () => {
    try {
      this.setState({
        evaluation: "",
        isEvaling: true,
        currentAction: "Evaluating",
      });
      await this.props.apiCallPostStreamingForAI(
        "/external/pdf/evalClearance",
        {
          visitId: this.props.visitData._id,
          serviceId: this.props.serviceData._id,
          stream: true,
        },
        (data) => {
          this.setState({
            evaluation: this.state.evaluation + data,
          });
        },
        () => {
          this.onEndEval();
        }
      );
    } catch (err) {
      cogoToast.error("Error Evaluating Service");
      console.log(err);
    }
  };
  onEndEval = async () => {
    // wait 2 seconds
    await new Promise((resolve) => setTimeout(resolve, 2000));
    this.setState({
      isEvaling: false,
      currentAction: null,
    });
  };
  updateVisitClearance = async () => {
    try {
      this.setState({
        isSaving: true,
      });
      let res = await this.props.apiCallPost("/visit/updateVisitClearance", {
        visitId: this.props.visitData._id,
        entity: "MasterService",
        entityId: this.state.serviceDetail._id,
        clearance: this.state.clearance,
        typeOfClearance: this.state.typeOfClearance,
        clearanceCase: this.state.clearanceCase,
        clearanceInsn: "",
      });
      this.setState({
        ...res,
        isSaving: false,
      });
    } catch (err) {
      this.setState({
        isSaving: false,
      });
      cogoToast.error("Error Updating Visit Clearance Data");
      console.log(err);
    }
  };
  renderServiceHeader = () => {
    return (
      <Stack direction="row" alignItems={"center"} spacing={2}>
        <Typography variant="overline">
          {this.state.serviceDetail.serviceName}
        </Typography>
        {this.renderSummaryButton()}
        {this.renderEvalButton()}
        {this.renderCurrentAction()}
      </Stack>
    );
  };
  renderGroups = () => {
    if (!this.state.showDetail) {
      return null;
    }
    let groupsForService = this.state.groups.filter(
      (x) => x.service?._id === this.state.serviceDetail._id
    );
    let noGroupsElem = <Alert severity="info">No data found</Alert>;
    if (groupsForService.length) {
      noGroupsElem = null;
    }
    return (
      <Stack sx={{ marginTop: "0rem" }}>
        {noGroupsElem}
        {groupsForService.map((group, idx) => {
          return (
            <div style={{ margin: "2rem 0" }} key={idx}>
              <DocumentGroup
                cfId={group._id}
                data={group}
                fromVisitPage={true}
                showDocumentHeader={true}
                employeeId={this.props.visitData.employee._id}
                onDelink={() => {
                  let groupId = group._id;
                  let temp = this.state.groups;
                  let index = temp.findIndex((x) => x._id === groupId);
                  temp.splice(index, 1);
                  this.setState({
                    groups: temp,
                  });
                }}
              />
            </div>
          );
        })}
      </Stack>
    );
  };
  renderCurrentAction = () => {
    if (!this.state.currentAction) {
      return null;
    }
    return (
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <Chip
          label={this.state.currentAction}
          icon={<Iconify icon="svg-spinners:pulse-3" />}
        />
      </Stack>
    );
  };
  renderSummary = () => {
    if (this.state.isSummarizing && !this.state.summary) {
      return <LinearProgress />;
    }
    if (!this.state.summary) {
      return null;
    }
    let s = this.state.summary;
    let lines = s.split("\n");
    return (
      <Stack spacing={2}>
        <Typography variant="overline">Summary</Typography>
        <Stack spacing={0}>
          {lines.map((line) => {
            // if we find word "Red Flags:", render a chip with label Red Flag & then rest of the text.
            let ll = _.trim(line);
            if (ll.startsWith("Red Flags:")) {
              let rest = ll.replace("Red Flags:", "");
              return (
                <Stack spacing={1}>
                  {" "}
                  <Box
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Chip label="Red Flags" color="error" size="small" />
                  </Box>
                  <Typography variant="caption">{rest}</Typography>
                </Stack>
              );
            }
            // Headline: ==> info
            if (ll.startsWith("Headline:")) {
              let rest = ll.replace("Headline:", "");
              return (
                <Stack spacing={1}>
                  <Box
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Chip label="Headline" color="info" size="small" />
                  </Box>
                  <Typography variant="caption">{rest}</Typography>
                </Stack>
              );
            }
            // Summary: ==> secondary

            if (ll.startsWith("Summary:")) {
              let rest = ll.replace("Summary:", "");
              return (
                <Stack spacing={1}>
                  <Box
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Chip label="Summary" color="secondary" size="small" />
                  </Box>
                  <Typography variant="caption">{rest}</Typography>
                </Stack>
              );
            }
            return <Typography variant="caption">{line}</Typography>;
          })}
        </Stack>
      </Stack>
    );
  };
  renderEval = () => {
    if (this.state.isEvaling && !this.state.evaluation) {
      return <LinearProgress />;
    }
    if (!this.state.evaluation) {
      return null;
    }
    let s = this.state.evaluation;
    let lines = s.split("\n");
    return (
      <Stack spacing={2}>
        <Typography variant="overline">Evaluation</Typography>
        <Stack spacing={0}>
          {lines.map((line) => {
            let ll = _.trim(line);
            if (ll.startsWith("Recommendation:")) {
              return (
                <Stack spacing={1} direction="row" alignItems={"center"}>
                  {" "}
                  <Box
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Chip
                      label={`AI ${line}`}
                      color="secondary"
                      size="small"
                      variant="outlined"
                    />
                  </Box>
                </Stack>
              );
            }

            return <Typography variant="caption">{line}</Typography>;
          })}
        </Stack>
      </Stack>
    );
  };
  renderSummaryButton = () => {
    if (this.context.currentUser.isExternal) {
      return null;
    }
    if (this.state.isSummarizing) {
      return <AILoader />;
    }
    let groupsForService = this.state.groups.filter(
      (x) => x.service?._id === this.state.serviceDetail._id
    );
    if (!groupsForService.length) {
      return null;
    }
    let summText = "Summarize";
    if (this.state.summary) {
      summText = "Resummarize";
    }
    return (
      <Stack direction="row" alignItems={"center"} spacing={2}>
        <LoadingButton
          disabled={
            this.state.isSaving ||
            this.state.isEvaling ||
            this.state.isSummarizing
          }
          loading={this.state.isSummarizing}
          onClick={() => {
            this.generateSummary();
          }}
          variant="outlined"
          startIcon={<Iconify icon="ooui:text-summary-ltr" />}
        >
          {summText}
        </LoadingButton>
      </Stack>
    );
  };
  renderEvalButton = () => {
    if (this.context.currentUser.isExternal) {
      return null;
    }
    if (this.state.isEvaling) {
      return <AILoader />;
    }
    let groupsForService = this.state.groups.filter(
      (x) => x.service?._id === this.state.serviceDetail._id
    );
    if (!groupsForService.length) {
      return null;
    }
    let evalText = "Evaluate";
    if (this.state.evaluation) {
      evalText = "Re-Evaluate";
    }
    return (
      <Stack direction="row" alignItems={"center"} spacing={2}>
        <LoadingButton
          disabled={
            this.state.isSaving ||
            this.state.isEvaling ||
            this.state.isSummarizing
          }
          loading={this.state.isEvaling}
          onClick={() => {
            this.evalClearance();
          }}
          variant="outlined"
          startIcon={<Iconify icon="tabler:list-check" />}
        >
          {evalText}
        </LoadingButton>
        {/* <Tooltip
          title={
            this.state.showDetail
              ? "Click to hide details"
              : "Click to show details"
          }
        >
          <IconButton
            size="small"
            onClick={() => {
              this.setState({
                showDetail: !this.state.showDetail,
              });
            }}
          >
            <Iconify
              icon={
                this.state.showDetail
                  ? "solar:square-double-alt-arrow-up-broken"
                  : "solar:square-double-alt-arrow-down-broken"
              }
            />
          </IconButton>
        </Tooltip> */}
      </Stack>
    );
  };
  generateClearance = async ({ download, email }) => {
    this.setState({ loadingClearancePdf: true });
    const payloads = [];
    const serviceId = this.props.serviceData._id;
    try {
      const service = this.props.visitData.bookedServices.find(
        (s) => s.serviceId === serviceId
      );
      const serviceMetadata = this.props.visitData.companyProtocols
        .map((p) => {
          if (p._id === service.parentProtocolId) {
            const serviceIdx = p.masterServices?.findIndex(
              (i) => i === serviceId
            );
            if (serviceIdx !== -1) {
              return p.serviceMetadata[serviceIdx];
            }
          }
        })
        .filter((p) => p);
      if (serviceMetadata && serviceMetadata.length > 0) {
        const templateId = serviceMetadata[0].clearanceTemplateId;
        console.log({ service, serviceMetadata });
        const protocol = await this.props.apiCallPost(
          "/company-protocol/getCompanyProtocolById",
          {
            companyProtocolId: service.parentProtocolId,
          }
        );
        let visitDate = this.props.visitData.clinicPSLs
          .map((psl) => {
            console.log({ psl });
            if (psl.masterServices.includes(serviceId)) {
              return psl.scheduledOnDate;
            }
          })
          .filter((p) => p);
        // if (visitDate && visitDate.length > 0) {
        //   visitDate = visitDate[0];
        // }
        console.log({ visitDate });
        const data = {
          certificationFor: service.serviceName,
          employee: {
            name: this.props.visitData.employee.employeeName,
            number: this.props.visitData.employee.employeeNumber,
            dob: "",
            id: this.props.visitData.employee.employeeNumber,
          },
          company: {
            name: this.props.visitData.company.companyName,
            division: "",
            location: this.props.visitData.company.companyAddress,
          },
          clearance: {
            title: service.clearanceCase,
            language: service.clearance,
          },
          test: {
            collectionDate: "",
            collectionSite: "",
            testingLaboratory: "",
            reasonForTest: "",
            results: service.clearance,
            expirationDate: "",
          },
          dot: {
            agency: protocol.regulatoryAuthority,
          },
          isDot: protocol.isDot,
          substancesTested: [],
          visit: {
            date: "<todo>",
          },
        };
        payloads.push({
          fileId: templateId,
          data,
          convertTo: "pdf",
        });
        if (download) {
          const response = await axios.post(
            DOCUMENT_GENERATOR_URL + "/api/templater/template",
            {
              fileId: templateId,
              data,
              convertTo: "pdf",
            },
            {
              responseType: "blob",
            }
          );

          // Create object URL for the blob
          const url = URL.createObjectURL(response.data);

          // Automatically trigger download
          const a = document.createElement("a");
          a.href = url;
          a.download = `clearance.pdf`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }

      // const files = await this.props.apiCallPost("/files/groups/search", {
      //   visitId: this.props.id,
      // });
      // for (const bookedService of this.state.bookedServices) {
      //   // if it has a file, get the details
      //   const serviceId = bookedService.serviceId;

      //   // check if there is a file for this
      //   const matchingFile = files.filter((f) => {
      //     return f.service?._id === serviceId;
      //   });

      //   if (matchingFile.length > 0) {
      //     // get the protocol to see dot/non-dot
      //     const protocol = await this.props.apiCallPost(
      //       "/company-protocol/getCompanyProtocolById",
      //       {
      //         companyProtocolId: bookedService.parentProtocolId,
      //       }
      //     );
      //     // get service to see type of service
      //     const service = await this.props.apiCallPost(
      //       "/master-service/getMasterServiceById",
      //       {
      //         masterServiceId: bookedService.serviceId,
      //       }
      //     );

      //     const file = matchingFile[0];
      //     console.log({ file, protocol, service });
      // if (service.serviceType === "DRUG") {
      //   const data = {
      //     certificationFor: service.serviceName,
      //     employee: {
      //       name: this.state.employee.employeeName,
      //       number: this.state.employee.employeeNumber,
      //       dob: this.state.employee.employeeDoB
      //         ? format(new Date(this.state.employee.employeeDoB), "PP")
      //         : "",
      //       id: this.state.employee.employeeNumber,
      //     },
      //     company: {
      //       name: this.state.company.companyName,
      //       division: "",
      //       location: this.state.company.companyAddress,
      //     },
      //     test: {
      //       collectionDate: "",
      //       collectionSite: "",
      //       testingLaboratory: "",
      //       reasonForTest: "",
      //       results: bookedService.clearance,
      //     },
      //     dot: {
      //       agency: protocol.regulatoryAuthority,
      //     },
      //     isDot: protocol.isDot,
      //     substancesTested: [],
      //   };
      //   for (const structuredData of file.dataStructured) {
      //     data.substancesTested.push(structuredData.parameter);
      //   }
      //   console.log({ data });
      //   payloads.push({
      //     fileId: TEMPLATE_ID.CLEARANCE_DRUG,
      //     data,
      //     convertTo: "pdf",
      //   });
      // } else if (!protocol.isDot && service.serviceType === "VACCINE") {
      //   const data = {
      //     certificationFor: service.serviceName,
      //     employee: {
      //       name: this.state.employee.employeeName,
      //       number: this.state.employee.employeeNumber,
      //       dob: this.state.employee.employeeDoB
      //         ? format(new Date(this.state.employee.employeeDoB), "PP")
      //         : "",
      //     },
      //     company: {
      //       name: this.state.company.companyName,
      //       division: "",
      //       location: this.state.company.companyAddress,
      //     },
      //     table: {
      //       data: [],
      //     },
      //     clearanceLanguage: bookedService.clearance,
      //   };
      //   for (const structuredData of file.dataStructured) {
      //     data.table.data.push([structuredData.parameter]);
      //   }
      //   console.log({ data });
      //   payloads.push({
      //     fileId: TEMPLATE_ID.CLEARANCE_NON_DOT_VACCINE,
      //     data,
      //     convertTo: "pdf",
      //   });
      // }
      // }
      // }

      // if (payloads.length > 0) {
      //   if (download) {
      //     const response = await axios.post(
      //       DOCUMENT_GENERATOR_URL + "/api/templater/template-multiple-files",
      //       payloads,
      //       {
      //         responseType: "blob",
      //       }
      //     );

      //     // Create object URL for the blob
      //     const url = URL.createObjectURL(response.data);

      //     // Automatically trigger download
      //     const a = document.createElement("a");
      //     a.href = url;
      //     a.download = `clearance.pdf`;
      //     document.body.appendChild(a);
      //     a.click();
      //     document.body.removeChild(a);
      //   }
      //   if (email) {
      //     const response = await axios.post(
      //       DOCUMENT_GENERATOR_URL + "/api/templater/template-multiple-files",
      //       payloads,
      //       {
      //         responseType: "blob",
      //       }
      //     );
      //     const file = new File([response.data], "filename.pdf", {
      //       type: "application/pdf",
      //     });
      //     // Now you can include this file in your form data to send to the backend API
      //     const formData = new FormData();
      //     formData.append("files", file);

      //     const to = [];
      //     if (this.state.employee.employeePersonalEmail) {
      //       to.push({
      //         name: "",
      //         address: this.state.employee.employeePersonalEmail,
      //       });
      //     }
      //     if (this.state.employee.employeeOfficeEmail) {
      //       to.push({
      //         name: "",
      //         address: this.state.employee.employeeOfficeEmail,
      //       });
      //     }

      //     // Add other form fields
      //     formData.append("to", JSON.stringify(to));
      //     formData.append("cc", JSON.stringify([]));
      //     formData.append("html", "");
      //     formData.append("subject", "Clearance Letter");

      //     // Send the form data to the backend API
      //     const emailRes = await this.props.apiCallPost(
      //       "/email/sendEmail",
      //       formData,
      //       {
      //         headers: {
      //           "Content-Type": "multipart/form-data",
      //         },
      //       }
      //     );
      //   }
      // } else {
      //   cogoToast.error(
      //     "Please provide at least one protocol clearance to preview clearance."
      //   );
      // }
    } catch (error) {
      console.error("could not generate clearance", error);
    }
    this.setState({ loadingClearancePdf: false });
    return payloads;
  };
  renderClearanceOptions = () => {
    if (this.context.currentUser.isExternal) {
      return null;
    }
    let clearanceOptions = this.state.serviceDetail.clearanceOptions;
    if (!clearanceOptions) {
      return null;
    }
    if (!clearanceOptions.length) {
      return null;
    }

    let options = clearanceOptions.map((x) => x.case);

    let clElem = null;
    if (this.state.clearanceCase) {
      let type = this.state.typeOfClearance;
      let color = "secondary";
      if (type === "Not Cleared") {
        color = "error";
      } else if (type === "Cleared") {
        color = "primary";
      } else {
        color = "warning";
      }
      clElem = (
        <Stack spacing={2}>
          <Typography variant="overline" color={color}>
            {type}
          </Typography>
          <TextField
            size="small"
            disabled={this.state.isSaving}
            multiline
            fullWidth
            label="Clearance Language"
            value={this.state.clearance}
            onChange={(e) => {
              this.setState({
                clearance: e.target.value,
              });
            }}
            onBlur={() => {
              this.updateVisitClearance();
            }}
          />
        </Stack>
      );
    }
    return (
      <Stack spacing={2}>
        <Stack direction="row" alignItems={"center"} spacing={2}>
          <Typography variant="overline">Clearance</Typography>
          <Select
            size="small"
            disabled={this.state.isSaving}
            sx={{
              minWidth: "150px",
            }}
            value={this.state.clearanceCase}
            onChange={(e) => {
              let idx = _.findIndex(
                clearanceOptions,
                (x) => x.case === e.target.value
              );
              this.setState(
                {
                  clearanceCase: e.target.value,
                  clearance: clearanceOptions[idx].text,
                  typeOfClearance: clearanceOptions[idx].typeOfClearance,
                },
                () => {
                  this.updateVisitClearance();
                }
              );
            }}
          >
            {options.map((opt, idx) => (
              <MenuItem size="small" value={opt} key={idx}>
                {opt}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        {clElem}
        {this.state.clearanceCase && (
          <Button
            onClick={() => {
              this.generateClearance({
                download: true,
                email: false,
              });
            }}
          >
            Download Clearance
          </Button>
        )}
      </Stack>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <Stack spacing={2}>
        {this.renderServiceHeader()}
        {this.renderSummary()}
        {this.props.serviceData.serviceType === "VACCINATION" && (
          <Stack>
            <div>
              <Typography variant="overline">
                Enter Next Vaccination Due Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  // value={moment(state.injuryDetails.injuryOccurTime)}
                  onChange={(m) => {
                    // const date = m.toDate();
                    // state.setInjuryOccurTime(date);
                    // state.updateScalarValue(
                    //   "injuryDetails",
                    //   state.injuryDetails
                    // );
                  }}
                />
              </LocalizationProvider>
            </div>
          </Stack>
        )}
        <Typography variant="overline" sx={{ marginBottom: "1rem" }}>
          Visit Documents
        </Typography>
        <Stack sx={{ maxHeight: "60vh", overflowY: "scroll" }}>
          {this.renderGroups()}
        </Stack>

        {/* <Grid container spacing={4}>
          <Grid item xs={6} sx={{ paddingRight: "1rem" }}>
            {this.renderSummary()}
            {this.renderEval()}
            {this.renderClearanceOptions()}
          </Grid>
          {this.props.serviceData.serviceType === "VACCINATION" && (
            <Stack>
              <div>
                <Typography variant="overline">
                  Enter Next Vaccination Due Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    // value={moment(state.injuryDetails.injuryOccurTime)}
                    onChange={(m) => {
                      // const date = m.toDate();
                      // state.setInjuryOccurTime(date);
                      // state.updateScalarValue(
                      //   "injuryDetails",
                      //   state.injuryDetails
                      // );
                    }}
                  />
                </LocalizationProvider>
              </div>
            </Stack>
          )}
          <Grid
            item
            xs={6}
            sx={{
              borderLeft: "1px solid #80808060",
              paddingRight: "1rem !important",
            }}
          >
            <Typography variant="overline" sx={{ marginBottom: "1rem" }}>
              Visit Documents
            </Typography>
            <Stack sx={{ maxHeight: "60vh", overflowY: "scroll" }}>
              {this.renderGroups()}
            </Stack>
          </Grid>
        </Grid> */}
      </Stack>
    );
  }
}

export default WithAPICall(VisitServiceDataEach);
