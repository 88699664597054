import { Button, LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import cogoToast from "cogo-toast";
import React from "react";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import Iconify from "src/components/iconify";
import { View500 } from "src/sections/error";
import { getUrlParams } from "src/utils/url-params";
import { WithAPICall } from "../../utils/apiUtil";
import CompanyEmployees from "../CompanyEmployees";
import TokenContext from "src/TokenContext";

class EmployeeRoster extends React.Component {
  static contextType = TokenContext;
  state = {
    currentTab: "INFO",
    companyData: null,
    companyNotes: [],
    isLoading: true,
    isError: false,
  };
  async componentDidMount() {
    await this.getById();
    const urlParams = getUrlParams();
    if (urlParams.currentTab) {
      this.setState({ currentTab: urlParams.currentTab });
    }
  }
  getById = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost("/company/getById", {
        _id: this.context.currentUser.company._id,
      });
      this.setState({ companyData: res, isLoading: false });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Employer Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  createEmployee = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost("/employee/createFromName", {
        companyId: this.props.id,
        employeeName: "Shibjash",
      });
      //    this.setState({ companyData: res, isLoading: false });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Employer Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };

  renderHeader = () => {
    let name =
      this.state.companyData.companyInformalName ||
      this.state.companyData.companyName ||
      "Company Details";
    return (
      <Typography variant="h4" sx={{ mb: 2 }}>
        Employee Roster
      </Typography>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <>
        {this.renderHeader()}
        <CompanyEmployees
          id={this.context.currentUser.company._id}
          data={this.state.companyData}
          onUpdate={(key, value) => {
            let companyData = this.state.companyData;
            companyData[key] = value;
            this.setState({ companyData });
          }}
        />
      </>
    );
  }
}

export default WithAPICall(EmployeeRoster);
