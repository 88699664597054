import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import _ from "lodash";
import {
  Alert,
  Button,
  Card,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { View500 } from "src/sections/error";
import { Stack } from "@mui/system";

import Iconify from "src/components/iconify";
import VisitChooseClinic from "./VisitChooseClinic";
import VisitChooseLab from "./VisitChooseLab";

class VisitGroupServices extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    serviceDetails: [],
    internalServices: [],
    questionnaireServices: [],
    labServices: [],
    clinicServices: [],
    otherServices: [],
  };
  componentDidMount() {
    this.getServiceDetails();
  }
  getServiceDetails = async () => {
    try {
      this.setState(
        {
          isError: false,
          serviceDetails: this.props.visit.servicesInfo,
        },
        () => {
          this.groupServices();
        }
      );
    } catch (err) {
      console.log(err);
      this.setState({
        isError: true,
        isLoading: false,
      });
    }
  };
  groupServices = () => {
    let internalServices = [];
    let questionnaireServices = [];
    let clinicServices = [];
    let otherServices = [];
    let serviceDetails = this.state.serviceDetails;
    for (var i = 0; i < serviceDetails.length; i++) {
      let s = serviceDetails[i];
      if (s.serviceType === "QUESTIONNAIRE") {
        questionnaireServices.push(s);
        continue;
      }
      if (s.serviceType === "ADMINISTRATIVE") {
        internalServices.push(s);
        continue;
      }
      if (s.isHandledByWorkCare) {
        internalServices.push(s);
        continue;
      }
      if (
        s.serviceRequiresProviderType === "Clinic" ||
        s.serviceRequiresProviderType === "Lab"
      ) {
        clinicServices.push(s);
        continue;
      }
      otherServices.push(s);
    }
    this.setState({
      internalServices,
      questionnaireServices,
      clinicServices,
      otherServices,
      isLoading: false,
    });
  };
  renderInternalServices = () => {
    let internalServices = this.state.internalServices;
    if (!internalServices || !internalServices.length) {
      return null;
    }
    return (
      <Card
        sx={{
          p: 2,
          rounded: 1,
          boxShadow: 3,
        }}
      >
        <Stack spacing={2}>
          <Typography variant="h6">Services Directly From WorkCare</Typography>
          {internalServices.map((service) => {
            return (
              <Stack
                key={service._id}
                spacing={1}
                direction="row"
                alignItems={"center"}
              >
                <Typography variant="subtitle1">
                  {service.serviceName}
                </Typography>
                <IconButton
                  size="small"
                  href={`/services/${service._id}`}
                  variant="outlined"
                  // target="non_blank"
                >
                  <Iconify icon="mdi:arrow-top-right" />
                </IconButton>
              </Stack>
            );
          })}
        </Stack>
      </Card>
    );
  };
  renderQuestionnaireServices = () => {
    let questionnaireServices = this.state.questionnaireServices;
    if (!questionnaireServices || !questionnaireServices.length) {
      return null;
    }
    return (
      <Card
        sx={{
          p: 2,
          rounded: 1,
          boxShadow: 3,
        }}
      >
        <Stack spacing={2}>
          <Typography variant="h6">Questionnaire Services</Typography>
          <Alert severity="info">
            This Questionnaire will be sent to the employee by the system. No
            further action is required by you.
          </Alert>
          {questionnaireServices.map((service) => {
            return (
              <Stack
                key={service._id}
                spacing={1}
                direction="row"
                alignItems={"center"}
              >
                <Typography variant="subtitle1">
                  {service.serviceName}
                </Typography>
                <IconButton
                  size="small"
                  href={`/services/${service._id}`}
                  variant="outlined"
                >
                  <Iconify icon="mdi:arrow-top-right" />
                </IconButton>
              </Stack>
            );
          })}
        </Stack>
      </Card>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <Stack spacing={3}>
        {this.renderInternalServices()}
        {this.renderQuestionnaireServices()}
        {this.state.clinicServices?.length > 0 && (
          <VisitChooseClinic
            {...this.props}
            services={this.state.clinicServices}
            visit={this.props.visit}
            onFreezeClinic={(data) => this.props.onFreezeClinic(data)}
            unFreezeClinics={() => this.props.unFreezeClinics()}
            key={`vcc-${this.props.key}`}
          />
        )}
      </Stack>
    );
  }
}

export default WithAPICall(VisitGroupServices);
