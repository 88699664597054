//import { ControlGroup, Tag } from "@blueprintjs/core";
import React from "react";
import _ from "lodash";
import { Stack } from "@mui/system";
import { LinearProgress, Typography } from "@mui/material";

class AILoader extends React.Component {
  state = {
    bgColourArray: [
      "#F6F7F9",
      "#EDEFF2",
      "#E5E8EB",
      "#DCE0E5",
      "#D3D8DE",
      "#C5CBD3",
      "#ABB3BF",
      "#8F99A8",
      "#F6F7F9",
      "#EDEFF2",
      "#F6F7F9",
      "#EDEFF2",
      "#E5E8EB",
    ],
  };
  componentDidMount() {
    this.interval = setInterval(
      () =>
        this.setState({
          bgColourArray: _.shuffle(this.state.bgColourArray),
        }),
      100
    );
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  renderRow = (word) => {
    let letters = word.split("");
    let numLetters = letters.length;
    let indices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    let chosenIndicesForLetter = _.sampleSize(indices, numLetters).sort(
      (a, b) => a - b
    );
    return indices.map((each) => {
      let indexInChosen = _.findIndex(
        chosenIndicesForLetter,
        (x) => x === each
      );
      let whichLetter = " ";
      if (indexInChosen > -1) {
        whichLetter = letters[indexInChosen];
      }
      return (
        <Typography
          variant="overline"
          sx={{
            width: "30px",
            textAlign: "center",
            background: `${_.shuffle(this.state.bgColourArray)[each]}`,
          }}
        >
          {whichLetter}
        </Typography>
      );
    });
  };
  render() {
    return (
      <Stack spacing={0} alignItems="center">
        <LinearProgress color="info" />
        <Stack direction="row" spacing={0} alignItems="center">
          {this.props.text
            ? this.renderRow(this.props.text)
            : this.renderRow("THINKING")}
        </Stack>
        <LinearProgress color="secondary" />
      </Stack>
    );
  }
}

export default AILoader;
