import React, { useState } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  useTheme,
  Box,
  IconButton,
  Tooltip,
  Switch,
  Button,
} from "@mui/material";
import Iconify from "src/components/iconify";
import { SaveIcon } from "lucide-react";
import { Stack } from "@mui/system";
import cogoToast from "cogo-toast";
const fieldNameLookup = {
  specimen_id: "Specimen ID",
  collection_date: "Collection Date",
  collector_name: "Collector Name",
  donor_name: "Donor Name",
  donor_signature: "Donor Signature",
  donor_signature_date: "Donor Signature Date",
  reason_for_test: "Reason for Test",
  test_type: "Test Type",
  substances_tested: "Substances Tested",
  collection_site: "Collection Site",
  collector_signature: "Collector Signature",
  collector_signature_date: "Collector Signature Date",
  specimen_temperature: "Specimen Temperature",
  medical_review_officer_name: "Medical Review Officer Name",
  medical_review_officer_signature: "Medical Review Officer Signature",
  medical_review_officer_signature_date:
    "Medical Review Officer Signature Date",
  final_result: "Final Result",
};

export const ChainOfCustodyDataViewer = ({ data_, onUpdate }) => {
  const theme = useTheme();
  const [data, setData] = useState(data_);
  const [abnormalFields, setAbnormalFields] = useState(data.hasAbnormal || []);
  const [hasChange, setHasChange] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const handleSave = () => {
    setIsSaving(true);
    onUpdate(data);
    setIsSaving(false);
    setHasChange(false);
  };
  let saveElem = null;
  if (hasChange && !isSaving) {
    saveElem = (
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="overline">Unsaved Changes</Typography>
        <Button
          variant="contained"
          onClick={handleSave}
          startIcon={<Iconify icon="bx:save" />}
        >
          Save
        </Button>
      </Stack>
    );
  }
  if (isSaving) {
    saveElem = (
      <Stack direction="row" spacing={2}>
        <Typography variant="overline">Saving...</Typography>
        <Iconify icon="pulse-3" />
      </Stack>
    );
  }

  const handleChange = (field, value) => {
    if (!onUpdate) {
      cogoToast.error("Updates are restricted.");
      return;
    }
    const updatedData = { ...data, [field]: value };
    setData(updatedData);
    setHasChange(true);
    setIsSaving(false);
  };

  const toggleAbnormal = (field) => {
    const newAbnormalFields = abnormalFields.includes(field)
      ? abnormalFields.filter((f) => f !== field)
      : [...abnormalFields, field];
    setAbnormalFields(newAbnormalFields);
    onUpdate({ ...data, hasAbnormal: newAbnormalFields });
  };

  const isAbnormal = (field) => abnormalFields.includes(field);

  const getTooltipText = (field) => {
    return isAbnormal(field)
      ? "This result is potentially abnormal. Click to mark it as normal."
      : "This result is normal. Click to mark it as potentially abnormal.";
  };

  const renderEditableCell = (field, value) => {
    if (typeof value === "boolean") {
      return (
        <Switch
          checked={value}
          onChange={(e) => handleChange(field, e.target.checked)}
        />
      );
    }

    if (Array.isArray(value)) {
      return (
        <TextField
          fullWidth
          size="small"
          value={value.join(", ")}
          onChange={(e) => handleChange(field, e.target.value.split(", "))}
        />
      );
    }

    return (
      <TextField
        fullWidth
        size="small"
        value={value || ""}
        onChange={(e) => handleChange(field, e.target.value)}
      />
    );
  };

  const renderRow = (field) => (
    <TableRow
      key={field}
      sx={{
        bgcolor: isAbnormal(field) ? theme.palette.warning.light : "inherit",
      }}
    >
      <TableCell>
        <Box display="flex" alignItems="center">
          {fieldNameLookup[field] || field}
          <Tooltip title={getTooltipText(field)} arrow>
            <Box
              component="span"
              sx={{ display: "inline-flex", ml: 1, cursor: "pointer" }}
            >
              <Iconify
                icon={isAbnormal(field) ? "ep:warning-filled" : "mdi:tick"}
                onClick={() => toggleAbnormal(field)}
              />
            </Box>
          </Tooltip>
        </Box>
      </TableCell>
      <TableCell>{renderEditableCell(field, data[field])}</TableCell>
    </TableRow>
  );

  const renderHeader = () => {
    if (abnormalFields.length === 0) {
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">Chain of Custody</Typography>
          {saveElem}
        </Box>
      );
    }

    const abnormalFieldNames = abnormalFields
      .map((field) => fieldNameLookup[field] || field)
      .join(", ");

    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{
          breakAfter: "avoid",
        }}
      >
        <Box display="flex" alignItems="center">
          <Iconify
            icon="mdi:warning"
            sx={{ mr: 1, color: theme.palette.error.main }}
          />
          <Typography variant="subtitle1">
            Chain of Custody | Red Flags: {abnormalFieldNames}
          </Typography>
          {saveElem}
        </Box>
      </Box>
    );
  };

  return (
    <div
      style={{
        backgroundColor: hasChange ? theme.palette.warning.lighter : "inherit",
      }}
    >
      <Card
        sx={{
          p: 2,
          m: 1,
          bgcolor:
            abnormalFields.length > 0
              ? theme.palette.error.light
              : theme.palette.info.lighter,
        }}
      >
        {renderHeader()}
      </Card>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {Object.keys(data)
              .filter((key) => !["dataType", "hasAbnormal"].includes(key))
              .map(renderRow)}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
