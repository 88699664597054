export const POOL_SETTINGS = {
  subheader: "Pool Information",
  caption: "Details about the pool if this protocol is a pool",
  items: [
    {
      id: "drawRequirementDrugs",
      label: "Draw Requirement For Drug (%)",
      type: "TEXT",
      isNumber: true,
      isPool: true,
    },
    {
      id: "drawRequirementAlcohol",
      label: "Draw Requirement For Alcohol (%)",
      type: "TEXT",
      isNumber: true,
      isPool: true,
    },
    {
      id: "companyProtocolFrequencyValue",
      label: "Draw Frequency (Value)",
      type: "TEXT",
      isNumber: true,
      isPool: true,
    },
    {
      id: "companyProtocolFrequencyUnit",
      label: "Draw Frequency (Unit)",
      type: "OPTIONS",
      options: ["Day(s)", "Week(s)", "Month(s)", "Year(s)"],
      isPool: true,
    },
    {
      id: "poolCompleteVisitWithinValue",
      label: "Complete Visit Within (Value)",
      type: "TEXT",
      isNumber: true,
      isPool: true,
    },
    {
      id: "poolCompleteVisitWithinUnit",
      label: "Complete Visit Within (Unit)",
      type: "OPTIONS",
      options: ["Day(s)", "Week(s)", "Month(s)", "Year(s)"],
      isPool: true,
    },
    {
      id: "canPoolDrawVisitGoIntoNextPeriod",
      label: "Can Pool Draw Visit Go Into Next Period?",
      type: "SWITCH",
      isPool: true,
    },
  ],
};
