import React from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  OverlayView,
} from "@react-google-maps/api";

import { Alert, Chip, LinearProgress, Typography } from "@mui/material";
import Iconify from "src/components/iconify";
const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "50vh",
};
const center = {
  lat: 38.89768,
  lng: -77.0343434,
};

const GMapMultiple = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  if (loadError) {
    return (
      <div>
        <Alert severity="warning">Map failed to load.</Alert>
      </div>
    );
  }

  if (!isLoaded) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={8}
        center={props.placeDetail?.lat ? props.placeDetail : center}
      >
        {props.markers.map((marker, index) => {
          if (marker._id === "EMPLOYEE") {
            return (
              <React.Fragment key={index}>
                <Marker
                  draggable={false}
                  position={{ lat: marker.lat, lng: marker.lng }}
                />
                <OverlayView
                  position={{ lat: marker.lat, lng: marker.lng }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <div
                    style={{
                      background: "lightblue",
                      border: "2px solid blue",
                      padding: 3,
                      borderRadius: 5,
                      width: "fit-content",
                    }}
                  >
                    <Typography variant="overline">Employee</Typography>
                  </div>
                </OverlayView>
              </React.Fragment>
            );
          }
          if (props.chosenMarkerIndices.includes(marker._id)) {
            return (
              <React.Fragment key={index}>
                <Marker
                  draggable={false}
                  onClick={() => {
                    props.onSelectMarker(marker._id);
                  }}
                  position={{ lat: marker.lat, lng: marker.lng }}
                />
                <OverlayView
                  position={{ lat: marker.lat, lng: marker.lng }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <div
                    style={{
                      background: "lightgreen",
                      border: "2px solid green",
                      padding: 3,
                      borderRadius: 5,
                      width: "fit-content",
                    }}
                  >
                    <Typography variant="overline">{marker.label}</Typography>
                  </div>
                </OverlayView>
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={index}>
              <Marker
                draggable={false}
                onClick={() => {
                  props.onSelectMarker(marker._id);
                }}
                position={{ lat: marker.lat, lng: marker.lng }}
              />
              <OverlayView
                position={{ lat: marker.lat, lng: marker.lng }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <div
                  style={{
                    background: "lightgrey",
                    border: "1px solid #ccc",
                    padding: 3,
                    borderRadius: 5,
                    width: "fit-content",
                  }}
                >
                  <Typography variant="overline">{marker.label}</Typography>
                </div>
              </OverlayView>
            </React.Fragment>
          );
        })}
      </GoogleMap>
    </div>
  );
};

export default GMapMultiple;
