import { BarChart, Card, DonutChart, List, ListItem } from "@tremor/react";
import React from "react";
import _, { filter } from "lodash";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import {
  FILTER_UTILS,
  sumAggregation,
  averageAggregation,
  maxAggregation,
  minAggregation,
  countAggregation,
} from "../../utils/grid-utils";
import { formatTime } from "../utils/fn";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Button,
  //   Card,
  Stack,
  Chip,
  Drawer,
  LinearProgress,
  MenuItem,
  Select,
  Skeleton,
  Table,
  TableBody,
  TextField,
  Tooltip,
  Typography,
  InputLabel,
  FormControl,
  Box,
  Checkbox,
  Avatar,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  Badge,
  ListItemText,
  Divider,
} from "@mui/material";
import { RiErrorWarningLine } from "@remixicon/react";

class VisitStatusReport extends React.Component {
  state = {
    data: [],
    filterCompany: [],
    filterStage: [],
    gridApi: null,
    columnApi: null,
    aggregations: {
      sum: (params) => sumAggregation(params),
      average: (params) => averageAggregation(params),
      max: (params) => maxAggregation(params),
      min: (params) => minAggregation(params),
      count: (params) => countAggregation(params),
    },
  };

  componentDidMount() {
    console.log("vsr::componentDidMount", this.props.data);
    this.setState({
      data: this.props.data,
    });
  }

  /*** Company FILTER  */
  renderCompanySelectionFilter = () => {
    const filtItems = _.uniq(
      this.props.data.map((item) => item.company.companyInformalName)
    );

    if (this.props.disableCompanySelector && filtItems.length == 1) {
      return null;
    }
    return (
      <FormControl>
        <InputLabel id="label" size="small">
          Filter By Employer
        </InputLabel>
        <Select
          size="small"
          labelId="label"
          placeholder="Status"
          multiple
          value={this.state.filterCompany}
          onChange={(e) => {
            let v = e.target.value;
            const fCompany = typeof v === "string" ? v.split(",") : v;
            this.setState({
              filterCompany: fCompany,
            });
          }}
          sx={{
            minWidth: "200px",
          }}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip size="small" key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {filtItems.map((item) => (
            <MenuItem key={item} value={item} size="small">
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  FILT_STAGES = {
    "Pre-Visit": ["REQUESTED", "OUTREACH_FOR_SCHEDULING", "SCHEDULED"],
    "Review & Chaser": [
      "PENDING_DOCUMENTS_FROM_CLINIC",
      "PARTIAL_DOCUMENTS_RECEIVED",
      "REVIEW_NOT_STARTED",
      "IN_RN_REVIEW",
      "IN_MD_REVIEW",
      "PENDING_INFORMATION_FROM_EMPLOYEE",
      "PENDING_AFFIDAVIT",
    ],
    Closed: [
      "CLEARED",
      "CLEARED_WITH_RESTRICTIONS",
      "NOT_CLEARED",
      "NO_SHOW",
      "CANCELLED",
    ],
    Administrative: [
      "POSSIBLE_NO_SHOW",
      "PANEL_CHANGE_REQUESTED",
      "ADDITIONAL_TESTING_REQUIRED",
      "RETURN_TO_CLINIC",
    ],
  };

  renderVisitStatusGroupFilter = () => {
    let filterStageKeys = Object.keys(this.FILT_STAGES);
    return (
      <FormControl>
        <InputLabel id="label" size="small">
          Filter By Stage
        </InputLabel>
        <Select
          size="small"
          labelId="label"
          placeholder="Stage"
          multiple
          value={this.state.filterStage}
          onChange={(e) => {
            let v = e.target.value;
            const fStage = typeof v === "string" ? v.split(",") : v;
            const fCompany = this.state.filterCompany;
            this.setState({
              filterStage: fStage,
            });
          }}
          sx={{
            minWidth: "200px",
          }}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip size="small" key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {filterStageKeys.map((item) => (
            <MenuItem key={item} value={item} size="small">
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  /******** GRID BEGINS */
  /** COLUMNS */
  COLUMNS = [
    {
      headerName: "Visit ID",
      field: "humanReadableId",
      cellDataType: "text",
    },
    {
      headerName: "Visit Status",
      field: "visitStatus",
      cellDataType: "text",
    },
    {
      headerName: "Employee",
      field: "employee.employeeName",
      cellDataType: "text",
    },
    {
      headerName: "Employer",
      field: "company.companyInformalName",
      cellDataType: "text",
    },
    {
      headerName: "Protocol(s)",
      //   field: "protocol.companyProtocolName",
      valueGetter: (params) => {
        return _.join(
          params.data.bookedProtocols.map((bp) => bp.companyProtocolName),
          ","
        );
      },
      cellDataType: "text",
    },
    // {
    //   headerName: "Service",
    //   field: "psl.providerServiceAlias",
    //   cellDataType: "text",
    // },
    {
      headerName: "Preference Recorded",
      field: "employeeSchedulingPreference",
      cellDataType: "BOOLEAN",
    },
    {
      headerName: "Visit Requested At",
      field: "createdAt",
      cellDataType: "date",
    },
    {
      headerName: "Visit Requested At (Time)",
      field: "createdAt",
      cellDataType: "time",
    },
    // {
    //   headerName: "Requested By",
    //   field: "visitCreatedBy.name",
    //   cellDataType: "text",
    // },
    {
      headerName: "Requester User Type ",
      field: "visitCreatedBy.isExternal",
      cellDataType: "text",
      valueFormatter: (params) => {
        return params?.value ? "External" : "Internal";
      },
    },
    {
      headerName: "Scheduling Confirmed At",
      field: "createdAt",
      cellDataType: "date",
    },
    {
      headerName: "Scheduling Confirmed At (Time)",
      field: "createdAt",
      cellDataType: "time",
    },
    {
      headerName: "Scheduled By",
      field: "scheduledBy.name",
      cellDataType: "text",
    },
    {
      headerName: "Scheduler User Type ",
      field: "createdBy.isExternal",
      cellDataType: "text",
      valueFormatter: (params) => {
        return params?.value ? "External" : "Internal";
      },
    },
    {
      headerName: "Date of Visit",
      valueGetter: (params) => {
        return _.min(params.data.clinicPSLs.map((bp) => bp.scheduledTime));
      },
      cellDataType: "date",
    },
    {
      headerName: "Date of Visit (Time)",
      valueGetter: (params) => {
        return _.min(params.data.clinicPSLs.map((bp) => bp.scheduledTime));
      },
      cellDataType: "time",
    },

    {
      headerName: "Provider(s)",
      valueGetter: (params) => {
        return _.join(
          _.uniq(
            params.data.clinicPSLs.map((bp) => bp.providerObj.providerName)
          ),
          ","
        );
      },
      cellDataType: "text",
    },

    // {
    //   headerName: "Request to Schedule (Cal. Days)",
    //   valueGetter: (params) => {
    //     let visitRequestedAt = new Date(params.data.visit.createdAt);
    //     let schedulingConfirmedAt = new Date(params.data.createdAt);
    //     let diff = Math.abs(
    //       visitRequestedAt.getTime() - schedulingConfirmedAt.getTime()
    //     );
    //     return Math.round(diff / (1000 * 60 * 60 * 24));
    //   },
    //   cellDataType: "number",
    // },
    // {
    //   headerName: "Schedule to Visit (Cal. Days)",
    //   valueGetter: (params) => {
    //     let visitRequestedAt = new Date(params.data.scheduledTime);
    //     let schedulingConfirmedAt = new Date(params.data.createdAt);
    //     let diff = Math.abs(
    //       visitRequestedAt.getTime() - schedulingConfirmedAt.getTime()
    //     );
    //     return Math.round(diff / (1000 * 60 * 60 * 24));
    //   },
    //   cellDataType: "number",
    // },
  ];

  onGridReady = (params) => {
    this.setState({ gridApi: params.api, columnApi: params.columnApi });
  };
  /** RENDER GRID */
  renderGrid = () => {
    let cs = "ag-theme-quartz";
    let theme = localStorage.getItem("themeMode");
    if (theme && theme === "dark") {
      cs = "ag-theme-quartz-dark";
    }
    return (
      <div className={cs} style={{ height: "70vh" }}>
        <AgGridReact
          onGridReady={this.onGridReady}
          rowData={this.state.data}
          columnDefs={this.COLUMNS.map((x) => {
            return { ...FILTER_UTILS[x.cellDataType], ...x };
          })}
          defaultColDef={{
            sortable: true,
          }}
          autoSizeStrategy={{
            type: "fitCellContents",
          }}
          pagination={true}
          rowGroupPanelShow={"always"}
          aggFuncs={this.state.aggregations}
          suppressDragLeaveHidesColumns={true}
          rowSelection="multiple"
          rowDragManaged={true}
          enableCharts={true}
          enableRangeSelection={true}
          enableCellTextSelection={true}
          sideBar={true}
          animateRows={true}
          statusBar={{
            statusPanels: [
              {
                statusPanel: "agTotalAndFilteredRowCountComponent",
                align: "left",
              },
              {
                statusPanel: "agSelectedRowCountComponent",
                align: "left",
              },
              {
                statusPanel: "agAggregationComponent",
                align: "right",
              },
            ],
          }}
          defaultExcelExportParams={{
            author: "WorkCare",
            fileName: `${this.props.reportType} - ${new Date().toLocaleDateString()}`,
            sheetName: `${this.props.reportType} - ${new Date().toLocaleDateString()}`,
          }}
        />
      </div>
    );
  };
  /******** GRID ENDS */
  /*********** CHART BEGINS */
  classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };
  filterData = () => {
    let rawData = this.state.data.map((i) => ({
      ...i,
      hrDate: new Date(i.createdAt).toLocaleDateString("en-US"),
    }));

    let data = rawData;
    if (this.state.filterCompany.length > 0) {
      data = rawData.filter((item) => {
        return this.state.filterCompany.includes(
          item.company.companyInformalName
        );
      });
    }
    if (this.state.filterStage.length > 0) {
      let stages = this.state.filterStage.reduce((acc, stage) => {
        return acc.concat(this.FILT_STAGES[stage]);
      }, []);
      data = data.filter((item) => {
        return stages.includes(item.visitStatus);
      });
    }
    return data;
  };
  dataMassager = () => {
    let data = this.filterData();
    const groupedByDate = {};
    const allStatuses = new Set();
    for (let i = 0; i < data.length; i++) {
      const name = data[i].visitStatus;
      const hrDate = data[i].hrDate;
      allStatuses.add(name);

      if (!groupedByDate[hrDate]) {
        groupedByDate[hrDate] = {
          date: hrDate,
        };
      }

      if (!groupedByDate[hrDate][name]) {
        groupedByDate[hrDate][name] = 0;
      }
      groupedByDate[hrDate][name] += 1;
    }

    const values = Object.values(groupedByDate);

    const requestToSchedule = [];
    const scheduleToAppointment = [];
    const appointmentToCompletion = [];
    const requestToCompletion = [];

    for (const visit of data) {
      const requestDate = visit.createdAt;
      const appointmentDate = visit.visitAt;
      const scheduledDate = visit.confirmedAt;
      const completionDate = visit.completedAt;

      if (scheduledDate && requestDate) {
        const requestToScheduleDays = Math.round(
          (new Date(scheduledDate) - new Date(requestDate)) / 1000
        );
        requestToSchedule.push(requestToScheduleDays);
      }

      if (appointmentDate && scheduledDate) {
        const scheduleToAppointmentDays = Math.round(
          (new Date(appointmentDate) - new Date(scheduledDate)) / 1000
        );
        scheduleToAppointment.push(scheduleToAppointmentDays);
      }

      if (completionDate && appointmentDate) {
        const appointmentToCompletionDays = Math.round(
          (new Date(completionDate) - new Date(appointmentDate)) / 1000
        );
        appointmentToCompletion.push(appointmentToCompletionDays);
      }

      if (completionDate && requestDate) {
        const requestToCompletionDays = Math.round(
          (new Date(completionDate) - new Date(requestDate)) / 1000
        );
        requestToCompletion.push(requestToCompletionDays);
      }
    }

    return {
      data: _.sortBy(values, "createdAt"),
      categories: Array.from(allStatuses),
      requestToSchedule: {
        average: _.mean(requestToSchedule),
        max: _.max(requestToSchedule),
      },
      scheduleToAppointment: {
        average: _.mean(scheduleToAppointment),
        max: _.max(scheduleToAppointment),
      },
      appointmentToCompletion: {
        average: _.mean(appointmentToCompletion),
        max: _.max(appointmentToCompletion),
      },
      requestToCompletion: {
        average: _.mean(requestToCompletion),
        max: _.max(requestToCompletion),
      },
    };
  };
  renderChart = () => {
    let {
      data,
      categories,
      requestToCompletion,
      requestToSchedule,
      scheduleToAppointment,
      appointmentToCompletion,
    } = this.dataMassager();

    let totalsData = this.filterData();
    let totalStatusCounts = _.groupBy(totalsData, "visitStatus");

    let statusKeys = Object.keys(totalStatusCounts);
    let totalsDataArr = statusKeys.map((key) => {
      return {
        name: key,
        amount: totalStatusCounts[key].length,
        share: `${(
          (totalStatusCounts[key].length / totalsData.length) *
          100
        ).toFixed(2)}%`,
      };
    });

    return (
      <div className="grid grid-cols-[2fr_1fr]">
        <Card className="w-full max-w-full">
          <Stack direction="row" spacing={4} className="justify-between">
            <h3 className="font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Visit Status
            </h3>
            <h3 className="font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              {this.renderVisitStatusGroupFilter()}{" "}
              {this.renderCompanySelectionFilter()}
            </h3>
          </Stack>
          <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
            Visit Status Distribution
          </p>

          <BarChart
            data={data}
            index="date"
            categories={categories}
            showLegend={false}
            yAxisWidth={45}
            stack={true}
            className="mt-6 hidden h-96 sm:block"
            customTooltip={(payload) => {
              if (!payload.active) {
                return "";
              }
              let data = payload.payload;
              let sum = _.sumBy(data, "value");
              return (
                <div
                  className="mt-8 flex justify-center"
                  style={{
                    zIndex: "1001 !important",
                  }}
                >
                  <div className="w-52 rounded-tremor-default border border-tremor-border bg-tremor-background text-tremor-default shadow-tremor-input dark:border-dark-tremor-border dark:bg-dark-tremor-background dark:shadow-dark-tremor-input">
                    <div className="rounded-t-tremor-default border-b border-tremor-border bg-tremor-background-muted px-2.5 py-2 dark:border-dark-tremor-border dark:bg-dark-tremor-background">
                      <p className="font-medium text-tremor-content dark:text-dark-tremor-content">
                        {payload.label}: {sum}
                      </p>
                    </div>
                    {data.map((each) => {
                      if (!each.value) {
                        return null;
                      }
                      const classNameStr = each.className;
                      const className = classNameStr.replace("fill-", "bg-");
                      return (
                        <div className="flex w-full items-center justify-between space-x-4 px-2.5 py-2">
                          <div className="flex items-center space-x-2 truncate">
                            <span
                              className={`h-2.5 w-2.5 shrink-0 rounded-full ${className}`}
                              aria-hidden={true}
                            />
                            <p className="truncate text-tremor-content dark:text-dark-tremor-content">
                              {each.dataKey}
                            </p>
                          </div>
                          <p className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                            {each.value}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            }}
          />
          <ul
            role="list"
            className="mt-6 grid gap-3 sm:grid-cols-2 md:grid-cols-4"
          >
            <li className="rounded-tremor-small border border-tremor-border px-3 py-2 text-left dark:border-dark-tremor-border">
              <div className="flex items-center space-x-1.5">
                <span
                  className={this.classNames(
                    "bg-blue-500",
                    "h-2.5 w-2.5 rounded-sm"
                  )}
                  aria-hidden={true}
                />
                <p className="text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                  Request to Schedule
                </p>
              </div>
              <p className="mt-0.5 dark:text-dark-tremor-content-strong text-sm">
                Average: {formatTime(requestToSchedule.average)}
              </p>
              <span
                className={this.classNames(
                  "bg-red-100 text-red-800 dark:bg-red-400/10 dark:text-red-500",
                  "mt-4 inline-flex items-center gap-x-1.5 rounded-tremor-small px-2 py-1.5 text-tremor-label font-medium"
                )}
              >
                <RiErrorWarningLine
                  className="h-4 w-4 shrink-0"
                  aria-hidden={true}
                />
                Worst: {formatTime(requestToSchedule.max)}
              </span>
            </li>
            <li className="rounded-tremor-small border border-tremor-border px-3 py-2 text-left dark:border-dark-tremor-border">
              <div className="flex items-center space-x-1.5">
                <span
                  className={this.classNames(
                    "bg-blue-500",
                    "h-2.5 w-2.5 rounded-sm"
                  )}
                  aria-hidden={true}
                />
                <p className="text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                  Schedule to Appointment
                </p>
              </div>
              <p className="mt-0.5 dark:text-dark-tremor-content-strong text-sm">
                Average: {formatTime(scheduleToAppointment.average)}
              </p>
              <span
                className={this.classNames(
                  "bg-red-100 text-red-800 dark:bg-red-400/10 dark:text-red-500",
                  "mt-4 inline-flex items-center gap-x-1.5 rounded-tremor-small px-2 py-1.5 text-tremor-label font-medium"
                )}
              >
                <RiErrorWarningLine
                  className="h-4 w-4 shrink-0"
                  aria-hidden={true}
                />
                Worst: {formatTime(scheduleToAppointment.max)}
              </span>
            </li>
            <li className="rounded-tremor-small border border-tremor-border px-3 py-2 text-left dark:border-dark-tremor-border">
              <div className="flex items-center space-x-1.5">
                <span
                  className={this.classNames(
                    "bg-blue-500",
                    "h-2.5 w-2.5 rounded-sm"
                  )}
                  aria-hidden={true}
                />
                <p className="text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                  Appointment to Completion
                </p>
              </div>
              <p className="mt-0.5 dark:text-dark-tremor-content-strong text-sm">
                Average: {formatTime(appointmentToCompletion.average)}
              </p>
              <span
                className={this.classNames(
                  "bg-red-100 text-red-800 dark:bg-red-400/10 dark:text-red-500",
                  "mt-4 inline-flex items-center gap-x-1.5 rounded-tremor-small px-2 py-1.5 text-tremor-label font-medium"
                )}
              >
                <RiErrorWarningLine
                  className="h-4 w-4 shrink-0"
                  aria-hidden={true}
                />
                Worst: {formatTime(appointmentToCompletion.max)}
              </span>
            </li>
            <li className="rounded-tremor-small border border-tremor-border px-3 py-2 text-left dark:border-dark-tremor-border">
              <div className="flex items-center space-x-1.5">
                <span
                  className={this.classNames(
                    "bg-blue-500",
                    "h-2.5 w-2.5 rounded-sm"
                  )}
                  aria-hidden={true}
                />
                <p className="text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                  Request To Completion
                </p>
              </div>
              <p className="mt-0.5 dark:text-dark-tremor-content-strong text-sm">
                Average: {formatTime(requestToCompletion.average)}
              </p>
              <span
                className={this.classNames(
                  "bg-red-100 text-red-800 dark:bg-red-400/10 dark:text-red-500",
                  "mt-4 inline-flex items-center gap-x-1.5 rounded-tremor-small px-2 py-1.5 text-tremor-label font-medium"
                )}
              >
                <RiErrorWarningLine
                  className="h-4 w-4 shrink-0"
                  aria-hidden={true}
                />
                Worst: {formatTime(requestToCompletion.max)}
              </span>
            </li>
          </ul>
        </Card>
        <Card className="sm:max-w-lg">
          <h3 className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
            Visit Status - Total Counts
          </h3>
          <DonutChart
            className="mt-8"
            data={totalsDataArr}
            category="amount"
            index="name"
            showTooltip={true}
            colors={[
              "cyan",
              "blue",
              "indigo",
              "violet",
              "fuchsia",
              "pink",
              "red",
              "orange",
              "yellow",
              "lime",
              "green",
              "teal",
              "cyan",
              "blue",
              "indigo",
              "violet",
              "fuchsia",
              "pink",
              "red",
              "orange",
              "yellow",
              "lime",
              "green",
              "teal",
              "cyan",
              "blue",
              "indigo",
              "violet",
              "fuchsia",
              "pink",
              "red",
              "orange",
              "yellow",
              "lime",
              "green",
              "teal",
              "cyan",
              "blue",
              "indigo",
              "violet",
              "fuchsia",
              "pink",
              "red",
              "orange",
              "yellow",
              "lime",
              "green",
              "teal",
            ]}
          />
          <p className="mt-8 flex items-center justify-between text-tremor-label text-tremor-content dark:text-dark-tremor-content">
            <span>Status</span>
            <span>Count / Share</span>
          </p>
          <List className="mt-2">
            {totalsDataArr.map((item, index) => (
              <ListItem key={index} className="space-x-6">
                <div className="flex items-center space-x-2.5 truncate">
                  <span
                    className={this.classNames(
                      "h-2.5 w-2.5 shrink-0 rounded-sm"
                    )}
                    aria-hidden={true}
                  />
                  <span className="truncate dark:text-dark-tremor-content-emphasis">
                    {item.name}
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="font-medium tabular-nums text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    {item.amount}
                  </span>
                  <span className="rounded-tremor-small bg-tremor-background-subtle px-1.5 py-0.5 text-tremor-label font-medium tabular-nums text-tremor-content-emphasis dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis">
                    {item.share}
                  </span>
                </div>
              </ListItem>
            ))}
          </List>
        </Card>
      </div>
    );
  };
  renderAgingChart = () => {
    let data = this.filterData();

    // createdAt is the time stamp of creation. groupby time since createdAt in days

    let groupedByDate = _.groupBy(data, (item) => {
      let createdAt = new Date(item.createdAt);
      let now = new Date();
      let diff = Math.abs(now.getTime() - createdAt.getTime());
      return Math.round(diff / (1000 * 60 * 60 * 24));
    });
    let stackByVisitStatus = _.mapValues(groupedByDate, (value) => {
      return _.groupBy(value, "visitStatus");
    });
    stackByVisitStatus = _.mapValues(stackByVisitStatus, (value) => {
      return _.mapValues(value, (v) => v.length);
    });
    let massagedData = _.map(stackByVisitStatus, (value, key) => {
      return {
        days: key,
        ...value,
      };
    });
    let categories = _.uniq(data.map((item) => item.visitStatus));

    // totals by less than 4 days, 4 to 7 days and more than 7 days

    let lessThan4 = _.filter(data, (item) => {
      let createdAt = new Date(item.createdAt);
      let now = new Date();
      let diff = Math.abs(now.getTime() - createdAt.getTime());
      return Math.round(diff / (1000 * 60 * 60 * 24)) < 4;
    });
    let fourToSeven = _.filter(data, (item) => {
      let createdAt = new Date(item.createdAt);
      let now = new Date();
      let diff = Math.abs(now.getTime() - createdAt.getTime());
      return (
        Math.round(diff / (1000 * 60 * 60 * 24)) >= 4 &&
        Math.round(diff / (1000 * 60 * 60 * 24)) <= 7
      );
    });
    let moreThan7 = _.filter(data, (item) => {
      let createdAt = new Date(item.createdAt);
      let now = new Date();
      let diff = Math.abs(now.getTime() - createdAt.getTime());
      return Math.round(diff / (1000 * 60 * 60 * 24)) > 7;
    });

    let totalsDataArr = [
      {
        name: "Less than 4 days",
        amount: lessThan4.length,
        share: `${((lessThan4.length / data.length) * 100).toFixed(2)}%`,
      },
      {
        name: "4 to 7 days",
        amount: fourToSeven.length,
        share: `${((fourToSeven.length / data.length) * 100).toFixed(2)}%`,
      },
      {
        name: "More than 7 days",
        amount: moreThan7.length,
        share: `${((moreThan7.length / data.length) * 100).toFixed(2)}%`,
      },
    ];

    return (
      <div className="grid grid-cols-[2fr_1fr]">
        <Card className="w-full max-w-full">
          <Stack direction="row" spacing={4} className="justify-between">
            <h3 className="font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Visit Aging Report
            </h3>
          </Stack>
          <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
            Visit Status Distribution - Aging
          </p>

          <BarChart
            data={massagedData}
            index="days"
            categories={categories}
            showLegend={false}
            yAxisWidth={45}
            stack={true}
            className="mt-6 hidden h-96 sm:block"
            customTooltip={(payload) => {
              if (!payload.active) {
                return "";
              }
              let data = payload.payload;
              let sum = _.sumBy(data, "value");
              return (
                <div
                  className="mt-8 flex justify-center"
                  style={{
                    zIndex: "1001 !important",
                  }}
                >
                  <div className="w-52 rounded-tremor-default border border-tremor-border bg-tremor-background text-tremor-default shadow-tremor-input dark:border-dark-tremor-border dark:bg-dark-tremor-background dark:shadow-dark-tremor-input">
                    <div className="rounded-t-tremor-default border-b border-tremor-border bg-tremor-background-muted px-2.5 py-2 dark:border-dark-tremor-border dark:bg-dark-tremor-background">
                      <p className="font-medium text-tremor-content dark:text-dark-tremor-content">
                        {payload.label} Day{payload.label === "1" ? "" : "s"}:{" "}
                        {sum}
                      </p>
                    </div>
                    {data.map((each) => {
                      if (!each.value) {
                        return null;
                      }
                      const classNameStr = each.className;
                      const className = classNameStr.replace("fill-", "bg-");
                      return (
                        <div className="flex w-full items-center justify-between space-x-4 px-2.5 py-2">
                          <div className="flex items-center space-x-2 truncate">
                            <span
                              className={`h-2.5 w-2.5 shrink-0 rounded-full ${className}`}
                              aria-hidden={true}
                            />
                            <p className="truncate text-tremor-content dark:text-dark-tremor-content">
                              {each.dataKey}
                            </p>
                          </div>
                          <p className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                            {each.value}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            }}
          />
        </Card>
        <Card className="sm:max-w-lg">
          <h3 className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
            Visit Aging Report - Categories
          </h3>
          <DonutChart
            className="mt-8"
            data={totalsDataArr}
            category="amount"
            index="name"
            showTooltip={true}
            colors={[
              "cyan",
              "blue",
              "indigo",
              "violet",
              "fuchsia",
              "pink",
              "red",
              "orange",
              "yellow",
              "lime",
              "green",
              "teal",
              "cyan",
              "blue",
              "indigo",
              "violet",
              "fuchsia",
              "pink",
              "red",
              "orange",
              "yellow",
              "lime",
              "green",
              "teal",
              "cyan",
              "blue",
              "indigo",
              "violet",
              "fuchsia",
              "pink",
              "red",
              "orange",
              "yellow",
              "lime",
              "green",
              "teal",
              "cyan",
              "blue",
              "indigo",
              "violet",
              "fuchsia",
              "pink",
              "red",
              "orange",
              "yellow",
              "lime",
              "green",
              "teal",
            ]}
          />
          <p className="mt-8 flex items-center justify-between text-tremor-label text-tremor-content dark:text-dark-tremor-content">
            <span>Category</span>
            <span>Count / Share</span>
          </p>
          <List className="mt-2">
            {totalsDataArr.map((item, index) => (
              <ListItem key={index} className="space-x-6">
                <div className="flex items-center space-x-2.5 truncate">
                  <span
                    className={this.classNames(
                      "h-2.5 w-2.5 shrink-0 rounded-sm"
                    )}
                    aria-hidden={true}
                  />
                  <span className="truncate dark:text-dark-tremor-content-emphasis">
                    {item.name}
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="font-medium tabular-nums text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    {item.amount}
                  </span>
                  <span className="rounded-tremor-small bg-tremor-background-subtle px-1.5 py-0.5 text-tremor-label font-medium tabular-nums text-tremor-content-emphasis dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis">
                    {item.share}
                  </span>
                </div>
              </ListItem>
            ))}
          </List>
        </Card>
      </div>
    );
  };

  /********** CHART ENDS */
  render() {
    return (
      <div>
        {this.renderChart()}
        {this.renderAgingChart()}
        {this.renderGrid()}
      </div>
    );
  }
}
export default VisitStatusReport;
