import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Select,
  MenuItem,
  TextField,
  Snackbar,
  Grid,
} from "@mui/material";
import { WithAPICall } from "../utils/apiUtil";
import { PROTOCOL_ADDON_FEE_TYPES } from "src/statics/addon-fees";
import cogoToast from "cogo-toast";

const ProtocolAddonFees = ({ protocolId, data, onUpdate, apiCallPost }) => {
  const [rows, setRows] = useState(data.addOnFees || []);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const addRow = () => {
    // Check if there's any row with an empty feeType
    const hasEmptyFeeType = rows.some((row) => row.feeType === "");
    if (hasEmptyFeeType) {
      setOpenSnackbar(true);
      return;
    }
    setRows([...rows, { addOnFeeType: "", addOnFeeUSD: "" }]);
  };

  const saveFees = async () => {
    try {
      cogoToast.loading("Updating Protocol Data...");
      const res = await apiCallPost(
        "/company-protocol/companyProtocolUpdateScalarValue",
        {
          companyProtocolId: protocolId,
          key: "addOnFees",
          value: rows.filter(
            (row) => row.addOnFeeType !== "" && row.addOnFeeUSD > 0
          ),
        }
      );
      await onUpdate("addOnFees", res.addOnFees);
      setOpenSnackbar(false);
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Updating Protocol Data");
    }
  };

  const handleFeeTypeChange = (index, value) => {
    // Prevent adding a fee type that already exists
    if (rows.some((row) => row.addOnFeeType === value)) {
      setOpenSnackbar(true);
      return;
    }
    const newRows = [...rows];
    newRows[index].addOnFeeType = value;
    setRows(newRows);
  };

  const handleValueChange = (index, value) => {
    const newRows = [...rows];
    newRows[index].addOnFeeUSD = parseFloat(value);
    setRows(newRows);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="p-4">
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Cannot add duplicate or empty Fee Type"
      />
      <TableContainer component={Paper} className="mb-4">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Fee Type</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Select
                    value={row.addOnFeeType}
                    onChange={(e) => handleFeeTypeChange(index, e.target.value)}
                    fullWidth
                  >
                    {PROTOCOL_ADDON_FEE_TYPES.map((f) => (
                      <MenuItem key={f.key} value={f.value}>
                        {f.key}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <TextField
                    value={row.addOnFeeUSD}
                    onChange={(e) => handleValueChange(index, e.target.value)}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container spacing={2} className="mt-4">
        <Grid item>
          <Button variant="contained" size="small" onClick={addRow}>
            Add Row
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" size="small" onClick={saveFees}>
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default WithAPICall(ProtocolAddonFees);
