import { BarChart, Card } from "@tremor/react";
import React from "react";
import _ from "lodash";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Button,
  //   Card,
  Stack,
  Chip,
  Drawer,
  LinearProgress,
  MenuItem,
  Select,
  Skeleton,
  Table,
  TableBody,
  TextField,
  Tooltip,
  Typography,
  InputLabel,
  FormControl,
  Box,
  Checkbox,
  Avatar,
  TableRow,
  TableCell,
  List,
  ListItem,
  Paper,
  IconButton,
  Badge,
  ListItemText,
  Divider,
} from "@mui/material";

import {
  FILTER_UTILS,
  sumAggregation,
  averageAggregation,
  maxAggregation,
  minAggregation,
  countAggregation,
} from "../../utils/grid-utils";

class ReviewerWorkloadReport extends React.Component {
  state = {
    dataFilter: [],
    filterUser: [],
    isLoading: true,
    gridApi: null,
    columnApi: null,
    aggregations: {
      sum: (params) => sumAggregation(params),
      average: (params) => averageAggregation(params),
      max: (params) => maxAggregation(params),
      min: (params) => minAggregation(params),
      count: (params) => countAggregation(params),
    },
  };

  componentDidMount() {
    this.setState({
      isLoading: false,
      dataFilter: this.props.data,
    });
  }

  /***** User Filter Render */
  renderUserSelectionFilter = () => {
    return (
      <FormControl>
        <InputLabel id="label" size="small">
          Filter By User
        </InputLabel>
        <Select
          size="small"
          labelId="label"
          placeholder="Status"
          multiple
          value={this.state.filterUser}
          onChange={(e) => {
            let v = e.target.value;
            const fUser = typeof v === "string" ? v.split(",") : v;

            this.setState({
              filterUser: fUser,
              dataFilter: this.props.data.filter((item) => {
                return fUser.length === 0 ? true : fUser.includes(item.name);
              }),
            });
          }}
          sx={{
            minWidth: "200px",
          }}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip size="small" key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {this.props.data.map((item) => (
            <MenuItem key={item.name} value={item.name} size="small">
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  /******** GRID BEGINS */
  /** COLUMNS */
  COLUMNS = [
    {
      headerName: "Reviewer",
      field: "name",
      cellDataType: "text",
    },
    {
      headerName: "Qualif.",
      field: "reviewerType",
      cellDataType: "text",
    },
    {
      headerName: "Assigned Cases",
      field: "workload",
      cellDataType: "number",
    },
    {
      headerName: "Partial Documents Received",
      field: "workloadByStatus.PARTIAL_DOCUMENTS_RECEIVED",
      cellDataType: "number",
    },
    {
      headerName: "Review Not Started",
      field: "workloadByStatus.REVIEW_NOT_STARTED",
      cellDataType: "number",
    },
    {
      headerName: "In RN Review",
      field: "workloadByStatus.IN_RN_REVIEW",
      cellDataType: "number",
    },
    {
      headerName: "In MD Review",
      field: "workloadByStatus.IN_MD_REVIEW",
      cellDataType: "number",
    },
    {
      headerName: "Pending Information from Employee",
      field: "workloadByStatus.PENDING_INFORMATION_FROM_EMPLOYEE",
      cellDataType: "number",
    },
    {
      headerName: "Pending Affidavit",
      field: "workloadByStatus.PENDING_AFFIDAVIT",
      cellDataType: "number",
    },
    // {
    //   headerName: "Employer",
    //   field: "company.companyInformalName",
    //   cellDataType: "text",
    // },
    // {
    //   headerName: "Protocol(s)",
    //   //   field: "protocol.companyProtocolName",
    //   valueGetter: (params) => {
    //     return _.join(
    //       params.data.bookedProtocols.map((bp) => bp.companyProtocolName),
    //       ","
    //     );
    //   },
    //   cellDataType: "text",
    // },
    // {
    //   headerName: "Service",
    //   field: "psl.providerServiceAlias",
    //   cellDataType: "text",
    // },
    // {
    //   headerName: "Preference Recorded",
    //   field: "employeeSchedulingPreference",
    //   cellDataType: "BOOLEAN",
    // },
    // {
    //   headerName: "Visit Requested At",
    //   field: "createdAt",
    //   cellDataType: "date",
    // },
    // {
    //   headerName: "Visit Requested At (Time)",
    //   field: "createdAt",
    //   cellDataType: "time",
    // },
    // {
    //   headerName: "Requested By",
    //   field: "visitCreatedBy.name",
    //   cellDataType: "text",
    // },
    // {
    //   headerName: "Requester User Type ",
    //   field: "visitCreatedBy.isExternal",
    //   cellDataType: "text",
    //   valueFormatter: (params) => {
    //     return params?.value ? "External" : "Internal";
    //   },
    // },
    // {
    //   headerName: "Scheduling Confirmed At",
    //   field: "createdAt",
    //   cellDataType: "date",
    // },
    // {
    //   headerName: "Scheduling Confirmed At (Time)",
    //   field: "createdAt",
    //   cellDataType: "time",
    // },
    // {
    //   headerName: "Scheduled By",
    //   field: "createdBy.name",
    //   cellDataType: "text",
    // },
    // {
    //   headerName: "Scheduler User Type ",
    //   field: "createdBy.isExternal",
    //   cellDataType: "text",
    //   valueFormatter: (params) => {
    //     return params?.value ? "External" : "Internal";
    //   },
    // },
    // {
    //   headerName: "Date of Visit",
    //   valueGetter: (params) => {
    //     return _.min(params.data.clinicPSLs.map((bp) => bp.scheduledTime));
    //   },
    //   cellDataType: "date",
    // },
    // {
    //   headerName: "Date of Visit (Time)",
    //   valueGetter: (params) => {
    //     return _.min(params.data.clinicPSLs.map((bp) => bp.scheduledTime));
    //   },
    //   cellDataType: "time",
    // },

    // {
    //   headerName: "Provider(s)",
    //   valueGetter: (params) => {
    //     return _.join(
    //       _.uniq(
    //         params.data.clinicPSLs.map((bp) => bp.providerObj.providerName)
    //       ),
    //       ","
    //     );
    //   },
    //   cellDataType: "text",
    // },

    // {
    //   headerName: "Request to Schedule (Cal. Days)",
    //   valueGetter: (params) => {
    //     let visitRequestedAt = new Date(params.data.visit.createdAt);
    //     let schedulingConfirmedAt = new Date(params.data.createdAt);
    //     let diff = Math.abs(
    //       visitRequestedAt.getTime() - schedulingConfirmedAt.getTime()
    //     );
    //     return Math.round(diff / (1000 * 60 * 60 * 24));
    //   },
    //   cellDataType: "number",
    // },
    // {
    //   headerName: "Schedule to Visit (Cal. Days)",
    //   valueGetter: (params) => {
    //     let visitRequestedAt = new Date(params.data.scheduledTime);
    //     let schedulingConfirmedAt = new Date(params.data.createdAt);
    //     let diff = Math.abs(
    //       visitRequestedAt.getTime() - schedulingConfirmedAt.getTime()
    //     );
    //     return Math.round(diff / (1000 * 60 * 60 * 24));
    //   },
    //   cellDataType: "number",
    // },
  ];

  onGridReady = (params) => {
    this.setState({ gridApi: params.api, columnApi: params.columnApi });
  };
  /** RENDER GRID */
  renderGrid = () => {
    let cs = "ag-theme-quartz";
    let theme = localStorage.getItem("themeMode");
    if (theme && theme === "dark") {
      cs = "ag-theme-quartz-dark";
    }
    return (
      <div className={cs} style={{ height: "70vh" }}>
        <AgGridReact
          onGridReady={this.onGridReady}
          rowData={this.state.dataFilter}
          columnDefs={this.COLUMNS.map((x) => {
            return { ...FILTER_UTILS[x.cellDataType], ...x };
          })}
          defaultColDef={{
            sortable: true,
          }}
          autoSizeStrategy={{
            type: "fitCellContents",
          }}
          pagination={true}
          rowGroupPanelShow={"always"}
          aggFuncs={this.state.aggregations}
          suppressDragLeaveHidesColumns={true}
          rowSelection="multiple"
          rowDragManaged={true}
          enableCharts={true}
          enableRangeSelection={true}
          enableCellTextSelection={true}
          sideBar={true}
          animateRows={true}
          statusBar={{
            statusPanels: [
              {
                statusPanel: "agTotalAndFilteredRowCountComponent",
                align: "left",
              },
              {
                statusPanel: "agSelectedRowCountComponent",
                align: "left",
              },
              {
                statusPanel: "agAggregationComponent",
                align: "right",
              },
            ],
          }}
          defaultExcelExportParams={{
            author: "WorkCare",
            fileName: `${this.props.reportType} - ${new Date().toLocaleDateString()}`,
            sheetName: `${this.props.reportType} - ${new Date().toLocaleDateString()}`,
          }}
        />
      </div>
    );
  };
  /******** GRID ENDS */
  /*********** CHART BEGINS */
  classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };
  dataMassager = () => {
    let data = this.props.data.map((i) => ({
      name: i.name,
      PARTIAL_DOCUMENTS_RECEIVED:
        i.workloadByStatus.PARTIAL_DOCUMENTS_RECEIVED || 0,
      REVIEW_NOT_STARTED: i.workloadByStatus.REVIEW_NOT_STARTED || 0,
      IN_RN_REVIEW: i.workloadByStatus.IN_RN_REVIEW || 0,
      IN_MD_REVIEW: i.workloadByStatus.IN_MD_REVIEW || 0,
      PENDING_INFORMATION_FROM_EMPLOYEE:
        i.workloadByStatus.PENDING_INFORMATION_FROM_EMPLOYEE || 0,
      PENDING_AFFIDAVIT: i.workloadByStatus.PENDING_AFFIDAVIT || 0,
    }));

    return {
      data,
    };
  };
  renderChart = () => {
    let { data } = this.dataMassager();
    return (
      <Card className="w-full max-w-full">
        <Stack direction="row" spacing={4} className="justify-between">
          <h3 className="font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
            Assigned Cases
          </h3>
          <h3 className="font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
            {this.renderUserSelectionFilter()}
          </h3>
        </Stack>
        <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
          Cases Assigned to each Reviewer
        </p>
        <BarChart
          data={data}
          index="name"
          categories={Object.keys(data[0]).filter((i) => i !== "name")}
          showLegend={false}
          yAxisWidth={45}
          stack={true}
          className="mt-6 hidden h-96 sm:block"
          customTooltip={(payload) => {
            if (!payload.active) {
              return "";
            }
            let data = payload.payload;
            let sum = _.sumBy(data, "value");
            return (
              <div
                className="mt-8 flex justify-center"
                style={{
                  zIndex: "1001 !important",
                }}
              >
                <div className="w-52 rounded-tremor-default border border-tremor-border bg-tremor-background text-tremor-default shadow-tremor-input dark:border-dark-tremor-border dark:bg-dark-tremor-background dark:shadow-dark-tremor-input">
                  <div className="rounded-t-tremor-default border-b border-tremor-border bg-tremor-background-muted px-2.5 py-2 dark:border-dark-tremor-border dark:bg-dark-tremor-background">
                    <p className="font-medium text-tremor-content dark:text-dark-tremor-content">
                      {payload.label}: {sum}
                    </p>
                  </div>
                  {data.map((each) => {
                    if (!each.value) {
                      return null;
                    }
                    const classNameStr = each.className;
                    const className = classNameStr.replace("fill-", "bg-");
                    return (
                      <div className="flex w-full items-center justify-between space-x-4 px-2.5 py-2">
                        <div className="flex items-center space-x-2 truncate">
                          <span
                            className={`h-2.5 w-2.5 shrink-0 rounded-full ${className}`}
                            aria-hidden={true}
                          />
                          <p className="truncate text-tremor-content dark:text-dark-tremor-content">
                            {each.dataKey}
                          </p>
                        </div>
                        <p className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                          {each.value}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          }}
        />{" "}
      </Card>
    );
  };
  /********** CHART ENDS */
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <div>
        {this.renderChart()}
        {this.renderGrid()}
      </div>
    );
  }
}
export default ReviewerWorkloadReport;
