import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { WithAPICall } from "../utils/apiUtil";
import cogoToast from "cogo-toast";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import { Stack } from "@mui/system";
import _ from "lodash";

class CSFSubFieldCreate extends React.Component {
  state = {
    fieldDataFieldName: "",
    showInSearch: true,
    typeOfSearch: "EXACT",
    dataType: "string",
    listOfOptions: "",
    isLookUpValue: false,
    isCreating: false,
  };
  createFromName = async () => {
    if (this.state.dataType === "array" && !this.state.listOfOptions) {
      cogoToast.error("Please enter options for this field");
      return;
    }
    if (!this.state.dataType) {
      cogoToast.error("Please select a data type for this field");
      return;
    }
    if (!this.state.fieldDataFieldName) {
      cogoToast.error("Please enter a name for this field");
      return;
    }
    this.setState({ isCreating: true });
    try {
      cogoToast.loading("Creating Subfield");
      const res = await this.props.apiCallPost("/company/csf/subfield/add", {
        csfId: this.props.csfId,
        fieldDataFieldName: this.state.fieldDataFieldName,
        showInSearch: this.state.showInSearch,
        typeOfSearch: this.state.typeOfSearch,
        dataType: this.state.dataType,
        listOfOptions: this.state.listOfOptions,
        companyId: this.props.companyId,
      });
      this.props.onCreate(res);
      cogoToast.success("Subfield Created");
    } catch (err) {
      cogoToast.error("Error Creating Subfield");
      console.log(err);
    }
    this.setState({ isCreating: false });
  };
  DATA_TYPE_OPTIONS = [
    {
      value: "string",
      label: "Text",
    },
    { value: "number", label: "Number" },
    { value: "date", label: "Date" },
    { value: "boolean", label: "Yes/No" },
    { value: "array", label: "Select From Options" },
  ];
  renderIfArray = () => {
    if (this.state.dataType !== "array") return null;
    return (
      <Stack spacing={2}>
        <Alert severity="error">
          Enter the possible options for this field, separated by commas.
        </Alert>
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          name="name"
          label="Options"
          fullWidth
          disabled={this.state.isCreating}
          value={this.state.listOfOptions}
          onChange={(e) => {
            this.setState({ listOfOptions: e.target.value });
          }}
        />
      </Stack>
    );
  };
  render() {
    return (
      <Dialog open={this.props.isOpen} onClose={() => this.props.handleClose()}>
        <DialogTitle>
          Create A New Subfield Under {this.props.csfName}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity="warning">
              Careful while adding subfields - these can impact operations
              directly.
            </Alert>{" "}
            <Alert severity="error">
              You will not be able to edit the subfield name & data type after
              creation.
            </Alert>
            <br />
            <Alert severity="info">
              <AlertTitle>
                Adding subfield under: <b>{this.props.csfName}</b>
              </AlertTitle>
              {this.props.csfDescription}
            </Alert>
            <br />
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Subfield Name"
            fullWidth
            disabled={this.state.isCreating}
            value={this.state.fieldDataFieldName}
            onChange={(e) => {
              this.setState({ fieldDataFieldName: e.target.value });
            }}
          />
          <FormControlLabel
            label="Show this field in search & controls"
            labelPlacement="start"
            control={
              <Switch
                checked={this.state.showInSearch}
                onChange={() => {
                  this.setState({ showInSearch: !this.state.showInSearch });
                }}
              />
            }
            sx={{
              m: 0,
              width: 1,
              justifyContent: "space-between",
            }}
          />
          <FormControlLabel
            label="Search Behaviour"
            labelPlacement="start"
            control={
              <Select
                disabled={!this.state.showInSearch}
                value={this.state.typeOfSearch}
                onChange={(e) => {
                  this.setState({ typeOfSearch: e.target.value });
                }}
              >
                <MenuItem value={"EXACT"}>
                  Search should match items exactly
                </MenuItem>
                <MenuItem value={"TEXT_SEARCH"}>
                  Allow Full Text Search
                </MenuItem>
              </Select>
            }
            sx={{
              m: 0,
              width: 1,
              justifyContent: "space-between",
            }}
          />
          <FormControlLabel
            label="Data Type"
            labelPlacement="start"
            control={
              <Select
                value={this.state.dataType}
                onChange={(e) => {
                  let x = e.target.value;
                  this.setState({
                    dataType: x,
                    options: x === "array" ? this.state.listOfOptions : "",
                  });
                }}
              >
                {this.DATA_TYPE_OPTIONS.map((each, ix) => (
                  <MenuItem value={each.value} key={ix}>
                    {each.label}
                  </MenuItem>
                ))}
              </Select>
            }
            sx={{
              m: 0,
              width: 1,
              justifyContent: "space-between",
            }}
          />
          {this.renderIfArray()}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.handleClose()}
            disabled={this.state.isCreating}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={this.state.isCreating}
            variant="contained"
            disabled={!this.state.fieldDataFieldName}
            onClick={() => this.createFromName()}
          >
            Create Subfield
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}
export default WithAPICall(CSFSubFieldCreate);
