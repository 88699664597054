export const SERVICE_DATA_TYPE_OPTIONS = [
  "NOT_RELEVANT",
  "CHAIN_OF_CUSTODY",
  "VACCINATION", // done
  "MEDICAL_HISTORY_QUESTIONNAIRE", // done
  "LAB_REPORT", // done
  "MEDICAL_EXAM",
  "AUDIOGRAM", // done
  "PFT", // done
  "CXR",
  "ECG",
  "BAT", // done
  // "DOT_FORM", // done
  // "DOT_CARD", // done
  "RESPIRATOR_QUESTIONNAIRE", // done
  "Alcohol Test",
  "ASR Form",
  "Kraus Weber Back Assessment",
  "BBP Exposure Screening",
  "Beryllium Results",
  "Cardiac Stress Test",
  "CCF - NonDOT - Urine",
  "CCF - DOT - Urine",
  "CCF - NonDOT - Hair",
  "CCF - DOT - Oral Fluids",
  "CCF - NonDOT - Oral Fluids",
  "Coast Guard Exam (CG-719k)",
  "Dental Exam",
  "DOT Exam",
  "DOT Card",
  "Drug Screen Instant Limits",
  "Drug Screen Lab Results",
  "Epworth Sleepiness Scale",
  "Fit for Duty",
  "Functional Capacity Evaluation",
  "Height and Weight",
  "Hemoccult",
  "Immunization Summary/History",
  "Vaccine Declination Form",
  "Musculoskeletal Assessment",
  "OSHA Respirator Questionnaire",
  "Norwegian Seafarer Certification",
  "OGUK Exam Certification",
  "Post-Accident Questionnaire",
  "PEPCAS",
  "Respirator Fit Test",
  "TB Questionnaire",
  "Silica Medical Release",
  "TB Blood Result",
  "TB Mantoux",
  "Vision",
  "OTHER_MEDICAL",
];

export const TYPE_OF_DOCUMENT_OPTIONS = [
  "CHAIN_OF_CUSTODY",
  "MEDICAL_HISTORY_QUESTIONNAIRE", // done
  "VISION",
  "VITALS",
  "LAB_REPORT", // done
  "MEDICAL_EXAM",
  "AUDIOGRAM", // done
  "PFT", // done
  "CXR",
  "ECG",
  "BAT", // done
  // "DOT_FORM",
  "DOT_CARD",
  "RESPIRATOR_QUESTIONNAIRE", // done
  "MEDICAL_HISTORY_QUESTIONNAIRE",
  "VACCINATION", // done
  "OTHER_MEDICAL",
  "NOT_RELEVANT",
];
