import { create } from "zustand";

const useDocumentStore = create((set) => {
  return {
    document: null,
    error: null,
    status: "idle",

    setEmployeesData: (employees) => {
      set((state) => {
        return {
          document: {
            ...state.document,
            extractedEEInfo: [...employees],
          },
        };
      });
    },

    loadDocument: async (document) => {
      set({ status: "loading" });
      try {
        set({ document, status: "loaded" });
      } catch (error) {
        set({ error: error.message, status: "failed" });
      }
    },
  };
});

export default useDocumentStore;
