import React from "react";
import { cn } from "@/lib/utils";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent } from "@/components/ui/card";

export function RedFlagSummary({ text }) {
  if (!text) {
    return (
      <p className="text-sm text-muted-foreground">No summary generated yet.</p>
    );
  }

  const lines = text.trim().split("\n");

  return (
    <Card className="mb-4">
      <CardContent className="p-4">
        <p className="text-sm">
          <b>Data Summary</b>
        </p>
        {lines.map((line, index) => {
          const trimmedLine = line.trim();

          if (trimmedLine.startsWith("Red Flags:")) {
            const rest = trimmedLine.replace("Red Flags:", "").trim();
            return (
              <div key={index} className="">
                <Badge variant="destructive">Red Flags</Badge>
                <p className="text-sm">{rest}</p>
              </div>
            );
          }

          if (trimmedLine.startsWith("Headline:")) {
            const rest = trimmedLine.replace("Headline:", "").trim();
            return (
              <p key={index} className="text-sm font-medium">
                {rest}
              </p>
            );
          }

          if (trimmedLine.startsWith("Summary:")) {
            const rest = trimmedLine.replace("Summary:", "").trim();
            return (
              <div key={index} className="">
                <Badge variant="secondary">Summary</Badge>
                <p className="text-sm">{rest}</p>
              </div>
            );
          }

          return (
            <p key={index} className="text-sm">
              {trimmedLine}
            </p>
          );
        })}
      </CardContent>
    </Card>
  );
}
