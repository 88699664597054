import {
  Button,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import cogoToast from "cogo-toast";
import _, { debounce } from "lodash";
import moment from "moment";
import React from "react";
import TokenContext from "src/TokenContext";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import Iconify from "src/components/iconify";
import { WithAPICall } from "../utils/apiUtil";
import { niceDateTime } from "../utils/fn";

class VisitListEE extends React.Component {
  static contextType = TokenContext;
  state = {
    isLoading: true,
    // cu
    currentUser: {
      isEmployee: true,
      isExternal: true,
    },
    // company
    isSearchingCompany: false,
    companySearchResults: [],
    currentlyInCompany: null,
    // other filters
    filterStatus: [],
    filterEmployee: "",
    filterDateRange: [null, null],
    filterMasterServiceIds: [],
    filterProtocolIds: [],
    // search results
    isSearchingVisits: false,
    hasSearched: false,
    visits: [],
    options: [],
    loadingOptions: false,
    protocolOptions: [],
    loadingCompanyProtocols: false,
  };
  componentDidMount() {
    this.getCurrentUser();
    this.debouncedFetchOptions = debounce(this.fetchOptions, 500);
  }
  fetchOptions = async (inputValue) => {
    this.setState({
      loadingOptions: true,
    });
    if (inputValue) {
      try {
        const res = await this.props.apiCallPost(
          "/master-service/masterServiceFts",
          {
            limit: 1000,
            query: inputValue,
            skip: 0,
          }
        );
        this.setState({ options: res });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      this.setState({ options: [] });
    }
    this.setState({
      loadingOptions: true,
    });
  };
  getCurrentUser = async () => {
    try {
      const res = await this.props.apiCallPost("/user/getCurrentUser", {});
      let cu = res;
      let isEmployee = cu.isEmployee;
      let isExternal = cu.isExternal;
      this.setState({
        currentUser: cu,
      });
      if (isEmployee) {
        this.setState(
          {
            currentUser: cu,
            currentlyInCompany: {
              mainText: res.company.companyName,
              secondaryText: `${res.company.companyInformalName} ${res.companyWebsite}`,
              data: res.company,
            },
          },
          () => {
            this.getVisitsByEmployeeId();
          }
        );
      }
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading User Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  STATUS_OPTIONS = [
    "REQUESTED",
    "SCHEDULING_IN_PROGRESS",
    "SCHEDULED",
    "AWAITING_DOCUMENTS",
    "DOCUMENTS_RECEIVED",
    "DOCUMENTS_PROCESSED",
    "DOCUMENTS_IN_REVIEW",
    "DOCUMENTS_REVIEWED",
    "COMPLETED",
    "CANCELLED",
    "NO_SHOW",
    "RESCHEDULING_REQUESTED",
  ];
  searchCompaniesFTS = async (t) => {
    try {
      let res = await this.props.apiCallPostCancellable("/company/search", {
        query: t,
      });
      if (res) {
        this.setState({
          isSearchingCompany: false,
          companySearchResults: res.map((each) => {
            return {
              mainText: each.companyName,
              secondaryText: `${each.companyInformalName} ${each.companyWebsite}`,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingCompany: false,
        companySearchResults: [],
      });
      cogoToast.error("Error Searching Companies");
    }
  };
  searchVisits = async () => {
    let payload = {
      visitOutcome: [],
      visitStatus: this.state.filterStatus,
      companyId: this.state.currentlyInCompany?.data?._id || null,
      employeeName: this.state.filterEmployee || null,
      masterServiceIds: this.state.filterMasterServiceIds.map((s) => s._id),
      protocolIds: this.state.filterProtocolIds.map((s) => s?._id),
    };
    // if date range has both filled, convert to date from moment
    if (this.state.filterDateRange.filter(Boolean).length === 2) {
      payload.scheduledIn = this.state.filterDateRange.map((each) =>
        each.toDate()
      );
    } else {
      payload.scheduledIn = [];
    }

    try {
      this.setState({
        isSearchingVisits: true,
      });
      let res = await this.props.apiCallPost("/visit/searchVisits", payload);
      this.setState({
        isSearchingVisits: false,
        hasSearched: true,
        visits: res,
      });
    } catch (err) {
      this.setState({
        isSearchingVisits: false,
      });
      cogoToast.error("Error Searching Visits");
    }
  };
  getVisitsByEmployeeId = async () => {
    try {
      this.setState({
        isSearchingVisits: true,
      });
      let res = await this.props.apiCallPost(
        "/ext-employee/getVisitsByEmployeeId",
        {
          employeeId: this.state.currentUser.employee,
        }
      );
      if (window.location.href.includes("upcoming")) {
        res = res.filter((each) => {
          return ["SCHEDULED", "REQUESTED"].includes(each.visitStatus);
        });
      }
      if (window.location.href.includes("completed")) {
        res = res.filter((each) => {
          return each.visitStatus === "COMPLETED";
        });
      }
      if (window.location.href.includes("in-process")) {
        res = res.filter((each) => {
          return [
            "AWAITING_DOCUMENTS",
            "DOCUMENTS_RECEIVED",
            "DOCUMENTS_PROCESSED",
            "DOCUMENTS_IN_REVIEW",
            "DOCUMENTS_REVIEWED",
          ].includes(each.visitStatus);
        });
      }
      this.setState({
        isSearchingVisits: false,
        hasSearched: true,
        visits: res,
        isLoading: false,
      });
    } catch (err) {
      this.setState({
        isSearchingVisits: false,
      });
      cogoToast.error("Error Loading Visits");
    }
  };
  loadCompanyProtocols = async () => {
    cogoToast.loading("Loading company protocols.");
    try {
      const res = await this.props.apiCallPost(
        "/company-protocol/companyProtocolGetAll",
        {
          companyId: this.state.currentlyInCompany.data._id,
        }
      );
      this.setState({
        protocolOptions: res,
      });
      cogoToast.success("Company protocols loaded.");
    } catch (error) {
      console.error("could not get company protocols", error);
    }
  };

  renderTable = () => {
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Protocol(s)</TableCell>
            <TableCell>Clinic(s)</TableCell>
            <TableCell>Lab(s)</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Outcome</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.visits.map((each) => {
            return (
              <TableRow
                key={each._id}
                sx={{
                  borderBottom: "1px solid #C5CBD3 !important",
                }}
              >
                <TableCell>{this.renderProtocols(each)}</TableCell>{" "}
                <TableCell>{this.renderClinics(each)}</TableCell>{" "}
                <TableCell>{this.renderLabs(each)}</TableCell>
                <TableCell>
                  {this.renderNiceText(each, "visitStatus")}
                  {/* {!each.eeActionsDone && (
                    <Button
                      variant="contained"
                      sx={{
                        background: "#e9d502 !important",
                      }}
                      size="small"
                      href={`/visits/${each._id}`}
                      startIcon={<Iconify icon="icon-park-solid:attention" />}
                    >
                      Action Required
                    </Button>
                  )} */}
                </TableCell>
                <TableCell>
                  {/* {this.renderNiceText(each, "visitOutcome")} */}
                </TableCell>
                <TableCell>
                  {!each.eeActionsDone && each.visitStatus === "REQUESTED" && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      href={`/visits/${each._id}`}
                      startIcon={<Iconify icon="carbon:add-filled" />}
                    >
                      Enter Scheduling Preferences
                    </Button>
                  )}
                  {[
                    "SCHEDULED",
                    "AWAITING_DOCUMENTS",
                    "DOCUMENTS_RECEIVED",
                    "DOCUMENTS_PROCESSED",
                    "DOCUMENTS_IN_REVIEW",
                    "DOCUMENTS_REVIEWED",
                  ].includes(each.visitStatus) && (
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      href={`/visits/${each._id}`}
                      startIcon={<Iconify icon="mdi:eye" />}
                    >
                      Visit Details
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };
  renderNiceText = (each, key) => {
    if (!each[key]) return "N/A";
    return _.startCase(_.lowerCase(each[key]));
  };
  renderEmployee = (each) => {
    let elem = (
      <IconButton
        disabled={this.state.isSearchingVisits}
        size="small"
        color="primary"
        href={`/visits/${each._id}`}
        // target="non_blank"
        sx={{
          width: 24,
          height: 24,
          bgcolor: "primary.main",
          color: "primary.contrastText",
          "&:hover": {
            bgcolor: "primary.dark",
          },
        }}
      >
        <Iconify icon="mynaui:external-link" />
      </IconButton>
    );
    if (this.props.choosingVisit) {
      elem = (
        <IconButton
          disabled={this.state.isSearchingVisits}
          size="small"
          color="primary"
          onClick={() => {
            this.props.onVisitChosen(each);
          }}
          sx={{
            width: 24,
            height: 24,
            bgcolor: "primary.main",
            color: "primary.contrastText",
            "&:hover": {
              bgcolor: "primary.dark",
            },
          }}
        >
          <Iconify icon="teenyicons:tick-circle-solid" />
        </IconButton>
      );
    }
    return (
      <Stack direction="row" spacing={1} alignItems={"center"}>
        {" "}
        <Typography variant="overline">{each.employee.employeeName}</Typography>
        {elem}
      </Stack>
    );
  };
  renderProtocols = (each) => {
    if (!each.bookedProtocols || !each.bookedProtocols.length) {
      return "Not Chosen";
    }
    if (this.props.fromAssociationPage) {
      return (
        <Stack spacing={1}>
          {each.bookedProtocols.map((x) => {
            let bookedServices = each.bookedServices;
            let bookedServicesInThisProtocol = bookedServices.filter(
              (y) => y.parentProtocolId === x._id
            );
            let bookedServicesInThisProtocolIds =
              bookedServicesInThisProtocol.map((z) => z.serviceId);

            let serviceMetadataInThisProtocol = x.serviceMetadata;
            let masterServideIdsInThisProtocol = x.masterServices;
            let servicesToRender = [];

            for (var i = 0; i < bookedServicesInThisProtocol.length; i++) {
              let bookedServicesInThisProtocolIdx =
                bookedServicesInThisProtocolIds[i];
              let currentIdx = _.findIndex(
                masterServideIdsInThisProtocol,
                (w) => w === bookedServicesInThisProtocolIdx
              );
              if (currentIdx === -1) continue;
              servicesToRender.push({
                ...serviceMetadataInThisProtocol[currentIdx],
                serviceId: bookedServicesInThisProtocolIdx,
                hasReceivedAllData:
                  bookedServicesInThisProtocol[i].hasReceivedAllData,
                linkedGroups: bookedServicesInThisProtocol[i].linkedGroups
                  ? bookedServicesInThisProtocol[i].linkedGroups
                  : [],
              });
            }
            let servicesHeader = (
              <Typography variant="overline">Services</Typography>
            );

            if (!servicesToRender || !servicesToRender.length) {
              servicesHeader = null;
            }
            return (
              <Stack spacing={1}>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Typography variant="overline">
                    {x.companyProtocolName}
                  </Typography>
                  <Typography variant="caption">
                    {x.companyProtocolType}
                  </Typography>
                </Stack>
                <Stack spacing={2}>
                  {servicesHeader}
                  {servicesToRender.map((s) => {
                    let lg = s.linkedGroups;
                    let groupsElem = null;
                    if (lg && lg.length) {
                      groupsElem = (
                        <Stack>
                          <Typography variant="caption">Linked Data</Typography>
                          {lg.map((eachLg) => {
                            let eachLgButton = (
                              <IconButton
                                size="small"
                                color="primary"
                                href={`/documents/${eachLg.cfId}`}
                                // target="non_blank"
                                sx={{
                                  width: 24,
                                  height: 24,
                                  // bgcolor: "primary.error",
                                  // color: "primary.contrastText",
                                  // "&:hover": {
                                  //   bgcolor: "primary.dark",
                                  // },
                                }}
                              >
                                <Iconify icon="akar-icons:link-out" />
                              </IconButton>
                            );
                            return (
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                              >
                                {_.startCase(_.lowerCase(eachLg.pageDataType))}
                                {eachLgButton}
                              </Stack>
                            );
                          })}
                        </Stack>
                      );
                    }
                    let addButton = (
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => {
                          this.props.onAssociation(
                            s.serviceId,
                            each._id,
                            false
                          );
                        }}
                        sx={{
                          width: 24,
                          height: 24,
                          // bgcolor: "primary.error",
                          // color: "primary.contrastText",
                          // "&:hover": {
                          //   bgcolor: "primary.dark",
                          // },
                        }}
                      >
                        <Iconify icon="akar-icons:folder-add" />
                      </IconButton>
                    );
                    let doneButton = (
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => {
                          this.props.onAssociation(s.serviceId, each._id, true);
                        }}
                        sx={{
                          width: 24,
                          height: 24,
                          // bgcolor: "primary.error",
                          // color: "primary.contrastText",
                          // "&:hover": {
                          //   bgcolor: "primary.dark",
                          // },
                        }}
                      >
                        <Iconify icon="akar-icons:circle-check-fill" />
                      </IconButton>
                    );
                    return (
                      <Stack spacing={1}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          {s.serviceName}
                          {addButton}
                          {doneButton}
                        </Stack>
                        {groupsElem}
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      );
    }
    return (
      <Stack spacing={1}>
        {each.bookedProtocols.map((x) => {
          return (
            <Stack spacing={0}>
              <Typography variant="overline">
                {x.companyProtocolName}
              </Typography>
              <Typography variant="caption">{x.companyProtocolType}</Typography>
            </Stack>
          );
        })}
      </Stack>
    );
  };
  renderLabs = (each) => {
    if (!each.labFrozen) {
      return "Not Chosen";
    }
    if (!each.labPSLs) {
      return "N/A";
    }
    if (!each.labPSLs.length) {
      return "N/A";
    }
    return (
      <Stack spacing={1}>
        {each.labPSLs.map((x) => {
          return (
            <Stack spacing={0}>
              <Typography variant="overline">{x.providerName}</Typography>
              <Typography variant="caption">{x.pslName}</Typography>{" "}
              <Typography variant="caption">
                Collected At: {x.collectingClinicName || "NA"}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    );
  };
  renderClinics = (each) => {
    if (!each.clinicFrozen) {
      return "Not Chosen";
    }
    if (!each.clinicPSLs) {
      return "N/A";
    }
    if (!each.clinicPSLs.length) {
      return "N/A";
    }
    const groupedClinics = _.groupBy(each.clinicPSLs, "providerName");
    return (
      <Stack spacing={1}>
        {Object.keys(groupedClinics).map((x, index) => {
          const y = groupedClinics[x][0];
          let dt = "NA";
          if (!y.scheduledOnDate) {
            dt = "NA";
          } else {
            dt = new Date(y.scheduledOnDate);
            let tm = new Date(y.scheduledTime);
            dt.setHours(tm.getHours());
            dt.setMinutes(tm.getMinutes());
            dt = niceDateTime(dt);
          }
          return (
            <Stack spacing={1} key={index}>
              <Typography variant="overline">{x}</Typography>
              {groupedClinics[x].map((y, yIdx) => {
                return (
                  <Stack spacing={0} key={yIdx}>
                    <Typography variant="caption">{y.pslName}</Typography>{" "}
                  </Stack>
                );
              })}
              <Typography variant="caption">
                Scheduled At: {dt || "NA"}{" "}
                {y.providerObj.providerReadableTimezone || ""}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    );
  };
  renderHeader = () => {
    if (this.state.currentUser.isEmployee) {
      return (
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={2}
          sx={{
            width: "100%",
          }}
        >
          <Typography variant="h6">Your Visits</Typography>

          <Button
            size="small"
            variant="contained"
            startIcon={<Iconify icon="wpf:past" />}
            onClick={() => this.getVisitsByEmployeeId()}
            sx={{
              marginLeft: "auto",
            }}
          >
            Completed Visits
          </Button>
          <Button
            size="small"
            variant="contained"
            startIcon={<Iconify icon="material-symbols:event-upcoming" />}
            onClick={() => this.getVisitsByEmployeeId()}
          >
            Upcoming Visits
          </Button>
        </Stack>
      );
    }
    if (this.context.currentUser.isExternal) {
      return (
        <Stack
          spacing={1}
          direction={"row"}
          alignItems="end"
          justifyContent="end"
        >
          <Button
            size="small"
            disabled={this.state.isSearchingVisits}
            href="/visits/new"
            variant="contained"
            startIcon={<Iconify icon="mingcute:add-line" />}
          >
            Create a New Visit
          </Button>
          <Button
            size="small"
            disabled={this.state.isSearchingVisits}
            href="/visits/request"
            variant="contained"
            startIcon={<Iconify icon="ion:person-add" />}
          >
            Request a New Visit
          </Button>
        </Stack>
      );
    }
    return (
      <CustomBreadcrumbs
        heading="List of Visits"
        links={[{ name: "Visits Dashboard" }]}
        action={
          <Stack spacing={1} direction={"row"}>
            <Button
              size="small"
              disabled={this.state.isSearchingVisits}
              href="/visits/new"
              // target="non_blank"
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              New Visit
            </Button>{" "}
            <Button
              size="small"
              disabled={this.state.isSearchingVisits}
              onClick={() => {
                let momentToday = new Date();
                let m = moment(momentToday);
                this.setState(
                  {
                    filterDateRange: [m, m],
                  },
                  () => {
                    this.searchVisits();
                  }
                );
              }}
              variant="contained"
            >
              Today's Visits
            </Button>{" "}
            <Button
              size="small"
              disabled={this.state.isSearchingVisits}
              onClick={() => {
                this.setState(
                  {
                    filterStatus: ["AWAITING_DOCUMENTS"],
                  },
                  () => {
                    this.searchVisits();
                  }
                );
              }}
              variant="contained"
            >
              Pending Documents
            </Button>
          </Stack>
        }
      />
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <Stack spacing={2}>
        {this.renderHeader()}
        {this.state.visits.length > 0 ? (
          this.renderTable()
        ) : (
          <Stack>
            <Typography variant="body1">No Visits Found</Typography>
          </Stack>
        )}
      </Stack>
    );
  }
}

export default WithAPICall(VisitListEE);
