import React, { useState, useEffect } from "react";
import {
  Typography,
  Select,
  MenuItem,
  TextField,
  Box,
  Container,
  FormControl,
  InputLabel,
} from "@mui/material";
import { set } from "lodash";

const DynamicTextWithSelects = ({
  initialText,
  initialSelections,
  onTextChange,
  disabled,
}) => {
  const [parsedContent, setParsedContent] = useState([]);
  const [selections, setSelections] = useState({});
  const [finalText, setFinalText] = useState("");
  const [suppressUpdate, setSuppressUpdate] = useState(true);

  useEffect(() => {
    const parsed = parseContent(initialText);
    setParsedContent(parsed);

    // Initialize selections with first option of each select
    const initialSelections = {};
    parsed.forEach((item) => {
      if (item.type === "select" && item.options.length > 0) {
        initialSelections[item.name] = item.options[0].text;
      }
    });
    setSelections(initialSelections);
  }, [initialText]);

  useEffect(() => {
    updateFinalText();
  }, [selections, parsedContent]);

  const parseContent = (text) => {
    const result = [];
    const selectRegex = /<select[^>]*>[\s\S]*?<\/select>/g;
    let lastIndex = 0;
    let match;

    while ((match = selectRegex.exec(text)) !== null) {
      // Add text before the select
      if (match.index > lastIndex) {
        result.push({
          type: "text",
          content: text.slice(lastIndex, match.index).trim(),
        });
      }

      // Parse the select
      const selectContent = match[0];
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = selectContent;
      const select = tempDiv.querySelector("select");
      const name = select.getAttribute("name");
      const options = Array.from(select.querySelectorAll("option")).map(
        (option) => ({
          value: option.value,
          text: option.textContent,
        })
      );

      result.push({ type: "select", name, options });

      lastIndex = selectRegex.lastIndex;
    }

    // Add any remaining text
    if (lastIndex < text.length) {
      result.push({
        type: "text",
        content: text.slice(lastIndex).trim(),
      });
    }

    return result;
  };

  const updateFinalText = () => {
    let text = "";
    parsedContent.forEach((item) => {
      if (item.type === "text") {
        text += item.content + "\n";
      } else if (item.type === "select") {
        text += selections[item.name] + "\n";
      }
    });
    text = text.trim();
    setFinalText(text.trim());
    console.log("finalText", finalText);
    if (text && suppressUpdate === false) {
      onTextChange(text, selections);
    }
  };

  const handleSelectChange = (name, value) => {
    setSelections((prev) => ({ ...prev, [name]: value }));
    setSuppressUpdate(false);
  };

  const numSelects = parsedContent.filter(
    (item) => item.type === "select"
  ).length;

  if (numSelects === 0) {
  }

  return (
    <Container maxWidth="md">
      <Box my={4}>
        {numSelects > 0 && (
          <Typography variant="caption">
            Select from the {numSelects} option(s) below:
          </Typography>
        )}
        {parsedContent.map((item, index) =>
          item.type === "text" ? null : (
            <FormControl key={index} margin="normal" size="small" fullWidth>
              <InputLabel id={`select-label-${item.name}`}>
                {item.name}
              </InputLabel>
              <Select
                labelId={`select-label-${item.name}`}
                value={selections[item.name]}
                onChange={(e) => handleSelectChange(item.name, e.target.value)}
                label={item.name}
                disabled={disabled}
                autoWidth
              >
                {item.options.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.text}
                    style={{
                      whiteSpace: "unset",
                      wordBreak: "break-all",
                    }}
                  >
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        )}
      </Box>
    </Container>
  );
};

export default DynamicTextWithSelects;
