import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { TextField } from "@mui/material";
import { UploadAvatar } from "src/components/upload";
import CountrySelector from "../utils/CountrySelector";
import { WithAPICall } from "../utils/apiUtil";
import GMap from "../utils/GMap";
import UploadSnack from "../utils/UploadSnack";
import cogoToast from "cogo-toast";

class CompanyProfile extends React.Component {
  state = {};
  componentDidMount() {
    this.setState(
      {
        ...this.props.data,
      },
      () => {
        if (this.state.companyLogo) {
          this.getFile(this.state.companyLogo);
        }
      }
    );
  }
  updateScalarValue = async (key, value) => {
    if (value === null || value === undefined) {
      return;
    }
    if (this.props.data[key] === value) {
      return;
    }
    try {
      await this.props.apiCallPost("/company/updateScalarValue", {
        companyId: this.props.id,
        key: key,
        value: value,
      });
    } catch (err) {
      cogoToast.error("Error Updating Employer Data");
      console.log(err);
    }
  };
  setLogo = async (file) => {
    try {
      const fd = new FormData();
      fd.append("file", file[0]);
      fd.append("companyId", this.props.id);
      fd.append("purpose", "companyLogo");
      const res = await this.props.apiCallPostUpload(
        "/company/setLogo",
        fd,
        (e) => {
          this.setState({
            uploadProgress: e,
          });
        }
      );
      this.setState({
        uploadProgress: null,
        companyLogoUrl: res,
      });
    } catch (err) {
      cogoToast.error("Error Uploading Logo");
      console.log(err);
    }
  };
  getFile = async (id) => {
    try {
      const res = await this.props.apiCallPost("/company/getFile", {
        fileId: id,
      });
      this.setState({
        companyLogoUrl: res.psUrl,
      });
    } catch (err) {
      console.log(err);
    }
  };
  setPlace = (place) => {
    this.setState(
      {
        companyCity: place.city,
        companyCountry: place.country,
        companyCountryCallingCode: place.country === "United States" ? "1" : "",
        companyCountryShortCode: place.country === "United States" ? "US" : "",
        companyState: place.state,
        companyAddress: place.formatted_address,
        companyGeoLat: place.geolocation.lat,
        companyGeoLong: place.geolocation.lng,
        companyPostalCode: place.postal_code,
      },
      () => {
        this.updateScalarValue("companyCity", place.city);
        this.updateScalarValue("companyCountry", place.country);
        this.updateScalarValue(
          "companyCountryCallingCode",
          place.country === "United States" ? "1" : ""
        );
        this.updateScalarValue(
          "companyCountryShortCode",
          place.country === "United States" ? "US" : ""
        );
        this.updateScalarValue("companyState", place.state);
        this.updateScalarValue("companyAddress", place.formatted_address);
        this.updateScalarValue("companyGeoLat", place.geolocation.lat);
        this.updateScalarValue("companyGeoLong", place.geolocation.lng);
        this.updateScalarValue("companyPostalCode", place.postal_code);
      }
    );
  };
  render() {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid xs={12} md={4}>
            <Card sx={{ pt: 3, pb: 3, px: 3, textAlign: "center" }}>
              <Stack spacing={2}>
                <UploadSnack uploadProgress={this.state.uploadProgress} />
                <UploadAvatar
                  onDrop={(e) => this.setLogo(e)}
                  file={this.state.companyLogoUrl}
                />
                <TextField
                  label="Employer Name"
                  value={this.state.companyName}
                  onChange={(e) => {
                    this.setState({ companyName: e.target.value }, () => {
                      this.updateScalarValue(
                        "companyName",
                        this.state.companyName
                      );
                      this.props.onUpdate(
                        "companyName",
                        this.state.companyName
                      );
                    });
                  }}
                  // onBlur={() => {
                  //   this.updateScalarValue(
                  //     "companyName",
                  //     this.state.companyName
                  //   );
                  //   this.props.onUpdate("companyName", this.state.companyName);
                  // }}
                />
                <TextField
                  label="Informal Name"
                  value={this.state.companyInformalName}
                  onChange={(e) => {
                    this.setState(
                      { companyInformalName: e.target.value },
                      () => {
                        this.updateScalarValue(
                          "companyInformalName",
                          this.state.companyInformalName
                        );
                        this.props.onUpdate(
                          "companyInformalName",
                          this.state.companyInformalName
                        );
                      }
                    );
                  }}
                  // onBlur={() => {
                  //   this.updateScalarValue(
                  //     "companyInformalName",
                  //     this.state.companyInformalName
                  //   );
                  //   this.props.onUpdate(
                  //     "companyInformalName",
                  //     this.state.companyInformalName
                  //   );
                  // }}
                />
                <TextField
                  label="About"
                  multiline
                  value={this.state.companyAbout}
                  onChange={(e) => {
                    this.setState({ companyAbout: e.target.value }, () => {
                      this.updateScalarValue(
                        "companyAbout",
                        this.state.companyAbout
                      );
                    });
                  }}
                  onBlur={() => {
                    // this.updateScalarValue(
                    //   "companyAbout",
                    //   this.state.companyAbout
                    // );
                  }}
                />
              </Stack>
            </Card>
          </Grid>
          <Grid xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={2}>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  }}
                >
                  <PhoneInput
                    defaultCountry="US"
                    placeholder="Enter phone number"
                    value={this.state.companyPhone}
                    onChange={(e) => {
                      this.setState({ companyPhone: e }, () => {
                        this.updateScalarValue(
                          "companyPhone",
                          this.state.companyPhone
                        );
                      });
                    }}
                    onBlur={() => {
                      // this.updateScalarValue(
                      //   "companyPhone",
                      //   this.state.companyPhone
                      // );
                    }}
                  />
                  <PhoneInput
                    defaultCountry="US"
                    placeholder="Enter fax number"
                    value={this.state.companyFax}
                    onChange={(e) => {
                      this.setState({ companyFax: e }, () => {
                        this.updateScalarValue(
                          "companyFax",
                          this.state.companyFax
                        );
                      });
                    }}
                    onBlur={() => {
                      // this.updateScalarValue(
                      //   "companyFax",
                      //   this.state.companyFax
                      // );
                    }}
                  />
                  <TextField
                    label="Website"
                    value={this.state.companyWebsite}
                    onChange={(e) => {
                      this.setState({ companyWebsite: e.target.value }, () => {
                        let ws = this.state.companyWebsite;
                        if (
                          ws.startsWith("http://") ||
                          ws.startsWith("https://")
                        ) {
                          // Do nothing
                        } else {
                          ws = "https://" + ws;
                        }
                        if (this.state.companyWebsite) {
                          this.updateScalarValue("companyWebsite", ws);
                          this.setState({ companyWebsite: ws });
                        }
                      });
                    }}
                    onBlur={() => {
                      // let ws = this.state.companyWebsite;
                      // if (
                      //   ws.startsWith("http://") ||
                      //   ws.startsWith("https://")
                      // ) {
                      //   // Do nothing
                      // } else {
                      //   ws = "https://" + ws;
                      // }
                      // if (this.state.companyWebsite) {
                      //   this.updateScalarValue("companyWebsite", ws);
                      //   this.setState({ companyWebsite: ws });
                      // }
                    }}
                  />
                  <TextField
                    label="Email"
                    value={this.state.companyEmail}
                    onChange={(e) => {
                      this.setState({ companyEmail: e.target.value }, () => {
                        if (this.state.companyEmail) {
                          this.updateScalarValue(
                            "companyEmail",
                            this.state.companyEmail
                          );
                        }
                      });
                    }}
                    onBlur={() => {
                      // if (this.state.companyEmail) {
                      //   this.updateScalarValue(
                      //     "companyEmail",
                      //     this.state.companyEmail
                      //   );
                      // }
                    }}
                  />
                </Box>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  }}
                ></Box>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  }}
                >
                  <Box
                    rowGap={3}
                    columnGap={2}
                    display="grid"
                    gridTemplateColumns={{
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(1, 1fr)",
                    }}
                  >
                    <GMap
                      onPlace={(r) => this.setPlace(r)}
                      geoObject={{
                        lat: this.state.companyGeoLat,
                        lng: this.state.companyGeoLong,
                      }}
                      onLatLng={(geo) => {
                        this.setState({
                          companyGeoLat: geo.lat,
                          companyGeoLong: geo.lng,
                        });
                        this.updateScalarValue("companyGeoLat", geo.lat);
                        this.updateScalarValue("companyGeoLong", geo.lng);
                      }}
                    />
                  </Box>
                  <Box
                    rowGap={1}
                    columnGap={1}
                    display="grid"
                    gridTemplateColumns={{
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(1, 1fr)",
                    }}
                  >
                    <CountrySelector
                      value={{
                        label: this.state.companyCountry || "",
                        code: this.state.companyCountryShortCode || "",
                        phone: this.state.companyCountryCallingCode || "",
                      }}
                      onChange={(e) => {
                        this.setState({
                          country: e,
                          companyCountry: e.label,
                          companyCountryShortCode: e.code,
                          companyCountryCallingCode: e.phone,
                        });
                        this.updateScalarValue("companyCountry", e.label);
                        this.updateScalarValue(
                          "companyCountryShortCode",
                          e.code
                        );
                        this.updateScalarValue(
                          "companyCountryCallingCode",
                          e.phone
                        );
                      }}
                    />

                    <TextField
                      InputLabelProps={{
                        shrink: this.state.companyAddress,
                      }}
                      label="Address Line 1"
                      multiline
                      value={this.state.companyAddress}
                      onChange={(e) => {
                        this.setState(
                          {
                            companyAddress: e.target.value,
                          },
                          () => {
                            this.updateScalarValue(
                              "companyAddress",
                              this.state.companyAddress
                            );
                          }
                        );
                      }}
                      onBlur={() => {
                        // this.updateScalarValue(
                        //   "companyAddress",
                        //   this.state.companyAddress
                        // );
                      }}
                    />
                    <TextField
                      label="Address Line 2"
                      multiline
                      InputLabelProps={{
                        shrink: this.state.companyAddressQualilfier,
                      }}
                      value={this.state.companyAddressQualilfier}
                      onChange={(e) => {
                        this.setState(
                          {
                            companyAddressQualilfier: e.target.value,
                          },
                          () => {
                            this.updateScalarValue(
                              "companyAddressQualilfier",
                              this.state.companyAddressQualilfier
                            );
                          }
                        );
                      }}
                      onBlur={() => {
                        // this.updateScalarValue(
                        //   "companyAddressQualilfier",
                        //   this.state.companyAddressQualilfier
                        // );
                      }}
                    />
                    <TextField
                      InputLabelProps={{
                        shrink: this.state.companyCity,
                      }}
                      label="City"
                      value={this.state.companyCity}
                      onChange={
                        ((e) => {
                          this.setState({ companyCity: e.target.value });
                        },
                        () => {
                          this.updateScalarValue(
                            "companyCity",
                            this.state.companyCity
                          );
                        })
                      }
                      onBlur={() => {
                        // this.updateScalarValue(
                        //   "companyCity",
                        //   this.state.companyCity
                        // );
                      }}
                    />
                    <TextField
                      InputLabelProps={{
                        shrink: this.state.companyState,
                      }}
                      label="State"
                      value={this.state.companyState}
                      onChange={
                        ((e) => {
                          this.setState({ companyState: e.target.value });
                        },
                        () => {
                          this.updateScalarValue(
                            "companyState",
                            this.state.companyState
                          );
                        })
                      }
                      onBlur={() => {
                        // this.updateScalarValue(
                        //   "companyState",
                        //   this.state.companyState
                        // );
                      }}
                    />
                    <TextField
                      InputLabelProps={{
                        shrink: this.state.companyPostalCode,
                      }}
                      label="Postal Code"
                      value={this.state.companyPostalCode}
                      onChange={
                        ((e) => {
                          this.setState({ companyPostalCode: e.target.value });
                        },
                        () => {
                          this.updateScalarValue(
                            "companyPostalCode",
                            this.state.companyPostalCode
                          );
                        })
                      }
                      onBlur={() => {
                        // this.updateScalarValue(
                        //   "companyPostalCode",
                        //   this.state.companyPostalCode
                        // );
                      }}
                    />
                  </Box>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default WithAPICall(CompanyProfile);
