import { useParams } from "src/routes/hooks";
import DocumentDetails from "./DocumentDetails";

export default function DocumentByID() {
  const params = useParams();
  const { id } = params;
  return (
    <>
      <DocumentDetails id={id} />
    </>
  );
}
