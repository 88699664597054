import React from "react";
import {
  Button,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import { apiCallPostUnuath, niceDate, niceTime } from "../utils/fn";

class FormFoxAuthorizationLetter extends React.Component {
  state = {
    isLoading: true,
    visitId: null,
    data: null,
    isError: false,
  };

  componentDidMount() {
    this.getVisitData();
  }
  getVisitData = async () => {
    try {
      let res = await apiCallPostUnuath("/visit/getVisitByIDForPrint", {
        visitId: this.props.id,
      });
      this.setState({
        data: res,
        isLoading: false,
      });
    } catch (err) {
      this.setState({
        isError: true,
        isLoading: false,
      });
      console.log(err);
    }
  };

  render() {
    const finalData = this.state.data;
    const handlePrint = () => {
      const style = document.createElement("style");
      style.innerHTML = `
        @media print {
          body * {
            visibility: hidden;
          }
          #printableArea, #printableArea * {
            visibility: visible;
          }
          #printableArea {
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      `;
      document.head.appendChild(style);
      window.print();
      document.head.removeChild(style);
    };
    return (
      <Paper
        elevation={3}
        sx={{
          width: "210mm",
          minHeight: "200mm",
          padding: "10mm",
          margin: "auto",
          boxSizing: "border-box",
          position: "relative",
          "@media print": {
            boxShadow: "none",
            margin: 0,
          },
        }}
      >
        <Button
          variant="contained"
          onClick={handlePrint}
          sx={{
            position: "absolute",
            top: "10mm",
            right: "10mm",
            "@media print": {
              display: "none",
            },
          }}
        >
          Print
        </Button>

        <div id="printableArea">
          <Box
            component="img"
            sx={{
              width: "auto",

              maxWidth: { xs: 350, md: 250 },
            }}
            alt="Company Logo"
            src="/logo/crop_logo_full.png"
          />

          <Typography variant="body2" paragraph>
            "If employee is known or suspects that he/she may have an adverse
            reaction to completing elements of the physical, (such as blood
            draws, physical limitation, etc.) then the employee should notify
            WorkCare at the time they scheduled the physical so that appropriate
            safeguards may be taken to protect the health of the employee."
          </Typography>

          <Typography variant="body1" paragraph>
            The following employee has been scheduled for the MEDICAL PROCEDURES
            listed below on the date and at the location shown. If you are
            unable to keep this scheduled appointment or have any questions,
            please contact me at the number listed below.
          </Typography>

          <Typography variant="h6">EXAM INFORMATION</Typography>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>Employee</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Appt Date</TableCell>
                <TableCell>Site</TableCell>
                <TableCell>Appt Time</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{finalData?.employee?.employeeName}</TableCell>
                <TableCell>{finalData?.company?.companyName}</TableCell>
                <TableCell>
                  {niceDate(finalData?.providerEncounterOnDate)}
                </TableCell>
                <TableCell>{finalData?.employee?.employeeCity}</TableCell>
                <TableCell>
                  {niceTime(finalData?.providerEncounterOnTime)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Typography variant="h6" mt={2}>
            CONTACT INFORMATION
          </Typography>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>Client Svc Rep</TableCell>
                <TableCell>Clinic Name</TableCell>
                <TableCell>Address</TableCell>

                <TableCell>Phone</TableCell>
                <TableCell>E-Mail</TableCell>
                <TableCell>Fax</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{}</TableCell>
                <TableCell>{finalData?.provider?.providerName}</TableCell>
                <TableCell>{finalData?.provider?.providerAddress}</TableCell>
                <TableCell>{finalData?.provider?.providerPhone}</TableCell>
                <TableCell>{finalData?.provider?.providerEmail}</TableCell>
                <TableCell>{finalData?.provider?.providerFax}</TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Typography variant="h6" mt={2}>
            EMPLOYEE INSTRUCTIONS
          </Typography>
          <Typography variant="body2">
            BRING THE COMPLETED EXAM PACKET AND THIS PROTOCOL TO YOUR
            APPOINTMENT
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Avoid loud noise exposure for 14 - 16 hours prior to the exam. If you use hearing aids, please bring them to the clinic." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Avoid food and drink for 8 hours prior to the exam. Water and black decaffeinated coffee are OK." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Avoid alcohol consumption for 24 hours prior to exam." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Please bring a photo ID and list of medications with you." />
            </ListItem>
          </List>

          <Typography variant="body2" paragraph>
            NOTE: The following tests and procedures listed below will be
            performed. DO NOT leave until all of them have been completed.
          </Typography>
          <Typography variant="body2" paragraph>
            There may be a corresponding fee for no-show and canceled
            appointments without 24-hour notice.
          </Typography>

          <Typography variant="h6">CLINIC INSTRUCTIONS</Typography>
          <Typography variant="body2" paragraph>
            IMPORTANT: The College of American Pathologist (CAP), our laboratory
            accrediting agency, requires changes regarding proper specimen
            labeling in order to improve patient safety. Below is an excerpt
            from the CAP Laboratory General Checklist:
          </Typography>
          <Typography variant="body2" paragraph>
            GEN.40491 ? Alt Primary specimen containers are labeled with at
            least 2 patient-specific identifiers. The identifiers must
            correspond to information on the patient's test request form.
            WorkCare does not rely on request forms but our electronic order so
            DOB and Name are ESSENTIAL.
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Please check and confirm all identification prior to examination." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Mark Friday appointments for Saturday delivery." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Check expiration date on all blood tubes. If expired, DO NOT USE. Do not store tubes in hot locations. No more than 2 attempts to collect blood unless the employee agrees to 1 additional attempt." />
            </ListItem>
          </List>

          <Typography variant="h6" mt={2}>
            FOR PAPERWORK:
          </Typography>
          <Typography variant="body2" paragraph>
            Please use a WorkCare kit for this exam.- All Exam Paperwork,
            Medical History Questionnaires, original exam results(i.e. X-Ray cd,
            Audiogram Strip, EKG, etc.), should be sent through the WorkCare TPA
            Portal as set up by Concentra. Lab Specimens to be sent via the
            instruction in the WorkCare Lab Collection Kit.
          </Typography>

          <Typography variant="h6" mt={2}>
            AUTHORIZED SERVICE
          </Typography>
          {/* <Table size="small">
            <TableHead>
              <TableRow>
                {table?.headers.map((header, index) => (
                  <TableCell key={index}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {table?.data.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((col, colIndex) => (
                    <TableCell key={colIndex}>{col}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table> */}

          <Typography variant="body2" mt={2} paragraph>
            Note: Clinic: Please use a WorkCare kit for this exam and collect
            specimens in ALL tubes. Place the Medical History Qx, with all the
            original exam reports/results (i.e. Audiogram Strip, PFT, EKG, Back
            Fitness Test Results etc.), with the lab specimens in the kit.
            Please send the kit the same day of exam / collection, shipping
            supplies included in the WorkCare kit. if a chest x-ray is ordered,
            please forward a report within 3 days of exam fax to: 714-922-1024
          </Typography>

          <Typography variant="body2" paragraph>
            PLEASE ENSURE ALL COLLECTION TUBES/CUPS ARE LABELED WITH AT LEAST 2
            IDENTIFIERS (NAME & DOB or NAME & SSN)
          </Typography>

          <Typography variant="body2" paragraph>
            Clinic: Please use a WorkCare Chain of Custody form for this drug
            screen collection - Please Mark Panel # 30KO (CRL) / P3604 (Pactox)
            on the Non Nida Chain Of Custody Form. If the panel number is not
            listed as an option on the COC please mark "Other" and write in the
            panel number. Please send specimens the same day of collection.
            Please fax Chain of Custody Form to WorkCare at 714-922-1038. Thank
            you.
          </Typography>

          <Typography variant="body2" paragraph>
            Clinic: Please have the physician complete the Release to Duty form.
            The physician and the employee must sign the release. Once it has
            been completed, please provide the Employee / Applicant with the
            original, Do Not have them leave the clinic without it. Please
            provide WorkCare with a copy. Please fax to 714-922-1024
          </Typography>

          <Typography variant="h6" mt={2}>
            EXAM INFORMATION
          </Typography>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>Employee</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Appt Date</TableCell>
                <TableCell>Site</TableCell>
                <TableCell>Appt Time</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{finalData?.employee?.employeeName}</TableCell>
                <TableCell>{finalData?.company?.companyName}</TableCell>
                <TableCell>
                  {niceDate(finalData?.providerEncounterOnDate)}
                </TableCell>
                <TableCell>{finalData?.employee?.employeeCity}</TableCell>
                <TableCell>
                  {niceTime(finalData?.providerEncounterOnTime)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Typography variant="body2" mt={2} paragraph>
            Any treatment outside of what is authorized on this protocol must be
            approved by WorkCare. Please contact the individual responsible for
            scheduling the appointment at 800-455-6155 Ext 2457
          </Typography>

          <Typography variant="body2" paragraph>
            Please do not bill {finalData?.provider?.providerName} or the
            patient. All billing should go directly to:
          </Typography>

          <Typography variant="body2">
            WorkCare 300 S. Harbor Blivd., Sulte 600, Anaheim, CA 92805 Phone:
            (800) 455-6155 Fax: (714) 456-2154
          </Typography>
        </div>
      </Paper>
    );
  }
}

export default FormFoxAuthorizationLetter;
