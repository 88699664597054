const ROOTS = {
  DASHBOARD: "",
};
export const paths = {
  dashboard: {
    root: ROOTS.DASHBOARD,
    employers: `${ROOTS.DASHBOARD}/employers`,
    employees: `${ROOTS.DASHBOARD}/employees`,
    networks: `${ROOTS.DASHBOARD}/networks`,
    providers: `${ROOTS.DASHBOARD}/providers`,
    services: `${ROOTS.DASHBOARD}/services`,
    labpricings: `${ROOTS.DASHBOARD}/labpricings`,
    masterprotocols: `${ROOTS.DASHBOARD}/masterprotocols`,
    transfers: `${ROOTS.DASHBOARD}/transfers`,
    surveillance: `${ROOTS.DASHBOARD}/surveillance`,
    visits: `${ROOTS.DASHBOARD}/visits`,
    documents: `${ROOTS.DASHBOARD}/documents`,
    datafiles: `${ROOTS.DASHBOARD}/datafiles`,
    groups: `${ROOTS.DASHBOARD}/groups`,
    //  tasks: `${ROOTS.DASHBOARD}/tasks`,
    users: `${ROOTS.DASHBOARD}/users`,
    tasks: {
      adminChaser: `${ROOTS.DASHBOARD}/tasks/admin-chaser`,
      chaser: `${ROOTS.DASHBOARD}/tasks/chaser`,
      clearances: `${ROOTS.DASHBOARD}/tasks/clearances`,
      root: `${ROOTS.DASHBOARD}/tasks`,
    },
    emails: `${ROOTS.DASHBOARD}/emails`,
    reports: `${ROOTS.DASHBOARD}/reports`,
    ii: `${ROOTS.DASHBOARD}/ii`,
  },
  /*
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
  }, */
};
