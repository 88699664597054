// 'use client';
import { BarChart, Card } from "@tremor/react";
import { WithAPICall } from "../utils/apiUtil";
import { useContext, useEffect, useMemo, useState } from "react";
import structureData from "./utils/massage-data";
import { shuffle } from "lodash";
import HOCContext from "./contexts/HOCContext";

function valueFormatter(number) {
  return `${number}`;
}

export default WithAPICall(function Clearances({ apiCallPost }) {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const { from, to, companyIds } = useContext(HOCContext);
  const colors = useMemo(
    () =>
      shuffle([
        "red",
        "orange",
        "yellow",
        "cyan",
        "violet",
        "rose",
        "purple",
        "teal",
        "emerald",
        "neutral",
      ]),
    []
  );

  useEffect(() => {
    const init = async () => {
      try {
        const { massagedData, categories } = await structureData(
          "/dashboard/clearancesGivenAndPending",
          apiCallPost,
          from,
          to
        );
        setData(massagedData);
        setCategories(categories);
      } catch (error) {
        console.error("could not get data", error);
      }
    };
    init();
  }, [from, to, companyIds]);

  return (
    <>
      {/* <ul className="mt-6 flex gap-10">
        <li>
          <div className="flex items-center space-x-1.5">
            <span
              className="h-2.5 w-2.5 rounded-sm bg-tremor-brand dark:bg-dark-tremor-brand"
              aria-hidden={true}
            />
            <p className="text-tremor-label text-tremor-content dark:text-dark-tremor-content">
              Total No. of Clearances Granted
            </p>
          </div>
          <div className="flex items-center space-x-1.5">
            <p className="mt-0.5 text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              1000
            </p>
          </div>
        </li>
        <li>
          <div className="flex items-center space-x-1.5">
            <span
              className="h-2.5 w-2.5 rounded-sm bg-cyan-500"
              aria-hidden={true}
            />
            <p className="text-tremor-label text-tremor-content dark:text-dark-tremor-content">
              Total No. Of Clearance Tasks Pending
            </p>
          </div>
          <p className="mt-0.5 text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
            20
          </p>
        </li>
      </ul> */}
      <BarChart
        data={data}
        index="date"
        categories={categories}
        colors={colors}
        showLegend={false}
        valueFormatter={valueFormatter}
        yAxisWidth={45}
        className="mt-8 hidden h-56 sm:block"
      />
    </>
  );
});
