import { Helmet } from "react-helmet-async";
import { useParams } from "src/routes/hooks";
import ProtocolDetails from "./ProtocolDetails";

export default function ProtocolByCompanyID() {
  const params = useParams();
  const { id } = params;
  return (
    <>
      <Helmet>
        <title> Protocol Details</title>
      </Helmet>
      <ProtocolDetails protocolId={id} />
    </>
  );
}
