/* eslint-disable no-case-declarations */
import {
  Button,
  Card,
  Chip,
  Drawer,
  IconButton,
  LinearProgress,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Stack } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";
import cogoToast from "cogo-toast";
import _, { cloneDeep } from "lodash";
import React from "react";
import TokenContext from "src/TokenContext";
import Iconify from "src/components/iconify";
import { View500 } from "src/sections/error";
import { addUrlParams, getUrlParams } from "src/utils/url-params";
// import EmailThreads from "../Emails/EmailThreads";
import { WithAPICall } from "../utils/apiUtil";
// import PostVisitCompleted from "./PostVisitCompleted";
import VisitAppointmentTime from "./VisitAppointmentTime";
import VisitChooseProtocols from "./VisitChooseProtocols";
import VisitDocuments from "./VisitDocuments";
import VisitEmployeeLocation from "./VisitEmployeeLocation";
import VisitFinance from "./VisitFinance";
import VisitGroupServices from "./VisitGroupServices";
import VisitNotes from "./VisitNotes";
import VisitPagesOfFile from "./VisitPagesOfFile";
import VisitProtocolData from "./VisitProtocolData";
import VisitReviewSummarySchedule from "./VisitReviewSummarySchedule";
import VisitTimeline from "./VisitTimeline";
import VisitTimelineEventCreate from "./VisitTimelineEventCreate";
import { varBounce, MotionContainer } from "src/components/animate";
import { m } from "framer-motion";
import { LoadingButton } from "@mui/lab";
import { format } from "date-fns";
import Walktour from "src/components/walktour/walktour";
import { ACTIONS, EVENTS, STATUS } from "react-joyride";
// import VisitWorkspace from "./VisitWorkspace";
// import VisitAccordion from "./AccordionView/VisitAccordion";

import ClearanceAreaLayout from "./ClearanceArea/ClearanceAreaLayout";

function copyToClipboard(text) {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log(`Copied to clipboard: ${text}`);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
    cogoToast.success("Copied to clipboard.");
  } else {
    console.error("Clipboard API not supported");
  }
}

class VisitDetails extends React.Component {
  static contextType = TokenContext;

  state = {
    isLoading: true,
    isError: false,
    isSaving: false,
    currentTab: "SCHEDULING",
    drawerFileId: null,
    companyProtocols: [],
    groups: [],
    bookedProtocolIds: [],
    bookedServices: [],
    processes: [],
    clinicFrozen: false,
    clinicPSLs: [],
    labFrozen: false,
    labPSLs: [],
    activeStep: 0,
    templates: [],
    isNewLogOpen: false,
    loadingClearancePdf: false,
    loadingPatientLetter: false,
    isOpen: false,
    notes: null,

    //for joyride
    steps: [
      {
        target: "#STEP_1",
        title: "Visit Status",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            You can select the status of the visit you want to view.
          </Typography>
        ),
      },
      {
        target: "#STEP_2",
        title: "Search Employees",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            You can search for employees by name.
          </Typography>
        ),
      },
      {
        target: "#STEP_3",
        title: "Search Service",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            You can search for services by name.
          </Typography>
        ),
      },
      {
        target: "#STEP_4",
        title: "Date Range",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            You can select the date range you want to view data for.
          </Typography>
        ),
      },
      {
        target: "#STEP_5",
        title: "Create New Visit",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            You can create a new visit by clicking here.
          </Typography>
        ),
      },
      {
        target: "#STEP_6",
        title: "Request New Visit",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            You can request a new visit by clicking here.
          </Typography>
        ),
      },

      {
        target: "#STEP_7",
        title: "Employee Column",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            You can view employee name here.
          </Typography>
        ),
      },
      {
        target: "#STEP_8",
        title: "Protocol(s) Column",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            You can view protocol(s) here.
          </Typography>
        ),
      },
      {
        target: "#STEP_9",
        title: "Clinic(s) Column",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            You can view clinic(s) here.
          </Typography>
        ),
      },
      {
        target: "#STEP_10",
        title: "Status Column",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            You can view the status of employee here.
          </Typography>
        ),
      },
      {
        target: "#STEP_11",
        title: "Clearances(s) Column",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            You can view the clearances(s) of employee here.
          </Typography>
        ),
      },
      {
        target: "#STEP_12",
        title: "All Visits",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            You can view all the visits here.
          </Typography>
        ),
      },
      {
        target: "#STEP_13",
        title: "Resume This Walkthrough",
        disableBeacon: true,
        content: (
          <Typography sx={{ color: "text.secondary" }}>
            Once you have chosen this, click here again to resume this
            walkthrough.
          </Typography>
        ),
      },
    ],
    runJoyRide: false,
    stepIndex: 0,
  };

  STEPS = [
    {
      label: "Choose Protocols and Services",
      description: `Add the protocols and services you want to cover in this visit.`,
      next: "Choose Provider",
    },
    // {
    //   label: "Confirm Employee Location",
    //   description:
    //     "Once you confirm employee location, it will be easier to find providers close to the employee. This step is optional but recommended for all visits that involve visiting a physical location.",
    //   next: "Choose Providers",
    // },
    {
      label: "Add Details",
      description: "Choose the providers for the services you have chosen.",
      next: "Review & Confirm",
      previous: "Edit Protocols & Services",
    },
    // {
    //   label: "Choose Appointment Time",
    //   description: "Call and schedule times for the visit with the clinic.",
    //   next: "Review & Confirm Visit",
    // },
    {
      label: "Review & Confirm Visit",
      description: "Review and confirm the visit.",
      previous: "Choose Provider",
    },
  ];

  async componentDidMount() {
    await this.getById();
    await this.doLoadGroups();
    this.loadEmailTemplates();
    const urlParams = getUrlParams();
    if (urlParams.currentTab) {
      this.setState({ currentTab: urlParams.currentTab });
    }
  }
  handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ runJoyRide: false, stepIndex: 0 });
    }
  };

  doLoadGroups = async () => {
    try {
      let res = await this.props.apiCallPost("/files/groups/search", {
        visitId: this.props.id,
      });
      this.setState({
        groups: res,
      });
    } catch (err) {
      cogoToast.error("Error Loading File Groups");
      console.log(err);
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };

  getById = async () => {
    try {
      let res = await this.props.apiCallPost("/visit/getVisitById", {
        visitId: this.props.id,
      });
      this.setState(
        {
          isError: false,
          ...res,
        },
        () => {
          let canScheduleStates = [
            "REQUESTED",
            "OUTREACH_FOR_SCHEDULING",
            "RESCHEDULING_REQUESTED",
          ];
          let visitStatus = this.state.visitStatus;
          if (canScheduleStates.includes(visitStatus)) {
            this.setState(
              {
                // activeStep: 0,
                currentTab: "SCHEDULING",
              },
              () => {
                this.companyProtocolGetAll();
              }
            );
          } else {
            this.companyProtocolGetAll();
          }
          // if (this.state.visitStatus === "COMPLETED") {
          //   this.setState({
          //     activeStep: 5,
          //   });
          //   this.STEPS.push({
          //     label: "Post Visit Completed",
          //     description: "Actions to take once the visit has been completed.",
          //     showExternal: false,
          //   });
          //   this.STEPS[4].next = this.STEPS[5].label;
          // }
        }
      );
    } catch (err) {
      cogoToast.error("Error Loading Visit Data");
      console.log(err);
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  loadEmailTemplates = async () => {
    try {
      const res = await this.props.apiCallGet("/email/templates");
      this.setState({ templates: res.templates });
    } catch (error) {
      cogoToast.error("Could not retrieve templates. Please try again.");
      this.setState({ error: true });
    }
  };
  companyProtocolGetAll = async () => {
    try {
      let res = await this.props.apiCallPost(
        "/company-protocol/companyProtocolGetAll",
        {
          companyId: this.state.company._id,
        }
      );
      res = res.filter(
        (x) =>
          x.activeStatus === "ACTIVE" && (x.masterServices || []).length > 0
      );
      this.setState(
        {
          isLoading: false,
          isError: false,
          companyProtocols: res,
        },
        () => {
          this.doesModifiedEmployeeSatisfyAnyProtocols();
        }
      );
    } catch (err) {
      cogoToast.error("Error Loading Company Protocol Data");
      console.log(err);
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  doesModifiedEmployeeSatisfyAnyProtocols = async () => {
    try {
      let res = await this.props.apiCallPost(
        "/employee/doesModifiedEmployeeSatisfyAnyProtocols",
        {
          employeeId: this.state.employee._id,
          companyProtocolType: "Periodic",
          modSpec: {},
        }
      );

      this.setState({
        isLoading: false,
        isError: false,
      });
    } catch (err) {
      cogoToast.error("Error Loading Company Protocol Data");
      console.log(err);
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  updateVisitScalars = async (arr, showNotification = true) => {
    this.setState({
      isSaving: true,
    });
    try {
      let res = await this.props.apiCallPost("/visit/updateVisitScalars", {
        visitId: this.props.id,
        uops: arr,
      });
      if (showNotification) {
        cogoToast.success("Visit Data Saved");
      }
      this.setState({
        isSaving: false,
        ...res,
      });
    } catch (err) {
      cogoToast.error("Error Saving Visit Data");
      console.log(err);
      this.setState({
        isSaving: false,
      });
    }
  };

  renderSteps = () => {
    const isExternal = this.context.currentUser.isExternal;
    let steps = this.STEPS;
    return (
      <Card sx={{ p: 2 }}>
        <Stepper activeStep={this.state.activeStep} orientation="horizontal">
          {steps.map((step, index) => {
            return (
              <Step key={index}>
                <StepLabel>
                  <Typography variant="overline">{step.label}</Typography>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            mt: 2,
          }}
        >
          <Button
            size="small"
            disabled={!this.STEPS[this.state.activeStep].previous}
            onClick={() => {
              this.setState(
                {
                  activeStep: this.state.activeStep - 1,
                },
                () => {
                  this.updateVisitScalars([
                    {
                      key: "activeStep",
                      value: this.state.activeStep,
                    },
                  ]);
                }
              );
            }}
            variant="outlined"
            startIcon={<Iconify icon="ooui:previous-ltr" />}
          >
            {this.STEPS[this.state.activeStep].previous || "Previous"}
          </Button>
          <Button
            size="small"
            disabled={!this.STEPS[this.state.activeStep].next}
            onClick={() => {
              if (this.state.activeStep === 0) {
                if (
                  this.state.protocols.length === 0 ||
                  this.state.services.length === 0
                ) {
                  cogoToast.error(
                    "Please add protocols and services before proceeding."
                  );
                  return;
                }
              }
              this.setState(
                {
                  activeStep: this.state.activeStep + 1,
                },
                () => {
                  this.updateVisitScalars([
                    {
                      key: "activeStep",
                      value: this.state.activeStep,
                    },
                  ]);
                }
              );
            }}
            variant="outlined"
            endIcon={<Iconify icon="ooui:next-ltr" />}
          >
            {this.STEPS[this.state.activeStep].next || "Next"}
          </Button>
        </Stack>
      </Card>
    );
  };
  renderBasedOnStepIndex = () => {
    let stepIdx = this.state.activeStep;
    console.log(stepIdx);
    switch (stepIdx) {
      case 0:
        return (
          <VisitChooseProtocols
            isSaving={this.state.isSaving}
            employee={this.state.employee}
            companyProtocols={this.state.companyProtocols}
            protocols={this.state.protocols}
            servicesInfo={this.state.servicesInfo}
            services={this.state.services}
            visit={this.state}
            addProtocol={(protocolId, bookedServices) => {
              let newProtocols = [...this.state.protocols, protocolId];
              let newServices = [...this.state.services, ...bookedServices];
              const new_serviceParentProtocols = {
                ...(this.state.serviceParentProtocols || {}),
              };
              for (let svc of bookedServices) {
                new_serviceParentProtocols[svc] = protocolId;
              }
              this.setState(
                {
                  protocols: _.uniq(newProtocols),
                  services: _.uniq(newServices),
                  serviceParentProtocols: new_serviceParentProtocols,
                  clinicFrozen: false,
                  providerPSLs: [],
                },
                () => {
                  let payload = [
                    {
                      key: "protocols",
                      value: this.state.protocols,
                    },
                    {
                      key: "services",
                      value: this.state.services,
                    },
                    {
                      key: "serviceParentProtocols",
                      value: this.state.serviceParentProtocols,
                    },
                    {
                      key: "clinicFrozen",
                      value: this.state.clinicFrozen,
                    },
                    {
                      key: "providerPSLs",
                      value: this.state.providerPSLs,
                    },
                  ];
                  this.updateVisitScalars(payload);
                }
              );
            }}
            removeProtocol={(protocolId) => {
              let newProtocols = this.state.protocols.filter(
                (each) => each !== protocolId
              );

              let newServices = this.state.services.filter((each) => {
                if (this.state.serviceParentProtocols[each] === protocolId) {
                  return false;
                }
                return true;
              });

              this.setState(
                {
                  protocols: newProtocols,
                  services: newServices,
                  clinicFrozen: false,
                  labFrozen: false,
                },
                () => {
                  let payload = [
                    {
                      key: "protocols",
                      value: this.state.bookedProtocolIds,
                    },
                    {
                      key: "services",
                      value: this.state.bookedServices,
                    },
                    {
                      key: "providerPSLs",
                      value: [],
                    },
                    {
                      key: "providerPSLsInfo",
                      value: [],
                    },
                    {
                      key: "clinicFrozen",
                      value: this.state.clinicFrozen,
                    },
                  ];
                  this.updateVisitScalars(payload);
                }
              );
            }}
            key={`${this.state.protocols.length}-${this.state.services.length}-${this.state.servicesInfo.length}`}
          />
        );
      case 1:
        return (
          <>
            <VisitTimelineEventCreate
              visitId={this.props.id}
              isOpen={this.state.isNewLogOpen}
              handleClose={() => {
                this.setState({ isNewLogOpen: false });
              }}
              onCreate={(event) => {
                this.setState({ isNewLogOpen: false });
              }}
            />
            <VisitGroupServices
              companyProtocols={this.state.companyProtocols}
              visit={this.state}
              isSaving={this.state.isSaving}
              updateVisitScalars={this.updateVisitScalars}
              openLogEvent={() => {
                this.setState({ isNewLogOpen: true });
              }}
              onChangeNoteToClinic={(value) => {
                this.setState((oldState) => {
                  const newState = cloneDeep(oldState);
                  newState.noteToClinic = value;
                  return newState;
                });
              }}
              unFreezeClinics={() => {
                this.setState(
                  {
                    clinicFrozen: false,
                    provider: null,
                    providerDistanceFromEE: null,
                    providerTimeFromEE: null,
                    providerEncounterOnDate: null,
                    providerEncounterOnTime: null,
                    serviceBookingInfo: {},
                    providerPSLs: [],
                    providerPSLsInfo: [],
                  },
                  () => {
                    this.updateVisitScalars([
                      {
                        key: "provider",
                        value: this.state.provider,
                      },
                      {
                        key: "providerDistanceFromEE",
                        value: this.state.providerDistanceFromEE,
                      },
                      {
                        key: "providerTimeFromEE",
                        value: this.state.providerTimeFromEE,
                      },
                      {
                        key: "serviceBookingInfo",
                        value: this.state.serviceBookingInfo,
                      },
                      {
                        key: "providerPSLs",
                        value: this.state.providerPSLs,
                      },
                      {
                        key: "providerPSLsInfo",
                        value: this.state.providerPSLsInfo,
                      },
                      {
                        key: "clinicFrozen",
                        value: this.state.clinicFrozen,
                      },
                    ]);
                  }
                );
              }}
              onChangeNoteToWorkcare={(value) => {
                this.setState((oldState) => {
                  const newState = cloneDeep(oldState);
                  newState.noteToWorkcare = value;
                  return newState;
                });
              }}
              onFreezeClinic={(data) => {
                this.setState(
                  {
                    provider: data.provider,
                    providerDistanceFromEE: data.providerDistanceFromEE,
                    providerTimeFromEE: data.providerTimeFromEE,
                    providerPSLs: data.providerPSLs,
                    providerPSLsInfo: data.providerPSLsInfo,
                    serviceBookingInfo: data.serviceBookingInfo,
                    clinicFrozen: true,
                  },
                  () => {
                    this.updateVisitScalars([
                      {
                        key: "provider",
                        value: this.state.provider,
                      },
                      {
                        key: "providerDistanceFromEE",
                        value: this.state.providerDistanceFromEE,
                      },
                      {
                        key: "providerTimeFromEE",
                        value: this.state.providerTimeFromEE,
                      },
                      {
                        key: "providerPSLs",
                        value: this.state.providerPSLs,
                      },
                      {
                        key: "providerPSLsInfo",
                        value: this.state.providerPSLsInfo,
                      },
                      {
                        key: "serviceBookingInfo",
                        value: this.state.serviceBookingInfo,
                      },
                      {
                        key: "clinicFrozen",
                        value: this.state.clinicFrozen,
                      },
                    ]);
                  }
                );
              }}
              key={`vgs-${this.state.nEEOutreachAttempts}-${this.state.provider?._id}-${this.state.providerPSLsInfo.length}`}
            />
          </>
        );
      case 2:
        return (
          <VisitReviewSummarySchedule
            visit={this.state}
            onSetVisitScalar={(key, value) => {
              this.setState(
                {
                  [key]: value,
                },
                () => {
                  this.updateVisitScalars([
                    {
                      key: key,
                      value: this.state[key],
                    },
                  ]);
                }
              );
            }}
            onFreezeTime={(newValue) => {
              this.setState(
                {
                  providerEncounterOnTime: newValue,
                },
                () => {
                  this.updateVisitScalars([
                    {
                      key: "providerEncounterOnTime",
                      value: this.state.providerEncounterOnTime.toDate(),
                    },
                    {
                      key: "providerEncounterOnDate",
                      value: this.state.providerEncounterOnTime
                        .clone()
                        .startOf("day")
                        .toDate(),
                    },
                  ]);
                }
              );
            }}
            onSetToScheduled={() => {
              this.setState({
                visitStatus: "SCHEDULED",
              });
            }}
          />
        );
      case 5:
        return <div>Not In Production</div>;
      default:
        return <div>Not In Production</div>;
    }
  };
  renderVisitCancelDialog = () => {
    return (
      <Dialog
        open={this.state.isOpen}
        onClose={() => this.setState({ isOpen: false })}
      >
        <DialogTitle>Cancel this visit</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              name="name"
              label="Notes"
              fullWidth
              disabled={this.state.isCreating}
              value={this.state.notes}
              onChange={(e) => {
                this.setState({ notes: e.target.value });
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.handleClose()}
            disabled={this.state.isCreating}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={this.state.isCreating}
            disabled={!this.state.notes}
            variant="contained"
            onClick={async () => {
              let payload = {
                visitId: this.state._id,
                header: "Visit Cancelled",
                notes: this.state.notes,
                creator: "User",
                statusTo: "CANCELLED",
              };
              cogoToast.loading("Cancelling Event");
              const res = await this.props.apiCallPost(
                "/visit/events/createNewVisitEvent",
                payload
              );
              this.setState({
                visitStatus: "CANCELLED",
                isOpen: false,
              });
            }}
          >
            Cancel Visit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  };
  renderVisitInformation = () => {
    let employeeName = this.state.employee?.employeeName;
    let companyName = this.state.company?.companyName;
    let employeeId = this.state.employee?._id;
    let companyId = this.state.company?._id;
    let createdByName = this.state.createdBy?.name;
    let createdByEmail = this.state.createdBy?.email;

    let createdByElem = (
      <Tooltip title={createdByEmail}>
        <Chip
          label={`Created By: ${createdByName}`}
          size="small"
          variant="outlined"
        />
      </Tooltip>
    );
    let companyElem = (
      <Button
        variant="large"
        href={`/employers/${companyId}`}
        // target="non_blank"
        endIcon={<Iconify icon="mdi:arrow-top-right" />}
      >
        {companyName}
      </Button>
    );

    let employeeElem = (
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2">{employeeName}</Typography>
        <IconButton target="_blank" href={`/employees/${employeeId}`}>
          <Iconify icon="mdi:arrow-top-right" />
        </IconButton>
        <Tooltip
          title={
            <Stack spacing={0.5}>
              <Typography variant="caption">
                SSN: {this.state.employee.employeeSSN || "NA"}
              </Typography>
              <Typography variant="caption">
                Date of Birth:{" "}
                {this.state.employee.employeeDoB
                  ? format(new Date(this.state.employee.employeeDoB), "P")
                  : "NA"}
              </Typography>
              <Typography variant="caption">
                Sex: {this.state.employee.employeeSex || "NA"}
              </Typography>
              <Typography variant="caption">
                EID: {this.state.employee.employeeNumber || "NA"}
              </Typography>
            </Stack>
          }
        >
          <Iconify icon="lucide:info" />
        </Tooltip>
      </Stack>
    );
    let visitStatusElem = (
      <Tooltip title="Visit Status">
        <Chip
          label={`${_.startCase(_.lowerCase(this.state.visitStatus))}`}
          // size="small"
          variant="outlined"
        />
      </Tooltip>
    );
    return (
      <>
        <Stack spacing={1} direction="row" alignItems={"center"}>
          {companyElem}
          <Iconify icon="ph:dot-outline-fill" />
          {employeeElem}
          <Iconify icon="ph:dot-outline-fill" />
          <Button
            size="small"
            variant="outlined"
            startIcon={<Iconify icon="ph:copy" />}
            onClick={() => {
              copyToClipboard(this.state.humanReadableId);
            }}
          >
            {this.state.humanReadableId}
          </Button>
          <Iconify icon="ph:dot-outline-fill" />
          {this.state.externalAuthzCode && (
            <>
              <Button
                size="small"
                variant="outlined"
                startIcon={<Iconify icon="ph:copy" />}
                onClick={() => {
                  copyToClipboard(this.state.externalAuthzCode);
                }}
              >
                Ext Auth: {this.state.externalAuthzCode}
              </Button>
              <Iconify icon="ph:dot-outline-fill" />
            </>
          )}
          {visitStatusElem}
          <Iconify icon="ph:dot-outline-fill" />
          <Button
            startIcon={<Iconify icon="ic:baseline-phone" />}
            href={`tel:${this.state.employee.employeePersonalPhone}`}
            variant="outlined"
            size="small"
          >
            {this.state.employee.employeePersonalPhone}
          </Button>

          {/* {createdByElem}
        <Iconify icon="ph:dot-outline-fill" /> */}
          {![
            "AWAITING_DOCUMENTS",
            "DOCUMENTS_RECEIVED",
            "DOCUMENTS_PROCESSED",
            "DOCUMENTS_IN_REVIEW",
            "DOCUMENTS_REVIEWED",
            "COMPLETED",
            "CANCELLED",
          ].includes(this.state.visitStatus) && (
            <Button
              startIcon={<Iconify icon="material-symbols:cancel" />}
              color="error"
              sx={{ marginLeft: "auto" }}
              variant="contained"
              onClick={() => {
                this.setState({ isOpen: true });
              }}
            >
              Cancel Visit
            </Button>
          )}
          {/* <Button
            alignItems="end"
            size="small"
            variant="contained"
            startIcon={<Iconify icon="mingcute:question-fill" />}
            id="STEP_13"
            onClick={() => {
              this.setState({
                runJoyRide: true,
              });
            }}
          >
            Quick walkthrough
          </Button> */}
        </Stack>
      </>
    );
  };
  onEmailSent = (thread) => {
    this.setState({ isNewLogOpen: true });
  };
  tabs = [
    {
      value: "SCHEDULING",
      label: "Scheduling",
      icon: <Iconify icon="solar:calendar-broken" width={24} />,
      showExternal: true,
    },
    {
      value: "WORKSPACE",
      label: "Clearance",
      icon: <Iconify icon="solar:calendar-broken" width={24} />,
      showExternal: false,
    },
    // {
    //   value: "PROTOCOLS",
    //   label: "Protocols",
    //   icon: <Iconify icon="carbon:report-data" width={24} />,
    //   showExternal: false,
    // },
    // {
    //   value: "REPORTS",
    //   label: "Charts",
    //   icon: <Iconify icon="solar:document-linear" width={24} />,
    //   showExternal: false,
    // },
    {
      value: "NOTES",
      label: "Notes",
      icon: <Iconify icon="solar:document-add-linear" width={24} />,
    },
    {
      value: "FINANCE",
      label: "Finance",
      icon: <Iconify icon="solar:money-bag-outline" width={24} />,
    },
    {
      value: "TIMELINE",
      label: "Timeline",
      icon: <Iconify icon="gravity-ui:list-timeline" width={24} />,
      showExternal: true,
    },
  ];
  renderTabs = () => {
    let tabs = this.tabs;
    if (this.context.currentUser.isExternal) {
      tabs = this.tabs.filter((t) => t.showExternal);
    }
    return (
      <Tabs
        value={this.state.currentTab}
        onChange={(_, value) => {
          addUrlParams({ currentTab: value });
          this.setState({ currentTab: value });
        }}
        sx={{
          mb: { xs: 1, md: 1 },
        }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            icon={tab.icon}
            value={tab.value}
          />
        ))}
      </Tabs>
    );
  };
  renderTab = () => {
    switch (this.state.currentTab) {
      case "SCHEDULING":
        return this.renderSchedulingTab();
      case "WORKSPACE":
        return (
          <ClearanceAreaLayout
            _id={this.props.id}
            onStatusChange={(status) => {
              if (status) {
                this.setState({
                  visitStatus: status,
                });
              }
            }}
          />
          // <VisitWorkspace
          //   id={this.props.id}
          //   visitData={this.state}
          //   updateVisitScalars={(arr) => this.updateVisitScalars(arr)}
          // />
          // <VisitAccordion
          //   id={this.props.id}
          //   visitData={this.state}
          //   updateVisitScalars={(arr) => this.updateVisitScalars(arr)}
          //   groups={this.state.groups}
          // />
        );

      case "NOTES":
        return <VisitNotes id={this.props.id} />;
      // case "PROTOCOLS":
      //   return (
      //     <VisitProtocolData
      //       id={this.props.id}
      //       visitData={this.state}
      //       updateVisitScalars={(arr) => this.updateVisitScalars(arr)}
      //       groups={this.state.groups}
      //     />
      //   );
      case "TIMELINE":
        return (
          <VisitTimeline
            id={this.props.id}
            visitData={this.state}
            onStatusChange={(e) => {
              if (e.statusTo) {
                this.setState({
                  visitStatus: e.statusTo,
                });
              }
            }}
          />
        );
      case "EMAIL":
        const emailTypes = [
          {
            type: "Employer",
            name: this.state.company.companyName,
            address: this.state.company.companyEmail,
          },
        ];

        if (this.state.employee.employeeOfficeEmail) {
          emailTypes.push({
            type: "Employee",
            name: `${this.state.employee.employeeName}: Office Email`,
            address: this.state.employee.employeeOfficeEmail,
          });
        }
        if (this.state.employee.employeePersonalEmail) {
          emailTypes.push({
            type: "Employee",
            name: `${this.state.employee.employeeName}: Personal Email`,
            address: this.state.employee.employeePersonalEmail,
          });
        }
        this.state.clinicPSLs.forEach((psl) => {
          if (psl.providerObj.providerEmail) {
            emailTypes.push({
              type: "Provider",
              name: psl.providerObj.providerName,
              address: psl.providerObj.providerEmail,
            });
          }
        });

        return (
          <>
            <VisitTimelineEventCreate
              visitId={this.props.id}
              isOpen={this.state.isNewLogOpen}
              handleClose={() => {
                // this.setState({ isNewLogOpen: false });
              }}
              onCreate={(event) => {
                this.setState({ isNewLogOpen: false });
                // this.setState({
                //   events: [...this.state.events, event],
                //   isNewLogOpen: false,
                // });
              }}
            />
          </>
        );
      // case "REPORTS":
      //   return <VisitDocuments id={this.props.id} visitData={this.state} />;
      case "FINANCE":
        return (
          <VisitFinance
            visit={{ ...this.state }}
            updateServicesDone={async (rows) => {
              await this.updateVisitScalars([
                {
                  key: "servicesDone",
                  value: rows,
                },
              ]);
            }}
            updateAddonFees={async (rows) => {
              await this.updateVisitScalars([
                {
                  key: "addOnFees",
                  value: rows,
                },
              ]);
            }}
          />
        );
      default:
        return null;
      // return <div>Not In Production</div>;
    }
  };

  renderSchedulingTab = () => {
    let canScheduleStates = [
      "REQUESTED",
      "OUTREACH_FOR_SCHEDULING",
      "RESCHEDULING_REQUESTED",
    ];
    let visitStatus = this.state.visitStatus;
    if (canScheduleStates.includes(visitStatus)) {
      return (
        <Grid container spacing={1}>
          <Grid xs={12} md={12}>
            {this.renderSteps()}
          </Grid>
          <Grid xs={12} md={12}>
            {this.renderBasedOnStepIndex()}
          </Grid>
        </Grid>
      );
    } else {
      return <VisitReviewSummarySchedule visit={this.state} />;
    }
  };
  renderFileDrawer = () => {
    return (
      <Drawer
        anchor="right"
        open={this.state.drawerFileId ? true : false}
        onClose={() => {
          this.setState({
            drawerFileId: null,
          });
        }}
      >
        <div
          style={{
            width: "90vw",
          }}
        >
          <Card sx={{ p: 2 }}>
            <VisitPagesOfFile fileId={this.state.drawerFileId} />
          </Card>
        </div>
      </Drawer>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    // if the visit was requested and has not been scheduled
    // show that the details will be available once workcare
    // schedules the visit
    // if (
    //   this.context.currentUser.isExternal &&
    //   this.state.requestedByEmployer &&
    //   this.state.visitStatus === "REQUESTED"
    // ) {
    //   return (
    //     <div
    //       style={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         backgroundColor: "#f5f5f5",
    //         textAlign: "center",
    //         height: "100dvh",
    //       }}
    //     >
    //       <MotionContainer>
    //         <m.div variants={varBounce().in}>
    //           <Typography variant="h3" sx={{ mb: 2 }}>
    //             Workcare will schedule your visit soon.
    //           </Typography>
    //         </m.div>

    //         <m.div variants={varBounce().in}>
    //           <Typography sx={{ color: "text.secondary" }}>
    //             Details of this visit will be available once it has been
    //             scheduled.
    //             <br />
    //           </Typography>
    //         </m.div>

    //         <m.div variants={varBounce().in}>
    //           {/* <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} /> */}
    //         </m.div>
    //       </MotionContainer>
    //     </div>
    //   );
    // }
    const { runJoyRide, stepIndex, steps } = this.state;
    return (
      <Stack spacing={2}>
        <Walktour
          key={this.state.steps.length}
          continuous
          showProgress
          showSkipButton
          disableOverlayClose
          callback={this.handleJoyrideCallback}
          run={runJoyRide}
          stepIndex={stepIndex}
          steps={steps}
        />
        {this.renderVisitCancelDialog()}
        {this.renderFileDrawer()}
        {this.renderVisitInformation()}
        {this.renderTabs()}
        {this.renderTab()}
      </Stack>
    );
  }
}

export default WithAPICall(VisitDetails);
