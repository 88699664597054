import { Button, LinearProgress, TextField } from "@mui/material";
import React from "react";
import Iconify from "src/components/iconify";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { WithAPICall } from "../utils/apiUtil";
import { Stack } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import ImageLoaderForGrid from "../utils/ImageLoaderForGrid";
import { NoResultView, View500 } from "src/sections/error";
import ProtocolCreate from "./ProtocolCreate";
import cogoToast from "cogo-toast";
import _ from "lodash";

class ListProtocols extends React.Component {
  state = {
    query: "",
    isSearching: true,
    quickSearchText: "",
    enableAdvancedFilter: false,
    hasLoadingStarted: false,
    list: [],
    openProtocolCreate: false,
    selectedRows: [],
    aggregations: {
      sum: (params) => this.sumAggregation(params),
      average: (params) => this.averageAggregation(params),
      max: (params) => this.maxAggregation(params),
      min: (params) => this.minAggregation(params),
      count: (params) => this.countAggregation(params),
    },
  };
  buffer = [];
  timer = null;
  componentDidMount() {
    this.getMasterProtocols();
  }
  getMasterProtocols = async () => {
    try {
      let res = await this.props.apiCallPost(
        "/master-protocol/masterProtocolGetAll",
        {}
      );
      this.setState({ masterProtocols: res }, () => {
        this.search();
      });
    } catch (err) {
      cogoToast.error("Error Loading Master Protocols");
      this.setState({
        isLoading: false,
      });
    }
  };
  search = async () => {
    this.setState({ isSearching: true });
    cogoToast.loading("Loading data, this might take a while!");
    this.setState({
      list: [],
      isSearching: true,
      hasLoadingStarted: false,
    });
    this.buffer = [];
    this.timer = setInterval(() => {
      let bufferLength = this.buffer.length;
      let listLength = this.state.list.length;
      let diff = bufferLength - listLength;

      if (diff > 0) {
        this.setState({
          list: [
            ...this.state.list,
            ...this.buffer.slice(listLength, bufferLength),
          ],
        });
      }
    }, 250);
    let payload = {
      companyId: this.props.companyId,
    };

    await this.props.apiCallPostStreaming(
      "/company-protocol/companyProtocolGetAllStreaming",
      payload,
      (data) => {
        if (!this.state.hasLoadingStarted) {
          this.setState({ hasLoadingStarted: true });
        }
        this.buffer.push(...data);
      },
      () => {
        this.setState(
          { isSearching: false, hasLoadingStarted: false },
          async () => {
            // wait 4 seconds
            await new Promise((resolve) => setTimeout(resolve, 4000));
            clearInterval(this.timer);
          }
        );
      }
    );
  };
  COLUMNS = [
    {
      headerName: "Protocol Name",
      field: "companyProtocolName",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
      cellRenderer: (row) => this.renderName(row),
    },
    {
      headerName: "Code",
      field: "companyProtocolCode",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Type",
      field: "companyProtocolType",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Status",
      field: "activeStatus",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Cost Model",
      field: "costModel",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
    },
  ];
  booleanGetter = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    return (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        {row.value ? "Yes" : "No"}
      </span>
    );
  };

  renderName = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    return (
      <span
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ImageLoaderForGrid
          apiPrefix="provider"
          value={row.data.providerLogo}
          onLoad={() => {
            this.sizeToFit();
          }}
        />
        <Button
          fullWidth
          href={`/protocols/${row.data._id}`}
          variant="outlined"
          startIcon={<Iconify icon="mingcute:external-link-line" />}
        >
          {row.value}
        </Button>
      </span>
    );
  };
  sizeToFit = () => {
    const allColumnIds = [];
    this.state.gridApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    this.state.gridApi.autoSizeColumns(allColumnIds, false);
  };
  onGridReady = (params) => {
    this.setState({ gridApi: params.api, columnApi: params.columnApi });
  };
  sumAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));

    return _.sum(arr);
  };
  averageAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.mean(arr);
  };
  maxAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.max(arr);
  };
  minAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.min(arr);
  };
  countAggregation = (params) => {
    return params.values.length;
  };
  renderGrid = () => {
    let list = this.state.list;
    if (!list || list.length === 0) {
      return <NoResultView />;
    }
    let cs = "ag-theme-quartz";
    let theme = localStorage.getItem("themeMode");
    if (theme && theme === "dark") {
      cs = "ag-theme-quartz-dark";
    }
    return (
      <div className={cs} style={{ height: "70vh" }}>
        <AgGridReact
          onGridReady={this.onGridReady}
          rowData={this.state.list}
          columnDefs={this.COLUMNS}
          defaultColDef={{
            sortable: true,
          }}
          autoSizeStrategy={{
            type: "fitCellContents",
          }}
          pagination={true}
          rowGroupPanelShow={
            this.state.enableAdvancedFilter ? "always" : "never"
          }
          aggFuncs={this.state.aggregations}
          suppressDragLeaveHidesColumns={true}
          rowSelection="multiple"
          rowDragManaged={true}
          enableCharts={true}
          enableRangeSelection={true}
          enableCellTextSelection={true}
          quickFilterText={this.state.quickSearchText}
          sideBar={true}
          animateRows={true}
          enableAdvancedFilter={this.state.enableAdvancedFilter}
        />
      </div>
    );
  };

  renderIfSelectedRows = () => {
    if (!this.state.selectedRows) {
      return null;
    }
    if (this.state.selectedRows.length === 0) {
      return null;
    }
    return (
      <Button
        variant="contained"
        startIcon={<Iconify icon="mingcute:link-3-line" />}
        onClick={() => {
          cogoToast.info("Coming Soon!");
        }}
      >
        Associate {this.state.selectedRows.length}
        {this.state.selectedRows.length === 1 ? " Provider" : " Providers"} with
        Employer
      </Button>
    );
  };
  renderControlBox = () => {
    // let list = this.state.list;
    // if (!list) return null;
    // if (list.length === 0) return null;
    return (
      <Stack direction="row" spacing={1}>
        <TextField
          disabled={this.state.isSearching}
          value={this.state.quickSearchText}
          label="Quick Search"
          onChange={(e) =>
            this.setState({
              quickSearchText: e.target.value,
            })
          }
        />
        <Button
          disabled={this.state.isSearching}
          variant="contained"
          startIcon={<Iconify icon="mingcute:filter-line" />}
          onClick={() => {
            this.setState({
              enableAdvancedFilter: !this.state.enableAdvancedFilter,
            });
          }}
        >
          {this.state.enableAdvancedFilter
            ? "Advanced Filters"
            : "Simple Filters"}
        </Button>{" "}
        <LoadingButton
          loading={this.state.isSearching}
          onClick={() => this.search()}
          variant="contained"
          startIcon={<Iconify icon="mingcute:refresh-anticlockwise-1-line" />}
        >
          Refresh Data
        </LoadingButton>{" "}
        <Button
          disabled={this.state.isSearching}
          onClick={() => this.setState({ openProtocolCreate: true })}
          variant="contained"
          startIcon={<Iconify icon="mingcute:add-line" />}
        >
          New Protocol
        </Button>
      </Stack>
    );
  };
  render() {
    if (this.state.isSearching) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <div>
        <ProtocolCreate
          masterProtocols={this.state.masterProtocols}
          companyId={this.props.companyId}
          isOpen={this.state.openProtocolCreate}
          handleClose={() => {
            this.setState({ openProtocolCreate: false });
          }}
          onCreate={(c) => {
            this.setState({
              list: [c, ...this.state.list],
              openProtocolCreate: false,
            });
            window.open(`/protocols/${c._id}`, "non_blank");
          }}
        />
        <Stack spacing={3}>
          {this.renderControlBox()}
          {this.renderGrid()}
        </Stack>
      </div>
    );
  }
}
export default WithAPICall(ListProtocols);
