import React, { useState } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
  Box,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";
import Iconify from "src/components/iconify";
import { Stack } from "@mui/system";
import cogoToast from "cogo-toast";

export const LabReportDataViewer = ({ data_, onUpdate }) => {
  const theme = useTheme();
  const [data, setData] = useState(data_);
  const [editingIndex, setEditingIndex] = useState(null);
  const [hasChange, setHasChange] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const handleEdit = (index) => {
    setEditingIndex(index);
  };
  const handleSave = () => {
    setIsSaving(true);
    onUpdate(data);
    setIsSaving(false);
    setHasChange(false);
  };
  let saveElem = null;
  if (hasChange && !isSaving) {
    saveElem = (
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="overline">Unsaved Changes</Typography>
        <Button
          variant="contained"
          onClick={handleSave}
          startIcon={<Iconify icon="bx:save" />}
        >
          Save
        </Button>
      </Stack>
    );
  }
  if (isSaving) {
    saveElem = (
      <Stack direction="row" spacing={2}>
        <Typography variant="overline">Saving...</Typography>
        <Iconify icon="pulse-3" />
      </Stack>
    );
  }

  const handleChange = (field, value) => {
    if (!onUpdate) {
      cogoToast.error("Updates are restricted.");
      return;
    }
    const updatedData = { ...data, [field]: value };
    setData(updatedData);
    setHasChange(true);
    setIsSaving(false);
  };

  const toggleAbnormal = (index) => {
    const updatedItems = [...data.items];
    updatedItems[index] = {
      ...updatedItems[index],
      isAbnormal: !updatedItems[index].isAbnormal,
    };
    setData({ ...data, items: updatedItems });
  };

  const getTooltipText = (item) => {
    return item.isAbnormal
      ? "This result is potentially abnormal. Click to mark it as normal."
      : "This result is normal. Click to mark it as potentially abnormal.";
  };

  const handleUpdate = () => {
    onUpdate(data);
  };

  const renderHeader = () => {
    const abnormalItems = data.items.filter((item) => item.isAbnormal);
    if (abnormalItems.length === 0) {
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">Lab Results</Typography>
          {saveElem}
        </Box>
      );
    }

    const abnormalItemNames = abnormalItems
      .map((item) => item.parameter)
      .join(", ");

    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{
          breakAfter: "avoid",
        }}
      >
        <Box display="flex" alignItems="center">
          <Iconify
            icon="mdi:warning"
            sx={{ mr: 1, color: theme.palette.error.main }}
          />
          <Typography variant="subtitle1">
            Lab Report | Red Flags: {abnormalItemNames}
          </Typography>
        </Box>
        {saveElem}
      </Box>
    );
  };

  return (
    <div
      style={{
        backgroundColor: hasChange ? theme.palette.warning.lighter : "inherit",
      }}
    >
      <Card
        sx={{
          p: 2,
          m: 1,
          bgcolor: (data.items || []).some((item) => item.isAbnormal)
            ? theme.palette.error.light
            : theme.palette.info.lighter,
        }}
      >
        {renderHeader()}
      </Card>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Parameter</TableCell>
              <TableCell>Result</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell>Reference Range</TableCell>
              <TableCell>Notes</TableCell>
              {onUpdate && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.items.map((item, index) => (
              <TableRow
                key={index}
                sx={{
                  bgcolor: item.isAbnormal
                    ? theme.palette.warning.light
                    : "inherit",
                }}
              >
                <TableCell>
                  <Box display="flex" alignItems="center">
                    {item.parameter}
                    <Tooltip title={getTooltipText(item)} arrow>
                      <Box
                        component="span"
                        sx={{
                          display: "inline-flex",
                          ml: 1,
                          cursor: "pointer",
                        }}
                      >
                        <Iconify
                          icon={
                            item.isAbnormal ? "ep:warning-filled" : "mdi:tick"
                          }
                          onClick={() => toggleAbnormal(index)}
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                </TableCell>
                <TableCell>
                  {editingIndex === index ? (
                    <TextField
                      fullWidth
                      size="small"
                      value={item.result || ""}
                      onChange={(e) =>
                        handleChange(index, "result", e.target.value)
                      }
                    />
                  ) : (
                    item.result
                  )}
                </TableCell>
                <TableCell>
                  {editingIndex === index ? (
                    <TextField
                      fullWidth
                      size="small"
                      value={item.unit || ""}
                      onChange={(e) =>
                        handleChange(index, "unit", e.target.value)
                      }
                    />
                  ) : (
                    item.unit
                  )}
                </TableCell>
                <TableCell>
                  {editingIndex === index ? (
                    <TextField
                      fullWidth
                      size="small"
                      value={item.reference_range || ""}
                      onChange={(e) =>
                        handleChange(index, "reference_range", e.target.value)
                      }
                    />
                  ) : (
                    item.reference_range
                  )}
                </TableCell>
                <TableCell>
                  {editingIndex === index ? (
                    <TextField
                      fullWidth
                      size="small"
                      value={item.notes || ""}
                      onChange={(e) =>
                        handleChange(index, "notes", e.target.value)
                      }
                    />
                  ) : (
                    item.notes
                  )}
                </TableCell>
                {onUpdate && (
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() =>
                        editingIndex === index
                          ? handleSave(index)
                          : handleEdit(index)
                      }
                    >
                      <Iconify
                        icon={
                          editingIndex === index
                            ? "fluent:save-24-regular"
                            : "fluent:edit-24-regular"
                        }
                      />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
