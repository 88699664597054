import React from "react";
import {
  Paper,
  IconButton,
  Typography,
  Grid,
  Button,
  Stack,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Iconify from "src/components/iconify/iconify";
import moment from "moment";

class ModalHOC extends React.Component {
  state = {
    isOpened: false,
    dates: [moment(), moment().add(7, "days")],
  };
  render() {
    const { children } = this.props;

    // Create a new child element with the additional props
    const childWithProps = React.cloneElement(children, {
      from: this.state.dates[0],
      to: this.state.dates[1],
    });
    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          open={this.state.isOpened}
          onClose={() => {
            this.setState({ isOpened: false });
          }}
        >
          <DialogTitle>
            <Grid container>
              <Grid item xs={12}>
                {this.props.header}
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent sx={{ padding: "2rem" }}>
            {childWithProps}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({ isOpened: false });
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Paper
          // variant="outlined"
          elevation={2}
          sx={{
            padding: "1rem",
            border: "1px solid #e3e3e3c2",
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs={10}>
              <Typography variant="subtitle1">{this.props.header}</Typography>
            </Grid>
            <Grid
              item
              xs={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <IconButton
                onClick={() => {
                  this.setState({ isOpened: true });
                }}
                sx={{
                  marginLeft: "auto",
                }}
              >
                <Iconify icon="ci:expand" />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              {childWithProps}
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}

export default ModalHOC;
