// 'use client';
import { LineChart } from "@tremor/react";
import { WithAPICall } from "../utils/apiUtil";
import { groupBy } from "lodash";
import { useState, useEffect, useContext } from "react";
import structureData from "./utils/massage-data";
import HOCContext from "./contexts/HOCContext";

const data = [
  {
    date: "Jan 23",
    VisitSchedule: 232,
    VisitDocumentChase: 0,
    ProcessDocument: 49,
    PagesProcessedByAI: 100,
  },
  {
    date: "Feb 23",
    VisitSchedule: 241,
    VisitDocumentChase: 0,
    ProcessDocument: 61,
    PagesProcessedByAI: 200,
  },
  {
    date: "Mar 23",
    VisitSchedule: 291,
    VisitDocumentChase: 0,
    ProcessDocument: 55,
    PagesProcessedByAI: 250,
  },
  {
    date: "Apr 23",
    VisitSchedule: 101,
    VisitDocumentChase: 0,
    ProcessDocument: 21,
    PagesProcessedByAI: 300,
  },
  {
    date: "May 23",
    VisitSchedule: 318,
    VisitDocumentChase: 0,
    ProcessDocument: 66,
    PagesProcessedByAI: 350,
  },
  {
    date: "Jun 23",
    VisitSchedule: 205,
    VisitDocumentChase: 0,
    ProcessDocument: 69,
    PagesProcessedByAI: 400,
  },
  {
    date: "Jul 23",
    VisitSchedule: 372,
    VisitDocumentChase: 0,
    ProcessDocument: 55,
    PagesProcessedByAI: 450,
  },
  {
    date: "Aug 23",
    VisitSchedule: 341,
    VisitDocumentChase: 0,
    ProcessDocument: 74,
    PagesProcessedByAI: 500,
  },
  {
    date: "Sep 23",
    VisitSchedule: 387,
    VisitDocumentChase: 120,
    ProcessDocument: 190,
    PagesProcessedByAI: 550,
  },
  {
    date: "Oct 23",
    VisitSchedule: 220,
    VisitDocumentChase: 0,
    ProcessDocument: 89,
    PagesProcessedByAI: 600,
  },
  {
    date: "Nov 23",
    VisitSchedule: 372,
    VisitDocumentChase: 0,
    ProcessDocument: 44,
    PagesProcessedByAI: 650,
  },
  {
    date: "Dec 23",
    VisitSchedule: 321,
    VisitDocumentChase: 0,
    ProcessDocument: 93,
    PagesProcessedByAI: 700,
  },
];

const valueFormatter = (number) =>
  `${Intl.NumberFormat("us").format(number).toString()}`;

export default WithAPICall(function TasksAiSystem({ apiCallPost }) {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const { from, to, companyIds } = useContext(HOCContext);

  useEffect(() => {
    const init = async () => {
      try {
        const { massagedData, categories } = await structureData(
          "/dashboard/tasksCompleted",
          apiCallPost,
          from,
          to,
          companyIds
        );
        setData(massagedData);
        setCategories(categories);
      } catch (error) {
        console.error("could not get data", error);
      }
    };
    init();
  }, [from, to, companyIds]);

  return (
    <>
      <LineChart
        data={data}
        index="date"
        categories={categories}
        colors={["blue", "violet", "fuchsia"]}
        valueFormatter={valueFormatter}
        showLegend={false}
        showYAxis={false}
        startEndOnly={true}
        className="mt-6 h-32"
      />
      <ul role="list" className="mt-6 flex gap-10">
        {/* <li>
          <div className="flex items-center space-x-1.5">
            <span
              className="h-2.5 w-2.5 rounded-sm bg-tremor-brand dark:bg-dark-tremor-brand"
              aria-hidden={true}
            />
            <p className="text-tremor-label text-tremor-content dark:text-dark-tremor-content">
              Tasks completed by System, YTD
            </p>
          </div>
          <div className="flex items-center space-x-1.5">
            <p className="mt-0.5 text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              -
            </p>
          </div>
        </li> */}
        {/* <li>
          <div className="flex items-center space-x-1.5">
            <span
              className="h-2.5 w-2.5 rounded-sm bg-cyan-500"
              aria-hidden={true}
            />
            <p className="text-tremor-label text-tremor-content dark:text-dark-tremor-content">
              Pages processed by AI, YTD
            </p>
          </div>
          <div className="flex items-center space-x-1.5">
            <p className="mt-0.5 text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              -
            </p>
          </div>
        </li> */}
      </ul>
    </>
  );
});
