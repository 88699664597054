import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Alert,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Card,
  Grid,
  Stack,
  Button,
  Chip,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import VisitEachProtocol from "./VisitEachProtocol";
import { groupBy } from "lodash";
import Iconify from "src/components/iconify/iconify";
import TokenContext from "src/TokenContext";
import cogoToast from "cogo-toast";
import { LoadingButton } from "@mui/lab";
import _ from "lodash";

const ICONS = {
  Exam: "healthicons:i-exam-multiple-choice",
  "Drug and Alcohol": "mdi:drugs",
  Injections: "mdi:injection",
  "Other Medical Services": "icon-park-solid:other",
  "Not Categorized": "material-symbols-light:other-admission-sharp",
};

class VisitChooseProtocols extends React.Component {
  static contextType = TokenContext;
  state = {
    canEditProtocol: true,
    isRequestingVisit: false,
    serviceDetails: [],
    isLoading: false,
    loadingServiceDetails: false,
  };

  componentDidMount() {
    if (this.props.visit.isFromPoolDraw) {
      this.setState({ canEditProtocol: false });
    }
    this.getServiceDetails();
  }

  getServiceDetails = async () => {
    this.setState({ loadingServiceDetails: true });
    try {
      this.setState({
        isError: false,
        serviceDetails: this.props.servicesInfo,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isError: true,
        loadingServiceDetails: false,
      });
    }
    this.setState({ loadingServiceDetails: false });
  };

  renderProtocolList = () => {
    let protocolList = this.props.companyProtocols;
    if (!protocolList || !protocolList.length) {
      return <Alert severity="info">No Protocols Found</Alert>;
    }

    // if (this.props.visit.isFromPoolDraw) {
    //   let selectedProtocols = [];
    //   const companyProtocolIds = protocolList.map((p) => p._id);
    //   for (const service of this.props.visit.services) {
    //     if (companyProtocolIds.includes(service.parentProtocolId)) {
    //       selectedProtocols.push(
    //         protocolList.find((p) => p._id === service.parentProtocolId)
    //       );
    //     }
    //   }
    //   protocolList = selectedProtocols;
    //   // this.setState({ canEditProtocol: false });
    // }

    const groupedProtocolList = groupBy(
      protocolList,
      (val) => val.companyProtocolCategory || "Not Categorized"
    );

    const getNumberOfServicesBookedInCategory = (category) => {
      if (!Array.from(Object.keys(groupedProtocolList)).includes(category))
        return;
      let count = 0;
      for (const protocol of groupedProtocolList[category]) {
        for (const service of this.props.services) {
          if (
            this.props.visit.serviceParentProtocols[service] === protocol._id
          ) {
            count += 1;
          }
        }
      }
      return count;
    };

    return (
      <Stack rowGap={1} columnGap={2} display="flex" flexDirection="column">
        {Object.keys(groupedProtocolList).map((protocolCategory, cId) => (
          <Card key={cId}>
            <Accordion>
              <AccordionSummary
                expandIcon={<Iconify icon="gridicons:dropdown" />}
                aria-controls={`panel${cId}-content`}
                id={`panel${cId}-header`}
                sx={{
                  border: "1px solid #dfdfdf",
                  borderRadius: "16px",
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{ width: "100%" }}
                >
                  <Iconify
                    icon={ICONS[protocolCategory || "Not Categorized"]}
                  />
                  <Typography variant="subtitle1">
                    {protocolCategory || "Not Categorized"}
                  </Typography>
                  {getNumberOfServicesBookedInCategory(
                    protocolCategory || "Not Categorized"
                  ) > 0 && (
                    <Chip
                      size="small"
                      variant="outlined"
                      icon={<Iconify icon="ph:notification-fill" />}
                      label={`${getNumberOfServicesBookedInCategory(protocolCategory || "Not Categorized")} services selected`}
                      sx={{
                        marginLeft: "auto",
                      }}
                    />
                  )}
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                {groupedProtocolList[protocolCategory].map(
                  (eachProtocol, index) => (
                    <VisitEachProtocol
                      key={index}
                      isSaving={this.props.isSaving}
                      protocol={eachProtocol}
                      visit={this.props.visit}
                      employee={this.props.employee}
                      protocols={this.props.protocols}
                      services={this.props.services}
                      addProtocol={(protocolId, bookedServices) => {
                        this.props.addProtocol(protocolId, bookedServices);
                      }}
                      removeProtocol={(protocolId) => {
                        this.props.removeProtocol(protocolId);
                      }}
                      canEditProtocol={this.state.canEditProtocol}
                    />
                  )
                )}
              </AccordionDetails>
            </Accordion>
          </Card>
        ))}
      </Stack>
    );
  };
  handleVisitRequest = async () => {
    this.setState({
      isRequestingVisit: true,
    });
    await this.props.updateVisitScalars([
      {
        key: "requestedByEmployer",
        value: true,
      },
    ]);
    cogoToast.success("This visit has been requested.");
    this.setState({
      isRequestingVisit: false,
    });
  };
  render() {
    // const isExternal = this.context.currentUser.isExternal;
    // if (isExternal) {
    //   return (
    //     <Grid container spacing={1} sx={{ height: "100%" }}>
    //       <Grid item xs={12}>
    //         <Stack spacing={2}>
    //           {this.renderProtocolList()}
    //           <Stack direction="row">
    //             <LoadingButton
    //               loading={this.state.isRequestingVisit}
    //               variant="contained"
    //               color="primary"
    //               startIcon={<Iconify icon="ic:round-note-add" />}
    //               sx={{
    //                 width: "250px",
    //                 marginLeft: "auto",
    //               }}
    //               onClick={() => {
    //                 this.handleVisitRequest();
    //               }}
    //             >
    //               Request WorkCare to Schedule
    //             </LoadingButton>
    //           </Stack>
    //         </Stack>
    //       </Grid>
    //     </Grid>
    //   );
    // }
    return (
      <Grid container spacing={1} sx={{ height: "100%" }}>
        <Grid item xs={9}>
          <Stack spacing={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="overline" sx={{ mt: 1, mb: 1 }}>
                Selected Services
              </Typography>
              {this.state.loadingServiceDetails && (
                <CircularProgress size="small" />
              )}
              {this.state.serviceDetails.map((service, idx) => (
                <Chip
                  label={service.serviceName}
                  key={idx}
                  variant="outlined"
                />
              ))}
              {this.state.canEditProtocol == false && (
                <Typography variant="caption" sx={{ mt: 1, mb: 1 }}>
                  <b>
                    [Protocol/Service selection is locked: This visit is for a
                    Random Pooled draw.]
                  </b>
                </Typography>
              )}
            </Stack>
            {this.renderProtocolList()}
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Card
            sx={{
              height: "100%",
              p: 2,
            }}
          >
            <Typography variant="h6">Services Due</Typography>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default WithAPICall(VisitChooseProtocols);
