import { m } from "framer-motion";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { RouterLink } from "src/routes/components";

import CompactLayout from "src/layouts/compact";
import { SeverErrorIllustration } from "src/assets/illustrations";

import { varBounce, MotionContainer } from "src/components/animate";

// ----------------------------------------------------------------------

export default function Page500() {
  return (
    <CompactLayout>
      <MotionContainer>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            We ran into an error.
          </Typography>
        </m.div>
        <m.div variants={varBounce().in}>
          <Typography sx={{ color: "text.secondary" }}>
            We apologise. There seems to be an error here. We have been notified
            and will fix it as soon as possible.
          </Typography>
        </m.div>
        <m.div variants={varBounce().in}>
          <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
        <Button
          component={RouterLink}
          href="/"
          size="large"
          variant="contained"
        >
          Main Dashboard
        </Button>{" "}
        <Button
          onClick={() => {
            window.location.reload();
          }}
          size="large"
          variant="contained"
        >
          Refresh & Try Again
        </Button>
      </MotionContainer>
    </CompactLayout>
  );
}
