import React from "react";
import cogoToast from "cogo-toast";
import {
  Card,
  Stack,
  Typography,
  Box,
  Alert,
  Paper,
  Grid,
  IconButton,
  Drawer,
} from "@mui/material";
import DocumentGroup from "../Documents/DocumentGroup";
import { WithAPICall } from "../utils/apiUtil";
import _ from "lodash";
import { LoadingScreen } from "src/components/loading-screen";
import { Container } from "@mui/system";
import Iconify from "src/components/iconify/iconify";
import { format } from "date-fns";

class VisitDocuments extends React.Component {
  state = {
    isLoading: false,
    showDrawer: false,
    selectedHistoricalGroups: [],
  };
  componentDidMount() {
    this.getGroupsByVisit();
  }
  getGroupsByVisit = async () => {
    this.setState({ isLoading: true });
    try {
      let res = await this.props.apiCallPost("/files/groups/search", {
        visitId: this.props.id,
      });

      let resHistorical = await this.props.apiCallPost("/files/groups/search", {
        employeeId: this.props.visitData.employee._id,
      });

      this.setState({
        groups: res,
        groupsHistorical: resHistorical,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      cogoToast.error("Error getting data");
      this.setState({
        isError: true,
      });
    }
    this.setState({ isLoading: false });
  };
  renderGroups = () => {
    if (!this.state.groups || !this.state.groups.length) {
      return <Alert severity="info">No Documents Attached.</Alert>;
    }

    let gg = this.state.groups;
    let ggSorted = _.sortBy(gg, "extractedEEName");
    return (
      <Card sx={{ pt: 3, pb: 3, px: 3, boxShadow: 3 }}>
        <Stack spacing={2}>
          <Typography variant="button">Processed Groups</Typography>
          <Box
            rowGap={2}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(1, 1fr)",
              md: "repeat(1, 1fr)",
              lg: "repeat(1, 1fr)",
              xl: "repeat(1, 1fr)",
            }}
          >
            {ggSorted.map((g, index) => {
              return (
                <Stack key={index}>
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <IconButton
                      onClick={() => {
                        const selectedHistoricalGroups =
                          this.state.groupsHistorical.filter((h) => {
                            return (
                              h.pageDataType === g.pageDataType &&
                              h._id !== g._id
                            );
                          });
                        this.setState({
                          selectedHistoricalGroups: _.reverse(
                            _.sortBy(
                              selectedHistoricalGroups,
                              (g) => g.visit.providerEncounterOnDate
                            )
                          ),
                          showDrawer: true,
                        });
                      }}
                    >
                      <Iconify icon="mdi:clock" />
                    </IconButton>
                    <Typography variant="h6">
                      {g.service?.serviceName} - {g.pageDataType}
                    </Typography>
                  </Stack>
                  <DocumentGroup
                    data={g}
                    groupIx={index}
                    fromVisitPage={true}
                    showDocumentHeader={false}
                    cfId={g._id}
                    defaultCollapsed={false}
                    isHistorical={true}
                    hideLinkToVisit={true}
                  />
                </Stack>
              );
            })}
          </Box>
        </Stack>
      </Card>
    );
  };

  renderHistoricalGroupsDrawer = () => {
    return (
      <Drawer
        open={this.state.showDrawer}
        onClose={() => {
          this.setState({ showDrawer: false });
        }}
        anchor="right"
      >
        <Paper
          style={{
            width: "95vw",
            height: "100vh",
            overflowY: "auto",
            padding: "5px",
            bgcolor: "background.paper",
          }}
        >
          <Grid
            container
            spacing={0.5}
            width="95vw"
            sx={{
              padding: "1rem",
              height: "100%",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                maxHeight: "100vh",
                overflowY: "auto",
              }}
            >
              {this.state.selectedHistoricalGroups.map((g, index) => {
                return (
                  <>
                    <Typography variant="h6">
                      {g.service?.serviceName} - {g.pageDataType} [
                      {format(new Date(g.visit.providerEncounterOnDate), "PP")}]
                    </Typography>
                    <DocumentGroup
                      // showDocumentHeader={false}
                      // data={g}
                      // groupIx={index}
                      hideActions={true}
                      // key={g._id}
                      // cfId={g._id}
                      // employeeId={this.props.visitData.employee._id}

                      key={index}
                      data={g}
                      groupIx={index}
                      // fromVisitPage={true}
                      showDocumentHeader={false}
                      cfId={g._id}
                      defaultCollapsed={false}
                      // showDate={true}
                      isHistorical={true}
                      // hideLinkToVisit={true}
                    />
                  </>
                );
              })}
              {this.state.selectedHistoricalGroups.length === 0 && (
                <Alert severity="info">No historical data present.</Alert>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Drawer>
    );
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingScreen />;
    }
    return (
      <>
        {this.renderHistoricalGroupsDrawer()}
        {this.renderGroups()}
      </>
    );
    // return <>{this.renderHistoricalGroups()}</>;
  }
}

export default WithAPICall(VisitDocuments);
