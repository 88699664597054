/* eslint-disable no-case-declarations */
import {
  Button,
  Card,
  Chip,
  Drawer,
  IconButton,
  LinearProgress,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Alert,
  AlertTitle,
  FormControlLabel,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";
import cogoToast from "cogo-toast";
import _, { cloneDeep } from "lodash";
import React from "react";
import TokenContext from "src/TokenContext";
import Iconify from "src/components/iconify";
import { View500 } from "src/sections/error";
import { addUrlParams, getUrlParams } from "src/utils/url-params";
// import EmailThreads from "../../Emails/EmailThreads";
import { WithAPICall } from "../../utils/apiUtil";
import PostVisitCompleted from "../PostVisitCompleted";
import VisitAppointmentTime from "../VisitAppointmentTime";
import VisitChooseProtocols from "../VisitChooseProtocols";
import VisitDocuments from "../VisitDocuments";
import VisitEmployeeLocation from "../VisitEmployeeLocation";
import VisitFinance from "../VisitFinance";
import VisitGroupServices from "../VisitGroupServices";
import VisitNotes from "../VisitNotes";
import VisitPagesOfFile from "../VisitPagesOfFile";
import VisitProtocolData from "../VisitProtocolData";
import VisitReviewSummarySchedule from "../VisitReviewSummarySchedule";
import VisitTimeline from "../VisitTimeline";
import VisitTimelineEventCreate from "../VisitTimelineEventCreate";
import { varBounce, MotionContainer } from "src/components/animate";
import { m } from "framer-motion";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import ServiceQPreview from "src/micro/Services/ServiceQPreview";
import VisitReviewSummaryScheduleEE from "./VisitReviewSummaryScheduleEE";
import TextareaAutosize from "src/components/text-area-autosize";
import { groupBy } from "lodash";
import format from "date-fns/format";
import axios from "axios";
import { DOCUMENT_GENERATOR_URL } from "src/config-global";
import { LoadingButton } from "@mui/lab";

function substituteVariables(template, variables) {
  return template.replace(/{{\s*([^}]+)\s*}}/g, (_, key) => {
    return variables[key.trim()] || "";
  });
}

function copyToClipboard(text) {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log(`Copied to clipboard: ${text}`);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
    cogoToast.success("Copied to clipboard.");
  } else {
    console.error("Clipboard API not supported");
  }
}

class VisitDetailsEE extends React.Component {
  static contextType = TokenContext;

  state = {
    isLoading: true,
    isError: false,
    isSaving: false,
    currentTab: "SCHEDULING",
    drawerFileId: null,
    companyProtocols: [],
    bookedProtocolIds: [],
    bookedServices: [],
    processes: [],
    clinicFrozen: false,
    clinicPSLs: [],
    labFrozen: false,
    labPSLs: [],
    eeActiveStep: 0,
    templates: [],
    isNewLogOpen: false,
    loadingClearancePdf: false,
    loadingPatientLetter: false,
    openEmployeeLocationModal: false,
    bookedServicesDetailed: [],
    bookedProtocolsDetailed: [],
    drawerQuestionnaireId: null,
    isDownloadingAuthLetter: false,
  };

  STEPS = [
    {
      label: "Confirm Your Location",
      description: `Confirm or update your location`,
    },
    {
      label: "Confirm Date & Time",
      description: "Let us know your preferred date and time of visit",
      previous: "Confirm Your Location",
    },
    {
      label: "Questionnaire(s)",
      description: "Answer any questionnaire(s) required.",
      previous: "Confirm Date & Time",
    },
  ];

  async componentDidMount() {
    await this.getById();

    const urlParams = getUrlParams();
    if (urlParams.currentTab) {
      this.setState({ currentTab: urlParams.currentTab });
    }
  }
  getVisitPopulates = async () => {
    try {
      let res = await this.props.apiCallPost(
        "/ext-employee/getVisitPopulates",
        {
          visitId: this.props.id,
        }
      );
      this.setState({
        bookedServicesDetailed: res.bookedServices,
        bookedProtocolsDetailed: res.bookedProtocols,
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Visit Data");
    }
  };

  getById = async () => {
    try {
      let res = await this.props.apiCallPost("/visit/getVisitById", {
        visitId: this.props.id,
      });
      this.setState({
        isError: false,
        ...res,
      });
      await this.getVisitPopulates();
      this.setState({
        isLoading: false,
      });
    } catch (err) {
      cogoToast.error("Error Loading Visit Data");
      console.log(err);
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  loadEmailTemplates = async () => {
    try {
      const res = await this.props.apiCallGet("/email/templates");
      this.setState({ templates: res.templates });
    } catch (error) {
      cogoToast.error("Could not retrieve templates. Please try again.");
      this.setState({ error: true });
    }
  };
  companyProtocolGetAll = async () => {
    try {
      let res = await this.props.apiCallPost(
        "/company-protocol/companyProtocolGetAll",
        {
          companyId: this.state.company._id,
        }
      );
      this.setState(
        {
          isLoading: false,
          isError: false,
          companyProtocols: res,
        },
        () => {
          this.doesModifiedEmployeeSatisfyAnyProtocols();
        }
      );
    } catch (err) {
      cogoToast.error("Error Loading Company Protocol Data");
      console.log(err);
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  doesModifiedEmployeeSatisfyAnyProtocols = async () => {
    try {
      let res = await this.props.apiCallPost(
        "/employee/doesModifiedEmployeeSatisfyAnyProtocols",
        {
          employeeId: this.state.employee._id,
          companyProtocolType: "Periodic",
          modSpec: {},
        }
      );

      this.setState({
        isLoading: false,
        isError: false,
      });
    } catch (err) {
      cogoToast.error("Error Loading Company Protocol Data");
      console.log(err);
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  updateVisitScalars = async (arr, showNotification = true) => {
    this.setState({
      isSaving: true,
    });
    try {
      let res = await this.props.apiCallPost("/visit/updateVisitScalars", {
        visitId: this.props.id,
        uops: arr,
      });
      if (showNotification) {
        cogoToast.success("Visit Data Saved");
      }
      this.setState({
        isSaving: false,
        ...res,
      });
    } catch (err) {
      cogoToast.error("Error Saving Visit Data");
      console.log(err);
      this.setState({
        isSaving: false,
      });
    }
  };

  renderQuestionnaire = () => {
    let bookedServicesDetailed = this.state.bookedServicesDetailed;
    if (!bookedServicesDetailed) {
      return null;
    }
    if (!bookedServicesDetailed.length) {
      return null;
    }
    let questionnaireServices = _.filter(
      bookedServicesDetailed,
      (s) => s.serviceType === "QUESTIONNAIRE"
    );
    if (!questionnaireServices.length) {
      return null;
    }
    let l = questionnaireServices.length;
    let qS = l === 1 ? "questionnaire" : "questionnaires";
    let headerElem = (
      <Alert severity="info">
        <AlertTitle>Questionnaires</AlertTitle>
        As part of this visit, you need to answer a few questions relating to
        your health, so that we can make an assessment. You can answer these
        questions any time before your visit; but we recommend that you do so as
        early as possible, so that we can avoid any last minute delays.
      </Alert>
    );
    let qElem = (
      <Alert severity="info">
        You have {l} {qS} to answer as part of this visit.
      </Alert>
    );

    return (
      <Stack spacing={2}>
        {headerElem}
        {qElem}
        {questionnaireServices.map((q, index) => {
          let qId = q._id;
          let idxInBookedServices = _.findIndex(
            this.state.bookedServices,
            (s) => s.serviceId === qId
          );
          let isCompleted = false;
          if (idxInBookedServices > -1) {
            isCompleted =
              this.state.bookedServices[idxInBookedServices].hasReceivedAllData;
          }

          let completedElem = null;
          if (isCompleted) {
            completedElem = <Chip label="Completed" color="success" />;
          }
          let buttonText = isCompleted ? "View Answers" : "Start Answering";
          return (
            <Stack
              direction="row"
              alignItems={"center"}
              spacing={1}
              key={index}
            >
              <Typography variant="overline">{q.serviceName}</Typography>
              {completedElem}
              <Typography
                variant="caption"
                onClick={() => {
                  console.log("opening ques", q);
                  this.setState({ drawerQuestionnaireId: q._id });
                }}
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                {buttonText}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    );
  };
  renderQuestionnaireDrawer = () => {
    if (!this.state.drawerQuestionnaireId) {
      return null;
    }
    let id = this.state.drawerQuestionnaireId;
    let svc = _.find(this.state.bookedServicesDetailed, (s) => s._id === id);

    let idxInBookedServices = _.findIndex(
      this.state.bookedServices,
      (s) => s.serviceId === id
    );
    let isCompleted = false;

    if (idxInBookedServices > -1) {
      isCompleted =
        this.state.bookedServices[idxInBookedServices].hasReceivedAllData;
    }

    return (
      <Drawer
        anchor="right"
        open={this.state.drawerQuestionnaireId}
        onClose={() => {
          this.setState({ drawerQuestionnaireId: null });
        }}
      >
        <ServiceQPreview
          data={svc.questionnaireTemplate}
          serviceData={svc}
          isPreview={false}
          visitId={this.state._id}
          isCompleted={isCompleted}
          onSubmit={() => {
            // mark has received all data
            if (idxInBookedServices > -1) {
              let bookedServices = this.state.bookedServices;
              bookedServices[idxInBookedServices].hasReceivedAllData = true;
              this.setState({ bookedServices, drawerQuestionnaireId: null });
            } else {
              this.setState({ drawerQuestionnaireId: null });
            }
          }}
        />
      </Drawer>
    );
  };
  renderSteps = () => {
    const isExternal = this.context.currentUser.isExternal;
    let steps = this.STEPS;
    return (
      <Card sx={{ p: 2 }}>
        <Stepper activeStep={this.state.eeActiveStep} orientation="horizontal">
          {steps.map((step, index) => {
            return (
              <Step key={index}>
                <StepLabel>
                  <Typography variant="overline">{step.label}</Typography>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {/* <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            mt: 2,
          }}
        >
          <Button
            size="small"
            disabled={!this.STEPS[this.state.eeActiveStep].previous}
            onClick={() => {
              this.setState(
                {
                  eeActiveStep: this.state.eeActiveStep - 1,
                },
                () => {
                  this.updateVisitScalars([
                    {
                      key: "eeActiveStep",
                      value: this.state.eeActiveStep,
                    },
                  ]);
                }
              );
            }}
            variant="outlined"
            startIcon={<Iconify icon="ooui:previous-ltr" />}
          >
            {this.STEPS[this.state.eeActiveStep].previous || "Previous"}
          </Button>
          <Button
            size="small"
            disabled={!this.STEPS[this.state.eeActiveStep].next}
            onClick={() => {
              this.setState(
                {
                  eeActiveStep: this.state.eeActiveStep + 1,
                },
                () => {
                  this.updateVisitScalars([
                    {
                      key: "eeActiveStep",
                      value: this.state.eeActiveStep,
                    },
                  ]);
                }
              );
            }}
            variant="outlined"
            endIcon={<Iconify icon="ooui:next-ltr" />}
          >
            {this.STEPS[this.state.eeActiveStep].next || "Next"}
          </Button>
        </Stack> */}
      </Card>
    );
  };
  renderBasedOnStepIndex = () => {
    let stepIdx = this.state.eeActiveStep;
    return <></>;
  };
  renderVisitInformation = () => {
    let employeeName = this.state.employee?.employeeName;
    let companyName = this.state.company?.companyName;
    let employeeId = this.state.employee?._id;
    let companyId = this.state.company?._id;
    let createdByName = this.state.createdBy?.name;
    let createdByEmail = this.state.createdBy?.email;

    let createdByElem = (
      <Tooltip title={createdByEmail}>
        <Chip
          label={`Created By: ${createdByName}`}
          size="small"
          variant="outlined"
        />
      </Tooltip>
    );
    let companyElem = (
      <Button
        variant="large"
        href={`/employers/${companyId}`}
        // target="non_blank"
        endIcon={<Iconify icon="mdi:arrow-top-right" />}
      >
        {companyName}
      </Button>
    );

    let employeeElem = (
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2">{employeeName}</Typography>
        <IconButton target="_blank" href={`/employees/${employeeId}`}>
          <Iconify icon="mdi:arrow-top-right" />
        </IconButton>
      </Stack>
    );
    let visitStatusElem = (
      <Tooltip title="Visit Status">
        <Chip
          label={`${_.startCase(_.lowerCase(this.state.visitStatus))}`}
          // size="small"
          variant="outlined"
        />
      </Tooltip>
    );
    return (
      <Stack spacing={2} direction="row" alignItems={"center"}>
        <Button
          size="small"
          variant="outlined"
          startIcon={<Iconify icon="ph:copy" />}
          onClick={() => {
            copyToClipboard(this.state.humanReadableId);
          }}
        >
          Visit ID: {this.state.humanReadableId || "WC-123456"}
        </Button>
      </Stack>
    );
  };
  onEmailSent = (thread) => {
    this.setState({ isNewLogOpen: true });
  };
  tabs = [
    {
      value: "SCHEDULING",
      label: "Scheduling",
      icon: <Iconify icon="solar:calendar-broken" width={24} />,
      showExternal: true,
    },
    {
      value: "DATA",
      label: "Data",
      icon: <Iconify icon="carbon:report-data" width={24} />,
      showExternal: true,
    },
    {
      value: "NOTES",
      label: "Notes",
      icon: <Iconify icon="solar:document-add-linear" width={24} />,
    },
    // {
    //   value: "EMAIL",
    //   label: "Emails",
    //   icon: <Iconify icon="ic:outline-email" width={24} />,
    // },
    {
      value: "DOCUMENTS",
      label: "Documents",
      icon: <Iconify icon="solar:document-linear" width={24} />,
      showExternal: true,
    },
    {
      value: "FINANCE",
      label: "Finance",
      icon: <Iconify icon="solar:money-bag-outline" width={24} />,
    },
    {
      value: "TIMELINE",
      label: "Timeline",
      icon: <Iconify icon="gravity-ui:list-timeline" width={24} />,
      showExternal: true,
    },
  ];
  renderTabs = () => {
    let tabs = this.tabs;
    if (this.context.currentUser.isExternal) {
      tabs = this.tabs.filter((t) => t.showExternal);
    }
    return (
      <Tabs
        value={this.state.currentTab}
        onChange={(_, value) => {
          addUrlParams({ currentTab: value });
          this.setState({ currentTab: value });
        }}
        sx={{
          mb: { xs: 1, md: 1 },
        }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            icon={tab.icon}
            value={tab.value}
          />
        ))}
      </Tabs>
    );
  };
  renderTab = () => {
    switch (this.state.currentTab) {
      case "SCHEDULING":
        return this.renderSchedulingTab();
      case "NOTES":
        return <VisitNotes id={this.props.id} />;
      case "DATA":
        return <VisitProtocolData id={this.props.id} visitData={this.state} />;
      case "TIMELINE":
        return <VisitTimeline id={this.props.id} visitData={this.state} />;
      case "EMAIL":
        console.log(this.state);
        const emailTypes = [
          {
            type: "Employer",
            name: this.state.company.companyName,
            address: this.state.company.companyEmail,
          },
        ];

        if (this.state.employee.employeeOfficeEmail) {
          emailTypes.push({
            type: "Employee",
            name: `${this.state.employee.employeeName}: Office Email`,
            address: this.state.employee.employeeOfficeEmail,
          });
        }
        if (this.state.employee.employeePersonalEmail) {
          emailTypes.push({
            type: "Employee",
            name: `${this.state.employee.employeeName}: Personal Email`,
            address: this.state.employee.employeePersonalEmail,
          });
        }
        this.state.clinicPSLs.forEach((psl) => {
          if (psl.providerObj.providerEmail) {
            emailTypes.push({
              type: "Provider",
              name: psl.providerObj.providerName,
              address: psl.providerObj.providerEmail,
            });
          }
        });

        return (
          <>
            {/* <EmailThreads
              visitId={this.props.id}
              templates={this.state.templates}
              defaultTo={
                this.state.employee.employeePersonalEmail ||
                this.state.employee.employeeOfficeEmail
              }
              onEmailSent={this.onEmailSent}
              emailTypes={emailTypes}
            /> */}
            <VisitTimelineEventCreate
              visitId={this.props.id}
              isOpen={this.state.isNewLogOpen}
              handleClose={() => {
                // this.setState({ isNewLogOpen: false });
              }}
              onCreate={(event) => {
                this.setState({ isNewLogOpen: false });
                // this.setState({
                //   events: [...this.state.events, event],
                //   isNewLogOpen: false,
                // });
              }}
            />
          </>
        );
      case "DOCUMENTS":
        return <VisitDocuments id={this.props.id} />;
      case "FINANCE":
        return <VisitFinance {...this.state} />;
      default:
        return <div>Not In Production</div>;
    }
  };

  renderSchedulingTab = () => {
    if (this.state.visitStatus === "REQUESTED") {
      return (
        <Grid container spacing={1}>
          <Grid xs={12} md={12}>
            {this.renderSteps()}
          </Grid>
          <Grid xs={12} md={12}>
            {this.renderBasedOnStepIndex()}
          </Grid>
        </Grid>
      );
    } else {
      return <VisitReviewSummaryScheduleEE visit={this.state} />;
    }
  };
  renderFileDrawer = () => {
    return (
      <Drawer
        anchor="right"
        open={this.state.drawerFileId ? true : false}
        onClose={() => {
          this.setState({
            drawerFileId: null,
          });
        }}
      >
        <div
          style={{
            width: "90vw",
          }}
        >
          <Card sx={{ p: 2 }}>
            <VisitPagesOfFile fileId={this.state.drawerFileId} />
          </Card>
        </div>
      </Drawer>
    );
  };
  getAuthorizationLetterEmailTemplate = async (templateId) => {
    // const templateId = "665d6edda20ebc966317fdd7";
    try {
      const res = await this.props.apiCallPost("/email/fetchTemplate", {
        templateId,
      });
      return res;
    } catch (error) {
      console.error(error);
    }
  };
  sendAuthorizationLetterEmailForEE = async (psls) => {
    this.setState({ isDownloadingAuthLetter: true });
    const emailTemplate = await this.getAuthorizationLetterEmailTemplate(
      "665d6edda20ebc966317fdd7"
    );
    if (!emailTemplate) {
      cogoToast.error("No email template found for auth letter.");
      this.setState({ isDownloadingAuthLetter: false });
      return;
    }
    const emailHtml = emailTemplate.html;
    const emailBody = substituteVariables(emailHtml, {
      employeeName: this.state.employee.employeeName,
      companyName: this.state.company.companyName,
    });
    const psl = psls[0];
    const emailIds = [];
    const emailId = psl.providerObj.providerEmail;
    if (this.state.employee.employeePersonalEmail) {
      emailIds.push(this.state.employee.employeePersonalEmail);
    }
    if (this.state.employee.employeeOfficeEmail) {
      emailIds.push(this.state.employee.employeeOfficeEmail);
    }
    if (emailIds.length === 0) {
      cogoToast.error("Employee does not have an email added.");
      this.setState({ isDownloadingAuthLetter: false });
      return null;
    } else {
      const data = {
        date: format(new Date(), "PP"),
        encounterNumber: this.state._id,
        employeeName: this.state.employee.employeeName,
        employeeNumber: this.state.employee.employeeNumber || "Not Provided",
        employeeDoB: this.state.employee.employeeDoB || "Not Provided",
        comments: "",
        companyName: this.state.company.companyName,
        apptDate: format(new Date(psl.scheduledOnDate), "PP"),
        apptTime: format(new Date(psl.scheduledTime), "pp"),
        site: psl.providerObj.providerName,
        clientSvcRep: "",
        clinicName: psl.providerObj.providerName,
        address: psl.providerObj.providerAddress,
        phone: psl.providerObj.providerPhone,
        email: psl.providerObj.providerEmail,
        fax: psl.providerObj.providerFax,
        services: [],
        table: {
          headers: ["Service", "Instructions"],
          data: [],
        },
      };
      psls.forEach((psl) => {
        data.services.push({
          name: psl.pslName,
        });
        data.table.data.push([psl.pslName, ""]);
      });
      try {
        const res = await this.props.apiCallPost("/email/auth-letter/email", {
          documentData: data,
          fileId: "1bmcdSWFObp2Si88pDmJi0Lv93t96zkXCjmYH4K2m73U",
          emailIds,
          body: emailBody,
        });
        this.setState({ isDownloadingAuthLetter: false });
        return res;
      } catch (error) {
        console.error("could not send email", error);
      }
    }

    this.setState({ isDownloadingAuthLetter: false });
  };
  getAuthorizationLetters = async (psls) => {
    this.setState({ isDownloadingAuthLetter: true });
    const psl = psls[0];
    const data = {
      date: format(new Date(), "PP"),
      encounterNumber: this.state._id,
      employeeName: this.state.employee.employeeName,
      companyName: this.state.company.companyName,
      apptDate: psl.scheduledOnDate
        ? format(new Date(psl.scheduledOnDate), "PP")
        : "Date not selected",
      apptTime: psl.scheduledTime
        ? format(new Date(psl.scheduledTime), "pp")
        : "Time not selected",
      site: psl.providerObj.providerName,
      clientSvcRep: "",
      clinicName: psl.providerObj.providerName,
      address: psl.providerObj.providerAddress,
      phone: psl.providerObj.providerPhone,
      email: psl.providerObj.providerEmail,
      fax: psl.providerObj.providerFax,
      table: {
        headers: ["Service", "Instructions"],
        data: [],
      },
    };
    psls.forEach(async (psl) => {
      data.table.data.push([psl.pslName, ""]);
    });

    const response = await axios.post(
      DOCUMENT_GENERATOR_URL + "/api/templater/template",
      {
        fileId: "1bmcdSWFObp2Si88pDmJi0Lv93t96zkXCjmYH4K2m73U",
        data,
        convertTo: "pdf",
      },
      {
        responseType: "blob",
      }
    );

    // Create object URL for the blob
    const url = URL.createObjectURL(response.data);

    // Automatically trigger download
    const a = document.createElement("a");
    a.href = url;
    a.download = `${data.clinicName}_${data.employeeName}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.setState({ isDownloadingAuthLetter: false });
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    if (
      [
        "SCHEDULED",
        "AWAITING_DOCUMENTS",
        "DOCUMENTS_RECEIVED",
        "DOCUMENTS_PROCESSED",
        "DOCUMENTS_IN_REVIEW",
        "DOCUMENTS_REVIEWED",
      ].includes(this.state.visitStatus)
    ) {
      const groupedPsls = groupBy(
        this.state.clinicPSLs,
        (p) => p.providerObj.providerName
      );
      console.log({ groupedPsls });
      return (
        <Stack spacing={2}>
          <Typography variant="h4">Visit Details</Typography>
          <Card sx={{ padding: "1rem" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Clinic Name</TableCell>
                  <TableCell>Booked Services</TableCell>
                  <TableCell>Appointment Time</TableCell>
                  <TableCell>Authorization Letter</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from(Object.keys(groupedPsls)).map(
                  (providerName, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Stack spacing={0.5}>
                          <Typography variant="subtitle1">
                            {providerName}
                          </Typography>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={0.5}
                          >
                            <Iconify icon="ic:baseline-phone" />
                            <Typography variant="caption">
                              {
                                groupedPsls[providerName][0].providerObj
                                  .providerPhone
                              }
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={0.5}
                          >
                            <Iconify icon="material-symbols:fax" />
                            <Typography variant="caption">
                              {groupedPsls[providerName][0].providerObj
                                .providerFax || ""}
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={0.5}
                          >
                            <Iconify icon="ic:baseline-email" />
                            <Typography variant="caption">
                              {groupedPsls[providerName][0].providerObj
                                .providerEmail || "No email added for provider"}
                            </Typography>
                          </Stack>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Stack direction="column" spacing={0.5}>
                          {groupedPsls[providerName].map((psl, pIdx) => (
                            <Chip
                              size="small"
                              label={psl.pslName}
                              variant="outlined"
                              key={pIdx}
                            />
                          ))}
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={0.5}>
                          {groupedPsls[providerName][0].scheduledTime
                            ? `${format(
                                new Date(
                                  groupedPsls[providerName][0].scheduledTime
                                ),
                                "Pp"
                              )} ${
                                groupedPsls[providerName][0].providerObj
                                  .providerReadableTimezone
                              }`
                            : "Time Not Selected"}
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Tooltip title="Download Authorization Letter">
                            <LoadingButton
                              onClick={() => {
                                this.getAuthorizationLetters(
                                  groupedPsls[providerName]
                                );
                              }}
                              loading={this.state.isDownloadingAuthLetter}
                            >
                              <Iconify icon="material-symbols:download" />
                            </LoadingButton>
                          </Tooltip>
                          <Tooltip title="Email Authorization Letter">
                            <LoadingButton
                              onClick={() => {
                                this.sendAuthorizationLetterEmailForEE(
                                  groupedPsls[providerName]
                                );
                              }}
                              loading={this.state.isDownloadingAuthLetter}
                            >
                              <Iconify icon="ic:baseline-email" />
                            </LoadingButton>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </Card>
        </Stack>
      );
    }

    return (
      <Stack spacing={2}>
        {this.renderVisitInformation()}
        <Stack spacing={2}>
          <Stack>
            <Dialog
              open={this.state.openEmployeeLocationModal}
              onClose={() => {
                this.setState({ openEmployeeLocationModal: false });
              }}
            >
              <DialogTitle>Employee Location</DialogTitle>
              <DialogContent>
                <VisitEmployeeLocation
                  visit={this.state}
                  isSaving={this.state.isSaving}
                  employee={this.state.employee}
                  onUpdateLocation={async (params) => {
                    let payload = [
                      {
                        key: "employeeAddress",
                        value: params.employeeAddress || "",
                      },
                      {
                        key: "employeeAddressQualilfier",
                        value: params.employeeAddressQualilfier || "",
                      },
                      {
                        key: "employeeCity",
                        value: params.employeeCity || "",
                      },
                      {
                        key: "employeeState",
                        value: params.employeeState || "",
                      },
                      {
                        key: "employeePostalCode",
                        value: params.employeePostalCode || "",
                      },
                      {
                        key: "employeeGeoLat",
                        value: params.employeeGeoLat || "",
                      },
                      {
                        key: "employeeGeoLong",
                        value: params.employeeGeoLong || "",
                      },
                    ];
                    await this.updateVisitScalars(payload);
                    this.setState({
                      openEmployeeLocationModal: false,
                    });
                    cogoToast.success("Location preferences updated.");
                  }}
                />
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
            <Card
              sx={{
                padding: "1rem",
              }}
            >
              <Stack spacing={2}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {" "}
                  <Iconify icon="emojione-monotone:digit-one" />
                  <Typography variant="subtitle1">
                    Confirm your location.
                  </Typography>
                </Stack>

                <Typography variant="body2">
                  Confirming your location helps us schedule your visit at a
                  clinic close to you. This is the location we have on file. You
                  can edit if it is incorrect.
                </Typography>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Address</TableCell>
                      <TableCell>City</TableCell>
                      <TableCell>State</TableCell>
                      <TableCell>Zip Code</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {this.state.employeeAddress ||
                            this.state.employee?.employeeAddress}{" "}
                          <br />{" "}
                          {this.state.employeeAddressQualilfier ||
                            this.state.employee?.employeeAddressQualilfier}{" "}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {this.state.employeeCity ||
                            this.state.employee?.employeeCity}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {this.state.employeeState ||
                            this.state.employee?.employeeState}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {this.state.employeePostalCode ||
                            this.state.employee?.employeePostalCode}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  justifyContent="end"
                  sx={{ width: "100%" }}
                >
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<Iconify icon="material-symbols:edit" />}
                    onClick={() => {
                      this.setState({ openEmployeeLocationModal: true });
                    }}
                    sx={{
                      width: "250px",
                    }}
                  >
                    Edit Location
                  </Button>
                </Stack>
              </Stack>
            </Card>
          </Stack>
          <Card
            sx={{
              padding: "1rem",
            }}
          >
            <Stack spacing={2}>
              <Stack direction="row" alignItems="center" spacing={1}>
                {" "}
                <Iconify icon="emojione-monotone:digit-two" />
                <Typography variant="subtitle1">
                  Preferred Date & Time
                </Typography>
              </Stack>

              <Typography variant="body2">
                Enter your preferred date and time for this visit. This will
                help us schedule your visit at a time that is convenient for
                you.
              </Typography>
              <Stack direction="column" sx={{ width: "100%" }}>
                <Typography variant="caption">
                  You can enter your preferred date and time here. For example;
                  you can write "Weekdays 8:00 AM to 4:30 PM PDT."
                </Typography>
                <TextareaAutosize
                  minRows={5}
                  onChange={(e) => {
                    this.setState({
                      employeeSchedulingPreferenceDatetime: e.target.value,
                    });
                  }}
                />
              </Stack>
            </Stack>
          </Card>
          <Stack sx={{ width: "100%" }} justifyContent="end">
            <Box>
              <Button
                size="small"
                variant="contained"
                color="primary"
                startIcon={<Iconify icon="lets-icons:check-fill" />}
                onClick={() => {
                  this.setState(
                    {
                      eeActionsDone: true,
                    },
                    () => {
                      this.updateVisitScalars([
                        {
                          key: "eeActionsDone",
                          value: this.state.eeActionsDone,
                        },
                        {
                          key: "employeeSchedulingPreferenceDatetime",
                          value:
                            this.state.employeeSchedulingPreferenceDatetime,
                        },
                      ]);
                    }
                  );
                }}
              >
                {this.state.eeActionsDone
                  ? "Update Date & Time"
                  : "Confirm Date and Time"}
              </Button>
            </Box>
          </Stack>
          <>
            {this.renderQuestionnaire()}
            {this.renderQuestionnaireDrawer()}
          </>
        </Stack>
      </Stack>
    );
  }
}

export default WithAPICall(VisitDetailsEE);
