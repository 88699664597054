import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Unstable_Grid2";
import ListItemText from "@mui/material/ListItemText";
import FormControlLabel from "@mui/material/FormControlLabel";

import React from "react";
import { Chip, LinearProgress, Popover, TextField } from "@mui/material";
import { WithAPICall } from "../utils/apiUtil";
import cogoToast from "cogo-toast";
import { DayPicker } from "react-day-picker";
import { niceDate } from "../utils/fn";

class EmployeeSettings extends React.Component {
  state = { isLoading: true };
  componentDidMount() {
    this.setState({ ...this.props.data, isLoading: false });
  }
  SETTINGS = [
    {
      subheader: "Personal Identifiers",
      caption: "Enter Personal Identifiers For Employee",
      items: [
        {
          id: "employeeNumber",
          label: "Employee Number",
          type: "TEXT",
        },
        {
          id: "applicantNumber",
          label: "Applicant Number",
          type: "TEXT",
        },
        {
          id: "isCandidate",
          label: "Is this a candidate profile?",
          type: "SWITCH",
        },
        {
          id: "employeeDL",
          label: "Driver's License",
          type: "TEXT",
        },
        {
          id: "employeeDLState",
          label: "Driver's License State",
          type: "TEXT",
        },
        {
          id: "dotStatus",
          label: "DOT Status",
          type: "SWITCH",
        },
        {
          id: "dotCardExpirationDate",
          label: "DOT Card Expiration Date",
          type: "DATE",
        },
        {
          id: "dateOfFirstHiring",
          label: "Date of First Hiring",
          type: "DATE",
        },
        {
          id: "dateOfLastHiring",
          label: "Date of Last Hiring",
          type: "DATE",
        },
        {
          id: "dateOfLastTermination",
          label: "Date of Last Termination",
          type: "DATE",
        },
      ],
    },
    {
      subheader: "Exposures and Hazards",
      caption: "General Hazards and Exposures",
      items: [
        {
          label: "Exposure to Asbestos",
          id: "hasAsbestosExposure",
          type: "SWITCH",
        },
        {
          label: "First Exposure To Asbestos",
          id: "asbestosExposureInitialDate",
          type: "DATE",
        },
        {
          label: "Exposure to Inorganic Arsenic",
          id: "hasInorganicArsenicExposure",
          type: "SWITCH",
        },
        {
          label: "First Exposure To Arsenic",
          id: "arsenicExposureInitialDate",
          type: "DATE",
        },
        {
          label: "Needs Respirator Fit Test",
          id: "needsRespiratorFitTest",
          type: "SWITCH",
        },
        {
          label: "Exposure to Respirable Crystalline Silica",
          id: "hasRespirableCrystallineSilicaExposure",
          type: "SWITCH",
        },
        {
          label: "Exposure to Physical Exam Data Capture",
          id: "hasPhysicalExamDataCaptureExposure",
          type: "SWITCH",
        },
        {
          label: "Exposure to Methylene Chloride",
          id: "hasMethyleneChlorideExposure",
          type: "SWITCH",
        },
        {
          label: "Exposure to Lead",
          id: "hasLeadExposure",
          type: "SWITCH",
        },

        {
          label: "Exposure to Hazwoper",
          id: "hasHazwoperExposure",
          type: "SWITCH",
        },
        {
          label: "Exposure to Formaldehyde",
          id: "hasFormaldehydeExposure",
          type: "SWITCH",
        },
        {
          label: "Exposure to Chromium (VI) Hexavalent",
          id: "hasChromiumVIHexavalentExposure",
          type: "SWITCH",
        },
        {
          label: "Exposure to Cadmium",
          id: "hasCadmiumExposure",
          type: "SWITCH",
        },
        {
          label: "Exposure to Bloodborne Pathogen",
          id: "hasBloodbornePathogenExposure",
          type: "SWITCH",
        },
        {
          label: "Exposure to Beryllium",
          id: "hasBerylliumExposure",
          type: "SWITCH",
        },
        {
          label: "Exposure to Benzene",
          id: "hasBenzeneExposure",
          type: "SWITCH",
        },
      ],
    },
  ];
  updateScalarValue = async (key, value) => {
    if (value === null || value === undefined) {
      return;
    }
    if (this.props.data[key] === value) {
      return;
    }
    try {
      await this.props.apiCallPost("/employee/updateScalarValue", {
        employeeId: this.props.data._id,
        key: key,
        value: value,
      });
    } catch (err) {
      cogoToast.error("Error Updating Employee Data");
      console.log(err);
    }
  };
  renderControl = (type, item) => {
    switch (type) {
      case "SWITCH":
        return (
          <Switch
            disabled={item.nonEditable}
            checked={this.state[item.id]}
            onChange={() => {
              this.setState(
                {
                  [item.id]: !this.state[item.id],
                },
                () => {
                  this.updateScalarValue(item.id, this.state[item.id]);
                }
              );
            }}
          />
        );

      case "TEXT":
        return (
          <TextField
            value={this.state[item.id]}
            onChange={(e) => {
              this.setState({
                [item.id]: e.target.value,
              });
            }}
            onBlur={() => {
              this.updateScalarValue(item.id, this.state[item.id]);
            }}
          />
        );
      case "DATE":
        return (
          <>
            <Chip
              variant="contained"
              onClick={(e) => {
                this.setState({
                  anchorEl: e.currentTarget,
                  [`open_${item.label}`]: true,
                });
              }}
              label={this.state[item.id] ? niceDate(this.state[item.id]) : ""}
            />
            <Popover
              open={this.state[`open_${item.label}`]}
              anchorEl={this.state.anchorEl}
              onClose={() => {
                this.setState({
                  anchorEl: null,
                  [`open_${item.label}`]: false,
                });
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <DayPicker
                mode="single"
                captionLayout="dropdown-buttons"
                fromYear={1901}
                toYear={2024}
                onSelect={(e) => {
                  this.setState({
                    [item.id]: e,
                    anchorEl: null,
                    [`open_${item.label}`]: false,
                  });
                  this.updateScalarValue(item.id, e);
                }}
              />
            </Popover>
          </>
        );
    }
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <div>
        <Stack component={Card} spacing={3} sx={{ p: 3 }}>
          {this.SETTINGS.map((setting, index) => {
            return (
              <Grid key={setting.subheader} container spacing={3}>
                <Grid xs={12} md={4}>
                  <ListItemText
                    primary={setting.subheader}
                    secondary={setting.caption}
                    primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
                    secondaryTypographyProps={{ component: "span" }}
                  />
                </Grid>
                <Grid xs={12} md={8}>
                  <Stack
                    spacing={1}
                    sx={{
                      p: 3,
                      borderRadius: 2,
                      bgcolor: "background.neutral",
                    }}
                  >
                    <>
                      {setting.items.map((item, subIndex) => {
                        if (
                          index === 1 &&
                          subIndex === 1 &&
                          !this.state.hasAsbestosExposure
                        )
                          return null;
                        if (
                          index === 1 &&
                          subIndex === 3 &&
                          !this.state.hasInorganicArsenicExposure
                        )
                          return null;
                        return (
                          <FormControlLabel
                            key={item.id}
                            label={item.label}
                            labelPlacement="start"
                            control={this.renderControl(item.type, item)}
                            sx={{
                              m: 0,
                              width: 1,
                              justifyContent: "space-between",
                            }}
                          />
                        );
                      })}
                    </>
                  </Stack>
                </Grid>
              </Grid>
            );
          })}
        </Stack>
      </div>
    );
  }
}
export default WithAPICall(EmployeeSettings);
