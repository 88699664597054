import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Alert,
  Autocomplete,
  Card,
  Grid,
  LinearProgress,
  TextField,
  Typography,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import Iconify from "src/components/iconify";
import { LoadingButton } from "@mui/lab";
import cogoToast from "cogo-toast";
import _ from "lodash";

class EmployeeCSFDataField extends React.Component {
  state = {
    isError: false,
    isLoading: true,
    isSaving: false,
    isEditing: false,
    searchInput: "",
    searchResults: [],
    selectedResult: null,
    csfData: null,
    showDetail: false,
  };
  componentDidMount() {
    this.getCSFData();
  }
  searchCSFData = async () => {
    if (!this.state.searchInput) {
      this.setState({
        searchResults: [],
      });
      return;
    }
    if (this.state.searchInput.length < 3) {
      this.setState({
        searchResults: [],
      });
      return;
    }
    if (this.state.searchInput === "undefined") {
      this.setState({
        searchResults: [],
      });
      return;
    }
    try {
      this.setState({
        isSearching: true,
        searchResults: [],
      });
      let res = await this.props.apiCallPostCancellable(
        "/company/data/search/field",
        {
          companyId: this.props.companyId,
          csfId: this.props.data._id,
          query: this.state.searchInput,
        }
      );
      if (res) {
        this.setState({
          isSearching: false,
          searchResults: res.map((each) => {
            let mt = each[this.props.data.lookUpField];
            let csfFields = this.props.data.fieldDataFields.map(
              (x) => x.fieldDataFieldName
            );
            let st = csfFields.map((x) => `${x}: ${each[x]}`).join(" | ");
            return {
              mainText: mt,
              secondaryText: st,
              itemId: each._id,
              item: each,
            };
          }),
        });
      }
    } catch (err) {}
  };
  getCSFData = async () => {
    this.setState({
      isLoading: true,
    });
    try {
      let value = this.props.employeeData[this.props.data.employeeSourceKey];
      if (!value) {
        this.setState({
          isLoading: false,
          isError: false,
          isEditing: true,
        });
        return;
      }
      let res = await this.props.apiCallPost(
        "/company/data/getRecordByPrimaryKey",
        {
          companyId: this.props.companyId,
          csfId: this.props.data._id,
          value: value,
        }
      );
      this.setState({
        csfData: res,
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading CSF Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  saveCSFData = async () => {
    try {
      this.setState({
        isSaving: true,
      });
      await this.props.apiCallPost("/employee/updateScalarValue", {
        employeeId: this.props.employeeId,
        key: this.props.data.employeeSourceKey,
        value: this.state.selectedResult.item[this.props.data.lookUpField],
        typeOfValue: "CSF",
        csfId: this.props.data._id,
      });
      this.setState({
        isEditing: false,
        csfData: this.state.selectedResult.item,
        isSaving: false,
      });
    } catch (err) {
      this.setState(
        {
          isSaving: false,
          isLoading: true,
        },
        () => this.getCSFData()
      );
      cogoToast.error("Error Updating Employee Data");
      console.log(err);
    }
  };
  renderCSFData = () => {
    if (this.state.isLoading) return null;
    if (this.state.isError) return null;
    let ds = this.state.csfData;
    if (this.state.isSearching) {
      ds = null;
    }
    if (this.state.selectedResult) {
      ds = this.state.selectedResult.item;
    }
    if (this.state.isEditing && !this.state.selectedResult) {
      ds = null;
    }

    if (!ds) {
      return null;
    }
    let keys = this.props.data.fieldDataFields.map(
      (each) => each.fieldDataFieldName
    );
    if (this.props.fromTransferPage && !this.state.showDetail) {
      return null;
    }
    if (this.props.fromTransferPage && !this.props.allowEdit) {
      return keys.map((key, i) => {
        let esk = this.props.data.employeeSourceKey;
        if (key === esk) return null;
        return (
          <Stack spacing={1} key={i} direction={"row"} alignItems={"center"}>
            <Typography variant="body2">{key}: </Typography>
            <Typography variant="body3">{ds[key]}</Typography>
          </Stack>
        );
      });
    }
    if (this.props.fromTransferPage && this.props.allowEdit) {
      return keys.map((key, i) => {
        let esk = this.props.data.employeeSourceKey;
        if (key === esk) return null;
        return (
          <Stack spacing={1} key={i} direction={"row"} alignItems={"center"}>
            <Typography variant="body2">{key}: </Typography>
            <Typography variant="body3">{ds[key]}</Typography>
          </Stack>
        );
      });
    }
    return keys.map((key, i) => {
      return (
        <Stack spacing={1} key={i} direction={"row"} alignItems={"center"}>
          <Typography variant="body2">{key}</Typography>
          <Typography variant="body3">{ds[key]}</Typography>
        </Stack>
      );
    });
  };
  renderSearchField = () => {
    if (!this.state.isEditing) return null;
    return (
      <Autocomplete
        size={this.props.fromTransferPage ? "small" : "medium"}
        sx={{
          minWidth: 300,
        }}
        disabled={this.state.isSaving}
        disableClearable
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option?.mainText
        }
        filterOptions={(x) => x}
        options={this.state.isSearching ? [] : this.state.searchResults}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={this.state.selectedResult}
        noOptionsText={
          this.state.isSearching ? "Searching..." : "No results found"
        }
        onChange={(_, value) => {
          this.setState({
            selectedResult: value,
            searchResults: [value, ...this.state.searchResults],
          });
        }}
        onInputChange={(_, e) => {
          this.setState(
            {
              searchInput: e,
            },
            () => {
              this.searchCSFData();
            }
          );
        }}
        renderInput={(params) => (
          <TextField
            size={this.props.fromTransferPage ? "small" : "medium"}
            sx={{
              minWidth: 300,
            }}
            {...params}
            label={`Searching Across: ${this.props.data.employeeSourceKey}`}
            fullWidth
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ wordWrap: "break-word" }}>
                  <Box component="span">{option?.mainText}</Box>
                  <Typography variant="body2" color="text.secondary">
                    {option?.secondaryText}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    );
  };
  renderButton = () => {
    if (this.state.isEditing) {
      return (
        <Box>
          <LoadingButton
            onClick={() => this.saveCSFData()}
            loading={this.state.isSaving}
            variant="contained"
            startIcon={<Iconify icon="mdi:content-save" />}
          >
            Save
          </LoadingButton>
        </Box>
      );
    } else {
      return (
        <Box>
          {" "}
          <Button
            variant="contained"
            startIcon={<Iconify icon="mdi:pencil" />}
            onClick={() => {
              this.setState({
                isEditing: true,
              });
            }}
          >
            Edit
          </Button>
        </Box>
      );
    }
  };
  renderStructure = () => {
    if (this.props.fromTransferPage && !this.props.allowEdit) {
      let esk = this.props.data.employeeSourceKey;
      let eskVal = this.props.employeeData[esk];
      let dsk = this.props.data.descriptorField;

      let ds = this.state.csfData;
      if (this.state.isSearching) {
        ds = null;
      }
      if (this.state.selectedResult) {
        ds = this.state.selectedResult.item;
      }
      if (this.state.isEditing && !this.state.selectedResult) {
        ds = null;
      }
      let dskVal = "";
      if (!ds) {
        dskVal = "";
      } else {
        dskVal = ` | ${ds[dsk]}`;
      }
      return (
        <Stack>
          <Stack spacing={1} direction={"row"} alignItems={"center"}>
            <Typography variant="overline">
              {esk} : {eskVal} {dskVal}
            </Typography>
            <Tooltip
              title={
                this.state.showDetail
                  ? "Click to hide details"
                  : "Click to show details"
              }
            >
              <IconButton
                size="small"
                onClick={() => {
                  this.setState({
                    showDetail: !this.state.showDetail,
                  });
                }}
              >
                <Iconify
                  icon={
                    this.state.showDetail
                      ? "solar:square-double-alt-arrow-up-broken"
                      : "solar:square-double-alt-arrow-down-broken"
                  }
                />
              </IconButton>
            </Tooltip>
          </Stack>
          {this.renderCSFData()}
        </Stack>
      );
    }
    if (this.props.fromTransferPage && this.props.allowEdit) {
      let esk = this.props.data.employeeSourceKey;
      let eskVal;
      if (this.state.selectedResult) {
        eskVal = this.state.selectedResult.item[esk];
      } else {
        eskVal = this.props.employeeData[esk];
      }
      let dsk = this.props.data.descriptorField;
      let ds = this.state.csfData;
      if (this.state.isSearching) {
        ds = null;
      }
      if (this.state.selectedResult) {
        ds = this.state.selectedResult.item;
      }
      if (this.state.isEditing && !this.state.selectedResult) {
        ds = null;
      }
      let dskVal = "";
      if (!ds) {
        dskVal = "";
      } else {
        dskVal = ` | ${ds[dsk]}`;
      }
      let headLiner = (
        <Stack spacing={1} direction={"row"} alignItems={"center"}>
          <Typography variant="overline">
            {esk} : {eskVal} {dskVal}
          </Typography>{" "}
          <Tooltip title={`Search for new ${esk}`}>
            <IconButton
              size="small"
              onClick={() => {
                this.setState({
                  isEditing: true,
                });
              }}
            >
              <Iconify icon="solar:card-search-broken" />
            </IconButton>
          </Tooltip>{" "}
          <Tooltip
            title={
              this.state.showDetail
                ? "Click to hide details"
                : "Click to show details"
            }
          >
            <IconButton
              size="small"
              onClick={() => {
                this.setState({
                  showDetail: !this.state.showDetail,
                });
              }}
            >
              <Iconify
                icon={
                  this.state.showDetail
                    ? "solar:square-double-alt-arrow-up-broken"
                    : "solar:square-double-alt-arrow-down-broken"
                }
              />
            </IconButton>
          </Tooltip>
        </Stack>
      );
      if (this.state.isEditing) {
        headLiner = (
          <Stack spacing={1} direction={"row"} alignItems={"center"}>
            {this.renderSearchField()}{" "}
            <Tooltip title="Confirm this change">
              <IconButton
                size="small"
                onClick={() => {
                  let ds;
                  if (!this.state.selectedResult) return;
                  ds = this.state.selectedResult.item;
                  let eskVal = ds[this.props.data.lookUpField];
                  this.props.onSetCSF(eskVal);
                  this.setState({
                    isEditing: false,
                    isSearching: false,
                    searchResults: [],
                  });
                }}
              >
                <Iconify icon="solar:check-read-broken" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel, revert to original">
              <IconButton
                size="small"
                onClick={() => {
                  this.setState({
                    isEditing: false,
                    isSearching: false,
                    selectedResult: null,
                    searchResults: [],
                  });
                }}
              >
                <Iconify icon="solar:backspace-broken" />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      }
      let sx = {};
      if (this.state.isEditing) {
        sx = {
          bgcolor: "background.paper",
        };
      }
      return (
        <Stack sx={sx}>
          {headLiner}
          {this.renderCSFData()}
        </Stack>
      );
    }
    return (
      <div>
        <Card>
          <Stack
            spacing={3}
            sx={{
              mt: 2,
              mb: 2,
              p: 2,
              borderRadius: 2,
              bgcolor: "background.paper",
            }}
          >
            <Box
              rowGap={1}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
              }}
            >
              <Typography variant="h6">{this.props.data.fieldName}</Typography>
              {this.renderButton()}
            </Box>
            {this.renderSearchField()}
            {this.renderCSFData()}
          </Stack>
        </Card>
      </div>
    );
  };
  render() {
    if (!this.props.data) return null;
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <Alert severity="error">Error Loading Data</Alert>;
    }

    return this.renderStructure();
  }
}

export default WithAPICall(EmployeeCSFDataField);
