import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Alert,
  AlertTitle,
  Card,
  Chip,
  Drawer,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import _ from "lodash";
import cogoToast from "cogo-toast";
import ServiceQPreview from "../Services/ServiceQPreview";
import { niceDateTime } from "../utils/fn";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import GMap from "../utils/GMap";
import { LoadingButton } from "@mui/lab";

class VisitElementEmployee extends React.Component {
  state = {
    isLoading: true,
    bookedServicesDetailed: [],
    bookedProtocolsDetailed: [],
    bookedServices: [],
    bookedProtocols: [],
    isDrawerOpen: false,
    employeeSchedulingPreferenceLocation: {},
    employeeSchedulingPreferenceDatetime: null,
  };
  componentDidMount() {
    this.setState(
      {
        ...this.props.data,
      },
      () => {
        this.getVisitPopulates();
      }
    );
  }
  updateVisitScalars = async (arr, showNotification = true) => {
    this.setState({
      isSaving: true,
    });
    try {
      let res = await this.props.apiCallPost("/visit/updateVisitScalars", {
        visitId: this.state._id,
        uops: arr,
      });
      if (showNotification) {
        cogoToast.success("Visit Data Saved");
      }
      this.setState({
        isSaving: false,
        ...res,
      });
    } catch (err) {
      cogoToast.error("Error Saving Visit Data");
      console.log(err);
      this.setState({
        isSaving: false,
      });
    }
  };
  getVisitPopulates = async () => {
    try {
      let res = await this.props.apiCallPost(
        "/ext-employee/getVisitPopulates",
        {
          visitId: this.state._id,
        }
      );
      this.setState({
        bookedServicesDetailed: res.bookedServices,
        bookedProtocolsDetailed: res.bookedProtocols,
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Visit Data");
    }
  };
  updateEmployeeSchedulingPreference = async () => {
    try {
      cogoToast.loading("Saving");
      this.setState({ isSaving: true });
      await this.props.apiCallPost(
        "/ext-employee/updateEmployeeSchedulingPreference",
        {
          visitId: this.state._id,
          employeeSchedulingPreference: this.state.employeeSchedulingPreference,
        }
      );
      this.setState({ isSaving: false });
      cogoToast.success("Saved");
    } catch (err) {
      console.log(err);
      this.setState({ isSaving: false });
      cogoToast.error("Error Saving");
    }
  };
  renderEmployeePreference = () => {
    return (
      <Stack spacing={1}>
        <Typography variant="button">Scheduling Preference</Typography>{" "}
        <Typography variant="caption">
          If this visit requires you to visit a clinic, you can put down your
          availability and preferences (such as, Weekdays between 8 am to 8 pm &
          Weekends after noon) - so that we can try to accommodate your
          preferences. Please note that we may not always be able to adhere to
          these preferences, since they depend on the availability of services
          at the clinic.
        </Typography>
        <Typography variant="overline">Scheduling Preferences</Typography>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                label="Basic date time picker"
                onChange={(m, _) => {
                  console.log({ m });
                  this.setState({
                    employeeSchedulingPreferenceDatetime: m.toDate(),
                  });
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <GMap
              onPlace={(data) => {
                this.setState({
                  employeeSchedulingPreferenceLocation: data,
                });
              }}
              onLatLng={console.log}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              sx={{
                mt: 2,
              }}
              fullWidth
              disabled={this.state.isSaving}
              label="Anything you would like us to know"
              value={this.state.employeeSchedulingPreference}
              onChange={(e) => {
                this.setState({ employeeSchedulingPreference: e.target.value });
              }}
              // onBlur={() => {
              //   this.updateEmployeeSchedulingPreference();
              // }}
            />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="end">
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={() => {
              this.updateVisitScalars([
                {
                  key: "employeeSchedulingPreferenceLocation",
                  value: this.state.employeeSchedulingPreferenceLocation,
                },
                {
                  key: "employeeSchedulingPreferenceDatetime",
                  value: this.state.employeeSchedulingPreferenceDatetime,
                },
                {
                  key: "employeeSchedulingPreference",
                  value: this.state.employeeSchedulingPreference,
                },
              ]);
            }}
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>
    );
  };
  renderProtocols = () => {
    if (!this.state.bookedProtocolsDetailed) {
      return null;
    }
    if (!this.state.bookedProtocolsDetailed.length) {
      return null;
    }
    return (
      <Stack spacing={2}>
        <Typography variant="button">Protocols In This Visit</Typography>
        {this.state.bookedProtocolsDetailed.map((protocol) => {
          return (
            <Stack direction="row" alignItems={"center"} spacing={1}>
              <Typography variant="overline">
                {protocol.companyProtocolName}
              </Typography>
              <Typography variant="caption">
                {protocol.companyProtocolType}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    );
  };
  renderHeader = () => {
    return (
      <Stack>
        <Typography variant="overline">Visit Information</Typography>
        <Typography variant="caption">
          Current Visit Status:{" "}
          {_.startCase(_.lowerCase(this.state.visitOutcome))}
        </Typography>
        <Typography variant="caption">
          Visit Created: {niceDateTime(this.state.createdAt)}
        </Typography>
      </Stack>
    );
  };
  renderQuestionnaire = () => {
    let bookedServicesDetailed = this.state.bookedServicesDetailed;
    if (!bookedServicesDetailed) {
      return null;
    }
    if (!bookedServicesDetailed.length) {
      return null;
    }
    let questionnaireServices = _.filter(
      bookedServicesDetailed,
      (s) => s.serviceType === "QUESTIONNAIRE"
    );
    if (!questionnaireServices.length) {
      return null;
    }
    let l = questionnaireServices.length;
    let qS = l === 1 ? "questionnaire" : "questionnaires";
    let headerElem = (
      <Alert severity="info">
        <AlertTitle>Questionnaires</AlertTitle>
        As part of this visit, you need to answer a few questions relating to
        your health, so that we can make an assessment. You can answer these
        questions any time before your visit; but we recommend that you do so as
        early as possible, so that we can avoid any last minute delays.
      </Alert>
    );
    let qElem = (
      <Alert severity="info">
        You have {l} {qS} to answer as part of this visit.
      </Alert>
    );

    return (
      <Stack spacing={2}>
        {headerElem}
        {qElem}
        {questionnaireServices.map((q) => {
          let qId = q._id;
          let idxInBookedServices = _.findIndex(
            this.state.bookedServices,
            (s) => s.serviceId === qId
          );
          let isCompleted = false;
          if (idxInBookedServices > -1) {
            isCompleted =
              this.state.bookedServices[idxInBookedServices].hasReceivedAllData;
          }

          let completedElem = null;
          if (isCompleted) {
            completedElem = <Chip label="Completed" color="success" />;
          }
          let buttonText = isCompleted ? "View Answers" : "Start Answering";
          return (
            <Stack direction="row" alignItems={"center"} spacing={1}>
              <Typography variant="overline">{q.serviceName}</Typography>
              {completedElem}
              <Typography
                variant="caption"
                onClick={() => {
                  this.setState({ drawerQuestionnaireId: q._id });
                }}
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                {buttonText}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    );
  };
  renderQuestionnaireDrawer = () => {
    if (!this.state.drawerQuestionnaireId) {
      return null;
    }
    let id = this.state.drawerQuestionnaireId;
    let svc = _.find(this.state.bookedServicesDetailed, (s) => s._id === id);

    let idxInBookedServices = _.findIndex(
      this.state.bookedServices,
      (s) => s.serviceId === id
    );
    let isCompleted = false;

    if (idxInBookedServices > -1) {
      isCompleted =
        this.state.bookedServices[idxInBookedServices].hasReceivedAllData;
    }

    return (
      <Drawer
        anchor="right"
        open={this.state.drawerQuestionnaireId}
        onClose={() => {
          this.setState({ drawerQuestionnaireId: null });
        }}
      >
        <ServiceQPreview
          data={svc.questionnaireTemplate}
          serviceData={svc}
          isPreview={false}
          visitId={this.state._id}
          isCompleted={isCompleted}
          onSubmit={() => {
            // mark has received all data
            if (idxInBookedServices > -1) {
              let bookedServices = this.state.bookedServices;
              bookedServices[idxInBookedServices].hasReceivedAllData = true;
              this.setState({ bookedServices, drawerQuestionnaireId: null });
            } else {
              this.setState({ drawerQuestionnaireId: null });
            }
          }}
        />
      </Drawer>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <Card
        sx={{
          p: 2,
          boxShadow: 3,
        }}
      >
        <Stack spacing={2}>
          {this.renderHeader()}
          {this.renderEmployeePreference()}
          {this.renderProtocols()}
          {this.renderQuestionnaire()}
          {this.renderQuestionnaireDrawer()}
        </Stack>
      </Card>
    );
  }
}

export default WithAPICall(VisitElementEmployee);
