import React from "react";
import {
  apiCallPostUnuath,
  formattedTZTime,
  maskSSN,
  niceDate,
} from "../utils/fn";
import {
  Alert,
  Divider,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import _ from "lodash";

class VisitServiceClearance extends React.Component {
  state = {
    isLoading: true,
    visitId: null,
    serviceId: null,
    data: null,
    isError: false,
  };
  componentDidMount() {
    this.setState(
      {
        visitId: this.props.id.split("_")[0],
        serviceId: this.props.id.split("_")[1],
      },
      () => {
        this.getVisitData();
      }
    );
  }
  getVisitData = async () => {
    try {
      let res = await apiCallPostUnuath("/print/clearance/payload", {
        visitId: this.state.visitId,
      });
      this.setState({
        data: res,
        isLoading: false,
      });
    } catch (err) {
      this.setState({
        isError: true,
        isLoading: false,
      });
      console.log(err);
    }
  };
  renderClearance = () => {
    return (
      <Stack spacing={1}>
        {this.renderHeader()}
        {this.renderERTable()}
        {this.renderEETable()}
        {this.renderProviderTable()}
        <Divider
          flexItem
          sx={{
            bgcolor: "#919EAB",
          }}
        />
        {this.renderClearanceLanguage()}
        {this.renderFooter()}
      </Stack>
    );
  };
  renderHeader = () => {
    let serviceId = this.state.serviceId;
    let bsd = this.state.data.bookedServicesDetailed;
    let svc = _.find(bsd, { _id: serviceId });
    let svcName = svc.serviceName;
    return (
      <Stack direction="row" alignItems="center" spacing={2}>
        <Box
          component="img"
          src="/logo/logo_single.svg"
          sx={{ width: 80, height: 80, cursor: "pointer" }}
        />
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            bgcolor: "#919EAB",
          }}
        />
        <Typography variant="h6">Medical Release for {svcName}</Typography>
      </Stack>
    );
  };
  renderERTable = () => {
    let companyName = this.state.data.company.companyName;
    let companyAddress = this.state.data.company.companyAddress;
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <Typography variant="overline">Employer Information</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {" "}
          <TableRow>
            <TableCell>Employer</TableCell>
            <TableCell>{companyName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>{companyAddress}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };
  renderEETable = () => {
    let employeeName = this.state.data.employee.employeeName;
    let employeeNumber = this.state.data.employee.employeeNumber;
    let employeeDoB = this.state.data.employee.employeeDoB
      ? niceDate(new Date(this.state.data.employee.employeeDoB))
      : "Not on file";
    let employeeSSN = maskSSN(this.state.data.employee.employeeSSN);
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <Typography variant="overline">Employee Information</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Employee</TableCell>
            <TableCell>{employeeName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Employee ID</TableCell>
            <TableCell>{employeeNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>DoB</TableCell>
            <TableCell>{employeeDoB}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>SSN</TableCell>
            <TableCell>{employeeSSN}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };
  renderProviderTable = () => {
    let serviceId = this.state.serviceId;
    let clinicPSLs = this.state.data.clinicPSLs;
    let whichPSL = _.find(clinicPSLs, (x) =>
      x.masterServices.includes(serviceId)
    );
    let provider = whichPSL.providerObj;
    let providerName = provider.providerName;
    let providerAddress = provider.providerAddress;
    let scheduledTime = whichPSL.scheduledTime;
    let providerTimeZone = provider.providerReadableTimezone;
    let scTime = formattedTZTime(scheduledTime, providerTimeZone);

    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <Typography variant="overline">
                Collection Site/Clinic{" "}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Provider</TableCell>
            <TableCell>{providerName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>{providerAddress}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Visit Date and Time</TableCell>
            <TableCell>{scTime}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };
  renderClearanceLanguage = () => {
    let serviceId = this.state.serviceId;
    let bs = this.state.data.bookedServices;
    let svc = _.find(bs, { serviceId: serviceId });
    if (!svc) {
      return <Alert severity="error">Service not found.</Alert>;
    }
    let svcDetails = _.find(this.state.data.bookedServicesDetailed, {
      _id: serviceId,
    });
    if (!svcDetails) {
      return <Alert severity="error">Service not found.</Alert>;
    }
    let svcName = svcDetails.serviceName;
    let headerElem = (
      <Typography variant="h5">Medical Release for {svcName}</Typography>
    );
    let typeOfClearance = (
      <Typography variant="subtitle1">
        Result: {_.startCase(_.lowerCase(svc.typeOfClearance))}
      </Typography>
    );
    let clearanceLanguage = (
      <Typography variant="body2">
        {svc.clearance.map((i, key) => {
          return <p key={key}>{i}</p>;
        })}
      </Typography>
    );
    return (
      <Stack
        spacing={0.5}
        sx={{
          p: 2,
        }}
      >
        {headerElem}
        {typeOfClearance}
        {clearanceLanguage}
      </Stack>
    );
  };
  renderFooter = () => {
    let signatureImage = this.state.data.assignedMD.signatureImagePsUrl;
    let docName = `${this.state.data.assignedMD.name} ${this.state.data.assignedMD.reviewerType}`;
    let serviceId = this.state.serviceId;
    let bsd = this.state.data.bookedServices;
    let svc = _.find(bsd, { serviceId: serviceId });
    let clearedAt = svc.clearedAt;
    let docNameElem = (
      <Stack spacing={0.5} alignItems={"center"}>
        <Typography variant="body2">{docName}</Typography>
        <Divider
          flexItem
          sx={{
            bgcolor: "#919EAB",
          }}
        />
        <Typography variant="overline">Medical Review Officer</Typography>
      </Stack>
    );
    let docSignature = (
      <Stack spacing={0.5} alignItems={"center"}>
        <Box
          component="img"
          src={signatureImage}
          sx={{ width: 80, height: "auto", cursor: "pointer" }}
        />
        <Divider
          flexItem
          sx={{
            bgcolor: "#919EAB",
          }}
        />
        <Typography variant="overline">MRO Signature</Typography>
      </Stack>
    );
    let reviewTime = (
      <Stack spacing={0.5} alignItems={"center"}>
        <Typography variant="body2">{niceDate(clearedAt)}</Typography>
        <Divider
          flexItem
          sx={{
            bgcolor: "#919EAB",
          }}
        />
        <Typography variant="overline">Date</Typography>
      </Stack>
    );
    let footerAddress = (
      <Typography variant="caption">
        WorkCare Inc. | 300 South Harbor Boulevard, Anaheim, CA 92805 |
        support@workcare.com
      </Typography>
    );
    return (
      <Stack spacing={0.5} alignItems={"center"}>
        <Stack
          direction="row"
          alignItems={"flex-end"}
          justifyContent={"space-between"}
          sx={{
            p: 2,
            width: "100%",
          }}
        >
          {docNameElem}
          {docSignature}
          {reviewTime}
        </Stack>
        {footerAddress}
      </Stack>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <Alert severity="error">We ran into an error.</Alert>;
    }
    return (
      <Paper
        sx={{
          p: 1,
          width: "100%",
          height: "100%",
        }}
      >
        {this.renderClearance()}
      </Paper>
    );
  }
}

export default VisitServiceClearance;
