import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Box, Stack } from "@mui/system";
import { NoResultView, View500 } from "src/sections/error";
import cogoToast from "cogo-toast";
import { LoadingButton } from "@mui/lab";
import Iconify from "src/components/iconify";
import UserCreate from "./UserCreate";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";

class UserList extends React.Component {
  state = {
    isLoading: true,
    // cu
    currentUser: {
      isEmployee: true,
      isExternal: true,
    },
    // company
    isSearchingCompany: false,
    companySearchResults: [],
    currentlyInCompany: null,
    //
    isSearchingUser: false,
    userSearchResults: [],
    //
    isDoingAction: false,
    isError: false,
    //
    openUserCreate: false,

    isPermissionModalOpen: false,

    companyList: [],
    permissionOptions: ["View", "Edit", "Create"],

    companyPermissions: [],
    companyAccess: [],
    employees: [],
    masterServices: [],
    visits: [],
    clearances: [],
    protocols: [],
    indexValue: null,
  };
  componentDidMount() {
    this.getCurrentUser();
  }
  getCurrentUser = async () => {
    try {
      const res = await this.props.apiCallPost("/user/getCurrentUser", {});
      let cu = res;
      let isExternal = cu.isExternal;
      this.setState({
        currentUser: cu,
      });
      if (isExternal) {
        this.setState({
          currentUser: cu,
          currentlyInCompany: {
            mainText: res.company.companyName,
            secondaryText: `${res.company.companyInformalName} ${res.companyWebsite}`,
            data: res.company,
          },
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading User Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  renderCompanySearcher = () => {
    return (
      <Autocomplete
        sx={{
          minWidth: "200px",
        }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option?.mainText
        }
        filterOptions={(x) => x}
        options={
          this.state.isSearchingCompany ? [] : this.state.companySearchResults
        }
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={this.state.currentlyInCompany}
        noOptionsText={
          this.state.isSearchingCompany ? "Searching..." : "No results found"
        }
        onChange={(_, value) => {
          this.setState({
            currentlyInCompany: value,
            companySearchResults: [value, ...this.state.companySearchResults],
          });
        }}
        onInputChange={(_, e) => {
          if (e === "") return null;
          if (!e) return null;
          if (e.length < 4) return null;
          this.setState(
            {
              isSearchingCompany: true,
            },
            () => {
              this.searchCompaniesFTS(e);
            }
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label="Search Employers" fullWidth />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ wordWrap: "break-word" }}>
                  <Box component="span">{option?.mainText}</Box>
                  <Typography variant="body2" color="text.secondary">
                    {option?.secondaryText}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    );
  };
  searchCompaniesFTS = async (t) => {
    try {
      let res = await this.props.apiCallPostCancellable("/company/search", {
        query: t,
      });
      if (res) {
        this.setState({
          isSearchingCompany: false,
          companySearchResults: res.map((each) => {
            return {
              mainText: each.companyName,
              secondaryText: `${each.companyInformalName} ${each.companyWebsite}`,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingCompany: false,
        companySearchResults: [],
      });
      cogoToast.error("Error Searching Companies");
    }
  };
  searchUsersFTS = async (t) => {
    try {
      let payload = {
        query: t,
        showDeactivatedAlso: true,
      };
      if (this.state.currentlyInCompany) {
        payload.company = this.state.currentlyInCompany.data._id;
      }
      let res = await this.props.apiCallPostCancellable(
        "/user/searchUser",
        payload
      );
      if (res) {
        this.setState({
          isSearchingUser: false,
          userSearchResults: res.map((each) => {
            return {
              mainText: each.name,
              secondaryText: each.email,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingUser: false,
        userSearchResults: [],
      });
      cogoToast.error("Error Searching Users");
    }
  };
  updateUser = async (userId, index, uops) => {
    try {
      this.setState({
        isDoingAction: true,
      });
      let res = await this.props.apiCallPostCancellable("/user/updateUser", {
        userId: userId,
        uops: uops,
      });
      let userSearchResults = this.state.userSearchResults;
      userSearchResults[index] = {
        mainText: res.name,
        secondaryText: res.email,
        data: res,
      };
      cogoToast.success("User Updated");
      this.setState({
        isDoingAction: false,
        userSearchResults: userSearchResults,
      });
    } catch (err) {
      this.setState({
        isDoingAction: false,
      });
      cogoToast.error("Error Updating User");
    }
  };

  updateUserAccess = async (userId, entityAccess) => {
    try {
      this.setState({
        isDoingAction: true,
      });
      let res = await this.props.apiCallPostCancellable(
        "/user/updateUserAccess",
        {
          userId: userId,
          entityAccess: entityAccess,
        }
      );

      this.setState({
        isDoingAction: false,
        isPermissionModalOpen: false,
      });

      cogoToast.success("User Access Updated");
    } catch (err) {
      this.setState({
        isDoingAction: false,
      });
      cogoToast.error("Error Updating User Access");
    }
  };
  renderHeader = () => {
    return (
      <CustomBreadcrumbs
        heading="List of Users"
        links={[
          { name: "Dashboard", href: "/" },
          { name: "Users" },
          { name: "List" },
        ]}
        action={
          <Box>
            <Button
              disabled={
                this.state.isSearchingCompany ||
                this.state.isDoingAction ||
                this.state.isSearchingUser
              }
              onClick={() => this.setState({ openUserCreate: true })}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              New User
            </Button>
          </Box>
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
    );
  };
  renderFilterBox = () => {
    return (
      <Stack spacing={2} direction="row" flexWrap="wrap" alignItems="center">
        <TextField
          label="Search"
          value={this.state.query}
          onChange={(e) => {
            let v = e.target.value;
            this.setState(
              {
                query: v,
              },
              () => {
                if (v.length >= 3) {
                  this.searchUsersFTS(v);
                }
              }
            );
          }}
        />
        {this.renderCompanySearcher()}
        <LoadingButton
          loading={this.state.isSearchingUser}
          onClick={() => {
            this.searchUsersFTS(this.state.query);
          }}
        >
          Search
        </LoadingButton>
      </Stack>
    );
  };

  handleCompanyPermissionsChange = (event, index) => {
    let userListTemp = [...this.state.userSearchResults];
    if (!userListTemp[index].entityAccess) {
      userListTemp[index].entityAccess = {};
    }
    userListTemp[index].entityAccess.companyPermissions = event.target.value;
    this.setState({
      userSearchResults: userListTemp,
    });
  };

  handleCompanyAccessChange = (event, index) => {
    let userListTemp = [...this.state.userSearchResults];
    if (!userListTemp[index].entityAccess) {
      userListTemp[index].entityAccess = {};
    }
    userListTemp[index].entityAccess.companyAccess = event.target.value;
    this.setState({
      userSearchResults: userListTemp,
    });
  };

  handleEmployeesChange = (event, index) => {
    let userListTemp = [...this.state.userSearchResults];
    if (!userListTemp[index].entityAccess) {
      userListTemp[index].entityAccess = {};
    }
    userListTemp[index].entityAccess.employees = event.target.value;
    this.setState({
      userSearchResults: userListTemp,
    });
  };

  handleMasterServicesChange = (event, index) => {
    let userListTemp = [...this.state.userSearchResults];
    if (!userListTemp[index].entityAccess) {
      userListTemp[index].entityAccess = {};
    }
    userListTemp[index].entityAccess.masterServices = event.target.value;

    this.setState({
      userSearchResults: userListTemp,
      // masterServices: event.target.value,
    });
  };

  handleVisitsChange = (event, index) => {
    let userListTemp = [...this.state.userSearchResults];
    if (!userListTemp[index].entityAccess) {
      userListTemp[index].entityAccess = {};
    }

    userListTemp[index].entityAccess.visits = event.target.value;
    this.setState({
      userSearchResults: userListTemp,
      // visits: event.target.value,
    });
  };

  handleClearancesChange = (event, index) => {
    let userListTemp = [...this.state.userSearchResults];
    if (!userListTemp[index].entityAccess) {
      userListTemp[index].entityAccess = {};
    }
    userListTemp[index].entityAccess.clearances = event.target.value;
    this.setState({
      userSearchResults: userListTemp,
      // clearances: event.target.value,
    });
  };

  handleProtocolsChange = (event, index) => {
    let userListTemp = [...this.state.userSearchResults];
    if (!userListTemp[index].entityAccess) {
      userListTemp[index].entityAccess = {};
    }
    userListTemp[index].entityAccess.protocols = event.target.value;
    this.setState({
      userSearchResults: userListTemp,
      // protocols: event.target.value,
    });
  };

  getAllCompanies = async (t) => {
    try {
      let res = await this.props.apiCallPost("/company/getAll");
      if (res) {
        this.setState({
          companyList: res,
        });
      }
    } catch (err) {
      this.setState({
        companyList: [],
      });
      cogoToast.error("Error Searching Companies");
    }
  };

  renderPermissionModal = () => {
    let index = this.state.indexValue;
    return (
      <Dialog
        open={this.state.isPermissionModalOpen}
        onClose={() => {
          this.setState({ isPermissionModalOpen: false });
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>User Permissions</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Company</InputLabel>
            <Select
              multiple
              value={
                this.state.userSearchResults[index]?.entityAccess
                  ?.companyPermissions || []
              }
              onChange={(e) => this.handleCompanyPermissionsChange(e, index)}
              input={<OutlinedInput label="Company Permissions" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {this.state.permissionOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {/* <Checkbox
                    checked={
                      this.state.userSearchResults[
                        index
                      ]?.entityAccess?.companyPermissions?.indexOf(option) ||
                      -2 > -1
                    }
                  /> */}
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Company Access</InputLabel>
            <Select
              multiple
              value={
                this.state.userSearchResults[index]?.entityAccess
                  ?.companyAccess || []
              }
              onChange={(e) => this.handleCompanyAccessChange(e, index)}
              input={<OutlinedInput label="Company Access" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {this.state.companyList.map((company) => (
                <MenuItem
                  key={company?.companyName}
                  value={company?.companyName}
                >
                  {/* <Checkbox
                    checked={
                      this.state.userSearchResults[index]?.entityAccess
                        ?.companyAccess
                        ? this.state.userSearchResults[
                            index
                          ]?.entityAccess?.companyAccess.indexOf(
                            company?.companyName
                          ) > -1
                        : null
                    }
                  /> */}
                  <ListItemText primary={company?.companyName} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Employees</InputLabel>
            <Select
              multiple
              value={
                this.state.userSearchResults[index]?.entityAccess?.employees ||
                []
              }
              onChange={(e) => this.handleEmployeesChange(e, index)}
              input={<OutlinedInput label="Employees" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {this.state.permissionOptions.map((company) => (
                <MenuItem key={company} value={company}>
                  {/* <Checkbox
                    checked={
                      this.state.userSearchResults[
                        index
                      ]?.entityAccess?.employees.indexOf(company) > -1
                    }
                  /> */}
                  <ListItemText primary={company} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Master Services </InputLabel>
            <Select
              multiple
              value={
                this.state.userSearchResults[index]?.entityAccess
                  ?.masterServices || []
              }
              onChange={(e) => this.handleMasterServicesChange(e, index)}
              input={<OutlinedInput label="Master Services" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {this.state.permissionOptions.map((x) => (
                <MenuItem key={x} value={x}>
                  {/* <Checkbox
                    checked={
                      this.state.userSearchResults[
                        index
                      ]?.entityAccess?.masterServices.indexOf(x) > -1
                    }
                  /> */}
                  <ListItemText primary={x} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Visits </InputLabel>
            <Select
              multiple
              value={
                this.state.userSearchResults[index]?.entityAccess?.visits || []
              }
              onChange={(e) => this.handleVisitsChange(e, index)}
              input={
                <OutlinedInput
                  label="Visits
"
                />
              }
              renderValue={(selected) => selected.join(", ")}
            >
              {this.state.permissionOptions.map((x) => (
                <MenuItem key={x} value={x}>
                  {/* <Checkbox
                    checked={
                      this.state.userSearchResults[
                        index
                      ]?.entityAccess?.visits.indexOf(x) > -1
                    }
                  /> */}
                  <ListItemText primary={x} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Clearances </InputLabel>
            <Select
              multiple
              value={
                this.state.userSearchResults[index]?.entityAccess?.clearances ||
                []
              }
              onChange={(e) => this.handleClearancesChange(e, index)}
              input={
                <OutlinedInput
                  label="Clearances
"
                />
              }
              renderValue={(selected) => selected.join(", ")}
            >
              {this.state.permissionOptions.map((x) => (
                <MenuItem key={x} value={x}>
                  {/* <Checkbox
                    checked={
                      this.state.userSearchResults[
                        index
                      ]?.entityAccess?.clearances.indexOf(x) > -1
                    }
                  /> */}
                  <ListItemText primary={x} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Protocols </InputLabel>
            <Select
              multiple
              value={
                this.state.userSearchResults[index]?.entityAccess?.protocols ||
                []
              }
              onChange={(e) => this.handleProtocolsChange(e, index)}
              input={
                <OutlinedInput
                  label="Visits
"
                />
              }
              renderValue={(selected) => selected.join(", ")}
            >
              {this.state.permissionOptions.map((x) => (
                <MenuItem key={x} value={x}>
                  {/* <Checkbox
                    checked={
                      this.state.userSearchResults[
                        index
                      ]?.entityAccess?.protocols.indexOf(x) > -1
                    }
                  /> */}
                  <ListItemText primary={x} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.setState({ isPermissionModalOpen: false });
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.updateUserAccess(this.state.currentUser._id, {
                companyPermissions:
                  this.state.userSearchResults[index]?.entityAccess
                    ?.companyPermissions,
                companyAccess:
                  this.state.userSearchResults[index]?.entityAccess
                    ?.companyAccess,
                employees:
                  this.state.userSearchResults[index]?.entityAccess?.employees,
                masterServices:
                  this.state.userSearchResults[index]?.entityAccess
                    ?.masterServices,
                visits:
                  this.state.userSearchResults[index]?.entityAccess?.visits,
                clearances:
                  this.state.userSearchResults[index]?.entityAccess?.clearances,
                protocols:
                  this.state.userSearchResults[index]?.entityAccess?.protocols,
              });
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  renderTable = () => {
    if (!this.state.userSearchResults.length) {
      return <NoResultView />;
    }
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell> <TableCell>Email</TableCell>{" "}
            <TableCell>Employer</TableCell>{" "}
            <TableCell>User Permissions</TableCell>{" "}
            <TableCell>Is Active?</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.userSearchResults.map((each, index) => {
            let companyElem = null;
            if (!each.data.isExternal) {
              companyElem = (
                <Typography variant="overline">Internal User</Typography>
              );
            } else {
              let company = each.data.company;
              if (company) {
                let name = each.data.company.companyName;
                let url = `/employers/${each.data.company._id}`;
                companyElem = (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="overline">{name}</Typography>{" "}
                    <IconButton
                      size="small"
                      href={url}
                      variant="outlined"
                      // target="non_blank"
                    >
                      <Iconify icon="mdi:arrow-top-right" />
                    </IconButton>
                  </Stack>
                );
              } else {
                companyElem = null;
              }
            }
            return (
              <TableRow>
                <TableCell>
                  <Typography variant="overline">{each.data.name}</Typography>
                </TableCell>
                <TableCell>{each.data.email}</TableCell>
                <TableCell>{companyElem}</TableCell>
                <TableCell>
                  <Button
                    size="small"
                    variant="outlined"
                    disabled={this.state.isDoingAction}
                    color={each.data.isDeactivated ? "error" : "success"}
                    onClick={() => {
                      this.setState(
                        {
                          isPermissionModalOpen: true,
                          indexValue: index,
                        },
                        () => {
                          this.getAllCompanies();
                        }
                      );
                    }}
                  >
                    View
                  </Button>
                </TableCell>

                <TableCell>
                  <Tooltip
                    title={
                      each.data.isDeactivated
                        ? "Click To Activate"
                        : "Click To Deactivate"
                    }
                  >
                    <Button
                      size="small"
                      variant="outlined"
                      disabled={this.state.isDoingAction}
                      color={each.data.isDeactivated ? "error" : "success"}
                      onClick={() => {
                        this.updateUser(each.data._id, index, [
                          {
                            key: "isDeactivated",
                            value: !each.data.isDeactivated,
                          },
                        ]);
                      }}
                    >
                      {each.data.isDeactivated
                        ? "Currently Inactive"
                        : "Currently Active"}
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <Stack spacing={2}>
        <UserCreate
          isOpen={this.state.openUserCreate}
          handleClose={() => {
            this.setState({ openUserCreate: false });
          }}
          onCreate={(user) => {
            let uu = {
              mainText: user.name,
              secondaryText: user.email,
              data: user,
            };
            this.setState({
              userSearchResults: [uu, ...this.state.userSearchResults],
              openUserCreate: false,
            });
          }}
        />
        {this.renderHeader()} {this.renderFilterBox()} {this.renderTable()}
        {this.renderPermissionModal()}
      </Stack>
    );
  }
}

export default WithAPICall(UserList);
