import { Container } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { WithAPICall } from "../utils/apiUtil";
import { Grid, Typography, Stack, Button } from "@mui/material";
import Visits from "./Visits";
import ModalHOC from "./ModalHOC";
import TasksAiSystem from "./TasksAiSystem";
import TaskStatus from "./TaskStatus";
import ProviderVisits from "./ProviderVisits";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import HOCContext from "./contexts/HOCContext";
import cogoToast from "cogo-toast";
import { LoadingScreen } from "src/components/loading-screen";
import TokenContext from "src/TokenContext";
import VisitsStatusReportWrapper from "./VisitStatusReportWrapper";
import Walktour from "src/components/walktour/walktour";
import { ACTIONS, EVENTS, STATUS } from "react-joyride";
import Iconify from "src/components/iconify";

const INITIAL_STEPS = [
  {
    target: "#STEP_1",
    title: "Dashboard",
    disableBeacon: true,
    content: (
      <Typography sx={{ color: "text.secondary" }}>
        You can view charts corresponding to employee visits here. This
        includes...
      </Typography>
    ),
  },
  {
    target: "#STEP_2",
    title: "Date Picker",
    disableBeacon: true,
    content: (
      <Typography sx={{ color: "text.secondary" }}>
        You can select the date range you want to view the charts for.
      </Typography>
    ),
  },

  {
    target: "#STEP_3",
    title: "Revisit This Walkthrough",
    disableBeacon: true,
    content: (
      <Typography sx={{ color: "text.secondary" }}>
        That concludes the quick walkthrough. You can view this anytime by
        clicking here.
      </Typography>
    ),
  },
];

const shortcutsItems = [
  {
    label: "Today",
    getValue: () => {
      const today = moment();
      return [today, today];
    },
  },
  {
    label: "Last 3 Days",
    getValue: () => {
      const today = moment();
      return [today.clone().subtract(3, "days"), today];
    },
  },
  {
    label: "Next 3 Days",
    getValue: () => {
      const today = moment();
      return [today, today.clone().add(3, "days")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = moment();
      return [today.clone().subtract(7, "days"), today];
    },
  },
  {
    label: "Next 7 Days",
    getValue: () => {
      const today = moment();
      return [today, today.clone().add(7, "days")];
    },
  },
  {
    label: "Last 30 Days",
    getValue: () => {
      const today = moment();
      return [today.clone().subtract(30, "days"), today];
    },
  },
  {
    label: "Next 30 Days",
    getValue: () => {
      const today = moment();
      return [today, today.clone().add(30, "days")];
    },
  },
  {
    label: "This Month",
    getValue: () => {
      const today = moment();
      return [today.clone().startOf("month"), today.clone().endOf("month")];
    },
  },
  {
    label: "Next Month",
    getValue: () => {
      const today = moment();
      return [
        today.clone().add(1, "month").startOf("month"),
        today.clone().add(1, "month").endOf("month"),
      ];
    },
  },
  {
    label: "Last Month",
    getValue: () => {
      const today = moment();
      return [
        today.clone().subtract(1, "month").startOf("month"),
        today.clone().subtract(1, "month").endOf("month"),
      ];
    },
  },
  {
    label: "This Week",
    getValue: () => {
      const today = moment();
      return [today.clone().startOf("week"), today.clone().endOf("week")];
    },
  },
  {
    label: "Next Week",
    getValue: () => {
      const today = moment();
      return [
        today.clone().add(1, "week").startOf("week"),
        today.clone().add(1, "week").endOf("week"),
      ];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = moment();
      return [
        today.clone().subtract(1, "week").startOf("week"),
        today.clone().subtract(1, "week").endOf("week"),
      ];
    },
  },
  {
    label: "Reset",
    getValue: () => [null, null],
  },
];

export default WithAPICall(function Dashboard({ apiCallPost }) {
  const { currentUser } = useContext(TokenContext);
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState(moment().startOf("month"));
  const [to, setTo] = useState(moment().add(7, "days"));
  const [companyIds, setCompanyIds] = useState([currentUser.company._id]);
  const [companies, setCompanies] = useState([]);
  const [runJoyRide, setRunJoyRide] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState(INITIAL_STEPS);
  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRunJoyRide(false);
      setStepIndex(0);
    }
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const res = await apiCallPost("/company/search", {
          query: "",
        });
        setCompanies(res);
      } catch (error) {
        cogoToast.error(
          "Could not load employers. Please refresh and try again."
        );
      }
      setLoading(false);
    };
    init();
  }, []);

  if (loading) return <LoadingScreen />;

  return (
    <Container maxWidth={10000}>
      <Walktour
        key={steps.length}
        continuous
        showProgress
        showSkipButton
        disableOverlayClose
        callback={handleJoyrideCallback}
        run={runJoyRide}
        stepIndex={stepIndex}
        steps={steps}
      />

      <HOCContext.Provider value={{ from, to, companyIds }}>
        <div id="STEP_1">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h4" sx={{ mb: 3 }}>
                Dashboard
              </Typography>
            </Grid>
            <Grid item xs={6} alignItems="end">
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                display="flex"
                justifyContent="end"
              >
                <div id="STEP_2">
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateRangePicker
                      sx={{ width: 200 }}
                      slotProps={{
                        shortcuts: {
                          changeImportance: "accept",
                          dense: true,
                          subheader: (
                            <Typography variant="caption" sx={{ p: 2 }}>
                              Shortcuts
                            </Typography>
                          ),
                          items: shortcutsItems,
                        },
                        actionBar: { actions: [] },
                      }}
                      value={[from, to]}
                      localeText={{
                        start: "From",
                        end: "To",
                      }}
                      onChange={(newValue) => {
                        setFrom(newValue[0]);
                        setTo(newValue[1]);
                      }}
                    />
                  </LocalizationProvider>
                </div>
                {/* <Button
                  variant="contained"
                  startIcon={<Iconify icon="mingcute:question-fill" />}
                  id="STEP_3"
                  onClick={() => {
                    setRunJoyRide(true);
                  }}
                >
                  Quick walkthrough
                </Button> */}
              </Stack>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <ModalHOC header="Visits">
                <Visits />
              </ModalHOC>
            </Grid>
            <Grid item xs={6}>
              <ModalHOC header="Provider Visits">
                <ProviderVisits />
              </ModalHOC>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <ModalHOC header="Visits">
                <VisitsStatusReportWrapper />
              </ModalHOC>
            </Grid>
          </Grid>
        </div>
      </HOCContext.Provider>
    </Container>
  );
});
