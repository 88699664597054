import React from "react";
import {
  FormControl,
  Box,
  InputLabel,
  Select,
  MenuItem,
  Card,
  Grid,
  ListItemText,
  Typography,
} from "@mui/material";
import { formFoxReasonForTest } from "src/assets/data/formfox";
import { WithAPICall } from "../utils/apiUtil";
import cogoToast from "cogo-toast";
import { Stack } from "@mui/system";

class ProtocolFormFox extends React.Component {
  state = { ...this.props.data };

  updateScalarValue = async (key, value) => {
    cogoToast.loading("Updating Form Fox Details...");
    try {
      const res = await this.props.apiCallPost(
        "/company-protocol/companyProtocolUpdateScalarValue",
        {
          companyProtocolId: this.props.protocolId,
          key: key,
          value: value,
        }
      );
      this.setState({ ...res });
      cogoToast.success("Updated Successfully.");
    } catch (err) {
      cogoToast.error("Error Updating Protocol Data");
      console.log(err);
    }
  };

  render() {
    return (
      <Stack component={Card} spacing={3} sx={{ p: 3 }}>
        <Grid container>
          <Grid xs={12} md={4}>
            <ListItemText
              primary={"Form Fox Details"}
              secondary={""}
              primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
              secondaryTypographyProps={{ component: "span" }}
            />
          </Grid>
          <Grid xs={12} md={8}>
            <Stack
              spacing={1}
              sx={{
                p: 3,
                borderRadius: 2,
                bgcolor: "background.neutral",
              }}
            >
              <Grid container spacing={2} width="100%">
                <Grid item xs={3}>
                  <Typography variant="subtitle2">Reason For Test</Typography>
                </Grid>
                <Grid item xs={9}>
                  <FormControl fullWidth>
                    <Select
                      width="100%"
                      value={
                        this.state.formFoxReasonForTest?.id ||
                        this.props.data.formFoxReasonForTest?.id
                      }
                      onChange={(e) => {
                        const ffReason = formFoxReasonForTest.find(
                          (f) => f.id === e.target.value
                        );
                        if (ffReason) {
                          this.updateScalarValue(
                            "formFoxReasonForTest",
                            ffReason
                          );
                        }
                      }}
                    >
                      {formFoxReasonForTest.map((testType) => (
                        <MenuItem key={testType.id} value={testType.id}>
                          {testType.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    );
  }
}

export default WithAPICall(ProtocolFormFox);
