import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import Iconify from "src/components/iconify";
import FileThumbnail from "src/components/file-thumbnail";
import { niceDateTime, niceFileSize } from "./fn";
import { LinearProgress, TextField } from "@mui/material";
import _ from "lodash";
import { WithAPICall } from "./apiUtil";
import cogoToast from "cogo-toast";

class GenericFileListItem extends React.Component {
  state = { isLoading: true };
  componentDidMount() {
    this.setState(
      {
        ...this.props.data,
      },
      () => {
        this.setState({
          isLoading: false,
        });
      }
    );
  }
  updateFileDescription = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      await this.props.apiCallPost("/files/editDescription", {
        fileId: this.state._id,
        description: this.state.description,
      });
      this.setState(
        {
          isLoading: false,
        },
        () => {
          this.props.onDescriptionChange(this.state.description);
        }
      );
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Updating File Description");
      this.setState({
        description: this.props.data.description,
        isLoading: false,
      });
    }
  };
  renderText = () => {
    return (
      <ListItemText
        primary={
          <>
            <TextField
              size="small"
              value={this.state.description}
              onChange={(e) =>
                this.setState({
                  description: e.target.value,
                })
              }
              onBlur={() => {
                if (this.state.description !== this.props.data.description) {
                  this.updateFileDescription();
                }
              }}
            />
          </>
        }
        secondary={
          <>
            {niceFileSize(this.state.sizeBytes)}
            <Box
              sx={{
                mx: 0.75,
                width: 2,
                height: 2,
                borderRadius: "50%",
                bgcolor: "currentColor",
              }}
            />
            Uploaded: {niceDateTime(this.state.createdAt)} by{" "}
            {this.state.createdBy.name}
          </>
        }
        primaryTypographyProps={{
          noWrap: true,
          typography: "subtitle2",
        }}
        secondaryTypographyProps={{
          mt: 0.5,
          component: "span",
          alignItems: "center",
          typography: "caption",
          color: "text.disabled",
          display: "inline-flex",
        }}
      />
    );
  };
  renderAction = () => {
    if (this.props.data.entityType === "VISIT_OUTPUT_FILE") {
      return (
        <Box>
          {/* <IconButton
            target="non_blank"
            onClick={() => this.props.onSelectFile(this.props.data._id)}
          >
            <Iconify icon="iconoir:multiple-pages" />
          </IconButton>{" "} */}{" "}
          <IconButton
            color="error"
            // target="non_blank"
            onClick={() => this.props.dissociateFile()}
          >
            <Iconify icon="solar:trash-bin-trash-broken" />
          </IconButton>
          <IconButton
            href={this.state.psUrl}
            // target="non_blank"
          >
            <Iconify icon="eva:cloud-download-fill" />
          </IconButton>
        </Box>
      );
    }
    return (
      <Box>
        <IconButton
          color="error"
          // target="non_blank"
          onClick={() => this.props.dissociateFile()}
        >
          <Iconify icon="solar:trash-bin-trash-broken" />
        </IconButton>
        <IconButton
          href={this.state.psUrl}
          // target="non_blank"
        >
          <Iconify icon="eva:cloud-download-fill" />
        </IconButton>
      </Box>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }

    return (
      <>
        <Stack
          component={Paper}
          variant="outlined"
          spacing={1}
          direction={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "unset", sm: "center" }}
          sx={{
            borderRadius: 2,
            bgcolor: "unset",
            position: "relative",
            p: { xs: 2.5, sm: 2 },
          }}
        >
          <FileThumbnail
            file={_.last(this.state.originalName.split("."))}
            sx={{ width: 36, height: 36, mr: 1 }}
          />

          {this.renderText()}

          {this.renderAction()}
        </Stack>
      </>
    );
  }
}

export default WithAPICall(GenericFileListItem);
