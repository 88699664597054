import React from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Iconify from "src/components/iconify";
import { WithAPICall } from "../utils/apiUtil";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import { Box } from "@mui/system";
import { Button, LinearProgress } from "@mui/material";
import cogoToast from "cogo-toast";
import { View500 } from "src/sections/error";
import ProtocolCriteria from "./ProtocolCriteria";
import ProtocolServices from "./ProtocolServices";
import ProtocolSettings from "./ProtocolSettings";
import ProtocolNotes from "./ProtocolNotes";
import ProtocolClearances from "./ProtocolClearances";
import ProtocolPoolDetails from "./ProtocolPoolDetails";
import { addUrlParams, getUrlParams } from "src/utils/url-params";
import ProtocolFormFox from "./ProtocolFormFox";
import PoolTimeline from "./PoolTimeline";
import ProtocolAddonFees from "./ProtocolAddonFees";

class ProtocolDetails extends React.Component {
  state = {
    currentTab: "INFO",
    protocolData: null,
    isLoading: true,
    isError: false,
  };
  async componentDidMount() {
    await this.getById();
    const params = getUrlParams();
    if (params.currentTab) {
      this.setState({
        currentTab: params.currentTab,
      });
    }
  }
  getById = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost(
        "/company-protocol/getCompanyProtocolById",
        {
          companyProtocolId: this.props.protocolId,
        }
      );
      this.setState({ protocolData: res, isLoading: false });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Protocol Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };

  tabs = [
    {
      value: "INFO",
      label: "Info",
      icon: <Iconify icon="solar:info-circle-broken" width={24} />,
    },
    {
      value: "CRITERIA",
      label: "Criteria",
      icon: <Iconify icon="solar:settings-broken" width={24} />,
    },
    {
      value: "SERVICES",
      label: "Services In Scope",
      icon: <Iconify icon="solar:document-medicine-outline" width={24} />,
    },
    {
      value: "ADDON_FEES",
      label: "Add-On Fees",
      icon: <Iconify icon="solar:dollar-outline" width={24} />,
    },
    {
      value: "CLEARANCE",
      label: "Clearances",
      icon: <Iconify icon="solar:clipboard-broken" width={24} />,
    },
    {
      value: "NOTES",
      label: "Notes & Documents",
      icon: <Iconify icon="solar:document-add-linear" width={24} />,
    },
    {
      value: "POOLS",
      label: "Pool Management",
      icon: <Iconify icon="solar:settings-minimalistic-broken" width={24} />,
    },
    {
      value: "FORMFOX",
      label: "Form Fox",
      icon: (
        <div>
          <img src="/assets/icons/brands/ic_brand_formfox.png" alt="formfox" />
        </div>
      ),
    },
    {
      value: "TIMELINE",
      label: "Event Log",
      icon: <Iconify icon="gravity-ui:list-timeline" width={24} />,
    },
    /*
    {
      value: "USERS",
      label: "Users",
      icon: <Iconify icon="solar:login-broken" width={24} />,
    },
    
    */
  ];
  renderTabs = () => {
    return (
      <Tabs
        value={this.state.currentTab}
        onChange={(_, value) => {
          this.setState({ currentTab: value });
          addUrlParams({ currentTab: value });
        }}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        {this.tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            icon={tab.icon}
            value={tab.value}
          />
        ))}
      </Tabs>
    );
  };
  renderTab = () => {
    if (!this.state.protocolData) return null;
    let companyId = this.state.protocolData?.company._id;
    switch (this.state.currentTab) {
      case "INFO":
        return (
          <ProtocolSettings
            protocolId={this.props.protocolId}
            companyId={companyId}
            data={this.state.protocolData}
            onUpdate={(key, value) => {
              let protocolData = this.state.protocolData;
              protocolData[key] = value;
              this.setState({ protocolData });
            }}
          />
        );
      case "CRITERIA":
        return (
          <ProtocolCriteria
            protocolId={this.props.protocolId}
            companyId={companyId}
            data={this.state.protocolData}
            onUpdate={(key, value) => {
              let protocolData = this.state.protocolData;
              protocolData[key] = value;
              this.setState({ protocolData });
            }}
          />
        );
      case "SERVICES":
        return (
          <ProtocolServices
            protocolId={this.props.protocolId}
            companyId={companyId}
            data={this.state.protocolData}
            onUpdate={(key, value) => {
              let protocolData = this.state.protocolData;
              protocolData[key] = value;
              this.setState({ protocolData });
            }}
          />
        );
      case "ADDON_FEES":
        return (
          <ProtocolAddonFees
            key={this.state.protocolData.addOnFees.length}
            protocolId={this.props.protocolId}
            companyId={companyId}
            data={this.state.protocolData}
            onUpdate={(key, value) => {
              let protocolData = this.state.protocolData;
              protocolData[key] = value;
              this.setState({ protocolData });
            }}
          />
        );
      case "NOTES":
        return (
          <ProtocolNotes
            protocolId={this.props.protocolId}
            companyId={companyId}
            data={this.state.protocolData}
            onUpdate={(key, value) => {
              let protocolData = this.state.protocolData;
              protocolData[key] = value;
              this.setState({ protocolData });
            }}
          />
        );
      case "CLEARANCE":
        return (
          <ProtocolClearances
            protocolId={this.props.protocolId}
            companyId={companyId}
            data={this.state.protocolData}
            onUpdate={(key, value) => {
              let protocolData = this.state.protocolData;
              protocolData[key] = value;
              this.setState({ protocolData });
            }}
          />
        );
      case "POOLS":
        return (
          <ProtocolPoolDetails
            protocolId={this.props.protocolId}
            companyId={companyId}
            data={this.state.protocolData}
            onUpdate={(key, value) => {
              let protocolData = this.state.protocolData;
              protocolData[key] = value;
              this.setState({ protocolData });
            }}
          />
        );
      case "FORMFOX":
        return (
          <ProtocolFormFox
            protocolId={this.props.protocolId}
            companyId={companyId}
            data={this.state.protocolData}
          />
        );
      case "TIMELINE":
        return <PoolTimeline data={this.state.protocolData} />;
      default:
        return <div>Not Live</div>;
    }
  };
  renderHeader = () => {
    let companyName =
      this.state.protocolData?.company.companyInformalName ||
      this.state.protocolData?.company.companyName;
    let protocolName =
      this.state.protocolData?.companyProtocolName || "Protocol";
    let companyId = this.state.protocolData?.company._id;

    return (
      <CustomBreadcrumbs
        heading={protocolName}
        links={[
          { name: "Dashboard", href: "/" },
          { name: `${companyName}`, href: `/employers/${companyId}` },
          { name: `Details: ${protocolName}` },
        ]}
        action={
          <Box>
            <Button
              disabled={this.state.isLoading}
              onClick={() => this.getById()}
              variant="contained"
              startIcon={
                <Iconify icon="mingcute:refresh-anticlockwise-1-line" />
              }
            >
              Refresh Data
            </Button>
          </Box>
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <>
        {this.renderHeader()}
        {this.renderTabs()}
        {this.renderTab()}
      </>
    );
  }
}

export default WithAPICall(ProtocolDetails);
