import { create } from "zustand";

const useVisitStore = create((set) => {
  return {
    visit: null,
    error: null,
    status: "idle",

    loadVisit: async (visit) => {
      set({ status: "loading" });
      try {
        set({ visit, status: "loaded" });
      } catch (error) {
        set({ error: error.message, status: "failed" });
      }
    },
  };
});

export default useVisitStore;
