import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import { Box, Stack } from "@mui/system";
import {
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Chip,
  FormControlLabel,
  Switch,
  TextField,
  Grid,
  ListItemText,
  Card,
  LinearProgress,
} from "@mui/material";
import Iconify from "src/components/iconify";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { SingleInputTimeRangeField } from "@mui/x-date-pickers-pro/SingleInputTimeRangeField";
import moment from "moment";
import _ from "lodash";
import cogoToast from "cogo-toast";

class ProviderSchedules extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    weeklyTimings: [],
    isUpdatingTimings: false,
    clipboard: [],
  };
  componentDidMount() {
    this.setState({ ...this.props.data, isLoading: false });
  }
  SETTINGS = [
    {
      subheader: "Scheduling Details",
      caption: "Notes about scheduling",
      items: [
        { id: "schedulingNotes", label: "Notes", type: "TEXT" },
        {
          id: "hasBusinessHoursDATesting",
          label: "Drugs & Alcohol Testing - Regular Hours",
          type: "SWITCH",
        },
      ],
    },
    {
      subheader: "Requires Appointments",
      caption: "Services during regular hours",
      items: [
        {
          id: "hasBusinessHoursDATesting",
          label: "Drug & Alcohol Testing",
          type: "SWITCH",
        },
        {
          id: "requiresBusinessHoursDATestingPreschedule",
          label: "Drugs & Alcohol Testing - Requires Appointments",
          type: "SWITCH",
        },
        {
          id: "hasBusinessHoursMedicalTesting",
          label: "Medical Exams",
          type: "SWITCH",
        },
        {
          id: "requiresBusinessHoursMedicalTestingPreschedule",
          label: "Medical Exams - Requires Appointments",
          type: "SWITCH",
        },
      ],
    },
    {
      subheader: "After Hours",
      caption: "Services outside of regular hours",
      items: [
        {
          id: "hasAfterHoursDATesting",
          label: "Drug & Alcohol Testing",
          type: "SWITCH",
        },
        {
          id: "requiresAfterHoursDATestingPreschedule",
          label: "Drugs & Alcohol Testing - Requires Appointments",
          type: "SWITCH",
        },
        {
          id: "hasAfterHoursMedicalTesting",
          label: "Medical Exams",
          type: "SWITCH",
        },
        {
          id: "requiresAfterHoursMedicalTestingPreschedule",
          label: "Medical Exams - Requires Appointments",
          type: "SWITCH",
        },
      ],
    },
  ];
  renderControl = (type, item) => {
    switch (type) {
      case "SWITCH":
        return (
          <Switch
            disabled={item.nonEditable}
            checked={this.state[item.id]}
            onChange={() => {
              this.setState(
                {
                  [item.id]: !this.state[item.id],
                },
                () => {
                  this.updateScalarValue(item.id, this.state[item.id]);
                }
              );
            }}
          />
        );
      case "TEXT":
        return (
          <TextField
            sx={{
              minWidth: "400px",
            }}
            multiline
            value={this.state[item.id]}
            onChange={(e) => {
              this.setState({
                [item.id]: e.target.value,
              });
            }}
            onBlur={() => {
              this.updateScalarValue(item.id, this.state[item.id]);
            }}
          />
        );
    }
  };
  renderSettings = () => {
    return (
      <Stack component={Card} spacing={6} sx={{ p: 8 }}>
        {this.SETTINGS.map((setting, index) => {
          return (
            <Grid key={setting.subheader} container spacing={3}>
              <Grid xs={12} md={4}>
                <ListItemText
                  primary={setting.subheader}
                  secondary={setting.caption}
                  primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
                  secondaryTypographyProps={{ component: "span" }}
                />
              </Grid>
              <Grid xs={12} md={8}>
                <Stack
                  spacing={1}
                  sx={{
                    p: 3,
                    borderRadius: 2,
                    bgcolor: "background.neutral",
                  }}
                >
                  <>
                    {setting.items.map((item, subIndex) => {
                      if (
                        index === 1 &&
                        subIndex === 1 &&
                        !this.state.hasBusinessHoursDATesting
                      ) {
                        return null;
                      }
                      if (
                        index === 1 &&
                        subIndex === 3 &&
                        !this.state.hasBusinessHoursMedicalTesting
                      ) {
                        return null;
                      }
                      if (
                        index === 2 &&
                        subIndex === 1 &&
                        !this.state.hasAfterHoursDATesting
                      ) {
                        return null;
                      }
                      if (
                        index === 2 &&
                        subIndex === 3 &&
                        !this.state.hasAfterHoursMedicalTesting
                      ) {
                        return null;
                      }

                      return (
                        <FormControlLabel
                          key={item.id}
                          label={item.label}
                          labelPlacement="start"
                          control={this.renderControl(item.type, item)}
                          sx={{
                            m: 0,
                            width: 1,
                            justifyContent: "space-between",
                          }}
                        />
                      );
                    })}
                  </>
                </Stack>
              </Grid>
            </Grid>
          );
        })}
      </Stack>
    );
  };
  updateScalarValue = async (key, value) => {
    try {
      let res = await this.props.apiCallPost(
        "/provider/providerUpdateScalarValue",
        {
          providerId: this.props.data._id,
          key: key,
          value: value,
        }
      );
      if (key === "weeklyTimings") {
        this.setState({ weeklyTimings: res.weeklyTimings });
      }
    } catch (err) {
      cogoToast.error("Error Updating Provider Data");
      console.log(err);
    }
  };
  renderArrayOfSchedules = () => {
    let daysOfWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    let headings = ["Day", "Timings"];
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            {headings.map((each, i) => {
              return <TableCell key={i}>{each}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {daysOfWeek.map((each, i) => {
            return (
              <TableRow key={i}>
                <TableCell>{this.renderWeekdayText(each)}</TableCell>{" "}
                <TableCell>{this.renderTimings(each)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };
  addTiming = async (day) => {
    let wt = this.state.weeklyTimings;
    let wtd = wt.filter((each) => each.dayOfWeek === day);
    // first sort wtd by endTime, which is in HHMM, then pick last one but keep in mind hhmm is a string

    wtd = _.sortBy(wtd, (each) => each.endTime);

    // end time is in hhmm format. pick the last one, and create new object with startTime as end Time and endTime as +1 hour - but keeping in mind that it should not exceed 2359

    let startTime;
    let endTime;
    if (!wtd.length) {
      startTime = "0800";
      endTime = "1700";
    } else {
      let lastTiming = wtd[wtd.length - 1];
      startTime = lastTiming.endTime;
      endTime = moment(startTime, "HHmm").add(1, "hour").format("HHmm");
      if (parseInt(endTime) > 2359) {
        endTime = "2359";
      }
    }

    let payload = {
      dayOfWeek: day,
      startTime: startTime,
      endTime: endTime,
      notes: "",
      apptOnly: false,
      allowsWalkIn: false,
      isOpen24Hrs: false,
    };
    let newArr = [...this.state.weeklyTimings, payload];
    try {
      let res = await this.props.apiCallPost(
        "/provider/providerUpdateScalarValue",
        {
          providerId: this.props.data._id,
          key: "weeklyTimings",
          value: newArr,
        }
      );
      this.setState({
        weeklyTimings: res.weeklyTimings,
      });
    } catch (err) {
      cogoToast.error("Error Updating Schedule");
      console.log(err);
    }
  };
  renderTimings = (day) => {
    let weeklyTimings = this.state.weeklyTimings;
    let timings = weeklyTimings.filter((each) => each.dayOfWeek === day);
    // check if any is open 24 hours
    let isOpen24Hrs = _.find(timings, (each) => each.isOpen24Hrs);
    if (isOpen24Hrs) {
      return <div>{this.renderEachTiming(isOpen24Hrs)}</div>;
    }

    if (!timings.length) {
      return (
        <>
          <Chip
            label={`No availability for ${day}`}
            variant="outlined"
            color="error"
          />
        </>
      );
    }
    return (
      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(2, 1fr)",
          sm: "repeat(3, 1fr)",
        }}
      >
        {timings.map((each, i) => {
          return <div key={i}>{this.renderEachTiming(each)}</div>;
        })}
      </Box>
    );
  };
  renderEachTiming = (timing) => {
    const isSaveEnabled =
      timing.startTime &&
      timing.endTime &&
      moment(timing.startTime, "HHmm", true).isValid() &&
      moment(timing.endTime, "HHmm", true).isValid();

    return (
      <Stack
        spacing={1}
        sx={{
          p: 3,
          borderRadius: 2,
          bgcolor: "background.neutral",
        }}
      >
        <Box
          rowGap={1}
          columnGap={1}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(3, 1fr)",
          }}
        >
          {!timing.isOpen24Hrs && (
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              {" "}
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <SingleInputTimeRangeField
                  sx={{
                    minWidth: "200px",
                  }}
                  value={[
                    timing.startTime ? moment(timing.startTime, "HHmm") : null,
                    timing.endTime ? moment(timing.endTime, "HHmm") : null,
                  ]}
                  label="From - To"
                  onChange={(e) => {
                    let dateRange = e;
                    let startTime = dateRange[0]
                      ? dateRange[0].format("HHmm")
                      : "";
                    let endTime = dateRange[1]
                      ? dateRange[1].format("HHmm")
                      : "";
                    let _id = timing._id;
                    let weeklyTimings = this.state.weeklyTimings;
                    let index = weeklyTimings.findIndex(
                      (each) => each._id === _id
                    );
                    weeklyTimings[index].startTime = startTime;
                    weeklyTimings[index].endTime = endTime;
                    weeklyTimings[index].changed = true;
                    this.setState({ weeklyTimings });
                  }}
                />
              </LocalizationProvider>
              {timing.changed && (
                <IconButton
                  size="small"
                  color="primary"
                  disabled={!isSaveEnabled}
                  sx={{
                    width: 24,
                    height: 24,
                    bgcolor: "primary.main",
                    color: "primary.contrastText",
                    "&:hover": {
                      bgcolor: "primary.dark",
                    },
                  }}
                  onClick={() => {
                    this.updateScalarValue(
                      "weeklyTimings",
                      this.state.weeklyTimings
                    );
                  }}
                >
                  <Iconify icon="mdi:tick" />
                </IconButton>
              )}
            </Stack>
          )}

          <FormControlLabel
            label="Allows Walk-Ins"
            labelPlacement="start"
            control={
              <Switch
                checked={timing.allowsWalkIn}
                onChange={() => {
                  let weeklyTimings = this.state.weeklyTimings;
                  let index = weeklyTimings.findIndex(
                    (each) => each._id === timing._id
                  );
                  weeklyTimings[index].allowsWalkIn =
                    !weeklyTimings[index].allowsWalkIn;
                  this.setState({ weeklyTimings }, () => {
                    this.updateScalarValue(
                      "weeklyTimings",
                      this.state.weeklyTimings
                    );
                  });
                }}
              />
            }
            sx={{
              m: 0,
              width: 1,
              justifyContent: "space-between",
            }}
          />
          <FormControlLabel
            label="Open 24 Hours"
            labelPlacement="start"
            control={
              <Switch
                checked={timing.isOpen24Hrs}
                onChange={() => {
                  let weeklyTimings = this.state.weeklyTimings;
                  let index = weeklyTimings.findIndex(
                    (each) => each._id === timing._id
                  );
                  weeklyTimings[index].isOpen24Hrs =
                    !weeklyTimings[index].isOpen24Hrs;
                  this.setState({ weeklyTimings }, () => {
                    this.updateScalarValue(
                      "weeklyTimings",
                      this.state.weeklyTimings
                    );
                  });
                }}
              />
            }
            sx={{
              m: 0,
              width: 1,
              justifyContent: "space-between",
            }}
          />
        </Box>{" "}
        <Box
          rowGap={1}
          columnGap={1}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
          }}
        >
          <TextField
            multiline
            label="Notes"
            value={timing.notes}
            onChange={(e) => {
              let weeklyTimings = this.state.weeklyTimings;
              let index = weeklyTimings.findIndex(
                (each) => each._id === timing._id
              );
              weeklyTimings[index].notes = e.target.value;
              this.setState({ weeklyTimings });
            }}
            onBlur={() => {
              this.updateScalarValue("weeklyTimings", this.state.weeklyTimings);
            }}
          />{" "}
          <Box>
            {" "}
            <Chip
              color="error"
              label="Delete"
              variant="outlined"
              onDelete={() => {
                let weeklyTimings = this.state.weeklyTimings;
                let index = weeklyTimings.findIndex(
                  (each) => each._id === timing._id
                );
                weeklyTimings.splice(index, 1);
                this.setState({ weeklyTimings }, () => {
                  this.updateScalarValue(
                    "weeklyTimings",
                    this.state.weeklyTimings
                  );
                });
              }}
            />
          </Box>
        </Box>
      </Stack>
    );
  };
  renderWeekdayText = (day) => {
    let wt = this.state.weeklyTimings;
    let wtd = wt.filter((each) => each.dayOfWeek === day);
    let hasSchedulesForDay = wtd && wtd.length;
    let copyElement = (
      <IconButton
        size="small"
        color="primary"
        onClick={() => {
          this.setState({
            clipboard: wtd,
          });
        }}
        sx={{
          width: 24,
          height: 24,
          bgcolor: "secondary.main",
          color: "secondary.contrastText",
          "&:hover": {
            bgcolor: "secondary.dark",
          },
        }}
      >
        <Iconify icon="mingcute:copy-2-fill" />
      </IconButton>
    );
    let hasClipboard = this.state.clipboard && this.state.clipboard.length;
    let dayInClipboard = null;
    if (hasClipboard) {
      dayInClipboard = this.state.clipboard[0].dayOfWeek;
    }

    let clipboardElement = (
      <IconButton
        size="small"
        color="primary"
        onClick={() => {
          let cp = this.state.clipboard;
          let cpd = cp.map((each) => {
            return {
              ...each,
              dayOfWeek: day,
            };
          });
          let weeklyTimings = this.state.weeklyTimings;
          weeklyTimings = [...weeklyTimings, ...cpd];
          this.setState(
            {
              weeklyTimings: weeklyTimings,
            },
            () => {
              this.updateScalarValue("weeklyTimings", weeklyTimings);
            }
          );
        }}
        sx={{
          width: 24,
          height: 24,
          bgcolor: "secondary.main",
          color: "secondary.contrastText",
          "&:hover": {
            bgcolor: "secondary.dark",
          },
        }}
      >
        <Iconify icon="mingcute:paster-fill" />
      </IconButton>
    );
    if (dayInClipboard === day) {
      clipboardElement = null;
    }
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="h6">{day}</Typography>
        <IconButton
          size="small"
          color="primary"
          onClick={() => {
            this.addTiming(day);
          }}
          sx={{
            width: 24,
            height: 24,
            bgcolor: "primary.main",
            color: "primary.contrastText",
            "&:hover": {
              bgcolor: "primary.dark",
            },
          }}
        >
          <Iconify icon="mingcute:add-line" />
        </IconButton>
        {hasSchedulesForDay ? copyElement : null}
        {hasClipboard ? clipboardElement : null}
      </Stack>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <Stack spacing={4} sx={{ p: 3 }}>
        {this.renderArrayOfSchedules()}
        {this.renderSettings()}
      </Stack>
    );
  }
}

export default WithAPICall(ProviderSchedules);
