import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Alert,
  Autocomplete,
  Button,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Box, Stack } from "@mui/system";
import cogoToast from "cogo-toast";
import { LoadingButton } from "@mui/lab";

class UserCreate extends React.Component {
  state = {
    isCreatingInternal: true,
    // company
    isSearchingCompany: false,
    companySearchResults: [],
    currentlyInCompany: null,
    //
    isCreating: false,
  };
  searchCompaniesFTS = async (t) => {
    try {
      let res = await this.props.apiCallPostCancellable("/company/search", {
        query: t,
      });
      if (res) {
        this.setState({
          isSearchingCompany: false,
          companySearchResults: res.map((each) => {
            return {
              mainText: each.companyName,
              secondaryText: `${each.companyInformalName} ${each.companyWebsite}`,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingCompany: false,
        companySearchResults: [],
      });
      cogoToast.error("Error Searching Companies");
    }
  };
  createUser = async () => {
    let apiUrl = "/user/addInternalUser";
    if (!this.state.isCreatingInternal) {
      apiUrl = "/user/addExternalUser";
    }
    let payload = {
      name: this.state.name,
      email: this.state.email,
    };
    if (!this.state.isCreatingInternal) {
      payload.companyId = this.state.currentlyInCompany.data._id;
    }
    try {
      this.setState({
        isCreating: true,
      });
      let res = await this.props.apiCallPost(apiUrl, payload);
      this.setState(
        {
          name: "",
          email: "",
          currentlyInCompany: null,
          companySearchResults: [],
          isCreating: false,
          isSearchingCompany: false,
        },
        () => {
          this.props.onCreate(res);
        }
      );
      cogoToast.success("User Created");
    } catch (err) {
      this.setState({
        isCreating: false,
      });
      console.log(err);
      cogoToast.error("Error Creating User");
    }
  };
  renderCompanySearcher = () => {
    if (this.state.isCreatingInternal) return null;
    return (
      <Autocomplete
        sx={{
          minWidth: "200px",
        }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option?.mainText
        }
        filterOptions={(x) => x}
        options={
          this.state.isSearchingCompany ? [] : this.state.companySearchResults
        }
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={this.state.currentlyInCompany}
        noOptionsText={
          this.state.isSearchingCompany ? "Searching..." : "No results found"
        }
        onChange={(_, value) => {
          this.setState({
            currentlyInCompany: value,
            companySearchResults: [value, ...this.state.companySearchResults],
          });
        }}
        onInputChange={(_, e) => {
          if (e === "") return null;
          if (!e) return null;
          if (e.length < 4) return null;
          this.setState(
            {
              isSearchingCompany: true,
            },
            () => {
              this.searchCompaniesFTS(e);
            }
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label="Search Employers" fullWidth />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ wordWrap: "break-word" }}>
                  <Box component="span">{option?.mainText}</Box>
                  <Typography variant="body2" color="text.secondary">
                    {option?.secondaryText}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    );
  };
  renderSwitch = () => {
    return (
      <Stack spacing={2} direction="row" alignItems="center">
        <Typography variant="overline" gutterBottom>
          Creating Internal User
        </Typography>
        <Switch
          checked={this.state.isCreatingInternal}
          onChange={(e) => {
            this.setState({
              isCreatingInternal: e.target.checked,
            });
          }}
        />
      </Stack>
    );
  };
  renderName = () => {
    return (
      <TextField
        label="Name"
        variant="outlined"
        fullWidth
        required
        value={this.state.name}
        onChange={(e) => {
          this.setState({
            name: e.target.value,
          });
        }}
      />
    );
  };
  renderEmail = () => {
    return (
      <TextField
        label="Email"
        variant="outlined"
        fullWidth
        required
        value={this.state.email}
        onChange={(e) => {
          this.setState({
            email: e.target.value,
          });
        }}
      />
    );
  };
  renderButton = () => {
    if (!this.state.name) {
      return <Alert severity="warning">Name is required</Alert>;
    }
    if (!this.state.email) {
      return <Alert severity="warning">Email is required</Alert>;
    }
    if (!this.state.isCreatingInternal && !this.state.currentlyInCompany) {
      return <Alert severity="warning">Employer is required</Alert>;
    }
    return (
      <LoadingButton loading={this.state.isCreating} onClick={this.createUser}>
        Create
      </LoadingButton>
    );
  };
  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={() => {
          this.setState(
            {
              name: "",
              email: "",
              currentlyInCompany: null,
              companySearchResults: [],
              isCreating: false,
              isSearchingCompany: false,
            },
            () => {
              this.props.handleClose();
            }
          );
        }}
      >
        <DialogTitle>Create A New User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the details below to add a new user. <br />
            <br />
            <br />
          </DialogContentText>
          <Stack spacing={2}>
            {this.renderName()}
            {this.renderEmail()}
            {this.renderSwitch()}
            {this.renderCompanySearcher()}
          </Stack>
        </DialogContent>{" "}
        <DialogActions>
          <Button
            onClick={() => {
              this.setState(
                {
                  name: "",
                  email: "",
                  currentlyInCompany: null,
                  companySearchResults: [],
                  isCreating: false,
                  isSearchingCompany: false,
                },
                () => {
                  this.props.handleClose();
                }
              );
            }}
            disabled={this.state.isCreating}
          >
            Cancel
          </Button>
          {this.renderButton()}
        </DialogActions>
      </Dialog>
    );
  }
}

export default WithAPICall(UserCreate);
