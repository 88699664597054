import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import { Button, LinearProgress, Typography } from "@mui/material";
import { Stack, Box } from "@mui/system";
import { UploadBox } from "src/components/upload";
import { LoadingButton } from "@mui/lab";
import Iconify from "src/components/iconify";
import cogoToast from "cogo-toast";
import { NoResultView, View500 } from "src/sections/error";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { niceDateTime, niceFileSize } from "../utils/fn";
import moment from "moment";
import {
  Alert,
  Autocomplete,
  Card,
  Chip,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";

class ListDocuments extends React.Component {
  state = {
    isLoading: false,
    isUploading: false,
    filesToBeUploaded: [],
    uploadCount: 0,
    uploadProgress: null,
    statusIn: ["UNLINKED", "PARTIALLY_LINKED"],
    uploadedIn: [moment().subtract(1, "month"), moment().add(1, "month")],
  };
  STATUS_OPTIONS = ["UNLINKED", "PARTIALLY_LINKED", "ALL_LINKED"];
  COLUMNS = [
    {
      headerName: "File Name",
      field: "originalName",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Status",
      field: "processStatus",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Size",
      sizeBytes: "sizeBytes",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
      cellRenderer: (row) => {
        return niceFileSize(row.data.sizeBytes);
      },
    },
    {
      headerName: "Uploaded On",
      field: "createdAt",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agDateColumnFilter",
      cellRenderer: (row) => {
        return niceDateTime(row.data.createdAt);
      },
    },
    {
      headerName: "Uploaded By",
      field: "uploadedBy",
      valueGetter: (params) => {
        return params.data.createdBy.name;
      },
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "View",
      field: "psUrl",
      cellRenderer: (row) => {
        return (
          <Button
            href={row.data.psUrl}
            size="small"
            // target="non_blank"
          >
            Download
          </Button>
        );
      },
    },
    {
      headerName: "Details",
      field: "psUrl",
      cellRenderer: (row) => {
        return (
          <Button
            href={`/documents/${row.data._id}`}
            size="small"
            // target="non_blank"
          >
            View Details
          </Button>
        );
      },
    },
  ];
  componentDidMount() {
    this.getByEntityId();
  }
  getByEntityId = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost("/files/getByEntityType", {
        entityType: "VISIT_OUTPUT_FILE",
        uploadedIn: this.state.uploadedIn,
        statusIn: this.state.statusIn,
      });
      this.setState({ list: res, isLoading: false });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Files");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };

  renderStatusSelection = () => {
    return (
      <Select
        placeholder="Document Status"
        multiple
        value={this.state.statusIn}
        onChange={(e) => {
          let v = e.target.value;
          this.setState({
            statusIn: v,
          });
        }}
        sx={{
          minWidth: "150px",
        }}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip size="small" key={value} label={value} />
            ))}
          </Box>
        )}
      >
        {this.STATUS_OPTIONS.map((item) => (
          <MenuItem key={item} value={item} size="small">
            {item}
          </MenuItem>
        ))}
      </Select>
    );
  };

  renderDateRangeSearch = () => {
    const shortcutsItems = [
      {
        label: "Today",
        getValue: () => {
          const today = moment();
          return [today, today];
        },
      },
      {
        label: "Last 3 Days",
        getValue: () => {
          const today = moment();
          return [today.clone().subtract(3, "days"), today];
        },
      },
      {
        label: "Next 3 Days",
        getValue: () => {
          const today = moment();
          return [today, today.clone().add(3, "days")];
        },
      },
      {
        label: "Last 7 Days",
        getValue: () => {
          const today = moment();
          return [today.clone().subtract(7, "days"), today];
        },
      },
      {
        label: "Next 7 Days",
        getValue: () => {
          const today = moment();
          return [today, today.clone().add(7, "days")];
        },
      },
      {
        label: "Last 30 Days",
        getValue: () => {
          const today = moment();
          return [today.clone().subtract(30, "days"), today];
        },
      },
      {
        label: "Next 30 Days",
        getValue: () => {
          const today = moment();
          return [today, today.clone().add(30, "days")];
        },
      },
      {
        label: "This Month",
        getValue: () => {
          const today = moment();
          return [today.clone().startOf("month"), today.clone().endOf("month")];
        },
      },
      {
        label: "Next Month",
        getValue: () => {
          const today = moment();
          return [
            today.clone().add(1, "month").startOf("month"),
            today.clone().add(1, "month").endOf("month"),
          ];
        },
      },
      {
        label: "Last Month",
        getValue: () => {
          const today = moment();
          return [
            today.clone().subtract(1, "month").startOf("month"),
            today.clone().subtract(1, "month").endOf("month"),
          ];
        },
      },
      {
        label: "This Week",
        getValue: () => {
          const today = moment();
          return [today.clone().startOf("week"), today.clone().endOf("week")];
        },
      },
      {
        label: "Next Week",
        getValue: () => {
          const today = moment();
          return [
            today.clone().add(1, "week").startOf("week"),
            today.clone().add(1, "week").endOf("week"),
          ];
        },
      },
      {
        label: "Last Week",
        getValue: () => {
          const today = moment();
          return [
            today.clone().subtract(1, "week").startOf("week"),
            today.clone().subtract(1, "week").endOf("week"),
          ];
        },
      },
      {
        label: "Reset",
        getValue: () => [null, null],
      },
    ];

    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateRangePicker
          slotProps={{
            shortcuts: {
              changeImportance: "accept",
              dense: true,
              subheader: (
                <Typography variant="caption" sx={{ p: 2 }}>
                  Shortcuts
                </Typography>
              ),
              items: shortcutsItems,
            },
            actionBar: { actions: [] },
          }}
          value={this.state.uploadedIn}
          size="small"
          localeText={{
            start: "From",
            end: "To",
          }}
          onChange={(newValue) => {
            this.setState({
              uploadedIn: newValue,
            });
          }}
        />
      </LocalizationProvider>
    );
  };

  renderSearchButton = () => {
    return (
      <LoadingButton
        loading={this.state.isLoading}
        startIcon={<Iconify icon="lets-icons:search-alt-light" />}
        onClick={() => this.getByEntityId()}
      >
        Search
      </LoadingButton>
    );
  };
  renderFilterBox = () => {
    return (
      <Stack direction="column" spacing={1} alignItems={"left"}>
        <Typography variant="subtitle1">Search Controls</Typography>
        <Stack direction="row" spacing={1} alignItems={"center"}>
          {this.renderStatusSelection()}
          {this.renderDateRangeSearch()}
          {this.renderSearchButton()}
        </Stack>
      </Stack>
    );
  };

  uploadSingle = async (file, index) => {
    try {
      const fd = new FormData();
      fd.append("file", file);
      fd.append("entityType", "VISIT_OUTPUT_FILE");
      fd.append("entityId", "");
      fd.append("description", file.name);
      const res = await this.props.apiCallPostUpload(
        "/files/addToEntity",
        fd,
        (e) => {
          this.setState({
            uploadProgress: e,
            uploadCount: index,
          });
        }
      );
      if (res && res.message) {
        cogoToast.error(res.message);
        return;
      }
      this.setState({
        uploadProgress: null,
        list: [res, ...this.state.list],
      });
    } catch (err) {
      cogoToast.error("Error Uploading File");
      console.log(err);
    }
  };
  uploadMultiple = async (filesToBeUploaded) => {
    this.setState({
      isUploading: true,
      filesToBeUploaded: filesToBeUploaded,
    });
    for (let i = 0; i < filesToBeUploaded.length; i++) {
      await this.uploadSingle(filesToBeUploaded[i], i);
    }
    this.setState({
      isUploading: false,
      filesToBeUploaded: [],
    });
  };
  renderUploadProgress = () => {
    if (!this.state.isUploading) {
      return null;
    }
    let currentlyUploadedIndex = this.state.uploadCount + 1;
    let totalToUpload = this.state.filesToBeUploaded.length;

    return (
      <Stack direction="row" spacing={2} alignItems={"center"}>
        <Typography variant="caption">
          Uploading File {currentlyUploadedIndex} of {totalToUpload}
        </Typography>
        <LinearProgress
          variant="determinate"
          value={this.state.uploadProgress}
        />
      </Stack>
    );
  };
  renderUploader = () => {
    return (
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="subtitle1">Upload Documents</Typography>
        <UploadBox
          disabled={this.state.isUploading}
          onDrop={(e) => this.uploadMultiple(e)}
          placeholder={
            <Stack alignItems="center" sx={{ color: "text.disabled" }}>
              <LoadingButton loading={this.state.isUploading}>
                <Iconify icon="eva:cloud-upload-fill" width={30} />
              </LoadingButton>
            </Stack>
          }
        />{" "}
        {this.renderUploadProgress()}
      </Stack>
    );
  };
  renderHeader = () => {
    return <Typography variant="h6">Documents Dashboard</Typography>;
  };
  onGridReady = (params) => {
    this.setState({ gridApi: params.api, columnApi: params.columnApi });
  };
  renderGrid = () => {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    let list = this.state.list;
    if (!list || list.length === 0) {
      return <NoResultView />;
    }

    let cs = "ag-theme-quartz";
    let theme = localStorage.getItem("themeMode");
    if (theme && theme === "dark") {
      cs = "ag-theme-quartz-dark";
    }
    return (
      <div className={cs} style={{ height: "70vh" }}>
        <AgGridReact
          onGridReady={this.onGridReady}
          rowData={this.state.list}
          columnDefs={this.COLUMNS}
          defaultColDef={{
            sortable: true,
          }}
          autoSizeStrategy={{
            type: "fitCellContents",
          }}
          pagination={true}
          rowGroupPanelShow={
            this.state.enableAdvancedFilter ? "always" : "never"
          }
          suppressDragLeaveHidesColumns={true}
          rowSelection="multiple"
          rowDragManaged={true}
          enableCharts={true}
          enableRangeSelection={true}
          enableCellTextSelection={true}
          quickFilterText={this.state.quickSearchText}
          sideBar={true}
          animateRows={true}
          enableAdvancedFilter={this.state.enableAdvancedFilter}
        />
      </div>
    );
  };
  render() {
    // if (this.state.isLoading) {
    //   return <LinearProgress />;
    // }

    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <Stack spacing={2}>
        {this.renderHeader()}
        {this.renderUploader()}
        {this.renderFilterBox()}
        {this.renderGrid()}
      </Stack>
    );
  }
}
export default WithAPICall(ListDocuments);
