import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import { Box, Stack } from "@mui/system";
import {
  Alert,
  AlertTitle,
  Chip,
  FormControlLabel,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
  Drawer,
  Switch,
  LinearProgress,
  Grid,
} from "@mui/material";
import Iconify from "src/components/iconify";
import cogoToast from "cogo-toast";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ServiceQPreview from "./ServiceQPreview";

class ServiceQuestionnaire extends React.Component {
  state = {
    isLoading: true,
    questionnaireTemplate: [],
  };
  componentDidMount() {
    this.setState({
      ...this.props.data,
      isLoading: false,
    });
  }
  renderAlert = () => {
    if (this.props.data.serviceType === "QUESTIONNAIRE") return null;
    return (
      <Alert severity="warning">
        <AlertTitle>Quick Note</AlertTitle>
        You can add questions and build a questionnaire; but note that this
        service is tagged as a not a questionnaire service. If you have updated
        this recently, refresh the page once and check if the updated status
        reflects here.
      </Alert>
    );
  };
  QUESTION_TYPE_OPTIONS = [
    "Text",
    "Yes/No",
    "Options",
    "Date",
    "Multi-Select",
    "Section Header",
  ];
  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(this.state.questionnaireTemplate);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    this.setState(
      {
        questionnaireTemplate: items,
      },
      () => {
        this.updateScalarValue(
          "questionnaireTemplate",
          this.state.questionnaireTemplate
        );
      }
    );
  };
  updateScalarValue = async (key, value) => {
    try {
      this.setState({
        isSaving: true,
      });
      let res = await this.props.apiCallPost(
        "/master-service/masterServiceUpdateScalarValue",
        {
          masterServiceId: this.props.id,
          key: key,
          value: value,
        }
      );
      this.setState({
        ...res,
        isSaving: false,
      });
    } catch (err) {
      this.setState({
        isSaving: false,
        questionnaireTemplate: this.props.data.questionnaireTemplate,
      });
      cogoToast.error("Error Updating Questionnaire Data");
      console.log(err);
    }
  };
  renderQHeader = () => {
    return (
      <Stack spacing={3}>
        {this.renderAlert()}
        <ListItemText
          sx={{
            p: 3,
            borderRadius: 2,
            bgcolor: "background.neutral",
          }}
          primary={
            <Stack direction="row" alignItems="center" spacing={3}>
              <Typography variant="h6">Questionnaire</Typography>
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  let newObj = {
                    question: "",
                    answerType: "",
                    answerOptions: "",
                    isMandatory: false,
                  };
                  this.setState(
                    {
                      questionnaireTemplate: [
                        ...this.state.questionnaireTemplate,
                        newObj,
                      ],
                    },
                    () => {
                      this.updateScalarValue(
                        "questionnaireTemplate",
                        this.state.questionnaireTemplate
                      );
                    }
                  );
                }}
                sx={{
                  width: 24,
                  height: 24,
                  bgcolor: "primary.main",
                  color: "primary.contrastText",
                  "&:hover": {
                    bgcolor: "primary.dark",
                  },
                }}
              >
                <Iconify icon="mingcute:add-line" />
              </IconButton>{" "}
              <Button
                variant="contained"
                onClick={() => {
                  this.setState({ isOpen: true });
                }}
              >
                Preview
              </Button>
            </Stack>
          }
          secondary="Create a questionnaire form for this service"
          primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
          secondaryTypographyProps={{ component: "span" }}
        />
      </Stack>
    );
  };
  renderQuestions = () => {
    if (!this.state.questionnaireTemplate.length) return null;
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="questions">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {this.state.questionnaireTemplate.map((question, i) => (
                <Draggable
                  key={question._id}
                  draggableId={question._id}
                  index={i}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {this.renderEachQuestion(question, i)}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };
  renderEachQuestion = (question, i) => {
    return (
      <>
        <Stack
          spacing={2}
          sx={{
            mt: 2,
            p: 3,
            borderRadius: 2,
            bgcolor: "background.neutral",
          }}
        >
          <Box>
            <Button
              variant="outlined"
              startIcon={<Iconify icon="mdi:drag" />}
              sx={{
                cursor: "grab", // Change cursor to indicate draggable
              }}
              {...this.props.dragHandleProps}
            >
              Question {i + 1}
            </Button>
          </Box>

          <Box
            rowGap={2}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(1, 1fr)",
            }}
          >
            <TextField
              disabled={this.state.isSaving}
              placeholder="Question"
              label="Question"
              value={question.question}
              multiline
              onChange={(e) => {
                let questionnaireTemplate = this.state.questionnaireTemplate;
                questionnaireTemplate[i].question = e.target.value;
                this.setState({
                  questionnaireTemplate,
                });
              }}
              onBlur={() => {
                this.updateScalarValue(
                  "questionnaireTemplate",
                  this.state.questionnaireTemplate
                );
              }}
            />{" "}
            <FormControlLabel
              label="Question Type"
              labelPlacement="start"
              disabled={this.state.isSaving}
              control={
                <Select
                  disabled={this.state.isSaving}
                  value={question.answerType}
                  sx={{
                    minWidth: "300px",
                  }}
                  onChange={(e) => {
                    let questionnaireTemplate =
                      this.state.questionnaireTemplate;
                    questionnaireTemplate[i].answerType = e.target.value;
                    this.setState(
                      {
                        questionnaireTemplate,
                      },
                      () => {
                        this.updateScalarValue(
                          "questionnaireTemplate",
                          this.state.questionnaireTemplate
                        );
                      }
                    );
                  }}
                >
                  {this.QUESTION_TYPE_OPTIONS.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              }
              sx={{
                m: 0,
                width: 1,
                justifyContent: "space-between",
              }}
            />
            {["Options", "Multi-Select"].includes(question.answerType) && (
              <TextField
                disabled={this.state.isSaving}
                label="Options"
                value={question.answerOptions}
                multiline
                onChange={(e) => {
                  let questionnaireTemplate = this.state.questionnaireTemplate;
                  questionnaireTemplate[i].answerOptions = e.target.value;
                  this.setState({
                    questionnaireTemplate,
                  });
                }}
                onBlur={() => {
                  this.updateScalarValue(
                    "questionnaireTemplate",
                    this.state.questionnaireTemplate
                  );
                }}
              />
            )}{" "}
            <FormControlLabel
              label="Mandatory Question"
              labelPlacement="start"
              disabled={this.state.isSaving}
              control={
                <Switch
                  checked={question.isMandatory}
                  onChange={() => {
                    let questionnaireTemplate =
                      this.state.questionnaireTemplate;
                    questionnaireTemplate[i].isMandatory =
                      !questionnaireTemplate[i].isMandatory;
                    this.setState(
                      {
                        questionnaireTemplate,
                      },
                      () => {
                        this.updateScalarValue(
                          "questionnaireTemplate",
                          this.state.questionnaireTemplate
                        );
                      }
                    );
                  }}
                />
              }
              sx={{
                m: 0,
                width: 1,
                justifyContent: "space-between",
              }}
            />
          </Box>
          <Box>
            {" "}
            <Chip
              disabled={this.state.isSaving}
              color="error"
              label="Delete"
              variant="outlined"
              onDelete={() => {
                let questionnaireTemplate = this.state.questionnaireTemplate;
                questionnaireTemplate.splice(i, 1);
                this.setState(
                  {
                    questionnaireTemplate,
                  },
                  () => {
                    this.updateScalarValue(
                      "questionnaireTemplate",
                      this.state.questionnaireTemplate
                    );
                  }
                );
              }}
            />
          </Box>
        </Stack>
        {question?.answerType === "Yes/No" && this.renderYesNoBox(question, i)}
        {["Options", "Multi-Select"].includes(question.answerType) &&
          this.renderOptionsQuestions(question, i)}
      </>
    );
  };
  renderOptionsQuestions = (question, i) => {
    return question.answerOptions
      .split(",")
      .map((answerOption) => answerOption.trim())
      .map((answerOption, idx) => (
        <Grid container>
          <Grid item xs={6}>
            <Stack
              spacing={2}
              sx={{
                mt: 2,
                p: 3,
                borderRadius: 2,
                bgcolor: "background.neutral",
              }}
            >
              <Box>
                <Typography variant="subtitle1">
                  Question to ask if the user selects {answerOption}
                </Typography>
              </Box>
              <Box
                rowGap={2}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                }}
              >
                <TextField
                  disabled={this.state.isSaving}
                  placeholder="Question"
                  label="Question"
                  value={
                    question.multiChoiceQuestionOptions
                      ? question.multiChoiceQuestionOptions[answerOption]
                          ?.question
                      : null
                  }
                  multiline
                  onChange={(e) => {
                    let questionnaireTemplate =
                      this.state.questionnaireTemplate;
                    if (!questionnaireTemplate[i].multiChoiceQuestionOptions) {
                      questionnaireTemplate[i].multiChoiceQuestionOptions = {};
                    }
                    if (
                      !questionnaireTemplate[i].multiChoiceQuestionOptions[
                        answerOption
                      ]
                    ) {
                      questionnaireTemplate[i].multiChoiceQuestionOptions[
                        answerOption
                      ] = {
                        answerType: null,
                      };
                    }
                    questionnaireTemplate[i].multiChoiceQuestionOptions[
                      answerOption
                    ].question = e.target.value;
                    this.setState({
                      questionnaireTemplate,
                    });
                  }}
                  onBlur={() => {
                    this.updateScalarValue(
                      "questionnaireTemplate",
                      this.state.questionnaireTemplate
                    );
                  }}
                />{" "}
                <FormControlLabel
                  label="Question Type"
                  labelPlacement="start"
                  disabled={this.state.isSaving}
                  control={
                    <Select
                      disabled={this.state.isSaving}
                      value={
                        question.multiChoiceQuestionOptions
                          ? question.multiChoiceQuestionOptions[answerOption]
                              ?.answerType
                          : null
                      }
                      sx={{
                        minWidth: "300px",
                      }}
                      onChange={(e) => {
                        let questionnaireTemplate =
                          this.state.questionnaireTemplate;
                        if (
                          !questionnaireTemplate[i].multiChoiceQuestionOptions
                        ) {
                          questionnaireTemplate[i].multiChoiceQuestionOptions =
                            {};
                        }
                        if (
                          !questionnaireTemplate[i].multiChoiceQuestionOptions[
                            answerOption
                          ]
                        ) {
                          questionnaireTemplate[i].multiChoiceQuestionOptions[
                            answerOption
                          ] = {
                            answerType: null,
                          };
                        }
                        questionnaireTemplate[i].multiChoiceQuestionOptions[
                          answerOption
                        ].answerType = e.target.value;
                        this.setState(
                          {
                            questionnaireTemplate,
                          },
                          () => {
                            this.updateScalarValue(
                              "questionnaireTemplate",
                              this.state.questionnaireTemplate
                            );
                          }
                        );
                      }}
                    >
                      {this.QUESTION_TYPE_OPTIONS.filter(
                        (v) =>
                          v !== "Yes/No" &&
                          v !== "Options" &&
                          v !== "Multi-Select" &&
                          v !== "Section Header"
                      ).map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  sx={{
                    m: 0,
                    width: 1,
                    justifyContent: "space-between",
                  }}
                />
                {question.isMandatory && (
                  <FormControlLabel
                    label="Mandatory Question"
                    labelPlacement="start"
                    disabled={this.state.isSaving}
                    control={
                      <Switch
                        checked={
                          question.multiChoiceQuestionOptions
                            ? question.multiChoiceQuestionOptions[answerOption]
                                ?.isMandatory
                            : null
                        }
                        onChange={() => {
                          let questionnaireTemplate =
                            this.state.questionnaireTemplate;
                          if (
                            !questionnaireTemplate[i].multiChoiceQuestionOptions
                          ) {
                            questionnaireTemplate[
                              i
                            ].multiChoiceQuestionOptions = {};
                          }
                          if (
                            !questionnaireTemplate[i]
                              .multiChoiceQuestionOptions[answerOption]
                          ) {
                            questionnaireTemplate[i].multiChoiceQuestionOptions[
                              answerOption
                            ] = {
                              isMandatory: false,
                            };
                          }
                          questionnaireTemplate[i].multiChoiceQuestionOptions[
                            answerOption
                          ].isMandatory =
                            !questionnaireTemplate[i]
                              .multiChoiceQuestionOptions[answerOption]
                              .isMandatory;
                          this.setState(
                            {
                              questionnaireTemplate,
                            },
                            () => {
                              this.updateScalarValue(
                                "questionnaireTemplate",
                                this.state.questionnaireTemplate
                              );
                            }
                          );
                        }}
                      />
                    }
                    sx={{
                      m: 0,
                      width: 1,
                      justifyContent: "space-between",
                    }}
                  />
                )}
              </Box>
            </Stack>
          </Grid>
        </Grid>
      ));
  };
  renderYesNoBox = (question, i) => {
    return (
      <Grid container gap={2}>
        <Grid item xs={5}>
          <Stack
            spacing={2}
            sx={{
              mt: 2,
              p: 3,
              borderRadius: 2,
              bgcolor: "background.neutral",
            }}
          >
            <Box>
              <Typography variant="subtitle1">
                Question to ask if the user selects Yes
              </Typography>
            </Box>
            <Box
              rowGap={2}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(1, 1fr)",
              }}
            >
              <TextField
                disabled={this.state.isSaving}
                placeholder="Question"
                label="Question"
                value={question.yesQuestion?.question}
                multiline
                onChange={(e) => {
                  let questionnaireTemplate = this.state.questionnaireTemplate;
                  if (!questionnaireTemplate[i].yesQuestion) {
                    questionnaireTemplate[i].yesQuestion = {};
                  }
                  questionnaireTemplate[i].yesQuestion.question =
                    e.target.value;
                  this.setState({
                    questionnaireTemplate,
                  });
                }}
                onBlur={() => {
                  this.updateScalarValue(
                    "questionnaireTemplate",
                    this.state.questionnaireTemplate
                  );
                }}
              />{" "}
              <FormControlLabel
                label="Question Type"
                labelPlacement="start"
                disabled={this.state.isSaving}
                control={
                  <Select
                    disabled={this.state.isSaving}
                    value={question.yesQuestion?.answerType}
                    sx={{
                      minWidth: "300px",
                    }}
                    onChange={(e) => {
                      let questionnaireTemplate =
                        this.state.questionnaireTemplate;
                      if (!questionnaireTemplate[i].yesQuestion) {
                        questionnaireTemplate[i].yesQuestion = {};
                      }
                      questionnaireTemplate[i].yesQuestion.answerType =
                        e.target.value;
                      this.setState(
                        {
                          questionnaireTemplate,
                        },
                        () => {
                          this.updateScalarValue(
                            "questionnaireTemplate",
                            this.state.questionnaireTemplate
                          );
                        }
                      );
                    }}
                  >
                    {this.QUESTION_TYPE_OPTIONS.filter(
                      (v) => v !== "Yes/No"
                    ).map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                }
                sx={{
                  m: 0,
                  width: 1,
                  justifyContent: "space-between",
                }}
              />
              {["Options", "Multi-Select"].includes(
                question.yesQuestion?.answerType
              ) && (
                <TextField
                  disabled={this.state.isSaving}
                  label="Options"
                  value={question.yesQuestion?.answerOptions}
                  multiline
                  onChange={(e) => {
                    let questionnaireTemplate =
                      this.state.questionnaireTemplate;
                    if (!questionnaireTemplate[i].yesQuestion) {
                      questionnaireTemplate[i].yesQuestion = {};
                    }
                    questionnaireTemplate[i].yesQuestion.answerOptions =
                      e.target.value;
                    this.setState({
                      questionnaireTemplate,
                    });
                  }}
                  onBlur={() => {
                    this.updateScalarValue(
                      "questionnaireTemplate",
                      this.state.questionnaireTemplate
                    );
                  }}
                />
              )}{" "}
              {question.isMandatory && (
                <FormControlLabel
                  label="Mandatory Question"
                  labelPlacement="start"
                  disabled={this.state.isSaving}
                  control={
                    <Switch
                      checked={question.yesQuestion?.isMandatory}
                      onChange={() => {
                        let questionnaireTemplate =
                          this.state.questionnaireTemplate;
                        if (!questionnaireTemplate[i].yesQuestion) {
                          questionnaireTemplate[i].yesQuestion = {
                            isMandatory: false,
                          };
                        }
                        questionnaireTemplate[i].yesQuestion.isMandatory =
                          !questionnaireTemplate[i].yesQuestion.isMandatory;
                        this.setState(
                          {
                            questionnaireTemplate,
                          },
                          () => {
                            this.updateScalarValue(
                              "questionnaireTemplate",
                              this.state.questionnaireTemplate
                            );
                          }
                        );
                      }}
                    />
                  }
                  sx={{
                    m: 0,
                    width: 1,
                    justifyContent: "space-between",
                  }}
                />
              )}
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={5}>
          <Stack
            spacing={2}
            sx={{
              mt: 2,
              p: 3,
              borderRadius: 2,
              bgcolor: "background.neutral",
            }}
          >
            <Box>
              <Typography variant="subtitle1">
                Question to ask if the user selects No
              </Typography>
            </Box>
            <Box
              rowGap={2}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(1, 1fr)",
              }}
            >
              <TextField
                disabled={this.state.isSaving}
                placeholder="Question"
                label="Question"
                value={question.noQuestion?.question}
                multiline
                onChange={(e) => {
                  let questionnaireTemplate = this.state.questionnaireTemplate;
                  if (!questionnaireTemplate[i].noQuestion) {
                    questionnaireTemplate[i].noQuestion = {};
                  }
                  questionnaireTemplate[i].noQuestion.question = e.target.value;
                  this.setState({
                    questionnaireTemplate,
                  });
                }}
                onBlur={() => {
                  this.updateScalarValue(
                    "questionnaireTemplate",
                    this.state.questionnaireTemplate
                  );
                }}
              />{" "}
              <FormControlLabel
                label="Question Type"
                labelPlacement="start"
                disabled={this.state.isSaving}
                control={
                  <Select
                    disabled={this.state.isSaving}
                    value={question.noQuestion?.answerType}
                    sx={{
                      minWidth: "300px",
                    }}
                    onChange={(e) => {
                      let questionnaireTemplate =
                        this.state.questionnaireTemplate;
                      if (!questionnaireTemplate[i].noQuestion) {
                        questionnaireTemplate[i].noQuestion = {};
                      }
                      questionnaireTemplate[i].noQuestion.answerType =
                        e.target.value;
                      this.setState(
                        {
                          questionnaireTemplate,
                        },
                        () => {
                          this.updateScalarValue(
                            "questionnaireTemplate",
                            this.state.questionnaireTemplate
                          );
                        }
                      );
                    }}
                  >
                    {this.QUESTION_TYPE_OPTIONS.filter(
                      (v) => v !== "Yes/No"
                    ).map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                }
                sx={{
                  m: 0,
                  width: 1,
                  justifyContent: "space-between",
                }}
              />
              {["Options", "Multi-Select"].includes(
                question.noQuestion?.answerType
              ) && (
                <TextField
                  disabled={this.state.isSaving}
                  label="Options"
                  value={question.noQuestion?.answerOptions}
                  multiline
                  onChange={(e) => {
                    let questionnaireTemplate =
                      this.state.questionnaireTemplate;
                    if (!questionnaireTemplate[i].noQuestion) {
                      questionnaireTemplate[i].noQuestion = {};
                    }
                    questionnaireTemplate[i].noQuestion.answerOptions =
                      e.target.value;
                    this.setState({
                      questionnaireTemplate,
                    });
                  }}
                  onBlur={() => {
                    this.updateScalarValue(
                      "questionnaireTemplate",
                      this.state.questionnaireTemplate
                    );
                  }}
                />
              )}
              {question.isMandatory && (
                <FormControlLabel
                  label="Mandatory Question"
                  labelPlacement="start"
                  disabled={this.state.isSaving}
                  control={
                    <Switch
                      checked={question.noQuestion?.isMandatory}
                      onChange={() => {
                        let questionnaireTemplate =
                          this.state.questionnaireTemplate;
                        if (!questionnaireTemplate[i].noQuestion) {
                          questionnaireTemplate[i].noQuestion = {
                            isMandatory: false,
                          };
                        }
                        questionnaireTemplate[i].noQuestion.isMandatory =
                          !questionnaireTemplate[i].noQuestion.isMandatory;
                        this.setState(
                          {
                            questionnaireTemplate,
                          },
                          () => {
                            this.updateScalarValue(
                              "questionnaireTemplate",
                              this.state.questionnaireTemplate
                            );
                          }
                        );
                      }}
                    />
                  }
                  sx={{
                    m: 0,
                    width: 1,
                    justifyContent: "space-between",
                  }}
                />
              )}
            </Box>
          </Stack>
        </Grid>
      </Grid>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <div>
        {" "}
        <Drawer
          anchor="right"
          open={this.state.isOpen}
          onClose={() => {
            this.setState({ isOpen: false });
          }}
        >
          <ServiceQPreview
            data={this.state.questionnaireTemplate}
            isPreview={true}
            serviceData={this.props.data}
          />
        </Drawer>
        <Stack spacing={4}>
          {this.renderQuestions()}
          {this.renderQHeader()}
        </Stack>
      </div>
    );
  }
}

export default WithAPICall(ServiceQuestionnaire);
