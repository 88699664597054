import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Autocomplete,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import cogoToast from "cogo-toast";
import _, { cloneDeep, debounce, filter } from "lodash";
import moment, { calendarFormat } from "moment";
import React from "react";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import Iconify from "src/components/iconify";
import { WithAPICall } from "../utils/apiUtil";
import { niceDateTime } from "../utils/fn";
import TokenContext from "src/TokenContext";
import { format } from "date-fns";

// Please update the VisitRow class to match VisitList
// class SurveillanceListRow extends React.Component {
//   renderNiceText = (each, key) => {
//     if (!each[key]) return "N/A";
//     let txt = _.startCase(_.lowerCase(each[key]));
//     // replace "Rn" with "RN" and "Md" with "MD"
//     txt = txt.replace(/Rn/g, "RN").replace(/Md/g, "MD");
//     return txt;
//   };

//   renderEmployee = (each) => {
//     return (
//       <Stack direction="row" spacing={1} alignItems={"center"}>
//         <Stack spacing={1} direction="column" alignItems="left">
//           <Typography variant="overline">
//             {each.employee.employeeName}
//           </Typography>
//           <Typography variant="caption">
//             EID: {each.employee.employeeNumber || "<No EID on file>"}
//           </Typography>
//           <Typography variant="caption">
//             DoB:{" "}
//             {format(new Date(each.employee.employeeDoB || new Date()), "PP")}
//           </Typography>
//           <Typography variant="caption">
//             SSN: {each.employee.employeeSSN || "<No SSN on file>"}
//           </Typography>
//         </Stack>
//         <IconButton
//           size="small"
//           color="primary"
//           href={`/visits/${each._id}`}
//           target="_blank"
//           sx={{
//             width: 24,
//             height: 24,
//             bgcolor: "primary.main",
//             color: "primary.contrastText",
//             "&:hover": {
//               bgcolor: "primary.dark",
//             },
//           }}
//         >
//           <Iconify icon="mynaui:external-link" />
//         </IconButton>
//       </Stack>
//     );
//   };

//   renderProtocols = (each) => {
//     if (!each.protocolsInfo || !each.protocolsInfo.length) {
//       return "Not Chosen";
//     }

//     return (
//       <Stack spacing={1}>
//         {each.protocolsInfo.map((x) => {
//           return (
//             <Stack spacing={0}>
//               <Typography variant="overline">
//                 {x.companyProtocolName}
//               </Typography>
//               <Typography variant="caption">{x.companyProtocolType}</Typography>
//             </Stack>
//           );
//         })}
//       </Stack>
//     );
//   };
//   renderLabs = (each) => {
//     let labServices = each.servicesInfo.filter(
//       (x) => x.serviceRequiresProviderType === "Lab"
//     );

//     if (labServices.length == 0) {
//       return "No Lab Services";
//     }

//     return (
//       <Stack spacing={1}>
//         {labServices.map((x) => {
//           return (
//             <Stack spacing={0} style={{ marginLeft: "5px" }}>
//               <Typography variant="caption">{x.serviceName}</Typography>{" "}
//             </Stack>
//           );
//         })}
//       </Stack>
//     );
//   };
//   renderClinics = (each) => {
//     if (!each.clinicFrozen) {
//       return "Not Chosen";
//     }
//     if (!each.providerPSLs) {
//       return "N/A";
//     }
//     if (!each.providerPSLs.length) {
//       return "N/A";
//     }
//     // const groupedClinics = _.groupBy(each.providerPSLs, "providerName");
//     let dt = "";
//     if (!each.providerEncounterOnTime) {
//       dt = null;
//     } else {
//       dt = new Date(each.providerEncounterOnDate);
//       let tm = new Date(each.providerEncounterOnTime);
//       dt.setHours(tm.getHours());
//       dt.setMinutes(tm.getMinutes());
//       dt =
//         niceDateTime(dt) + " " + (each.provider.providerReadableTimezone || "");
//     }
//     return (
//       <Stack spacing={0.5}>
//         <Stack spacing={0}>
//           <Typography variant="overline">
//             {each.provider.providerName}
//           </Typography>{" "}
//         </Stack>
//         {each.servicesInfo
//           .filter((x) => x.serviceRequiresProviderType == "Clinic")
//           .map((x) => {
//             return (
//               <Stack spacing={0} style={{ marginLeft: "5px" }}>
//                 <Typography variant="caption">{x.serviceName}</Typography>{" "}
//               </Stack>
//             );
//           })}
//         {dt && (
//           <Stack direction="row" alignItems="center" spacing={0.5}>
//             <Iconify icon="lucide:calendar" />
//             <Typography variant="caption">
//               Scheduled At: <b>{dt || "Not Scheduled"}</b>
//             </Typography>
//           </Stack>
//         )}
//       </Stack>
//     );
//   };

//   renderClearances = (each) => {
//     const clearances = [];

//     for (let ps of each.protocolsInfo) {
//       if (ps.clearancePsUrl) {
//         const psDetailed = _.find(
//           each.bookedProtocols,
//           (psd) => psd._id === ps.protocolId
//         );
//         clearances.push(
//           <Stack spacing={0}>
//             <Stack direction="row" alignItems="center" spacing={1}>
//               <Typography variant="caption">
//                 {psDetailed.companyProtocolName}
//               </Typography>
//               <IconButton href={ps.clearancePsUrl} target="_blank">
//                 <Iconify icon="codicon:file-symlink-file" />
//               </IconButton>
//             </Stack>
//           </Stack>
//         );
//       }
//     }

//     if (clearances.length === 0) {
//       clearances.push(
//         <Stack spacing={0}>
//           <Stack direction="row" alignItems="center" spacing={1}>
//             <Typography variant="caption">No Clearances Issued [2]</Typography>
//           </Stack>
//         </Stack>
//       );
//     }

//     return <Stack spacing={1}>{clearances}</Stack>;
//   };

//   render() {
//     const each = this.props.visit;
//     return (
//       <TableRow
//         key={each._id}
//         sx={{
//           borderBottom: "1px solid #C5CBD3 !important",
//         }}
//       >
//         <TableCell>{this.renderEmployee(each)}</TableCell>{" "}
//         <TableCell>{each.company.companyName}</TableCell>{" "}
//         <TableCell>{this.renderProtocols(each)}</TableCell>{" "}
//         <TableCell>{this.renderClinics(each)}</TableCell>{" "}
//         <TableCell>{this.renderLabs(each)}</TableCell>
//         <TableCell>
//           <Stack spacing={1}>{this.renderNiceText(each, "visitStatus")}</Stack>
//         </TableCell>
//         <TableCell>{this.renderClearances(each)}</TableCell>
//       </TableRow>
//     );
//   }
// }
class MedSurveillanceList extends React.Component {
  static contextType = TokenContext;
  state = {
    isLoading: true,
    // cu
    currentUser: {
      isEmployee: true,
      isExternal: true,
    },
    // company
    isSearchingCompany: false,
    companySearchResults: [],
    currentlyInCompany: null,
    // other filters
    filterStatus: [],
    filterEmployee: "",
    filterDateRange: [null, null],
    filterMasterServiceIds: [],
    filterProtocolIds: [],
    // search results
    isSearchingVisits: false,
    hasSearched: false,
    visits: [],
    options: [],
    loadingOptions: false,
    protocolOptions: [],
    loadingCompanyProtocols: false,
    isAssociating: false,
  };
  componentDidMount() {
    this.getCurrentUser();
  }
  getCurrentUser = async () => {
    try {
      const res = await this.props.apiCallPost("/user/getCurrentUser", {});
      let cu = res;
      this.setState(
        {
          currentUser: cu,
          isLoading: false,
          filterDateRange: [
            moment().subtract(7, "days"),
            moment().add(7, "days"),
          ],
        },
        () => {
          this.searchUpcomingProtocols();
        }
      );
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading User Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  searchCompaniesFTS = async (t) => {
    try {
      let res = await this.props.apiCallPostCancellable("/company/search", {
        query: t,
      });
      if (res) {
        this.setState({
          isSearchingCompany: false,
          companySearchResults: res.map((each) => {
            return {
              mainText: each.companyName,
              secondaryText: `${each.companyInformalName} ${each.companyWebsite}`,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingCompany: false,
        companySearchResults: [],
      });
      cogoToast.error("Error Searching Companies");
    }
  };
  searchUpcomingProtocols = async () => {
    let payload = {
      companyId: this.state.currentlyInCompany?.data?._id || null,
      query: this.state.filterEmployee || null,
      protocolIds: this.state.filterProtocolIds.map((s) => s?._id),
    };
    // if date range has both filled, convert to date from moment
    if (this.state.filterDateRange.filter(Boolean).length === 2) {
      payload.upcomingIn = this.state.filterDateRange.map((each) =>
        each.toDate()
      );
    } else {
      payload.upcomingIn = [];
    }

    try {
      this.setState({
        isSearchingVisits: true,
      });
      let res = await this.props.apiCallPost(
        "/employee/data/searchByUpcomingProtocolsFts",
        payload
      );
      this.setState({
        isSearchingVisits: false,
        hasSearched: true,
        visits: res,
      });
    } catch (err) {
      this.setState({
        isSearchingVisits: false,
      });
      cogoToast.error("Error Searching Visits");
    }
  };
  loadCompanyProtocols = async () => {
    cogoToast.loading("Loading company protocols.");
    try {
      const res = await this.props.apiCallPost(
        "/company-protocol/companyProtocolGetAll",
        {
          companyId: this.state.currentlyInCompany.data._id,
        }
      );
      this.setState({
        protocolOptions: res,
      });
      cogoToast.success("Company protocols loaded.");
    } catch (error) {
      console.error("could not get company protocols", error);
    }
  };
  renderCompanySearcher = () => {
    if (
      this.state.currentUser.isExternal ||
      this.state.currentUser.isEmployee
    ) {
      return null;
    }
    return (
      <Autocomplete
        sx={{
          minWidth: "200px",
        }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option?.mainText
        }
        filterOptions={(x) => x}
        options={
          this.state.isSearchingCompany ? [] : this.state.companySearchResults
        }
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={this.state.currentlyInCompany}
        noOptionsText={
          this.state.isSearchingCompany ? "Searching..." : "No results found"
        }
        onChange={(_, value) => {
          this.setState(
            {
              currentlyInCompany: value,
              companySearchResults: [value, ...this.state.companySearchResults],
              protocolOptions: [],
              filterMasterServiceIds: [],
              filterProtocolIds: [],
            },
            () => {
              if (value) {
                this.loadCompanyProtocols();
              }
            }
          );
        }}
        onInputChange={(_, e) => {
          if (e === "") return null;
          if (!e) return null;
          if (e.length < 4) return null;
          this.setState(
            {
              isSearchingCompany: true,
            },
            () => {
              this.searchCompaniesFTS(e);
            }
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label="Search Employers" fullWidth />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ wordWrap: "break-word" }}>
                  <Box component="span">{option?.mainText}</Box>
                  <Typography variant="body2" color="text.secondary">
                    {option?.secondaryText}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    );
  };
  renderEmployeeSearch = () => {
    return (
      <TextField
        sx={{
          minWidth: "200px",
        }}
        InputLabelProps={{
          shrink: this.state.filterEmployee,
        }}
        label="Search Employees"
        onChange={(e) => {
          let v = e.target.value;
          this.setState({
            filterEmployee: v,
          });
        }}
        value={this.state.filterEmployee}
      />
    );
  };
  renderDateRangeSearch = () => {
    const shortcutsItems = [
      {
        label: "Today",
        getValue: () => {
          const today = moment();
          return [today, today];
        },
      },
      {
        label: "Last 3 Days",
        getValue: () => {
          const today = moment();
          return [today.clone().subtract(3, "days"), today];
        },
      },
      {
        label: "Next 3 Days",
        getValue: () => {
          const today = moment();
          return [today, today.clone().add(3, "days")];
        },
      },
      {
        label: "Last 7 Days",
        getValue: () => {
          const today = moment();
          return [today.clone().subtract(7, "days"), today];
        },
      },
      {
        label: "Next 7 Days",
        getValue: () => {
          const today = moment();
          return [today, today.clone().add(7, "days")];
        },
      },
      {
        label: "Last 30 Days",
        getValue: () => {
          const today = moment();
          return [today.clone().subtract(30, "days"), today];
        },
      },
      {
        label: "Next 30 Days",
        getValue: () => {
          const today = moment();
          return [today, today.clone().add(30, "days")];
        },
      },
      {
        label: "This Month",
        getValue: () => {
          const today = moment();
          return [today.clone().startOf("month"), today.clone().endOf("month")];
        },
      },
      {
        label: "Next Month",
        getValue: () => {
          const today = moment();
          return [
            today.clone().add(1, "month").startOf("month"),
            today.clone().add(1, "month").endOf("month"),
          ];
        },
      },
      {
        label: "Last Month",
        getValue: () => {
          const today = moment();
          return [
            today.clone().subtract(1, "month").startOf("month"),
            today.clone().subtract(1, "month").endOf("month"),
          ];
        },
      },
      {
        label: "This Week",
        getValue: () => {
          const today = moment();
          return [today.clone().startOf("week"), today.clone().endOf("week")];
        },
      },
      {
        label: "Next Week",
        getValue: () => {
          const today = moment();
          return [
            today.clone().add(1, "week").startOf("week"),
            today.clone().add(1, "week").endOf("week"),
          ];
        },
      },
      {
        label: "Last Week",
        getValue: () => {
          const today = moment();
          return [
            today.clone().subtract(1, "week").startOf("week"),
            today.clone().subtract(1, "week").endOf("week"),
          ];
        },
      },
      {
        label: "Reset",
        getValue: () => [null, null],
      },
    ];

    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateRangePicker
          slotProps={{
            shortcuts: {
              changeImportance: "accept",
              dense: true,
              subheader: (
                <Typography variant="caption" sx={{ p: 2 }}>
                  Shortcuts
                </Typography>
              ),
              items: shortcutsItems,
            },
            actionBar: { actions: [] },
          }}
          value={this.state.filterDateRange}
          size="small"
          localeText={{
            start: "From",
            end: "To",
          }}
          onChange={(newValue) => {
            this.setState({
              filterDateRange: newValue,
            });
          }}
        />
      </LocalizationProvider>
    );
  };
  renderSearchButton = () => {
    return (
      <LoadingButton
        loading={this.state.isSearchingVisits}
        startIcon={<Iconify icon="lets-icons:search-alt-light" />}
        onClick={this.searchUpcomingProtocols}
        variant="contained"
        sx={{
          height: "100%",
          minWidth: "150px",
        }}
      >
        Search
      </LoadingButton>
    );
  };
  handleInputChange = (event, newInputValue) => {
    this.setState({ inputValue: newInputValue }, () => {
      this.debouncedFetchOptions(newInputValue);
    });
  };
  handleSetProtocolFilter = (data) => {
    this.setState((prevState) => {
      const protocolDetails = cloneDeep(this.state.filterProtocolIds);
      protocolDetails.push(data);
      return { ...prevState, filterProtocolIds: protocolDetails };
    });
  };
  renderFilterBox = () => {
    if (this.state.currentUser.isEmployee) return null;
    return (
      <>
        <Stack direction="row" spacing={1} alignItems={"center"}>
          {this.renderCompanySearcher()}
          {this.renderEmployeeSearch()}
          <Autocomplete
            sx={{
              minWidth: "200px",
            }}
            options={this.state.protocolOptions}
            getOptionLabel={(option) => option.companyProtocolName}
            // onInputChange={this.handleInputChange}
            onChange={(_, value) => {
              console.log({ value });
              if (value) {
                this.handleSetProtocolFilter(value);
              } else {
                this.setState({ filterProtocolIds: [] });
              }
            }}
            disabled={!this.state.currentlyInCompany}
            renderInput={(params) => (
              <TextField
                onClick={() => {
                  console.log({ params });
                }}
                label="Search for a Protocol"
                {...params}
                variant="outlined"
              />
            )}
          />
        </Stack>
        <Stack direction="row" spacing={1} alignItems={"center"}>
          {this.renderDateRangeSearch()}
          {this.renderSearchButton()}
        </Stack>
      </>
    );
  };
  renderSearchResults = () => {
    if (!this.state.hasSearched) return null;
    if (this.state.isSearchingVisits) return <LinearProgress />;
    if (!this.state.visits || this.state.visits.length === 0) {
      return (
        <Alert severity="info">
          No Upcoming Protocols Found. Try modifying search criteria.
        </Alert>
      );
    }
    return this.renderTable();
  };
  renderTable = () => {
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Employee</TableCell> <TableCell>Employer</TableCell>
            <TableCell>Upcoming Protocol(s)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.visits.map((each) => {
            return (
              <TableRow
                key={each._id}
                sx={{
                  borderBottom: "1px solid #C5CBD3 !important",
                }}
              >
                <TableCell>{this.renderEmployee(each)}</TableCell>{" "}
                <TableCell>{each.company.companyName}</TableCell>{" "}
                <TableCell>{this.renderProtocols(each)}</TableCell>{" "}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };
  renderNiceText = (each, key) => {
    if (!each[key]) return "N/A";
    let txt = _.startCase(_.lowerCase(each[key]));
    // replace "Rn" with "RN" and "Md" with "MD"
    txt = txt.replace(/Rn/g, "RN").replace(/Md/g, "MD");
    return txt;
  };
  renderEmployee = (each) => {
    let elem = (
      <IconButton
        disabled={this.state.isSearchingVisits}
        size="small"
        color="primary"
        href={`/visits/${each._id}`}
        // target="non_blank"
        sx={{
          width: 24,
          height: 24,
          bgcolor: "primary.main",
          color: "primary.contrastText",
          "&:hover": {
            bgcolor: "primary.dark",
          },
        }}
      >
        <Iconify icon="mynaui:external-link" />
      </IconButton>
    );
    return (
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <Stack spacing={1} direction="column" alignItems="left">
          <Typography variant="overline">{each.employeeName}</Typography>
          <Typography variant="caption">
            EID: {each.employeeNumber || "<No EID on file>"}
          </Typography>
          <Typography variant="caption">
            DoB: {format(new Date(each.employeeDoB || new Date()), "PP")}
          </Typography>
          <Typography variant="caption">
            SSN: {each.employeeSSN || "<No SSN on file>"}
          </Typography>
        </Stack>
        {elem}
      </Stack>
    );
  };
  renderProtocols = (each) => {
    console.log({ each });
    if (!each.parentProtocols || !each.parentProtocols.length) {
      return "None upcoming.";
    }
    return (
      <Stack spacing={1}>
        {each.parentProtocols.map((x) => {
          return (
            <Stack spacing={0}>
              <Typography variant="overline">
                {x.protocolDetails.companyProtocolName}
              </Typography>
              {x.dueNext && (
                <Typography variant="caption">
                  Due on: {format(new Date(x.dueNext || new Date()), "PP")}
                </Typography>
              )}
              {x.lastDone && (
                <Typography variant="caption">
                  Last done: {format(new Date(x.lastDone || new Date()), "PP")}
                </Typography>
              )}
              {x.notes && (
                <Typography variant="caption">Notes: {x.notes}</Typography>
              )}
            </Stack>
          );
        })}
      </Stack>
    );
  };
  renderHeader = () => {
    return (
      <CustomBreadcrumbs
        heading="Services Due / Medical Surveillance"
        links={[{ name: "Medical Surveillance" }]}
        action={<div />}
      />
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <Stack spacing={2}>
        {this.renderHeader()}
        {this.renderFilterBox()}
        {this.renderSearchResults()}
      </Stack>
    );
  }
}

export default WithAPICall(MedSurveillanceList);
