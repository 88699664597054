import React, { useState, useEffect } from "react";
import {
  Typography,
  Select,
  MenuItem,
  TextField,
  Box,
  Container,
  Button,
  FormControl,
  InputLabel,
  Card,
} from "@mui/material";
import { Stack } from "@mui/system";
import _ from "lodash";
import DynamicTextWithSelects from "src/micro/Clearances/DynamicClearance";

const GenericXMLClearer = ({
  name,
  visit,
  protocol,
  groups,
  onClearanceChange,
}) => {
  const savedCO = visit.protocolClearance[protocol._id];

  const [clearanceOption, setClearanceOption] = useState(
    _.get(savedCO, "selections.clearanceOption", "")
  );

  const [finalClearanceText, setFinalClearanceText] = useState(
    _.get(savedCO, "selections.finalClearanceText", "")
  );

  const [clearanceOptionData, setClearanceOptionData] = useState(null);

  useEffect(() => {
    const co = protocol.clearanceOptions.find(
      (co) => co.case === clearanceOption
    );
    setClearanceOptionData(co);
    if (!co) return;
    const hasMarkup = co.text.includes("<select");
    if (hasMarkup == false) {
      setFinalClearanceText(co.text);
    }
  }, [clearanceOption]);

  return (
    <Card sx={{ p: 3 }}>
      <Stack spacing={1}>
        <Typography variant="body2">Medical Clearance: {name}</Typography>

        <FormControl margin="normal" size="small">
          <InputLabel id="select-major-opinion">Clearance Option</InputLabel>
          <Select
            labelId="select-co"
            value={clearanceOption}
            onChange={(e) => setClearanceOption(e.target.value)}
            disabled={false}
          >
            {protocol.clearanceOptions.map((co, i) => (
              <MenuItem key={i} value={co.case}>
                {`[${co.typeOfClearance}] - ${co.case}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {clearanceOptionData && (
          <DynamicTextWithSelects
            initialText={clearanceOptionData.text}
            initialSelections={[]}
            suppressUpdate={false}
            onTextChange={(text, selections) => {
              console.log(">>>text", text);
              setFinalClearanceText(text);
            }}
          />
        )}

        <TextField
          label="Final Clearance Text"
          value={finalClearanceText}
          multiline
          minRows={3}
          onChange={(e) => setFinalClearanceText(e.target.value)}
        />

        <Button
          variant="contained"
          onClick={() => {
            onClearanceChange(
              finalClearanceText,
              clearanceOptionData.typeOfClearance,
              clearanceOption,
              {
                clearanceOption,
                finalClearanceText,
              }
            );
          }}
        >
          Save & Update Clearance
        </Button>
      </Stack>
    </Card>
  );
};

export default GenericXMLClearer;
