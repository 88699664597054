import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  useTheme,
  Box,
  Tooltip,
  Button,
} from "@mui/material";
import Iconify from "src/components/iconify";
import { Stack } from "@mui/system";
import cogoToast from "cogo-toast";

const fieldNameLookup = {
  vaccineName: "Vaccine Name",
  dose: "Dose",
  series: "Series",
  manufacturer: "Manufacturer",
  lotNumber: "Lot Number",
  notes: "Notes",
  declinationReason: "Declination Reason",
};

export const VaccinationDataViewer = ({ data_, onUpdate }) => {
  const theme = useTheme();
  const [data, setData] = useState(data_);
  const [abnormalFields, setAbnormalFields] = useState(data.hasAbnormal || []);
  const [hasChange, setHasChange] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const handleSave = () => {
    setIsSaving(true);
    onUpdate(data);
    setIsSaving(false);
    setHasChange(false);
  };
  let saveElem = null;
  if (hasChange && !isSaving) {
    saveElem = (
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="overline">Unsaved Changes</Typography>
        <Button
          variant="contained"
          onClick={handleSave}
          startIcon={<Iconify icon="bx:save" />}
        >
          Save
        </Button>
      </Stack>
    );
  }
  if (isSaving) {
    saveElem = (
      <Stack direction="row" spacing={2}>
        <Typography variant="overline">Saving...</Typography>
        <Iconify icon="pulse-3" />
      </Stack>
    );
  }

  const handleChange = (field, value) => {
    if (!onUpdate) {
      cogoToast.error("Updates are restricted.");
      return;
    }
    const updatedData = { ...data, [field]: value };
    setData(updatedData);
    setHasChange(true);
    setIsSaving(false);
  };

  const toggleAbnormal = (field) => {
    const newAbnormalFields = abnormalFields.includes(field)
      ? abnormalFields.filter((f) => f !== field)
      : [...abnormalFields, field];
    setAbnormalFields(newAbnormalFields);
    onUpdate({ ...data, hasAbnormal: newAbnormalFields });
  };

  const isAbnormal = (field) => abnormalFields.includes(field);

  const getTooltipText = (field) => {
    return isAbnormal(field)
      ? "This field is potentially abnormal. Click to mark it as normal."
      : "This field is normal. Click to mark it as potentially abnormal.";
  };
  const renderRow = (field) => (
    <TableRow
      key={field}
      sx={{
        bgcolor: isAbnormal(field) ? theme.palette.warning.light : "inherit",
      }}
    >
      <TableCell>
        <Box display="flex" alignItems="center">
          {fieldNameLookup[field] || field}
          <Tooltip title={getTooltipText(field)} arrow>
            <Box
              component="span"
              sx={{ display: "inline-flex", ml: 1, cursor: "pointer" }}
            >
              <Iconify
                icon={isAbnormal(field) ? "ep:warning-filled" : "mdi:tick"}
                onClick={() => toggleAbnormal(field)}
              />
            </Box>
          </Tooltip>
        </Box>
      </TableCell>
      <TableCell>
        <TextField
          fullWidth
          size="small"
          value={data[field] || ""}
          onChange={(e) => handleChange(field, e.target.value)}
          multiline={field === "notes" || field === "declinationReason"}
          rows={field === "notes" || field === "declinationReason" ? 2 : 1}
        />
      </TableCell>
    </TableRow>
  );
  const renderHeader = () => {
    if (abnormalFields.length === 0) {
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">Vaccination Data</Typography>
          {saveElem}
        </Box>
      );
    }

    const abnormalFieldNames = abnormalFields
      .map((field) => fieldNameLookup[field] || field)
      .join(", ");

    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{
          breakAfter: "avoid",
        }}
      >
        <Box display="flex" alignItems="center">
          <Iconify
            icon="mdi:warning"
            sx={{ mr: 1, color: theme.palette.error.main }}
          />
          <Typography variant="subtitle1">
            Vaccination Data | Red Flags: {abnormalFieldNames}
          </Typography>
        </Box>
        {saveElem}
      </Box>
    );
  };

  return (
    <div
      style={{
        backgroundColor: hasChange ? theme.palette.warning.lighter : "inherit",
      }}
    >
      <Card
        sx={{
          p: 2,
          m: 1,
          bgcolor:
            abnormalFields.length > 0
              ? theme.palette.error.light
              : theme.palette.info.lighter,
        }}
      >
        {renderHeader()}
      </Card>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {Object.keys(data)
              .filter((key) => !["dataType", "hasAbnormal"].includes(key))
              .map(renderRow)}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
