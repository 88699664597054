// 'use client';
import { Typography } from "@mui/material";
import { BarList, LineChart } from "@tremor/react";
import { WithAPICall } from "../utils/apiUtil";
import { useContext, useEffect, useState } from "react";
import structureData from "./utils/massage-data";
import { shuffle } from "lodash";
import HOCContext from "./contexts/HOCContext";
import VisitStatusReport from "../Report/VisitStatusReport";

export default WithAPICall(function VisitStatusReportWrapper({ apiCallPost }) {
  const [data, setData] = useState([]);
  const { from, to, companyIds } = useContext(HOCContext);

  useEffect(() => {
    const init = async () => {
      try {
        console.log("vsrw from", from, "to", to);
        const res = await apiCallPost("/reports/visit/status", {
          from: from.toDate(),
          to: to.toDate(),
        });
        console.log("vsrw res", res);
        setData(res);
      } catch (error) {
        console.error("could not get data", error);
      }
    };
    init();
  }, [from, to, companyIds]);

  return (
    <>
      <VisitStatusReport
        key={data.length}
        data={data}
        disableCompanySelector={true}
      />
    </>
  );
});
