/**
 *
 * Contains logic for each drug service
 * Handles reading, positive, negative, mro callbacks, cleared rendering
 *
 */

import React, { useState, useEffect } from "react";
import { Check, AlertTriangle, HelpCircle, FileText } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";

import { useProtectedApi } from "@/hooks/use-apiCall";
import useVisitStore from "@/store/useVisitStore";

import { DrugScreenClearancePositive } from "./DrugScreenClearancePositive";
import { DrugScreenClearanceNegative } from "./DrugScreenClearanceNegative";
import { ClearanceScreenDone } from "./ClearanceScreenDone";

export function DrugScreenMother({
  currentStatus,
  protocolId,
  medicalRecords,
}) {
  const { callApi } = useProtectedApi();
  const { visit, loadVisit } = useVisitStore();

  const drugServices = currentStatus.childDrugServices;
  const drugAlcoholClearanceStatus = currentStatus.drugAlcoholClearanceStatus;

  return (
    <div className="space-y-6">
      {drugServices.map((drugService, index) => {
        const currentStatus = drugAlcoholClearanceStatus[drugService._id];
        return (
          <DrugScreenMotherSingle
            key={index}
            currentStatus={currentStatus}
            drugService={drugService}
            medicalRecords={medicalRecords}
          />
        );
      })}
    </div>
  );
}

function DrugScreenMotherSingle({
  currentStatus,
  drugService,
  medicalRecords,
}) {
  const { callApi } = useProtectedApi();
  const { visit, loadVisit } = useVisitStore();

  const thisServiceMR = medicalRecords.filter(
    (mr) =>
      mr.serviceId === drugService._id && mr.dataType === "CHAIN_OF_CUSTODY"
  );

  const hasPositiveDrugTest = thisServiceMR?.[0]?.final_result === "POSITIVE";

  let prefillPositiveValue = null;
  if (currentStatus?.isPositive == null) {
    prefillPositiveValue = hasPositiveDrugTest ? "Positive" : "Negative";
  } else {
    prefillPositiveValue = currentStatus?.isPositive ? "Positive" : "Negative";
  }

  const [isCleared, setIsCleared] = useState(currentStatus?.isCleared || false);
  const [isPositive, setIsPositive] = useState(prefillPositiveValue);

  if (isCleared) {
    return (
      <ClearanceScreenDone
        mode="drug"
        entityId={drugService._id}
        onChangeClearance={() => setIsCleared(false)}
      />
    );
  }

  return (
    <>
      <div className="space-y-2">
        <Label
          htmlFor="clearance-case"
          className="text-sm font-medium text-gray-700"
        >
          Drug Panel Result
        </Label>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Select value={isPositive} onValueChange={setIsPositive}>
                <SelectTrigger id="clearance-case" className="w-full">
                  <SelectValue placeholder="Select clearance case" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value={"Positive"} className="text-xs">
                    Positive
                  </SelectItem>
                  <SelectItem value={"Negative"} className="text-xs">
                    Negative
                  </SelectItem>
                </SelectContent>
              </Select>
            </TooltipTrigger>
            <TooltipContent>
              <p>Select the clearance status for this case</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>

      {isPositive === "Positive" && (
        <DrugScreenClearancePositive
          currentStatus={currentStatus}
          drugService={drugService}
          medicalRecords={medicalRecords}
          onClear={(updatedVisit) => {
            loadVisit(updatedVisit);
            setIsCleared(true);
          }}
        />
      )}
      {isPositive === "Negative" && (
        <DrugScreenClearanceNegative
          currentStatus={currentStatus}
          drugService={drugService}
          medicalRecords={medicalRecords}
          onClear={(updatedVisit) => {
            loadVisit(updatedVisit);
            setIsCleared(true);
          }}
        />
      )}
    </>
  );

  //   return <div>Unknown case in DrugScreenMother</div>;
}
