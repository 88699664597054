import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Alert,
  Card,
  Chip,
  LinearProgress,
  ListItemText,
  Tooltip,
  IconButton,
  Checkbox,
  Button,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import _ from "lodash";
import Iconify from "src/components/iconify";
import { Box, Stack } from "@mui/system";
import cogoToast from "cogo-toast";
import { LoadingButton } from "@mui/lab";

class VisitEachProtocol extends React.Component {
  state = {
    isCheckingProtocolCriteria: false,
    satisfiesProtocolCriteria: true,
    showDetail: false,
    isCheckingServiceCriteria: false,
    serviceMetadata: [],
  };
  componentDidMount() {
    this.setState(
      {
        serviceMetadata: this.props.protocol.serviceMetadata,
      },
      () => {
        this.checkProtocolCriteria();
      }
    );
  }
  checkProtocolCriteria = async () => {
    let p = this.props.protocol;
    if (
      p.companyProtocolCriteriaType === "FILTER" &&
      p.companyProtocolCriteria &&
      p.companyProtocolCriteria.filterSpec &&
      !_.isEmpty(p.companyProtocolCriteria.filterSpec)
    ) {
      try {
        let res = await this.props.apiCallPost(
          "/employee/doesModifiedEmployeeSatisfyThisProtocol",
          {
            protocolId: p._id,
            employeeId: this.props.employee._id,
            modSpec: {},
          }
        );
        let satisfied = res.satisfies;
        this.setState(
          {
            isCheckingProtocolCriteria: false,
            satisfiesProtocolCriteria: satisfied,
          },
          () => {
            this.checkServiceCriteria();
          }
        );
      } catch (err) {
        cogoToast.error("Error Checking Protocol Criteria");
        this.setState({
          isError: true,
        });
      }
    } else {
      this.setState(
        {
          isCheckingProtocolCriteria: false,
          satisfiesProtocolCriteria: true,
        },
        () => {
          this.checkServiceCriteria();
        }
      );
    }
  };
  checkServiceCriteria = async () => {
    let ms = this.props.protocol.masterServices;
    let sm = _.cloneDeep(this.props.protocol.serviceMetadata);
    if (!ms || !ms.length) {
      this.setState({
        isCheckingServiceCriteria: true,
      });
    }
    let res = await Promise.all(
      sm.map((eachService) => {
        let svcCriteriaType = eachService.serviceCriteriaType;
        if (svcCriteriaType !== "Conditional: Criteria") {
          return {
            satisfies: true,
          };
        }
        let fs = eachService.serviceCriteria.filterSpec;
        if (!fs) {
          return {
            satisfies: true,
          };
        }

        let payload = {
          filterSpec: fs,
          employeeId: this.props.employee._id,
          modSpec: {},
        };

        let apiUrl = "/employee/doesEmployeeSatisfyQuery";
        return this.props.apiCallPost(apiUrl, payload);
      })
    );
    let smMod = sm.map((each, index) => {
      each["satisfiesCriteria"] = res[index].satisfies;

      // check if protocol already added
      let bookedProtocolIds = this.props.protocols;
      let protocolId = this.props.protocol._id;
      let isAdded = bookedProtocolIds.includes(protocolId);
      if (isAdded) {
        // check if this service is included

        let bookedServices = this.props.services;
        let bookedServicesForThisProtocol = _.filter(
          bookedServices,
          (x) => this.props.visit.serviceParentProtocols[x] === protocolId
        );

        let serviceId = ms[index];
        let isServiceAdded = bookedServicesForThisProtocol.includes(serviceId);
        each["included"] = isServiceAdded;
      } else {
        each["included"] = res[index].satisfies;
      }
      return each;
    });
    this.setState({
      serviceMetadata: smMod,
      isCheckingServiceCriteria: false,
    });
  };
  renderServices = () => {
    if (this.state.isCheckingServiceCriteria) {
      return <LinearProgress />;
    }
    if (!this.state.showDetail) {
      return null;
    }
    let ms = this.props.protocol.masterServices;
    let sm = this.state.serviceMetadata;
    if (!ms || !ms.length) {
      return null;
    }
    return sm.map((each, index) => {
      let criteriaType = each.serviceCriteriaType;
      let criteriaElem = null;

      if (criteriaType === "Always Required") {
        criteriaElem = "Always Required";
      }
      if (criteriaType === "Conditional: Criteria") {
        let reqd = each.satisfiesCriteria;
        if (reqd) {
          criteriaElem = "Conditional, Required for this employee";
        } else {
          criteriaElem = "Conditional, Not Required for this employee";
        }
      }
      if (criteriaType === "Conditional: NL") {
        let fs = each.serviceCriteria.filterSpec;
        let txt = "This service could be conditional on certain criteria";

        criteriaElem = `Optional: ${fs || txt}`;
      }
      if (criteriaType === "Optional") {
        let fs = each.serviceCriteria.filterSpec;
        let txt = "This service could be conditional on certain criteria";
        criteriaElem = `Optional: ${fs || txt}`;
      }
      return (
        <Stack
          direction={"row"}
          key={index}
          spacing={1}
          alignItems={"flex-start"}
          sx={{
            margin: "0.5rem 0 0.5rem 0",
          }}
        >
          <Checkbox
            disabled={this.props.isSaving || !this.props.canEditProtocol}
            checked={each.included}
            onChange={() => {
              let sm = _.cloneDeep(this.state.serviceMetadata);
              sm[index].included = !sm[index].included;
              this.setState({
                serviceMetadata: sm,
              });
            }}
          />
          <ListItemText
            primary={each.serviceName}
            secondary={criteriaElem}
            primaryTypographyProps={{ typography: "overline", mb: 0.5 }}
            secondaryTypographyProps={{
              typography: "caption",
              component: "span",
            }}
          />
        </Stack>
      );
    });
  };
  renderProtocolHeader = () => {
    if (this.state.isCheckingProtocolCriteria) {
      return <LinearProgress color="info" />;
    }
    let satisfies = this.state.satisfiesProtocolCriteria;
    let p = this.props.protocol;
    let secElem = null;
    if (
      p.companyProtocolCriteriaType === "FILTER" &&
      p.companyProtocolCriteria &&
      p.companyProtocolCriteria.filterSpec &&
      !_.isEmpty(p.companyProtocolCriteria.filterSpec)
    ) {
      if (!satisfies) {
        secElem = (
          <Chip
            color="warning"
            label={"Does Not Match Criteria"}
            icon={<Iconify icon="solar:shield-warning-broken" />}
            size="small"
          />
        );
      } else {
        secElem = (
          <Chip
            color="success"
            label={"Matches Criteria"}
            icon={<Iconify icon="solar:check-circle-broken" />}
            size="small"
          />
        );
      }
    } else {
      secElem = (
        <Chip
          color="info"
          label={"Please check criteria manually"}
          icon={<Iconify icon="solar:clipboard-broken" />}
          size="small"
        />
      );
    }

    let isIncluded = this.props.protocols.includes(p._id);
    let includedElem = null;
    if (isIncluded) {
      includedElem = (
        <Chip
          color="success"
          label={"Included in Visit"}
          icon={<Iconify icon="solar:check-circle-broken" />}
          size="small"
        />
      );
      secElem = null;
    }

    return (
      <Stack spacing={1} direction="row" alignItems={"center"}>
        {includedElem}
        {secElem}
      </Stack>
    );

    // return (
    //   <ListItemText
    //     primary={p.companyProtocolName}
    //     secondary={
    //       <Stack spacing={1} direction="row" alignItems={"center"}>
    //         {includedElem}
    //         {secElem}
    //         <Tooltip
    //           title={
    //             this.state.showDetail
    //               ? "Click to hide details"
    //               : "Click to show details"
    //           }
    //         >
    //           <IconButton
    //             size="small"
    //             onClick={() => {
    //               this.setState({
    //                 showDetail: !this.state.showDetail,
    //               });
    //             }}
    //           >
    //             <Iconify
    //               icon={
    //                 this.state.showDetail
    //                   ? "solar:square-double-alt-arrow-up-broken"
    //                   : "solar:square-double-alt-arrow-down-broken"
    //               }
    //             />
    //           </IconButton>
    //         </Tooltip>
    //       </Stack>
    //     }
    //     primaryTypographyProps={{ typography: "button", mb: 0.5 }}
    //     secondaryTypographyProps={{ component: "span" }}
    //   />
    // );
  };
  massageAddData = () => {
    let sm = this.state.serviceMetadata;
    let ms = this.props.protocol.masterServices;
    let bookedServices = [];
    for (var i = 0; i < sm.length; i++) {
      if (sm[i].included) {
        bookedServices.push(ms[i]);
      }
    }
    this.props.addProtocol(this.props.protocol._id, bookedServices);
  };
  massageRemoveData = () => {
    this.props.removeProtocol(this.props.protocol._id);
  };
  renderAddButton = () => {
    let protocols = this.props.protocols;
    let protocolId = this.props.protocol._id;
    if (
      this.state.isCheckingProtocolCriteria ||
      this.state.isCheckingServiceCriteria
    ) {
      return null;
    }

    if (protocols.includes(protocolId)) {
      return (
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
          }}
        >
          <LoadingButton
            loading={this.props.isSaving}
            disabled={this.state.isCheckingServiceCriteria}
            color="info"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              this.massageAddData();
            }}
            variant="outlined"
            startIcon={<Iconify icon="material-symbols:update" />}
          >
            Update
          </LoadingButton>
          <LoadingButton
            loading={this.props.isSaving}
            color="error"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              this.massageRemoveData();
            }}
            variant="outlined"
            startIcon={<Iconify icon="mdi:delete-outline" />}
          >
            Remove
          </LoadingButton>{" "}
        </Box>
      );
    }
    return (
      <Box>
        <LoadingButton
          loading={this.props.isSaving}
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            this.massageAddData();
          }}
          variant="outlined"
          startIcon={<Iconify icon="gridicons:add-outline" />}
        >
          Add To Visit
        </LoadingButton>
      </Box>
    );
  };
  render() {
    if (this.state.isError) {
      return <Alert severity="error">Error Loading Protocol Data</Alert>;
    }
    let cardSx = {
      p: 2,
      rounded: 2,
      boxShadow: 2,
    };
    let protocols = this.props.protocols;
    let protocolId = this.props.protocol._id;
    if (protocols.includes(protocolId)) {
      cardSx["bgcolor"] = "background.neutral";
      cardSx["boxShadow"] = 5;
    }

    return (
      <>
        <Grid
          container
          spacing={1}
          sx={{
            padding: "0.5rem 0.2rem",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
              cursor: "pointer",
            },
          }}
          onClick={() => {
            this.setState({
              showDetail: !this.state.showDetail,
            });
          }}
        >
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {this.props.protocol.companyProtocolName}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            {this.props.protocol.masterServices.length > 0 && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  this.setState({
                    showDetail: !this.state.showDetail,
                  });
                }}
                startIcon={
                  <Iconify
                    icon={
                      this.state.showDetail
                        ? "solar:square-double-alt-arrow-up-broken"
                        : "solar:square-double-alt-arrow-down-broken"
                    }
                  />
                }
              >
                {!this.state.showDetail ? "Show Services" : "Hide Services"}
              </Button>
            )}
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            {this.props.canEditProtocol && this.renderAddButton()}
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            margin: "0.5rem 0 0.5rem 0",
          }}
        >
          <Grid item xs={6}>
            {this.renderServices()}
          </Grid>
        </Grid>
      </>
    );
    // return (
    //   <Card sx={cardSx}>
    //     <Stack spacing={2} direction="row">
    //       <Typography variant="subtitle1">
    //         {this.props.protocol.companyProtocolName}
    //       </Typography>
    //       {this.renderProtocolHeader()}
    //       {this.renderServices()}
    //       <Tooltip
    //         title={
    //           this.state.showDetail
    //             ? "Click to hide details"
    //             : "Click to show details"
    //         }
    //       >
    //         <IconButton
    //           size="small"
    //           onClick={() => {
    //             this.setState({
    //               showDetail: !this.state.showDetail,
    //             });
    //           }}
    //         >
    //           <Iconify
    //             icon={
    //               this.state.showDetail
    //                 ? "solar:square-double-alt-arrow-up-broken"
    //                 : "solar:square-double-alt-arrow-down-broken"
    //             }
    //           />
    //         </IconButton>
    //       </Tooltip>
    //       {this.props.canEditProtocol && this.renderAddButton()}
    //     </Stack>
    //   </Card>
    // );
  }
}

export default WithAPICall(VisitEachProtocol);
