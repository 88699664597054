import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import _, { flatten } from "lodash";
import { Box, Stack } from "@mui/system";
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  Chip,
  IconButton,
  Link,
  MenuItem,
  Popover,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Iconify from "src/components/iconify";
import { niceDate, niceDateTime } from "../utils/fn";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { LoadingButton } from "@mui/lab";
import cogoToast from "cogo-toast";
import { DayPicker } from "react-day-picker";
import DictaBox from "../utils/DictaBox";

class EachTask extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    isDoingAction: false,
    holdDate: null,
    anchorEl: null,
    openHoldDateSelector: false,
    isSearchingUser: false,
    userSearchResults: [],
    currentlyChosenUser: null,
    isEditingAssignee: false,
    showUpdateLog: false,
  };
  INTERPRETER_LIST = [
    {
      key: "CompanySetUp",
      label: "Set Up Employer Data",
      icon: "system-uicons:settings",
    },
    {
      key: "CAPSetUp",
      label: "Set Up Employer Provider Links",
      icon: "system-uicons:link-alt",
    },
    {
      key: "CompanyProtocolSetUp",
      label: "Set Up Employer Protocols",
      icon: "system-uicons:folder-add",
    },
    {
      key: "VisitSchedule",
      label: "Schedule Visit",
      icon: "uim:calender",
    },
    {
      key: "VisitDocumentChase",
      label: "Documents Chaser",
      icon: "icon-park-twotone:document-folder",
    },
    {
      key: "VisitClose",
      label: "Close Visit",
      icon: "fluent:calendar-cancel-16-regular",
    },
    {
      key: "ServiceSummarization",
      label: "Summarize Service",
      icon: "fa-solid:file-medical-alt",
    },
    {
      key: "ProtocolSummarization",
      label: "Summarize Protocol",
      icon: "fluent:book-pulse-24-filled",
    },
    {
      key: "ServiceClearance",
      label: "Clear Service",
      icon: "streamline:file-delete-alternate-solid",
    },
    {
      key: "ProtocolClearance",
      label: "Clear Protocol",
      icon: "material-symbols-light:folder-delete",
    },
    {
      key: "ProcessDocument",
      label: "Process Document",
      icon: "carbon:business-processes",
    },
    {
      key: "AssociateWithVisit",
      label: "Link Document With Visit",
      icon: "fluent:comment-link-16-filled",
    },
    {
      key: "VisitCreate",
      label: "Create Visit",
      icon: "fluent:form-new-24-filled",
    },
    {
      key: "DrawPool",
      label: "Create Pool Draw",
      icon: "material-symbols:group-add",
    },
  ];
  STATUS_LIST = [
    {
      key: "PENDING",
      label: "Pending",
      icon: "material-symbols:pending-actions-rounded",
      color: "warning",
    },
    {
      key: "COMPLETED",
      label: "Completed",
      icon: "carbon:task-complete",
      color: "success",
    },
    {
      key: "IN_PROGRESS",
      label: "In Progress",
      icon: "svg-spinners:pulse-rings-2",
      color: "info",
    },
    {
      key: "ON_HOLD",
      label: "On Hold",
      icon: "tabler:device-tablet-pause",
      color: "secondary",
    },
    {
      key: "CANCELLED",
      label: "Cancelled",
      icon: "carbon:rule-cancelled",
      color: "error",
    },
  ];
  searchUsersFTS = async (t) => {
    try {
      let res = await this.props.apiCallPostCancellable("/user/searchUser", {
        query: t,
        company: null,
        showDeactivatedAlso: false,
      });
      if (res) {
        this.setState({
          isSearchingUser: false,
          userSearchResults: res.map((each) => {
            return {
              mainText: each.name,
              secondaryText: each.email,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingUser: false,
        userSearchResults: [],
      });
      cogoToast.error("Error Searching Users");
    }
  };
  createVisitFromTask = async () => {
    this.setState({ isDoingAction: true });
    try {
      let res = await this.props.apiCallPost("/task/createVisitFromTask", {
        taskId: this.props.task._id,
      });
      this.props.onUpdate(res);
    } catch (err) {
      cogoToast.error("Error Creating Visit");
      console.log(err);
    }
    this.setState({ isDoingAction: false });
  };
  updateTask = async (uops) => {
    this.setState({ isDoingAction: true });
    try {
      let res = await this.props.apiCallPost("/task/updateTask", {
        taskId: this.props.task._id,
        uops: uops,
      });
      this.props.onUpdate(res);
    } catch (err) {
      cogoToast.error("Error Updating Task");
      console.log(err);
    }
    this.setState({
      isDoingAction: false,
      isEditingAssignee: false,
      currentlyChosenUser: null,
      userSearchResults: [],
    });
  };
  markAsComplete = async () => {
    this.setState({ isDoingAction: true });
    let uops = [
      {
        key: "taskStatus",
        value: "COMPLETED",
      },
      {
        key: "taskFinishedAt",
        value: new Date(),
      },
    ];
    try {
      let res = await this.props.apiCallPost("/task/updateTask", {
        taskId: this.props.task._id,
        uops: uops,
      });
      this.props.onUpdate(res);
    } catch (err) {
      cogoToast.error("Error Updating Task");
      console.log(err);
    }
    this.setState({ isDoingAction: false });
  };
  updateVisitClearance = async (
    whichEntity,
    whichEntityKey,
    clearance,
    typeOfClearance,
    clearanceCase
  ) => {
    try {
      this.setState({
        isDoingAction: true,
      });

      let visitId = this.props.task.visit._id;
      let entityId = this.props.task[whichEntity]._id;
      await this.props.apiCallPost("/visit/updateVisitClearance", {
        visitId: visitId,
        entity: whichEntityKey,
        entityId: entityId,
        clearance: clearance,
        typeOfClearance: typeOfClearance,
        clearanceCase: clearanceCase,
        clearanceInsn: "",
      });
      this.markAsComplete();
    } catch (err) {
      this.setState({
        isDoingAction: false,
      });
      cogoToast.error("Error Updating Visit Clearance Data");
      console.log(err);
    }
  };
  updateVisitClearanceNote = async (
    whichEntity,
    clearanceNote,
    whichEntityKey
  ) => {
    try {
      this.setState({
        isDoingAction: true,
      });
      let visitId = this.props.task.visit._id;
      let entityId = this.props.task[whichEntityKey]._id;
      await this.props.apiCallPost("/visit/updateVisitClearanceNote", {
        visitId: visitId,
        entity: whichEntity,
        entityId: entityId,
        clearanceNote: clearanceNote,
        clearanceInsn: "",
      });
      this.setState({
        isDoingAction: false,
      });
    } catch (err) {
      this.setState({
        isDoingAction: false,
      });
      cogoToast.error("Error Updating Visit Clearance Data");
      console.log(err);
    }
  };
  renderUserSearcher = () => {
    if (this.props.currentUser.isExternal) {
      return null;
    }
    return (
      <Autocomplete
        sx={{
          minWidth: "200px",
        }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option?.mainText
        }
        filterOptions={(x) => x}
        options={this.state.isSearchingUser ? [] : this.state.userSearchResults}
        autoComplete
        includeInputInList
        filterSelectedOptions
        disabled={this.state.isDoingAction}
        value={this.state.currentlyChosenUser}
        noOptionsText={
          this.state.isSearchingUser ? "Searching..." : "No results found"
        }
        onChange={(_, value) => {
          this.setState({
            currentlyChosenUser: value,
            userSearchResults: [value, ...this.state.userSearchResults],
          });
        }}
        onInputChange={(_, e) => {
          if (e === "") return null;
          if (!e) return null;
          if (e.length < 4) return null;
          this.setState(
            {
              isSearchingUser: true,
            },
            () => {
              this.searchUsersFTS(e);
            }
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label="Search Users" fullWidth />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ wordWrap: "break-word" }}>
                  <Box component="span">{option?.mainText}</Box>
                  <Typography variant="body2" color="text.secondary">
                    {option?.secondaryText}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    );
  };
  renderIfCompany = () => {
    if (_.isEmpty(this.props.task.company)) {
      return null;
    }
    if (this.props.hideCompany) return null;
    let name = this.props.task.company.companyName;
    let url = `/employers/${this.props.task.company._id}`;
    return this.renderNameAndLink("Employer", name, url);
  };
  renderIfEmployee = () => {
    if (_.isEmpty(this.props.task.employee)) {
      return null;
    }
    if (this.props.hideEmployee) return null;
    let name = this.props.task.employee.employeeName;
    let url = `/employees/${this.props.task.employee._id}`;
    return this.renderNameAndLink("Employee", name, url);
  };
  renderIfVisit = () => {
    if (_.isEmpty(this.props.task.visit)) {
      return null;
    }
    if (this.props.hideVisit) return null;
    let name = "Visit Details";
    let url = `/visits/${this.props.task.visit._id}`;
    return this.renderNameAndLink("", name, url);
  };
  renderServiceDataFromVisit = () => {
    let interpreter = this.props.task.taskInterpreter;
    if (interpreter === "ServiceSummarization") {
      let ms = this.props.task.masterService;
      let msId = ms._id;
      let visit = this.props.task.visit;
      let bs = visit?.bookedServices;
      let idx = _.findIndex(bs, { serviceId: msId });
      if (idx === -1) return null;
      let summary = bs[idx].summary;
      return this.renderSummary(summary);
    }
    if (interpreter === "ProtocolSummarization") {
      let visit = this.props.task.visit;
      let protocolSummaries = visit?.protocolSummaries;
      let ps = protocolSummaries;
      let protocol = this.props.task.protocol;
      let idx = _.findIndex(ps, { protocolId: protocol._id });
      if (idx === -1) return null;
      let summary = ps[idx].summary;
      return this.renderSummary(summary);
    }
    if (interpreter === "ProtocolClearance") {
      let visit = this.props.task.visit;
      let protocol = this.props.task.protocol;
      let ps = visit?.protocolSummaries;
      let idx = _.findIndex(ps, { protocolId: protocol._id });
      if (idx === -1) return null;
      let summary = ps[idx].summary;
      let evaluation = ps[idx].evaluation;
      let clearanceOptions = protocol.clearanceOptions;
      return (
        <Stack spacing={1}>
          {this.renderSummary(summary)}
          {this.renderEval(evaluation)}
          {this.renderClearanceOptions(
            clearanceOptions,
            ps[idx],
            idx,
            "Protocol",
            "protocol"
          )}
        </Stack>
      );
    }
    if (interpreter === "ServiceClearance") {
      let ms = this.props.task.masterService;
      let msId = ms._id;
      let visit = this.props.task.visit;
      let bs = visit?.bookedServices;
      let idx = _.findIndex(bs, { serviceId: msId });
      if (idx === -1) return null;
      let summary = bs[idx].summary;
      let evaluation = bs[idx].evaluation;
      let clearanceOptions = ms.clearanceOptions;
      return (
        <Stack spacing={1}>
          {this.renderSummary(summary)}
          {this.renderEval(evaluation)}
          {this.renderClearanceOptions(
            clearanceOptions,
            bs[idx],
            idx,
            "MasterService",
            "masterService"
          )}
        </Stack>
      );
    }
  };
  renderIfDocument = () => {
    if (_.isEmpty(this.props.task.document)) {
      return null;
    }
    let name = this.props.task.document.originalName;
    let url = `/documents/${this.props.task.document._id}`;
    return this.renderNameAndLink("File", name, url);
  };
  renderIfProvider = () => {
    if (_.isEmpty(this.props.task.provider)) {
      return null;
    }
    let name = this.props.task.provider.providerName;
    let url = `/providers/${this.props.task.provider._id}`;
    let extraElem = null;
    if (this.props.task.provider.providerPhone) {
      extraElem = {
        key: "Phone",
        value: this.props.task.provider.providerPhone,
      };
    }
    return this.renderNameAndLink("Provider", name, url, extraElem);
  };
  renderIfService = () => {
    if (_.isEmpty(this.props.task.masterService)) {
      return null;
    }
    let name = this.props.task.masterService.serviceName;
    let url = `/services/${this.props.task.masterService._id}`;
    return this.renderNameAndLink("Service", name, url);
  };
  renderIfProtocol = () => {
    if (_.isEmpty(this.props.task.protocol)) {
      return null;
    }
    let name = this.props.task.protocol.companyProtocolName;
    let url = `/protocols/${this.props.task.protocol._id}`;
    return this.renderNameAndLink("Protocol", name, url);
  };
  renderAssignee = () => {
    let status = this.props.task.taskStatus;
    if (this.state.isEditingAssignee) {
      if (this.props.currentUser.isExternal) {
        return null;
      }

      if (["COMPLETED", "CANCELLED"].includes(status)) {
        return null;
      }
      let saveButton = (
        <LoadingButton
          loading={this.state.isDoingAction}
          onClick={() => {
            this.updateTask([
              {
                key: "taskAssignee",
                value: "User",
              },
              {
                key: "taskAssigneeId",
                value: this.state.currentlyChosenUser.data._id,
              },
            ]);
          }}
        >
          Save
        </LoadingButton>
      );
      if (!this.state.currentlyChosenUser) {
        saveButton = null;
      }
      if (this.state.currentlyChosenUser) {
        if (this.props.task.taskAssignee) {
          if (this.props.task.taskAssignee === "User") {
            let id = this.props.task?.taskAssigneeId?._id;
            if (id === this.state.currentlyChosenUser.data._id) {
              saveButton = (
                <Button
                  onClick={() => {
                    this.setState({ isEditingAssignee: false });
                  }}
                >
                  Save
                </Button>
              );
            }
          }
        }
      }

      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="caption">Assign To</Typography>
          {this.renderUserSearcher()} {saveButton}
        </Stack>
      );
    }
    let taskAssignee = this.props.task.taskAssignee;

    if (!taskAssignee) {
      if (["COMPLETED", "CANCELLED"].includes(status)) {
        return null;
      }
      return (
        <Box>
          <Button
            onClick={() => this.setState({ isEditingAssignee: true })}
            variant="outlined"
          >
            Assign Task
          </Button>
        </Box>
      );
    }
    let isUser = taskAssignee === "User";
    let name = isUser
      ? this.props.task.taskAssigneeId?.name || "User"
      : "System";
    let editButton = (
      <IconButton onClick={() => this.setState({ isEditingAssignee: true })}>
        <Iconify icon="mdi:pencil" />
      </IconButton>
    );
    if (this.props.currentUser.isExternal) {
      editButton = null;
    }
    if (["COMPLETED", "CANCELLED"].includes(status)) {
      editButton = null;
    }
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="caption">Assigned To</Typography>{" "}
        <Typography variant="overline">{name}</Typography> {editButton}
      </Stack>
    );
  };
  renderNameAndLink = (type, name, link, extraElem = null) => {
    let extra = null;
    if (extraElem) {
      extra = (
        <Stack direction="row" alignItems={"center"} spacing={1}>
          <Typography variant="caption">
            {extraElem.key} : {extraElem.value}
          </Typography>{" "}
        </Stack>
      );
    }
    return (
      <Stack spacing={0}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="caption">{type}</Typography>{" "}
          <Typography variant="overline">{name}</Typography>{" "}
          <IconButton
            size="small"
            href={link}
            variant="outlined"
            // target="non_blank"
          >
            <Iconify icon="mdi:arrow-top-right" />
          </IconButton>
        </Stack>
        {extra}
      </Stack>
    );
  };
  renderSummary = (summary) => {
    if (!summary) {
      return null;
    }
    let s = summary;
    let lines = s.split("\n");
    return (
      <Stack spacing={2}>
        <Stack spacing={1} direction={"row"} alignItems={"center"}>
          <Typography variant="overline">Summary</Typography>
          <Tooltip
            title={
              this.state.showSummary
                ? "Click to hide summary"
                : "Click to show summary"
            }
          >
            <IconButton
              size="small"
              onClick={() => {
                this.setState({
                  showSummary: !this.state.showSummary,
                });
              }}
            >
              <Iconify
                icon={
                  this.state.showDetail
                    ? "solar:square-double-alt-arrow-up-broken"
                    : "solar:square-double-alt-arrow-down-broken"
                }
              />
            </IconButton>
          </Tooltip>
        </Stack>
        <Stack spacing={0}>
          {lines.map((line) => {
            if (!line) return null;
            if (line.trim() === "") return null;
            if (!this.state.showSummary) return null;
            // if we find word "Red Flags:", render a chip with label Red Flag & then rest of the text.
            let ll = _.trim(line);
            if (ll.startsWith("Red Flags:")) {
              let rest = ll.replace("Red Flags:", "");
              return (
                <Stack spacing={1}>
                  {" "}
                  <Box
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Chip label="Red Flags" color="error" size="small" />
                  </Box>
                  <Typography variant="caption">{rest}</Typography>
                </Stack>
              );
            }
            // Headline: ==> info
            if (ll.startsWith("Headline:")) {
              let rest = ll.replace("Headline:", "");
              return (
                <Stack spacing={1}>
                  <Box
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Chip label="Headline" color="info" size="small" />
                  </Box>
                  <Typography variant="caption">{rest}</Typography>
                </Stack>
              );
            }
            // Summary: ==> secondary

            if (ll.startsWith("Summary:")) {
              let rest = ll.replace("Summary:", "");
              return (
                <Stack spacing={1}>
                  <Box
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Chip label="Summary" color="secondary" size="small" />
                  </Box>
                  <Typography variant="caption">{rest}</Typography>
                </Stack>
              );
            }
            return <Typography variant="caption">{line}</Typography>;
          })}
        </Stack>
      </Stack>
    );
  };
  renderEval = (evaluation) => {
    if (!evaluation) {
      return null;
    }
    let s = evaluation;
    let lines = s.split("\n");
    return (
      <Stack spacing={2}>
        <Typography variant="overline">Evaluation</Typography>
        <Stack spacing={0}>
          {lines.map((line) => {
            let ll = _.trim(line);
            if (ll.startsWith("Recommendation:")) {
              return (
                <Stack spacing={1} direction="row" alignItems={"center"}>
                  {" "}
                  <Box
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Chip
                      label={`AI ${line}`}
                      color="secondary"
                      size="small"
                      variant="outlined"
                    />
                  </Box>
                </Stack>
              );
            }

            return <Typography variant="caption">{line}</Typography>;
          })}
        </Stack>
      </Stack>
    );
  };
  renderClearanceOptions = (
    clearanceOptions,
    bs,
    bsIndex,
    whichEntity,
    whichEntityKey
  ) => {
    let taskStatus = this.props.task.taskStatus;
    if (taskStatus === "CANCELLED") {
      return null;
    }
    let dictaElem = (
      <DictaBox
        disabled={this.state.isDoingAction || taskStatus === "COMPLETED"}
        text={bs.clearanceNote || ""}
        entityType="VoiceNote"
        entityId={bs._id}
        label="Clearance Notes"
        onAction={(text) => {
          this.updateVisitClearanceNote(whichEntity, text, whichEntityKey);
        }}
      />
    );
    if (taskStatus === "COMPLETED") {
      dictaElem = (
        <Stack spacing={1}>
          <Typography variant="overline">Clearance Notes</Typography>{" "}
          <Typography variant="caption">
            {bs.clearanceNote || "No Clearance Notes"}
          </Typography>
        </Stack>
      );
    }
    if (!clearanceOptions) {
      return (
        <Alert severity="error">
          The selected protocol for this visit does not have a clearance option
          set. Please click{" "}
          <Link
            href={`/protocols/${this.props.task.protocol._id}`}
            target="_blank"
          >
            here
          </Link>{" "}
          to add clearance options.
        </Alert>
      );
    }
    if (!clearanceOptions.length) {
      return (
        <Alert severity="error">
          The selected protocol for this visit does not have a clearance option
          set. Please click{" "}
          <Link
            href={`/protocols/${this.props.task.protocol?._id}/?currentTab=CLEARANCE`}
            target="_blank"
          >
            here
          </Link>{" "}
          to add clearance options.
        </Alert>
      );
      // return null;
    }
    let options = clearanceOptions.map((x) => x.case);
    let clElem = null;
    if (bs.clearanceCase) {
      let type = bs.typeOfClearance;
      let color = "secondary";
      if (type === "Not Cleared") {
        color = "error";
      } else if (type === "Cleared") {
        color = "primary";
      } else {
        color = "warning";
      }
      clElem = (
        <Stack spacing={2}>
          <Typography variant="overline" color={color}>
            {type}
          </Typography>
          <TextField
            size="small"
            disabled={this.state.isDoingAction || taskStatus === "COMPLETED"}
            multiline
            fullWidth
            InputLabelProps={{
              shrink: bs.clearance,
            }}
            label="Clearance Language"
            value={bs.clearance}
            onChange={(e) => {
              let data = this.props.task;
              bs.clearance = e.target.value;
              data.visit.bookedServices[bsIndex] = bs;
              this.props.onUpdate(data);
            }}
          />
        </Stack>
      );
    }
    return (
      <Stack spacing={2}>
        <Stack direction="row" alignItems={"center"} spacing={2}>
          <Typography variant="overline">Clearance</Typography>
          <Select
            size="small"
            disabled={this.state.isDoingAction || taskStatus === "COMPLETED"}
            sx={{
              minWidth: "150px",
            }}
            value={bs.clearanceCase}
            onChange={(e) => {
              let idx = _.findIndex(
                clearanceOptions,
                (x) => x.case === e.target.value
              );
              let data = this.props.task;
              bs.clearanceCase = e.target.value;
              bs.clearance = clearanceOptions[idx].text;
              bs.typeOfClearance = clearanceOptions[idx].typeOfClearance;
              data.visit.bookedServices[bsIndex] = bs;
              this.props.onUpdate(data);
            }}
          >
            {options.map((opt, idx) => (
              <MenuItem size="small" value={opt} key={idx}>
                {opt}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        {clElem}
        {dictaElem}
      </Stack>
    );
  };
  renderMetadata = () => {
    let md = this.props.task.taskMetadata;
    if (!md) return null;
    let keys = Object.keys(md);
    if (!keys.length) {
      return null;
    }
    return (
      <Stack spacing={2}>
        {keys.map((eachKey) => {
          return this.renderEachKeyOfMetadata(eachKey);
        })}
      </Stack>
    );
  };
  renderEachKeyOfMetadata = (key) => {
    let data = this.props.task.taskMetadata[key];

    if (key === "protocols") {
      let dd = data.map((x) => {
        return {
          companyProtocolName: x.companyProtocolName,
          companyProtocolType: x.companyProtocolType,
        };
      });
      return (
        <Stack spacing={1}>
          <Typography variant="overline">Protocols</Typography>
          {dd.map((each) => {
            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="overline">
                  {each.companyProtocolName}
                </Typography>
                <Typography variant="caption">
                  {each.companyProtocolType}
                </Typography>{" "}
              </Stack>
            );
          })}
        </Stack>
      );
    }
  };
  renderDueDate = () => {
    let finishedAt = this.props.task.taskFinishedAt;
    let dueDate = this.props.task.taskDueDate;
    let nice = niceDate(dueDate);
    if (!finishedAt) {
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="subtitle2">Due</Typography>{" "}
          <Typography variant="subtitle1">{nice}</Typography>
        </Stack>
      );
    }
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="caption">Due</Typography>{" "}
        <Typography variant="overline">{nice}</Typography>
      </Stack>
    );
  };
  renderTaskFinishedAt = () => {
    let finishedAt = this.props.task.taskFinishedAt;
    if (!finishedAt) {
      return null;
    }
    let nice = niceDateTime(finishedAt);
    let artefactElem = null;
    let taskCompletor = this.props.task.taskCompletor;
    let isUser = taskCompletor === "User";
    let name = isUser
      ? this.props.task.taskCompletorId?.name || "User"
      : "System";
    let text = `Task Completed by ${name} at ${nice}`;
    let elemText = "Task Completed";
    let interpreter = this.props.task.taskInterpreter;
    if (interpreter === "VisitCreate") {
      elemText = "Visit Created";
      artefactElem = (
        <IconButton
          size="small"
          href={`/visits/${this.props.task.taskFinalArtifactId}`}
          variant="outlined"
          // target="non_blank"
        >
          <Iconify icon="mdi:arrow-top-right" />
        </IconButton>
      );
    }
    return (
      <Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="overline">{elemText}</Typography> {artefactElem}{" "}
        </Stack>{" "}
        <Typography variant="caption">{text}</Typography>
      </Stack>
    );
  };
  renderInterpreter = () => {
    let interpreter = this.props.task.taskInterpreter;
    let found = _.find(this.INTERPRETER_LIST, { key: interpreter });
    if (!found) {
      return null;
    }
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Iconify icon={found.icon} />
        <Typography variant="overline">{found.label}</Typography>{" "}
      </Stack>
    );
  };
  renderStatus = () => {
    let status = this.props.task.taskStatus;
    let found = _.find(this.STATUS_LIST, { key: status });
    if (!found) {
      return null;
    }
    return (
      <Chip
        label={found.label}
        color={found.color}
        icon={<Iconify icon={found.icon} />}
      />
    );
  };
  renderInfo = () => {
    return (
      <Box
        rowGap={1}
        columnGap={1}
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)",
          xl: "repeat(4, 1fr)",
        }}
      >
        {this.renderIfEmployee()}
        {this.renderIfCompany()}
        {this.renderIfVisit()}
        {this.renderIfProvider()}
        {this.renderIfService()}
        {this.renderIfProtocol()}
        {this.renderIfDocument()}
      </Box>
    );
  };
  renderAction = () => {
    let status = this.props.task.taskStatus;
    if (["COMPLETED", "CANCELLED"].includes(status)) {
      return null;
    }
    let interpreter = this.props.task.taskInterpreter;
    let action = null;
    let text = "Mark Complete";
    if (interpreter === "VisitCreate") {
      action = this.createVisitFromTask;
      text = "Create Visit";
    }
    if (
      [
        "CompanySetUp",
        "CAPSetUp",
        "CompanyProtocolSetUp",
        "VisitSchedule",
        "VisitDocumentChase",
        "VisitClose",
        "AssociateWithVisit",
        "ProcessDocument",
        "DrawPool",
      ].includes(interpreter)
    ) {
      action = this.markAsComplete;
      text = "Mark Done";
    }
    if (["ServiceSummarization"].includes(interpreter)) {
      return null;
    }
    // if (["ServiceClearance"].includes(interpreter)) {
    //   let ms = this.props.task.masterService;
    //   let msId = ms._id;
    //   let visit = this.props.task.visit;
    //   let bs = visit?.bookedServices;
    //   let idx = _.findIndex(bs, { serviceId: msId });
    //   if (idx === -1) {
    //     console.log("********", this.props.task);
    //   }
    //   let clearance = bs[idx].clearance;
    //   let typeOfClearance = bs[idx].typeOfClearance;
    //   let clearanceCase = bs[idx].clearanceCase;
    //   return (
    //     <LoadingButton
    //       variant="contained"
    //       loading={this.state.isDoingAction}
    //       onClick={() => {
    //         this.updateVisitClearance(
    //           "masterService",
    //           "MasterService",
    //           clearance,
    //           typeOfClearance,
    //           clearanceCase
    //         );
    //       }}
    //     >
    //       Set Service Clearance
    //     </LoadingButton>
    //   );
    // }

    if (["ProtocolSummarization"].includes(interpreter)) {
      return null;
    }
    // if (["ProtocolClearance"].includes(interpreter)) {
    //   let visit = this.props.task.visit;
    //   let protocolSummaries = visit?.protocolSummaries;
    //   let ps = protocolSummaries;
    //   let protocol = this.props.task.protocol;
    //   let idx = _.findIndex(ps, { protocolId: protocol._id });
    //   if (idx === -1) {
    //     console.log("********", this.props.task);
    //   }
    //   let clearance = ps[idx].clearance;
    //   let typeOfClearance = ps[idx].typeOfClearance;
    //   let clearanceCase = ps[idx].clearanceCase;
    //   return (
    //     <LoadingButton
    //       variant="contained"
    //       loading={this.state.isDoingAction}
    //       onClick={() => {
    //         this.updateVisitClearance(
    //           "protocol",
    //           "Protocol",
    //           clearance,
    //           typeOfClearance,
    //           clearanceCase
    //         );
    //       }}
    //     >
    //       Set Protocol Clearance
    //     </LoadingButton>
    //   );
    // }

    if (!action) {
      return null;
    }
    if (this.props.fromChaser) {
      return (
        <Box>
          <IconButton onClick={action} disabled={this.state.isDoingAction}>
            <Iconify icon="mdi:check-bold" />
          </IconButton>
        </Box>
      );
    }
    return (
      <LoadingButton
        variant="contained"
        loading={this.state.isDoingAction}
        onClick={action}
      >
        {text}
      </LoadingButton>
    );
  };
  renderCreation = () => {
    let taskCreator = this.props.task.taskCreator;
    if (!taskCreator) {
      return null;
    }
    let isUser = taskCreator === "User";
    let ts = this.props.task.createdAt;
    let niceTs = niceDateTime(ts);
    let userStr = isUser
      ? this.props.task.taskCreatorId?.name || "User"
      : "System";
    return (
      <Typography variant="caption">{`Created by ${userStr} at ${niceTs}`}</Typography>
    );
  };
  renderMetaActions = () => {
    let taskStatus = this.props.task.taskStatus;
    if (taskStatus === "COMPLETED") {
      return null;
    }
    if (taskStatus === "CANCELLED") {
      return null;
    }
    let holdDateElem = null;
    if (this.state.holdDate) {
      holdDateElem = (
        <Button
          disabled={this.state.isDoingAction}
          variant="outlined"
          size="small"
          color="secondary"
          startIcon={<Iconify icon="fluent-mdl2:date-time-12" />}
          onClick={() => {
            this.updateTask([
              { key: "taskStatus", value: "PENDING" },
              { key: "taskDueDate", value: this.state.holdDate },
            ]);
          }}
        >
          Defer Task To
        </Button>
      );
      if (this.props.fromChaser) {
        holdDateElem = (
          <IconButton
            disabled={this.state.isDoingAction}
            size="small"
            color="secondary"
            onClick={() => {
              this.updateTask([
                { key: "taskStatus", value: "PENDING" },
                { key: "taskDueDate", value: this.state.holdDate },
              ]);
            }}
          >
            <Iconify icon="fluent-mdl2:date-time-12" />
          </IconButton>
        );
      }
    }
    let inProgressButton = null;
    let cu = this.props.currentUser;
    let cuId = cu._id;
    let cancelButton = (
      <Button
        disabled={this.state.isDoingAction}
        variant="outlined"
        size="small"
        color="error"
        startIcon={<Iconify icon="solar:trash-bin-minimalistic-broken" />}
        onClick={() => {
          this.updateTask([{ key: "taskStatus", value: "CANCELLED" }]);
        }}
      >
        Remove Task
      </Button>
    );
    if (this.props.fromChaser) {
      cancelButton = (
        <IconButton
          disabled={this.state.isDoingAction}
          size="small"
          color="error"
          onClick={() => {
            this.updateTask([{ key: "taskStatus", value: "CANCELLED" }]);
          }}
        >
          <Iconify icon="solar:trash-bin-minimalistic-broken" />
        </IconButton>
      );
    }
    if (taskStatus === "IN_PROGRESS") {
      inProgressButton = (
        <Button
          disabled={this.state.isDoingAction}
          variant="outlined"
          size="small"
          color="secondary"
          startIcon={<Iconify icon="fluent-mdl2:progress-20-regular" />}
          onClick={() => {
            this.updateTask([
              { key: "taskStatus", value: "PENDING" },
              {
                key: "inProgressUser",
                value: null,
              },
              {
                key: "inProgressUserId",
                value: null,
              },
            ]);
          }}
        >
          Pause Working On Task
        </Button>
      );
      if (this.props.fromChaser) {
        inProgressButton = (
          <IconButton
            disabled={this.state.isDoingAction}
            size="small"
            color="secondary"
            onClick={() => {
              this.updateTask([
                { key: "taskStatus", value: "PENDING" },
                {
                  key: "inProgressUser",
                  value: null,
                },
                {
                  key: "inProgressUserId",
                  value: null,
                },
              ]);
            }}
          >
            <Iconify icon="solar:pause-broken" />
          </IconButton>
        );
      }
    } else {
      inProgressButton = (
        <Button
          disabled={this.state.isDoingAction}
          variant="outlined"
          size="small"
          color="secondary"
          startIcon={<Iconify icon="fluent-mdl2:progress-20-regular" />}
          onClick={() => {
            this.updateTask([
              { key: "taskStatus", value: "IN_PROGRESS" },
              {
                key: "inProgressUser",
                value: "User",
              },
              {
                key: "inProgressUserId",
                value: cuId,
              },
            ]);
          }}
        >
          Start Working On Task
        </Button>
      );
      if (this.props.fromChaser) {
        inProgressButton = (
          <IconButton
            disabled={this.state.isDoingAction}
            size="small"
            color="secondary"
            onClick={() => {
              this.updateTask([
                { key: "taskStatus", value: "IN_PROGRESS" },
                {
                  key: "inProgressUser",
                  value: "User",
                },
                {
                  key: "inProgressUserId",
                  value: cuId,
                },
              ]);
            }}
          >
            <Iconify icon="solar:play-broken" />
          </IconButton>
        );
      }
    }
    return (
      <Stack direction="row" alignItems="center" spacing={0}>
        <Box> {inProgressButton}</Box>
        <Stack direction="row" alignItems="center" spacing={1}>
          {" "}
          {holdDateElem}
          <>
            <Chip
              disabled={this.state.isDoingAction}
              icon={<Iconify icon="fluent-mdl2:date-time-12" />}
              variant="contained"
              onClick={(e) => {
                this.setState({
                  anchorEl: e.currentTarget,
                  openHoldDateSelector: true,
                });
              }}
              label={
                this.state.holdDate
                  ? niceDate(this.state.holdDate)
                  : this.props.fromChaser
                    ? "Defer"
                    : "Defer Task"
              }
            />
            <Popover
              open={this.state.openHoldDateSelector}
              anchorEl={this.state.anchorEl}
              onClose={() => {
                this.setState({
                  anchorEl: null,
                  openHoldDateSelector: false,
                });
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <DayPicker
                disabled={this.state.isDoingAction}
                mode="single"
                captionLayout="dropdown-buttons"
                fromDate={new Date()}
                toYear={2025}
                onSelect={(e) => {
                  this.setState({
                    holdDate: e,
                    anchorEl: null,
                    openHoldDateSelector: false,
                  });
                }}
              />
            </Popover>
          </>
        </Stack>
        <Box> {cancelButton}</Box>
      </Stack>
    );
  };
  renderChangeLog = () => {
    let changelog = this.props.task.changelog;
    if (!changelog) {
      return null;
    }
    if (!changelog.length) {
      return null;
    }
    if (
      ["COMPLETED", "CANCELLED"].includes(this.props.task.taskStatus) &&
      this.props.fromChaser
    ) {
      return null;
    }
    let cl = _.cloneDeep(changelog);
    return (
      <Stack spacing={1}>
        <hr />
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Typography variant="subtitle2">
            {this.props.fromChaser ? "Logs" : "Update Logs"}
          </Typography>{" "}
          <Tooltip
            title={
              this.state.showUpdateLog
                ? "Click to hide log"
                : "Click to show log"
            }
          >
            <IconButton
              size="small"
              onClick={() => {
                this.setState({
                  showUpdateLog: !this.state.showUpdateLog,
                });
              }}
            >
              <Iconify
                icon={
                  this.state.showUpdateLog
                    ? "solar:square-double-alt-arrow-up-broken"
                    : "solar:square-double-alt-arrow-down-broken"
                }
              />
            </IconButton>
          </Tooltip>
        </Stack>
        {changelog.map((each, clIndex) => {
          if (!each) return null;
          if (!each.uops) return null;
          if (!this.state.showUpdateLog) return null;
          let name = each?.updatedBy?.name || "System";
          let ts = each.updatedAt
            ? niceDateTime(each.updatedAt)
            : "Internal Server Time";
          let uu = _.cloneDeep(each.uops);
          let uopsArr = each.uops
            .map((x) => {
              if (x.key === "taskStatus") {
                return `Status updated to ${_.startCase(_.lowerCase(x.value))}`;
              }
              if (x.key === "taskDueDate") {
                return `Due Date updated to ${niceDate(x.value)}`;
              }
              if (x.key === "inProgressUser") {
                let isNull = x.value === null;
                if (isNull) {
                  let allPrevChangeLogs = cl.slice(0, clIndex);
                  let lastInProgressUser = null;
                  let whichIndex = null;
                  for (let i = allPrevChangeLogs.length - 1; i >= 0; i--) {
                    let eachChangeLog = allPrevChangeLogs[i];
                    let eachUops = eachChangeLog.uops;
                    let eachUopsInProgressUser = _.find(eachUops, {
                      key: "inProgressUser",
                    });
                    if (eachUopsInProgressUser) {
                      lastInProgressUser = eachUopsInProgressUser.value;
                      whichIndex = i;
                      break;
                    }
                  }
                  if (!lastInProgressUser) {
                    return null;
                  }
                  let isUser = lastInProgressUser === "User";
                  if (!isUser) {
                    return `System stopped working on this`;
                  }
                  let uq = allPrevChangeLogs[whichIndex];
                  let _idUserIdx = _.findIndex(uq.uops, {
                    key: "inProgressUserId",
                  });
                  let _objUser = uq.uops[_idUserIdx].value_f;
                  let _objUserName = _objUser?.name || "User";
                  return `${_objUserName} stopped working on this`;
                }
                let isUser = x.value === "User";
                if (!isUser) {
                  return `System started processing task`;
                }
                let _idUserIdx = _.findIndex(uu, { key: "inProgressUserId" });
                let _objUser = uu[_idUserIdx].value_f;
                let _objUserName = _objUser?.name || "User";
                return `${_objUserName} started working on this.`;
              }
              if (x.key === "taskAssignee") {
                let isNull = x.value === null;
                if (isNull) {
                  let allPrevChangeLogs = cl.slice(0, clIndex);
                  let lastAssignedUser = null;
                  let whichIndex = null;
                  for (let i = allPrevChangeLogs.length - 1; i >= 0; i--) {
                    let eachChangeLog = allPrevChangeLogs[i];
                    let eachUops = eachChangeLog.uops;
                    let eachUopsInProgressUser = _.find(eachUops, {
                      key: "taskAssignee",
                    });
                    if (eachUopsInProgressUser) {
                      lastAssignedUser = eachUopsInProgressUser.value;
                      whichIndex = i;
                      break;
                    }
                  }
                  if (!lastAssignedUser) {
                    return null;
                  }
                  let isUser = lastAssignedUser === "User";
                  if (!isUser) {
                    return `Assignment removed from System`;
                  }

                  let uq = allPrevChangeLogs[whichIndex];
                  let _idUserIdx = _.findIndex(uq.uops, {
                    key: "taskAssigneeId",
                  });
                  let _objUser = uq.uops[_idUserIdx].value_f;
                  let _objUserName = _objUser?.name || "User";
                  return `Removed assignment from ${_objUserName}`;
                }
                let isUser = x.value === "User";
                if (!isUser) {
                  return `Assigned to System`;
                }

                let _idUserIdx = _.findIndex(uu, { key: "taskAssigneeId" });

                let _objUser = uu[_idUserIdx].value_f;
                let _objUserName = _objUser?.name || "User";
                return `Assigned to ${_objUserName}`;
              }
              if (x.key === "taskAssigneeId") {
                return null;
              }
              if (x.key === "inProgressUserId") {
                return null;
              }
            })
            .filter(Boolean);
          let uopsString = uopsArr.join("; ");
          return (
            <Stack spacing={1}>
              <Stack direction="row" alignItems="center" spacing={0}>
                <Typography variant="caption">{name}</Typography>{" "}
                <Iconify icon="ph:dot-outline-fill" />
                <Typography variant="caption">{niceDateTime(ts)}</Typography>
              </Stack>{" "}
              <Stack
                direction="row"
                alignItems="center"
                spacing={0}
                sx={{ ml: 1 }}
              >
                <Iconify icon="octicon:dash-16" />
                <Typography variant="caption">{uopsString}</Typography>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    );
  };
  renderTaskDocuments = () => {
    if (this.props.task.taskInterpreter !== "ProtocolClearance") return;
    const documents = flatten(
      this.props.task?.visit?.bookedServices.map((s) => s.linkedGroups)
    );
    return (
      <Stack spacing={1}>
        <Typography variant="overline">Documents</Typography>
        <Stack direction="row" spacing={1}>
          {documents.map((d, index) => (
            <Button
              href={`/documents/${d.cfId}`}
              endIcon={<Iconify icon="akar-icons:link-out" />}
              size="small"
              variant="outlined"
              // color="secondary"
            >
              {d.pageDataType}
            </Button>
          ))}
        </Stack>
      </Stack>
    );
  };
  render() {
    let status = this.props.task.taskStatus;
    let color = _.find(this.STATUS_LIST, { key: status })?.color;
    if (this.props.fromChaser) {
      return (
        <Card
          sx={{
            bgcolor: `${color}.light`,
            boxShadow: 3,
            padding: 1,
            margin: 1,
          }}
        >
          {this.renderTaskFinishedAt()}
          <Stack
            flexWrap={"wrap"}
            direction="row"
            alignItems="center"
            spacing={0.5}
          >
            {this.renderAction()}
            {this.renderMetaActions()}
          </Stack>{" "}
          {this.renderChangeLog()}
          <Grid xs={12} md={2} style={{ marginTop: "1rem" }}>
            {this.props.task.isFormFoxTask && (
              <Stack spacing={2}>
                <Chip
                  icon={<Iconify icon="material-symbols:star" />}
                  label="Automated (via FormFox)"
                  variant="outlined"
                />
              </Stack>
            )}
          </Grid>
        </Card>
      );
    }
    return (
      <Card
        sx={{
          bgcolor: `${color}.light`,
          boxShadow: 3,
          padding: 2,
          margin: 2,
        }}
      >
        <Grid container spacing={3}>
          <Grid xs={12} md={8}>
            {" "}
            <Stack spacing={2}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                flexWrap="wrap"
              >
                {this.renderInterpreter()} {this.renderStatus()}{" "}
                {this.renderAssignee()}
              </Stack>
              {this.renderInfo()}
              {this.renderMetadata()}
              {this.renderServiceDataFromVisit()}
              {this.renderTaskDocuments()}
            </Stack>
          </Grid>{" "}
          <Grid xs={12} md={4}>
            <Stack spacing={1}>
              {" "}
              {this.renderCreation()}
              {this.renderDueDate()}
              {this.renderTaskFinishedAt()}
              {this.renderAction()}
              {this.renderMetaActions()}
              {this.renderChangeLog()}
              {this.props.task.isFormFoxTask && (
                <Stack spacing={2}>
                  <Chip
                    icon={<Iconify icon="material-symbols:star" />}
                    label="Automated (via FormFox)"
                    variant="outlined"
                  />
                </Stack>
              )}
            </Stack>
          </Grid>
        </Grid>
        <Grid xs={12} md={2} style={{ marginTop: "1rem" }}></Grid>
      </Card>
    );
  }
}
export default WithAPICall(EachTask);
