// 'use client';
import { LineChart } from "@tremor/react";
import { useContext, useEffect, useState } from "react";
import { WithAPICall } from "../utils/apiUtil";
import HOCContext from "./contexts/HOCContext";
import { cloneDeep, groupBy } from "lodash";

const valueFormatter = (number) =>
  `${Intl.NumberFormat("us").format(number).toString()}`;

export default WithAPICall(function Visits({ apiCallPost }) {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const { from, to, companyIds } = useContext(HOCContext);

  useEffect(() => {
    const init = async () => {
      const massagedData = [];
      let categories = [];
      try {
        const res = await apiCallPost("/dashboard/visitActivityByDate", {
          from: from.toDate(),
          to: to.toDate(),
          companyIds,
        });
        for (const date of Array.from(Object.keys(res))) {
          let total = 0;
          const dayDate = {
            date,
          };
          for (const company of Array.from(Object.keys(res[date]))) {
            if (!categories.includes(company)) categories.push(company);
            dayDate[company] = res[date][company].count;
            total += res[date][company].count;
          }
          dayDate.Total = total;
          massagedData.push(dayDate);
        }
        for (const category of categories) {
          for (const dayDate of massagedData) {
            if (!(category in dayDate)) dayDate[category] = 0;
          }
        }
      } catch (error) {
        console.error("could not get data", error);
      }
      setData(massagedData);
      setCategories(categories);
    };
    init();
  }, [from, to, companyIds]);

  return (
    <>
      <ul role="list" className="mt-6 flex gap-10">
        <li>
          <div className="flex items-center space-x-1.5">
            <span
              className="h-2.5 w-2.5 rounded-sm bg-tremor-brand dark:bg-dark-tremor-brand"
              aria-hidden={true}
            />
            <p className="text-tremor-label text-tremor-content dark:text-dark-tremor-content">
              Visits MTD (Closed)
            </p>
          </div>
          <div className="flex items-center space-x-1.5">
            <p className="mt-0.5 text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              -
            </p>
            {/* <span className="rounded-tremor-small bg-tremor-background-subtle px-1.5 py-0.5 text-tremor-label font-medium text-tremor-content dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content">
              +16%
            </span> */}
          </div>
        </li>
        <li>
          <div className="flex items-center space-x-1.5">
            <span
              className="h-2.5 w-2.5 rounded-sm bg-cyan-500"
              aria-hidden={true}
            />
            <p className="text-tremor-label text-tremor-content dark:text-dark-tremor-content">
              Visits YTD (Closed)
            </p>
          </div>
          <p className="mt-0.5 text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
            -
          </p>
        </li>
      </ul>
      <LineChart
        data={data}
        index="date"
        categories={categories}
        colors={[
          "red",
          "orange",
          "yellow",
          "cyan",
          "violet",
          "rose",
          "purple",
          "teal",
          "emerald",
          "neutral",
        ]}
        valueFormatter={valueFormatter}
        showLegend={false}
        showYAxis={false}
        startEndOnly={true}
        className="mt-6 h-32"
        // customTooltip={customTooltip}
      />
    </>
  );
});
