import { useState, useEffect } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Loader2, Download, Mail, Link } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import { User, Briefcase, Calendar, MapPin, Shield } from "lucide-react";
import useVisitStore from "@/store/useVisitStore";
import { niceDateUS } from "@/micro/utils/fn";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useProtectedApi } from "@/hooks/use-apiCall";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/hooks/use-toast";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { format } from "date-fns";

function ClearanceCard({ employee, visit }) {
  const { callApi } = useProtectedApi();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [emailTo, setEmailTo] = useState(employee.email);
  const [emailSubject, setEmailSubject] = useState("[WorkCare] Patient Letter");
  const [emailBody, setEmailBody] = useState(
    "Please find attached your patient letter."
  );

  const sendPatientLetter = async () => {
    setIsSendingEmail(true);
    try {
      const res = await callApi("/visit/sendExplicitPatientLetterToEmployee", {
        visitId: visit._id,
        subject: emailSubject,
        body: emailBody,
        email: emailTo,
      });
      console.log(res);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSendingEmail(false);
      setIsDialogOpen(false);
    }
  };

  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [isFilesDialogOpen, setIsFilesDialogOpen] = useState(false);

  const [linkedFiles, setLinkedFiles] = useState([]);

  useEffect(() => {
    const fetchLinkedFiles = async () => {
      try {
        const res = await callApi("/datafiles/getDataFilesByVisitId", {
          visitId: visit._id,
        });
        console.log({ fetchLinkedFiles: res });
        setLinkedFiles(res);
      } catch (error) {
        console.error("Error fetching linked files:", error);
      }
    };
    fetchLinkedFiles();
  }, [visit._id]);

  return (
    <>
      <TooltipProvider>
        <Card className="w-full max-w-md bg-gradient-to-br from-slate-50 to-slate-100 shadow-md">
          <CardContent className="p-4">
            <div className="flex justify-between items-start mb-4">
              <div>
                <h2 className="text-lg font-semibold text-slate-800">
                  {employee.name}
                </h2>
                <p className="text-sm text-slate-500">{employee.employer}</p>
              </div>
              <Tooltip>
                <TooltipTrigger>
                  <Badge
                    variant="outline"
                    className="text-xs bg-blue-50 text-blue-700 border-blue-200"
                  >
                    {employee.jobCode}
                  </Badge>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Job Code</p>
                </TooltipContent>
              </Tooltip>
            </div>

            <div className="grid grid-cols-2 gap-3 text-sm mb-4">
              <Tooltip>
                <TooltipTrigger className="flex items-center text-slate-600">
                  <User className="w-4 h-4 mr-2 text-slate-400" />
                  <span className="truncate">{employee.dob}</span>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Date of Birth</p>
                </TooltipContent>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger className="flex items-center text-slate-600">
                  <Briefcase className="w-4 h-4 mr-2 text-slate-400" />
                  <span className="truncate">{employee.employeeNumber}</span>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Employee Number</p>
                </TooltipContent>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger className="flex items-center text-slate-600">
                  <Shield className="w-4 h-4 mr-2 text-slate-400" />
                  <span className="truncate">{employee.ssn}</span>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Social Security Number</p>
                </TooltipContent>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger className="flex items-center text-slate-600">
                  <Briefcase className="w-4 h-4 mr-2 text-slate-400" />
                  <span className="truncate">{employee.jobTitle}</span>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{employee.jobDescription}</p>
                </TooltipContent>
              </Tooltip>
            </div>

            <Separator className="my-3" />

            <div className="grid grid-cols-2 gap-3 text-sm">
              <Tooltip>
                <TooltipTrigger className="flex items-center text-slate-600">
                  <Calendar className="w-4 h-4 mr-2 text-slate-400" />
                  <span className="truncate">{visit.date}</span>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Visit Date</p>
                </TooltipContent>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger className="flex items-center text-slate-600">
                  <MapPin className="w-4 h-4 mr-2 text-slate-400" />
                  <span className="truncate">{visit.clinic}</span>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Clinic</p>
                </TooltipContent>
              </Tooltip>
            </div>

            <div className="mt-3">
              <p className="text-xs font-semibold text-slate-500 mb-2">
                Protocols:
              </p>
              <div className="flex flex-wrap gap-2">
                {visit.protocols.map((protocol, index) => (
                  <Tooltip key={index}>
                    <TooltipTrigger>
                      <Badge
                        variant="secondary"
                        className="text-xs bg-green-50 text-green-700 border-green-200"
                      >
                        {protocol}
                      </Badge>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>{protocol} Protocol</p>
                    </TooltipContent>
                  </Tooltip>
                ))}
              </div>
              <div className="mt-3">
                <p className="text-xs font-semibold text-slate-500 mb-2">
                  Linked Files:
                </p>
                <div className="flex flex-wrap gap-2">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="outline"
                          size="sm"
                          className="h-6 px-2 text-xs"
                          onClick={() => setIsFilesDialogOpen(true)}
                        >
                          <User className="h-3 w-3 mr-1" />
                          View Linked Files
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>View Linked Files</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <p className="text-xs font-semibold text-slate-500 mb-2">
                Patient Letter:
              </p>
              <div className="flex space-x-1">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="outline"
                        size="sm"
                        className="h-6 px-2 text-xs"
                        onClick={() => {
                          window.open(
                            `/prints/patient-letter/${visit._id}`,
                            "_blank"
                          );
                        }}
                      >
                        <User className="h-3 w-3 mr-1" />
                        Preview
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Preview Patient Letter</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="outline"
                        size="sm"
                        className="h-6 px-2 text-xs"
                        disabled={isSendingEmail}
                        onClick={() => {
                          setIsDialogOpen(true);
                        }}
                      >
                        <Mail className="h-3 w-3 mr-1" />
                        Email
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Send Patient Letter via Email</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                {/* <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      size="sm"
                      className="h-6 px-2 text-xs"
                      disabled={isDownloadingPdf}
                      onClick={() => {
                        if (!isDownloadingPdf) {
                          fetchPatientLetterPdf();
                        }
                      }}
                    >
                      <Download className="h-3 w-3 mr-1" />
                      Download
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Download Patient Letter as PDF</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider> */}
                {/* <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      size="sm"
                      className="h-6 w-10 p-0"
                      disabled={isDownloadingPdf}
                      onClick={() => {
                        if (!isDownloadingPdf) {
                          // fetchPdf();
                        }
                      }}
                    >
                      {isDownloadingPdf ? (
                        <Loader2 className="h-3 w-3 animate-spin" />
                      ) : (
                        <Download className="h-3 w-3" />
                      )}
                      {isDownloadingPdf ? "Downloading..." : "Download file"}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>
                      {isDownloadingPdf ? "Downloading..." : "Download file"}
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider> */}
              </div>
            </div>
          </CardContent>
        </Card>
      </TooltipProvider>
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="sm:max-w-[60vw] sm:max-h-[80vh]">
          <DialogHeader>
            <DialogTitle>Send Patient via Email</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="to" className="text-right">
                To
              </Label>
              <Input
                id="to"
                value={emailTo}
                onChange={(e) => setEmailTo(e.target.value)}
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="subject" className="text-right">
                Subject
              </Label>
              <Input
                id="subject"
                value={emailSubject}
                onChange={(e) => setEmailSubject(e.target.value)}
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="body" className="text-right">
                Body
              </Label>
              <Textarea
                id="body"
                rows={10}
                value={emailBody}
                onChange={(e) => setEmailBody(e.target.value)}
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="body" className="text-right">
                Attachment:
              </Label>
              <div className="col-span-3">
                The Patient Letter will be sent as an attachment to this email.
              </div>
            </div>
          </div>
          <DialogFooter>
            <Button
              type="submit"
              onClick={sendPatientLetter}
              disabled={isSendingEmail}
            >
              {isSendingEmail && <Loader2 className="h-3 w-3 animate-spin" />}
              Send Email
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* New Dialog for Linked Files */}
      <Dialog open={isFilesDialogOpen} onOpenChange={setIsFilesDialogOpen}>
        <DialogContent className="sm:max-w-[525px]">
          <DialogHeader>
            <DialogTitle>Linked Files</DialogTitle>
          </DialogHeader>
          <ScrollArea className="h-[300px] w-full rounded-md border p-4">
            <div className="space-y-4">
              {linkedFiles.map((file, index) => (
                <div key={index} className="flex justify-between items-center">
                  <div className="flex items-center space-x-2">
                    <Link className="h-4 w-4 text-slate-400" />
                    <span className="text-sm font-medium">
                      <a href={file.psUrl} target="_blank" rel="noreferrer">
                        {file.originalName}
                      </a>
                    </span>
                  </div>
                  <div className="text-xs text-slate-500">
                    Uploaded: {niceDateUS(new Date(file.createdAt))}
                  </div>
                </div>
              ))}
            </div>
          </ScrollArea>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default function Component() {
  const { visit, status } = useVisitStore();

  if (status !== "loaded") return <div>Loading...</div>;

  const employeeData = {
    name: visit.employee.employeeName,
    dob: niceDateUS(new Date(visit.employee.employeeDoB)),
    employer: visit.company.companyName,
    employeeNumber: visit.employee.employeeNumber,
    ssn: visit.employee.employeeSSN,
    email:
      visit.employee?.employeeOfficeEmail ||
      visit.employee?.employeePersonalEmail,
    jobCode: "_",
    jobTitle: "_",
    jobDescription: "_",
  };

  const visitData = {
    date: niceDateUS(new Date(visit.providerEncounterOnDate)),
    _id: visit._id,
    clinic: visit.provider.providerName,
    protocols: visit.protocolsInfo.map((pi) => pi.companyProtocolName),
  };

  return <ClearanceCard employee={employeeData} visit={visitData} />;
}
