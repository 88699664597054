import { Button, LinearProgress, TextField } from "@mui/material";
import React from "react";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import Iconify from "src/components/iconify";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { WithAPICall } from "../utils/apiUtil";
import { Box, Stack } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import ImageLoaderForGrid from "../utils/ImageLoaderForGrid";
import { NoResultView, View500 } from "src/sections/error";
import ProviderNetworkCreate from "./ProviderNetworkCreate";

class ListProviderNetworks extends React.Component {
  state = {
    query: "",
    isSearching: true,
    quickSearchText: "",
    enableAdvancedFilter: false,
    list: [],
    openNetworkCreate: false,
  };
  componentDidMount() {
    this.search();
  }
  search = async () => {
    this.setState({ isSearching: true });
    try {
      const res = await this.props.apiCallPost(
        "/provider/providerNetworkSearch",
        {
          query: this.state.query,
        }
      );
      this.setState({ list: res, isSearching: false });
    } catch (err) {
      this.setState({
        isSearching: false,
        isError: true,
      });
      console.log(err);
    }
    this.setState({ isSearching: false });
  };
  COLUMNS = [
    {
      headerName: "Network",
      field: "networkInformalName",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
      cellRenderer: (row) => this.renderName(row),
    },
    {
      headerName: "Legal Entity",
      field: "networkName",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Type",
      field: "networkProviderType",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Phone",
      field: "networkPhone",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.renderPhone(row),
    },
    {
      headerName: "Email",
      field: "networkEmail",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.renderSendEmail(row),
    },
    {
      headerName: "Website",
      field: "networkWebsite",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.renderWebsite(row),
    },
    {
      headerName: "Country",
      field: "networkCountry",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.renderCountry(row),
    },
    {
      headerName: "State",
      field: "networkState",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
    },
    {
      headerName: "City",
      field: "networkCity",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
    },
  ];
  renderCountry = (row) => {
    return (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Iconify
          icon={`circle-flags:${row.data.networkCountryShortCode?.toLowerCase()}`}
          sx={{ mr: 1 }}
        />
        {row.value}
      </span>
    );
  };
  renderName = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    return (
      <span
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ImageLoaderForGrid
          apiPrefix="provider"
          value={row.data.networkLogo}
          onLoad={() => {
            this.sizeToFit();
          }}
        />
        <Button
          fullWidth
          href={`/networks/${row.data._id}`}
          variant="outlined"
          startIcon={<Iconify icon="mingcute:external-link-line" />}
        >
          {row.value}
        </Button>
      </span>
    );
  };
  renderGoToPage = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    return (
      <span
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          fullWidth
          href={`/networks/${row.data._id}`}
          variant="outlined"
          startIcon={<Iconify icon="mingcute:external-link-line" />}
        ></Button>
      </span>
    );
  };
  renderWebsite = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    if (!row.value) return null;
    return (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Button
          fullWidth
          target="non_blank noopener noreferrer"
          href={row.value}
          variant="outlined"
          startIcon={<Iconify icon="mingcute:external-link-line" />}
        >
          {row.value}
        </Button>
      </span>
    );
  };
  renderSendEmail = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    if (!row.value) return null;
    return (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Button
          fullWidth
          target="non_blank noopener noreferrer"
          href={`mailto:${row.value}`}
          variant="outlined"
          startIcon={<Iconify icon="mingcute:mail-send-line" />}
        >
          {row.value}
        </Button>
      </span>
    );
  };
  renderPhone = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    if (!row.value) return null;
    return (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Button
          fullWidth
          target="non_blank noopener noreferrer"
          href={`tel:${row.value}`}
          variant="outlined"
          startIcon={<Iconify icon="mingcute:phone-outgoing-line" />}
        >
          {row.value}
        </Button>
      </span>
    );
  };
  sizeToFit = () => {
    const allColumnIds = [];
    this.state.gridApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    this.state.gridApi.autoSizeColumns(allColumnIds, false);
  };
  onGridReady = (params) => {
    this.setState({ gridApi: params.api, columnApi: params.columnApi });
  };
  renderGrid = () => {
    let list = this.state.list;
    if (!list || list.length === 0) {
      return <NoResultView />;
    }
    let cs = "ag-theme-quartz";
    let theme = localStorage.getItem("themeMode");
    if (theme && theme === "dark") {
      cs = "ag-theme-quartz-dark";
    }
    return (
      <div className={cs} style={{ height: "70vh" }}>
        <AgGridReact
          onGridReady={this.onGridReady}
          rowData={this.state.list}
          columnDefs={this.COLUMNS}
          defaultColDef={{
            sortable: true,
          }}
          autoSizeStrategy={{
            type: "fitCellContents",
          }}
          pagination={true}
          rowGroupPanelShow={
            this.state.enableAdvancedFilter ? "always" : "never"
          }
          suppressDragLeaveHidesColumns={true}
          rowSelection="multiple"
          rowDragManaged={true}
          enableCharts={true}
          enableRangeSelection={true}
          enableCellTextSelection={true}
          quickFilterText={this.state.quickSearchText}
          sideBar={true}
          animateRows={true}
          enableAdvancedFilter={this.state.enableAdvancedFilter}
        />
      </div>
    );
  };
  renderHeader = () => {
    return (
      <CustomBreadcrumbs
        heading="List of Provider Networks"
        links={[
          { name: "Dashboard", href: "/" },
          { name: "Provider Networks" },
          { name: "List" },
        ]}
        action={
          <Box>
            <Button
              disabled={this.state.isSearching}
              onClick={() => this.setState({ openNetworkCreate: true })}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              New Network
            </Button>
          </Box>
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
    );
  };
  renderControlBox = () => {
    let list = this.state.list;
    if (!list) return null;
    if (list.length === 0) return null;
    return (
      <Stack direction="row" spacing={1}>
        <TextField
          disabled={this.state.isSearching}
          value={this.state.quickSearchText}
          label="Quick Search"
          onChange={(e) =>
            this.setState({
              quickSearchText: e.target.value,
            })
          }
        />
        <Button
          disabled={this.state.isSearching}
          variant="contained"
          startIcon={<Iconify icon="mingcute:filter-line" />}
          onClick={() => {
            this.setState({
              enableAdvancedFilter: !this.state.enableAdvancedFilter,
            });
          }}
        >
          {this.state.enableAdvancedFilter
            ? "Advanced Filters"
            : "Simple Filters"}
        </Button>{" "}
        <LoadingButton
          loading={this.state.isSearching}
          onClick={() => this.search()}
          variant="contained"
          startIcon={<Iconify icon="mingcute:refresh-anticlockwise-1-line" />}
        >
          Refresh Data
        </LoadingButton>{" "}
      </Stack>
    );
  };
  render() {
    if (this.state.isSearching) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <div>
        <ProviderNetworkCreate
          isOpen={this.state.openNetworkCreate}
          handleClose={() => {
            this.setState({ openNetworkCreate: false });
          }}
          onCreate={(network) => {
            this.setState({
              list: [network, ...this.state.list],
              openNetworkCreate: false,
            });
          }}
        />
        <Stack spacing={3}>
          {this.renderHeader()}
          {this.renderControlBox()}
          {this.renderGrid()}
        </Stack>
      </div>
    );
  }
}
export default WithAPICall(ListProviderNetworks);
