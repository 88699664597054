import React from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { Alert, LinearProgress } from "@mui/material";
const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "50vh",
};
const center = {
  lat: 38.89768,
  lng: -77.0343434,
};

const GMapRenderer = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  if (loadError) {
    return (
      <div>
        <Alert severity="warning">Map failed to load.</Alert>
      </div>
    );
  }

  if (!isLoaded) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={10}
        center={props.geoObject?.lat ? props.geoObject : center}
      >
        <Marker
          draggable
          onDragEnd={(e) => {
            props.onLatLng({
              lat: e.latLng.lat(),
              lng: e.latLng.lng(),
            });
          }}
          clickable={false}
          position={props.geoObject?.lat ? props.geoObject : center}
        />
      </GoogleMap>
    </div>
  );
};

export default GMapRenderer;
