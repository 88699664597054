import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import App from "./app";
import { LicenseInfo } from "@mui/x-license-pro";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import * as Sentry from "@sentry/react";
import { StytchProvider } from "@stytch/react";
import { StytchUIClient } from "@stytch/vanilla-js";
import "react-image-gallery/styles/css/image-gallery.css";

const stytch = new StytchUIClient(
  "public-token-live-a3d62995-b815-4c9f-a173-37b55b05b087"
);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
    }),
  ],
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 1.0,
});

const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key for Clerk");
}
const MUI_KEY = process.env.REACT_APP_MUI_KEY;
if (!MUI_KEY) {
  throw new Error("Missing Publishable Key for UI");
}
LicenseInfo.setLicenseKey(MUI_KEY);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <StytchProvider stytch={stytch}>
          <App />
        </StytchProvider>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
