/** GRID UTILS */
import _ from "lodash";
import { isInPastDateStrict, isInFutureDateStrict } from "../micro/utils/fn";

export const FILTER_UTILS = {
  text: {
    filter: "agMultiColumnFilter",
    enableRowGroup: true,
    aggFunc: "count",
    filterParams: {
      filterOptions: ["equals", "notEqual", "startsWith", "endsWith"],
    },
  },
  date: {
    valueFormatter: (params) => {
      return params.value
        ? new Date(params.value).toLocaleDateString("en-US")
        : "";
    },
    filter: "agDateColumnFilter",
    aggFunc: "count",
    enableRowGroup: true,
    filterParams: {
      filterOptions: [
        "greaterThan",
        "greaterThanOrEqual",
        "lessThan",
        "lessThanOrEqual",
        "equals",
      ],
      comparator: function (filterDates, cellValue) {
        if (!cellValue) return -1;
        let date = new Date(cellValue);
        let isPast = isInPastDateStrict(date, filterDates);
        let isFuture = isInFutureDateStrict(date, filterDates);
        if (isPast) {
          return -1;
        } else if (isFuture) {
          return 1;
        } else {
          return 0;
        }
      },
    },
  },
  time: {
    valueFormatter: (params) => {
      return params.value ? new Date(params.value).toLocaleString("en-US") : "";
    },
    filter: "agDateColumnFilter",
    aggFunc: "count",
    enableRowGroup: true,
    filterParams: {
      filterOptions: [
        "greaterThan",
        "greaterThanOrEqual",
        "lessThan",
        "lessThanOrEqual",
        "equals",
      ],
      comparator: function (filterDates, cellValue) {
        if (!cellValue) return -1;
        let date = new Date(cellValue);
        let isPast = isInPastDateStrict(date, filterDates);
        let isFuture = isInFutureDateStrict(date, filterDates);
        if (isPast) {
          return -1;
        } else if (isFuture) {
          return 1;
        } else {
          return 0;
        }
      },
    },
  },
  number: {
    filter: "agNumberColumnFilter",
    aggFunc: "sum",
    enableRowGroup: true,
    filterParams: {
      filterOptions: ["equals", "notEqual", "lessThan", "greaterThan"],
    },
  },
  boolean: {
    valueFormatter: (params) => {
      return params?.value ? "true" : "false";
    },
    filter: "agSetColumnFilter",
    aggFunc: "count",
    enableRowGroup: true,
    filterParams: {
      values: ["true", "false"],
    },
  },
  BOOLEAN: {
    valueFormatter: (params) => {
      return params?.value ? "Yes" : "No";
    },
    filter: "agSetColumnFilter",
    aggFunc: "count",
    enableRowGroup: true,
    filterParams: {
      values: ["Yes", "No"],
    },
  },
};
export const sumAggregation = (params) => {
  let arr = _.map(params.values, (each) => Number(each));
  arr = arr.filter((value) => !Number.isNaN(value));

  return _.sum(arr);
};
export const averageAggregation = (params) => {
  let arr = _.map(params.values, (each) => Number(each));
  arr = arr.filter((value) => !Number.isNaN(value));
  return _.mean(arr);
};
export const maxAggregation = (params) => {
  let arr = _.map(params.values, (each) => Number(each));
  arr = arr.filter((value) => !Number.isNaN(value));
  return _.max(arr);
};
export const minAggregation = (params) => {
  let arr = _.map(params.values, (each) => Number(each));
  arr = arr.filter((value) => !Number.isNaN(value));
  return _.min(arr);
};
export const countAggregation = (params) => {
  return params.values.length;
};
