import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import { Box, Stack } from "@mui/system";
import {
  Button,
  Chip,
  FormControlLabel,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Iconify from "src/components/iconify";
import cogoToast from "cogo-toast";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";

const TextTemplates = ({ data, onUpdate }) => {
  const [rows, setRows] = useState(data || []);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const addRow = () => {
    // Check if there's any row empty
    const hasEmptyFeeType = rows.some(
      (row) => row.template === "" || row.text === ""
    );
    if (hasEmptyFeeType) {
      setOpenSnackbar(true);
      return;
    }
    setRows([...rows, { template: "", text: "" }]);
  };

  const saveRows = async () => {
    try {
      await onUpdate(rows);
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Updating Text Templates");
    }
  };

  const removeRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const handleTemplateChange = (index, value) => {
    const newRows = [...rows];
    newRows[index].template = value;
    setRows(newRows);
  };

  const handleValueChange = (index, value) => {
    const newRows = [...rows];
    newRows[index].text = value;
    setRows(newRows);
  };

  return (
    <div className="p-1">
      <Typography variant="body2">Add & Remove Text Templates</Typography>
      <TableContainer component={Paper} className="mb-4">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Template</TableCell>
              <TableCell>Text</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <TextField
                    value={row.template}
                    onChange={(e) =>
                      handleTemplateChange(index, e.target.value)
                    }
                    fullWidth
                    onBlur={saveRows}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={row.text}
                    onChange={(e) => handleValueChange(index, e.target.value)}
                    fullWidth
                    onBlur={saveRows}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => removeRow(index)}
                  >
                    Remove
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container spacing={2} className="mt-4">
        <Grid item>
          <Button variant="contained" size="small" onClick={addRow}>
            Add a New Template
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

class ProtocolClearances extends React.Component {
  state = {
    clearanceOptions: [],
  };
  componentDidMount() {
    this.setState({
      ...this.props.data,
    });
  }
  CLEARANCE_TYPE_OPTIONS = [
    "Cleared",
    "Cleared Provisionally",
    "Cleared With Restriction",
    "Not Cleared",
    "Null Clearance",
  ];
  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(this.state.clearanceOptions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    this.setState(
      {
        clearanceOptions: items,
      },
      () => {
        this.updateScalarValue("clearanceOptions", this.state.clearanceOptions);
      }
    );
  };
  updateScalarValue = async (key, value) => {
    try {
      this.setState({
        isSaving: true,
      });
      let res = await this.props.apiCallPost(
        "/company-protocol/companyProtocolUpdateScalarValue",
        {
          companyProtocolId: this.props.protocolId,
          key: key,
          value: value,
        }
      );
      this.setState({
        ...res,
        isSaving: false,
      });
    } catch (err) {
      this.setState({
        isSaving: false,
        clearanceOptions: this.props.data.clearanceOptions,
      });
      cogoToast.error("Error Updating Protocol Clearance Data");
      console.log(err);
    }
  };
  renderClearanceHeader = () => {
    return (
      <Stack spacing={3}>
        <ListItemText
          sx={{
            p: 3,
            borderRadius: 2,
            bgcolor: "background.neutral",
          }}
          primary={
            <Stack direction="row" alignItems="center" spacing={3}>
              <Typography variant="h6">Clearances</Typography>
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  let newObj = {
                    criteria: "",
                    text: "",
                    case: "",
                    typeOfClearance: "",
                  };
                  this.setState(
                    {
                      clearanceOptions: [
                        ...this.state.clearanceOptions,
                        newObj,
                      ],
                    },
                    () => {
                      this.updateScalarValue(
                        "clearanceOptions",
                        this.state.clearanceOptions
                      );
                    }
                  );
                }}
                sx={{
                  width: 24,
                  height: 24,
                  bgcolor: "primary.main",
                  color: "primary.contrastText",
                  "&:hover": {
                    bgcolor: "primary.dark",
                  },
                }}
              >
                <Iconify icon="mingcute:add-line" />
              </IconButton>
            </Stack>
          }
          secondary="Set clearange language defaults and criteria here."
          primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
          secondaryTypographyProps={{ component: "span" }}
        />
      </Stack>
    );
  };
  renderClearances = () => {
    if (!this.state.clearanceOptions.length) return null;
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="clearances">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {this.state.clearanceOptions.map((clearance, i) => (
                <Draggable
                  key={clearance._id}
                  draggableId={clearance._id}
                  index={i}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {this.renderEachClearance(clearance, i)}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  renderTextTemplates = (index) => {
    return (
      <TextTemplates
        data={this.state.clearanceOptions[index].textTemplates || []}
        onUpdate={(rows) => {
          const co = this.state.clearanceOptions;
          co[index].textTemplates = rows;
          this.setState({ clearanceOptions: co }, () => {
            this.updateScalarValue(
              "clearanceOptions",
              this.state.clearanceOptions
            );
          });
        }}
      />
    );
  };

  renderEachClearance = (clearance, i) => {
    return (
      <Stack
        spacing={2}
        sx={{
          mt: 2,
          p: 3,
          borderRadius: 2,
          bgcolor: "background.neutral",
        }}
      >
        <Box>
          <Button
            variant="outlined"
            startIcon={<Iconify icon="mdi:drag" />}
            sx={{
              cursor: "grab", // Change cursor to indicate draggable
            }}
            {...this.props.dragHandleProps}
          >
            Clearance Template {i + 1}
          </Button>
        </Box>

        <Box
          rowGap={2}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
          }}
        >
          <FormControlLabel
            label="Type of Clearance"
            labelPlacement="start"
            disabled={this.state.isSaving}
            control={
              <Select
                disabled={this.state.isSaving}
                value={clearance.typeOfClearance}
                sx={{
                  minWidth: "300px",
                }}
                onChange={(e) => {
                  let clearanceOptions = this.state.clearanceOptions;
                  clearanceOptions[i].typeOfClearance = e.target.value;
                  this.setState(
                    {
                      clearanceOptions,
                    },
                    () => {
                      this.updateScalarValue(
                        "clearanceOptions",
                        this.state.clearanceOptions
                      );
                    }
                  );
                }}
              >
                {this.CLEARANCE_TYPE_OPTIONS.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            }
            sx={{
              m: 0,
              width: 1,
              justifyContent: "space-between",
            }}
          />
          <TextField
            disabled={this.state.isSaving}
            placeholder="Case"
            label="Case"
            value={clearance.case}
            multiline
            onChange={(e) => {
              let clearanceOptions = this.state.clearanceOptions;
              clearanceOptions[i].case = e.target.value;
              this.setState({
                clearanceOptions,
              });
            }}
            onBlur={() => {
              this.updateScalarValue(
                "clearanceOptions",
                this.state.clearanceOptions
              );
            }}
          />
          <TextField
            disabled={this.state.isSaving}
            label="Clearance Language"
            value={clearance.text}
            multiline
            onChange={(e) => {
              let clearanceOptions = this.state.clearanceOptions;
              clearanceOptions[i].text = e.target.value;
              this.setState({
                clearanceOptions,
              });
            }}
            onBlur={() => {
              this.updateScalarValue(
                "clearanceOptions",
                this.state.clearanceOptions
              );
            }}
          />
          {this.renderTextTemplates(i)}
          <TextField
            placeholder="Use this for clearances that are easy to auto-clear from data perspectives; eg a Drug Screen that has all negative results can be auto-cleared."
            disabled={this.state.isSaving}
            label="Clearance Criteria"
            value={clearance.criteria}
            multiline
            onChange={(e) => {
              let clearanceOptions = this.state.clearanceOptions;
              clearanceOptions[i].criteria = e.target.value;
              this.setState({
                clearanceOptions,
              });
            }}
            onBlur={() => {
              this.updateScalarValue(
                "clearanceOptions",
                this.state.clearanceOptions
              );
            }}
          />
        </Box>
        <Box>
          {" "}
          <Chip
            disabled={this.state.isSaving}
            color="error"
            label="Delete"
            variant="outlined"
            onDelete={() => {
              let clearanceOptions = this.state.clearanceOptions;
              clearanceOptions.splice(i, 1);
              this.setState(
                {
                  clearanceOptions,
                },
                () => {
                  this.updateScalarValue(
                    "clearanceOptions",
                    this.state.clearanceOptions
                  );
                }
              );
            }}
          />
        </Box>
      </Stack>
    );
  };
  render() {
    return (
      <div>
        {" "}
        <Stack spacing={4}>
          {this.renderClearanceHeader()}
          {this.renderClearances()}
        </Stack>
      </div>
    );
  }
}

export default WithAPICall(ProtocolClearances);
