import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Alert,
  LinearProgress,
  TextField,
  Typography,
  Snackbar,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Grid,
  TableBody,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

import { View500 } from "src/sections/error";
import cogoToast from "cogo-toast";
import { Box, Stack } from "@mui/system";
import _ from "lodash";
import { th } from "date-fns/locale";
import { protocol } from "socket.io-client";

class EmployeeProtocols extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    companyProtocols: [],
    parentProtocols: [],
    parentProtocolsInfo: [],
    parentProtocolsData: {},
  };
  componentDidMount() {
    this.getEEProtocols();
  }
  getEEProtocols = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost("/employee/getEEProtocols", {
        employeeId: this.props.id,
      });

      let cp = await this.props.apiCallPost(
        "/company-protocol/companyProtocolGetAll",
        {
          companyId: this.props.companyId,
        }
      );
      this.setState({
        parentProtocolsInfo: res,
        parentProtocols: this.props.data.parentProtocols,
        companyProtocols: cp,
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Employee Protocols");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };

  updateScalarValue = async (key, value) => {
    try {
      await this.props.apiCallPost("/employee/updateScalarValue", {
        employeeId: this.props.id,
        key: key,
        value: value,
      });
    } catch (err) {
      cogoToast.error("Error Updating Employee Data");
      console.log(err);
    }
  };

  addRow = () => {
    // Check if there's any row which is empty
    const hasEmpty = this.state.parentProtocols.some((row) => row === "");
    if (hasEmpty) {
      cogoToast.error("Cannot add duplicate or empty Protocol.");
      return;
    }
    this.setState({
      parentProtocols: [
        ...this.state.parentProtocols,
        {
          protocolId: "",
          dueNext: undefined,
          notes: "",
        },
      ],
    });
  };

  saveProtocols = async () => {
    try {
      cogoToast.loading("Updating Protocol Data...");
      const nProtocols = _.uniqBy(
        this.state.parentProtocols,
        "protocolId"
      ).length;
      if (nProtocols !== this.state.parentProtocols.length) {
        cogoToast.error("Cannot add the same protocol twice.");
        return;
      }

      const nonEmptyProtocols = this.state.parentProtocols.filter(
        (each) => each.protocolId !== ""
      );

      await this.props.apiCallPost("/employee/updateScalarValue", {
        employeeId: this.props.id,
        key: "parentProtocols",
        value: nonEmptyProtocols,
      });
      cogoToast.success("Protocol Updated Successfully");
      this.props.onUpdate("parentProtocols", nonEmptyProtocols);
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Updating Protocol Data");
    }
  };

  renderProtocolTable = () => {
    return (
      <div className="p-4">
        <Snackbar
          open={() => {}}
          autoHideDuration={6000}
          onClose={() => {}}
          message="Cannot add duplicate Protocol"
        />
        <TableContainer component={Paper} className="mb-4">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Protocol</TableCell>
                <TableCell>Last Done</TableCell>
                <TableCell>Next Due</TableCell>
                <TableCell>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.parentProtocols.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Select
                      value={row.protocolId}
                      onChange={(e) => {
                        const newParentProtocols = [
                          ...this.state.parentProtocols,
                        ];
                        newParentProtocols[index].protocolId = e.target.value;
                        this.setState({
                          parentProtocols: newParentProtocols,
                        });
                      }}
                      fullWidth
                    >
                      {this.state.companyProtocols.map((f) => (
                        <MenuItem key={f._id} value={f._id}>
                          {f.companyProtocolName}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Protocol Last Done"
                        value={moment(new Date(_.get(row, ["lastDone"])))}
                        onChange={(e) => {
                          if (e.isValid()) {
                            const date = e.toDate();
                            const newParentProtocols = [
                              ...this.state.parentProtocols,
                            ];
                            newParentProtocols[index].lastDone = date;
                            this.setState({
                              parentProtocols: newParentProtocols,
                            });
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </TableCell>
                  <TableCell>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Protocol Next Due"
                        value={moment(new Date(_.get(row, ["dueNext"])))}
                        onChange={(e) => {
                          if (e.isValid()) {
                            const date = e.toDate();
                            const newParentProtocols = [
                              ...this.state.parentProtocols,
                            ];
                            newParentProtocols[index].dueNext = date;
                            this.setState({
                              parentProtocols: newParentProtocols,
                            });
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={_.get(row, ["notes"])}
                      onChange={(e) => {
                        const newParentProtocols = [
                          ...this.state.parentProtocols,
                        ];
                        newParentProtocols[index].notes = e.target.value;
                        this.setState({
                          parentProtocols: newParentProtocols,
                        });
                      }}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container spacing={2} className="mt-4">
          <Grid item>
            <Button
              variant="contained"
              size="small"
              onClick={() => this.addRow()}
            >
              Add Protocol
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="small"
              onClick={() => this.saveProtocols()}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  };

  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return this.renderProtocolTable();
  }
}

export default WithAPICall(EmployeeProtocols);
