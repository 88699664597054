import { BarChart, Card } from "@tremor/react";
import React from "react";
import _ from "lodash";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import {
  FILTER_UTILS,
  sumAggregation,
  averageAggregation,
  maxAggregation,
  minAggregation,
  countAggregation,
} from "../../utils/grid-utils";
class SchedulerEfficiencyReport extends React.Component {
  state = {
    data: [],
    gridApi: null,
    columnApi: null,
    aggregations: {
      sum: (params) => sumAggregation(params),
      average: (params) => averageAggregation(params),
      max: (params) => maxAggregation(params),
      min: (params) => minAggregation(params),
      count: (params) => countAggregation(params),
    },
  };

  /******** GRID BEGINS */
  /** COLUMNS */
  COLUMNS = [
    {
      headerName: "Visit ID",
      field: "visit.humanReadableId",
      cellDataType: "text",
    },
    {
      headerName: "Employee",
      field: "employee.employeeName",
      cellDataType: "text",
    },
    {
      headerName: "Employer",
      field: "company.companyInformalName",
      cellDataType: "text",
    },
    {
      headerName: "Protocol",
      field: "protocol.companyProtocolName",
      cellDataType: "text",
    },
    {
      headerName: "Service",
      field: "psl.providerServiceAlias",
      cellDataType: "text",
    },
    {
      headerName: "Preference Recorded",
      field: "visit.employeeSchedulingPreference",
      cellDataType: "BOOLEAN",
    },
    {
      headerName: "Visit Requested At",
      field: "visit.createdAt",
      cellDataType: "date",
    },
    {
      headerName: "Visit Requested At (Time)",
      field: "visit.createdAt",
      cellDataType: "time",
    },
    {
      headerName: "Requested By",
      field: "visitCreatedBy.name",
      cellDataType: "text",
    },
    {
      headerName: "Requester User Type ",
      field: "visitCreatedBy.isExternal",
      cellDataType: "text",
      valueFormatter: (params) => {
        return params?.value ? "External" : "Internal";
      },
    },
    {
      headerName: "Scheduling Confirmed At",
      field: "createdAt",
      cellDataType: "date",
    },
    {
      headerName: "Scheduling Confirmed At (Time)",
      field: "createdAt",
      cellDataType: "time",
    },
    {
      headerName: "Scheduled By",
      field: "createdBy.name",
      cellDataType: "text",
    },
    {
      headerName: "Scheduler User Type ",
      field: "createdBy.isExternal",
      cellDataType: "text",
      valueFormatter: (params) => {
        return params?.value ? "External" : "Internal";
      },
    },
    {
      headerName: "Date of Visit",
      field: "scheduledTime",
      cellDataType: "date",
    },
    {
      headerName: "Date of Visit (Time)",
      field: "scheduledTime",
      cellDataType: "time",
    },

    {
      headerName: "Provider",
      field: "provider.providerName",
      cellDataType: "text",
    },

    {
      headerName: "Request to Schedule (Cal. Days)",
      valueGetter: (params) => {
        let visitRequestedAt = new Date(params.data.visit.createdAt);
        let schedulingConfirmedAt = new Date(params.data.createdAt);
        let diff = Math.abs(
          visitRequestedAt.getTime() - schedulingConfirmedAt.getTime()
        );
        return Math.round(diff / (1000 * 60 * 60 * 24));
      },
      cellDataType: "number",
    },
    {
      headerName: "Schedule to Visit (Cal. Days)",
      valueGetter: (params) => {
        let visitRequestedAt = new Date(params.data.scheduledTime);
        let schedulingConfirmedAt = new Date(params.data.createdAt);
        let diff = Math.abs(
          visitRequestedAt.getTime() - schedulingConfirmedAt.getTime()
        );
        return Math.round(diff / (1000 * 60 * 60 * 24));
      },
      cellDataType: "number",
    },
  ];

  onGridReady = (params) => {
    this.setState({ gridApi: params.api, columnApi: params.columnApi });
  };
  /** RENDER GRID */
  renderGrid = () => {
    let cs = "ag-theme-quartz";
    let theme = localStorage.getItem("themeMode");
    if (theme && theme === "dark") {
      cs = "ag-theme-quartz-dark";
    }
    return (
      <div className={cs} style={{ height: "70vh" }}>
        <AgGridReact
          onGridReady={this.onGridReady}
          rowData={this.props.data}
          columnDefs={this.COLUMNS.map((x) => {
            return { ...FILTER_UTILS[x.cellDataType], ...x };
          })}
          defaultColDef={{
            sortable: true,
          }}
          autoSizeStrategy={{
            type: "fitCellContents",
          }}
          pagination={true}
          rowGroupPanelShow={"always"}
          aggFuncs={this.state.aggregations}
          suppressDragLeaveHidesColumns={true}
          rowSelection="multiple"
          rowDragManaged={true}
          enableCharts={true}
          enableRangeSelection={true}
          enableCellTextSelection={true}
          sideBar={true}
          animateRows={true}
          statusBar={{
            statusPanels: [
              {
                statusPanel: "agTotalAndFilteredRowCountComponent",
                align: "left",
              },
              {
                statusPanel: "agSelectedRowCountComponent",
                align: "left",
              },
              {
                statusPanel: "agAggregationComponent",
                align: "right",
              },
            ],
          }}
          defaultExcelExportParams={{
            author: "WorkCare",
            fileName: `${this.props.reportType} - ${new Date().toLocaleDateString()}`,
            sheetName: `${this.props.reportType} - ${new Date().toLocaleDateString()}`,
          }}
        />
      </div>
    );
  };
  /******** GRID ENDS */
  /*********** CHART BEGINS */
  classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };
  dataMassager = () => {
    let dd = this.props.data;
    let data = _.uniqBy(dd, "visit.humanReadableId");
    let categories = _.uniq(data.map((each) => each.createdBy.name));
    let sortedByTime = _.sortBy(data, "createdAt");
    let mappedToDateString = sortedByTime.map((each) => {
      let date = new Date(each.createdAt);
      return {
        ...each,
        date: date.toLocaleDateString("en-US"),
      };
    });
    let groupedByDate = _.groupBy(mappedToDateString, "date");
    let xAxisDates = Object.keys(groupedByDate);
    let massagedData = [];
    for (let i = 0; i < xAxisDates.length; i++) {
      let eachDate = xAxisDates[i];
      let obj = {
        date: eachDate,
      };
      for (let j = 0; j < categories.length; j++) {
        obj[categories[j]] = 0;
      }
      let eachDateData = groupedByDate[eachDate];
      for (let j = 0; j < eachDateData.length; j++) {
        let eachRow = eachDateData[j];
        obj[eachRow.createdBy.name] += 1;
      }
      massagedData.push(obj);
    }

    return massagedData;
  };
  renderChart = () => {
    let data = this.dataMassager();
    let dd = this.props.data;
    let ddUniq = _.uniqBy(dd, "visit.humanReadableId");

    let aggregateByChaser = _.groupBy(ddUniq, "createdBy.name");

    let chasers = Object.keys(aggregateByChaser);

    let chaserCount = chasers.map((chaser) => {
      return {
        name: chaser,
        value: aggregateByChaser[chaser].length,
        color: "bg-blue-500",
      };
    });
    chaserCount = _.sortBy(chaserCount, "value").reverse();
    let totalCount = ddUniq.length;
    let top_3_chasers = chaserCount.slice(0, 3);

    return (
      <Card className="w-full max-w-full">
        <h3 className="font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
          Scheduler Productivity By Date
        </h3>
        <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
          Top 3 Schedulers In Selected Time Period
        </p>
        <ul
          role="list"
          className="mt-6 grid gap-3 sm:grid-cols-2 md:grid-cols-4"
        >
          {top_3_chasers.map((tab) => (
            <li
              key={tab.name}
              className="rounded-tremor-small border border-tremor-border px-3 py-2 text-left dark:border-dark-tremor-border"
            >
              <div className="flex items-center space-x-1.5">
                <span
                  className={this.classNames(
                    tab.color,
                    "h-2.5 w-2.5 rounded-sm"
                  )}
                  aria-hidden={true}
                />
                <p className="text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                  {tab.name}
                </p>
              </div>
              <p className="mt-0.5 font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                {tab.value}
              </p>
            </li>
          ))}
          <li className="rounded-tremor-small border border-tremor-border px-3 py-2 text-left dark:border-dark-tremor-border">
            <div className="flex items-center space-x-1.5">
              <span
                className={this.classNames(
                  "bg-blue-500",
                  "h-2.5 w-2.5 rounded-sm"
                )}
                aria-hidden={true}
              />
              <p className="text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                Total
              </p>
            </div>
            <p className="mt-0.5 font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              {totalCount}
            </p>
          </li>
        </ul>
        <BarChart
          data={data}
          index="date"
          categories={Object.keys(data[0]).filter((key) => key !== "date")}
          showLegend={false}
          yAxisWidth={45}
          stack={true}
          className="mt-6 hidden h-96 sm:block"
          customTooltip={(payload) => {
            if (!payload.active) {
              return "";
            }
            let data = payload.payload;
            let sum = _.sumBy(data, "value");
            return (
              <div className="mt-8 flex justify-center">
                <div className="w-52 rounded-tremor-default border border-tremor-border bg-tremor-background text-tremor-default shadow-tremor-input dark:border-dark-tremor-border dark:bg-dark-tremor-background dark:shadow-dark-tremor-input">
                  <div className="rounded-t-tremor-default border-b border-tremor-border bg-tremor-background-muted px-2.5 py-2 dark:border-dark-tremor-border dark:bg-dark-tremor-background">
                    <p className="font-medium text-tremor-content dark:text-dark-tremor-content">
                      {payload.label}: {sum}
                    </p>
                  </div>
                  {data.map((each) => {
                    if (!each.value) {
                      return null;
                    }
                    const classNameStr = each.className;
                    const className = classNameStr.replace("fill-", "bg-");
                    return (
                      <div className="flex w-full items-center justify-between space-x-4 px-2.5 py-2">
                        <div className="flex items-center space-x-2 truncate">
                          <span
                            className={`h-2.5 w-2.5 shrink-0 rounded-full ${className}`}
                            aria-hidden={true}
                          />
                          <p className="truncate text-tremor-content dark:text-dark-tremor-content">
                            {each.dataKey}
                          </p>
                        </div>
                        <p className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                          {each.value}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          }}
        />
      </Card>
    );
  };

  /********** CHART ENDS */
  render() {
    this.dataMassager();
    return (
      <div>
        {this.renderChart()}
        {this.renderGrid()}
      </div>
    );
  }
}
export default SchedulerEfficiencyReport;
