import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import Iconify from "src/components/iconify";

import { countries } from "src/assets/data";
import { Box } from "@mui/system";

class CountrySelector extends React.Component {
  render() {
    return (
      <Autocomplete
        sx={{
          minWidth: "400px",
        }}
        disableClearable
        onChange={(_, value) => {
          this.props.onChange(value);
        }}
        value={this.props.value}
        options={countries}
        renderOption={(props, country) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <Iconify
              key={country.label}
              icon={`circle-flags:${country.code?.toLowerCase()}`}
              sx={{ mr: 1 }}
            />
            {country.label} ({country.code}) +{country.phone}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Country"
            InputProps={{
              ...params.InputProps,
              startAdornment: this.props.value ? (
                <InputAdornment
                  position="start"
                  sx={{
                    ...(!this.props.value.code && {
                      display: "none",
                    }),
                  }}
                >
                  <Iconify
                    icon={`circle-flags:${this.props.value.code?.toLowerCase()}`}
                    sx={{ mr: -0.5, ml: 0.5 }}
                  />
                </InputAdornment>
              ) : null,
            }}
          />
        )}
      />
    );
  }
}

export default CountrySelector;
