import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Alert,
  Autocomplete,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  ListItemText,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import Iconify from "src/components/iconify";
import { Box, Stack } from "@mui/system";
import cogoToast from "cogo-toast";
import _ from "lodash";
import EmployeeTransfer from "../Employee/EmployeeTransfer";
import EmployeeNewForm from "../Employee/EmployeeNewForm";
import { niceDate } from "../utils/fn";
import { View500 } from "src/sections/error";
import { ACTIONS, EVENTS, STATUS } from "react-joyride";
import Walktour from "src/components/walktour";

class TransfersRoot extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    flowType: null,
    // company
    isSearchingCompany: false,
    companySearchResults: [],
    currentlyInCompany: null,
    csf: [],
    nonLookUpCSFs: [],
    lookUpCSFs: [],
    // employee
    isSearchingEmployee: false,
    employeeSearchResults: [],
    currentlyInEmployee: null,
    steps: [],
    runJoyRide: false,
    stepIndex: 0,
  };
  INITIAL_STEPS = [
    {
      target: "#STEP_1",
      title: "This Page",
      disableBeacon: true,
      content: (
        <Typography sx={{ color: "text.secondary" }}>
          You can add new employee records, or evaluate requirements for
          transferring existing employees.
        </Typography>
      ),
    },
    {
      target: "#STEP_2",
      title: "Two Different Choices: Add or Update",
      disableBeacon: true,
      content: (
        <Typography sx={{ color: "text.secondary" }}>
          You have two options here: you can either add a new employee record,
          or fetch an existing employee record.
        </Typography>
      ),
    },
    {
      target: "#STEP_3",
      title: "Adding A New Employee",
      disableBeacon: true,
      content: (
        <Typography sx={{ color: "text.secondary" }}>
          If this is a completely new hire, select this option. You will be
          asked to fill out a form with the employee's details. Note that some
          international employees might not be in the database. In those cases,
          proceed as if adding a new record.
        </Typography>
      ),
    },
    {
      target: "#STEP_4",
      title: "Transferring An Employee",
      disableBeacon: true,
      content: (
        <Typography sx={{ color: "text.secondary" }}>
          If you are transferring an existing employee, select this option. You
          can search for the employee by name, SSN, personnel number or
          applicant ID.
        </Typography>
      ),
    },
    {
      target: "#STEP_5",
      title: "Resume This Walkthrough",
      disableBeacon: true,
      content: (
        <Typography sx={{ color: "text.secondary" }}>
          Once you have chosen this, click here again to resume this
          walkthrough.
        </Typography>
      ),
    },
  ];
  componentDidMount() {
    this.getCurrentUser();
  }
  getCurrentUser = async () => {
    try {
      let res = await this.props.apiCallPost("/user/getCurrentUser");
      if (res.isExternal) {
        this.setState(
          {
            currentlyInCompany: {
              mainText: res.company.companyName,
              secondaryText: `${res.company.companyInformalName} ${res.companyWebsite}`,
              data: res.company,
            },
            isExternal: true,
            isLoading: false,
            steps: this.INITIAL_STEPS,
          },
          () => {
            this.getCSFDefinitions();
          }
        );
      } else {
        this.setState({
          currentlyInCompany: null,
          isExternal: false,
          isLoading: false,
          steps: this.INITIAL_STEPS,
        });
      }
    } catch (err) {
      cogoToast.error("Error Loading User Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  searchCompaniesFTS = async (t) => {
    try {
      let res = await this.props.apiCallPostCancellable("/company/search", {
        query: t,
      });
      if (res) {
        this.setState({
          isSearchingCompany: false,
          companySearchResults: res.map((each) => {
            return {
              mainText: each.companyName,
              secondaryText: `${each.companyInformalName} ${each.companyWebsite}`,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingCompany: false,
        companySearchResults: [],
      });
      cogoToast.error("Error Searching Companies");
    }
  };
  searchEmployeesFTS = async (t) => {
    try {
      let res = await this.props.apiCallPostCancellable(
        "/employee/data/searchAugmentedFts",
        {
          companyId: this.state.currentlyInCompany.data._id,
          query: t,
        }
      );
      if (res) {
        this.setState({
          isSearchingEmployee: false,
          employeeSearchResults: res.map((each) => {
            return {
              mainText: each.employeeName,
              secondaryText: `${each.employeeNumber} | ${each.employeeOfficeEmail}`,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingEmployee: false,
        employeeSearchResults: [],
      });
      cogoToast.error("Error Searching Employees");
    }
  };
  getCSFDefinitions = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost("/company/csf/get", {
        companyId: this.state.currentlyInCompany.data._id,
      });
      let nonLookUpCSFs = _.filter(res, (each) => !each.needsLookUp);
      let lookUpCSFs = _.filter(res, (each) => each.needsLookUp);
      this.setState({
        csf: res,
        nonLookUpCSFs: nonLookUpCSFs,
        lookUpCSFs: lookUpCSFs,
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Employer Specific Fields");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  renderCompanySearcher = () => {
    if (!this.state.flowType) {
      return null;
    }
    if (this.state.isExternal) {
      return null;
    }
    return (
      <Autocomplete
        disableClearable
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option?.mainText
        }
        filterOptions={(x) => x}
        options={
          this.state.isSearchingCompany ? [] : this.state.companySearchResults
        }
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={this.state.currentlyInCompany}
        noOptionsText={
          this.state.isSearchingCompany ? "Searching..." : "No results found"
        }
        onChange={(_, value) => {
          this.setState(
            {
              currentlyInCompany: value,
              companySearchResults: [value, ...this.state.companySearchResults],
            },
            () => {
              this.getCSFDefinitions();
            }
          );
        }}
        onInputChange={(_, e) => {
          if (e === "") return null;
          if (!e) return null;
          if (e.length < 4) return null;
          this.setState(
            {
              isSearchingCompany: true,
            },
            () => {
              this.searchCompaniesFTS(e);
            }
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label="Search Employers" fullWidth />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ wordWrap: "break-word" }}>
                  <Box component="span">{option?.mainText}</Box>
                  <Typography variant="body2" color="text.secondary">
                    {option?.secondaryText}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    );
  };
  renderEmployeeSearcher = () => {
    if (
      !this.state.currentlyInCompany ||
      !this.state.currentlyInCompany.data ||
      !this.state.currentlyInCompany.data._id
    ) {
      return null;
    }
    if (this.state.flowType !== "OLD") {
      return null;
    }
    return (
      <Stack id="STEP_SEARCH">
        <Autocomplete
          disableClearable
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option?.mainText
          }
          filterOptions={(x) => x}
          options={
            this.state.isSearchingEmployee
              ? []
              : this.state.employeeSearchResults
          }
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={this.state.currentlyInEmployee}
          noOptionsText={
            this.state.isSearchingEmployee ? "Searching..." : "No results found"
          }
          onChange={(_, value) => {
            this.setState(
              {
                currentlyInEmployee: value,
                employeeSearchResults: [
                  value,
                  ...this.state.employeeSearchResults,
                ],
              },
              () => {
                let steps = this.state.steps;
                steps.push({
                  target: "#STEP_INFO",
                  title: "Employee Information",
                  disableBeacon: true,
                  content: (
                    <Typography sx={{ color: "text.secondary" }}>
                      This is the employee you have selected. Verify the
                      information to ensure you are working with the right
                      employee.
                    </Typography>
                  ),
                });
                steps.push({
                  target: "#STEP_DETAIL",
                  title: "View Details",
                  disableBeacon: true,
                  content: (
                    <Typography sx={{ color: "text.secondary" }}>
                      If you want to see more details, click here.
                    </Typography>
                  ),
                });
                steps.push({
                  target: "#STEP_TRANSFER",
                  title: "Transferring Details",
                  disableBeacon: true,
                  content: (
                    <Typography sx={{ color: "text.secondary" }}>
                      In this section, on the left you will see the current data
                      we have on file for this employee. On the right, you can
                      fill in the changes proposed, for example a new job or
                      location. Once you're done, click the Check Against
                      Protocols button at the bottom to proceed.
                    </Typography>
                  ),
                });
                this.setState({ steps: steps, stepIndex: 5 });
              }
            );
          }}
          onInputChange={(_, e) => {
            if (e === "") return null;
            if (!e) return null;
            if (e.length < 4) return null;
            this.setState(
              {
                isSearchingEmployee: true,
              },
              () => {
                this.searchEmployeesFTS(e);
              }
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Employees (Use Name, SSN, Personnel Number or Applicant ID)"
              fullWidth
            />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item sx={{ wordWrap: "break-word" }}>
                    <Box component="span">{option?.mainText}</Box>
                    <Typography variant="body2" color="text.secondary">
                      {option?.secondaryText}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
        <Alert severity="info">
          You can search by employee name, applicant ID, personnel number, SSN
          or other identifiers.
        </Alert>
      </Stack>
    );
  };
  renderTransfer = () => {
    if (
      !this.state.currentlyInEmployee ||
      !this.state.currentlyInEmployee.data ||
      !this.state.currentlyInEmployee.data._id
    ) {
      return null;
    }
    if (this.state.flowType !== "OLD") {
      return null;
    }
    return (
      <div id="STEP_TRANSFER">
        <EmployeeTransfer
          id={this.state.currentlyInEmployee.data._id}
          data={this.state.currentlyInEmployee.data}
          companyId={this.state.currentlyInCompany.data._id}
          onReset={() => {
            this.setState({
              currentlyInEmployee: null,
              flowType: null,
            });
          }}
        />
      </div>
    );
  };
  renderNewForm = () => {
    if (
      !this.state.currentlyInCompany ||
      !this.state.currentlyInCompany.data ||
      !this.state.currentlyInCompany.data._id
    ) {
      return null;
    }

    if (this.state.flowType !== "NEW") {
      return null;
    }
    return (
      <div id="STEP_ADD">
        <EmployeeNewForm
          companyId={this.state.currentlyInCompany.data._id}
          onReset={() => {
            this.setState({
              currentlyInEmployee: null,
              flowType: null,
            });
          }}
        />
      </div>
    );
  };
  renderTransferPageHeader = () => {
    return (
      <Stack spacing={3}>
        <ListItemText
          sx={{
            p: 3,
            borderRadius: 2,
            bgcolor: "background.neutral",
          }}
          primary={
            <Stack direction="row" alignItems="center" spacing={3}>
              <Typography variant="h6" id="STEP_1">
                Medical/D&A Screening Request
              </Typography>
              <ToggleButtonGroup
                id="STEP_2"
                exclusive
                value={this.state.flowType}
                onChange={(_, val) => {
                  let steps = this.INITIAL_STEPS;

                  if (val === "OLD") {
                    steps.push({
                      target: "#STEP_SEARCH",
                      title: "Search Employees",
                      disableBeacon: true,
                      content: (
                        <Typography sx={{ color: "text.secondary" }}>
                          You can start typing in here to search for an employee
                          by name, SSN, personnel number or applicant ID. Please
                          enter at least 4 characters to start searching.
                        </Typography>
                      ),
                    });
                  } else {
                    steps.push({
                      target: "#STEP_ADD",
                      title: "Add A New Employee",
                      disableBeacon: true,
                      content: (
                        <Stack spacing={2}>
                          {" "}
                          <Typography sx={{ color: "text.secondary" }}>
                            Fill in the basic details of the new employee. You
                            can always add more details later if you don't have
                            all details off hand.
                          </Typography>{" "}
                          <Typography sx={{ color: "text.secondary" }}>
                            Note that it is critical to fill in the employee's
                            phone number correctly so we can reach them to
                            schedule a visit.
                          </Typography>{" "}
                          <Typography sx={{ color: "text.secondary" }}>
                            Similarly, please fill in the Job Codes and other
                            details correctly so the right set of tests can be
                            scheduled.
                          </Typography>
                        </Stack>
                      ),
                    });
                  }
                  this.setState({ flowType: val, steps: steps, stepIndex: 5 });
                }}
              >
                <ToggleButton value="NEW" aria-label="new" id="STEP_3">
                  Add New Personnel Record
                </ToggleButton>
                <ToggleButton value="OLD" aria-label="new" id="STEP_4">
                  Transfer Existing Employee
                </ToggleButton>
              </ToggleButtonGroup>
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  this.setState({
                    currentlyInEmployee: null,
                    flowType: null,
                  });
                }}
                sx={{
                  width: 24,
                  height: 24,
                  bgcolor: "primary.main",
                  color: "primary.contrastText",
                  "&:hover": {
                    bgcolor: "primary.dark",
                  },
                }}
              >
                <Iconify icon="mingcute:refresh-anticlockwise-1-line" />
              </IconButton>
              {this.renderJoyRideButton()}
            </Stack>
          }
          secondary="Choose whether you want to add a new personnel record, or transfer an existing employee. Note that if you're transferring internationally, some employee records may not be in the database. In those cases, proceed as if adding a new record."
          primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
          secondaryTypographyProps={{ component: "span" }}
        />
      </Stack>
    );
  };
  renderEmployeeInfo = () => {
    if (
      !this.state.currentlyInEmployee ||
      !this.state.currentlyInEmployee.data ||
      !this.state.currentlyInEmployee.data._id
    ) {
      return null;
    }
    if (this.state.flowType === "NEW") {
      return null;
    }
    if (!this.state.flowType) {
      return null;
    }
    if (this.state.isSearchingEmployee) {
      return null;
    }
    let ssn = "";
    if (this.state.currentlyInEmployee.data.employeeSSN) {
      let s = this.state.currentlyInEmployee.data.employeeSSN;
      ssn = s.slice(-4);
      ssn = "| SSN: XXX-XX-" + ssn;
    }
    return (
      <Stack spacing={3} id="STEP_INFO">
        <ListItemText
          sx={{
            p: 3,
            borderRadius: 2,
            bgcolor: "background.neutral",
          }}
          primary={
            <Stack direction="row" alignItems="center" spacing={3}>
              <Typography variant="h6">
                {this.state.currentlyInEmployee.data.employeeName}
              </Typography>
              <Typography variant="overline">
                {" "}
                {this.state.currentlyInEmployee.data.employeeNumber} |{" "}
                {niceDate(this.state.currentlyInEmployee.data.employeeDoB)} |{" "}
                {this.state.currentlyInEmployee.data.employeeSex} {ssn}
              </Typography>{" "}
              <Tooltip
                title={
                  this.state.showDetail
                    ? "Click to hide details"
                    : "Click to show details"
                }
              >
                <IconButton
                  id="STEP_DETAIL"
                  size="small"
                  onClick={() => {
                    this.setState({
                      showDetail: !this.state.showDetail,
                    });
                  }}
                >
                  <Iconify
                    icon={
                      this.state.showDetail
                        ? "solar:square-double-alt-arrow-up-broken"
                        : "solar:square-double-alt-arrow-down-broken"
                    }
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          }
          secondary={` ${this.state.currentlyInEmployee.data.employeeCountry} |
          ${this.state.currentlyInEmployee.data.employeeOfficeEmail}`}
          primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
          secondaryTypographyProps={{ component: "span" }}
        />
        {this.renderDetailsEmployee()}
      </Stack>
    );
  };
  renderDetailsEmployee = () => {
    if (
      !this.state.currentlyInEmployee ||
      !this.state.currentlyInEmployee.data ||
      !this.state.currentlyInEmployee.data._id
    ) {
      return null;
    }
    if (this.state.flowType === "NEW") {
      return null;
    }
    if (!this.state.flowType) {
      return null;
    }
    if (this.state.isSearchingEmployee) {
      return null;
    }
    if (!this.state.showDetail) {
      return null;
    }
    let addlDetails = [
      {
        key: "employeeCountry",
        label: "Country",
      },
      {
        key: "employeeAddress",
        label: "Address Line 1",
      },
      {
        key: "employeeAddressQualilfier",
        label: "Address Line 2",
      },
      {
        key: "employeeCity",
        label: "City",
      },
      {
        key: "employeeState",
        label: "State",
      },
      {
        key: "employeePostalCode",
        label: "Postal Code",
      },
      {
        key: "employeePersonalPhone",
        label: "Personal Phone",
      },
      {
        key: "employeeOfficePhone",
        label: "Office Phone",
      },
      {
        key: "employeePersonalEmail",
        label: "Personal Email",
      },
      {
        key: "employeeOfficeEmail",
        label: "Work Email",
      },
      {
        key: "dateOfLastHiring",
        label: "Last Hired",
      },
      {
        key: "dateOfLastTermination",
        label: "Last Terminated",
      },
    ];
    return (
      <Stack spacing={3}>
        {addlDetails.map((each) => {
          let val = this.state.currentlyInEmployee.data[each.key];
          if (!val) return null;
          return (
            <Stack direction="row" alignItems="center" spacing={3}>
              <Typography variant="button">{each.label}</Typography>
              <Typography variant="overline">
                {this.state.currentlyInEmployee.data[each.key]}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    );
  };
  renderCompanyInfo = () => {
    if (
      !this.state.currentlyInCompany ||
      !this.state.currentlyInCompany.data ||
      !this.state.currentlyInCompany.data._id
    ) {
      return null;
    }

    if (this.state.isSearchingCompany) {
      return null;
    }
    return (
      <Stack spacing={3}>
        <ListItemText
          sx={{
            p: 3,
            borderRadius: 2,
            bgcolor: "background.neutral",
          }}
          primary={
            <Stack direction="row" alignItems="center" spacing={3}>
              <Typography variant="h6">
                {this.state.currentlyInCompany.data.companyName}
              </Typography>
            </Stack>
          }
          secondary={this.state.currentlyInCompany.data.companyWebsite}
          primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
          secondaryTypographyProps={{ component: "span" }}
        />
      </Stack>
    );
  };
  handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ runJoyRide: false, stepIndex: 0 });
    }
  };
  renderJoyRideButton = () => {
    return null;
    // return (
    //   <Button
    //     variant="contained"
    //     startIcon={<Iconify icon="mingcute:question-fill" />}
    //     id="STEP_5"
    //     onClick={() => {
    //       this.setState({
    //         runJoyRide: true,
    //       });
    //     }}
    //   >
    //     Quick walkthrough
    //   </Button>
    // );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    const { runJoyRide, stepIndex, steps } = this.state;
    return (
      <Stack spacing={2}>
        <Walktour
          key={this.state.steps.length}
          continuous
          showProgress
          showSkipButton
          disableOverlayClose
          callback={this.handleJoyrideCallback}
          run={runJoyRide}
          stepIndex={stepIndex}
          steps={steps}
        />
        {this.renderTransferPageHeader()}
        {this.renderCompanySearcher()}
        {this.renderCompanyInfo()}
        {this.renderNewForm()}
        {this.renderEmployeeSearcher()}
        {this.renderEmployeeInfo()}
        {this.renderTransfer()}
      </Stack>
    );
  }
}

export default WithAPICall(TransfersRoot);
