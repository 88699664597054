import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import { Box, Stack } from "@mui/system";
import {
  Alert,
  Button,
  Card,
  Chip,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Iconify from "src/components/iconify";
import cogoToast from "cogo-toast";
import Grid from "@mui/system/Unstable_Grid/Grid";
import GMapMultiple from "../utils/GMapMultiple";
import _, { flatten } from "lodash";
import { LoadingButton } from "@mui/lab";
import VisitChooseLabSupplyStatus from "./VisitChooseLabSupplyStatus";

class VisitChooseProvider extends React.Component {
  state = {
    isLoading: true,
    hasSearched: false,
    isSearching: false,
    results: [],
    chosenProviderIds: [],
    chosenPSLs: [],
  };
  async componentDidMount() {
    let chosenPSLs = this.props.visit.labPSLs;
    if (chosenPSLs) {
      this.setState({ chosenPSLs, isLoading: false });
    }

    // check which services have a default lab
    // if they have a default lab, find the lab psl which
    // has the service and attach psl
    await this.checkAndApplyDefaultProviders();
  }

  async componentDidUpdate() {
    // await this.checkAndApplyDefaultProviders();
  }

  checkAndApplyDefaultProviders = async () => {
    const servicesWithDefaultLabs = this.props.services.filter(
      (s) => s.defaultLab
    );
    const chosenPSLs = _.cloneDeep(this.state.chosenPSLs);
    for (const service of servicesWithDefaultLabs) {
      try {
        const psls = await this.props.apiCallPost(
          "/provider-service-link/providerServiceLinkGetAll",
          {
            doesApplyToNetwork: false,
            providerId: service.defaultLab._id,
          }
        );
        // check if service psl is in psls
        const masterServiceIds = flatten(
          psls.map((p) => p.masterServices.map((m) => m._id))
        );
        let selectedPsl = null;
        psls.forEach((psl) => {
          const masterServiceIds = psl.masterServices.map((m) => m._id);
          if (masterServiceIds.indexOf(service._id) !== -1) {
            selectedPsl = psl;
            selectedPsl.masterServices = [service._id];
          }
        });
        if (selectedPsl) {
          let newObj = {
            providerName: service.defaultLab.providerName,
            providerObj: service.defaultLab,
            pslObj: selectedPsl,
            providerId: service.defaultLab._id,
            pslId: selectedPsl._id,
            pslName: selectedPsl.providerServiceAlias,
            masterServices: selectedPsl.masterServices,
          };
          let visit = this.props.visit;
          let clinicPSLs = visit.clinicPSLs;
          if (!clinicPSLs || !clinicPSLs.length) {
            newObj["collectingClinicId"] = null;
            newObj["collectingClinicName"] = null;
          } else {
            let clinicProviders = clinicPSLs.map((x) => x.providerObj);
            let uniqClinicProviders = _.uniqBy(clinicProviders, "_id");
            let clinicOptions = uniqClinicProviders.map((x) => {
              return {
                value: x._id,
                label: x.providerName,
              };
            });
            if (clinicOptions.length === 1) {
              newObj["collectingClinicId"] = clinicOptions[0].value;
              newObj["collectingClinicName"] = clinicOptions[0].label;
            } else {
              newObj["collectingClinicId"] = null;
              newObj["collectingClinicName"] = null;
            }
          }
          chosenPSLs.push(newObj);
        }
        this.setState({ chosenPSLs });
      } catch (error) {}
    }
  };

  renderServices = () => {
    let services = this.props.services;
    return (
      <Stack spacing={2}>
        <Typography variant="h6">
          {this.props.typeOfProvider} Services
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Service</TableCell>
              <TableCell>Chosen Lab</TableCell>{" "}
              <TableCell>Sample Collection At</TableCell>{" "}
              <TableCell>Supply Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map((service) => {
              let isIncluded = this.isServiceInChosenPSLs(service._id);
              let rowSx = {
                bgcolor: isIncluded ? "success.light" : "background.paper",
              };
              return (
                <TableRow sx={rowSx}>
                  <TableCell>
                    <Stack
                      key={service._id}
                      spacing={1}
                      direction="row"
                      alignItems={"center"}
                    >
                      <Typography variant="subtitle1">
                        {service.serviceName}
                      </Typography>
                      <IconButton
                        disabled={this.props.visit.isSaving}
                        size="small"
                        href={`/services/${service._id}`}
                        variant="outlined"
                        // target="non_blank"
                      >
                        <Iconify icon="mdi:arrow-top-right" />
                      </IconButton>
                    </Stack>
                  </TableCell>{" "}
                  <TableCell>
                    {this.whoIsProvidingService(service._id)}
                  </TableCell>{" "}
                  <TableCell>
                    {this.renderCollectingClinicChoice(service._id)}
                  </TableCell>
                  <TableCell>
                    {this.renderVisitChooseLabSupplyStatus(service._id)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Stack>
    );
  };
  renderVisitChooseLabSupplyStatus = (serviceId) => {
    let chosenPSLs = this.state.chosenPSLs;
    let chosenPSLForThisService = _.find(chosenPSLs, (x) =>
      x.masterServices.includes(serviceId)
    );
    if (!chosenPSLForThisService) {
      return <Alert severity="warning">No Provider Chosen</Alert>;
    }

    if (!chosenPSLForThisService.collectingClinicId) {
      return <Alert severity="warning">No Clinic Chosen</Alert>;
    }

    if (!chosenPSLForThisService.pslObj.network) {
      return <Alert severity="info">No Supplies Needed</Alert>;
    }
    if (
      !chosenPSLForThisService.pslObj.suppliesNeeded ||
      !chosenPSLForThisService.pslObj.suppliesNeeded.length
    ) {
      return <Alert severity="info">No Supplies Needed</Alert>;
    }
    let sn = chosenPSLForThisService.pslObj.suppliesNeeded;
    return (
      <Stack spacing={1}>
        <Typography variant="caption">Supplies Needed</Typography>
        {sn.map((each, snIdx) => {
          return (
            <VisitChooseLabSupplyStatus
              key={`${serviceId}-${snIdx}-${chosenPSLForThisService.collectingClinicId}`}
              visit={this.props.visit}
              chosenPSLForThisService={chosenPSLForThisService}
              supply={each}
            />
          );
        })}
      </Stack>
    );
  };
  renderCollectingClinicChoice = (serviceId) => {
    let visit = this.props.visit;
    let clinicPSLs = visit.clinicPSLs;
    if (!clinicPSLs || !clinicPSLs.length) {
      return <Alert severity="warning">No Clinic Providers Chosen</Alert>;
    }
    let clinicProviders = clinicPSLs.map((x) => x.providerObj);
    let uniqClinicProviders = _.uniqBy(clinicProviders, "_id");
    let clinicOptions = uniqClinicProviders.map((x) => {
      return {
        value: x._id,
        label: x.providerName,
      };
    });
    // find which PSL is chosen for this service
    let chosenPSLs = this.state.chosenPSLs;
    let chosenPSLForThisService = _.find(chosenPSLs, (x) =>
      x.masterServices.includes(serviceId)
    );

    if (!chosenPSLForThisService) {
      return <Alert severity="warning">No Provider Chosen</Alert>;
    }

    if (clinicOptions.length === 1) {
      return (
        <Typography variant="overline">{clinicOptions[0].label}</Typography>
      );
    }
    let collectingClinicId = chosenPSLForThisService.collectingClinicId;

    return (
      <Select
        value={collectingClinicId}
        onChange={(e) => {
          let chosenPSLs = _.cloneDeep(this.state.chosenPSLs);
          let idx = _.findIndex(chosenPSLs, (x) =>
            x.masterServices.includes(serviceId)
          );
          chosenPSLs[idx].collectingClinicId = e.target.value;
          chosenPSLs[idx].collectingClinicName = _.find(clinicOptions, (x) => {
            return x.value === e.target.value;
          }).label;
          this.setState({ chosenPSLs });
        }}
      >
        {clinicOptions.map((x) => {
          return <MenuItem value={x.value}>{x.label}</MenuItem>;
        })}
      </Select>
    );
  };
  isServiceInChosenPSLs = (serviceId) => {
    let chosenPSLs = this.state.chosenPSLs;
    let masterServices = _.flatten(chosenPSLs.map((x) => x.masterServices));
    if (masterServices.includes(serviceId)) {
      return true;
    }
  };
  whoIsProvidingService = (serviceId) => {
    const service = this.props.services.find((s) => s._id === serviceId);
    let provider = null;
    let pslName = "";
    if (service.defaultLab) {
      provider = service.defaultLab;
    } else {
      let isIncluded = this.isServiceInChosenPSLs(serviceId);
      if (!isIncluded) {
        return <Alert severity="warning">No Provider Chosen</Alert>;
      }
      let chosenPSLs = this.state.chosenPSLs;
      let idx = _.findIndex(chosenPSLs, (x) =>
        x.masterServices.includes(serviceId)
      );
      let psl = chosenPSLs[idx];

      provider = psl.providerObj;
      let pslName = psl.pslName;
    }
    return (
      <Stack>
        <Typography variant="overline">{provider.providerName}</Typography>{" "}
        <Typography variant="caption">{pslName}</Typography>
      </Stack>
    );
  };
  findProviders = async () => {
    let services = this.props.services;
    let serviceIds = services.map((x) => x._id);
    try {
      this.setState({
        results: [],
        chosenProviderId: null,
        isSearching: true,
      });
      let res = await this.props.apiCallPost(
        "/visit/lookupProvidersByServices",
        {
          companyId: this.props.visit.company._id,
          masterServiceIds: serviceIds,
          eeGeoLong: this.props.visit.employeeGeoLat
            ? this.props.visit.employeeGeoLat
            : 0,
          eeGeoLat: this.props.visit.employeeGeoLong
            ? this.props.visit.employeeGeoLong
            : 0,
          radiusMeters: 0,
        }
      );
      this.setState({
        hasSearched: true,
        results: res,
        isSearching: false,
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Employer Data");
      this.setState({
        isLoading: false,
        isError: true,
        isSearching: false,
      });
    }
  };
  renderNotCovered = (eachProvider) => {
    let coverage_ls = eachProvider.coverage_ls;
    let serviceIds = this.props.services.map((x) => x._id);
    let notCovered = serviceIds.filter((x) => !coverage_ls.includes(x));
    let coversAll = notCovered.length === 0;
    if (coversAll) {
      return (
        <Stack direction="row" alignItems={"center"} spacing={1}>
          <Iconify icon="mingcute:check-circle-fill" />
          <Typography variant="caption">
            Covers All {this.props.typeOfProvider} Services
          </Typography>
        </Stack>
      );
    }
    let nc = [];
    if (!coversAll) {
      nc = notCovered
        .map((x) => {
          return {
            service: _.find(this.props.services, (y) => y._id === x),
          };
        })
        .map((z) => z.service);
    }
    return (
      <Stack spacing={1}>
        <Typography variant="caption">
          Does not cover {nc.map((x) => x.serviceName).join(", ")}
        </Typography>
      </Stack>
    );
  };
  renderPSLsForEachProvider = (eachProvider) => {
    let psls = eachProvider.psls;
    if (!psls || !psls.length) {
      return <Alert severity="warning">No Provider Services Matched.</Alert>;
    }
    return (
      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(2, 1fr)",
          sm: "repeat(3, 1fr)",
          lg: "repeat(5, 1fr)",
        }}
      >
        {psls.map((eachPsl, pslIndex) => {
          let ms = eachPsl.masterServices;
          let msMapped = ms
            .map((x) => {
              return {
                service: _.find(this.props.services, (y) => y._id === x),
              };
            })
            .map((z) => z.service);

          let chosenPSLs = this.state.chosenPSLs;
          let isThisPSLChosen = _.findIndex(
            chosenPSLs,
            (x) => x.pslId === eachPsl._id
          );
          let isThisPSLChosenByThisProvider = false;
          if (isThisPSLChosen > -1) {
            let cpsl = chosenPSLs[isThisPSLChosen];
            let currentProviderId = eachProvider.provider._id;
            if (cpsl.providerId === currentProviderId) {
              isThisPSLChosenByThisProvider = true;
            }
          }
          let buttonElem = (
            <Button
              disabled={this.props.visit.isSaving}
              size="small"
              onClick={() => {
                let chosenPSLs = _.cloneDeep(this.state.chosenPSLs);
                for (var i = 0; i < eachPsl.masterServices.length; i++) {
                  let ms = eachPsl.masterServices[i];
                  let isIncludedAlready = this.isServiceInChosenPSLs(ms);
                  if (isIncludedAlready) {
                    cogoToast.error(
                      "Sorry, this service has already been covered."
                    );
                    return;
                  }
                }
                let newObj = {
                  providerName: eachProvider.provider.providerName,
                  providerObj: eachProvider.provider,
                  pslObj: eachPsl,
                  providerId: eachProvider.provider._id,
                  pslId: eachPsl._id,
                  pslName: eachPsl.providerServiceAlias,
                  masterServices: eachPsl.masterServices,
                };
                let visit = this.props.visit;
                let clinicPSLs = visit.clinicPSLs;
                if (!clinicPSLs || !clinicPSLs.length) {
                  newObj["collectingClinicId"] = null;
                  newObj["collectingClinicName"] = null;
                } else {
                  let clinicProviders = clinicPSLs.map((x) => x.providerObj);
                  let uniqClinicProviders = _.uniqBy(clinicProviders, "_id");
                  let clinicOptions = uniqClinicProviders.map((x) => {
                    return {
                      value: x._id,
                      label: x.providerName,
                    };
                  });
                  if (clinicOptions.length === 1) {
                    newObj["collectingClinicId"] = clinicOptions[0].value;
                    newObj["collectingClinicName"] = clinicOptions[0].label;
                  } else {
                    newObj["collectingClinicId"] = null;
                    newObj["collectingClinicName"] = null;
                  }
                }
                chosenPSLs.push(newObj);
                this.setState({ chosenPSLs });
              }}
            >
              Choose
            </Button>
          );
          if (isThisPSLChosenByThisProvider) {
            buttonElem = (
              <Button
                disabled={this.props.visit.isSaving}
                size="small"
                onClick={() => {
                  let chosenPSLs = _.cloneDeep(this.state.chosenPSLs);

                  let idx = _.findIndex(
                    chosenPSLs,
                    (x) => x.pslId === eachPsl._id
                  );
                  chosenPSLs.splice(idx, 1);

                  this.setState({ chosenPSLs });
                }}
              >
                Remove
              </Button>
            );
          }
          let cardSx = {
            p: 2,
            boxShadow: 3,
            rounded: 1,
            bgcolor: isThisPSLChosenByThisProvider
              ? "success.light"
              : "background.paper",
          };
          return (
            <Card key={pslIndex} sx={cardSx}>
              <Stack>
                <Typography variant="button">
                  {eachPsl.providerServiceAlias}
                </Typography>
                {msMapped.map((eachMs, msIndex) => {
                  return (
                    <Typography variant="caption" key={msIndex}>
                      {eachMs.serviceName}
                    </Typography>
                  );
                })}
                {buttonElem}
              </Stack>
            </Card>
          );
        })}
      </Box>
    );
  };
  renderEachProvider = (eachProvider) => {
    let coverage_ls = eachProvider.coverage_ls;
    let serviceIds = this.props.services.map((x) => x._id);
    let notCovered = serviceIds.filter((x) => !coverage_ls.includes(x));
    let coversAll = notCovered.length === 0;
    let nc = [];
    if (!coversAll) {
      nc = notCovered
        .map((x) => {
          return {
            service: _.find(this.props.services, (y) => y._id === x),
          };
        })
        .map((z) => z.service);
    }

    return (
      <Card
        key={eachProvider.provider._id}
        sx={{
          p: 2,
          boxShadow: 3,
          rounded: 1,
        }}
      >
        <Stack spacing={1}>
          <Typography variant="overline">
            {eachProvider.provider.providerName}
          </Typography>
          {this.renderNotCovered(eachProvider)}
          {this.renderPSLsForEachProvider(eachProvider)}
        </Stack>
      </Card>
    );
  };
  renderResults = () => {
    if (this.props.visit.labFrozen) {
      return null;
    }
    if (!this.props.visit.clinicFrozen) {
      return (
        <Alert severity="warning">Please choose clinic providers first.</Alert>
      );
    }
    if (!this.state.hasSearched) {
      return null;
    }
    if (!this.state.results || !this.state.results.length) {
      return <Alert severity="warning">No Providers Found</Alert>;
    }
    let results = this.state.results;
    let markers = this.state.results.map((x) => {
      return {
        lat: x.provider.providerGeoLat,
        lng: x.provider.providerGeoLong,
        label: x.provider.providerName,
        _id: x.provider._id,
      };
    });
    markers.push({
      lat: this.props.visit.employeeGeoLat,
      lng: this.props.visit.employeeGeoLong,
      label: "Employee",
      _id: "EMPLOYEE",
    });
    let averageLat = markers.reduce((acc, curr) => {
      return acc + curr.lat;
    }, 0);
    averageLat = averageLat / markers.length;
    let averageLong = markers.reduce((acc, curr) => {
      return acc + curr.lng;
    }, 0);
    averageLong = averageLong / markers.length;
    let centre = {
      lat: averageLat,
      lng: averageLong,
    };
    let chosenProviderIds = _.uniq(
      this.state.chosenPSLs.map((x) => x.providerId)
    );
    return (
      <Grid container spacing={3}>
        <Grid xs={12} md={4}>
          <GMapMultiple
            markers={markers}
            placeDetail={centre}
            chosenMarkerIndices={chosenProviderIds}
            onSelectMarker={(_id) => {}}
          />
        </Grid>{" "}
        <Grid xs={12} md={8}>
          <Stack spacing={2}>
            {results.map((each) => {
              return this.renderEachProvider(each);
            })}
          </Stack>
        </Grid>
      </Grid>
    );
  };
  renderButton = () => {
    // if (!this.props.visit.clinicFrozen) {
    //   return null;
    // }
    if (this.props.visit.labFrozen) {
      return (
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
          }}
        >
          <Button
            disabled={this.props.visit.isSaving}
            onClick={() => {
              this.props.unFreezeLabs();
            }}
            variant="outlined"
          >
            Unfreeze Lab Choices
          </Button>
        </Box>
      );
    }
    let services = this.props.services;
    let serviceIds = services.map((x) => x._id);
    let allServicesChosen = serviceIds
      .map((x) => this.isServiceInChosenPSLs(x))
      .every((x) => x === true);

    let chosenPSLs = this.state.chosenPSLs;
    let chosenPSLCollectingClinicIds = chosenPSLs.map(
      (x) => x.collectingClinicId
    );
    let allClinicsChosen = chosenPSLCollectingClinicIds.every(
      (x) => x !== null
    );

    return (
      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
        }}
      >
        <Button
          size="small"
          disabled={this.props.visit.isSaving}
          onClick={() => {
            this.props.onFreezeLab(this.state.chosenPSLs);
          }}
          variant="contained"
          color="primary"
          startIcon={<Iconify icon="carbon:add" />}
        >
          Confirm Lab Providers
        </Button>
      </Box>
    );

    // if (!allServicesChosen || !allClinicsChosen) {
    //   return (
    //     <Box
    //       rowGap={3}
    //       columnGap={2}
    //       display="grid"
    //       gridTemplateColumns={{
    //         xs: "repeat(1, 1fr)",
    //         sm: "repeat(2, 1fr)",
    //       }}
    //     >
    //       {/* <LoadingButton
    //         disabled={this.props.visit.isSaving}
    //         loading={this.state.isSearching}
    //         onClick={() => {
    //           this.findProviders();
    //         }}
    //         variant="contained"
    //       >
    //         Find Labs
    //       </LoadingButton>
    //       <Chip label="Not All Services Chosen" color="warning" /> */}
    //     </Box>
    //   );
    // } else {
    //   return (
    //     <Box
    //       rowGap={3}
    //       columnGap={2}
    //       display="grid"
    //       gridTemplateColumns={{
    //         xs: "repeat(1, 1fr)",
    //         sm: "repeat(2, 1fr)",
    //       }}
    //     >
    //       <Button
    //         size="small"
    //         disabled={this.props.visit.isSaving}
    //         onClick={() => {
    //           this.props.onFreezeLab(this.state.chosenPSLs);
    //         }}
    //         variant="contained"
    //         color="primary"
    //         startIcon={<Iconify icon="carbon:add" />}
    //       >
    //         Confirm Lab Providers
    //       </Button>
    //     </Box>
    //   );
    // }
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <Paper sx={{ p: 2, rounded: 1, boxShadow: 3 }}>
        <Stack spacing={2}>
          <Alert severity="info">
            The default lab is selected for each service. In case there are
            multiple collection centers, you will be prompted to select the
            required collection center for a lab test.
          </Alert>
          {this.renderServices()}
          {this.renderButton()}
          {this.renderResults()}
        </Stack>
      </Paper>
    );
  }
}

export default WithAPICall(VisitChooseProvider);
