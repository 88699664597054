import React from "react";
import {
  Button,
  Card,
  Chip,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Box, Stack } from "@mui/system";
import Editor from "src/components/editor";
import GenericFileUploadHeader from "../utils/GenericFileUploadHeader";
import { niceDateTime } from "./fn";
import { WithAPICall } from "./apiUtil";
import cogoToast from "cogo-toast";
const filter = createFilterOptions();
class GenericNote extends React.Component {
  state = { isLoading: true, isUpdating: false };
  componentDidMount() {
    this.setState(
      {
        ...this.props.data,
      },
      () => {
        if (this.props.type === "JOBS") {
          this.setState({
            jobTitle: this.props.data.jobTitle,
            text: this.props.data.jobDescription,
          });
        }
        this.setState({
          isLoading: false,
        });
      }
    );
  }
  NOTE_TYPES = [
    "Billing Note",
    "Drug and Alcohol Note",
    "Medical Note",
    "HR Note",
    "Result Notification Note",
    "General Note",
    "After-Hours Note",
  ];
  editEntity = async (key) => {
    try {
      this.setState({ isUpdating: true });
      let res;
      if (this.props.type === "JOBS") {
        res = await this.props.apiCallPost("/company/jobs/edit", {
          jobId: this.state._id,
          companyId: this.props.companyId,
          jobTitle: this.state.jobTitle,
          jobDescription: this.state.text,
        });
      } else {
        res = await this.props.apiCallPost("/notes/editEntity", {
          noteId: this.state._id,
          text: this.state.text,
          purpose: this.state.purpose,
        });
      }
      this.setState({ isUpdating: false, ...res });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Updating Note");
      this.setState({
        isUpdating: false,
        [key]: this.props.data[key],
      });
    }
  };
  renderIfUpdated = () => {
    if (!this.state.updatedBy) {
      return null;
    }
    return (
      <>
        <Box
          sx={{
            mx: 0.75,
            width: 2,
            height: 2,
            borderRadius: "50%",
            bgcolor: "currentColor",
          }}
        />
        Last Updated: {niceDateTime(this.state.updatedAt)}
      </>
    );
  };
  renderSearchOrText = () => {
    let opt = this.NOTE_TYPES;
    if (this.props.extraOptions && this.props.extraOptions.length > 0) {
      opt = [...opt, ...this.props.extraOptions];
    }
    if (this.props.type === "JOBS") {
      return (
        <TextField
          label="Job Title"
          onChange={(e) =>
            this.setState({
              jobTitle: e.target.value,
            })
          }
          value={this.state.jobTitle}
          onBlur={() => {
            this.editEntity("jobTitle");
          }}
        />
      );
    } else {
      return (
        <Autocomplete
          disableClearable
          value={{ label: this.state.purpose }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={opt.map((each, index) => {
            return { label: each, id: index };
          })}
          onChange={(_, newValue) => {
            if (typeof newValue === "string") {
              this.setState({ purpose: newValue }, () => {
                this.editEntity("purpose");
              });
            } else if (newValue && newValue.inputValue) {
              this.setState({ purpose: newValue.inputValue }, () => {
                this.editEntity("purpose");
              });
            } else {
              this.setState({ purpose: newValue.label }, () => {
                this.editEntity("purpose");
              });
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            const isExisting = options.some(
              (option) => inputValue === option.label
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                label: `Add "${inputValue}"`,
              });
            }
            return filtered;
          }}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.label;
          }}
          renderOption={(props, option) => <li {...props}>{option.label}</li>}
          sx={{ width: 300 }}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} label="Type of Note" />
          )}
        />
      );
    }
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <div>
        <Card
          sx={{
            bgcolor: "background.neutral",
          }}
        >
          <Stack
            spacing={2}
            sx={{
              p: 3,
            }}
          >
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
              }}
            >
              <Box
                rowGap={1}
                display="grid"
                gridTemplateColumns={{
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                }}
              >
                <ListItemText
                  primary={this.renderSearchOrText()}
                  secondary={
                    <>
                      Created: {niceDateTime(this.state.createdAt)} By{" "}
                      {this.state.createdBy.name} {this.renderIfUpdated()}
                    </>
                  }
                  primaryTypographyProps={{
                    typography: "title2 ",
                  }}
                  secondaryTypographyProps={{
                    mt: 0.5,
                    component: "span",
                    alignItems: "center",
                    typography: "caption",
                    color: "text.disabled",
                    display: "inline-flex",
                  }}
                />
                <GenericFileUploadHeader
                  entityType={this.props.type === "JOBS" ? "Job" : "Note"}
                  entityId={this.state._id}
                />
              </Box>
              <Box>
                <Editor
                  onEditorBlur={(e) => {
                    console.log("im blurred");
                    this.setState({ text: e }, () => {
                      this.editEntity("text");
                    });
                  }}
                  value={this.state.text}
                  noteId={`div_${this.state._id}`}
                />
              </Box>
            </Box>
          </Stack>
        </Card>
      </div>
    );
  }
}

export default WithAPICall(GenericNote);
