"use client";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Textarea } from "@/components/ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  MessageCircle,
  Clock,
  RefreshCw,
  User,
  ChevronRight,
} from "lucide-react";
import { format } from "date-fns";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Badge } from "@/components/ui/badge";
import { Drawer, DrawerContent, DrawerTrigger } from "@/components/ui/drawer";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

import useVisitStore from "@/store/useVisitStore";
import { useProtectedApi } from "@/hooks/use-apiCall";

/**
 * 
 *     //[
    // {
    //   _id: "1",
    //   comment:
    //     "Patient shows signs of improvement. Recommend continuing current treatment plan.",
    //   createdBy: "123", // Assuming this is the ObjectId for Dr. Smith
    //   createdByName: "Dr. Smith",
    //   createdAt: new Date("2023-06-10T09:30:00"),
    // },
    // {
    //   _id: "2",
    //   comment:
    //     "Administered prescribed medication. Patient reported feeling better after 30 minutes.",
    //   createdBy: "456", // Assuming this is the ObjectId for Nurse Johnson
    //   createdByName: "Nurse Johnson",
    //   createdAt: new Date("2023-06-10T11:45:00"),
    // },
    // {
    //   _id: "3",
    //   comment:
    //     "Reviewed latest test results. Everything looks good, but let's monitor for another 24 hours.",
    //   createdBy: "789", // Assuming this is the ObjectId for Dr. Lee
    //   createdByName: "Dr. Lee",
    //   createdAt: new Date("2023-06-10T14:20:00"),
    // },
    //]
 */

export function CommentThreadWithDrawer({ handleVisitStatusChange }) {
  const { visit, status } = useVisitStore();
  const { callApi } = useProtectedApi();

  const [comments, setComments] = useState(visit?.commentsThread || []);
  const [newComment, setNewComment] = useState("");
  const [visitStatus, setVisitStatus] = useState("In Progress");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const addComment = async (status) => {
    try {
      const resp = await callApi("/visit/addCommentToVisit", {
        visitId: visit._id,
        comment: newComment,
      });
      if (status) {
        handleVisitStatusChange(status);
      }
      setNewComment("");
      setComments([...resp.commentsThread]);
    } catch (err) {
      console.log(err);
    }
  };

  const latestComment = comments[comments.length - 1];

  const CommentThread = () => (
    <div className="space-y-4 mb-4 max-h-[calc(100vh-200px)] overflow-y-auto pr-2">
      {comments.map((comment, index) => (
        <div
          key={comment._id}
          // initial={{ opacity: 0, y: 10 }}
          // animate={{ opacity: 1, y: 0 }}
          // transition={{ duration: 0.2, delay: index * 0.05 }}
          className={`flex flex-col ${comment.createdBy !== "system" ? "items-end" : "items-start"}`}
        >
          <div
            className={`rounded-lg p-3 max-w-[85%] shadow-sm ${
              comment.createdBy !== "system"
                ? "bg-blue-50 text-blue-800"
                : "bg-gray-50 text-gray-800"
            }`}
          >
            <div className="flex justify-between items-center mb-1">
              <span className="text-xs font-medium">
                {comment.createdByName}
              </span>
              <span className="text-xs text-gray-500 ml-2">
                {format(new Date(comment.createdAt), "HH:mm")}
              </span>
            </div>
            <p className="text-sm">{comment.comment}</p>
          </div>
          <div className="flex items-center mt-1 text-xs text-gray-500">
            <Tooltip>
              <TooltipTrigger>
                <Clock className="w-3 h-3 mr-1" />
              </TooltipTrigger>
              <TooltipContent>Full Timestamp</TooltipContent>
            </Tooltip>
            {format(new Date(comment.createdAt), "MMM d, yyyy")}
          </div>
        </div>
      ))}
    </div>
  );

  if (status !== "loaded") return <div>Loading...</div>;

  return (
    <TooltipProvider>
      <Card className="w-full max-w-4xl mx-auto shadow-md bg-white">
        <CardContent className="p-4">
          <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
            <DrawerTrigger asChild>
              <div className="cursor-pointer">
                <div className="flex justify-between items-center mb-4">
                  {latestComment && (
                    <div>
                      <h2 className="text-xl font-semibold text-gray-800">
                        Latest Interaction
                      </h2>
                      <p className="text-sm text-gray-600 mt-1">
                        {latestComment.createdByName} -{" "}
                        {format(
                          new Date(latestComment.createdAt),
                          "MMM d, HH:mm"
                        )}
                      </p>
                      <p className="text-sm text-gray-700 mt-1 line-clamp-1">
                        {latestComment.comment}
                      </p>
                    </div>
                  )}
                  <div className="flex items-center">
                    <Badge variant="secondary" className="mr-2">
                      {comments.length} interactions
                    </Badge>
                    <ChevronRight className="w-5 h-5 text-gray-400" />
                  </div>
                </div>
              </div>
            </DrawerTrigger>
            <DrawerContent className="p-4 max-w-4xl mx-auto max-h-[80vh]">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold text-gray-800">
                  Communication Thread
                </h2>
                {/* <div className="flex items-center space-x-2">
                  <span className="text-sm text-gray-600">Visit Status:</span>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Select
                        value={visitStatus}
                        onValueChange={(value) => updateVisitStatus(value)}
                      >
                        <SelectTrigger className="w-[180px]">
                          <SelectValue placeholder="Select status" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="In Progress">
                            In Progress
                          </SelectItem>
                          <SelectItem value="Awaiting MD Review">
                            Awaiting MD Review
                          </SelectItem>
                          <SelectItem value="Awaiting RN Action">
                            Awaiting RN Action
                          </SelectItem>
                          <SelectItem value="Completed">Completed</SelectItem>
                        </SelectContent>
                      </Select>
                    </TooltipTrigger>
                    <TooltipContent>Update visit status</TooltipContent>
                  </Tooltip>
                </div> */}
              </div>
              <CommentThread />
              <div className="mt-4 bg-gray-50 rounded-md p-3">
                <Textarea
                  placeholder="Type your comment here..."
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  className="mb-3 resize-none border-gray-200 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  rows={3}
                />
                <div className="flex justify-between items-center">
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => addComment()}
                        className="text-gray-600 hover:bg-gray-100"
                      >
                        <MessageCircle className="w-4 h-4 mr-2" />
                        Comment
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      Send comment without updating status
                    </TooltipContent>
                  </Tooltip>
                  <div className="space-x-2">
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => addComment("IN_RN_REVIEW")}
                          className="bg-green-50 text-green-600 hover:bg-green-100 border-green-200"
                        >
                          <User className="w-4 h-4 mr-2" />
                          To RN
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        Send and update status to 'Awaiting RN Action'
                      </TooltipContent>
                    </Tooltip>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => addComment("IN_MD_REVIEW")}
                          className="bg-blue-50 text-blue-600 hover:bg-blue-100 border-blue-200"
                        >
                          <User className="w-4 h-4 mr-2" />
                          To MD
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        Send and update status to 'Awaiting MD Review'
                      </TooltipContent>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </DrawerContent>
          </Drawer>
        </CardContent>
      </Card>
    </TooltipProvider>
  );
}
