import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Alert,
  AlertTitle,
  Button,
  LinearProgress,
  Typography,
} from "@mui/material";
import { View500 } from "src/sections/error";
import cogoToast from "cogo-toast";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  timelineOppositeContentClasses,
} from "@mui/lab";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import { Stack } from "@mui/system";
import VisitTimelineEventCreate from "./VisitTimelineEventCreate";
import Iconify from "src/components/iconify";
import { niceDateTime } from "../utils/fn";
import _ from "lodash";

class VisitTimeline extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    events: [],
  };
  EVENT_MAPS = {
    "Visit Date & Time": {
      color: "secondary",
      icon: "mdi:calendar-clock",
    },
    "Employee Scheduling Preference Updated": {
      color: "info",
      icon: "material-symbols-light:room-preferences-outline-rounded",
    },
    "Authorization Sent": {
      color: "info",
      icon: "solar:document-broken",
    },
    "Data Received": {
      color: "success",
      icon: "ri:folder-received-line",
    },
    "Data Removed": {
      color: "error",
      icon: "mage:box-cross-fill",
    },
    "Visit Requested": {
      icon: "iconoir:system-restart",
      color: "success",
    },
    "Employee Outreach Attempt - Scheduling": {
      icon: "mage:calendar-question-mark-fill",
      color: "info",
    },
    "Employee Outreach Attempt - Documents": {
      icon: "solar:file-broken",
      color: "info",
    },
    "Provider Outreach Attempt": {
      icon: "fluent:document-question-mark-16-filled",
      color: "info",
    },
    "Rescheduling Request Received": {
      icon: "mdi:calendar-repeat-outline",
      color: "warning",
    },
    "Documents Received": {
      icon: "streamline:mail-incoming",
      color: "info",
    },
    "Documents Processed": {
      icon: "fluent-mdl2:process-meta-task",
      color: "warning",
    },
    "In Review": {
      icon: "carbon:review",
      color: "info",
    },
    "Documents Reviewed": {
      icon: "ant-design:file-done-outlined",
      color: "success",
    },
    "Visit Scheduled": {
      icon: "mdi:calendar-tick-outline",
      color: "success",
    },
    "Visit Cancelled": {
      icon: "material-symbols:cancel",
      color: "error",
    },
    "Visit Completed": {
      icon: "mdi:tick-circle",
      color: "success",
    },
    "Visit No Show": {
      icon: "mage:calendar-cross",
      color: "error",
    },
    "Service Clearance": {
      icon: "ri:health-book-line",
      color: "info",
    },
    "Protocol Clearance": {
      icon: "ri:health-book-line",
      color: "info",
    },
    "Medical Hold": {
      icon: "ri:health-book-line",
      color: "info",
    },
  };
  componentDidMount() {
    let eventsArr = [];
    let firstEvent = {
      statusFrom: "",
      statusTo: "REQUESTED",
      header: "Visit Requested",
      notes: "The visit was first requested/created.",
      creator: "User",
      createdBy: {
        name: this.props.visitData.requestedBy.name,
      },
      createdAt: this.props.visitData.createdAt,
    };
    let visitStatus = this.props.visitData.visitStatus;
    let hasNotBeenScheduled = ["REQUESTED", "RESCHEDULING_REQUESTED"].includes(
      visitStatus
    );
    eventsArr.push(firstEvent);
    if (!hasNotBeenScheduled) {
      let earliestTime = this.props.visitData.providerEncounterOnTime;
      let visitEvent = {
        header: "Visit Date & Time",
        notes: `The visit scheduled time ${niceDateTime(earliestTime)}`,
        createdAt: earliestTime,
      };
      eventsArr.push(visitEvent);
    }
    this.setState(
      {
        events: eventsArr,
      },
      () => {
        this.getTimelineEvents();
      }
    );
  }
  getTimelineEvents = async () => {
    try {
      const res = await this.props.apiCallPost("/visit/events/getByVisitId", {
        visitId: this.props.id,
      });
      // sort by createdAt
      let eventsArr = [...res, ...this.state.events];

      eventsArr.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
      this.setState({
        events: eventsArr,
        isLoading: false,
      });
    } catch (error) {
      this.setState({ isError: true, isLoading: false });
      cogoToast.error("Error fetching timeline events");
    }
  };

  renderTimeline = () => {
    let events = this.state.events;
    if (!events.length) {
      return <Alert severity="info">No events to display</Alert>;
    }
    return (
      <Timeline
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0.2,
          },
        }}
      >
        {events.map((event, eIdx) => {
          let eventHeader = event.header;
          if (eventHeader.includes("Employee Outreach Attempt - Scheduling")) {
            let countOfOutreachAttempts = events.filter((e) =>
              e.header.includes("Employee Outreach Attempt - Scheduling")
            );
            let idxOfCurrentEvent = countOfOutreachAttempts.findIndex(
              (e) => e._id === event._id
            );
            eventHeader = `Employee Outreach Attempt - Scheduling - ${idxOfCurrentEvent + 1}`;
          }
          if (eventHeader.includes("Employee Outreach Attempt - Documents")) {
            let countOfOutreachAttempts = events.filter((e) =>
              e.header.includes("Employee Outreach Attempt - Documents")
            );
            let idxOfCurrentEvent = countOfOutreachAttempts.findIndex(
              (e) => e._id === event._id
            );
            eventHeader = `Employee Outreach Attempt - Documents - ${idxOfCurrentEvent + 1}`;
          }
          let obj = this.EVENT_MAPS[event.header];
          return (
            <TimelineItem key={eIdx}>
              <TimelineOppositeContent
                align="right"
                variant="body2"
                color="text.secondary"
                sx={{
                  pt: 1,
                }}
              >
                <Stack spacing={0}>
                  <Typography variant="overline">
                    {niceDateTime(event.createdAt)}
                  </Typography>{" "}
                  <Typography variant="caption">
                    {event.createdBy?.name}
                  </Typography>
                </Stack>
              </TimelineOppositeContent>{" "}
              <TimelineSeparator>
                <TimelineDot color={obj?.color}>
                  <Iconify icon={obj?.icon} />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Alert severity={obj?.color} icon={false}>
                  <AlertTitle> {eventHeader}</AlertTitle>
                  {event.notes}
                </Alert>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    );
  };
  renderHeader = () => {
    return (
      <CustomBreadcrumbs
        heading="Visit Timeline"
        links={[{ name: "Timeline of Events" }]}
        action={
          <Stack spacing={1} direction={"row"}>
            <Button
              disabled={this.state.isLoading}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
              onClick={() => {
                this.setState({
                  isNewLogOpen: true,
                });
              }}
            >
              Log New Event
            </Button>
          </Stack>
        }
      />
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <Stack>
        <VisitTimelineEventCreate
          visitId={this.props.id}
          isOpen={this.state.isNewLogOpen}
          handleClose={() => {
            this.setState({ isNewLogOpen: false });
          }}
          onCreate={(event) => {
            this.setState({
              events: [...this.state.events, event],
              isNewLogOpen: false,
            });
            this.props.onStatusChange(event);
          }}
        />
        {this.renderHeader()}
        <Stack>{this.renderTimeline()}</Stack>
      </Stack>
    );
  }
}

export default WithAPICall(VisitTimeline);
