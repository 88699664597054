import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Alert,
  IconButton,
  LinearProgress,
  ListItemText,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import Iconify from "src/components/iconify";
import { NoResultView } from "src/sections/error";
import cogoToast from "cogo-toast";

class ProviderNetworkSuppliesProvided extends React.Component {
  state = { isLoading: true, suppliesProvided: [] };
  componentDidMount() {
    this.setState({
      ...this.props.data,
      isLoading: false,
    });
  }
  updateScalarValue = async (key, value) => {
    if (value === null || value === undefined) {
      return;
    }

    try {
      this.setState({
        isSaving: true,
      });
      await this.props.apiCallPost(
        "/provider/providerNetworkUpdateScalarValue",
        {
          networkId: this.props.id,
          key: key,
          value: value,
        }
      );
      this.setState({
        isSaving: false,
      });
    } catch (err) {
      this.setState({
        isSaving: false,
        [key]: this.props.data[key],
      });
      cogoToast.error("Error Updating Provider Network Data");
      console.log(err);
    }
  };
  renderSuppliesHeader = () => {
    return (
      <Stack spacing={3}>
        <ListItemText
          sx={{
            p: 3,
            borderRadius: 2,
            bgcolor: "background.neutral",
          }}
          primary={
            <Stack direction="row" alignItems="center" spacing={3}>
              <Typography variant="h6">Lab Supplies</Typography>
              <IconButton
                disabled={this.state.isSaving}
                size="small"
                color="primary"
                onClick={() => {
                  let newObj = {
                    supplyType: "",
                    isCompanySpecific: false,
                    costPerUnitUSD: 1,
                  };
                  this.setState(
                    {
                      suppliesProvided: [
                        ...this.state.suppliesProvided,
                        newObj,
                      ],
                    },
                    () => {
                      this.updateScalarValue(
                        "suppliesProvided",
                        this.state.suppliesProvided
                      );
                    }
                  );
                }}
                sx={{
                  width: 24,
                  height: 24,
                  bgcolor: "primary.main",
                  color: "primary.contrastText",
                  "&:hover": {
                    bgcolor: "primary.dark",
                  },
                }}
              >
                <Iconify icon="mingcute:add-line" />
              </IconButton>
            </Stack>
          }
          secondary="Define the different types of supplies that are provided by this lab."
          primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
          secondaryTypographyProps={{ component: "span" }}
        />
      </Stack>
    );
  };
  renderTable = () => {
    let list = this.state.suppliesProvided;
    if (!list || list.length === 0) {
      return <NoResultView />;
    }
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Supply Type</TableCell>{" "}
            <TableCell>Is Company Specific?</TableCell>
            <TableCell>Cost Per Unit (USD)</TableCell>{" "}
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row, index) => {
            return (
              <TableRow>
                <TableCell>{this.renderSupplyType(row, index)}</TableCell>
                <TableCell>{this.renderCompanySpecific(row, index)}</TableCell>
                <TableCell>{this.renderCost(row, index)}</TableCell>
                <TableCell>
                  <IconButton
                    disabled={this.state.isSaving}
                    size="small"
                    color="error"
                    onClick={() => {
                      let list = this.state.suppliesProvided;
                      list.splice(index, 1);
                      this.setState({ suppliesProvided: list }, () => {
                        this.updateScalarValue(
                          "suppliesProvided",
                          this.state.suppliesProvided
                        );
                      });
                    }}
                    sx={{
                      width: 24,
                      height: 24,
                      bgcolor: "error.main",
                      color: "error.contrastText",
                      "&:hover": {
                        bgcolor: "error.dark",
                      },
                    }}
                  >
                    <Iconify icon="solar:trash-bin-trash-broken" />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };
  renderSupplyType = (row, index) => {
    return (
      <TextField
        disabled={this.state.isSaving}
        label="Supply Type"
        value={row.supplyType}
        onChange={(e) => {
          let list = this.state.suppliesProvided;
          list[index].supplyType = e.target.value;
          this.setState({ suppliesProvided: list });
        }}
        onBlur={() => {
          this.updateScalarValue(
            "suppliesProvided",
            this.state.suppliesProvided
          );
        }}
      />
    );
  };
  renderCompanySpecific = (row, index) => {
    return (
      <Switch
        disabled={this.state.isSaving}
        checked={row.isCompanySpecific}
        onChange={() => {
          let list = this.state.suppliesProvided;
          list[index].isCompanySpecific = !list[index].isCompanySpecific;
          this.setState({ suppliesProvided: list }, () => {
            this.updateScalarValue(
              "suppliesProvided",
              this.state.suppliesProvided
            );
          });
        }}
      />
    );
  };
  renderCost = (row, index) => {
    return (
      <TextField
        disabled={this.state.isSaving}
        label="Cost Per Unit (USD)"
        value={row.costPerUnitUSD}
        onChange={(e) => {
          let list = this.state.suppliesProvided;
          list[index].costPerUnitUSD = e.target.value;
          this.setState({ suppliesProvided: list });
        }}
        onBlur={() => {
          let list = this.state.suppliesProvided;
          list[index].costPerUnitUSD = parseFloat(list[index].costPerUnitUSD);
          this.setState({ suppliesProvided: list }, () => {
            this.updateScalarValue(
              "suppliesProvided",
              this.state.suppliesProvided
            );
          });
        }}
      />
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    let isLab = this.props.data.networkProviderType === "Lab";
    if (!isLab) {
      return (
        <Alert severity="info">This section is only applicable to Labs</Alert>
      );
    }
    return (
      <div>
        <Stack spacing={2}>
          {this.renderSuppliesHeader()}
          {this.renderTable()}
        </Stack>
      </div>
    );
  }
}

export default WithAPICall(ProviderNetworkSuppliesProvided);
