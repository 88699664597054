import React from "react";
import RecordRTC from "recordrtc";
import { WithAPICall } from "../utils/apiUtil";
import { Button, Chip, LinearProgress, Typography } from "@mui/material";
import cogoToast from "cogo-toast";
import { Stack } from "@mui/system";
import Iconify from "src/components/iconify";
import { niceDateTime } from "./fn";

class AudioBox extends React.Component {
  state = {
    isRecording: false,
    isPaused: false,
    recordedBlob: null,
    recordingTime: 0,
    isUploading: false,
    uploadingProgress: 0,
    isTranscribing: false,
    transcription: "",
    isInFlux: false,
    cleanedUpTranscript: "",
  };
  componentDidMount() {
    this.startRecording();
  }
  uploadFile = async () => {
    try {
      let fd = new FormData();
      fd.append("file", this.state.recordedBlob);
      fd.append("entityType", this.props.entityType);
      fd.append("entityId", this.props.entityId);
      fd.append("description", `Voice Note ${niceDateTime(new Date())}`);
      let r = await this.props.apiCallPostUpload(
        "/files/addToEntity",
        fd,
        (progress) => {
          this.setState({
            isUploading: true,
            uploadingProgress: progress,
          });
        }
      );
      this.setState(
        {
          isUploading: false,
          uploadingProgress: 0,
          recordedBlob: null,
          recordingTime: 0,
          fileId: r._id,
        },
        () => {
          this.transcribe();
        }
      );
    } catch (error) {
      cogoToast.error("Failed to upload audio.");
      this.props.onError();
      console.log(error);
    }
  };
  transcribe = async () => {
    this.setState({ isTranscribing: true });
    try {
      let r = await this.props.apiCallPost("/external/audio/transcribe", {
        cfioId: this.state.fileId,
        prompt: "Transcribe:",
      });
      this.setState({ transcription: r.text, isTranscribing: false }, () => {
        this.props.onTranscription(this.state.transcription);
      });
    } catch (error) {
      cogoToast.error("Failed to transcribe audio.");
      this.props.onError();
      console.log(error);
    }
  };

  startRecording = () => {
    this.setState({ isRecording: true, isPaused: false });
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        let options = {
          type: "audio",
          mimeType: "audio/webm",
          disableLogs: true,
          timeSlice: 1000,
          ondataavailable: (blob) => {},
        };

        const isSafari = /^((?!chrome|android).)*safari/i.test(
          navigator.userAgent
        );
        if (isSafari) {
          options.mimeType = "audio/mp4";
        }

        this.recordRTC = new RecordRTC(stream, options);
        this.recordRTC.startRecording();
        this.startTimer();
      })
      .catch((err) => {
        cogoToast.error("Failed to start recording.");
      });
  };
  stopRecording = () => {
    this.setState({
      isInFlux: true,
    });
    this.stopTimer();
    this.recordRTC.stopRecording(() => {
      let blob = this.recordRTC.getBlob();

      this.setState(
        {
          isInFlux: false,
          recordedBlob: blob,
          isRecording: false,
          isPaused: false,
        },
        () => {
          this.uploadFile();
        }
      );
    });
  };
  pauseRecording = () => {
    this.setState({ isPaused: true });
    this.recordRTC.pauseRecording();
    this.stopTimer();
  };
  resumeRecording = () => {
    this.setState({ isPaused: false });
    this.recordRTC.resumeRecording();
    this.startTimer();
  };
  startTimer = () => {
    this.timerID = setInterval(() => {
      this.setState({ recordingTime: this.state.recordingTime + 1 });
    }, 1000);
  };
  stopTimer = () => {
    clearInterval(this.timerID);
    this.timerID = null;
  };
  formatTime = (time) => {
    let minutes = Math.floor(time / 60);
    let seconds = time % 60;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${minutes}:${seconds}`;
  };
  renderControls = () => {
    if (this.state.isInFlux) {
      return (
        <Stack spacing={2}>
          <Typography variant="overline">Processing Audio</Typography>
          <LinearProgress />
        </Stack>
      );
    }
    if (this.state.isUploading) {
      return (
        <Stack spacing={2}>
          <Typography variant="overline">
            Uploading Audio: {this.state.uploadingProgress}%
          </Typography>
          <LinearProgress
            variant="determinate"
            value={this.state.uploadingProgress}
          />
        </Stack>
      );
    }
    if (this.state.isTranscribing) {
      return (
        <Stack spacing={2}>
          <Typography variant="overline">Transcribing Audio</Typography>
          <LinearProgress />
        </Stack>
      );
    }

    let stopButton = (
      <Button
        size="small"
        onClick={this.stopRecording}
        variant="contained"
        startIcon={<Iconify icon="ri:stop-fill" />}
      >
        Stop
      </Button>
    );
    if (!this.state.isRecording) {
      return (
        <Button
          size="small"
          onClick={this.startRecording}
          variant="contained"
          startIcon={<Iconify icon="mingcute:mic-fill" />}
        >
          Start
        </Button>
      );
    } else {
      if (this.state.isPaused) {
        return (
          <Stack
            direction="row"
            spacing={0.5}
            alignItems={"center"}
            flexWrap={"wrap"}
          >
            <Chip
              size="small"
              icon={<Iconify icon="bi:pause-fill" />}
              color="warning"
              label={`Paused: ${this.formatTime(this.state.recordingTime)}`}
            />
            <Button
              size="small"
              onClick={this.resumeRecording}
              variant="contained"
            >
              Resume
            </Button>
            {stopButton}
          </Stack>
        );
      } else {
        return (
          <Stack
            direction="row"
            spacing={2}
            alignItems={"center"}
            flexWrap={"wrap"}
          >
            <Chip
              size="small"
              icon={<Iconify icon="svg-spinners:pulse" />}
              color="secondary"
              label={`Listening: ${this.formatTime(this.state.recordingTime)}`}
            />
            <Button
              size="small"
              onClick={this.pauseRecording}
              variant="contained"
              startIcon={<Iconify icon="zondicons:pause-solid" />}
            >
              Pause
            </Button>
            {stopButton}
          </Stack>
        );
      }
    }
  };
  render() {
    return <Stack spacing={2}>{this.renderControls()}</Stack>;
  }
}

export default WithAPICall(AudioBox);
