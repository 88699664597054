import React, { useState } from "react";
import { Button, Typography, Box, Paper, Divider } from "@mui/material";
import { Stack } from "@mui/system";

import _ from "lodash";
import { niceDateTimeTZ, niceDateTZ } from "../utils/fn";
import DrugScreenClearer from "./Clearers/DrugScreenClearer";
import AlcoholClearer from "./Clearers/AlcoholClearer";
// Define the workflow stages, their possible next steps, and help text
const workflowStages = {
  INITIAL_DETERMINATION: {
    next: [
      "ACKNOWLEDGE_NEGATIVE",
      "ACKNOWLEDGE_ADMIN_FAILURE",
      "ACKNOWLEDGE_ADMIN_FAILURE_CORRECTABLE",
      "ACKNOWLEDGE_NON_NEGATIVE",
    ],
    helpText:
      "Based on review of CoC and Lab Results, the following actions can be taken:",
    canIssueClearance: false,
  },
  ACKNOWLEDGE_NEGATIVE: {
    next: [],
    helpText:
      "Acknowledgement of a negative result. The clearance can be generated. No further action is required.",
    canIssueClearance: true,
    blurb:
      "Negative drug screening results, no errors in CoC or other documentation.",
  },
  ACKNOWLEDGE_ADMIN_FAILURE: {
    next: [],
    helpText:
      "Acknowledgement of an administrative failure due to mismatch in Specimen ID or missing name/signature of Collector in CoC. A clearance cannot be generated. Advise a new booking and a new specimen to be collected.",
    canIssueClearance: false,
    blurb: "Fatal error in CoC or other documentation.",
  },
  ACKNOWLEDGE_ADMIN_FAILURE_CORRECTABLE: {
    next: ["OUTREACH_AFFIDAVIT"],
    helpText:
      "Acknowledgement of an correctable administrative failure due to missing Donor signature or missing name/signature of Collector. A clearance can be generated only if all results are negative. Apply for an Affidavit.",
    canIssueClearance: false,
    blurb: "Error in CoC or other documentation, can proceed via an affidavit.",
  },
  OUTREACH_AFFIDAVIT: {
    next: ["AFFIDAVIT_RECEIVED"],
    helpText:
      "Affidavit has been requested. If Affidavit is not received, proceed to first outreach attempt.",
    canIssueClearance: false,
    blurb: "Request an affidavit.",
  },
  AFFIDAVIT_RECEIVED: {
    next: [],
    helpText: "Affidavit received. A clearance can be issued",
    canIssueClearance: true,
    blurb: "Affidavit received.",
  },

  ACKNOWLEDGE_NON_NEGATIVE: {
    next: ["OUTREACH_ATTEMPT_1"],
    helpText:
      "Initial acknowledgment of a non-negative result. Proceed to first outreach attempt.",
    canIssueClearance: false,
    blurb: "Non negative drug screening results.",
    startClock: "donorOutreachClockStartedAt",
  },
  OUTREACH_ATTEMPT_1: {
    next: ["OUTREACH_ATTEMPT_2", "MRO_ACCEPT_EVIDENCE", "MRO_DENY_EVIDENCE"],
    helpText:
      "First attempt to contact the individual. If unsuccessful, proceed to second attempt.",
    canIssueClearance: false,
    blurb: "Make first attempt to contact the individual.",
  },
  OUTREACH_ATTEMPT_2: {
    next: ["OUTREACH_ATTEMPT_3", "MRO_ACCEPT_EVIDENCE", "MRO_DENY_EVIDENCE"],
    helpText:
      "Second attempt to contact the individual. If unsuccessful, proceed to third attempt.",
    canIssueClearance: false,
    blurb: "Make second attempt to contact the individual.",
  },
  OUTREACH_ATTEMPT_3: {
    next: ["MRO_OUTREACH_FAILED", "MRO_ACCEPT_EVIDENCE", "MRO_DENY_EVIDENCE"],
    helpText:
      "Final attempt to contact. Decide whether to accept or deny evidence based on the outcome.",
    canIssueClearance: false,
    blurb: "Make third attempt to contact the individual.",
  },
  MRO_OUTREACH_FAILED: {
    next: [],
    helpText:
      "Final attempt to contact failed. Contact the DER after 24 hours.",
    canIssueClearance: false,
    blurb: "Final attempt to contact Donor failed. Contact the DER.",
    startClock: "derOutreachClockStartedAt",
  },
  MRO_ACCEPT_EVIDENCE: {
    next: ["MRO_AWAITING_DOCUMENTS"],
    helpText:
      "Evidence accepted. Await necessary documents from the individual.",
    canIssueClearance: false,
    blurb: "Accept evidence.",
  },
  MRO_DENY_EVIDENCE: {
    next: [],
    helpText: "Evidence denied. This is a terminal state in the workflow.",
    canIssueClearance: true,
    blurb: "Deny evidence.",
  },
  MRO_AWAITING_DOCUMENTS: {
    next: ["AWAITING_SPLIT_SAMPLE_PAYMENT"],
    helpText: "Documents received. Proceed to request split sample payment.",
    canIssueClearance: false,
    blurb: "Await documents.",
    startClock: "splitSampleProcessingClockStartedAt",
  },
  AWAITING_SPLIT_SAMPLE_PAYMENT: {
    next: ["AWAITING_SPLIT_SAMPLE_LAB_REPORT"],
    helpText: "Payment received. Await lab report for the split sample.",
    canIssueClearance: false,
    blurb: "Await split sample payment.",
  },
  AWAITING_SPLIT_SAMPLE_LAB_REPORT: {
    next: [],
    helpText:
      "Awaiting final lab report. This is the last step in the process.",
    canIssueClearance: true,
    blurb: "Await lab report from testing the split sample.",
  },
};

const getStdWorkflow = (groups, service) => {
  console.log("getStdWorkflow", groups, service._id);
  const g = _.find(
    groups,
    (g) => g.pageDataType === "LAB_REPORT" && g.service._id === service._id
  );
  console.log("g", g);
  if (!g) return "INITIAL_DETERMINATION";
  const hasAllNegative = _.every(g.dataStructured, (d) => d.isNormal == true);
  console.log({ hasAllNegative });
  if (hasAllNegative) return "ACKNOWLEDGE_NEGATIVE";
  return "INITIAL_DETERMINATION";
};

export const VisitProcessDrugTracker = ({
  name,
  visit,
  protocol,
  service,
  groups,
  doUpdateVisitDrugProcessStatus,
  doUpdateVisitClearance,
}) => {
  const savedCO = visit.drugProcessStatus[service._id];

  const [currentStatus, setCurrentStatus] = useState(
    _.get(savedCO, "status", getStdWorkflow(groups, service))
  );

  const handleStatusChange = async (nextStatus) => {
    const nextWfStage = workflowStages[nextStatus];
    // await onStatusChange(nextStatus, nextWfStage.startClock || null);
    await doUpdateVisitDrugProcessStatus(
      service._id,
      nextStatus,
      nextWfStage.startClock || null
    );
    setCurrentStatus(nextStatus);
  };

  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Typography variant="overline" gutterBottom>
        D&A Process Current Status for {name}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {currentStatus.replace(/_/g, " ")}
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Stack spacing={1} direction="row">
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" color="text.secondary" paragraph>
            {workflowStages[currentStatus].helpText}
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            Possible Next Steps:
          </Typography>

          {workflowStages[currentStatus].next.map((nextStatus) => (
            <Stack
              direction="row"
              spacing={1}
              key={nextStatus}
              style={{
                alignItems: "baseline",
              }}
            >
              <Button
                key={nextStatus}
                variant="outlined"
                onClick={() => handleStatusChange(nextStatus)}
                sx={{ mr: 1, mb: 1 }}
              >
                {nextStatus.replace(/_/g, " ")}
              </Button>
              {workflowStages[nextStatus].blurb && (
                <Typography variant="caption" display="block">
                  {workflowStages[nextStatus].blurb}
                </Typography>
              )}
            </Stack>
          ))}
          {workflowStages[currentStatus].next.length === 0 && (
            <Typography variant="body2" color="text.secondary">
              No further actions available.
            </Typography>
          )}
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" color="text.secondary" paragraph>
            Clocks:
          </Typography>

          {_.get(savedCO, "donorOutreachClockStartedAt") && (
            <Typography variant="caption" display="block">
              Donor Outreach Clock Started At:{" "}
              {niceDateTimeTZ(new Date(savedCO.donorOutreachClockStartedAt))}
            </Typography>
          )}

          {_.get(savedCO, "derOutreachClockStartedAt") && (
            <Typography variant="caption" display="block">
              DER Outreach Clock Started At:{" "}
              {niceDateTimeTZ(new Date(savedCO.derOutreachClockStartedAt))}
            </Typography>
          )}

          {_.get(savedCO, "splitSampleProcessingClockStartedAt") && (
            <Typography variant="caption" display="block">
              Split Sample Processing Clock Started At:{" "}
              {niceDateTimeTZ(
                new Date(savedCO.splitSampleProcessingClockStartedAt)
              )}
            </Typography>
          )}
        </Box>
      </Stack>
      <Divider sx={{ my: 2 }} />
      {workflowStages[currentStatus].canIssueClearance && (
        <DrugScreenClearer
          name=""
          visit={visit}
          protocol={protocol}
          service={service}
          groups={groups}
          onClearanceChange={(
            clearance,
            typeOfClearance,
            clearanceCase,
            selections
          ) => {
            doUpdateVisitClearance(
              "DrugAlcohol",
              service._id, // use service _id
              clearance,
              typeOfClearance,
              clearanceCase,
              selections,
              ""
            );
          }}
        />
      )}
    </Paper>
  );
};
