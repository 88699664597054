"use client";
import React, { useState } from "react";
import {
  Pill,
  Beaker,
  Stethoscope,
  AlertTriangle,
  CheckCircle,
  XCircle,
  AlertCircle,
} from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Label } from "@/components/ui/label";

import { useProtectedApi } from "@/hooks/use-apiCall";
import useVisitStore from "@/store/useVisitStore";

import { MedicalClearanceScreen } from "./MedicalClearance";
import { AlcoholClearanceScreen, AlcoholTestMother } from "./AlcoholClearance";
import { DrugScreenMother } from "./DrugScreenMother";

const statusConfig = {
  disabled: { color: "text-gray-400 bg-gray-100", tooltip: "Not applicable" },
  warning: { color: "text-amber-700 bg-amber-50", tooltip: "Not cleared" },
  success: { color: "text-green-700 bg-green-50", tooltip: "Cleared" },
  waiting: { color: "text-blue-700 bg-blue-50", tooltip: "Not processed yet" },
};

const clearanceCaseIcons = {
  Cleared: <CheckCircle className="w-5 h-5 text-green-500" />,
  "Not Cleared": <XCircle className="w-5 h-5 text-red-500" />,
  "Cleared With Restrictions": (
    <AlertCircle className="w-5 h-5 text-amber-500" />
  ),
  "Not Applicable": <AlertTriangle className="w-5 h-5 text-gray-500" />,
};

const TabButton = ({ tab, isActive, onClick }) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>
        <button
          className={`flex-1 px-6 py-3 text-sm font-medium transition-colors duration-200 ease-in-out
            ${isActive ? "border-b-2 border-primary" : "hover:bg-gray-50"}
            ${statusConfig[tab.status].color}`}
          onClick={onClick}
          disabled={tab.status === "disabled"}
        >
          <div className="flex items-center justify-center space-x-2">
            {tab.icon}
            <span>{tab.label}</span>
          </div>
        </button>
      </TooltipTrigger>
      <TooltipContent>
        <p>{statusConfig[tab.status].tooltip}</p>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

const ClearanceStatusSelect = ({ value, onChange }) => (
  <div className="space-y-2">
    <Label
      htmlFor="clearance-case"
      className="text-sm font-medium text-gray-700"
    >
      Clearance Status
    </Label>
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Select value={value} onValueChange={onChange}>
            <SelectTrigger id="clearance-case" className="w-full">
              <SelectValue placeholder="Select clearance case" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Cleared">Cleared</SelectItem>
              <SelectItem value="Not Cleared">Not Cleared</SelectItem>
              <SelectItem value="Cleared With Restrictions">
                Cleared With Restrictions
              </SelectItem>
            </SelectContent>
          </Select>
        </TooltipTrigger>
        <TooltipContent>
          <p>Select the clearance status for this case</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  </div>
);

const ClearanceAlert = ({ clearanceCase }) => (
  <Alert variant={clearanceCase === "Cleared" ? "default" : "destructive"}>
    <div className="flex items-center space-x-2">
      {clearanceCaseIcons[clearanceCase]}
      <AlertTitle>{clearanceCase}</AlertTitle>
    </div>
    <AlertDescription>
      {clearanceCase === "Cleared" &&
        "The employee has been cleared for this category."}
      {clearanceCase === "Not Cleared" &&
        "The employee has not been cleared for this category."}
      {clearanceCase === "Cleared With Restrictions" &&
        "The employee has been cleared with certain restrictions."}
    </AlertDescription>
  </Alert>
);

const Restrictions = () => (
  <div className="bg-amber-50 border border-amber-200 rounded-md p-4">
    <h3 className="text-sm font-medium text-amber-800 mb-2">Restrictions:</h3>
    <ul className="list-disc list-inside text-sm text-amber-700 space-y-1">
      <li>Restriction 1 details</li>
      <li>Restriction 2 details</li>
      <li>Restriction 3 details</li>
    </ul>
  </div>
);

const getCurrentClearanceStatus = (visit, protocolId) => {
  const services = visit.servicesInfo;
  const serviceParentProtocols = visit.serviceParentProtocols;

  const childServices = services.filter(
    (s) => serviceParentProtocols[s._id] === protocolId
  );

  const childDrugServices = childServices.filter(
    (s) => s.serviceType === "DRUG"
  );

  const childAlcoholServices = childServices.filter(
    (s) => s.serviceType === "ALCOHOL"
  );

  const childMedicalServices = childServices.filter(
    (s) =>
      s.serviceRequiresProviderType === "Clinic" &&
      s.serviceType !== "DRUG" &&
      s.serviceType !== "ALCOHOL"
  );

  const requiresMedicalClearance = childMedicalServices.length > 0;
  const requiresDrugClearance = childDrugServices.length > 0;
  const requiresAlcoholClearance = childAlcoholServices.length > 0;

  let medicalClearanceStatus = null;
  if (requiresMedicalClearance) {
    medicalClearanceStatus = visit.protocolClearance[protocolId];
  }

  return {
    childDrugServices,
    childAlcoholServices,
    childMedicalServices,
    requiresMedicalClearance,
    requiresDrugClearance,
    requiresAlcoholClearance,
    medicalClearanceStatus,
    drugAlcoholClearanceStatus: visit.drugAlcoholClearance, // map from drug svc _id
    alcoholClearanceStatus: visit.alcoholClearance, // newly introduced (TODO: migrate)
  };
};

export function ProtocolClearanceMother({ protocolId, medicalRecords }) {
  const { callApi } = useProtectedApi();
  const { visit, loadVisit } = useVisitStore();

  const [activeTab, setActiveTab] = useState(0);
  // const [clearanceCase, setClearanceCase] = useState("Not Cleared");

  const protocol = visit.protocolsInfo.find((p) => p._id === protocolId);
  const currentStatus = getCurrentClearanceStatus(visit, protocolId);

  const tabData = [
    {
      label: "Drug",
      icon: <Pill className="w-4 h-4" />,
      status: "warning",
      applicable: true,
      component: (currentStatus) => {
        if (currentStatus.requiresDrugClearance) {
          return (
            <>
              <DrugScreenMother
                currentStatus={currentStatus}
                protocolId={protocolId}
                medicalRecords={medicalRecords}
              />
            </>
          );
        } else {
          return (
            <Alert>
              <AlertTriangle className="w-5 h-5" />
              <AlertTitle>Not Applicable</AlertTitle>
              <AlertDescription>
                Drug clearance is not applicable for this visit.
              </AlertDescription>
            </Alert>
          );
        }
      },
    },
    {
      label: "Alcohol",
      icon: <Beaker className="w-4 h-4" />,
      status: "success",
      applicable: true,
      component: (currentStatus) => {
        if (currentStatus.requiresAlcoholClearance) {
          return (
            <AlcoholTestMother
              currentStatus={currentStatus}
              protocolId={protocolId}
              medicalRecords={medicalRecords}
            />
          );
        } else {
          return (
            <Alert>
              <AlertTriangle className="w-5 h-5" />
              <AlertTitle>Not Applicable</AlertTitle>
              <AlertDescription>
                Alcohol clearance is not applicable for this visit.
              </AlertDescription>
            </Alert>
          );
        }
      },
    },
    {
      label: "Medical",
      icon: <Stethoscope className="w-4 h-4" />,
      status: "waiting",
      applicable: true,
      component: (currentStatus) => {
        if (currentStatus.requiresMedicalClearance) {
          return (
            <MedicalClearanceScreen
              currentStatus={currentStatus}
              protocolId={protocolId}
              medicalRecords={medicalRecords}
            />
          );
        } else {
          return (
            <Alert>
              <AlertTriangle className="w-5 h-5" />
              <AlertTitle>Not Applicable</AlertTitle>
              <AlertDescription>
                Medical clearance is not applicable for this visit.
              </AlertDescription>
            </Alert>
          );
        }
      },
    },
  ];

  const activeTabData = tabData[activeTab];

  return (
    <div className="w-full max-w-4xl mx-auto bg-white rounded-lg shadow-md overflow-hidden">
      <div className="flex border-b">
        {tabData.map((tab, index) => (
          <TabButton
            key={tab.label}
            tab={tab}
            isActive={activeTab === index}
            onClick={() => setActiveTab(index)}
          />
        ))}
      </div>
      <div className="p-1 space-y-1">
        {activeTabData.component(currentStatus)}
      </div>
    </div>
  );
}
