import React from "react";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Unstable_Grid2";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Alert,
  Button,
  Chip,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Iconify from "src/components/iconify";
import { WithAPICall } from "../utils/apiUtil";
import { View500 } from "src/sections/error";
import CSFSubFieldCreate from "./CSFSubFieldCreate";
import cogoToast from "cogo-toast";
import _, { cloneDeep } from "lodash";

class CSFIndividual extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    openSubfieldCreate: false,
    fieldDataFields: [],
    isDeletingField: false,
  };
  componentDidMount() {
    this.setState(
      {
        isLoading: false,
        ...this.props.data,
      },
      () => {
        console.log("STATE FIELDS", this.state);
      }
    );
  }
  NICE_TYPES = {
    string: "Text",
    number: "Number",
    boolean: "Yes/No",
    array: "List of options",
    date: "Date",
  };
  editCSFValue = async (key) => {
    try {
      await this.props.apiCallPost("/company/csf/edit", {
        csfId: this.props.data._id,
        companyId: this.props.companyId,
        key: key,
        value: this.state[key],
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Editing Value");
    }
  };
  editCSFSubfieldValue = async (subIndex, key) => {
    try {
      let sf = this.state.fieldDataFields[subIndex];
      await this.props.apiCallPost("/company/csf/subfield/edit", {
        csfId: this.props.data._id,
        companyId: this.props.companyId,
        csfSubFieldId: sf._id,
        key: key,
        value: sf[key],
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Editing Value");
    }
  };
  deleteCsfSubfield = async ({ csfSubFieldId }) => {
    /**
     *  companyId: string;
      csfId: string;
      csfSubFieldId: string;
     */
    this.setState({ isDeletingField: true });
    try {
      const payload = {
        csfSubFieldId,
        companyId: this.state.companyId,
        csfId: this.state._id,
      };
      const res = await this.props.apiCallPost(
        `/company/csf/subfield/delete`,
        payload
      );
      this.setState((prevState) => {
        const subfields = cloneDeep(prevState.fieldDataFields).filter(
          (f) => f._id !== csfSubFieldId
        );
        return { ...prevState, fieldDataFields: subfields };
      });
      cogoToast.success("Deleted subfield successfully.");
    } catch (error) {
      console.error("error in deleting subfield", error);
      cogoToast.error("Could not delete subfield.");
    }

    this.setState({ isDeletingField: false });
  };
  renderFieldsIfLookup = () => {
    if (!this.state.needsLookUp) {
      return null;
    }
    let label = "Please add subfields first to begin mapping.";
    let options = [];
    if (this.state.fieldDataFields && this.state.fieldDataFields.length) {
      label = (
        <>
          Map <b>{this.state.employeeSourceKey}</b> to subfield
        </>
      );
      options = this.state.fieldDataFields.map(
        (each) => each.fieldDataFieldName
      );
    }
    return (
      <Stack>
        {" "}
        <FormControlLabel
          label={label}
          labelPlacement="start"
          control={
            <Select
              disabled={
                !this.state.fieldDataFields ||
                !this.state.fieldDataFields.length
              }
              value={this.state.lookUpField}
              onChange={(e) => {
                this.setState({ lookUpField: e.target.value }, () => {
                  this.editCSFValue("lookUpField");
                });
              }}
            >
              {options.map((each, ix) => {
                return (
                  <MenuItem value={each} key={ix}>
                    {each}
                  </MenuItem>
                );
              })}
            </Select>
          }
          sx={{
            m: 0,
            width: 1,
            justifyContent: "space-between",
          }}
        />{" "}
        <FormControlLabel
          label="Choose Descriptor"
          labelPlacement="start"
          control={
            <Select
              disabled={
                !this.state.fieldDataFields ||
                !this.state.fieldDataFields.length
              }
              value={this.state.descriptorField}
              onChange={(e) => {
                this.setState({ descriptorField: e.target.value }, () => {
                  this.editCSFValue("descriptorField");
                });
              }}
            >
              {options.map((each, ix) => {
                return (
                  <MenuItem value={each} key={ix}>
                    {each}
                  </MenuItem>
                );
              })}
            </Select>
          }
          sx={{
            m: 0,
            width: 1,
            justifyContent: "space-between",
          }}
        />
      </Stack>
    );
  };
  renderIfArray = (subIndex) => {
    let fdf = this.state.fieldDataFields[subIndex];
    if (fdf.dataType !== "array") return null;
    return (
      <TextField
        multiline
        margin="dense"
        id="description"
        name="name"
        label="List of Options"
        fullWidth
        disabled={this.state.isCreating}
        value={fdf.listOfOptions}
        onChange={(e) => {
          let fdf = this.state.fieldDataFields;
          fdf[subIndex].listOfOptions = e.target.value;
          this.setState({ fieldDataFields: fdf });
        }}
        onBlur={() => {
          this.editCSFSubfieldValue(subIndex, "listOfOptions");
        }}
      />
    );
  };
  renderSubfields = () => {
    if (!this.state.fieldDataFields || !this.state.fieldDataFields.length) {
      return (
        <Grid xs={12} md={8}>
          <Stack spacing={2} sx={{ p: 0, borderRadius: 2 }}>
            <Alert severity="warning">
              No data fields added. Please add at least one data field to get
              started.
            </Alert>
            <Box>
              <Button
                onClick={() => this.setState({ openSubfieldCreate: true })}
                variant="outlined"
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                Add Subfield
              </Button>
            </Box>
          </Stack>
        </Grid>
      );
    }
    return (
      <Grid xs={12} md={8}>
        <Stack spacing={2} sx={{ p: 0, borderRadius: 2 }}>
          {this.state.fieldDataFields.map((fieldDataField, subIndex) => {
            return (
              <Stack
                spacing={1}
                sx={{
                  p: 2,
                  borderRadius: 2,
                  bgcolor: "background.paper",
                  boxShadow: 3,
                }}
              >
                <Box
                  rowGap={2}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(1, 1fr)",
                  }}
                >
                  <Box
                    rowGap={2}
                    columnGap={2}
                    display="grid"
                    gridTemplateColumns={{
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(2, 1fr)",
                    }}
                  >
                    {" "}
                    <Chip
                      label={fieldDataField.fieldDataFieldName}
                      color="success"
                    />{" "}
                    <Chip
                      label={this.NICE_TYPES[fieldDataField.dataType]}
                      color="info"
                    />
                  </Box>
                  {this.renderIfArray(subIndex)}
                  <Box
                    rowGap={2}
                    columnGap={2}
                    display="grid"
                    gridTemplateColumns={{
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(2, 1fr)",
                    }}
                  >
                    <FormControlLabel
                      label="Show in search"
                      labelPlacement="start"
                      control={
                        <Switch
                          checked={fieldDataField.showInSearch}
                          onChange={() => {
                            let fdf = this.state.fieldDataFields;
                            fdf[subIndex].showInSearch =
                              !fdf[subIndex].showInSearch;
                            this.setState({ fieldDataFields: fdf }, () => {
                              this.editCSFSubfieldValue(
                                subIndex,
                                "showInSearch"
                              );
                            });
                          }}
                        />
                      }
                      sx={{
                        m: 0,
                        width: 1,
                        justifyContent: "space-between",
                      }}
                    />
                    <FormControlLabel
                      label="Search Behaviour"
                      labelPlacement="start"
                      control={
                        <Select
                          disabled={!fieldDataField.showInSearch}
                          value={fieldDataField.typeOfSearch}
                          onChange={(e) => {
                            let fdf = this.state.fieldDataFields;
                            fdf[subIndex].typeOfSearch = e.target.value;
                            this.setState({ fieldDataFields: fdf }, () => {
                              this.editCSFSubfieldValue(
                                subIndex,
                                "typeOfSearch"
                              );
                            });
                          }}
                        >
                          <MenuItem value={"EXACT"}>Exact Match</MenuItem>
                          <MenuItem value={"TEXT_SEARCH"}>Text Search</MenuItem>
                        </Select>
                      }
                      sx={{
                        m: 0,
                        width: 1,
                        justifyContent: "space-between",
                      }}
                    />
                  </Box>
                  <Box>
                    <Button
                      color="error"
                      startIcon={<Iconify icon="material-symbols:delete" />}
                      onClick={() => {
                        this.deleteCsfSubfield({
                          csfSubFieldId: fieldDataField._id,
                        });
                      }}
                    >
                      Delete Subfield
                    </Button>
                  </Box>
                </Box>
              </Stack>
            );
          })}
        </Stack>
      </Grid>
    );
  };
  renderCompanySpecificField = () => {
    return (
      <Grid container spacing={3}>
        <Grid xs={12} md={4}>
          <Box
            rowGap={2}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(1, 1fr)",
            }}
          >
            {" "}
            <TextField
              multiline
              label="Field Name"
              value={this.state.fieldName}
              onChange={(e) => {
                this.setState({
                  fieldName: e.target.value,
                });
              }}
              onBlur={() => {
                this.editCSFValue("fieldName");
              }}
            />
            {this.state.needsLookUp ? (
              <Chip
                label={this.state.fieldTag || "No tag defined"}
                color="info"
              />
            ) : null}
            <Chip
              color={this.state.needsLookUp ? "primary" : "warning"}
              label={
                this.state.needsLookUp
                  ? "This field is linked to look-up tables."
                  : "Data for this field is entered manually."
              }
            />
            <TextField
              multiline
              label="Field Description"
              value={this.state.fieldDescription}
              onChange={(e) => {
                this.setState({
                  fieldDescription: e.target.value,
                });
              }}
              onBlur={() => {
                this.editCSFValue("fieldDescription");
              }}
            />
            {this.renderFieldsIfLookup()}
            <Box>
              <Button
                onClick={() => this.setState({ openSubfieldCreate: true })}
                variant="outlined"
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                Add Subfield
              </Button>
            </Box>
            <Box>
              <Button
                color="error"
                startIcon={<Iconify icon="material-symbols:delete" />}
                onClick={() => {
                  this.props.deleteCsf({
                    csfId: this.state._id,
                  });
                }}
              >
                Delete Field
              </Button>
            </Box>
          </Box>
        </Grid>
        {this.renderSubfields()}
      </Grid>
    );
  };

  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <div>
        <CSFSubFieldCreate
          csfId={this.props.data._id}
          csfName={this.props.data.fieldName}
          csfDescription={this.props.data.fieldDescription}
          companyId={this.props.companyId}
          isOpen={this.state.openSubfieldCreate}
          onCreate={(res) => {
            this.setState({
              fieldDataFields: res.fieldDataFields,
              openSubfieldCreate: false,
            });
          }}
          handleClose={() => {
            this.setState({ openSubfieldCreate: false });
          }}
        />

        {this.renderCompanySpecificField()}
      </div>
    );
  }
}
export default WithAPICall(CSFIndividual);
