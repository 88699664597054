/* eslint-disable no-redeclare */
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Card,
  Chip,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import axios from "axios";
import cogoToast from "cogo-toast";
import { format } from "date-fns";
import _, { flatten, groupBy } from "lodash";
import React from "react";
import Iconify from "src/components/iconify";
import { View500 } from "src/sections/error";
import { DOCUMENT_GENERATOR_URL } from "../../../config-global";
import { WithAPICall } from "../../utils/apiUtil";
import generateBarcode from "../utils/generate-ff-barcode";

function substituteVariables(template, variables) {
  return template.replace(/{{\s*([^}]+)\s*}}/g, (_, key) => {
    return variables[key.trim()] || "";
  });
}

class VisitReviewSummaryScheduleEE extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    serviceDetails: [],
    isSaving: false,
    isDownloadingAuthLetter: false,
  };
  componentDidMount() {
    this.setState(
      {
        ...this.props.visit,
        isLoading: true,
      },
      () => {
        this.getServiceDetails();
      }
    );
  }
  updateVisitScalars = async (arr) => {
    try {
      let res = await this.props.apiCallPost("/visit/updateVisitScalars", {
        visitId: this.props.visit._id,
        uops: arr,
      });
    } catch (err) {
      cogoToast.error("Error Saving Visit Data");
      console.log(err);
      this.setState({
        isSaving: false,
      });
    }
  };
  getAuthorizationLetterEmailTemplate = async (templateId) => {
    // const templateId = "665d6edda20ebc966317fdd7";
    try {
      const res = await this.props.apiCallPost("/email/fetchTemplate", {
        templateId,
      });
      return res;
    } catch (error) {
      console.error(error);
    }
  };
  getAuthorizationLetters = async (psls) => {
    this.setState({ isDownloadingAuthLetter: true });
    const psl = psls[0];
    const data = {
      date: format(new Date(), "PP"),
      encounterNumber: this.state._id,
      employeeName: this.state.employee.employeeName,
      companyName: this.state.company.companyName,
      apptDate: format(new Date(psl.scheduledOnDate), "PP"),
      apptTime: format(new Date(psl.scheduledTime), "pp"),
      site: psl.providerObj.providerName,
      clientSvcRep: "",
      clinicName: psl.providerObj.providerName,
      address: psl.providerObj.providerAddress,
      phone: psl.providerObj.providerPhone,
      email: psl.providerObj.providerEmail,
      fax: psl.providerObj.providerFax,
      table: {
        headers: ["Service", "Instructions"],
        data: [],
      },
    };
    psls.forEach(async (psl) => {
      data.table.data.push([psl.pslName, ""]);
    });

    const response = await axios.post(
      DOCUMENT_GENERATOR_URL + "/api/templater/template",
      {
        fileId: "1bmcdSWFObp2Si88pDmJi0Lv93t96zkXCjmYH4K2m73U",
        data,
        convertTo: "pdf",
      },
      {
        responseType: "blob",
      }
    );

    // Create object URL for the blob
    const url = URL.createObjectURL(response.data);

    // Automatically trigger download
    const a = document.createElement("a");
    a.href = url;
    a.download = `${data.clinicName}_${data.employeeName}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.setState({ isDownloadingAuthLetter: false });
  };
  sendAuthorizationLetterFaxForClinic = async (psls) => {
    this.setState({ isDownloadingAuthLetter: true });
    const psl = psls[0];
    const faxNumber = psl.providerObj.providerFax;
    if (!faxNumber) {
      cogoToast.error("Provider does not have a fax number added.");
      this.setState({ isDownloadingAuthLetter: false });
      return;
    } else {
      const data = {
        date: format(new Date(), "PP"),
        encounterNumber: this.state._id,
        employeeName: this.state.employee.employeeName,
        employeeNumber: this.state.employee.employeeNumber || "Not Provided",
        employeeDoB: this.state.employee.employeeDoB || "Not Provided",
        comments: this.props.visit.noteToClinic
          ? this.props.visit.noteToClinic[psl.providerId] ||
            "No Comments Provided"
          : "No Comments Provided",
        companyName: this.state.company.companyName,
        apptDate: format(new Date(psl.scheduledOnDate), "PP"),
        apptTime: format(new Date(psl.scheduledTime), "pp"),
        site: psl.providerObj.providerName,
        clientSvcRep: "",
        clinicName: psl.providerObj.providerName,
        address: psl.providerObj.providerAddress,
        phone: psl.providerObj.providerPhone,
        email: psl.providerObj.providerEmail,
        fax: psl.providerObj.providerFax,
        services: [],
      };
      psls.forEach((psl) => {
        data.services.push({
          name: psl.pslName,
        });
      });
      try {
        const res = await this.props.apiCallPost("/email/auth-letter/fax", {
          documentData: data,
          fileId: "1gdyvxgpAO-ALOxa6F1hA16MTCd0Tixs2D4_aB2_jp80",
          faxNumber,
        });
        this.setState({ isDownloadingAuthLetter: false });
        return res;
      } catch (error) {
        console.error("could not send fax", error);
      }
    }

    this.setState({ isDownloadingAuthLetter: false });
  };
  sendAuthorizationLetterEmailForClinic = async (psls) => {
    this.setState({ isDownloadingAuthLetter: true });
    const emailTemplate = await this.getAuthorizationLetterEmailTemplate(
      "665d7dd1a20ebc96631800e8"
    );
    if (!emailTemplate) {
      cogoToast.error("No email template found for auth letter.");
      this.setState({ isDownloadingAuthLetter: false });
      return;
    }
    const emailHtml = emailTemplate.html;
    const emailBody = substituteVariables(emailHtml, {
      employeeName: this.state.employee.employeeName,
      providerName: this.state.company.companyName,
    });
    const psl = psls[0];
    const emailId = psl.providerObj.providerEmail;
    if (!emailId) {
      cogoToast.error("Provider does not have a email added.");
      this.setState({ isDownloadingAuthLetter: false });
      return null;
    } else {
      const data = {
        date: format(new Date(), "PP"),
        encounterNumber: this.state._id,
        employeeName: this.state.employee.employeeName,
        employeeNumber: this.state.employee.employeeNumber || "Not Provided",
        employeeDoB: this.state.employee.employeeDoB || "Not Provided",
        comments: this.props.visit.noteToClinic
          ? this.props.visit.noteToClinic[psl.providerId] ||
            "No Comments Provided"
          : "No Comments Provided",
        companyName: this.state.company.companyName,
        apptDate: format(new Date(psl.scheduledOnDate), "PP"),
        apptTime: format(new Date(psl.scheduledTime), "pp"),
        site: psl.providerObj.providerName,
        clientSvcRep: "",
        clinicName: psl.providerObj.providerName,
        address: psl.providerObj.providerAddress,
        phone: psl.providerObj.providerPhone,
        email: psl.providerObj.providerEmail,
        fax: psl.providerObj.providerFax,
        services: [],
      };
      psls.forEach((psl) => {
        data.services.push({
          name: psl.pslName,
        });
      });
      try {
        const res = await this.props.apiCallPost("/email/auth-letter/email", {
          documentData: data,
          fileId: "1gdyvxgpAO-ALOxa6F1hA16MTCd0Tixs2D4_aB2_jp80",
          emailIds: [emailId],
          body: emailBody,
        });
        this.setState({ isDownloadingAuthLetter: false });

        return res;
      } catch (error) {
        console.error("could not send fax", error);
      }
    }

    this.setState({ isDownloadingAuthLetter: false });
  };
  sendAuthorizationLetterEmailForEE = async (psls) => {
    this.setState({ isDownloadingAuthLetter: true });
    const emailTemplate = await this.getAuthorizationLetterEmailTemplate(
      "665d6edda20ebc966317fdd7"
    );
    if (!emailTemplate) {
      cogoToast.error("No email template found for auth letter.");
      this.setState({ isDownloadingAuthLetter: false });
      return;
    }
    const emailHtml = emailTemplate.html;
    const emailBody = substituteVariables(emailHtml, {
      employeeName: this.state.employee.employeeName,
      companyName: this.state.company.companyName,
    });
    const psl = psls[0];
    const emailIds = [];
    const emailId = psl.providerObj.providerEmail;
    if (this.props.visit.employee.employeePersonalEmail) {
      emailIds.push(this.props.visit.employee.employeePersonalEmail);
    }
    if (this.props.visit.employee.employeeOfficeEmail) {
      emailIds.push(this.props.visit.employee.employeeOfficeEmail);
    }
    if (emailIds.length === 0) {
      cogoToast.error("Employee does not have an email added.");
      this.setState({ isDownloadingAuthLetter: false });
      return null;
    } else {
      const data = {
        date: format(new Date(), "PP"),
        encounterNumber: this.state._id,
        employeeName: this.state.employee.employeeName,
        employeeNumber: this.state.employee.employeeNumber || "Not Provided",
        employeeDoB: this.state.employee.employeeDoB || "Not Provided",
        comments: this.props.visit.noteToClinic
          ? this.props.visit.noteToClinic[psl.providerId] ||
            "No Comments Provided"
          : "No Comments Provided",
        companyName: this.state.company.companyName,
        apptDate: format(new Date(psl.scheduledOnDate), "PP"),
        apptTime: format(new Date(psl.scheduledTime), "pp"),
        site: psl.providerObj.providerName,
        clientSvcRep: "",
        clinicName: psl.providerObj.providerName,
        address: psl.providerObj.providerAddress,
        phone: psl.providerObj.providerPhone,
        email: psl.providerObj.providerEmail,
        fax: psl.providerObj.providerFax,
        services: [],
        table: {
          headers: ["Service", "Instructions"],
          data: [],
        },
      };
      psls.forEach((psl) => {
        data.services.push({
          name: psl.pslName,
        });
        data.table.data.push([psl.pslName, ""]);
      });
      try {
        const res = await this.props.apiCallPost("/email/auth-letter/email", {
          documentData: data,
          fileId: "1bmcdSWFObp2Si88pDmJi0Lv93t96zkXCjmYH4K2m73U",
          emailIds,
          body: emailBody,
        });
        this.setState({ isDownloadingAuthLetter: false });
        return res;
      } catch (error) {
        console.error("could not send fax", error);
      }
    }

    this.setState({ isDownloadingAuthLetter: false });
  };
  getAuthorizationLettersForClinic = async (psls) => {
    this.setState({ isDownloadingAuthLetter: true });
    const psl = psls[0];

    const data = {
      date: format(new Date(), "PP"),
      encounterNumber: this.state._id,
      employeeName: this.state.employee.employeeName,
      employeeNumber: this.state.employee.employeeNumber || "Not Provided",
      employeeDoB: this.state.employee.employeeDoB || "Not Provided",
      comments: this.props.visit.noteToClinic
        ? this.props.visit.noteToClinic[psl.providerId] ||
          "No Comments Provided"
        : "No Comments Provided",
      companyName: this.state.company.companyName,
      apptDate: format(new Date(psl.scheduledOnDate), "PP"),
      apptTime: format(new Date(psl.scheduledTime), "pp"),
      site: psl.providerObj.providerName,
      clientSvcRep: "",
      clinicName: psl.providerObj.providerName,
      address: psl.providerObj.providerAddress,
      phone: psl.providerObj.providerPhone,
      email: psl.providerObj.providerEmail,
      fax: psl.providerObj.providerFax,
      table: {
        headers: ["Service", "Instructions"],
        data: [],
      },
      services: [],
    };
    psls.forEach(async (psl) => {
      // @ts-ignore
      data.table.data.push([psl.pslName, ""]);
      data.services.push({ name: psl.pslName });
    });
    let url = null;
    if (psl.providerObj.isFormFox) {
      // get formfox order details
      try {
        const res = await this.props.apiCallPost(
          "/visit/formfox/getFormFoxOrdersForProvider",
          {
            visitId: this.state._id,
            providerId: psl.providerId,
          }
        );
        // for each unique reference test id generate an authorization letter
        // for each provider, there will be only 1 formfox reference test id

        const formFoxOrders = flatten(res);
        console.log({ formFoxOrders });
        const referenceTestId = formFoxOrders[0].referenceTestId;
        // get reference test id for this psl
        const barcodeBase64 = generateBarcode(referenceTestId);
        const response = await axios.post(
          DOCUMENT_GENERATOR_URL + `/api/templater/template-with-image`,
          {
            fileId: "1oJMQpnYfGfVEEx6Ibv4sqpoFBpIPS8LLyOKwpQX2E-M",
            data,
            convertTo: "pdf",
            images: {
              barcode: barcodeBase64,
            },
          },
          {
            responseType: "blob",
          }
        );
        url = URL.createObjectURL(response.data);
      } catch (error) {
        console.error("could not get formfox order", error);
      }
    } else {
      const response = await axios.post(
        DOCUMENT_GENERATOR_URL + "/api/templater/template",
        {
          fileId: "1gdyvxgpAO-ALOxa6F1hA16MTCd0Tixs2D4_aB2_jp80",
          data,
          convertTo: "pdf",
        },
        {
          responseType: "blob",
        }
      );
      // Create object URL for the blob
      url = URL.createObjectURL(response.data);
    }

    // Automatically trigger download
    const a = document.createElement("a");
    a.href = url;
    a.download = `${data.clinicName}_${data.employeeName}.pdf`;
    console.log("download tag", a);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.setState({ isDownloadingAuthLetter: false });
  };
  getServiceDetails = async () => {
    let services = _.uniq(
      this.props.visit.bookedServices.map((x) => x.serviceId)
    );
    try {
      let res = await this.props.apiCallPost(
        "/master-service/getMasterServiceById",
        {
          masterServiceId: services,
        }
      );
      this.setState({
        isLoading: false,
        isError: false,
        serviceDetails: res,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isError: true,
        isLoading: false,
      });
    }
  };
  pushToEventLog = async () => {
    this.setState({ isSaving: true });
    let providerNames = this.props.visit.clinicPSLs.map((x) => x.providerName);
    let labNames = this.props.visit.labPSLs.map((x) => x.providerName);
    let names = _.uniq([...providerNames, ...labNames]);
    let str = "";
    if (names.length) {
      str = names.join(", ");
    }
    let payload = {
      visitId: this.props.visit._id,
      header: "Visit Scheduled",
      notes: str,
      creator: "User",
      statusTo: "SCHEDULED",
    };
    try {
      let res = await this.props.apiCallPost(
        "/visit/events/createNewVisitEvent",
        payload
      );
      this.setState({ isSaving: false, visitStatus: "SCHEDULED" }, () => {
        this.props.onSetToScheduled();
      });
      cogoToast.success("Event Logged");
    } catch (err) {
      cogoToast.error("Error Scheduling");
      console.log(err);
    }
    this.setState({ isSaving: false });
  };
  renderEmployeeInformation = () => {
    let eeName = this.props.visit.employee.employeeName;
    let erName = this.props.visit.company.companyName;
    const clinicPSLs = this.state.clinicPSLs;
    const groupedPSLs = groupBy(clinicPSLs, (obj) => obj.providerId);

    const handleEmailAuthorizationLetter = async (psls) => {
      let payload = {
        visitId: this.props.visit._id,
        header: `Authorization letter emailed to Employee`,
        notes: "",
        creator: "User",
      };
      const res = await this.sendAuthorizationLetterEmailForEE(psls);
      if (res) {
        await this.addNewEvent(payload);
      }
    };

    return (
      <Stack spacing={1}>
        <Typography variant="overline">Employee Details</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Employee Name</TableCell>
              <TableCell>Employer Name</TableCell>
              <TableCell>Employee Address</TableCell>
              <TableCell>Employee Phone No</TableCell>
              <TableCell>Authorization Letter</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{eeName}</TableCell>
              <TableCell>{erName}</TableCell>
              <TableCell>
                <Stack spacing={0}>
                  <Typography variant="caption">
                    {`${this.props.visit.employee.employeeAddress || ""}`}
                  </Typography>
                  <Typography variant="caption">
                    {`${this.props.visit.employee.employeeAddressQualifier || ""}`}
                  </Typography>
                  <Typography variant="caption">
                    {`${this.props.visit.employee.employeeCity || ""}`}
                  </Typography>
                  <Typography variant="caption">
                    {`${this.props.visit.employee.employeeState || ""}`}
                  </Typography>
                  <Typography variant="caption">
                    {`${this.props.visit.employee.employeePostalCode || ""}`}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell></TableCell>
              <TableCell>
                <Stack spacing={1}>
                  {Object.keys(groupedPSLs).map((providerId, idx) => {
                    const providerName =
                      groupedPSLs[providerId][0].providerName;
                    return (
                      <Stack
                        direction="row"
                        spacing={1}
                        key={idx}
                        alignItems="center"
                      >
                        <Typography variant="overline">
                          {providerName}
                        </Typography>
                        <LoadingButton
                          variant="outlined"
                          size="small"
                          loading={this.state.isDownloadingAuthLetter}
                          onClick={() => {
                            this.getAuthorizationLetters(
                              groupedPSLs[providerId]
                            );
                          }}
                        >
                          <Iconify icon="material-symbols:download" />
                        </LoadingButton>
                        <LoadingButton
                          variant="outlined"
                          size="small"
                          loading={this.state.isDownloadingAuthLetter}
                          onClick={() => {
                            handleEmailAuthorizationLetter(
                              groupedPSLs[providerId]
                            );
                          }}
                        >
                          <Iconify icon="ic:baseline-email" />
                        </LoadingButton>
                      </Stack>
                    );
                  })}
                </Stack>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    );
  };
  renderChosenProtocols = () => {
    let bp = this.props.visit.bookedProtocolIds;
    let bs = this.props.visit.bookedServices;
    let cp = this.props.visit.companyProtocols;
    let sd = this.state.serviceDetails;

    if (!bp || !bp.length) {
      return <Alert severity="warning">No protocols chosen</Alert>;
    }
    return (
      <Stack spacing={2}>
        <Typography variant="subtitle1">Protocols</Typography>
        <Stack spacing={1}>
          {bp.map((p, index) => {
            let pId = p;
            let whichCp = cp.find((c) => c._id === pId);
            let cpName = whichCp?.companyProtocolName || "Unknown Protocol";
            let cpType = whichCp?.companyProtocolType || "Unknown Type";
            let cpServices = whichCp.masterServices;
            let bookedServices = _.filter(
              bs,
              (b) => b.parentProtocolId === pId
            ).map((x) => x.serviceId);

            let servicesArr = [];

            for (var i = 0; i < bookedServices.length; i++) {
              let sId = bookedServices[i];

              let whichSd = _.find(sd, (s) => s._id === sId);

              let isHandledByWorkCare = whichSd?.isHandledByWorkCare;

              if (isHandledByWorkCare) {
                servicesArr.push({
                  serviceName: whichSd?.serviceName,
                  serviceId: sId,
                  isHandledByWorkCare: true,
                });
              } else {
                // find the type, "Clinic" or "Lab"

                let serviceRequiresProviderType =
                  whichSd?.serviceRequiresProviderType;

                if (serviceRequiresProviderType === "Clinic") {
                  let clinicPSLs = this.props.visit.clinicPSLs;
                  let whichPSL = _.find(clinicPSLs, (c) =>
                    c.masterServices.includes(sId)
                  );
                  if (!whichPSL) {
                    servicesArr.push({
                      serviceName: whichSd.serviceName,
                      serviceId: sId,
                      isHandledByWorkCare: false,
                      serviceRequiresProviderType: "Clinic",
                      clinicName: null,
                      clinicId: null,
                      pslName: null,
                    });
                  } else {
                    servicesArr.push({
                      serviceName: whichSd?.serviceName,
                      serviceId: sId,
                      isHandledByWorkCare: false,
                      serviceRequiresProviderType: "Clinic",
                      clinicName: whichPSL.providerName,
                      clinicId: whichPSL.providerId,
                      pslName: whichPSL.pslName,
                      scheduledTime: whichPSL.scheduledTime,
                    });
                  }
                } else if (serviceRequiresProviderType === "Lab") {
                  let labPSLs = this.props.visit.labPSLs;
                  let whichPSL = _.find(labPSLs, (c) =>
                    c.masterServices.includes(sId)
                  );
                  if (!whichPSL) {
                    servicesArr.push({
                      serviceName: whichSd?.serviceName,
                      serviceId: sId,
                      isHandledByWorkCare: false,
                      serviceRequiresProviderType: "Lab",
                      clinicName: null,
                      clinicId: null,
                      pslName: null,
                    });
                  } else {
                    servicesArr.push({
                      serviceName: whichSd?.serviceName,
                      serviceId: sId,
                      isHandledByWorkCare: false,
                      serviceRequiresProviderType: "Lab",
                      clinicName: whichPSL.providerName,
                      clinicId: whichPSL.providerId,
                      pslName: whichPSL.pslName,
                      collectionAt: whichPSL.collectingClinicName || null,
                    });
                  }
                }
              }
            }

            return (
              <Card
                key={index}
                sx={{
                  p: 2,
                  bgcolor: "bg.neutral",
                  boxShadow: 2,
                }}
              >
                <Stack spacing={1}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="overline">{cpName}</Typography>{" "}
                    <Typography variant="caption">{cpType}</Typography>
                  </Stack>
                  <Stack spacing={1}>
                    {servicesArr.map((s) => {
                      return this.renderServices(s);
                    })}
                  </Stack>
                </Stack>
              </Card>
            );
          })}
        </Stack>
      </Stack>
    );
  };

  renderLabProviders = () => {
    let labPSLs = this.props.visit.labPSLs;
    if (!labPSLs.length) {
      return null;
    }
    return (
      <Stack spacing={2}>
        <Typography variant="subtitle2">Labs</Typography>
        {labPSLs.map((k) => {
          let pObj = k.providerObj;
          let pslName = k.pslName;
          let collectionAt = k.collectingClinicName || "Not Chosen";
          return (
            <Card
              sx={{
                p: 2,
                bgcolor: "bg.neutral",
                boxShadow: 2,
                maxWidth: 300,
              }}
            >
              <Stack spacing={1} direction="column" justifyContent="center">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="overline">{k.providerName}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {/* <Iconify icon="lets-icons:date-fill" /> */}
                  <Typography variant="overline">{pslName}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify icon="lets-icons:date-fill" />
                  <Typography variant="caption">{collectionAt}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify icon="mdi:location" />
                  <Typography variant="caption">
                    {pObj.providerAddress}, {pObj.providerCity},
                    {pObj.providerCountry}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify icon="material-symbols:call" />
                  <Typography variant="caption">
                    {pObj.providerPhone}
                  </Typography>
                </Stack>
              </Stack>
              {pObj.isFormFox && (
                <Chip
                  size="small"
                  color="info"
                  sx={{
                    marginTop: "0.5rem",
                    width: "fit-content",
                  }}
                  icon={<Iconify icon="material-symbols:star" />}
                  label="Booking through FormFox"
                />
              )}
            </Card>
          );
        })}
      </Stack>
    );
  };
  renderValidations = () => {
    // check if services require Clinic & !clinicFrozen

    let sd = this.state.serviceDetails;
    let clinicServices = _.filter(
      sd,
      (x) => x.serviceRequiresProviderType === "Clinic"
    );
    if (clinicServices.length && !this.props.visit.clinicFrozen) {
      return <Alert severity="warning">Clinic Services Not Frozen</Alert>;
    }
    let labServices = _.filter(
      sd,
      (x) => x.serviceRequiresProviderType === "Lab"
    );
    // if (labServices.length && !this.props.visit.labFrozen) {
    //   return <Alert severity="warning">Lab Services Not Frozen</Alert>;
    // }

    let bs = this.props.visit.bookedServices.map((x) => x.serviceId);
    let allServicesInPSLs = true;
    let lp = this.props.visit.labPSLs.map((x) => x.masterServices);
    let cp = this.props.visit.clinicPSLs.map((x) => x.masterServices);
    let allServices = _.flattenDeep([lp, cp]);
    for (var i = 0; i < bs.length; i++) {
      let s = this.state.serviceDetails.find((x) => x._id === bs[i]);

      if (!allServices.includes(bs[i])) {
        if (s?.isHandledByWorkCare) {
          continue;
        } else {
          allServicesInPSLs = false;
          break;
        }
      }
    }

    let allClinicPSLsScheduled = true;
    let cpScheduled = this.props.visit.clinicPSLs.map((x) => x.scheduledTime);
    for (var i = 0; i < cpScheduled.length; i++) {
      if (!cpScheduled[i]) {
        allClinicPSLsScheduled = false;
        break;
      }
    }

    let allLabsHaveCollectionSites = true;
    let labCollectionSites = this.props.visit.labPSLs.map(
      (x) => x.collectingClinicName
    );
    for (var i = 0; i < labCollectionSites.length; i++) {
      if (!labCollectionSites[i]) {
        allLabsHaveCollectionSites = false;
        break;
      }
    }

    let allInPSLElem = (
      <Chip
        label="All Services Booked Appropriately"
        icon={<Iconify icon="teenyicons:tick-circle-solid" />}
        color="success"
      />
    );

    if (!allServicesInPSLs) {
      allInPSLElem = (
        <Chip
          label="Service Bookings Not Completed"
          icon={<Iconify icon="carbon:warning-filled" />}
          color="error"
        />
      );
    }
    let allClinicsElem = (
      <Chip
        label="All Clinic Services Scheduled"
        icon={<Iconify icon="teenyicons:tick-circle-solid" />}
        color="success"
      />
    );
    if (!allClinicPSLsScheduled) {
      allClinicsElem = (
        <Chip
          label="Clinic Services Not Scheduled"
          icon={<Iconify icon="carbon:warning-filled" />}
          color="error"
        />
      );
    }
    if (!this.props.visit.clinicPSLs.length) {
      allClinicsElem = null;
    }

    let allLabsHaveCollectionElem = (
      <Chip
        label="All Labs Have Collection Sites Chosen"
        icon={<Iconify icon="teenyicons:tick-circle-solid" />}
        color="success"
      />
    );

    if (!allLabsHaveCollectionSites) {
      allLabsHaveCollectionElem = (
        <Chip
          label="Collection Sites for Labs Not Selected"
          icon={<Iconify icon="carbon:warning-filled" />}
          color="error"
        />
      );
    }
    if (!this.props.visit.labPSLs.length) {
      allLabsHaveCollectionElem = null;
    }

    let isGoodToGo =
      allServicesInPSLs && allClinicPSLsScheduled && allLabsHaveCollectionSites;

    let canScheduleStates = [
      "REQUESTED",
      "SCHEDULING_IN_PROGRESS",
      "RESCHEDULING_REQUESTED",
    ];
    let canSchedule = canScheduleStates.includes(this.state.visitStatus);

    let buttonElem = (
      <LoadingButton
        loading={this.state.isSaving}
        variant="contained"
        color="primary"
        onClick={this.pushToEventLog}
      >
        Confirm Visit
      </LoadingButton>
    );
    if (!isGoodToGo) {
      buttonElem = null;
    }
    if (!canSchedule) {
      buttonElem = null;
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Typography variant="subtitle1">Validations</Typography>
        </Grid>
        <Grid item xs={7}>
          <Stack
            spacing={1}
            direction="row"
            alignItems={"center"}
            flexWrap="wrap"
          >
            {allInPSLElem} {allClinicsElem} {allLabsHaveCollectionElem}{" "}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {buttonElem}
        </Grid>
      </Grid>
    );
  };
  addNewEvent = async (payload) => {
    try {
      let res = await this.props.apiCallPost(
        "/visit/events/createNewVisitEvent",
        payload
      );
      this.setState({ isSaving: false });
      cogoToast.success("Event Logged");
    } catch (err) {
      cogoToast.error("Error Scheduling");
      console.log(err);
    }
  };

  renderClinicTable = () => {
    // group providers and see what services they provide
    const groupedPsls = groupBy(
      this.props.visit.clinicPSLs,
      (p) => p.providerObj.providerName
    );
    console.log({ groupedPsls });

    const handleEmailAuthorizationLetter = async (psls) => {
      const providerName = psls[0].providerName;
      let payload = {
        visitId: this.props.visit._id,
        header: `Authorization letter emailed to ${providerName}`,
        notes: "",
        creator: "User",
      };
      const res = await this.sendAuthorizationLetterEmailForClinic(psls);
      if (res) {
        await this.addNewEvent(payload);
      }
    };

    const handleFaxAuthorizationLetter = async (psls) => {
      const providerName = psls[0].providerName;
      let payload = {
        visitId: this.props.visit._id,
        header: `Authorization letter faxed to ${providerName}`,
        notes: "",
        creator: "User",
      };
      const res = await this.sendAuthorizationLetterFaxForClinic(psls);
      if (res) {
        await this.addNewEvent(payload);
      }
    };

    return (
      <Stack spacing={1} sx={{ mt: 2 }}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Typography variant="overline">Selected Clinic</Typography>
        </Stack>
        {this.props.visit.clinicPSLs.length === 0 ? (
          <Alert severity="info" sx={{ mt: 1 }}>
            Find and select clinic to book appointment time.
          </Alert>
        ) : (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Clinic Name</TableCell>
                <TableCell>Chosen Services</TableCell>
                <TableCell>Appointment Time</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from(Object.keys(groupedPsls)).map(
                (providerName, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Stack spacing={0.5}>
                        <Typography variant="subtitle1">
                          {providerName}
                        </Typography>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={0.5}
                        >
                          <Iconify icon="ic:baseline-phone" />
                          <Typography variant="caption">
                            {
                              groupedPsls[providerName][0].providerObj
                                .providerPhone
                            }
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={0.5}
                        >
                          <Iconify icon="material-symbols:fax" />
                          <Typography variant="caption">
                            {groupedPsls[providerName][0].providerObj
                              .providerFax || ""}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={0.5}
                        >
                          <Iconify icon="ic:baseline-email" />
                          <Typography variant="caption">
                            {groupedPsls[providerName][0].providerObj
                              .providerEmail || "No email added for provider"}
                          </Typography>
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack direction="column" spacing={0.5}>
                        {groupedPsls[providerName].map((psl, pIdx) => (
                          <Chip
                            size="small"
                            label={psl.pslName}
                            variant="outlined"
                            key={pIdx}
                          />
                        ))}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={0.5}>
                        {format(
                          new Date(groupedPsls[providerName][0].scheduledTime),
                          "Pp"
                        )}
                        {groupedPsls[providerName][0].providerReadableTimezone}
                      </Stack>
                    </TableCell>

                    <TableCell></TableCell>
                    <TableCell />
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        )}
      </Stack>
    );
  };

  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <Paper
        sx={{
          p: 2,
          bgcolor: "bg.neutral",
          boxShadow: 2,
        }}
      >
        <Stack spacing={2}>
          {this.renderEmployeeInformation()}
          {this.renderClinicTable()}
        </Stack>
      </Paper>
    );
  }
}

export default WithAPICall(VisitReviewSummaryScheduleEE);
