import Typography from "@mui/material/Typography";
import { ComingSoonIllustration } from "src/assets/illustrations";
export default function ComingSoonView() {
  return (
    <>
      <Typography variant="h3" sx={{ mb: 2 }}>
        Coming Soon!
      </Typography>

      <Typography sx={{ color: "text.secondary" }}>
        You'll see the primary dashboard here.
      </Typography>

      <ComingSoonIllustration sx={{ my: 10, height: 240 }} />
    </>
  );
}
