import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Card,
  Chip,
  Drawer,
  LinearProgress,
  MenuItem,
  Select,
  Skeleton,
  Tooltip,
  Typography,
  InputLabel,
  FormControl,
  Box,
  Checkbox,
  TableRow,
  TableCell,
  List,
  ListItem,
  Paper,
  IconButton,
  Badge,
  ListItemText,
  Divider,
} from "@mui/material";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Stack } from "@mui/system";
import _ from "lodash";
import cogoToast from "cogo-toast";
import { formatTime, niceDate, niceDateTime } from "../utils/fn";
import { LoadingButton } from "@mui/lab";
import Iconify from "src/components/iconify";
import format from "date-fns/format";
import TextareaAutosize from "src/components/text-area-autosize";
import VISIT_STATUS from "../Visit/data/visit-status";
import { PROTOCOL_STATUS } from "../Visit/data/protocol-status";
import VisitTimelineEventCreate from "../Visit/VisitTimelineEventCreate";
import DocumentGroup from "../Documents/DocumentGroup";
import { UploadBox } from "src/components/upload";
import { formatFileSize } from "react-papaparse";
import DynamicTextWithSelects from "./DynamicClearance";

class VisitClearanceDetails extends React.Component {
  state = {
    ...this.props.data,

    isLoading: false,
    bookedServicesDetailed: [],
    bookedProtocolsDetailed: [],
    bookedServices: [],
    bookedProtocols: [],
    isDrawerOpen: false,
    employeeSchedulingPreferenceLocation: {},
    employeeSchedulingPreferenceDatetime: null,
    openVisitDrawer: false,

    //
    selectedServiceId: null,
    selectedServiceClearanceCase: null,
    selectedServiceClearanceLanguage: null,

    // service summary & eval
    summary: "",
    isSummarizing: false,
    currentAction: "Summarizing",
    evaluation: "",
    isEvaling: false,

    // comments
    comment: "",
    isAddingComment: false,

    // log outreach attempt
    openOutreachAttemptModal: false,

    groups: [],
    isLoadingGroups: false,

    // timeline events
    events: [],
    isLoadingEvents: false,
    //
    fileList: [],
    startTime: null,
    endTime: null,
    //
    historicalData: [],
  };
  componentDidMount() {
    this.setState({
      ...this.props.data,
    });

    this.getFilesByVisit();
  }
  VISIT_STATUS_META = {
    PARTIAL_DOCUMENTS_RECEIVED: {
      icon: "material-symbols:stroke-partial",
      color: "warning",
    },
    REVIEW_NOT_STARTED: {
      icon: "ph:clock-duotone",
      color: "warning",
    },
    IN_RN_REVIEW: {
      icon: "mingcute:nurse-line",
      color: "primary",
    },
    IN_MD_REVIEW: {
      icon: "healthicons:doctor",
      color: "secondary",
    },
    PENDING_INFORMATION_FROM_EMPLOYEE: {
      icon: "grommet-icons:document-user",
      color: "info",
    },
    PENDING_AFFIDAVIT: {
      icon: "material-symbols-light:contract-outline",
      color: "info",
    },
    CLEARED: {
      icon: "mdi:tick-decagram",
      color: "success",
    },
    CLEARED_WITH_RESTRICTIONS: {
      icon: "ph:circle-half-light",
      color: "warning",
    },
    NOT_CLEARED: {
      icon: "fe:warning",
      color: "error",
    },
  };
  CLEARANCE_TYPE_META = {
    Cleared: {
      color: "success",
      icon: "mdi:tick-decagram",
    },
    "Cleared Provisionally": {
      color: "success",
      icon: "mdi:progress-tick",
    },
    "Cleared With Restriction": {
      color: "warning",
      icon: "material-symbols-light:data-check",
    },
    "Not Cleared": {
      color: "error",
      icon: "zondicons:block",
    },
    "Null Clearance": {
      color: "warning",
      icon: "mingcute:na-fill",
    },
  };
  startTimer = () => {
    this.setState({
      startTime: new Date(),
    });
  };
  endTimer = (id) => {
    this.setState(
      {
        endTime: new Date(),
      },
      () => {
        this.telemetryTime(id);
      }
    );
  };
  telemetryTime = async (id) => {
    if (!this.state.startTime || !this.state.endTime) return;
    let timeTaken = this.state.endTime - this.state.startTime;
    if (timeTaken < 0) return;
    if (timeTaken > 15 * 60 * 1000) {
      return;
    }
    let secondsElapsed = Math.round(timeTaken / 1000);
    try {
      await this.props.apiCallPost("/analytics/timelog", {
        studyEntityType: "VISIT_REVIEW",
        studyEntitySubType: "",
        studyEntityId: this.state._id,
        secondsElapsed: secondsElapsed,
      });
      this.setState({
        startTime: null,
        endTime: null,
      });
    } catch (err) {
      console.error(err);
    }
  };
  /**
   * Visit timeline events
   */
  getTimelineEvents = async () => {
    this.setState({ isLoadingEvents: true });
    try {
      const res = await this.props.apiCallPost("/visit/events/getByVisitId", {
        visitId: this.state._id,
      });
      // sort by createdAt
      let eventsArr = [...res];

      eventsArr.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
      this.setState({
        events: eventsArr,
        isLoadingEvents: false,
      });
    } catch (error) {
      this.setState({ isError: true, isLoadingEvents: false });
      cogoToast.error("Error fetching timeline events");
    }
  };

  /**
   * Visit Documents
   */
  getGroupsByVisit = async () => {
    this.setState({ isLoadingGroups: true });
    try {
      let res = await this.props.apiCallPost("/files/groups/search", {
        visitId: this.state._id,
      });
      this.setState({
        groups: res,
        isLoadingGroups: false,
      });
    } catch (error) {
      console.log(error);
      cogoToast.error("Error getting data");
      this.setState({
        isError: true,
      });
    }
    this.setState({ isLoadingGroups: false });
  };
  getFilesByVisit = async () => {
    this.setState({ isLoadingFiles: true });
    try {
      let res = await this.props.apiCallPost("/files/getByEntityId", {
        entityId: this.state._id,
        entityType: "VISIT_AUX_FILE",
      });
      this.setState({
        fileList: res,
        isLoadingFiles: false,
      });
    } catch (error) {
      console.log(error);
      cogoToast.error("Error getting data");
      this.setState({
        isError: true,
      });
    }
    this.setState({ isLoadingFiles: false });
  };
  getPreviousData = async (documentTypes) => {
    let eeId = this.state.employee._id;
    try {
      cogoToast.loading("Fetching historical data...");
      this.setState({
        isFetchingHistorical: true,
      });
      let res = [];
      for (var i = 0; i < documentTypes.length; i++) {
        let resIdx = await this.props.apiCallPost(
          "/files/getGroupDataForEmployee",
          {
            employeeId: eeId,
            serviceDataType: documentTypes[i],
          }
        );
        res = res.concat(resIdx);
      }

      let currentGroupIds = this.state.groups.map((g) => g._id);
      // remove those from res.
      let resFiltered = res.filter((r) => !currentGroupIds.includes(r._id));
      let histData = resFiltered.map((x) => {
        let vDate = new Date(x.visit.visitAt);
        return {
          vDate: vDate,
          ...x,
        };
      });
      let sortedByDateHistData = _.reverse(_.sortBy(histData, (x) => x.vDate));
      this.setState({
        historicalData: sortedByDateHistData,
        isFetchingHistorical: false,
      });
    } catch (err) {
      cogoToast.error("Failed to fetch historical data.");
      this.setState({
        isFetchingHistorical: false,
        isLoading: false,
      });
      console.log(err);
    }
  };

  /**
   * Generate Summaries
   */

  addComment = async ({ comment }) => {
    this.setState({ isAddingComment: true });
    try {
      const res = await this.props.apiCallPost("/visit/addCommentToVisit", {
        visitId: this.props.data._id,
        comment,
      });
      this.setState({ ...res, comment: "" });
      cogoToast.success("Comment added");
    } catch (error) {
      console.error("could not add comment", error);
    }
    this.setState({ isAddingComment: false });
  };

  generateServiceSummary = async ({ serviceId }) => {
    const serviceSummary = this.state.serviceSummary || {};
    serviceSummary[serviceId] = {
      ...(serviceSummary[serviceId] || {}),
      summary: "",
      ts: new Date(),
    };

    try {
      this.setState({
        serviceSummary: serviceSummary,
        isSummarizing: true,
        currentAction: "Summarizing",
      });

      await this.props.apiCallPostStreamingForAI(
        "/external/pdf/generateSummary",
        {
          visitId: this.props.data._id,
          serviceId,
          stream: true,
        },
        (data) => {
          serviceSummary[serviceId].summary =
            serviceSummary[serviceId].summary + data;
          this.setState({
            serviceSummary,
          });
        },
        async () => {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          this.updateVisitScalars([
            {
              key: "serviceSummary",
              value: serviceSummary,
            },
          ]);
          this.setState(
            {
              isSummarizing: false,
              currentAction: null,
              serviceSummary,
            },
            () => {
              cogoToast.success("Service Summary Generated");
            }
          );
        }
      );
    } catch (err) {
      cogoToast.error("Error Summarizing Service");
      console.log(err);
    }
  };

  // generateServiceEval = async ({ serviceId }) => {
  //   let bookedServices = this.state.bookedServices;
  //   let idx = _.findIndex(bookedServices, (s) => s.serviceId === serviceId);
  //   let obj = bookedServices[idx];
  //   obj.isEvaluating = true;
  //   obj.evaluation = "";
  //   bookedServices[idx] = obj;
  //   try {
  //     this.setState({
  //       bookedServices: bookedServices,
  //       isEvaling: true,
  //       currentAction: "Evaluating",
  //     });
  //     await this.props.apiCallPostStreamingForAI(
  //       "/external/pdf/evalClearance",
  //       {
  //         visitId: this.props.data._id,
  //         serviceId,
  //         stream: true,
  //       },
  //       (data) => {
  //         let obj = bookedServices[idx];
  //         obj.evaluation = obj.evaluation + data;
  //         bookedServices[idx] = obj;

  //         this.setState({
  //           bookedServices,
  //         });
  //       },
  //       async () => {
  //         await new Promise((resolve) => setTimeout(resolve, 2000));
  //         // save in booked services
  //         let obj = bookedServices[idx];
  //         obj.isEvaluating = false;
  //         bookedServices[idx] = obj;
  //         this.updateVisitScalars([
  //           {
  //             key: "bookedServices",
  //             value: bookedServices,
  //           },
  //         ]);

  //         this.setState({
  //           isEvaling: false,
  //           currentAction: null,
  //           bookedServices,
  //         });
  //       }
  //     );
  //   } catch (err) {
  //     cogoToast.error("Error Evaluating Service");
  //     console.log(err);
  //   }
  // };

  generateProtocolSummary = async ({ protocolId }) => {
    // let protocolSummaries = this.state.protocolSummaries;
    // let idx = _.findIndex(
    //   protocolSummaries,
    //   (s) => s.protocolId === protocolId
    // );
    // if (idx === -1) {
    //   let oo = {
    //     protocolId: protocolId,
    //     summary: "",
    //     isSummarizing: true,
    //   };
    //   protocolSummaries.push(oo);
    // } else {
    //   let obj = protocolSummaries[idx];
    //   obj.isSummarizing = true;
    //   obj.summary = "";
    //   protocolSummaries[idx] = obj;
    // }

    const protocolSummary = this.state.protocolSummary || {};
    protocolSummary[protocolId] = {
      ...(protocolSummary[protocolId] || {}),
      summary: "",
      evaluation: "",
      ts: new Date(),
    };

    try {
      this.setState({
        protocolSummary: protocolSummary,
        isSummarizing: true,
        currentAction: "Summarizing",
      });
      await this.props.apiCallPostStreamingForAI(
        "/external/pdf/generateProtocolSummary",
        {
          visitId: this.props.data._id,
          protocolId,
          stream: true,
        },
        (data) => {
          // let obj;
          // if (idx === -1) {
          //   obj = protocolSummaries[protocolSummaries.length - 1];
          // } else {
          //   obj = protocolSummaries[idx];
          // }
          // obj.summary = obj.summary + data;

          // protocolSummaries[idx] = obj;
          protocolSummary[protocolId].summary =
            protocolSummary[protocolId].summary + data;
          this.setState({
            protocolSummary: protocolSummary,
          });
        },
        async () => {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          // save in protocol summaries
          // let obj = protocolSummaries[idx];
          // obj.isSummarizing = false;
          // protocolSummaries[idx] = obj;
          this.updateVisitScalars([
            {
              key: "protocolSummary",
              value: protocolSummary,
            },
          ]);
          this.setState(
            {
              isSummarizing: false,
              currentAction: null,
              protocolSummary: protocolSummary,
            },
            () => {
              cogoToast.success("Protocol Summary Generated");
              cogoToast.loading("Evaluating Protocol");
              this.generateProtocolEval({ protocolId });
            }
          );
        }
      );
    } catch (err) {
      cogoToast.error("Error Summarizing Protocol");
      console.log(err);
    }
  };
  generateProtocolEval = async ({ protocolId }) => {
    // let protocolSummaries = this.state.protocolSummaries;
    // let idx = _.findIndex(
    //   protocolSummaries,
    //   (s) => s.protocolId === protocolId
    // );
    // if (idx === -1) {
    //   this.generateProtocolSummary({ protocolId });
    //   return;
    // } else {
    //   let obj = protocolSummaries[idx];
    //   obj.isEvaluating = true;
    //   obj.evaluation = "";
    //   protocolSummaries[idx] = obj;
    // }
    const protocolSummary = this.state.protocolSummary || {};
    protocolSummary[protocolId] = {
      ...(protocolSummary[protocolId] || {}),
      evaluation: "",
      ts: new Date(),
    };
    try {
      this.setState({
        protocolSummary: protocolSummary,
        isEvaling: true,
        currentAction: "Evaluating",
      });
      await this.props.apiCallPostStreamingForAI(
        "/external/pdf/evalProtocolClearance",
        {
          visitId: this.props.data._id,
          protocolId,
          stream: true,
        },
        (data) => {
          protocolSummary[protocolId].evaluation =
            protocolSummary[protocolId].evaluation + data;
          this.setState({
            protocolSummary: protocolSummary,
          });
        },
        async () => {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          // save in booked services
          // let obj = protocolSummaries[idx];
          // obj.isEvaluating = false;
          // protocolSummaries[idx] = obj;
          this.updateVisitScalars([
            {
              key: "protocolSummary",
              value: protocolSummary,
            },
          ]);
          this.setState({
            isEvaling: false,
            currentAction: null,
            protocolSummary,
          });
        }
      );
    } catch (err) {
      cogoToast.error("Error Evaluating Protocol");
      console.log(err);
    }
  };

  updateVisitScalars = async (arr, showNotification = true) => {
    this.setState({
      isSaving: true,
    });
    try {
      let res = await this.props.apiCallPost("/visit/updateVisitScalars", {
        visitId: this.state._id,
        uops: arr,
      });
      if (showNotification) {
        cogoToast.success("Visit Data Saved");
      }
      this.setState({
        isSaving: false,
        // selectedServiceClearanceCase: null,
        // selectedServiceClearanceLanguage: "",
        ...res,
      });
    } catch (err) {
      cogoToast.error("Error Saving Visit Data");
      console.log(err);
      this.setState({
        isSaving: false,
      });
    }
  };

  updateVisitStatus = async (status) => {
    try {
      await this.props.apiCallPost("/visit/events/createNewVisitEvent", {
        visitId: this.state._id,
        statusTo: status,
        header: "Visit Status Updated",
        creator: "User",
      });
      this.setState({
        visitStatus: status,
      });
    } catch (err) {
      cogoToast.error("Error Saving Visit Data");
      console.log(err);
      this.setState({
        isSaving: false,
      });
    }
  };

  updateProtocolStatus = async (protocolId, status) => {
    try {
      await this.props.apiCallPost("/visit/updateProtocolStatus", {
        visitId: this.state._id,
        protocolId: protocolId,
        status: status,
        note: "",
      });
      this.setState({
        protocolStatus: {
          ...this.state.protocolStatus,
          [protocolId]: {
            ...this.state.protocolStatus[protocolId],
            status: status,
            note: "",
          },
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  updateVisitClearance = async (
    entity,
    entityId,
    clearance,
    typeOfClearance,
    clearanceCase,
    selections,
    clearanceInsn = ""
  ) => {
    this.setState({
      isSaving: true,
    });
    try {
      let res = await this.props.apiCallPost("/visit/updateVisitClearance", {
        visitId: this.state._id,
        entity: entity,
        entityId: entityId,
        clearance: clearance,
        typeOfClearance: typeOfClearance,
        clearanceCase: clearanceCase,
        selections: selections,
        clearanceInsn: clearanceInsn,
      });
      cogoToast.success("Clearance Updated");
      this.setState(
        {
          isSaving: false,
          ...res,
        },
        () => {
          this.waitForPDF(entityId, entity);
        }
      );
    } catch (err) {
      cogoToast.error("Error Saving Visit Data");
      console.log(err);
      this.setState({
        isSaving: false,
      });
    }
  };
  waitForPDF = async (entityId, entityType) => {
    // wait 10 seconds
    await new Promise((resolve) => setTimeout(resolve, 10000));
    try {
      let res = await this.props.apiCallPost("/visit/getVisitByID", {
        visitId: this.state._id,
      });
      this.setState({
        ...res,
      });
    } catch (err) {
      console.log(err);
    }
  };

  getServiceSummary = ({ serviceId }) => {
    return this.state.serviceSummary[serviceId]?.summary;
  };
  reportMisclassified = async (g, index) => {
    try {
      await this.props.apiCallPost("/files/setStatus", {
        cfId: g.parentFile._id,
        processStatus: "NEEDS_MANUAL_CLASSIFICATION",
      });
      await this.props.apiCallPost("/files/groups/setAllArchived", {
        cfId: g.parentFile._id,
      });
      await this.props.apiCallPost("/visit/updateVisitScalars", {
        visitId: this.state._id,
        uops: [
          {
            key: "visitStatus",
            value: "PENDING_DOCUMENTS_FROM_CLINIC",
          },
        ],
      });
      cogoToast.success(
        "File has been pushed to the queue for classification."
      );
      this.setState({
        groups: [],
      });
    } catch (err) {
      cogoToast.error("Error in putting file in reclassification queue");
      console.log(err);
    }
  };
  reportIrrelevant = async (g, index) => {
    try {
      this.setState({ isSaving: true });
      await this.props.apiCallPost("/files/groups/updateInfo", {
        groupId: g._id,
        uops: [
          {
            key: "isArchived",
            value: true,
          },
        ],
      });
      const newGroups = this.state.groups.filter(
        (group) => group._id !== g._id
      );
      this.setState({ isSaving: false, groups: newGroups });
      cogoToast.success("File marked as irrelevant.");
    } catch (err) {
      cogoToast.error("Error in marking file as irrelevant");
    }
  };
  renderGroups = () => {
    if (!this.state.groups || !this.state.groups.length) {
      return <Alert severity="info">No files found.</Alert>;
    }

    let gg = this.state.groups;
    if (this.state.selectedServiceId) {
      const documentTypes = this.state.servicesInfo.find(
        (s) => s._id === this.state.selectedServiceId
      ).documentTypes;
      // filter groups by documentTypes
      gg = gg.filter((g) => {
        return documentTypes.includes(g.serviceDataType);
      });
    }

    let ggSorted = gg; // _.sortBy(gg, "extractedEEName");
    return (
      <Card sx={{ pt: 3, pb: 3, px: 3, boxShadow: 3, overflowY: "auto" }}>
        <Stack spacing={1}>
          <Box
            rowGap={2}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(1, 1fr)",
              md: "repeat(1, 1fr)",
              lg: "repeat(1, 1fr)",
              xl: "repeat(1, 1fr)",
            }}
          >
            {ggSorted.map((g, index) => {
              return (
                <Stack key={index}>
                  <Typography variant="h4" sx={{ mb: 3 }}>
                    {g.service?.serviceName}
                  </Typography>
                  <DocumentGroup
                    showMaxButton={true}
                    onMaximise={() => {
                      this.setState({
                        maximiseGroup: g,
                      });
                    }}
                    onUnmaximise={() => {
                      this.setState({
                        maximiseGroup: null,
                      });
                    }}
                    data={g}
                    groupIx={index}
                    fromVisitPage={false}
                    showDocumentHeader={false}
                    cfId={g._id}
                    hideLinkToVisit={true}
                    markAsIrrelevant={() => {
                      this.reportIrrelevant(g, index);
                    }}
                    markAsReclassify={() => {
                      this.reportMisclassified(g, index);
                    }}
                  />
                </Stack>
              );
            })}
          </Box>
        </Stack>
      </Card>
    );
  };
  renderHistoricalGroups = () => {
    if (!this.state.historicalData) return null;
    if (!this.state.selectedServiceId) return null;
    if (this.state.selectedType !== "SERVICE") return null;
    let hasHistory =
      this.state.historicalData && this.state.historicalData.length > 0;
    if (!hasHistory) return null;

    let hd = this.state.historicalData;
    return (
      <Stack spacing={2}>
        <Typography variant="h6">Previous Encounters</Typography>
        {hd.map((h, index) => {
          return (
            <Card sx={{ p: 1, boxShadow: 3 }} key={index}>
              <DocumentGroup
                showMaxButton={true}
                onMaximise={() => {
                  this.setState({
                    maximiseGroup: h,
                  });
                }}
                onUnmaximise={() => {
                  this.setState({
                    maximiseGroup: null,
                  });
                }}
                data={h}
                isHistorical={true}
                groupIx={index}
                fromVisitPage={false}
                showDocumentHeader={false}
                cfId={h._id}
                hideLinkToVisit={true}
              />
            </Card>
          );
        })}
      </Stack>
    );
  };
  renderEmployeeOutreach = () => {
    if (this.state.isLoadingEvents) return <LinearProgress />;
    const events = this.state.events.filter((e) =>
      e.header.includes("Employee Outreach Attempt - Documents")
    );

    let visitOutreachTimeline = null;
    if (events.length > 0) {
      visitOutreachTimeline = (
        <List>
          {events.map((vvtl, index) => {
            let date = new Date(vvtl.createdAt);
            let niceDate = format(date, "PPpp");
            return (
              <ListItem key={index}>
                <ListItemText primary={vvtl.notes} secondary={niceDate} />
              </ListItem>
            );
          })}
        </List>
      );
    } else {
      visitOutreachTimeline = (
        <Typography variant="caption">No Outreach Attempts Logged</Typography>
      );
    }
    return (
      <Stack spacing={1} direction="row" alignItems={"center"}>
        <Button
          size="small"
          variant="contained"
          color="info"
          onClick={() => {
            this.setState({
              openOutreachAttemptModal: true,
            });
          }}
        >
          Log Employee Outreach
        </Button>
        <Tooltip title={visitOutreachTimeline}>
          <Badge badgeContent={events.length} color="secondary">
            <IconButton>
              <Iconify icon="mdi:timeline-clock-outline" />
            </IconButton>
          </Badge>
        </Tooltip>
      </Stack>
    );
  };
  renderVisitComments = () => {
    return (
      <Stack spacing={1}>
        <Typography variant="overline">Internal Comments</Typography>
        <Paper
          sx={{
            maxHeight: "40vh",
            overflowY: "auto",
          }}
        >
          {this.state.commentsThread?.map((comment, index) => (
            <Card
              sx={{
                p: 1,
                boxShadow: 2,
                bgcolor:
                  index % 2 === 0 ? "background.paper" : "background.neutral",
              }}
            >
              <Stack spacing={1} key={index}>
                <Stack direction="row" spacing={0.5}>
                  <Typography variant="overline">
                    {comment.createdByName}
                  </Typography>
                  <Typography variant="caption">
                    {format(new Date(comment.createdAt), "PPpp")}
                  </Typography>
                </Stack>
                <Typography variant="caption">{comment.comment}</Typography>
              </Stack>
            </Card>
          ))}
        </Paper>
        <Stack spacing={1}>
          <TextareaAutosize
            minRows={3}
            onChange={(e) => {
              this.setState({ comment: e.target.value });
            }}
            value={this.state.comment}
          />
          <LoadingButton
            loading={this.state.isAddingComment}
            size="small"
            variant="contained"
            color="primary"
            icon={<Iconify icon="material-symbols:add-comment" />}
            onClick={() => {
              this.addComment({ comment: this.state.comment });
            }}
          >
            Add Comment
          </LoadingButton>
        </Stack>
      </Stack>
    );
  };
  renderEval = (text) => {
    if (!text) {
      return null;
    }
    let s = text;
    let lines = s.split("\n");
    return (
      <Stack spacing={2}>
        <Typography variant="overline">AI Evaluation</Typography>
        <Divider />
        <Stack spacing={0}>
          {lines.map((line) => {
            let ll = _.trim(line);
            if (ll.startsWith("Recommendation:")) {
              return (
                <Stack spacing={1} direction="row" alignItems={"center"}>
                  {" "}
                  <Box
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Chip
                      label={`AI ${line}`}
                      color="secondary"
                      size="small"
                      variant="outlined"
                    />
                  </Box>
                </Stack>
              );
            }
            return <Typography variant="caption">{line}</Typography>;
          })}
        </Stack>
      </Stack>
    );
  };
  renderSummary = (text) => {
    if (!text) {
      return <Typography variant="caption">No summary generated</Typography>;
    }

    let s = text;
    let lines = s.split("\n");
    return (
      <Stack spacing={1}>
        <Stack spacing={0}>
          {lines.map((line) => {
            // if we find word "Red Flags:", render a chip with label Red Flag & then rest of the text.
            let ll = _.trim(line);
            if (ll.startsWith("Red Flags:")) {
              let rest = ll.replace("Red Flags:", "");
              return (
                <Stack spacing={1}>
                  {" "}
                  <Box
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Chip
                      label="Red Flags"
                      color="error"
                      size="small"
                      variant="outlined"
                    />
                  </Box>
                  <Typography variant="caption">{rest}</Typography>
                </Stack>
              );
            }
            // Headline: ==> info
            if (ll.startsWith("Headline:")) {
              let rest = ll.replace("Headline:", "");
              return (
                <Stack spacing={1}>
                  <Typography variant="caption">{rest}</Typography>
                </Stack>
              );
            }
            // Summary: ==> secondary
            if (ll.startsWith("Summary:")) {
              let rest = ll.replace("Summary:", "");
              return (
                <Stack spacing={1}>
                  <Box
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Chip
                      variant="outlined"
                      label="Summary"
                      color="secondary"
                      size="small"
                    />
                  </Box>
                  <Typography variant="caption">{rest}</Typography>
                </Stack>
              );
            }
            return <Typography variant="caption">{line}</Typography>;
          })}
        </Stack>
      </Stack>
    );
  };
  renderClearanceTab = () => {
    if (!this.state.selectedServiceId) {
      return (
        <Alert severity="info">
          Select a service or protocol from the left panel.
        </Alert>
      );
    }
    if (this.state.isLoadingGroups) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <Alert severity="error">Error Loading Data</Alert>;
    }
    if (this.state.selectedType === "SERVICE") {
      const serviceDetails = this.state.servicesInfo.find(
        (bs) => bs._id === this.state.selectedServiceId
      );
      const serviceSummary = this.state.serviceSummary || {};
      let evalElem = serviceSummary[serviceDetails._id]
        ? this.renderSummary(serviceSummary[serviceDetails._id].summary)
        : null;
      if (!evalElem) {
        evalElem = (
          <Button
            size="small"
            onClick={() =>
              this.generateServiceSummary({
                serviceId: this.state.selectedServiceId,
              })
            }
          >
            Generate AI Summary
          </Button>
        );
      }

      return (
        <Stack spacing={2}>
          <Typography variant="h4">{serviceDetails.serviceName}</Typography>{" "}
          {evalElem}
          {/* <Card sx={{ p: 1 }} key={bookedService._id}>
            <Stack spacing={2}>
              <FormControl>
                <InputLabel labelId="select-label">Clearance</InputLabel>
                <Select
                  labelId="select-label"
                  size="small"
                  disabled={this.state.isSaving}
                  value={
                    serviceDetails.clearanceOptions.find(
                      (c) => c.case === bookedService.clearanceCase
                    )?._id || ""
                  }
                  sx={{
                    minWidth: "150px",
                  }}
                  onChange={(e) => {
                    const idx = this.state.bookedServices.findIndex(
                      (s) => s.serviceId === this.state.selectedServiceId
                    );
                    const selectedOption = serviceDetails.clearanceOptions.find(
                      (option) => option._id === e.target.value
                    );
                    const bookedServices = this.state.bookedServices;
                    if (idx > -1) {
                      bookedServices[idx].clearanceCase = selectedOption.case;
                      bookedServices[idx].clearance = selectedOption.text;
                      bookedServices[idx].typeOfClearance =
                        selectedOption.typeOfClearance;
                      this.setState(
                        {
                          bookedServices,
                          selectedServiceClearanceCase: selectedOption.case,
                          selectedServiceClearanceLanguage: selectedOption.text,
                        },
                        () => {
                          // this.updateVisitScalars([
                          //   {
                          //     key: "bookedServices",
                          //     value: this.state.bookedServices,
                          //   },
                          // ]);
                          this.updateVisitClearance(
                            // visitId
                            "MasterService",
                            this.state.selectedServiceId,
                            selectedOption.text,
                            selectedOption.typeOfClearance,
                            selectedOption.case,
                            ""
                          );
                        }
                      );
                    }
                  }}
                >
                  {serviceDetails.clearanceOptions.map((option, index) => (
                    <MenuItem key={index} value={option._id}>
                      {option.case}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextareaAutosize
                minRows={4}
                value={
                  this.state.selectedServiceClearanceLanguage ||
                  bookedService.clearance
                }
                onChange={(e) => {
                  this.setState({
                    selectedServiceClearanceLanguage: e.target.value,
                  });
                }}
                onBlur={() => {
                  const idx = this.state.bookedServices.findIndex(
                    (s) => s.serviceId === this.state.selectedServiceId
                  );
                  const bookedServices = this.state.bookedServices;
                  if (idx > -1) {
                    bookedServices[idx].clearance =
                      this.state.selectedServiceClearanceLanguage ||
                      bookedService.clearance;
                    this.setState(
                      {
                        bookedServices,
                      },
                      () => {
                        this.updateVisitScalars([
                          {
                            key: "bookedServices",
                            value: this.state.bookedServices,
                          },
                        ]);
                      }
                    );
                  }
                }}
              />
            </Stack>
          </Card> */}
          <Stack>{this.renderGroups()}</Stack>
          <Stack>{this.renderHistoricalGroups()}</Stack>
        </Stack>
      );
    } else if (this.state.selectedType === "PROTOCOL") {
      const protocolDetails = this.state.protocolsInfo.find(
        (bs) => bs._id === this.state.selectedServiceId
      );

      if (!protocolDetails) {
        return <Alert severity="info">No protocol details found</Alert>;
      }

      if (protocolDetails.clearanceOptions.length === 0) {
        return <Alert severity="info">No clearance options found</Alert>;
      }

      let protocolSummary = this.state.protocolSummary;
      let protocolSummaryObj = protocolSummary[protocolDetails._id];
      let protocolClearance = this.state.protocolClearance;
      let protocolClearanceObj = protocolClearance[protocolDetails._id];

      if (protocolClearanceObj == null) {
        protocolClearanceObj = {
          clearance: protocolDetails.clearanceOptions[0].text,
          typeOfClearance: protocolDetails.clearanceOptions[0].typeOfClearance,
          case: protocolDetails.clearanceOptions[0].case,
          note: "",
          insn: "",
          details: {},
          ts: new Date(),
          pdfKey: "",
          clearancePsUrl: "",
        };
      }

      let clGroundTruth = "";
      for (let co of protocolDetails.clearanceOptions) {
        if (co.case === this.state.selectedServiceClearanceCase) {
          clGroundTruth = co.text;
        }
      }

      // let idx = _.findIndex(
      //   protocolSummaries,
      //   (s) => s.protocolId === protocolDetails._id
      // );
      // let protocolSummaryObj = {};
      // if (idx > -1) {
      //   protocolSummaryObj = protocolSummaries[idx];
      // }

      let evalElem = protocolSummaryObj
        ? this.renderEval(protocolSummaryObj.evaluation)
        : null;
      if (!evalElem) {
        evalElem = (
          <Button
            size="small"
            onClick={() =>
              this.generateProtocolEval({
                protocolId: this.state.selectedServiceId,
              })
            }
          >
            Generate AI Evaluation
          </Button>
        );
      }
      if (!protocolSummaryObj?.summary) {
        evalElem = (
          <Button
            size="small"
            onClick={() =>
              this.generateProtocolSummary({
                protocolId: this.state.selectedServiceId,
              })
            }
          >
            Generate AI Summary & Evaluation
          </Button>
        );
      }
      return (
        <Stack spacing={2}>
          <Typography variant="h4">
            {protocolDetails.companyProtocolName}
          </Typography>{" "}
          {evalElem}
          <Card sx={{ p: 1 }} key={protocolDetails._id}>
            <Stack spacing={2}>
              <FormControl>
                <InputLabel labelId="select-label">Clearance</InputLabel>
                <Select
                  labelId="select-label"
                  size="small"
                  disabled={this.state.isSaving}
                  value={this.state.selectedServiceClearanceCase}
                  sx={{
                    minWidth: "150px",
                  }}
                  onChange={(e) => {
                    console.log("case > select", e.target.value);
                    const selectedOption =
                      protocolDetails.clearanceOptions.find(
                        (option) => option.case === e.target.value
                      );

                    protocolClearanceObj.case = selectedOption.case;
                    protocolClearanceObj.clearance = selectedOption.text;
                    protocolClearanceObj.typeOfClearance =
                      selectedOption.typeOfClearance;
                    protocolClearanceObj.selections = {};
                    this.setState(
                      {
                        protocolClearance: {
                          ...protocolClearance,
                          [protocolDetails._id]: protocolClearanceObj,
                        },
                        selectedServiceClearanceCase: selectedOption.case,
                        selectedServiceClearanceLanguage: selectedOption.text,
                        clearanceLanguageGroundTruth: selectedOption.text,
                      },
                      () => {
                        this.updateVisitClearance(
                          // visitId
                          "Protocol",
                          this.state.selectedServiceId,
                          selectedOption.text,
                          selectedOption.typeOfClearance,
                          selectedOption.case,
                          protocolClearanceObj.selections,
                          ""
                        );
                      }
                    );
                  }}
                >
                  {protocolDetails.clearanceOptions.map((option, index) => (
                    <MenuItem key={index} value={option.case}>
                      {option.case}
                    </MenuItem>
                  ))}
                </Select>
                <DynamicTextWithSelects
                  disabled={this.state.isSaving}
                  initialText={clGroundTruth || ""}
                  onTextChange={(text, selections) => {
                    protocolClearanceObj.clearance = text;
                    protocolClearanceObj.selections = selections;
                    this.setState(
                      {
                        selectedServiceClearanceLanguage: text,
                        protocolClearance: {
                          ...protocolClearance,
                          [protocolDetails._id]: protocolClearanceObj,
                        },
                      },
                      () => {
                        this.updateVisitClearance(
                          "Protocol",
                          this.state.selectedServiceId,
                          protocolClearanceObj.clearance,
                          protocolClearanceObj.typeOfClearance,
                          protocolClearanceObj.case,
                          protocolClearanceObj.selections,
                          ""
                        );
                      }
                    );
                  }}
                />
              </FormControl>
              <Typography variant="caption">Clearance Language</Typography>
              <TextareaAutosize
                minRows={4}
                disabled={this.state.isSaving}
                value={this.state.selectedServiceClearanceLanguage}
                onChange={(e) => {
                  this.setState({
                    selectedServiceClearanceLanguage: e.target.value,
                  });
                }}
                onBlur={() => {
                  protocolClearanceObj.clearance =
                    this.state.selectedServiceClearanceLanguage;
                  this.setState(
                    {
                      protocolClearance: {
                        ...protocolClearance,
                        [protocolDetails._id]: protocolClearanceObj,
                      },
                    },
                    () => {
                      this.updateVisitClearance(
                        "Protocol",
                        this.state.selectedServiceId,
                        protocolClearanceObj.clearance,
                        protocolClearanceObj.typeOfClearance,
                        protocolClearanceObj.case,
                        protocolClearanceObj.selections,
                        ""
                      );
                    }
                  );
                }}
              />
            </Stack>
          </Card>
        </Stack>
      );
    }
  };

  renderServicesList = () => {
    if (this.state.isLoading) return <LinearProgress />;
    if (this.state.servicesInfo.length === 0) return null;
    let bs = this.state.servicesInfo;

    return (
      <List>
        {bs.map((service, index) => {
          let serviceSummary = this.state.serviceSummary || {};

          let summary = serviceSummary[service._id];

          let summaryElem = null;
          if (summary && summary.summary) {
            summaryElem = this.renderSummary(summary.summary);
          } else {
            summaryElem = (
              <LoadingButton
                loading={this.state.isSummarizing}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState(
                    {
                      selectedServiceId: service._id,
                      selectedType: "SERVICE",
                    },
                    () => {
                      this.generateServiceSummary({
                        serviceId: service._id,
                      });
                    }
                  );
                }}
              >
                Summarize
              </LoadingButton>
            );
          }
          let obj = service;
          let documentTypes = service.documentTypes || [];
          let linkedGroups = obj.linkedGroups;
          let docTypesObj = {};
          // for (let i = 0; i < documentTypes.length; i++) {
          //   let key = documentTypes[i];
          //   docTypesObj[key] = false;
          //   let pdt = linkedGroups.map((x) => x.pageDataType);
          //   if (pdt.includes(key)) {
          //     docTypesObj[key] = true;
          //   }
          // }
          // let typeOfClearance = obj.typeOfClearance;
          // let clearanceElem = null;
          // if (typeOfClearance) {
          //   clearanceElem = (
          //     <Chip
          //       size="small"
          //       label={typeOfClearance}
          //       color={this.CLEARANCE_TYPE_META[typeOfClearance].color}
          //       icon={
          //         <Iconify
          //           icon={this.CLEARANCE_TYPE_META[typeOfClearance].icon}
          //         />
          //       }
          //     />
          //   );
          // }
          let docTypesElem = (
            <Stack spacing={0.5}>
              {service.documentTypes.map((key, index) => {
                return (
                  <Box>
                    <Chip
                      size="small"
                      key={index}
                      label={key}
                      icon={<Iconify icon="mdi:file-document-tick-outline" />}
                      color={"info"}
                    />
                  </Box>
                );
              })}
            </Stack>
          );
          // let pdfElem = null;
          // if (obj.clearancePsUrl) {
          //   pdfElem = (
          //     <IconButton href={obj.clearancePsUrl} target="_blank">
          //       <Iconify icon="codicon:file-symlink-file" />
          //     </IconButton>
          //   );
          // }
          let anyFalse = Object.values(docTypesObj).includes(false);
          if (anyFalse) {
            summaryElem = null;
          }
          return (
            <ListItem
              key={index}
              sx={{
                bgcolor:
                  this.state.selectedServiceId === service._id
                    ? "warning.lighter"
                    : "",
                p: 2,
                "&:hover": {
                  cursor: "pointer",
                  bgcolor: "background.neutral",
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                this.setState(
                  {
                    selectedServiceId: service._id,
                    selectedType: "SERVICE",
                  },
                  () => {
                    const serviceDetails = this.state.servicesInfo.find(
                      (bs) => bs._id === this.state.selectedServiceId
                    );
                    let documentTypes = serviceDetails.documentTypes;
                    console.log("STATE", documentTypes);
                    this.getPreviousData(documentTypes);
                  }
                );
              }}
            >
              <Stack spacing={1}>
                <Typography variant="overline">
                  {service.serviceName}
                </Typography>
                <Stack direction="row" spacing={0.5} alignItems={"center"}>
                  {docTypesElem}
                  {/* {clearanceElem} */}
                  {/* {pdfElem} */}
                </Stack>
                {summaryElem}
              </Stack>
            </ListItem>
          );
        })}
      </List>
    );
  };
  renderProtocolList = () => {
    if (this.state.isLoading) return <LinearProgress />;
    if (this.state.protocolsInfo.length === 0) return null;

    return (
      <List>
        {this.state.protocolsInfo.map((protocol, index) => {
          let protocolSummaries = this.state.protocolSummary;
          let protocolClearance = this.state.protocolClearance;

          let summary = protocolSummaries[protocol._id];
          let clearance = protocolClearance[protocol._id];

          let summaryElem = null;
          if (summary) {
            summaryElem = this.renderSummary(summary.summary);
          } else {
            summaryElem = (
              <LoadingButton
                loading={this.state.isSummarizing}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState(
                    {
                      selectedServiceId: protocol._id,
                      selectedType: "PROTOCOL",
                    },
                    () => {
                      this.generateProtocolSummary({
                        protocolId: protocol._id,
                      });
                    }
                  );
                }}
              >
                Summarize
              </LoadingButton>
            );
          }

          let clearanceElem = null;
          let pdfElem = null;
          if (clearance) {
            let obj = clearance;
            let typeOfClearance = obj.typeOfClearance;
            if (typeOfClearance) {
              clearanceElem = (
                <Chip
                  size="small"
                  label={typeOfClearance}
                  color={this.CLEARANCE_TYPE_META[typeOfClearance].color}
                  icon={
                    <Iconify
                      icon={this.CLEARANCE_TYPE_META[typeOfClearance].icon}
                    />
                  }
                />
              );
            }
            if (obj.clearancePsUrl) {
              pdfElem = (
                <IconButton href={obj.clearancePsUrl} target="_blank">
                  <Iconify icon="codicon:file-symlink-file" />
                </IconButton>
              );
            }
          }
          return (
            <ListItem
              key={index}
              sx={{
                bgcolor:
                  this.state.selectedServiceId === protocol._id
                    ? "warning.lighter"
                    : "",
                p: 2,
                "&:hover": {
                  cursor: "pointer",
                  bgcolor: "background.neutral",
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                const protocolClearanceObj =
                  this.state.protocolClearance[protocol._id];
                console.log(
                  "protoClick > protocolClearanceObj",
                  protocolClearanceObj
                );
                this.setState({
                  selectedServiceId: protocol._id,
                  selectedType: "PROTOCOL",
                  selectedServiceClearanceCase: protocolClearanceObj?.case,
                  selectedServiceClearanceLanguage:
                    protocolClearanceObj?.clearance,
                });
              }}
            >
              <Stack spacing={1}>
                <Stack>
                  <Typography variant="overline">
                    {protocol.companyProtocolName}
                  </Typography>
                  <Typography variant="caption">
                    {protocol.companyProtocolType}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={0.5} alignItems={"center"}>
                  {clearanceElem}
                  {pdfElem}
                </Stack>
                {summaryElem}
              </Stack>
            </ListItem>
          );
        })}
      </List>
    );
  };
  renderEEDetails = () => {
    let phone =
      this.state.employee.employeeOfficePhone ||
      this.state.employee.employeePersonalPhone ||
      "Phone Not On File";
    return (
      <Stack>
        <Typography variant="overline">
          {this.state.employee.employeeName}
        </Typography>
        <Typography variant="caption">
          DOB: {niceDate(this.state.employee.employeeDoB)} |{" "}
          {this.state.employee.employeeSex}
        </Typography>
        <Typography variant="caption">
          SSN: {this.state.employee.employeeSSN} | EID:{" "}
          {this.state.employee.employeeNumber}
        </Typography>
        <Typography variant="caption">
          {this.props.data.employee.employeeCity}{" "}
          {this.props.data.employee.employeeState}{" "}
          {this.props.data.employee.employeePostalCode}
        </Typography>
        <Button
          icon={<Iconify icon="carbon:phone" />}
          disabled={phone === "Phone Not On File"}
          href={`tel:${phone}`}
          variant="outlined"
          color="primary"
          size="small"
        >
          {phone}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          icon={<Iconify icon="pajamas:duplicate" />}
          onClick={() => {
            navigator.clipboard.writeText(this.state.humanReadableId);
            cogoToast.success("Visit ID copied to clipboard");
          }}
        >
          Visit ID: {this.state.humanReadableId}
        </Button>
      </Stack>
    );
  };
  renderVisitDrawer = () => {
    let drawerContents = (
      <Paper
        style={{
          width: "95vw",
          height: "100vh",
          overflowY: "auto",
          padding: "5px",
          bgcolor: "background.paper",
        }}
      >
        <Grid
          container
          spacing={0.5}
          width="95vw"
          sx={{
            padding: "1rem",
            height: "100%",
          }}
        >
          <Grid item xs={3}>
            <Stack>
              <Typography variant="overline">Protocols</Typography>
              {this.renderProtocolList()}
              <Typography variant="overline">Services</Typography>
              {this.renderServicesList()}
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              maxHeight: "100vh",
              overflowY: "auto",
            }}
          >
            {this.renderClearanceTab()}
          </Grid>
          <Grid item xs={3}>
            <Card
              sx={{
                p: 1,
                height: "100%",
              }}
            >
              <Stack
                spacing={1}
                justifyContent={"space-between"}
                sx={{
                  height: "100%",
                }}
              >
                <Stack spacing={1}>
                  {this.renderEEDetails()}
                  <Typography variant="overline">
                    Set Protocol Status
                  </Typography>
                  <FormControl>
                    <Typography variant="subtitle2">
                      {this.state.protocolsInfo[0].companyProtocolName}
                    </Typography>
                    <Select
                      disabled={this.state.isSaving}
                      value={
                        this.state.protocolStatus[
                          this.state.protocolsInfo[0]._id
                        ]?.status || "<Protocol Status>"
                      }
                      onChange={(e) => {
                        this.updateProtocolStatus(
                          this.state.protocolsInfo[0]._id,
                          e.target.value
                          //[
                          //</FormControl>{ key: "visitStatus", value: e.target.value },
                          //]
                        );
                      }}
                    >
                      {PROTOCOL_STATUS.map((status, index) => (
                        <MenuItem key={index} value={status}>
                          {status}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography variant="overline">Set Visit Status</Typography>
                  <FormControl>
                    <Select
                      disabled={this.state.isSaving}
                      value={this.state.visitStatus}
                      onChange={(e) => {
                        this.setState(
                          {
                            visitStatus: e.target.value,
                          },
                          () => {
                            this.updateVisitStatus(e.target.value);
                          }
                        );
                      }}
                    >
                      {VISIT_STATUS.map((status, index) => (
                        <MenuItem key={index} value={status}>
                          {status}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {this.renderEmployeeOutreach()}
                  {this.renderFileList()}
                </Stack>
                {this.renderVisitComments()}
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Paper>
    );
    if (this.state.maximiseGroup) {
      let isHistorical = false;
      let groupId = this.state.maximiseGroup._id;
      let g = this.state.groups.find((g) => g._id === groupId);
      let index = this.state.groups.findIndex((g) => g._id === groupId);
      if (!g) {
        // check if historical
        g = this.state.historicalData.find((g) => g._id === groupId);
        index = this.state.historicalData.findIndex((g) => g._id === groupId);
        if (!g) {
          return null;
        }
        isHistorical = true;
      }
      if (!g) {
        return null;
      }
      drawerContents = (
        <Paper
          style={{
            width: "95vw",
            height: "100vh",
            overflowY: "auto",
            padding: "5px",
            bgcolor: "background.paper",
          }}
        >
          <Grid
            container
            spacing={0.5}
            width="95vw"
            sx={{
              padding: "1rem",
              height: "100%",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                maxHeight: "100vh",
                overflowY: "auto",
              }}
            >
              <DocumentGroup
                isHistorical={isHistorical}
                showMaxButton={true}
                maximised={true}
                onMinimise={() => {
                  this.setState({
                    maximiseGroup: null,
                  });
                }}
                data={g}
                groupIx={index}
                fromVisitPage={false}
                showDocumentHeader={false}
                cfId={g._id}
                hideLinkToVisit={true}
                markAsIrrelevant={() => {
                  this.reportIrrelevant(g, index);
                }}
                markAsReclassify={() => {
                  this.reportMisclassified(g, index);
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      );
    }
    return (
      <Drawer
        open={this.state.openVisitDrawer}
        onClose={() => {
          this.setState({ openVisitDrawer: false }, () => {
            this.endTimer();
          });
        }}
        anchor="right"
      >
        {drawerContents}
      </Drawer>
    );
  };
  uploadHelper = async (filesToBeUploaded) => {
    let ftbu = filesToBeUploaded;
    let len = ftbu.length;
    if (!len) {
      this.setState({
        isUploading: false,
      });
      return;
    }

    for (let i = 0; i < ftbu.length; i++) {
      cogoToast.loading(`Uploading ${i + 1} of ${len}`);
      console.log(ftbu[i]);
      await this.uploadSingle(ftbu[i]);
      cogoToast.success(`Uploaded ${i + 1} of ${len}`);
    }
    this.setState({
      isUploading: false,
    });
  };
  uploadSingle = async (file) => {
    try {
      const fd = new FormData();
      fd.append("file", file);
      fd.append("entityType", "VISIT_AUX_FILE");
      fd.append("entityId", this.props.data._id);
      fd.append("description", file.name);
      let res = await this.props.apiCallPostUpload(
        "/files/addToEntity",
        fd,
        (e) => {}
      );
      let ftbu = this.state.fileList;
      ftbu.push(res);
      return;
    } catch (err) {
      cogoToast.error("Error Uploading File");
      console.log(err);
    }
  };
  uploadMultiple = async (filesToBeUploaded) => {
    this.setState(
      {
        isUploading: true,
      },
      () => {
        this.uploadHelper(filesToBeUploaded);
      }
    );
  };
  renderFileList = () => {
    if (this.state.isLoadingFiles) return <LinearProgress />;
    let fileList = this.state.fileList;

    return (
      <Stack spacing={0.5}>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="overline">Files</Typography>{" "}
          <UploadBox
            disabled={this.state.isUploading}
            onDrop={(e) => this.uploadMultiple(e)}
            placeholder={
              <Stack alignItems="center" sx={{ color: "text.disabled" }}>
                <LoadingButton loading={this.state.isUploading}>
                  <Iconify icon="eva:cloud-upload-fill" width={17} />
                </LoadingButton>
              </Stack>
            }
          />
        </Stack>{" "}
        <List>
          {fileList.map((file, index) => {
            return (
              <ListItem key={index}>
                <Stack
                  sx={{
                    width: "100%",
                  }}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <Stack spacing={0.5}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Iconify icon="mi:attachment" />
                      <Typography variant="overline">
                        {file.originalName}
                      </Typography>
                    </Stack>
                    <Typography variant="caption">
                      {formatFileSize(file.sizeBytes)} |{file.createdBy?.name}{" "}
                      at {niceDateTime(new Date(file.createdAt))}
                    </Typography>
                  </Stack>
                </Stack>
                <IconButton href={file.psUrl} target="_blank">
                  <Iconify icon="quill:link-out" />
                </IconButton>
              </ListItem>
            );
          })}
        </List>
      </Stack>
    );
  };
  render() {
    if (this.state.isLoading) {
      return (
        <Accordion>
          <AccordionSummary>
            <Stack direction="row" spacing={2} alignItems="center">
              <Skeleton variant="text" width={200} />
              <Skeleton variant="text" width={200} />
              <Skeleton variant="text" width={200} />
            </Stack>
          </AccordionSummary>
          <AccordionDetails></AccordionDetails>
        </Accordion>
      );
    }

    let diff = 0;
    let diffText = "N/A";
    if (this.props.data.firstDocumentReceivedAt) {
      const d1 = new Date(this.props.data.firstDocumentReceivedAt);
      const d2 = new Date();
      diff = (d2 - d1) / 1000;
      if (diff > 0) {
        diffText = formatTime(diff);
      }
    }

    let visitStatusElemText = _.startCase(_.lowerCase(this.state.visitStatus));
    // replace "rn" and "md" with "RN" and "MD"
    if (visitStatusElemText.includes("Rn")) {
      visitStatusElemText = visitStatusElemText.replace("Rn", "RN");
    }
    if (visitStatusElemText.includes("Md")) {
      visitStatusElemText = visitStatusElemText.replace("Md", "MD");
    }
    let visitStatusElem = (
      <Chip
        variant="contained"
        label={visitStatusElemText}
        color={this.VISIT_STATUS_META[this.props.data.visitStatus].color}
        icon={
          <Iconify
            icon={this.VISIT_STATUS_META[this.props.data.visitStatus].icon}
          />
        }
      />
    );
    const uniqProtocols = this.state.protocolsInfo;
    let protocolNamesElem = null;

    protocolNamesElem = (
      <Tooltip title="Booked Protocols">
        <Stack direction="row" spacing={0.5} flexWrap={"wrap"}>
          {uniqProtocols.map((p, index) => {
            return (
              <Chip
                key={index}
                size="small"
                variant={p.isUrgent ? "contained" : "outlined"}
                color={p.isUrgent ? "error" : "primary"}
                label={p.companyProtocolName}
              />
            );
          })}
        </Stack>
      </Tooltip>
    );

    let employeeDataElem = (
      <Stack direction="column" spacing={0.5}>
        <Stack direction="row" spacing={0.5} alignItems={"center"}>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
            }}
            color={this.VISIT_STATUS_META[this.state.visitStatus].color}
          >
            {_.upperCase(this.props.data.employee.employeeName)}{" "}
          </Typography>
          <Typography variant="caption">
            {niceDate(new Date(this.props.data.employee.employeeDoB))} |{" "}
            {this.props.data.employee.employeeSex}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={0.5}>
          <Typography variant="overline">
            {this.props.data.employee.employeePersonalPhone ||
              this.props.data.employee.employeeOfficePhone ||
              "Phone not on file"}{" "}
            |
          </Typography>
          <Typography variant="caption">
            {this.props.data.employee.employeeCity}{" "}
            {this.props.data.employee.employeeState}{" "}
            {this.props.data.employee.employeePostalCode}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={0.5}>
          {" "}
          <Typography variant="overline">
            {this.props.data.company.companyName} |{" "}
          </Typography>
          <Typography variant="caption">
            EID: {this.props.data.employee.employeeNumber} |{" "}
          </Typography>
          <Typography variant="caption">
            SSN: {this.props.data.employee.employeeSSN || "No SSN on file"}
          </Typography>
        </Stack>
      </Stack>
    );
    let assignmentElem = null;
    if (!this.props.data.assignedRN && !this.props.data.assignedMD) {
      assignmentElem = (
        <Chip
          label="Not Assigned"
          variant="outlined"
          icon={<Iconify icon="mdi:user-off-outline" />}
          color="error"
        />
      );
    } else {
      let rnElem = null;
      let mdElem = null;
      if (this.props.data.assignedRN) {
        rnElem = (
          <Chip
            label={`RN: ${this.props.data.assignedRN.name}`}
            variant="contained"
            icon={<Iconify icon="mingcute:nurse-line" />}
            color="primary"
          />
        );
      } else {
        rnElem = (
          <Chip
            label={`No RN Assigned`}
            variant="outlined"
            icon={<Iconify icon="mingcute:nurse-line" />}
            color="primary"
          />
        );
      }

      if (this.props.data.assignedMD) {
        mdElem = (
          <Chip
            label={`MD: ${this.props.data.assignedMD.name}`}
            variant="contained"
            icon={<Iconify icon="healthicons:doctor" />}
            color="secondary"
          />
        );
      } else {
        mdElem = (
          <Chip
            label={`No MD Assigned`}
            variant="outlined"
            icon={<Iconify icon="healthicons:doctor" />}
            color="secondary"
          />
        );
      }
      assignmentElem = (
        <Stack direction="column" spacing={0.5}>
          {rnElem}
          {mdElem}
        </Stack>
      );
    }
    let commentsElem = null;
    if (this.state.commentsThread && this.state.commentsThread.length) {
      let lastComment = _.last(this.state.commentsThread);
      commentsElem = (
        <Stack spacing={0.5}>
          <Typography variant="overline">
            {lastComment.createdByName}
          </Typography>
          <Typography variant="body2">{lastComment.comment}</Typography>
          <Typography variant="caption">
            {niceDateTime(new Date(lastComment.createdAt))}
          </Typography>
        </Stack>
      );
    } else {
      commentsElem = (
        <Typography variant="caption">No comments so far</Typography>
      );
    }
    return (
      <>
        {/* {this.renderVisitDrawer()} */}
        {/* <VisitTimelineEventCreate
          visitId={this.state._id}
          isOpen={this.state.openOutreachAttemptModal}
          onClose={() => {
            this.setState({
              openOutreachAttemptModal: false,
            });
          }}
          handleClose={() => {
            this.setState({
              openOutreachAttemptModal: false,
            });
          }}
          onCreate={(res) => {
            this.setState({
              openOutreachAttemptModal: false,
              events: [...this.state.events, res],
            });
          }}
        /> */}
        <TableRow
          sx={{
            borderBottom: "1px solid #a0a0a0",
            "&:hover": {
              cursor: "pointer",
              bgcolor: "background.neutral",
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            // // this.startTimer();
            // this.setState(
            //   { openVisitDrawer: true, selectedServiceId: null },
            //   () => {
            //     this.getGroupsByVisit();
            //     this.getTimelineEvents();
            //   }
            // );
            window.open(
              `/visits/${this.state._id}?currentTab=WORKSPACE`,
              "_blank"
            );
          }}
        >
          <TableCell>
            <Stack spacing={0.2} direction="row">
              {this.props.isAssigning && (
                <Checkbox
                  disabled={this.props.isBulkSetting}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  checked={this.props.isSelected}
                  onChange={(e) => {
                    if (e.target.checked) {
                      this.props.onSelectVisitForAssigning();
                    } else {
                      this.props.onUnselectVisitForAssigning();
                    }
                  }}
                />
              )}
              <Stack spacing={0.5}>
                {visitStatusElem}
                {protocolNamesElem}
              </Stack>
            </Stack>
          </TableCell>
          <TableCell>{employeeDataElem}</TableCell>
          <TableCell>{assignmentElem}</TableCell>
          <TableCell>
            <Typography variant="overline">{diffText}</Typography>
          </TableCell>
          <TableCell>{commentsElem}</TableCell>
        </TableRow>
      </>
    );
  }
}

export default WithAPICall(VisitClearanceDetails);
