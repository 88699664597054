import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Autocomplete,
  Button,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { NoResultView, View500 } from "src/sections/error";
import cogoToast from "cogo-toast";
import moment from "moment";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { LoadingButton } from "@mui/lab";
import EachTask from "./EachTask";
import _ from "lodash";

class TaskList extends React.Component {
  state = {
    isLoading: true,
    showSearchControls: true,
    // cu
    currentUser: {
      isEmployee: true,
      isExternal: true,
    },
    // company
    isSearchingCompany: false,
    companySearchResults: [],
    currentlyInCompany: null,
    //
    taskDueDateIn: [moment(), moment().add(7, "days")],
    taskFinishedIn: [null, null],
    taskStatus: [],
    taskInterpreter: [],
    prefix: "",
    //
    protocolOptions: [],
    loadingCompanyProtocols: false,
    filterProtocolId: null,

    //
    isError: false,
    taskList: [],
  };
  componentDidMount() {
    this.getCurrentUser();
  }
  getCurrentUser = async () => {
    try {
      const res = await this.props.apiCallPost("/user/getCurrentUser", {});
      let cu = res;
      let isExternal = cu.isExternal;
      this.setState({
        currentUser: cu,
      });
      if (isExternal) {
        this.setState(
          {
            currentUser: cu,
            currentlyInCompany: {
              mainText: res.company.companyName,
              secondaryText: `${res.company.companyInformalName} ${res.companyWebsite}`,
              data: res.company,
            },
            taskDueDateIn: [
              moment().subtract(3, "days"),
              moment().add(7, "days"),
            ],

            isLoading: false,
          },
          () => {
            this.searchTasks();
          }
        );
      } else {
        this.setState(
          {
            isLoading: false,
            taskDueDateIn: [
              moment().subtract(3, "days"),
              moment().add(7, "days"),
            ],
          },
          () => {
            this.searchTasks();
          }
        );
      }
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading User Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };

  loadCompanyProtocols = async () => {
    cogoToast.loading("Loading company protocols.");
    try {
      const res = await this.props.apiCallPost(
        "/company-protocol/companyProtocolGetAll",
        {
          companyId: this.state.currentlyInCompany.data._id,
        }
      );
      this.setState({
        protocolOptions: res.filter(
          (p) => p.isPool && p.activeStatus === "ACTIVE"
        ),
      });
      cogoToast.success("Company protocols loaded.");
    } catch (error) {
      console.error("could not get company protocols", error);
    }
  };

  INTERPRETER_LIST = {
    CompanySetUp: "Set Up Employer Data",
    CAPSetUp: "Set Up Employer Provider Links",
    CompanyProtocolSetUp: "Set Up Employer Protocols",
    VisitSchedule: "Schedule Visit",
    VisitDocumentChase: "Documents Chaser",
    VisitClose: "Close Visit",
    ServiceSummarization: "Summarize Service",
    ProtocolSummarization: "Summarize Protocol",
    ServiceClearance: "Clear Service",
    ProtocolClearance: "Clear Protocol",
    ProcessDocument: "Process Document",
    AssociateWithVisit: "Link Document With Visit",
    VisitCreate: "Create Visit",
    DrawPool: "Create Pool Draw",
  };
  STATUS_LIST = {
    PENDING: "Pending",
    COMPLETED: "Completed",
    CANCELLED: "Cancelled",
    IN_PROGRESS: "In Progress",
  };
  searchTasks = async () => {
    let payload = {
      prefix: this.state.prefix || "",
      taskStatus: this.state.taskStatus || null,
      company: this.state.currentlyInCompany?.data?._id || null,
      taskInterpreter: this.state.taskInterpreter,
      taskTags: [],
    };
    if (this.state.taskDueDateIn.filter(Boolean).length === 2) {
      payload.taskDueDateIn = this.state.taskDueDateIn.map((each) =>
        each.toDate()
      );
    } else {
      payload.taskDueDateIn = [];
    }
    if (this.state.taskFinishedIn.filter(Boolean).length === 2) {
      payload.taskFinishedIn = this.state.taskFinishedIn.map((each) =>
        each.toDate()
      );
    } else {
      payload.taskFinishedIn = [];
    }

    if (this.state.filterProtocolId) {
      payload.protocol = this.state.filterProtocolId._id;
    }

    try {
      this.setState({ isSearching: true });
      let res = await this.props.apiCallPost("/task/searchTasks", payload);
      if (!res || !res.length) {
        this.setState({
          isLoading: false,
          taskList: [],
          isSearching: false,
        });
        return;
      }
      // filter tasklist to show only non-completed
      res = res.filter((r) => r.taskStatus !== "COMPLETED");
      let resSorted = res.sort((a, b) => {
        const statusOrder = [
          "PENDING",
          "IN_PROGRESS",
          "COMPLETED",
          "CANCELLED",
        ];
        const statusA = statusOrder.indexOf(a.taskStatus);
        const statusB = statusOrder.indexOf(b.taskStatus);

        if (statusA !== statusB) {
          return statusA - statusB;
        }

        if (a.taskDueDate < b.taskDueDate) {
          return -1;
        }
        if (a.taskDueDate > b.taskDueDate) {
          return 1;
        }
        return 0;
      });
      this.setState({
        isLoading: false,
        taskList: resSorted,
        isSearching: false,
      });
    } catch (err) {
      this.setState({ isError: true });
      console.error(err);
      cogoToast.error("Error fetching tasks");
    }
  };
  searchCompaniesFTS = async (t) => {
    try {
      let res = await this.props.apiCallPostCancellable("/company/search", {
        query: t,
      });
      if (res) {
        this.setState({
          isSearchingCompany: false,
          companySearchResults: res.map((each) => {
            return {
              mainText: each.companyName,
              secondaryText: `${each.companyInformalName} ${each.companyWebsite}`,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingCompany: false,
        companySearchResults: [],
      });
      cogoToast.error("Error Searching Companies");
    }
  };
  renderTask = (index) => {
    let task = this.state.taskList[index];
    return (
      <EachTask
        currentUser={this.state.currentUser}
        task={task}
        INTERPRETER_LIST={this.INTERPRETER_LIST}
        STATUS_LIST={this.STATUS_LIST}
        onUpdate={(data) => {
          let taskList = this.state.taskList;
          taskList[index] = data;
          this.setState({
            taskList: taskList,
          });
        }}
      />
    );
  };
  renderTaskList = () => {
    if (this.state.isSearching) {
      return <LinearProgress />;
    }
    let length = this.state.taskList.length;
    if (!length) {
      return <NoResultView />;
    }
    return (
      <Stack spacing={2}>
        {this.state.taskList.map((each, index) => {
          return this.renderTask(index);
        })}
      </Stack>
    );
  };
  renderHeader = () => {
    return (
      <Stack direction="row" spacing={2} alignItems={"center"}>
        {" "}
        <Typography variant="subtitle1">Tasks</Typography>
        <Button
          onClick={() => {
            this.setState({
              showSearchControls: !this.state.showSearchControls,
            });
          }}
        >
          {!this.state.showSearchControls
            ? "Show Search Controls"
            : "Hide Search Controls"}
        </Button>
      </Stack>
    );
  };
  renderCompanySearcher = () => {
    if (this.state.currentUser.isExternal) {
      return null;
    }
    return (
      <Autocomplete
        sx={{
          minWidth: "200px",
        }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option?.mainText
        }
        filterOptions={(x) => x}
        options={
          this.state.isSearchingCompany ? [] : this.state.companySearchResults
        }
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={this.state.currentlyInCompany}
        noOptionsText={
          this.state.isSearchingCompany ? "Searching..." : "No results found"
        }
        onChange={(_, value) => {
          this.setState(
            {
              currentlyInCompany: value,
              companySearchResults: [value, ...this.state.companySearchResults],
              protocolOptions: [],
              filterProtocolId: null,
            },
            () => {
              if (value) {
                this.loadCompanyProtocols();
              }
            }
          );
        }}
        onInputChange={(_, e) => {
          if (e === "") return null;
          if (!e) return null;
          if (e.length < 4) return null;
          this.setState(
            {
              isSearchingCompany: true,
            },
            () => {
              this.searchCompaniesFTS(e);
            }
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label="Search Employers" fullWidth />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ wordWrap: "break-word" }}>
                  <Box component="span">{option?.mainText}</Box>
                  <Typography variant="body2" color="text.secondary">
                    {option?.secondaryText}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    );
  };
  renderTaskDueDateIn = () => {
    const shortcutsItems = [
      {
        label: "Today",
        getValue: () => {
          const today = moment();
          return [today, today];
        },
      },
      {
        label: "Last 3 Days",
        getValue: () => {
          const today = moment();
          return [today.clone().subtract(3, "days"), today];
        },
      },
      {
        label: "Next 3 Days",
        getValue: () => {
          const today = moment();
          return [today, today.clone().add(3, "days")];
        },
      },
      {
        label: "Last 7 Days",
        getValue: () => {
          const today = moment();
          return [today.clone().subtract(7, "days"), today];
        },
      },
      {
        label: "Next 7 Days",
        getValue: () => {
          const today = moment();
          return [today, today.clone().add(7, "days")];
        },
      },
      {
        label: "Last 30 Days",
        getValue: () => {
          const today = moment();
          return [today.clone().subtract(30, "days"), today];
        },
      },
      {
        label: "Next 30 Days",
        getValue: () => {
          const today = moment();
          return [today, today.clone().add(30, "days")];
        },
      },
      {
        label: "This Month",
        getValue: () => {
          const today = moment();
          return [today.clone().startOf("month"), today.clone().endOf("month")];
        },
      },
      {
        label: "Next Month",
        getValue: () => {
          const today = moment();
          return [
            today.clone().add(1, "month").startOf("month"),
            today.clone().add(1, "month").endOf("month"),
          ];
        },
      },
      {
        label: "Last Month",
        getValue: () => {
          const today = moment();
          return [
            today.clone().subtract(1, "month").startOf("month"),
            today.clone().subtract(1, "month").endOf("month"),
          ];
        },
      },
      {
        label: "This Week",
        getValue: () => {
          const today = moment();
          return [today.clone().startOf("week"), today.clone().endOf("week")];
        },
      },
      {
        label: "Next Week",
        getValue: () => {
          const today = moment();
          return [
            today.clone().add(1, "week").startOf("week"),
            today.clone().add(1, "week").endOf("week"),
          ];
        },
      },
      {
        label: "Last Week",
        getValue: () => {
          const today = moment();
          return [
            today.clone().subtract(1, "week").startOf("week"),
            today.clone().subtract(1, "week").endOf("week"),
          ];
        },
      },
      {
        label: "Reset",
        getValue: () => [null, null],
      },
    ];
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateRangePicker
          slotProps={{
            shortcuts: {
              changeImportance: "accept",
              dense: true,
              subheader: (
                <Typography variant="caption" sx={{ p: 2 }}>
                  Shortcuts
                </Typography>
              ),
              items: shortcutsItems,
            },
            actionBar: { actions: [] },
          }}
          value={this.state.taskDueDateIn}
          localeText={{
            start: "From",
            end: "To",
          }}
          onChange={(newValue) => {
            this.setState({
              taskDueDateIn: newValue,
            });
          }}
        />
      </LocalizationProvider>
    );
  };
  renderTaskFinishedIn = () => {
    const shortcutsItems = [
      {
        label: "Today",
        getValue: () => {
          const today = moment();
          return [today, today];
        },
      },
      {
        label: "Last 3 Days",
        getValue: () => {
          const today = moment();
          return [today.clone().subtract(3, "days"), today];
        },
      },
      {
        label: "Next 3 Days",
        getValue: () => {
          const today = moment();
          return [today, today.clone().add(3, "days")];
        },
      },
      {
        label: "Last 7 Days",
        getValue: () => {
          const today = moment();
          return [today.clone().subtract(7, "days"), today];
        },
      },
      {
        label: "Next 7 Days",
        getValue: () => {
          const today = moment();
          return [today, today.clone().add(7, "days")];
        },
      },
      {
        label: "Last 30 Days",
        getValue: () => {
          const today = moment();
          return [today.clone().subtract(30, "days"), today];
        },
      },
      {
        label: "Next 30 Days",
        getValue: () => {
          const today = moment();
          return [today, today.clone().add(30, "days")];
        },
      },
      {
        label: "This Month",
        getValue: () => {
          const today = moment();
          return [today.clone().startOf("month"), today.clone().endOf("month")];
        },
      },
      {
        label: "Next Month",
        getValue: () => {
          const today = moment();
          return [
            today.clone().add(1, "month").startOf("month"),
            today.clone().add(1, "month").endOf("month"),
          ];
        },
      },
      {
        label: "Last Month",
        getValue: () => {
          const today = moment();
          return [
            today.clone().subtract(1, "month").startOf("month"),
            today.clone().subtract(1, "month").endOf("month"),
          ];
        },
      },
      {
        label: "This Week",
        getValue: () => {
          const today = moment();
          return [today.clone().startOf("week"), today.clone().endOf("week")];
        },
      },
      {
        label: "Next Week",
        getValue: () => {
          const today = moment();
          return [
            today.clone().add(1, "week").startOf("week"),
            today.clone().add(1, "week").endOf("week"),
          ];
        },
      },
      {
        label: "Last Week",
        getValue: () => {
          const today = moment();
          return [
            today.clone().subtract(1, "week").startOf("week"),
            today.clone().subtract(1, "week").endOf("week"),
          ];
        },
      },
      {
        label: "Reset",
        getValue: () => [null, null],
      },
    ];
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateRangePicker
          slotProps={{
            shortcuts: {
              changeImportance: "accept",
              dense: true,
              subheader: (
                <Typography variant="caption" sx={{ p: 2 }}>
                  Shortcuts
                </Typography>
              ),
              items: shortcutsItems,
            },
            actionBar: { actions: [] },
          }}
          value={this.state.taskFinishedIn}
          localeText={{
            start: "From",
            end: "To",
          }}
          onChange={(newValue) => {
            this.setState({
              taskFinishedIn: newValue,
            });
          }}
        />
      </LocalizationProvider>
    );
  };
  renderTaskStatus = () => {
    // select
    return (
      <Select
        clear
        sx={{
          minWidth: "100px",
        }}
        value={this.state.taskStatus}
        valueRender={(value) => {
          if (!value) return "";
          return this.STATUS_LIST[value];
        }}
        onChange={(e) => {
          this.setState({
            taskStatus: e.target.value,
          });
        }}
      >
        {Object.keys(this.STATUS_LIST).map((each) => {
          return (
            <MenuItem key={each} value={each}>
              {this.STATUS_LIST[each]}
            </MenuItem>
          );
        })}
      </Select>
    );
  };
  renderTaskInterpreter = () => {
    // select
    return (
      <Select
        sx={{
          minWidth: "100px",
        }}
        value={
          this.state.taskInterpreter.length
            ? this.state.taskInterpreter[0]
            : null
        }
        valueRender={(value) => {
          if (!value) return "";
          return this.INTERPRETER_LIST[value];
        }}
        onChange={(e) => {
          this.setState({
            taskInterpreter: [e.target.value],
          });
        }}
      >
        {Object.keys(this.INTERPRETER_LIST).map((each) => {
          return (
            <MenuItem key={each} value={each}>
              {this.INTERPRETER_LIST[each]}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  handleSetProtocolFilter = (data) => {
    this.setState((prevState) => {
      return { ...prevState, filterProtocolId: data };
    });
  };

  renderProtocol = () => {
    return (
      <Autocomplete
        sx={{
          minWidth: "200px",
        }}
        options={this.state.protocolOptions}
        getOptionLabel={(option) => option.companyProtocolName}
        // onInputChange={this.handleInputChange}
        onChange={(_, value) => {
          console.log({ value });
          if (value) {
            this.handleSetProtocolFilter(value);
          } else {
            this.setState({ filterProtocolId: null });
          }
        }}
        disabled={!this.state.currentlyInCompany}
        renderInput={(params) => (
          <TextField
            onClick={() => {
              console.log({ params });
            }}
            label="Select a Random Pool"
            {...params}
            variant="outlined"
          />
        )}
      />
    );
  };

  renderFilterBox = () => {
    if (!this.state.showSearchControls) {
      return null;
    }
    return (
      <Stack spacing={2} direction="row" flexWrap="wrap" alignItems="end">
        <TextField
          label="Search"
          value={this.state.prefix}
          onChange={(e) => {
            this.setState({
              prefix: e.target.value,
            });
          }}
        />
        {this.renderCompanySearcher()}
        <Stack spacing={1}>
          <Typography variant="caption">Due Date</Typography>
          {this.renderTaskDueDateIn()}
        </Stack>{" "}
        <Stack spacing={1}>
          <Typography variant="caption">Finished Date</Typography>
          {this.renderTaskFinishedIn()}
        </Stack>
        <Stack spacing={1}>
          <Typography variant="caption">Status</Typography>
          {this.renderTaskStatus()}
        </Stack>
        <Stack spacing={1}>
          <Typography variant="caption">Type</Typography>
          {this.renderTaskInterpreter()}
        </Stack>
        <Stack spacing={1}>
          <Typography variant="caption">Pool</Typography>
          {this.renderProtocol()}
        </Stack>
        <LoadingButton
          size="large"
          loading={this.state.isSearching}
          onClick={this.searchTasks}
        >
          Search
        </LoadingButton>
      </Stack>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <Stack spacing={2}>
        {this.renderHeader()}
        {this.renderFilterBox()}
        {this.renderTaskList()}
      </Stack>
    );
  }
}

export default WithAPICall(TaskList);
