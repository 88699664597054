import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Alert,
  AlertTitle,
  Card,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
  LinearProgress,
  Chip,
  Popover,
  Drawer,
} from "@mui/material";
import _, { debounce } from "lodash";
import { QuickScore } from "quick-score";
import { Box, Stack } from "@mui/system";
import Iconify from "src/components/iconify";
import ProviderServiceCreate from "./ProviderServiceCreate";
import cogoToast from "cogo-toast";
import { NoResultView, View500 } from "src/sections/error";
import { DayPicker } from "react-day-picker";
import { niceDate } from "../utils/fn";
import ListServices from "../Services/ListServices";

class ProviderServices extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    list: [],
    nwList: [],
    filteredList: [],
    companyList: [],
    hasLoadingStarted: false,
    openCreate: false,
    openServicesDrawer: false,
    searchInput: "",
    anchorEl: null,
    currentlySelectedLinkId: null,
  };
  buffer = [];
  timer = null;
  componentDidMount() {
    this.capInvertedGetAll();
  }
  capInvertedGetAll = async () => {
    try {
      let res = await this.props.apiCallPost(
        "/company-associated-provider/capInvertedGetAll",
        {
          providerId: this.props.id,
        }
      );
      this.setState(
        {
          companyList: res.map((each) => {
            return {
              companyId: each.company._id,
              companyName: each.company.companyName,
            };
          }),
        },
        () => {
          this.providerServiceLinkGetAll();
        }
      );
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Linked Employers");
      this.setState({ isLoading: false, isError: true });
    }
  };
  providerServiceLinkGetAll = async () => {
    try {
      let payload = {
        doesApplyToNetwork: this.props.doesApplyToNetwork,
      };
      if (this.props.doesApplyToNetwork) {
        payload["networkId"] = this.props.id;
      } else {
        payload["providerId"] = this.props.id;
        // is provider part of network?
        let pn = this.props.data.providerNetwork;
        if (pn && pn._id) {
          payload["networkId"] = pn._id;
        }
      }
      let res = await this.props.apiCallPost(
        "/provider-service-link/providerServiceLinkGetAll",
        payload
      );
      let list = res;

      if (!this.props.doesApplyToNetwork) {
        // find all which have originalServiceLink
        let diffed = list.filter((each) => each.originalServiceLink);
        for (var i = 0; i < diffed.length; i++) {
          let each = diffed[i];
          let idx = list.findIndex((x) => x._id === each.originalServiceLink);
          if (idx > -1) {
            list.splice(idx, 1);
          }
        }
      }
      this.setState({ list: list, isLoading: false, filteredList: list });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Provider Services");
      this.setState({ isLoading: false, isError: true });
    }
  };
  updateScalarValue = async (id, key, value, set) => {
    let idToMod = id;
    let list = this.state.list;
    let psl = list.find((each) => each._id === id);
    let currentlyProvider = !this.props.doesApplyToNetwork;
    let pslIsNetwork = psl.doesApplyToNetwork;
    let listIdx = list.findIndex((each) => each._id === id);
    this.setState({ isSaving: true });
    if (pslIsNetwork && currentlyProvider) {
      try {
        let res = await this.props.apiCallPost(
          "/provider-service-link/providerServiceCreateDiff",
          {
            pslId: id,
            providerId: this.props.id,
          }
        );
        list[listIdx] = res;
        idToMod = res._id;
      } catch (err) {
        cogoToast.error("Error Updating Data");
        this.setState({
          isSaving: false,
          isError: true,
        });
        console.log(err);
        return;
      }
    }
    try {
      let res = await this.props.apiCallPost(
        "/provider-service-link/providerServiceLinkUpdateScalarValue",
        {
          providerServiceLinkId: idToMod,
          key: key,
          value: value,
        }
      );
      list[listIdx] = res;
      this.setState({ list, isSaving: false });
    } catch (err) {
      cogoToast.error("Error Updating Data");
      this.setState({
        isSaving: false,
        isError: true,
      });
      console.log(err);
    }
  };
  handleSearch = debounce((s) => {
    if (s === "") return null;
    if (!s) return null;
    if (s.length < 4) return null;
    let qs = new QuickScore(this.state.list, [
      "providerServiceAlias",
      "providerServiceCode",
      "costModel",
      "activeStatus",
    ]);
    let res = qs.search(s);
    let it = res.map((each) => each.item);
    this.setState({
      filteredList: it,
    });
  }, 300);
  renderAlert = () => {
    return (
      <Alert severity="warning">
        <AlertTitle>Careful!</AlertTitle>
        Be thoughtful while changing data on this tab. It will have a cascading
        effect!
      </Alert>
    );
  };
  renderSearchBox = () => {
    let loading = this.state.isLoading || this.state.hasLoadingStarted;
    let list = this.state.list;
    if (!list || !list.length) return null;
    if (loading) return null;
    return (
      <TextField
        label="Search"
        placeholder="Type 3 characters or more to search"
        onChange={(e) => {
          let x = e.target.value;
          if (x.length > 3) {
            this.handleSearch(e.target.value);
          } else {
            this.setState({
              filteredList: this.state.list,
            });
          }
        }}
      />
    );
  };
  renderHeader = () => {
    return (
      <Stack direction="row" alignItems="center" spacing={3}>
        <Typography variant="h6">
          Provider {this.props.doesApplyToNetwork ? "Network" : ""} Service Link
        </Typography>
        {this.renderSearchBox()}
        <IconButton
          size="small"
          color="primary"
          onClick={() => {
            this.setState({ openCreate: true });
          }}
          sx={{
            width: 24,
            height: 24,
            bgcolor: "primary.main",
            color: "primary.contrastText",
            "&:hover": {
              bgcolor: "primary.dark",
            },
          }}
        >
          <Iconify icon="mingcute:add-line" />
        </IconButton>
      </Stack>
    );
  };
  ACTIVE_STATUS_OPTIONS = ["ACTIVE", "TEMP_CLOSED", "PERM_CLOSED"];
  COST_MODEL_OPTIONS = ["FLAT_RATE", "USAGE_BASED", "HYBRID"];
  renderNetworkChip = (row) => {
    // case 1 provider
    // case 2 network
    // case 3 network in provider
    // case 3.1 modded

    if (this.props.doesApplyToNetwork) return null;

    if (row.doesApplyToNetwork) {
      let text = `Inherited from Network ${row.network.networkName}`;
      return (
        <Box>
          <Chip
            variant="outlined"
            size="small"
            label={text}
            sx={{
              mb: 1,
            }}
          />
        </Box>
      );
    } else {
      if (row.originalServiceLink) {
        let text = `Modified from Network ${row.network.networkName}`;
        return (
          <Box>
            <Chip
              color="secondary"
              variant="contained"
              size="small"
              label={text}
              sx={{
                mb: 1,
              }}
            />
          </Box>
        );
      } else {
        let d = this.props.data;
        let pn = d.providerNetwork;
        if (pn && pn._id) {
          let text = `Specific To ${d.providerName}`;
          return (
            <Box>
              <Chip
                color="secondary"
                variant="outlined"
                size="small"
                label={text}
                sx={{
                  mb: 1,
                }}
              />
            </Box>
          );
        }
        return;
      }
    }
  };
  renderServicesIncluded = (row, i) => {
    let loading = this.state.isSaving;
    if (!this.props.doesApplyToNetwork && row.doesApplyToNetwork) {
      loading = true;
    }
    let masterServices = row.masterServices;
    if (!masterServices || !masterServices.length) {
      return (
        <Stack direction="row" spacing={1} alignItems={"center"}>
          {" "}
          <Typography variant="overline">Master Services</Typography>{" "}
          <IconButton
            disabled={loading}
            size="small"
            color="primary"
            onClick={() => {
              this.setState({
                openServicesDrawer: true,
                currentlySelectedLinkId: row._id,
              });
            }}
            sx={{
              width: 24,
              height: 24,
              bgcolor: "primary.main",
              color: "primary.contrastText",
              "&:hover": {
                bgcolor: "primary.dark",
              },
            }}
          >
            <Iconify icon="mingcute:add-line" />
          </IconButton>
        </Stack>
      );
    }
    return (
      <Stack spacing={2}>
        <Stack direction="row" spacing={1} alignItems={"center"}>
          <Typography variant="overline">Master Services</Typography>{" "}
          <IconButton
            size="small"
            color="primary"
            disabled={loading}
            onClick={() => {
              // if (!loading) {
              //   return null;
              // }
              this.setState({
                openServicesDrawer: true,
                currentlySelectedLinkId: row._id,
              });
            }}
            sx={{
              width: 24,
              height: 24,
              bgcolor: "primary.main",
              color: "primary.contrastText",
              "&:hover": {
                bgcolor: "primary.dark",
              },
            }}
          >
            <Iconify icon="mingcute:edit-line" />
          </IconButton>
        </Stack>

        {this.renderMasterServicesMap(row, i, loading)}
      </Stack>
    );
  };
  renderMasterServicesMap = (row, i) => {
    let masterServices = row.masterServices;
    return masterServices.map((eachService, index) => {
      let isActive = eachService.isActive;
      return (
        <Stack direction="row" spacing={1} key={index} alignItems={"center"}>
          <Chip
            size="small"
            label={eachService.serviceName}
            color={isActive ? "primary" : "error"}
          />
          <IconButton
            size="small"
            href={`/services/${eachService._id}`}
            variant="outlined"
            // target="non_blank"
          >
            <Iconify icon="mdi:arrow-top-right" />
          </IconButton>
        </Stack>
      );
    });
  };
  renderCostModelOptions = (row, i, loading) => {
    if (!row.costModel) return null;
    let cm = row.costModel;
    switch (cm) {
      case "FLAT_RATE":
        return (
          <Box
            rowGap={2}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(1, 1fr)",
            }}
          >
            <TextField
              size="small"
              disabled={this.state.isSaving}
              key={`${row._id}__${row.updatedAt}`}
              value={row.baseCostUsd}
              onChange={(e) => {
                let list = this.state.list;
                let idx = i;
                list[idx].baseCostUsd = e.target.value;
                this.setState({ list });
              }}
              label="Flat Rate (USD)"
              onBlur={() => {
                this.updateScalarValue(
                  row._id,
                  "baseCostUsd",
                  parseFloat(row.baseCostUsd)
                );
              }}
            />
          </Box>
        );
      case "USAGE_BASED":
        return (
          <Box
            rowGap={2}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
            }}
          >
            <TextField
              size="small"
              disabled={this.state.isSaving}
              value={row.unitCostUsd}
              onChange={(e) => {
                let list = this.state.list;
                let idx = i;
                list[idx].unitCostUsd = e.target.value;
                this.setState({ list });
              }}
              key={`${row._id}__${row.updatedAt}`}
              label="Unit Rate (USD)"
              onBlur={() => {
                this.updateScalarValue(
                  row._id,
                  "unitCostUsd",
                  parseFloat(row.unitCostUsd)
                );
              }}
            />
            <TextField
              size="small"
              disabled={this.state.isSaving}
              key={`${row._id}__${row.updatedAt}`}
              value={row.costModelUnitOfWork}
              onChange={(e) => {
                let list = this.state.list;
                let idx = i;
                list[idx].costModelUnitOfWork = e.target.value;
                this.setState({ list });
              }}
              label="Unit"
              onBlur={() => {
                this.updateScalarValue(
                  row._id,
                  "costModelUnitOfWork",
                  row.costModelUnitOfWork
                );
              }}
            />
          </Box>
        );
      case "HYBRID":
        return (
          <Stack spacing={2}>
            <Box
              rowGap={2}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(1, 1fr)",
              }}
            >
              <TextField
                size="small"
                disabled={this.state.isSaving}
                key={`${row._id}__${row.updatedAt}`}
                value={row.baseCostUsd}
                onChange={(e) => {
                  let list = this.state.list;
                  let idx = i;
                  list[idx].baseCostUsd = e.target.value;
                  this.setState({ list });
                }}
                label="Flat Rate (USD)"
                onBlur={() => {
                  this.updateScalarValue(
                    row._id,
                    "baseCostUsd",
                    parseFloat(row.baseCostUsd)
                  );
                }}
              />
            </Box>
            <Box
              rowGap={2}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
              }}
            >
              <TextField
                size="small"
                disabled={this.state.isSaving}
                key={`${row._id}__${row.updatedAt}`}
                value={row.unitCostUsd}
                onChange={(e) => {
                  let list = this.state.list;
                  let idx = i;
                  list[idx].unitCostUsd = e.target.value;
                  this.setState({ list });
                }}
                label="Unit Rate (USD)"
                onBlur={() => {
                  this.updateScalarValue(
                    row._id,
                    "unitCostUsd",
                    parseFloat(row.unitCostUsd)
                  );
                }}
              />
              <TextField
                size="small"
                key={`${row._id}__${row.updatedAt}`}
                disabled={this.state.isSaving}
                value={row.costModelUnitOfWork}
                onChange={(e) => {
                  let list = this.state.list;
                  let idx = i;
                  list[idx].costModelUnitOfWork = e.target.value;
                  this.setState({ list });
                }}
                label="Unit"
                onBlur={() => {
                  this.updateScalarValue(
                    row._id,
                    "costModelUnitOfWork",
                    row.costModelUnitOfWork
                  );
                }}
              />
            </Box>
          </Stack>
        );
      default:
        return null;
    }
  };
  renderCostModel = (row, i) => {
    return (
      <Stack spacing={2}>
        <Box
          rowGap={2}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
          }}
        >
          <Select
            size="small"
            disabled={this.state.isSaving}
            value={row.costModel}
            onChange={(e) => {
              let list = this.state.list;
              let idx = i;
              list[idx].costModel = e.target.value;
              list[idx].costModelUnitOfWork = null;
              list[idx].baseCostUsd = null;
              list[idx].unitCostUsd = null;
              this.setState({ list }, () => {
                this.updateScalarValue(row._id, "costModel", e.target.value);
                this.updateScalarValue(row._id, "costModelUnitOfWork", null);
                this.updateScalarValue(row._id, "baseCostUsd", null);
                this.updateScalarValue(row._id, "unitCostUsd", null);
              });
            }}
            sx={{
              minWidth: "100px",
            }}
          >
            {this.COST_MODEL_OPTIONS.map((each) => {
              return <MenuItem value={each}>{each}</MenuItem>;
            })}
          </Select>
        </Box>
        {this.renderCostModelOptions(row, i)}
      </Stack>
    );
  };
  renderStatus = (row, i) => {
    return (
      <Box>
        <Select
          size="small"
          disabled={this.state.isSaving}
          value={row.activeStatus}
          onChange={(e) => {
            let list = this.state.list;
            let idx = i;
            list[idx].activeStatus = e.target.value;
            this.setState({ list }, () => {
              this.updateScalarValue(row._id, "activeStatus", e.target.value);
            });
          }}
          sx={{
            minWidth: "100px",
          }}
        >
          {this.ACTIVE_STATUS_OPTIONS.map((each) => {
            return <MenuItem value={each}>{each}</MenuItem>;
          })}
        </Select>
      </Box>
    );
  };
  renderSupplies = (row, i) => {
    if (!row.network) {
      return null;
    }
    if (!row.network.suppliesProvided) {
      return null;
    }
    if (!row.network.suppliesProvided.length) {
      return null;
    }
    let options = row.network.suppliesProvided.map((x) => x.supplyType);

    return (
      <Stack spacing={1}>
        <Stack direction="row" spacing={1} alignItems={"center"}>
          <Typography variant="overline">Supplies</Typography>{" "}
          <IconButton
            disabled={this.state.isSaving}
            size="small"
            color="primary"
            onClick={() => {
              let newObj = {
                supplyType: options[0],
                quantity: 1,
              };

              let list = this.state.list;
              let idx = i;
              list[idx].suppliesNeeded = [...list[idx].suppliesNeeded, newObj];

              this.setState({ list }, () => {
                this.updateScalarValue(
                  row._id,
                  "suppliesNeeded",
                  list[idx].suppliesNeeded
                );
              });
            }}
            sx={{
              width: 24,
              height: 24,
              bgcolor: "primary.main",
              color: "primary.contrastText",
              "&:hover": {
                bgcolor: "primary.dark",
              },
            }}
          >
            <Iconify icon="mingcute:add-line" />
          </IconButton>
        </Stack>
        {this.renderSuppliesEach(row, i, options)}
      </Stack>
    );
  };
  renderSuppliesEach = (row, i, options) => {
    let supplies = row.suppliesNeeded;
    if (!supplies || !supplies.length) {
      return null;
    }
    return supplies.map((each, index) => {
      return (
        <Stack spacing={1} key={index} alignItems={"center"} direction="row">
          <Select
            size="small"
            sx={{
              minWidth: "100px",
            }}
            disabled={this.state.isSaving}
            value={each.supplyType}
            onChange={(e) => {
              let list = this.state.list;
              let idx = i;
              list[idx].suppliesNeeded[index].supplyType = e.target.value;
              this.setState({ list }, () => {
                this.updateScalarValue(
                  row._id,
                  "suppliesNeeded",
                  row.suppliesNeeded
                );
              });
            }}
          >
            {options.map((x) => {
              return <MenuItem value={x}>{x}</MenuItem>;
            })}
          </Select>
          <TextField
            size="small"
            disabled={this.state.isSaving}
            value={each.quantity}
            onChange={(e) => {
              let list = this.state.list;
              let idx = i;
              list[idx].suppliesNeeded[index].quantity = e.target.value;
              this.setState({ list });
            }}
            onBlur={() => {
              let list = this.state.list;
              let idx = i;
              list[idx].suppliesNeeded[index].quantity = parseInt(
                list[idx].suppliesNeeded[index].quantity
              );
              this.setState({ list }, () => {
                this.updateScalarValue(
                  row._id,
                  "suppliesNeeded",
                  row.suppliesNeeded
                );
              });
            }}
            label="Quantity"
          />
        </Stack>
      );
    });
  };
  renderSurcharges = (row, i) => {
    return (
      <Stack spacing={1}>
        <Stack direction="row" spacing={1} alignItems={"center"}>
          {" "}
          <Typography variant="overline">Surcharges</Typography>{" "}
          <IconButton
            disabled={this.state.isSaving}
            size="small"
            color="primary"
            onClick={() => {
              let newObj = {
                surchargeAmtUsd: 0,
                companies: [],
              };
              let list = this.state.list;
              let idx = i;
              list[idx].surchargeAmts = [...list[idx].surchargeAmts, newObj];

              this.setState({ list }, () => {
                this.updateScalarValue(
                  row._id,
                  "surchargeAmts",
                  list[idx].surchargeAmts
                );
              });
            }}
            sx={{
              width: 24,
              height: 24,
              bgcolor: "primary.main",
              color: "primary.contrastText",
              "&:hover": {
                bgcolor: "primary.dark",
              },
            }}
          >
            <Iconify icon="mingcute:add-line" />
          </IconButton>
        </Stack>
        {this.renderSurchargeEach(row, i)}
      </Stack>
    );
  };
  renderSurchargeEach = (row, i) => {
    let surcharges = row.surchargeAmts;
    if (!surcharges || !surcharges.length) {
      return null;
    }
    return surcharges.map((each, index) => {
      return (
        <Stack spacing={2} key={index}>
          <Typography variant="overline">Surcharge {index + 1}</Typography>
          <TextField
            size="small"
            disabled={this.state.isSaving}
            value={each.surchargeAmtUsd}
            onChange={(e) => {
              let list = this.state.list;
              let idx = i;
              list[idx].surchargeAmts[index].surchargeAmtUsd = e.target.value;
              this.setState({ list });
            }}
            onBlur={() => {
              let list = this.state.list;
              let idx = i;
              list[idx].surchargeAmts[index].surchargeAmtUsd = parseFloat(
                list[idx].surchargeAmts[index].surchargeAmtUsd
              );
              this.setState({ list }, () => {
                this.updateScalarValue(
                  row._id,
                  "surchargeAmts",
                  row.surchargeAmts
                );
              });
            }}
            label="Surcharge Amount (USD)"
          />
          <Select
            size="small"
            multiple
            disabled={this.state.isSaving}
            value={each.companies}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    size="small"
                    key={value}
                    label={
                      _.find(
                        this.state.companyList,
                        (x) => x.companyId === value
                      ).companyName
                    }
                  />
                ))}
              </Box>
            )}
            onChange={(e) => {
              const x = e.target.value;

              let list = this.state.list;
              let idx = i;
              list[idx].surchargeAmts[index].companies = x;
              this.setState({ list });
            }}
            sx={{ minWidth: "100px" }}
            onBlur={() => {
              this.updateScalarValue(
                row._id,
                "surchargeAmts",
                row.surchargeAmts
              );
            }}
          >
            {this.state.companyList.map((x) => {
              return <MenuItem value={x.companyId}>{x.companyName}</MenuItem>;
            })}{" "}
          </Select>
        </Stack>
      );
    });
  };
  renderEffective = (row, i, whichEffective) => {
    return (
      <>
        <Chip
          size="small"
          variant="contained"
          onClick={(e) => {
            if (this.state.isSaving) return null;
            this.setState({
              anchorEl: e.currentTarget,
              [`open_${row._id}_${whichEffective}`]: true,
            });
          }}
          label={
            row[`effective${whichEffective}`]
              ? `Effective ${whichEffective}: ${niceDate(
                  row[`effective${whichEffective}`]
                )}`
              : `Set Effective ${whichEffective} Date`
          }
        />
        <Popover
          open={this.state[`open_${row._id}_${whichEffective}`]}
          anchorEl={this.state.anchorEl}
          onClose={() => {
            this.setState({
              anchorEl: null,
              [`open_${row._id}_${whichEffective}`]: false,
            });
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <DayPicker
            mode="single"
            disabled={this.state.isSaving}
            captionLayout="dropdown-buttons"
            fromYear={1901}
            toYear={2035}
            onSelect={(e) => {
              let list = this.state.list;
              let idx = i;
              let keyBeingUpdated = `effective${whichEffective}`;
              let keyToCheckAgainst =
                keyBeingUpdated === "effectiveFrom"
                  ? "effectiveTo"
                  : "effectiveFrom";
              let val = e;
              let valToCheckAgainst = list[idx][keyToCheckAgainst];
              if (valToCheckAgainst) {
                valToCheckAgainst = new Date(valToCheckAgainst);
                val = new Date(val);
                // from can't be more than to; to can't be less than from
                if (keyBeingUpdated === "effectiveFrom") {
                  if (val.getTime() > valToCheckAgainst.getTime()) {
                    cogoToast.error(
                      "Effective From can't be more than Effective To"
                    );
                    return null;
                  }
                }
                if (keyBeingUpdated === "effectiveTo") {
                  if (val.getTime() < valToCheckAgainst.getTime()) {
                    cogoToast.error(
                      "Effective To can't be less than Effective From"
                    );
                    return null;
                  }
                }
              }
              list[idx][`effective${whichEffective}`] = e;
              this.setState(
                {
                  list,
                  anchorEl: null,
                  [`open_${row._id}_${whichEffective}`]: false,
                },
                () => {
                  this.updateScalarValue(
                    row._id,
                    `effective${whichEffective}`,
                    e
                  );
                }
              );
            }}
          />
        </Popover>
      </>
    );
  };
  renderList = () => {
    let list = this.state.list;
    if (!list || !list.length) {
      return <NoResultView />;
    }
    if (!this.state.filteredList.length) {
      return <NoResultView />;
    }
    return list.map((each, idx) => {
      let id = each._id;
      let flIDs = this.state.filteredList.map((each) => each._id);
      if (!flIDs.includes(id)) return null;

      return (
        <Card
          sx={{
            p: 2,
            boxShadow: 2,
            bgcolor: "background.neutral",
            overflow: "auto",
          }}
          key={idx}
        >
          <Stack spacing={2}>
            {this.renderNetworkChip(each)}
            <Typography variant="overline">Basics</Typography>
            <TextField
              key={`${each._id}__${each.updatedAt}_alias`}
              label="Provider Alias"
              value={each.providerServiceAlias}
              onChange={(e) => {
                let list = _.cloneDeep(this.state.list);
                list[idx].providerServiceAlias = e.target.value;
                this.setState({ list });
              }}
              onBlur={() => {
                this.updateScalarValue(
                  each._id,
                  "providerServiceAlias",
                  each.providerServiceAlias
                );
              }}
            />{" "}
            <TextField
              key={`${each._id}__${each.updatedAt}_code`}
              label="Provider Billing Code"
              value={each.providerServiceCode}
              onChange={(e) => {
                let list = _.cloneDeep(this.state.list);
                list[idx].providerServiceCode = e.target.value;
                this.setState({ list });
              }}
              onBlur={() => {
                this.updateScalarValue(
                  each._id,
                  "providerServiceCode",
                  each.providerServiceCode
                );
              }}
            />
            <Typography variant="overline">Effective Dates</Typography>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                lg: "repeat(4, 1fr)",
              }}
            >
              {" "}
              {this.renderEffective(each, idx, "From")}
              {this.renderEffective(each, idx, "To")}
            </Box>{" "}
            <Typography variant="overline">Status</Typography>
            {this.renderStatus(each, idx)}{" "}
            <Typography variant="overline">Cost Model</Typography>
            {this.renderCostModel(each, idx)}
            {this.renderSupplies(each, idx)}
            {this.renderSurcharges(each, idx)}
            {this.renderServicesIncluded(each, idx)}
          </Stack>
        </Card>
      );
    });
  };
  render() {
    if (this.state.isError) {
      return <View500 />;
    }
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <Stack spacing={3}>
        <Drawer
          anchor="right"
          open={this.state.openServicesDrawer}
          onClose={() => {
            this.setState({
              openServicesDrawer: false,
              currentlySelectedLinkId: null,
            });
          }}
        >
          <div
            style={{
              width: "80vw",
            }}
          >
            <Card sx={{ p: 2 }}>
              <ListServices
                fromProviderPage={true}
                selectedData={
                  this.state.currentlySelectedLinkId
                    ? _.find(
                        this.state.list,
                        (each) =>
                          each._id === this.state.currentlySelectedLinkId
                      ).masterServices
                    : []
                }
                onSelectData={(data) => {
                  let idList = data.map((each) => each._id);
                  this.updateScalarValue(
                    this.state.currentlySelectedLinkId,
                    "masterServices",
                    idList,
                    true
                  );
                  this.setState({
                    openServicesDrawer: false,
                  });
                }}
              />
            </Card>
          </div>
        </Drawer>{" "}
        <ProviderServiceCreate
          id={this.props.id}
          data={this.props.data}
          isOpen={this.state.openCreate}
          doesApplyToNetwork={this.props.doesApplyToNetwork}
          handleClose={() => {
            this.setState({ openCreate: false });
          }}
          onCreate={(c) => {
            this.setState({
              list: [c, ...this.state.list],
              filteredList: [c, ...this.state.filteredList],
              openCreate: false,
            });
          }}
        />
        {this.renderAlert()}
        {this.renderHeader()}
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
        >
          {this.renderList()}
        </Box>
      </Stack>
    );
  }
}

export default WithAPICall(ProviderServices);
