import {
  Alert,
  FormControlLabel,
  MenuItem,
  Select,
  Typography,
  ListItemText,
  Card,
  LinearProgress,
  TextField,
} from "@mui/material";
import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import cogoToast from "cogo-toast";
import { Box, Stack } from "@mui/system";
import CompanyEmployees from "../Company/CompanyEmployees";
import ProtocolManualCriteria from "./ProtocolManualCriteria";

class ProtocolCriteria extends React.Component {
  state = { isLoading: true };
  componentDidMount() {
    this.setState({ ...this.props.data, isLoading: false });
  }
  updateScalarValue = async (key, value) => {
    try {
      this.setState({
        isSaving: true,
      });
      let res = await this.props.apiCallPost(
        "/company-protocol/companyProtocolUpdateScalarValue",
        {
          companyProtocolId: this.props.protocolId,
          key: key,
          value: value,
        }
      );
      this.setState({
        ...res,
        isSaving: false,
      });
      cogoToast.success("Protocol Data Updated");
    } catch (err) {
      this.setState({
        isSaving: false,
        [key]: this.props.data[key],
      });
      cogoToast.error("Error Updating Protocol Data");
      console.log(err);
    }
  };

  CRITERIA_OPTIONS = ["FILTER", "MANUAL", "NLP"];
  renderCriteriaTypeChoice = () => {
    return (
      <Stack component={Card} spacing={3} sx={{ p: 3 }}>
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
          }}
        >
          {" "}
          <ListItemText
            primary={
              this.props.data.isPool ? (
                <Typography variant="h6">Pool Inclusion Criteria</Typography>
              ) : (
                <Typography variant="h6">
                  Protocol Inclusion Criteria
                </Typography>
              )
            }
            secondary={`This section defines which employees come under this protocol. 'FILTER' will define it programmatically. Select 'MANUAL' to upload a list manually. Select 'NLP' to write natural language criteria.`}
            primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
            secondaryTypographyProps={{ component: "span" }}
          />{" "}
          <FormControlLabel
            label={"Criteria Type"}
            labelPlacement="start"
            control={
              <Select
                sx={{
                  ml: 3,
                  width: 1,
                }}
                disabled={this.state.poolIsCanonicalPopulationFrozen}
                value={this.state.companyProtocolCriteriaType}
                onChange={(e) =>
                  this.setState(
                    {
                      companyProtocolCriteriaType: e.target.value,
                      companyProtocolCriteria: { filterSpec: null },
                    },
                    () => {
                      this.updateScalarValue(
                        "companyProtocolCriteriaType",
                        e.target.value
                      );
                      this.updateScalarValue("companyProtocolCriteria", {
                        filterSpec: null,
                      });
                    }
                  )
                }
              >
                {this.CRITERIA_OPTIONS.map((opt) => (
                  <MenuItem value={opt}>{opt}</MenuItem>
                ))}
              </Select>
            }
            sx={{
              m: 0,
              width: 1,
              justifyContent: "space-between",
            }}
          />
        </Box>
      </Stack>
    );
  };
  renderBasedOnCriteriaType = () => {
    let type = this.state.companyProtocolCriteriaType;
    switch (type) {
      case "FILTER":
        return this.renderIfFilter();
      case "MANUAL":
        return this.renderIfManual();
      case "NLP":
        return this.renderIfNLP();
      default:
        return null;
    }
  };
  renderIfFilter = () => {
    return (
      <Stack spacing={3}>
        <Alert severity="info">
          You can set up the filter criteria for this protocol. This will be
          used to automatically include employees in this protocol.
        </Alert>

        <CompanyEmployees
          isSaving={this.state.isSaving}
          onSetFilterSpec={async (fs) => {
            cogoToast.loading("Updating Protocol Criteria");
            let x = {
              filterSpec: fs,
            };
            this.updateScalarValue("companyProtocolCriteria", x);
            try {
              const res = await this.props.apiCallPost(
                "/company-protocol/pool/freezeFilterCriteria",
                {
                  poolId: this.state._id,
                  filterSpec: fs,
                }
              );
              this.setState({ ...res });
              this.props.onUpdate("poolChangelog", res.poolChangelog || []);
            } catch (error) {
              console.error("could not update pool criteria", error);
            }
            this.setState({
              companyProtocolCriteria: x,
            });
          }}
          onUnfreezePool={async () => {
            try {
              const res = await this.props.apiCallPost(
                "/company-protocol/pool/unFreeze",
                {
                  poolId: this.state._id,
                }
              );
              this.setState({ ...res });
              this.props.onUpdate("poolChangelog", res.poolChangelog || []);
            } catch (error) {
              console.error("could not unfreeze pool", error);
            }
          }}
          id={this.props.companyId}
          insideProtocol={true}
          isPool={this.state.isPool}
          filterSpec={this.state.companyProtocolCriteria?.filterSpec}
          protocol={this.state}
        />
      </Stack>
    );
  };
  renderIfManual = () => {
    return (
      <ProtocolManualCriteria
        protocolId={this.props.protocolId}
        companyId={this.props.companyId}
        insideProtocol={true}
        filterSpec={this.state.companyProtocolCriteria?.filterSpec}
        protocol={this.state}
      />
    );
  };
  renderIfNLP = () => {
    return (
      <TextField
        multiline
        label="Criteria"
        placeholder="Enter criteria in plain English"
        value={this.state.companyProtocolCriteria.filterSpec}
        onChange={(e) => {
          this.setState({
            companyProtocolCriteria: {
              filterSpec: e.target.value,
            },
          });
        }}
        onBlur={() => {
          this.updateScalarValue(
            "companyProtocolCriteria",
            this.state.companyProtocolCriteria
          );
        }}
      />
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <Stack spacing={2}>
        {this.renderCriteriaTypeChoice()}
        {this.renderBasedOnCriteriaType()}
      </Stack>
    );
  }
}

export default WithAPICall(ProtocolCriteria);
