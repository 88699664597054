import {
  Alert,
  Badge,
  Button,
  Card,
  Chip,
  FormControlLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import _ from "lodash";
import React from "react";
import { DayPicker } from "react-day-picker";
import { niceDate } from "../utils/fn";
import cogoToast from "cogo-toast";
import { LoadingButton } from "@mui/lab";
import { WithAPICall } from "../utils/apiUtil";

class ServiceQPreview extends React.Component {
  state = {
    anchorEl: null,
    questions: [],
    oldAnswers: [],
    isLoading: true,
    subheaders: [],
    isSaving: false,
    isSaved: false,
  };
  componentDidMount() {
    this.setState(
      {
        questions: this.props.data.map((x) => {
          return { ...x, answer: "" };
        }),
      },
      () => {
        this.checkIfCompleted();
      }
    );
  }
  checkIfCompleted = () => {
    if (this.props.isPreview) {
      this.findSubheaders();
    } else {
      if (this.props.isCompleted) {
        this.setState({ isSaved: true }, () => {
          this.findSubheaders();
        });
      } else {
        this.findSubheaders();
      }
    }
  };
  findSubheaders = () => {
    let q = this.state.questions;
    let subheaders = [];
    for (var i = 0; i < q.length; i++) {
      if (q[i].answerType === "Section Header") {
        subheaders.push(i);
      }
    }
    this.setState({ subheaders }, () => {
      this.getAnswers();
    });
  };
  setAnswers = (i, answer, save = true) => {
    let newQ = this.state.questions;
    newQ[i].answer = answer;
    this.setState({ questions: newQ }, () => {
      if (save) {
        this.saveAnswers(i);
      }
    });
  };
  setYesNoAnswer = (i, answer, yesNoKey, save = true) => {
    let newQ = this.state.questions;
    newQ[i][yesNoKey].answer = answer;
    this.setState({ questions: newQ }, () => {
      if (save) {
        this.saveAnswers(i);
      }
    });
  };
  setMcqAnswer = (i, answer, answerOption, save = true) => {
    let newQ = this.state.questions;
    newQ[i].multiChoiceQuestionOptions[answerOption].answer = answer;
    this.setState({ questions: newQ }, () => {
      if (save) {
        this.saveAnswers(i);
      }
    });
  };
  saveAnswers = async (i) => {
    if (this.props.isPreview) {
      return null;
    }
    let data = this.state.questions[i];
    let hasRecordId = data.recordId;
    if (hasRecordId) {
      try {
        await this.props.apiCallPost("/ext-employee/q/editAnswer", {
          recordId: data.recordId,
          data: data,
        });
      } catch (err) {
        console.log(err);
        cogoToast.error("Error Saving Answer");
      }
    } else {
      try {
        let res = await this.props.apiCallPost("/ext-employee/q/addAnswer", {
          visitId: this.props.visitId,
          serviceId: this.props.serviceData._id,
          data: data,
        });
        let oldAnswers = this.state.oldAnswers;
        oldAnswers.push(res);
        let newQ = this.state.questions;
        newQ[i].recordId = res._id;
        this.setState({ oldAnswers, questions: newQ });
      } catch (err) {
        console.log(err);
        cogoToast.error("Error Saving Answer");
      }
    }
  };
  getAnswers = async () => {
    if (this.props.isPreview) {
      this.setState({
        isLoading: false,
      });
      return null;
    }
    try {
      let res = await this.props.apiCallPost("/ext-employee/q/getAnswers", {
        visitId: this.props.visitId,
        serviceId: this.props.serviceData._id,
      });
      if (!res || !res.length) {
        this.setState({
          oldAnswers: [],
          isLoading: false,
        });
        return;
      } else {
        // first, find the _id in question
        let qs = this.state.questions;
        let oldAnswers = res;
        for (var i = 0; i < oldAnswers.length; i++) {
          let obj = oldAnswers[i];
          let data = obj.data;
          let qId = data._id;
          let idx = _.findIndex(qs, (x) => x._id === qId);
          if (idx > -1) {
            qs[idx].answer = data.answer;
            qs[idx].recordId = obj._id;
          }
        }
        this.setState({
          oldAnswers: oldAnswers,
          questions: qs,
          isLoading: false,
        });
      }
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Answers");
    }
  };
  submitAnswers = async () => {
    if (this.props.isPreview) {
      cogoToast.success("This is a preview. No data will be saved.");
      // wait 2 seconds
      await new Promise((r) => setTimeout(r, 2000));
      this.setState({ isSaving: false, isSaved: true });
    } else {
      try {
        await this.props.apiCallPost("/ext-employee/q/submit", {
          visitId: this.props.visitId,
          serviceId: this.props.serviceData._id,
        });
        cogoToast.success("Answers Submitted");
        this.setState({ isSaving: false, isSaved: true }, () => {
          this.props.onSubmit();
        });
      } catch (err) {
        this.setState({
          isSaving: false,
        });
        console.log(err);
        cogoToast.error("Error Submitting Answers");
      }
    }
  };
  renderHeader = () => {
    let q = this.props.data;
    let qL = _.filter(q, (x) => x.answerType !== "Section Header");
    if (!qL) return null;
    if (!qL.length) return null;
    let qLL = qL.length;
    let timePerQ = 20;
    let time = qLL * timePerQ;
    let timeInMins = Math.round(time / 60);
    let qElem = (
      <Alert severity="info">
        This questionnaire has {qLL} questions. It should take about{" "}
        {timeInMins} minutes to complete. If you have any queries, please reach
        out to support@workcare.com for assistance.
      </Alert>
    );
    return (
      <Stack spacing={2} justifyContent={"center"}>
        {" "}
        <Box
          component="img"
          src="/logo/logo_single.svg"
          sx={{ width: 100, height: 100, cursor: "pointer" }}
        />{" "}
        <ListItemText
          sx={{
            p: 3,
            borderRadius: 2,
            bgcolor: "background.neutral",
          }}
          primary={`Questionnaire: ${this.props.serviceData.serviceName}`}
          secondary={
            <Stack spacing={2}>
              <Typography variant="subtitle2">
                {this.props.serviceData.patientInstruction}
              </Typography>{" "}
              <Typography variant="subtitle2">
                {this.props.serviceData.questionnairePtInsn}
              </Typography>{" "}
              <Typography variant="subtitle2">
                All your responses to this questionnaire will be saved
                automatically. You can come back and complete it later. We
                encourage you to complete it as soon as possible to avoid any
                delays in your service.
              </Typography>{" "}
              <Typography variant="caption">
                This questionnaire is used to gather information about your
                health and physical condition, both now and in the past. This
                information will be used to determine if you can safely perform
                the duties of your job. This exam is not intended to substitute
                for care provided by your personal physician. The results of the
                examination are kept confidential.
              </Typography>
            </Stack>
          }
          primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
          secondaryTypographyProps={{ component: "span" }}
        />
        {qElem}
      </Stack>
    );
  };
  renderQuestions = () => {
    let q = this.state.questions;
    if (!q) return null;
    if (!q.length) return null;
    return (
      <Stack spacing={2}>
        {q.map((eachQ, i) => {
          return (
            <Card
              sx={{
                p: 3,
                boxShadow: 2,
              }}
            >
              <Stack spacing={1}>
                {this.renderEachQuestion(eachQ, i)}
                {this.renderControl(eachQ, i)}
              </Stack>
            </Card>
          );
        })}
      </Stack>
    );
  };
  renderEachQuestion = (q, i) => {
    if (q.answerType === "Section Header") {
      return (
        <Alert severity="warning" icon={false}>
          {q.question}
        </Alert>
      );
    }
    let allCount = this.state.questions.length;
    let subHeaderCount = this.state.subheaders.length;
    let numSubheadersBeforeI = _.filter(this.state.subheaders, (x) => x < i);
    let adjustedIndex = i - numSubheadersBeforeI.length + 1;
    let adjustedCount = allCount - subHeaderCount;

    let labelPrefix = `Q ${adjustedIndex} of ${adjustedCount}`;
    let answer = q.answer;
    let isMandatory = q.isMandatory;
    let badgeColor = "error";
    if (answer) {
      badgeColor = "success";
    }
    let qElem = <Chip variant="outlined" label={labelPrefix} size="small" />;
    if (isMandatory) {
      qElem = (
        <Badge color={badgeColor} variant="dot">
          <Chip variant="outlined" label={labelPrefix} size="small" />
        </Badge>
      );
    }
    return (
      <Stack spacing={1} alignItems="flex-start">
        {qElem}
        <Typography variant="overline">{q.question}</Typography>
      </Stack>
    );
  };
  renderControl = (q, i) => {
    let type = q.answerType;
    if (type === "Section Header") return null;
    if (this.state.isSaved) {
      let answer = q.answer;
      if (answer) {
        if (q.answerType === "Date") {
          answer = niceDate(answer);
        }
      } else {
        answer = "Not Answered";
      }

      return <Typography variant="body2">{answer}</Typography>;
    }
    switch (type) {
      case "Multi-Select":
        let v = this.state.questions[i].answer;
        let vv = [];
        if (v) {
          vv = v.split(",");
        }
        return (
          <>
            <Stack spacing={2}>
              <Alert severity="info">
                Tap to choose as many as are applicable. Once done, tap the
                "Save" button below the options to save your selection.
              </Alert>
              <ToggleButtonGroup
                size="small"
                color="secondary"
                orientation="vertical"
                value={vv}
                onChange={(_, v) => {
                  this.setAnswers(i, v.join(","), false);
                }}
              >
                {q.answerOptions
                  .split(",")
                  .map((x) => _.trim(x))
                  .map((y) => (
                    <ToggleButton
                      size="small"
                      value={y}
                      sx={{
                        m: 1,
                      }}
                    >
                      {y}
                    </ToggleButton>
                  ))}
              </ToggleButtonGroup>
              <Button
                onClick={() => {
                  cogoToast.info("Saved Selection");
                  this.saveAnswers(i);
                }}
                variant="contained"
              >
                Save Selection
              </Button>
            </Stack>
            {vv.map((v) => {
              if (
                q.multiChoiceQuestionOptions &&
                q.multiChoiceQuestionOptions[v]
              ) {
                return (
                  <>
                    <Typography>
                      {q.multiChoiceQuestionOptions[v].question}
                    </Typography>
                    {this.renderMultiChoiceQuestion(q, v, i)}
                  </>
                );
              }
              return <></>;
            })}
          </>
        );
      case "Options":
        return (
          <Select
            size="small"
            sx={{ minWidth: 200 }}
            value={q.answer || ""}
            onChange={(e) => {
              this.setAnswers(i, e.target.value);
            }}
          >
            {q.answerOptions
              .split(",")
              .map((x) => _.trim(x))
              .map((each) => (
                <MenuItem value={each}>{each}</MenuItem>
              ))}
          </Select>
        );
      case "Text":
        return (
          <TextField
            size="small"
            value={q.answer}
            multiline
            fullWidth
            onChange={(e) => {
              this.setAnswers(i, e.target.value, false);
            }}
            onBlur={(e) => {
              this.saveAnswers(i);
            }}
          />
        );
      case "Date":
        return (
          <>
            <Chip
              size="small"
              variant="contained"
              onClick={(e) => {
                this.setState({
                  anchorEl: e.currentTarget,
                  [`open_${q._id}`]: true,
                });
              }}
              label={q.answer ? niceDate(q.answer) : "Not Set"}
            />
            <Popover
              open={this.state[`open_${q._id}`]}
              anchorEl={this.state.anchorEl}
              onClose={() => {
                this.setState({
                  anchorEl: null,
                  [`open_${q.label}`]: false,
                });
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <DayPicker
                mode="single"
                captionLayout="dropdown-buttons"
                fromYear={1901}
                toYear={2024}
                onSelect={(e) => {
                  this.setState({
                    anchorEl: null,
                    [`open_${q._id}`]: false,
                  });
                  this.setAnswers(i, e);
                }}
              />
            </Popover>
          </>
        );
      case "Yes/No":
        return (
          <>
            <RadioGroup
              size="small"
              value={q.answer}
              onChange={(e) => {
                this.setAnswers(i, e.target.value);
              }}
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
            {q.answer && (
              <>
                <Typography>
                  {q.answer === "Yes"
                    ? q.yesQuestion?.question
                    : q.noQuestion?.question}
                </Typography>
                {this.renderYesNoQuestion(q, i)}
              </>
            )}
          </>
        );
      default:
        return null;
    }
  };
  renderMultiChoiceQuestion = (q, answerOption, i) => {
    switch (q.multiChoiceQuestionOptions[answerOption].answerType) {
      case "Text":
        return (
          <TextField
            size="small"
            value={q.multiChoiceQuestionOptions[answerOption].answer}
            multiline
            fullWidth
            onChange={(e) => {
              this.setMcqAnswer(i, e.target.value, answerOption, false);
            }}
            // onBlur={(e) => {
            //   this.saveAnswers(i);
            // }}
          />
        );
      case "Date":
        return (
          <>
            <Chip
              size="small"
              variant="contained"
              onClick={(e) => {
                this.setState({
                  anchorEl: e.currentTarget,
                  [`open_${q._id}`]: true,
                });
              }}
              label={
                q.multiChoiceQuestionOptions[answerOption].answer
                  ? niceDate(q.multiChoiceQuestionOptions[answerOption].answer)
                  : "Not Set"
              }
            />
            <Popover
              open={this.state[`open_${q._id}`]}
              anchorEl={this.state.anchorEl}
              onClose={() => {
                this.setState({
                  anchorEl: null,
                  [`open_${q.label}`]: false,
                });
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <DayPicker
                mode="single"
                captionLayout="dropdown-buttons"
                fromYear={1901}
                toYear={2024}
                onSelect={(e) => {
                  this.setState({
                    anchorEl: null,
                    [`open_${q._id}`]: false,
                  });
                  this.setMcqAnswer(i, e, answerOption, false);
                }}
              />
            </Popover>
          </>
        );
      default:
        return null;
    }
  };
  renderYesNoQuestion = (q, i) => {
    const yesNoKey = q.answer === "Yes" ? "yesQuestion" : "noQuestion";
    let type = q[yesNoKey]?.answerType;
    if (type === "Section Header") return null;
    if (this.state.isSaved) {
      let answer = q.answer;
      if (answer) {
        if (q.answerType === "Date") {
          answer = niceDate(answer);
        }
      } else {
        answer = "Not Answered";
      }

      return <Typography variant="body2">{answer}</Typography>;
    }
    switch (type) {
      case "Multi-Select":
        let v = this.state.questions[i][yesNoKey].answer;
        let vv = [];
        if (v) {
          vv = v.split(",");
        }
        return (
          <Stack spacing={2}>
            <Alert severity="info">
              Tap to choose as many as are applicable. Once done, tap the "Save"
              button below the options to save your selection.
            </Alert>
            <ToggleButtonGroup
              size="small"
              color="secondary"
              orientation="vertical"
              value={vv}
              onChange={(_, v) => {
                this.setYesNoAnswer(i, v.join(","), yesNoKey, false);
              }}
            >
              {q[yesNoKey].answerOptions
                .split(",")
                .map((x) => _.trim(x))
                .map((y) => (
                  <ToggleButton
                    size="small"
                    value={y}
                    sx={{
                      m: 1,
                    }}
                  >
                    {y}
                  </ToggleButton>
                ))}
            </ToggleButtonGroup>
            <Button
              onClick={() => {
                cogoToast.info("Saved Selection");
                this.setAnswers(i);
              }}
              variant="contained"
            >
              Save Selection
            </Button>
          </Stack>
        );
      case "Options":
        return (
          <Select
            size="small"
            sx={{ minWidth: 200 }}
            value={q[yesNoKey].answer || ""}
            onChange={(e) => {
              this.setYesNoAnswer(i, e.target.value, yesNoKey);
            }}
          >
            {q[yesNoKey].answerOptions
              .split(",")
              .map((x) => _.trim(x))
              .map((each) => (
                <MenuItem value={each}>{each}</MenuItem>
              ))}
          </Select>
        );
      case "Text":
        return (
          <TextField
            size="small"
            value={q[yesNoKey].answer}
            multiline
            fullWidth
            onChange={(e) => {
              this.setAnswers(i, e.target.value, yesNoKey, false);
            }}
            onBlur={(e) => {
              this.saveAnswers(i);
            }}
          />
        );
      case "Date":
        return (
          <>
            <Chip
              size="small"
              variant="contained"
              onClick={(e) => {
                this.setState({
                  anchorEl: e.currentTarget,
                  [`open_${q._id}`]: true,
                });
              }}
              label={
                q[yesNoKey].answer ? niceDate(q[yesNoKey].answer) : "Not Set"
              }
            />
            <Popover
              open={this.state[`open_${q._id}`]}
              anchorEl={this.state.anchorEl}
              onClose={() => {
                this.setState({
                  anchorEl: null,
                  [`open_${q.label}`]: false,
                });
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <DayPicker
                mode="single"
                captionLayout="dropdown-buttons"
                fromYear={1901}
                toYear={2024}
                onSelect={(e) => {
                  this.setState({
                    anchorEl: null,
                    [`open_${q._id}`]: false,
                  });
                  this.setYesNoAnswer(i, e, yesNoKey);
                }}
              />
            </Popover>
          </>
        );
      default:
        return null;
    }
  };
  renderValidation = () => {
    let q = this.state.questions;
    if (!q) return null;
    if (!q.length) return null;
    if (this.state.isSaved) return null;
    let numQ = q.length - this.state.subheaders.length;
    let mandatoryQ = _.filter(
      q,
      (x) => x.isMandatory && x.answerType !== "Section Header"
    );
    let mandatoryYesNoNotAnswered = _.filter(
      q,
      (x) =>
        x.answerType === "Yes/No" &&
        x.isMandatory &&
        (x.yesQuestion?.isMandatory || x.noQuestion?.isMandatory) &&
        !x.yesQuestion?.answer &&
        !x.noQuestion?.answer
    );
    let allMandatoryAnswered = _.filter(
      mandatoryQ,
      (x) =>
        x.answer ||
        (x.answerType === "Yes/No" &&
          (x.yesQuestion?.answer || x.noQuestion?.answer))
    ).length;

    if (allMandatoryAnswered === mandatoryQ.length) {
      if (mandatoryYesNoNotAnswered.length > 0) {
        return (
          <Alert severity="error">
            Some mandatory Yes/No questions have not been answered yet.
          </Alert>
        );
      }
      return (
        <Stack>
          <Alert severity="success">
            All mandatory questions have been answered. You can now submit this
            questionnaire. Before you do so, please review your answers to
            ensure they are accurate. You will not be able to change your
            answers once you submit.
          </Alert>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={this.state.isSaving}
            onClick={() => {
              this.setState({ isSaving: true });
              this.submitAnswers();
            }}
          >
            Submit Response
          </LoadingButton>
        </Stack>
      );
    }
    return (
      <Alert severity="error">
        {mandatoryQ.length - allMandatoryAnswered} mandatory questions have not
        been answered yet.
      </Alert>
    );
  };
  render() {
    let styleObj = {};
    if (this.props.isPreview) {
      styleObj = {
        width: "80vw",
      };
    } else {
      styleObj = {
        width: "92vw",
      };
    }
    if (this.state.isLoading) {
      return (
        <div style={styleObj}>
          <LinearProgress />
        </div>
      );
    }
    return (
      <div style={styleObj}>
        <Stack
          spacing={3}
          sx={{
            width: 1,
            p: 3,
            borderRadius: 2,
            bgcolor: "background.paper",
          }}
        >
          {this.renderHeader()}
          {this.renderQuestions()}
          {this.renderValidation()}
        </Stack>
      </div>
    );
  }
}

export default WithAPICall(ServiceQPreview);
