import { Container, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import Iconify from "src/components/iconify/iconify";
import { LoadingScreen } from "src/components/loading-screen";
import { addUrlParams, getUrlParams } from "src/utils/url-params";

import { WithAPICall } from "../utils/apiUtil";
import EmailBuilder from "./EmailBuilder";
import EmailThreads from "./EmailThreads";
import cogoToast from "cogo-toast";
import { cloneDeep } from "lodash";

const tabs = [
  {
    value: "THREADS",
    label: "Emails",
    icon: <Iconify icon="ic:baseline-email" width={24} />,
  },
  {
    value: "BUILDER",
    label: "Email Templates",
    icon: <Iconify icon="carbon:template" width={24} />,
  },
];

class Emails extends React.Component {
  state = {
    currentTab: "THREADS",
    isLoading: true,

    templates: [],
  };

  init = async () => {
    this.setState({ isLoading: true });
    try {
      const res = await this.props.apiCallGet("/email/templates");
      this.setState({ templates: res.templates });
    } catch (error) {
      cogoToast.error("Could not retrieve templates. Please try again.");
      this.setState({ error: true });
    }
    this.setState({ isLoading: false });
  };

  handleCreateNewTemplate = async ({ createTemplateName }) => {
    try {
      const res = await this.props.apiCallPost("/email/createNewTemplate", {
        templateName: createTemplateName,
      });
      this.setState({
        templates: [...this.state.templates, res.template],
      });
      cogoToast.success("New Template Created.");
    } catch (error) {
      cogoToast.error("Could not create template.");
      console.error(error);
    }
    this.setState({
      openTemplateModal: false,
    });
  };
  handleTemplateSave = async ({ id, html, json }) => {
    try {
      const res = await this.props.apiCallPost("/email/updateTemplateHtml", {
        id,
        html,
        unlayerJson: json,
      });
      this.setState((prevState) => {
        const templates = cloneDeep(prevState.templates);
        templates.forEach((t) => {
          if (t._id === id) {
            t.html = html;
            t.unlayerJson = json;
          }
        });
        return { ...prevState, templates };
      });
      cogoToast.success("Template saved.");
    } catch (error) {
      cogoToast.error("Could not save template. Please try again.");
    }
  };

  async componentDidMount() {
    await this.init();
    const urlParams = getUrlParams();
    if (urlParams.currentTab) {
      this.setState({ currentTab: urlParams.currentTab });
    }
  }

  renderLoading = () => {
    return (
      <LoadingScreen
        sx={{
          borderRadius: 1.5,
          bgcolor: "background.default",
        }}
      />
    );
  };

  renderTabs = () => {
    return (
      <Tabs
        value={this.state.currentTab}
        onChange={(_, value) => {
          addUrlParams({ currentTab: value });
          this.setState({ currentTab: value });
        }}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            icon={tab.icon}
            value={tab.value}
          />
        ))}
      </Tabs>
    );
  };

  renderTab = () => {
    switch (this.state.currentTab) {
      case "THREADS":
        return <EmailThreads templates={this.state.templates} />;
      case "BUILDER":
        return (
          <EmailBuilder
            templates={this.state.templates}
            handleCreateNewTemplate={this.handleCreateNewTemplate}
            handleTemplateSave={this.handleTemplateSave}
          />
        );
      default:
        return "Not Implemented";
    }
  };

  render() {
    if (this.state.isLoading) {
      return this.renderLoading();
    }
    return (
      <>
        <Container maxWidth="xl">
          <Typography variant="h4">Mail</Typography>
          {this.renderTabs()}
          {this.renderTab()}
        </Container>
      </>
    );
  }
}

export default WithAPICall(Emails);
