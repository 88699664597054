import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import { Box, Stack } from "@mui/system";
import {
  Typography,
  Chip,
  LinearProgress,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  TextField,
  Divider,
} from "@mui/material";
import _ from "lodash";
import VisitServicesData from "./VisitServicesData";
import Iconify from "src/components/iconify";
import AILoader from "../utils/AILoader";
import { LoadingButton } from "@mui/lab";
import cogoToast from "cogo-toast";
import TokenContext from "src/TokenContext";
import { VisitProcessDrugTracker } from "./VisitProcessDrugTracker";
import AlcoholClearer from "./Clearers/AlcoholClearer";
import GenericXMLClearer from "./Clearers/GenericXMLClearer";
import DOTFormClearer from "./Clearers/DOTFormClearer";

class VisitProtocolDataEach extends React.Component {
  static contextType = TokenContext;

  state = {
    isLoading: true,
    isError: false,
    summary: "",
    isSummarizing: false,
    currentAction: null,
    isSaving: false,
    showDetail: true,
    isEvaling: false,
    clearance: "",
    typeOfClearance: "",
    clearanceCase: "",
    evaluation: "",
  };
  componentDidMount() {
    const services = this.props.visitData.servicesInfo;
    const childServices = services.filter(
      (s) =>
        this.props.visitData.serviceParentProtocols[s._id] ===
        this.props.protocolData._id
    );
    const childDrugServices = childServices.filter(
      (s) => s.serviceType === "DRUG"
    );

    const childAlcoholServices = childServices.filter(
      (s) => s.serviceType === "ALCOHOL"
    );

    const childMedicalServices = childServices.filter(
      (s) =>
        s.serviceRequiresProviderType === "Clinic" &&
        s.serviceType !== "DRUG" &&
        s.serviceType !== "ALCOHOL"
    );

    this.setState(
      {
        ...this.props.visitData,
        isLoading: false,
        childServices,
        childDrugServices,
        childAlcoholServices,
        childMedicalServices,
        // protocolNeedsMedicalClearance,
        // protocolNeedsAlcoholClearance,
        // protocolNeedsDrugClearance,
      },
      () => {
        this.checkForSummary();
      }
    );
  }

  doUpdateVisitClearance = async (
    entity,
    protocolId,
    clearance,
    typeOfClearance,
    clearanceCase,
    selections,
    clearanceInsn
  ) => {
    try {
      let payload = {
        visitId: this.state._id,
        entity: entity,
        entityId: protocolId,
        clearance: clearance,
        typeOfClearance: typeOfClearance,
        clearanceCase: clearanceCase,
        selections: selections,
        clearanceInsn: clearanceInsn,
      };
      cogoToast.loading("Updating Visit Clearance");
      await this.props.apiCallPost("/visit/updateVisitClearance", payload);
      cogoToast.success("Visit Clearance Updated");
      this.setState({
        isSaving: false,
      });
    } catch (err) {
      cogoToast.error("Error updating visit clearance.");
      console.log(err);
      this.setState({
        isSaving: false,
      });
    }
  };

  doUpdateVisitDrugProcessStatus = async (entityId, status, startClock) => {
    try {
      let payload = {
        visitId: this.state._id,
        entityId: entityId,
        status: status,
        startClock: startClock,
      };
      cogoToast.loading("Updating Visit Clearance");
      await this.props.apiCallPost(
        "/visit/updateVisitDrugProcessStatus",
        payload
      );
      cogoToast.success("Visit Process Status Updated");
      this.setState({
        isSaving: false,
      });
    } catch (err) {
      cogoToast.error("Error updating visit process status.");
      console.log(err);
      this.setState({
        isSaving: false,
      });
    }
  };

  checkForSummary = () => {
    const summary =
      this.props.visitData.protocolSummary[this.props.protocolData._id];
    const clearance =
      this.props.visitData.protocolClearance[this.props.protocolData._id];
    if (!summary) {
      this.setState({
        isLoading: false,
        showDetail: true,
      });
      return;
    }

    if (!clearance) {
      this.setState({
        isLoading: false,
        showDetail: true,
      });
      return;
    }

    this.setState({
      isLoading: false,
      showDetail: false,
      summary: summary.summary,
      evaluation: summary.evaluation || "",
      clearance: clearance.clearance,
      typeOfClearance: clearance.typeOfClearance,
      clearanceCase: clearance.clearanceCase,
    });

    // let visitData = this.props.visitData;
    // let protocolSummaries = visitData.protocolSummary;
    // if (!protocolSummaries) {
    //   this.setState({
    //     isLoading: false,
    //     showDetail: true,
    //   });
    // } else {
    //   let idx = _.findIndex(
    //     protocolSummaries,
    //     (x) => x.protocolId === this.props.protocolData._id
    //   );
    //   if (idx === -1) {
    //     this.setState({
    //       isLoading: false,
    //       showDetail: true,
    //     });
    //   } else {
    //     let obj = protocolSummaries[idx];
    //     this.setState({
    //       isLoading: false,
    //       showDetail: false,
    //       summary: obj.summary,
    //       clearance: obj.clearance,
    //       typeOfClearance: obj.typeOfClearance,
    //       clearanceCase: obj.clearanceCase,
    //       evaluation: obj.evaluation || "",
    //     });
    //   }
    // }
  };
  generateSummary = async () => {
    try {
      this.setState({
        summary: "",
        isSummarizing: true,
        currentAction: "Summarizing",
      });
      await this.props.apiCallPostStreamingForAI(
        "/external/pdf/generateProtocolSummary",
        {
          visitId: this.props.visitData._id,
          protocolId: this.props.protocolData._id,
          serviceIds: _.filter(
            this.props.visitData.bookedServices,
            (x) => x.parentProtocolId === this.props.protocolData._id
          ).map((x) => x.serviceId),
          stream: true,
        },
        (data) => {
          this.setState({
            summary: this.state.summary + data,
          });
        },
        () => {
          this.onEndSummarization();
        }
      );
    } catch (err) {
      cogoToast.error("Error Summarizing Service");
      console.log(err);
    }
  };
  onEndSummarization = async () => {
    // wait 2 seconds
    await new Promise((resolve) => setTimeout(resolve, 2000));
    this.setState({
      isSummarizing: false,
      currentAction: null,
    });
  };
  evalProtocolClearance = async () => {
    try {
      this.setState({
        evaluation: "",
        isEvaling: true,
        currentAction: "Evaluating",
      });
      await this.props.apiCallPostStreamingForAI(
        "/external/pdf/evalProtocolClearance",
        {
          visitId: this.props.visitData._id,
          protocolId: this.props.protocolData._id,
          serviceIds: _.filter(
            this.props.visitData.bookedServices,
            (x) => x.parentProtocolId === this.props.protocolData._id
          ).map((x) => x.serviceId),
          stream: true,
        },
        (data) => {
          this.setState({
            evaluation: this.state.evaluation + data,
          });
        },
        () => {
          this.onEndEval();
        }
      );
    } catch (err) {
      cogoToast.error("Error Evaluating Protocol");
      console.log(err);
    }
  };
  onEndEval = async () => {
    // wait 2 seconds
    await new Promise((resolve) => setTimeout(resolve, 2000));
    this.setState({
      isEvaling: false,
      currentAction: null,
    });
  };
  updateVisitClearance = async () => {
    try {
      this.setState({
        isSaving: true,
      });
      let res = await this.props.apiCallPost("/visit/updateVisitClearance", {
        visitId: this.props.visitData._id,
        entity: "Protocol",
        entityId: this.props.protocolData._id,
        clearance: this.state.clearance,
        typeOfClearance: this.state.typeOfClearance,
        clearanceCase: this.state.clearanceCase,
        clearanceInsn: "",
      });
      this.setState({
        ...res,
        isSaving: false,
      });
    } catch (err) {
      this.setState({
        isSaving: false,
      });
      cogoToast.error("Error Updating Visit Clearance Data");
      console.log(err);
    }
  };
  // renderServices = () => {
  //   if (!this.state.showDetail) {
  //     return null;
  //   }
  //   return (
  //     <VisitServicesData
  //       protocolData={this.props.protocolData}
  //       id={this.props.id}
  //       visitData={this.props.visitData}
  //     />
  //   );
  // };
  renderCurrentAction = () => {
    if (!this.state.currentAction) {
      return null;
    }
    return (
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <Chip
          label={this.state.currentAction}
          icon={<Iconify icon="svg-spinners:pulse-3" />}
        />
      </Stack>
    );
  };
  renderSummary = () => {
    if (this.state.isSummarizing && !this.state.summary) {
      return <LinearProgress />;
    }
    if (!this.state.summary) {
      return null;
    }
    let s = this.state.summary;
    let lines = s.split("\n");
    return (
      <Stack spacing={2}>
        <Typography variant="overline">Protocol Summary</Typography>
        <Divider />
        <Stack spacing={0}>
          {lines.map((line) => {
            let ll = _.trim(line);
            if (ll.startsWith("Red Flags:")) {
              let rest = ll.replace("Red Flags:", "");
              return (
                <Stack spacing={1}>
                  {" "}
                  <Box
                    sx={{
                      mt: 1,
                    }}
                  >
                    {/* <Chip label="Red Flags" color="error" size="small" /> */}
                    <Typography variant="subtitle2">Red Flags</Typography>
                  </Box>
                  <Typography variant="caption">{rest}</Typography>
                </Stack>
              );
            }
            // Headline: ==> info
            if (ll.startsWith("Headline:")) {
              let rest = ll.replace("Headline:", "");
              return (
                <Stack spacing={1}>
                  <Box
                    sx={{
                      mt: 1,
                    }}
                  >
                    {/* <Chip label="Headline" color="info" size="small" /> */}
                    <Typography variant="subtitle2">Headline</Typography>
                  </Box>
                  <Typography variant="caption">{rest}</Typography>
                </Stack>
              );
            }
            // Summary: ==> secondary

            if (ll.startsWith("Summary:")) {
              let rest = ll.replace("Summary:", "");
              return (
                <Stack spacing={1}>
                  <Box
                    sx={{
                      mt: 1,
                    }}
                  >
                    {/* <Chip label="Summary" color="secondary" size="small" /> */}
                    <Typography variant="subtitle2">Summary</Typography>
                  </Box>
                  <Typography variant="caption">{rest}</Typography>
                </Stack>
              );
            }
            return <Typography variant="caption">{line}</Typography>;
          })}
        </Stack>
      </Stack>
    );
  };
  renderSummaryButton = () => {
    if (this.context.currentUser.isExternal) {
      return null;
    }
    if (this.state.isSummarizing) {
      return <AILoader />;
    }

    let serviceIds = _.filter(
      this.props.visitData.bookedServices,
      (x) => x.parentProtocolId === this.props.protocolData._id
    ).map((x) => x.serviceId);

    if (!serviceIds.length) {
      return null;
    }
    let summText = "Summarize";
    if (this.state.summary) {
      summText = "Resummarize";
    }
    return (
      <Stack direction="row" alignItems={"center"} spacing={2}>
        <LoadingButton
          disabled={this.state.isSaving}
          loading={this.state.isSummarizing}
          onClick={() => {
            this.generateSummary();
          }}
          startIcon={<Iconify icon="ooui:text-summary-ltr" />}
          variant="outlined"
        >
          {summText}
        </LoadingButton>
      </Stack>
    );
  };
  renderEval = () => {
    if (this.state.isEvaling && !this.state.evaluation) {
      return <LinearProgress />;
    }
    if (!this.state.evaluation) {
      return null;
    }
    let s = this.state.evaluation;
    let lines = s.split("\n");
    return (
      <Stack spacing={2}>
        <Typography variant="overline">Protocol Evaluation</Typography>
        <Divider />
        <Stack spacing={0}>
          {lines.map((line) => {
            let ll = _.trim(line);
            if (ll.startsWith("Recommendation:")) {
              return (
                <Stack spacing={1} direction="row" alignItems={"center"}>
                  {" "}
                  <Box
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Chip
                      label={`AI ${line}`}
                      color="secondary"
                      size="small"
                      variant="outlined"
                    />
                  </Box>
                </Stack>
              );
            }
            return <Typography variant="caption">{line}</Typography>;
          })}
        </Stack>
      </Stack>
    );
  };
  renderEvalButton = () => {
    if (this.context.currentUser.isExternal) {
      return null;
    }
    if (this.state.isEvaling) {
      return <AILoader />;
    }

    let evalText = "Evaluate";
    if (this.state.evaluation) {
      evalText = "Re-Evaluate";
    }
    return (
      <Stack direction="row" alignItems={"center"} spacing={2}>
        <LoadingButton
          disabled={
            this.state.isSaving ||
            this.state.isEvaling ||
            this.state.isSummarizing
          }
          loading={this.state.isEvaling}
          onClick={() => {
            this.evalProtocolClearance();
          }}
          startIcon={<Iconify icon="tabler:list-check" />}
          variant="outlined"
        >
          {evalText}
        </LoadingButton>
      </Stack>
    );
  };
  renderProtocolHeader = () => {
    return (
      <Stack direction="row" alignItems={"center"} spacing={2}>
        <Typography variant="subtitle1">
          {this.props.protocolData.companyProtocolName}
        </Typography>
        {this.renderSummaryButton()}
        {this.renderEvalButton()}
        {this.renderCurrentAction()}
      </Stack>
    );
  };

  renderMedicalClearance = () => {
    if (this.state.childMedicalServices.length === 0) {
      return null;
    }

    const childDOTServices = this.state.childMedicalServices.filter((s) =>
      s.documentTypes.includes("DOT_CARD")
    );

    if (childDOTServices.length > 0) {
      return (
        <DOTFormClearer
          name="DOT Form & Card"
          visit={this.state}
          protocol={this.props.protocolData}
          groups={this.props.groups}
          onClearanceChange={(
            clearance,
            typeOfClearance,
            clearanceCase,
            selections
          ) => {
            this.doUpdateVisitClearance(
              "Medical",
              this.props.protocolData._id,
              clearance,
              typeOfClearance,
              clearanceCase,
              selections,
              ""
            );
          }}
        />
      );
    } else {
      return (
        <Stack spacing={1}>
          <GenericXMLClearer
            name={""}
            visit={this.state}
            protocol={this.props.protocolData}
            groups={this.props.groups}
            onClearanceChange={(
              clearance,
              typeOfClearance,
              clearanceCase,
              selections
            ) => {
              this.doUpdateVisitClearance(
                "Medical",
                this.props.protocolData._id, // use protocol _id
                clearance,
                typeOfClearance,
                clearanceCase,
                selections,
                ""
              );
            }}
          />
        </Stack>
      );
    }
  };

  renderDrugClearance = () => {
    return (
      <Stack spacing={2}>
        {this.state.childDrugServices.map((drug) => {
          return (
            <VisitProcessDrugTracker
              name={drug.serviceName}
              visit={this.state}
              protocol={this.props.protocolData}
              service={drug}
              doUpdateVisitClearance={this.doUpdateVisitClearance}
              doUpdateVisitDrugProcessStatus={
                this.doUpdateVisitDrugProcessStatus
              }
              groups={this.props.groups}
            />
          );
        })}
      </Stack>
    );
  };

  renderAlcoholClearance = () => {
    if (this.state.childAlcoholServices.length === 0) {
      return null;
    }
    return (
      <AlcoholClearer
        name="Alcohol Clearance"
        visit={this.state}
        protocol={this.props.protocolData}
        groups={this.props.groups}
        onClearanceChange={(
          clearance,
          typeOfClearance,
          clearanceCase,
          selections
        ) => {
          this.doUpdateVisitClearance(
            "DrugAlcohol",
            this.state.childAlcoholServices[0]._id, // use service _id
            clearance,
            typeOfClearance,
            clearanceCase,
            selections,
            ""
          );
        }}
      />
    );
  };

  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <Stack spacing={2}>
        {this.renderProtocolHeader()}
        {this.renderSummary()}
        {this.renderEval()}

        {this.renderMedicalClearance()}
        {this.renderDrugClearance()}
        {this.renderAlcoholClearance()}
      </Stack>
    );
  }
}

export default WithAPICall(VisitProtocolDataEach);
