import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Card,
  Typography,
  Select,
  MenuItem,
  TextField,
  Box,
  Button,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { Stack } from "@mui/system";
import _ from "lodash";
import { niceDateTZ } from "src/micro/utils/fn";
import moment from "moment";

const newDate = (s) => {
  if (s) return new Date(s.replaceAll("Z", ""));
  return new Date();
};

const getStdExpirationDate = (groups) => {
  const g = _.find(groups, (g) => g.pageDataType === "DOT_CARD");
  if (!g) return null;
  const dotExpirationDate = _.find(
    g.dataStructured,
    (d) => d.key === "dotExpirationDate"
  );
  if (!dotExpirationDate) return null;
  return moment(dotExpirationDate.value, "MM/DD/YYYY").toDate().toString();
};

const DOTFormClearer = ({
  name,
  visit,
  protocol,
  groups,
  onClearanceChange,
}) => {
  const savedCO = visit.protocolClearance[protocol._id];

  const [primarySelection, setPrimarySelection] = useState(
    _.get(savedCO, "selections.primarySelection", "")
  );
  const [secondarySelection, setSecondarySelection] = useState(
    _.get(savedCO, "selections.secondarySelection", [])
  );
  const [finalSelection, setFinalSelection] = useState(
    _.get(savedCO, "selections.finalSelection", "")
  );
  const [finalClearanceText, setFinalClearanceText] = useState(
    _.get(savedCO, "finalClearanceText", "")
  );

  const _stdExp = getStdExpirationDate(groups);

  const [date1, setDate1] = useState(
    newDate(_.get(savedCO, "selections.date1", _stdExp))
  );
  const [date2, setDate2] = useState(
    newDate(_.get(savedCO, "selections.date2", null))
  );

  useEffect(() => {
    let prelim = "";
    switch (primarySelection) {
      case "NOT_CLEARED":
        prelim =
          "The employee does not meet standards in 49 CFR 391.41 with any applicable State variances.";
        break;
      case "CLEARED":
        prelim =
          "The employee meets standards in 49 CFR 391.41 with any applicable State variances.";
        break;
      case "CLEARED_WITH_RESTRICTIONS":
        prelim = `The employee meets standards in 49 CFR 391.41 with any applicable State variances with the following: ${secondarySelection}.`;
        break;
    }
    let sec = "";
    const clinicExpDate = niceDateTZ(date1);
    const wcExpDate = niceDateTZ(date2);
    switch (finalSelection) {
      case "DOT_PHY_EXP_AGREE":
        sec = `WorkCare agrees with the DOT card expiration date provided by the examiner. The DOT card expiration is ${clinicExpDate}`;
        break;
      case "DOT_PHY_EXP_DISAGREE":
        sec = `WorkCare recommends a DOT card expiration of ${wcExpDate} instead of the DOT expiration card of ${clinicExpDate}`;
        break;
    }

    setFinalClearanceText(`The above noted individual was reviewed in accordance with the FMCSA Medical Review Board recommendations.  
On the basis of this review the findings/comments are as follows:
${prelim}
${sec}
  `);
  }, [primarySelection, secondarySelection, finalSelection, date1, date2]);

  const handlePrimaryChange = (event) => {
    setPrimarySelection(event.target.value);
    if (event.target.value !== "D") {
      setSecondarySelection([]);
    }
  };

  const handleSecondaryChange = (event) => {
    setSecondarySelection(event.target.value);
  };

  const handleFinalChange = (event) => {
    setFinalSelection(event.target.value);
  };

  return (
    <Card sx={{ p: 3 }}>
      <Stack spacing={1}>
        <Typography variant="body2">Clearance: {name}</Typography>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Examiner Recommended Expiration Date"
            value={date1}
            onChange={(newValue) => setDate1(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="WorkCare Recommended Expiration Date"
            value={date2}
            onChange={(newValue) => setDate2(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <FormControl fullWidth>
          <InputLabel>Clearance</InputLabel>
          <Select
            value={primarySelection}
            label="Clearance"
            onChange={handlePrimaryChange}
          >
            <MenuItem value="NOT_CLEARED">
              The employee does not meet standards in 49 CFR 391.41 with any
              applicable State variances
            </MenuItem>
            <MenuItem value="CLEARED">
              The employee meets standards in 49 CFR 391.41 with any applicable
              State variances
            </MenuItem>
            <MenuItem value="CLEARED_WITH_RESTRICTIONS">
              The employee meets standards in 49 CFR 391.41 with any applicable
              State variances with the following...
            </MenuItem>
          </Select>
        </FormControl>

        {primarySelection === "CLEARED_WITH_RESTRICTIONS" && (
          <FormControl fullWidth>
            <InputLabel>Restrictions</InputLabel>
            <Select
              multiple
              value={secondarySelection}
              label="Restrictions"
              onChange={handleSecondaryChange}
              renderValue={(selected) => selected.join(", ")}
            >
              {[
                "wearing corrective lenses",
                "wearing hearing aid",
                "accompanied by a waiver/exception",
                "accompanied by a Skill Performance Evaluation (SPE) Certificate",
                "Grandfathered from state requirements",
              ].map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={secondarySelection.indexOf(option) > -1} />
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl fullWidth>
          <InputLabel>Final Selection</InputLabel>
          <Select
            value={finalSelection}
            label="Expiration Determined"
            onChange={handleFinalChange}
          >
            <MenuItem value="DOT_PHY_EXP_AGREE">
              WorkCare agrees with the DOT card expiration date provided by the
              examiner. The DOT card expiration is...
            </MenuItem>
            <MenuItem value="DOT_PHY_EXP_DISAGREE">
              WorkCare recommends a DOT card expiration of ... instead of the
              DOT expiration card of ...
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Final Clearance Text"
          value={finalClearanceText}
          multiline
          minRows={3}
          onChange={(e) => setFinalClearanceText(e.target.value)}
        />
        <Button
          variant="contained"
          onClick={() => {
            onClearanceChange(
              finalClearanceText,
              primarySelection,
              primarySelection,
              {
                primarySelection,
                secondarySelection,
                finalSelection,
                finalClearanceText,
                date1,
                date2,
              }
            );
          }}
        >
          Save & Update Clearance
        </Button>
      </Stack>
    </Card>
  );
};

export default DOTFormClearer;
