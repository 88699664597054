import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {
  MenuItem,
  Select,
  TextField,
  Chip,
  LinearProgress,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { UploadAvatar } from "src/components/upload";
import CountrySelector from "../utils/CountrySelector";
import { WithAPICall } from "../utils/apiUtil";
import GMap from "../utils/GMap";
import UploadSnack from "../utils/UploadSnack";
import cogoToast from "cogo-toast";
import Popover from "@mui/material/Popover";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { niceDate } from "../utils/fn";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

const filter = createFilterOptions();
class EmployeeProfile extends React.Component {
  state = { isLoading: true };
  componentDidMount() {
    this.setState(
      {
        ...this.props.data,
        isLoading: false,
      },
      () => {
        if (this.state.employeePhoto) {
          this.getFile(this.state.employeePhoto);
        }
      }
    );
  }
  LIST_OF_SEX = ["Male", "Female", "Other"];
  LIST_OF_PREF_COMM = [
    "Work Phone",
    "Home Phone",
    "Personal Phone",
    "Work Email",
    "Personal Email",
    "Work Phone - Text",
    "Home Phone - Text",
    "Personal Phone - Text",
  ];
  updateScalarValue = async (key, value) => {
    if (value === null || value === undefined) {
      return;
    }
    if (this.props.data[key] === value) {
      return;
    }
    try {
      await this.props.apiCallPost("/employee/updateScalarValue", {
        employeeId: this.props.id,
        key: key,
        value: value,
      });
    } catch (err) {
      cogoToast.error("Error Updating Employee Data");
      console.log(err);
    }
  };
  setLogo = async (file) => {
    try {
      const fd = new FormData();
      fd.append("file", file[0]);
      fd.append("employeeId", this.props.id);
      fd.append("purpose", "employeePhoto");
      const res = await this.props.apiCallPostUpload(
        "/employee/setPhoto",
        fd,
        (e) => {
          this.setState({
            uploadProgress: e,
          });
        }
      );
      this.setState({
        uploadProgress: null,
        employeePhotoUrl: res,
      });
    } catch (err) {
      cogoToast.error("Error Uploading Logo");
      console.log(err);
    }
  };
  getFile = async (id) => {
    // try {
    //   const res = await this.props.apiCallPost("/employee/getFile", {
    //     fileId: id,
    //   });
    //   this.setState({
    //     employeePhotoUrl: res.psUrl,
    //   });
    // } catch (err) {
    //   console.log(err);
    // }
  };
  setPlace = (place) => {
    this.setState(
      {
        employeeCity: place.city,
        employeeCountry: place.country,
        employeeCountryCallingCode:
          place.country === "United States" ? "1" : "",
        employeeCountryShortCode: place.country === "United States" ? "US" : "",
        employeeState: place.state,
        employeeAddress: place.formatted_address,
        employeeGeoLat: place.geolocation.lat,
        employeeGeoLong: place.geolocation.lng,
        employeePostalCode: place.postal_code,
      },
      () => {
        this.updateScalarValue("employeeCity", place.city);
        this.updateScalarValue("employeeCountry", place.country);
        this.updateScalarValue(
          "employeeCountryCallingCode",
          place.country === "United States" ? "1" : ""
        );
        this.updateScalarValue(
          "employeeCountryShortCode",
          place.country === "United States" ? "US" : ""
        );
        this.updateScalarValue("employeeState", place.state);
        this.updateScalarValue("employeeAddress", place.formatted_address);
        this.updateScalarValue("employeeGeoLat", place.geolocation.lat);
        this.updateScalarValue("employeeGeoLong", place.geolocation.lng);
        this.updateScalarValue("employeePostalCode", place.postal_code);
      }
    );
  };
  renderDOBNicely = (d) => {
    if (!d) return "DoB Not Entered";
    return `DoB: ${niceDate(d)}`;
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <div>
        <Grid container spacing={3}>
          <Grid xs={12} md={4}>
            <Card sx={{ pt: 3, pb: 3, px: 3, textAlign: "center" }}>
              <Stack spacing={2}>
                <UploadSnack uploadProgress={this.state.uploadProgress} />
                <UploadAvatar
                  onDrop={(e) => this.setLogo(e)}
                  file={this.state.employeePhotoUrl}
                />
                <TextField
                  label="Employee Name"
                  value={this.state.employeeName}
                  onChange={(e) => {
                    this.setState({ employeeName: e.target.value });
                  }}
                  onBlur={() => {
                    this.updateScalarValue(
                      "employeeName",
                      this.state.employeeName
                    );
                    this.props.onUpdate(
                      "employeeName",
                      this.state.employeeName
                    );
                  }}
                />
                <TextField
                  label="Preferred Name"
                  value={this.state.employeePreferredName}
                  onChange={(e) => {
                    this.setState({ employeePreferredName: e.target.value });
                  }}
                  onBlur={() => {
                    this.updateScalarValue(
                      "employeePreferredName",
                      this.state.employeePreferredName
                    );
                    this.props.onUpdate(
                      "employeePreferredName",
                      this.state.employeePreferredName
                    );
                  }}
                />
                <Autocomplete
                  disableClearable
                  value={{ label: this.state.employeeSex || "" }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  options={this.LIST_OF_SEX.map((each, index) => {
                    return { label: each, id: index };
                  })}
                  onChange={(_, newValue) => {
                    if (typeof newValue === "string") {
                      this.setState(
                        {
                          employeeSex: newValue,
                        },
                        () => {
                          this.updateScalarValue(
                            "employeeSex",
                            this.state.employeeSex
                          );
                        }
                      );
                    } else if (newValue && newValue.inputValue) {
                      this.setState(
                        {
                          employeeSex: newValue.inputValue,
                        },
                        () => {
                          this.updateScalarValue(
                            "employeeSex",
                            this.state.employeeSex
                          );
                        }
                      );
                    } else {
                      this.setState(
                        {
                          employeeSex: newValue.label,
                        },
                        () => {
                          this.updateScalarValue(
                            "employeeSex",
                            this.state.employeeSex
                          );
                        }
                      );
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some(
                      (option) => inputValue === option.label
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        label: `Add "${inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.label;
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>{option.label}</li>
                  )}
                  sx={{ width: 300 }}
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} label="Sex" />
                  )}
                />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Employee Date of Birth"
                    value={moment(new Date(this.state.employeeDoB))}
                    onChange={(e) => {
                      if (e.isValid()) {
                        const date = e.toDate();
                        this.setState({
                          employeeDoB: date,
                        });
                        this.updateScalarValue("employeeDoB", date);
                      }
                    }}
                  />
                </LocalizationProvider>
                {/* <Chip
                  variant="contained"
                  onClick={(e) => {
                    this.setState({
                      anchorEl: e.currentTarget,
                      dateOpen: true,
                    });
                  }}
                  label={this.renderDOBNicely(this.state.employeeDoB)}
                /> */}
                {/* <Popover
                  open={this.state.dateOpen}
                  anchorEl={this.state.anchorEl}
                  onClose={() => {
                    this.setState({ anchorEl: null, dateOpen: false });
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <DayPicker
                    mode="single"
                    captionLayout="dropdown-buttons"
                    fromYear={1901}
                    toYear={2024}
                    onSelect={(e) => {
                      this.setState({
                        employeeDoB: e,
                        anchorEl: null,
                        dateOpen: false,
                      });
                      this.updateScalarValue("employeeDoB", e);
                    }}
                  />
                </Popover> */}
                <TextField
                  label="SSN"
                  value={this.state.employeeSSN}
                  onChange={(e) => {
                    this.setState({ employeeSSN: e.target.value });
                  }}
                  onBlur={() => {
                    this.updateScalarValue(
                      "employeeSSN",
                      this.state.employeeSSN
                    );
                  }}
                />
                <TextField
                  label="Pronoun"
                  value={this.state.employeePronoun}
                  onChange={(e) => {
                    this.setState({ employeePronoun: e.target.value });
                  }}
                  onBlur={() => {
                    this.updateScalarValue(
                      "employeePronoun",
                      this.state.employeePronoun
                    );
                  }}
                />
                <TextField
                  label="Preferred Language"
                  value={this.state.employeePreferredLanguage}
                  onChange={(e) => {
                    this.setState({
                      employeePreferredLanguage: e.target.value,
                    });
                  }}
                  onBlur={() => {
                    this.updateScalarValue(
                      "employeePreferredLanguage",
                      this.state.employeePreferredLanguage
                    );
                  }}
                />
              </Stack>
            </Card>
          </Grid>
          <Grid xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={2}>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  }}
                >
                  {" "}
                  <PhoneInput
                    defaultCountry="US"
                    placeholder="Work Phone"
                    value={this.state.employeeOfficePhone}
                    onChange={(e) => {
                      this.setState({ employeeOfficePhone: e });
                    }}
                    onBlur={() => {
                      this.updateScalarValue(
                        "employeeOfficePhone",
                        this.state.employeeOfficePhone
                      );
                    }}
                  />
                  <PhoneInput
                    defaultCountry="US"
                    placeholder="Home Phone"
                    value={this.state.employeeHomePhone}
                    onChange={(e) => {
                      this.setState({ employeeHomePhone: e });
                    }}
                    onBlur={() => {
                      this.updateScalarValue(
                        "employeeHomePhone",
                        this.state.employeeHomePhone
                      );
                    }}
                  />
                  <PhoneInput
                    defaultCountry="US"
                    placeholder="Personal Phone"
                    value={this.state.employeePersonalPhone}
                    onChange={(e) => {
                      this.setState({ employeePersonalPhone: e });
                    }}
                    onBlur={() => {
                      this.updateScalarValue(
                        "employeePersonalPhone",
                        this.state.employeePersonalPhone
                      );
                    }}
                  />
                  <Select
                    value={this.state.employeePreferredCommunication}
                    onChange={(e) => {
                      this.setState(
                        {
                          employeePreferredCommunication: e.target.value,
                        },
                        () => {
                          this.updateScalarValue(
                            "employeePreferredCommunication",
                            this.state.employeePreferredCommunication
                          );
                        }
                      );
                    }}
                  >
                    {this.LIST_OF_PREF_COMM.map((each) => {
                      return <MenuItem value={each}>{each}</MenuItem>;
                    })}
                  </Select>
                  <TextField
                    label="Work Email"
                    value={this.state.employeeOfficeEmail}
                    onChange={(e) => {
                      this.setState({ employeeOfficeEmail: e.target.value });
                    }}
                    onBlur={() => {
                      if (this.state.employeeOfficeEmail) {
                        this.updateScalarValue(
                          "employeeOfficeEmail",
                          this.state.employeeOfficeEmail
                        );
                      }
                    }}
                  />
                  <TextField
                    label="Personal Email"
                    value={this.state.employeePersonalEmail}
                    onChange={(e) => {
                      this.setState({ employeePersonalEmail: e.target.value });
                    }}
                    onBlur={() => {
                      this.updateScalarValue(
                        "employeePersonalEmail",
                        this.state.employeePersonalEmail
                      );
                    }}
                  />
                </Box>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  }}
                ></Box>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  }}
                >
                  <Box
                    rowGap={3}
                    columnGap={2}
                    display="grid"
                    gridTemplateColumns={{
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(1, 1fr)",
                    }}
                  >
                    <GMap
                      onPlace={(r) => this.setPlace(r)}
                      geoObject={{
                        lat: this.state.employeeGeoLat,
                        lng: this.state.employeeGeoLong,
                      }}
                      onLatLng={(geo) => {
                        this.setState({
                          employeeGeoLat: geo.lat,
                          employeeGeoLong: geo.lng,
                        });
                        this.updateScalarValue("employeeGeoLat", geo.lat);
                        this.updateScalarValue("employeeGeoLong", geo.lng);
                      }}
                    />
                  </Box>
                  <Box
                    rowGap={1}
                    columnGap={1}
                    display="grid"
                    gridTemplateColumns={{
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(1, 1fr)",
                    }}
                  >
                    <CountrySelector
                      value={{
                        label: this.state.employeeCountry || "",
                        code: this.state.employeeCountryShortCode || "",
                        phone: this.state.employeeCountryCallingCode || "",
                      }}
                      onChange={(e) => {
                        this.setState({
                          country: e,
                          employeeCountry: e.label,
                          employeeCountryShortCode: e.code,
                          employeeCountryCallingCode: e.phone,
                        });
                        this.updateScalarValue("employeeCountry", e.label);
                        this.updateScalarValue(
                          "employeeCountryShortCode",
                          e.code
                        );
                        this.updateScalarValue(
                          "employeeCountryCallingCode",
                          e.phone
                        );
                      }}
                    />

                    <TextField
                      InputLabelProps={{
                        shrink: this.state.employeeAddress,
                      }}
                      label="Address Line 1"
                      multiline
                      value={this.state.employeeAddress}
                      onChange={(e) => {
                        this.setState({
                          employeeAddress: e.target.value,
                        });
                      }}
                      onBlur={() => {
                        this.updateScalarValue(
                          "employeeAddress",
                          this.state.employeeAddress
                        );
                      }}
                    />
                    <TextField
                      InputLabelProps={{
                        shrink: this.state.employeeAddressQualilfier,
                      }}
                      label="Address Line 2"
                      multiline
                      value={this.state.employeeAddressQualilfier}
                      onChange={(e) => {
                        this.setState({
                          employeeAddressQualilfier: e.target.value,
                        });
                      }}
                      onBlur={() => {
                        this.updateScalarValue(
                          "employeeAddressQualilfier",
                          this.state.employeeAddressQualilfier
                        );
                      }}
                    />
                    <TextField
                      InputLabelProps={{
                        shrink: this.state.employeeCity,
                      }}
                      label="City"
                      value={this.state.employeeCity}
                      onChange={(e) => {
                        this.setState({ employeeCity: e.target.value });
                      }}
                      onBlur={() => {
                        this.updateScalarValue(
                          "employeeCity",
                          this.state.employeeCity
                        );
                      }}
                    />
                    <TextField
                      InputLabelProps={{
                        shrink: this.state.employeeState,
                      }}
                      label="State"
                      value={this.state.employeeState}
                      onChange={(e) => {
                        this.setState({ employeeState: e.target.value });
                      }}
                      onBlur={() => {
                        this.updateScalarValue(
                          "employeeState",
                          this.state.employeeState
                        );
                      }}
                    />
                    <TextField
                      InputLabelProps={{
                        shrink: this.state.employeePostalCode,
                      }}
                      label="Postal Code"
                      value={this.state.employeePostalCode}
                      onChange={(e) => {
                        this.setState({ employeePostalCode: e.target.value });
                      }}
                      onBlur={() => {
                        this.updateScalarValue(
                          "employeePostalCode",
                          this.state.employeePostalCode
                        );
                      }}
                    />
                  </Box>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default WithAPICall(EmployeeProfile);
