import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import AILoader from "../utils/AILoader";
import { Stack } from "@mui/system";
import { IconButton, TextField, Typography } from "@mui/material";
import _ from "lodash";
import { LoadingButton } from "@mui/lab";
import Iconify from "src/components/iconify";

class VisitPageParseData extends React.Component {
  state = {
    isReadingPage: false,
    pageIx: null,
    pageType: null,
    pageContent: null,
    provenance: null,
    readoutContent: "",
    isEditing: false,
    hasBeenChangedByHuman: false,
    parameters: [],
    items: [],
    parsedData: "",
  };
  componentDidMount() {
    let contentGroup = this.props.contentGroup;
    if (contentGroup) {
      if (contentGroup.parameters && contentGroup.parameters.length) {
        this.setState({
          parameters: contentGroup.parameters,
        });
      }
    }
  }
  readPagesOfFile = async () => {
    try {
      this.setState({
        parsedData: "",
        isReadingPage: true,
        isEditing: false,
      });
      await this.props.apiCallPostStreamingForAI(
        "/external/pdf/parseDocumentValues",
        {
          fileId: this.props.fileId,
          stream: true,
          contentGroupId: this.props.contentGroup._id,
        },
        (data) => {
          console.log(data);
          this.setState({
            parsedData: this.state.parsedData + data,
          });
        },
        () => {
          console.log(this.state.parsedData);
          this.onEnd();
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  onEnd = async () => {
    // wait for 2 seconds.

    await new Promise((resolve) => setTimeout(resolve, 2000));

    let res = JSON.parse(this.state.parsedData);
    console.log(res, this.state.parsedData);
    this.setState({
      isReadingPage: false,
      items: res.data,
    });
  };

  renderPageContent = () => {
    if (this.state.isReadingPage) {
      let parsedData = _.clone(this.state.parsedData);
      if (!parsedData) {
        return <Typography variant="overline">Beginning to parse</Typography>;
      }
      // count of the word "parameter"
      let count = (parsedData.match(/parameter/g) || []).length;
      return (
        <Typography variant="overline">Parsed {count} Parameters</Typography>
      );
    }
    return (
      <div>
        {this.state.items.map((item, index) => (
          <div key={index}>
            <p>Parameter: {item.parameter}</p>
            <p>Value: {item.value}</p>
            <p>Notes: {item.notes}</p>
          </div>
        ))}
      </div>
    );
  };
  renderButton = () => {
    if (this.state.isReadingPage) {
      return <AILoader text="READING" />;
    }
    if (this.state.readoutContent) {
      if (this.state.isEditing) {
        return (
          <Stack direction="row" alignItems={"center"} spacing={2}>
            <Typography variant="overline">Editing</Typography>
            <IconButton
              size="small"
              variant="contained"
              color="success"
              onClick={() => {
                // if no edit --> nothing.
                if (!this.state.hasBeenChangedByHuman) {
                  this.setState({
                    isEditing: false,
                  });
                } else {
                  this.props.editContentReadOut(
                    this.state.readoutContent,
                    "USER_GENERATED"
                  );
                  this.setState({
                    isEditing: false,
                  });
                }
              }}
            >
              <Iconify icon="mingcute:check-circle-fill" />
            </IconButton>
          </Stack>
        );
      } else {
        return (
          <Stack direction="row" alignItems={"center"} spacing={2}>
            <Typography variant="overline">Content</Typography>
            <IconButton
              size="small"
              variant="outlined"
              onClick={() => {
                this.setState({ isEditing: true });
              }}
            >
              <Iconify icon="mingcute:edit-2-line" />
            </IconButton>
          </Stack>
        );
      }
    }
    return (
      <LoadingButton
        loading={this.state.isReadingPage}
        onClick={this.readPagesOfFile}
      >
        Read Page Data
      </LoadingButton>
    );
  };
  render() {
    return (
      <Stack>
        {this.renderButton()}
        {this.renderPageContent()}
      </Stack>
    );
  }
}

export default WithAPICall(VisitPageParseData);
