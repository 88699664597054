import React from "react";
import { Button, Chip, LinearProgress, TextField } from "@mui/material";
import { NoResultView, View500 } from "src/sections/error";
import ContactCreate from "../utils/ContactCreate";
import Iconify from "src/components/iconify";
import { LoadingButton } from "@mui/lab";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "react-phone-number-input/style.css";
import { WithAPICall } from "../utils/apiUtil";
import { Stack } from "@mui/system";
import ImageLoaderForGrid from "../utils/ImageLoaderForGrid";
import {
  isInFutureDateStrict,
  isInPastDateStrict,
  niceDate,
} from "../utils/fn";
import cogoToast from "cogo-toast";
import _ from "lodash";

class CompanyEmployees extends React.Component {
  state = {
    query: "",
    isSearching: true,
    quickSearchText: "",
    enableAdvancedFilter: false,
    hasLoadingStarted: false,
    list: [],
    columns: [],
    openEmployeeCreate: false,
    aggregations: {
      sum: (params) => this.sumAggregation(params),
      average: (params) => this.averageAggregation(params),
      max: (params) => this.maxAggregation(params),
      min: (params) => this.minAggregation(params),
      count: (params) => this.countAggregation(params),
    },
  };
  buffer = [];
  timer = null;
  componentDidMount() {
    if (this.props.insideProtocol) {
      this.setState({
        enableAdvancedFilter: true,
      });
    }
    this.getCSF();
  }
  getCSF = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost("/company/csf/get", {
        companyId: this.props.id,
      });
      this.setState(
        {
          csf: res,
        },
        () => {
          this.massageCSF();
        }
      );
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Employee Specific Fields");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  massageCSF = () => {
    let csf = this.state.csf;
    let headers = [];
    for (let i = 0; i < csf.length; i++) {
      let current = csf[i];
      let fdfs = current.fieldDataFields;
      let innerArray = [];
      for (let j = 0; j < fdfs.length; j++) {
        let filter;
        let cellDataType;
        let filterParams;
        let aggFunc;
        let fdf = fdfs[j];
        filter = "agMultiColumnFilter";
        let dt = fdf.dataType;

        switch (dt) {
          case "date":
            aggFunc = "count";
            filter = "agDateColumnFilter";
            cellDataType = "date";
            filterParams = {
              filterOptions: [
                "greaterThan",
                "greaterThanOrEqual",
                "lessThan",
                "lessThanOrEqual",
                "equals",
              ],
            };

            break;
          case "number":
            aggFunc = "sum";
            filter = "agNumberColumnFilter";
            cellDataType = "number";
            filterParams = {
              filterOptions: [
                "greaterThan",
                "greaterThanOrEqual",
                "lessThan",
                "lessThanOrEqual",
                "equals",
              ],
            };
            break;
          case "string":
            aggFunc = "count";
            filter = "agMultiColumnFilter";
            cellDataType = "text";
            filterParams = {
              filterOptions: ["equals", "notEqual"],
            };
            break;
          case "array":
            aggFunc = "count";
            filter = "agMultiColumnFilter";
            cellDataType = "text";
            filterParams = {
              filterOptions: ["equals", "notEqual"],
            };
            break;
          case "boolean":
            aggFunc = "count";
            filter = "agMultiColumnFilter";
            cellDataType = "boolean";
            filterParams = {
              filterOptions: ["true", "false"],
            };
            break;
          default:
            aggFunc = "count";
            filter = "agMultiColumnFilter";
            cellDataType = "text";
            filterParams = {
              filterOptions: ["equals", "notEqual"],
            };
            break;
        }

        let firstComponentOfID = current.needsLookUp
          ? "csfObject"
          : "esfObject";
        let secondComponentOfID = current.fieldName;
        let thirdComponentOfID = fdf.fieldDataFieldName;
        let colId = `${firstComponentOfID}${this.delimiter}${secondComponentOfID}${this.delimiter}${thirdComponentOfID}`;

        innerArray.push({
          headerName: fdf.fieldDataFieldName,
          colId: colId,
          enableRowGroup: true,
          enablePivot: true,
          filter: filter,
          aggFunc: aggFunc,
          cellDataType: cellDataType,
          filterParams: filterParams,
          valueGetter: (params) => {
            if (!params?.data) return null;
            let root = params?.data;
            let objectKey = current.needsLookUp ? "csfObject" : "esfObject";
            if (!root[objectKey]) return null;
            let object = root[objectKey];
            let ft = "fieldName";
            if (!object[current[ft]]) return null;
            let field = object[current[ft]];
            let dataType = fdf.dataType;
            if (dataType === "date") {
              if (!field[fdf.fieldDataFieldName]) return null;
              let date = new Date(field[fdf.fieldDataFieldName]);
              return date;
            }
            if (dataType === "boolean") {
              if (!field[fdf.fieldDataFieldName]) return false;
              return true;
            }
            if (!field[fdf.fieldDataFieldName]) return null;
            return field[fdf.fieldDataFieldName];
          },
          cellRenderer: (params) => {
            if (!params?.data) return null;
            let root = params?.data;
            let objectKey = current.needsLookUp ? "csfObject" : "esfObject";
            if (!root[objectKey]) return null;
            let object = root[objectKey];
            let ft = "fieldName";
            if (!object[current[ft]]) return null;
            let field = object[current[ft]];
            let dataType = fdf.dataType;
            let val = field[fdf.fieldDataFieldName];
            if (!val) return null;

            if (dataType === "boolean") {
              if (this.state.enableAdvancedFilter) {
                return val;
              }
              return val ? "Yes" : "No";
            }
            if (dataType === "date") {
              if (!val) {
                return null;
              }
              return niceDate(val);
            }
            return val;
          },
        });
      }
      headers.push({
        headerName: current.fieldName,
        children: innerArray,
      });
    }
    this.setState(
      {
        columns: [...this.COLUMNS, ...headers],
        isSearching: false,
        hasLoadingStarted: false,
      },
      () => {
        this.search();
      }
    );
  };
  search = async () => {
    cogoToast.loading("Loading data, this might take a while!");
    this.setState({
      list: [],
      hasLoadingStarted: false,
    });
    this.buffer = [];
    this.timer = setInterval(() => {
      let bufferLength = this.buffer.length;
      let listLength = this.state.list.length;
      let diff = bufferLength - listLength;

      if (diff > 0) {
        this.setState({
          list: [
            ...this.state.list,
            ...this.buffer.slice(listLength, bufferLength),
          ],
        });
      }
    }, 250);
    let url = "/employee/data/searchAugmented";
    const payload = {
      companyId: this.props.id,
      query: "",
    };
    if (
      this.props.protocol?.isPool &&
      this.props.protocol?.companyProtocolCriteriaType === "MANUAL"
    ) {
      url = "/employee/data/getByPoolAugmented";
      payload.poolId = this.props.protocol._id;
    }
    await this.props.apiCallPostStreaming(
      url,
      payload,
      (data) => {
        if (!this.state.hasLoadingStarted) {
          this.setState({ hasLoadingStarted: true });
        }
        this.buffer.push(...data);
      },
      () => {
        this.setState(
          { isSearching: false, hasLoadingStarted: false },

          async () => {
            // wait 4 seconds
            await new Promise((resolve) => setTimeout(resolve, 4000));
            this.setAdvancedFilter();
            clearInterval(this.timer);
          }
        );
      }
    );
  };
  renderHeader = () => {
    return (
      <Stack direction="row" spacing={3} alignItems="center">
        <TextField
          disabled={this.state.isSearching || this.state.hasLoadingStarted}
          value={this.state.quickSearchText}
          label="Quick Search"
          onChange={(e) =>
            this.setState({
              quickSearchText: e.target.value,
            })
          }
        />
        <Button
          disabled={this.state.isSearching || this.state.hasLoadingStarted}
          variant="contained"
          startIcon={<Iconify icon="mingcute:filter-line" />}
          onClick={() => {
            this.setState({
              enableAdvancedFilter: !this.state.enableAdvancedFilter,
            });
          }}
        >
          {this.state.enableAdvancedFilter
            ? "Advanced Filters"
            : "Simple Filters"}
        </Button>{" "}
        <LoadingButton
          loading={this.state.isSearching || this.state.hasLoadingStarted}
          onClick={() => this.search()}
          variant="contained"
          startIcon={<Iconify icon="mingcute:refresh-anticlockwise-1-line" />}
        >
          Refresh Data
        </LoadingButton>{" "}
        {this.renderLoadedSoFar()}
        {this.renderIfInProtocol()}
        {this.renderIfInCompany()}
      </Stack>
    );
  };
  renderIfInCompany = () => {
    if (this.props.insideProtocol) {
      return null;
    }
    return (
      <Button
        disabled={this.state.isSearching}
        onClick={() => this.setState({ openEmployeeCreate: true })}
        variant="contained"
        startIcon={<Iconify icon="mingcute:add-line" />}
      >
        New Employee
      </Button>
    );
  };
  renderLoadedSoFar = () => {
    if (!this.state.hasLoadingStarted) return null;
    return (
      <Chip
        icon={<Iconify icon="carbon:progress-ring" />}
        label={`Loaded ${this.state.list.length} records`}
        variant="outlined"
      />
    );
  };
  onGridReady = (params) => {
    this.setState({ gridApi: params.api, columnApi: params.columnApi });
  };
  setAdvancedFilter = () => {
    if (this.props.insideProtocol) {
      let fs = this.props.filterSpec;
      let isEmpty = _.isEmpty(fs);
      if (isEmpty) {
        return;
      } else {
        this.setState(
          {
            enableAdvancedFilter: true,
          },
          () => {
            this.state.gridApi.setAdvancedFilterModel(fs);
          }
        );
      }
    }
  };
  delimiter = "%";
  COLUMNS = [
    {
      headerName: "Basic Information",
      children: [
        {
          headerName: "Employee",
          field: "employeePreferredName",
          colId: `root${this.delimiter}employeePreferredName`,
          enableRowGroup: true,
          enablePivot: true,
          filter: "agMultiColumnFilter",
          cellDataType: "text",
          filterParams: {
            filterOptions: ["equals", "notEqual"],
          },
          cellRenderer: (row) => this.renderEmployeeName(row),
        },
        {
          headerName: "Legal Name",
          field: "employeeName",
          colId: `root${this.delimiter}employeeName`,
          enableRowGroup: true,
          enablePivot: true,
          filter: "agMultiColumnFilter",
          cellDataType: "text",
          filterParams: {
            filterOptions: ["equals", "notEqual"],
          },
        },
        {
          headerName: "Sex",
          field: "employeeSex",
          colId: `root${this.delimiter}employeeSex`,
          enableRowGroup: true,
          enablePivot: true,
          filter: "agMultiColumnFilter",
          cellDataType: "text",
          filterParams: {
            filterOptions: ["equals", "notEqual"],
          },
          aggFunc: "count",
        },
        {
          headerName: "DoB",
          field: "employeeDoB",
          colId: `root${this.delimiter}employeeDoB`,
          enableRowGroup: true,
          enablePivot: true,
          filter: "agDateColumnFilter",
          aggFunc: "count",
          cellDataType: "date",
          valueGetter: (params) => {
            if (!params?.data?.employeeDoB) return null;
            let dob = new Date(params.data.employeeDoB);
            return dob;
          },
          filterParams: {
            filterOptions: [
              "greaterThan",
              "greaterThanOrEqual",
              "lessThan",
              "lessThanOrEqual",
              "equals",
            ],
            comparator: function (filterDates, cellValue) {
              if (!cellValue) return -1;
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
          cellRenderer: (row) => this.renderDOBNicely(row),
        },
        {
          headerName: "Age",
          field: "employeeAge",
          colId: `root${this.delimiter}employeeAge`,
          enableRowGroup: true,
          enablePivot: true,
          filter: "agNumberColumnFilter",
          aggFunc: "average",
          cellDataType: "number",
          filterParams: {
            filterOptions: [
              "greaterThan",
              "greaterThanOrEqual",
              "lessThan",
              "lessThanOrEqual",
              "equals",
            ],
          },
        },
      ],
    },
    {
      headerName: "Contact Information",
      children: [
        {
          headerName: "Work Email",
          field: "employeeOfficeEmail",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          colId: `root${this.delimiter}employeeOfficeEmail`,
          cellDataType: "text",
          filterParams: {
            filterOptions: ["equals", "notEqual"],
          },
          cellRenderer: (row) => this.renderSendEmail(row),
        },
        {
          headerName: "Work Phone",
          field: "employeeOfficePhone",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          colId: `root${this.delimiter}employeeOfficePhone`,
          cellDataType: "text",
          filterParams: {
            filterOptions: ["equals", "notEqual"],
          },
          cellRenderer: (row) => this.renderPhone(row),
        },
        {
          headerName: "Country",
          field: "employeeCountry",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          aggFunc: "count",
          colId: `root${this.delimiter}employeeCountry`,
          cellDataType: "text",
          filterParams: {
            filterOptions: ["equals", "notEqual"],
          },
          cellRenderer: (row) => this.renderCountry(row),
        },
        {
          headerName: "State",
          field: "employeeState",
          filter: "agMultiColumnFilter",
          aggFunc: "count",
          enableRowGroup: true,
          enablePivot: true,
          colId: `root${this.delimiter}employeeState`,
          cellDataType: "text",
          filterParams: {
            filterOptions: ["equals", "notEqual"],
          },
        },
        {
          headerName: "City",
          field: "employeeCity",
          filter: "agMultiColumnFilter",
          aggFunc: "count",
          enableRowGroup: true,
          enablePivot: true,
          colId: `root${this.delimiter}employeeCity`,
          cellDataType: "text",
          filterParams: {
            filterOptions: ["equals", "notEqual"],
          },
        },
      ],
    },
    {
      headerName: "Employment Information",
      children: [
        {
          headerName: "Employee ID",
          field: "employeeNumber",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          colId: `root${this.delimiter}employeeNumber`,
          cellDataType: "text",
          filterParams: {
            filterOptions: ["equals", "notEqual"],
          },
        },
        {
          headerName: "Is Candidate?",
          field: "isCandidate",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          aggFunc: "count",
          colId: `root${this.delimiter}isCandidate`,
          cellDataType: "boolean",
          filterParams: {
            filterOptions: ["true", "false"],
          },
          cellRenderer: (row) => this.booleanGetter(row),
        },

        {
          headerName: "Applicant ID",
          field: "applicantId",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          colId: `root${this.delimiter}applicantId`,
          cellDataType: "text",
          filterParams: {
            filterOptions: ["equals", "notEqual"],
          },
        },
        {
          headerName: "DOT",
          field: "dotStatus",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          aggFunc: "count",
          colId: `root${this.delimiter}dotStatus`,
          cellDataType: "boolean",
          filterParams: {
            filterOptions: ["true", "false"],
          },
          cellRenderer: (row) => this.booleanGetter(row),
        },
        {
          headerName: "Employment Status",
          field: "employmentStatus",
          filter: "agMultiColumnFilter",
          aggFunc: "count",
          enableRowGroup: true,
          enablePivot: true,
          colId: `root${this.delimiter}employmentStatus`,
          cellDataType: "text",
          filterParams: {
            filterOptions: ["equals", "notEqual"],
          },
        },
        {
          headerName: "First Hired",
          field: "dateOfFirstHiring",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          cellDataType: "date",
          valueGetter: (params) => {
            if (!params?.data?.dateOfFirstHiring) return null;
            let d = new Date(params.data.dateOfFirstHiring);
            return d;
          },
          filterParams: {
            filterOptions: [
              "greaterThan",
              "greaterThanOrEqual",
              "lessThan",
              "lessThanOrEqual",
              "equals",
            ],
            comparator: function (filterDates, cellValue) {
              if (!cellValue) return -1;
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
          cellRenderer: (row) => this.renderDOBNicely(row),
        },
        {
          headerName: "Last Hired",
          field: "dateOfLastHiring",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          cellDataType: "date",
          valueGetter: (params) => {
            if (!params?.data?.dateOfLastHiring) return null;
            let d = new Date(params.data.dateOfLastHiring);
            return d;
          },
          filterParams: {
            filterOptions: [
              "greaterThan",
              "greaterThanOrEqual",
              "lessThan",
              "lessThanOrEqual",
              "equals",
            ],
            comparator: function (filterDates, cellValue) {
              if (!cellValue) return -1;
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
          cellRenderer: (row) => this.renderDOBNicely(row),
        },
        {
          headerName: "Last Terminated",
          field: "dateOfLastTermination",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          cellDataType: "date",
          valueGetter: (params) => {
            if (!params?.data?.dateOfLastTermination) return null;
            let d = new Date(params.data.dateOfLastTermination);
            return d;
          },
          filterParams: {
            filterOptions: [
              "greaterThan",
              "greaterThanOrEqual",
              "lessThan",
              "lessThanOrEqual",
              "equals",
            ],
            comparator: function (filterDates, cellValue) {
              if (!cellValue) return -1;
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
          cellRenderer: (row) => this.renderDOBNicely(row),
        },
      ],
    },
    {
      headerName: "Exposure Information",
      children: [
        {
          headerName: "Needs Respirator Fit Test",
          field: "needsRespiratorFitTest",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          aggFunc: "count",
          enablePivot: true,
          colId: `root${this.delimiter}needsRespiratorFitTest`,
          cellDataType: "boolean",
          filterParams: {
            filterOptions: ["true", "false"],
          },
          cellRenderer: (row) => this.booleanGetter(row),
        },
        {
          headerName: "Has Respirable Crystalline Silica Exposure",
          field: "hasRespirableCrystallineSilicaExposure",
          filter: "agMultiColumnFilter",
          aggFunc: "count",
          enableRowGroup: true,
          enablePivot: true,
          colId: `root${this.delimiter}hasRespirableCrystallineSilicaExposure`,
          cellDataType: "boolean",
          filterParams: {
            filterOptions: ["true", "false"],
          },
          cellRenderer: (row) => this.booleanGetter(row),
        },
        {
          headerName: "Has Methylene Chloride Exposure",
          field: "hasMethyleneChlorideExposure",
          filter: "agMultiColumnFilter",
          aggFunc: "count",
          enableRowGroup: true,
          enablePivot: true,
          colId: `root${this.delimiter}hasMethyleneChlorideExposure`,
          cellDataType: "boolean",
          filterParams: {
            filterOptions: ["true", "false"],
          },
          cellRenderer: (row) => this.booleanGetter(row),
        },
        {
          headerName: "Has Lead Exposure",
          field: "hasLeadExposure",
          filter: "agMultiColumnFilter",
          aggFunc: "count",
          enableRowGroup: true,
          enablePivot: true,
          colId: `root${this.delimiter}hasLeadExposure`,
          cellDataType: "boolean",
          filterParams: {
            filterOptions: ["true", "false"],
          },
          cellRenderer: (row) => this.booleanGetter(row),
        },
        {
          headerName: "Has Inorganic Arsenic Exposure",
          field: "hasInorganicArsenicExposure",
          aggFunc: "count",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          colId: `root${this.delimiter}hasInorganicArsenicExposure`,
          cellDataType: "boolean",
          filterParams: {
            filterOptions: ["true", "false"],
          },
          cellRenderer: (row) => this.booleanGetter(row),
        },
        {
          headerName: "Has Inorganic Arsenic Exposure",
          field: "arsenicExposureInitialDate",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          cellDataType: "date",
          valueGetter: (params) => {
            if (!params?.data?.arsenicExposureInitialDate) return null;
            let dob = new Date(params.data.arsenicExposureInitialDate);
            return dob;
          },
          filterParams: {
            filterOptions: [
              "greaterThan",
              "greaterThanOrEqual",
              "lessThan",
              "lessThanOrEqual",
              "equals",
            ],
            comparator: function (filterDates, cellValue) {
              if (!cellValue) return -1;
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
          cellRenderer: (row) => this.renderDOBNicely(row),
        },
        {
          headerName: "Has Hazwoper Exposure",
          field: "hasHazwoperExposure",
          filter: "agMultiColumnFilter",
          aggFunc: "count",
          enableRowGroup: true,
          enablePivot: true,
          colId: `root${this.delimiter}hasHazwoperExposure`,
          cellDataType: "boolean",
          filterParams: {
            filterOptions: ["true", "false"],
          },
          cellRenderer: (row) => this.booleanGetter(row),
        },
        {
          headerName: "Has Formaldehyde Exposure",
          field: "hasFormaldehydeExposure",
          filter: "agMultiColumnFilter",
          aggFunc: "count",
          enableRowGroup: true,
          enablePivot: true,
          colId: `root${this.delimiter}hasFormaldehydeExposure`,
          cellDataType: "boolean",
          filterParams: {
            filterOptions: ["true", "false"],
          },
          cellRenderer: (row) => this.booleanGetter(row),
        },
        {
          headerName: "Has Chromium (VI) Exposure",
          field: "hasChromiumVIHexavalentExposure",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          aggFunc: "count",
          enablePivot: true,
          colId: `root${this.delimiter}hasChromiumVIHexavalentExposure`,
          cellDataType: "boolean",
          filterParams: {
            filterOptions: ["true", "false"],
          },
          cellRenderer: (row) => this.booleanGetter(row),
        },
        {
          headerName: "Has Cadmium Exposure",
          field: "hasCadmiumExposure",
          filter: "agMultiColumnFilter",
          aggFunc: "count",
          enableRowGroup: true,
          enablePivot: true,
          colId: `root${this.delimiter}hasCadmiumExposure`,
          cellDataType: "boolean",
          filterParams: {
            filterOptions: ["true", "false"],
          },
          cellRenderer: (row) => this.booleanGetter(row),
        },
        {
          headerName: "Has Bloodborne Pathogen Exposure",
          field: "hasBloodbornePathogenExposure",
          filter: "agMultiColumnFilter",
          aggFunc: "count",
          enableRowGroup: true,
          enablePivot: true,
          colId: `root${this.delimiter}hasBloodbornePathogenExposure`,
          cellDataType: "boolean",
          filterParams: {
            filterOptions: ["true", "false"],
          },
          cellRenderer: (row) => this.booleanGetter(row),
        },
        {
          headerName: "Has Beryllium Exposure",
          field: "hasBerylliumExposure",
          filter: "agMultiColumnFilter",
          aggFunc: "count",
          enableRowGroup: true,
          enablePivot: true,
          colId: `root${this.delimiter}hasBerylliumExposure`,
          cellDataType: "boolean",
          filterParams: {
            filterOptions: ["true", "false"],
          },
          cellRenderer: (row) => this.booleanGetter(row),
        },
        {
          headerName: "Has Benzene Exposure",
          field: "hasBenzeneExposure",
          filter: "agMultiColumnFilter",
          aggFunc: "count",
          enableRowGroup: true,
          enablePivot: true,
          colId: `root${this.delimiter}hasBenzeneExposure`,
          cellDataType: "boolean",
          filterParams: {
            filterOptions: ["true", "false"],
          },
          cellRenderer: (row) => this.booleanGetter(row),
        },
        {
          headerName: "Has Asbestos Exposure",
          field: "hasAsbestosExposure",
          filter: "agMultiColumnFilter",
          aggFunc: "count",
          enableRowGroup: true,
          enablePivot: true,
          colId: `root${this.delimiter}hasAsbestosExposure`,
          cellRenderer: (row) => this.booleanGetter(row),
        },
        {
          headerName: "Initial Asbestos Exposure",
          field: "asbestosExposureInitialDate",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          cellDataType: "date",
          valueGetter: (params) => {
            if (!params?.data?.asbestosExposureInitialDate) return null;
            let dob = new Date(params.data.asbestosExposureInitialDate);
            return dob;
          },
          filterParams: {
            filterOptions: [
              "greaterThan",
              "greaterThanOrEqual",
              "lessThan",
              "lessThanOrEqual",
              "equals",
            ],
            comparator: function (filterDates, cellValue) {
              if (!cellValue) return -1;
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
          cellRenderer: (row) => this.renderDOBNicely(row),
        },
      ],
    },
  ];
  sumAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));

    return _.sum(arr);
  };
  averageAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.mean(arr);
  };
  maxAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.max(arr);
  };
  minAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.min(arr);
  };
  countAggregation = (params) => {
    return params.values.length;
  };
  booleanGetter = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    return (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        {row.value ? "Yes" : "No"}
      </span>
    );
  };
  sizeToFit = () => {
    const allColumnIds = [];
    this.state.gridApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    this.state.gridApi.autoSizeColumns(allColumnIds, false);
  };
  renderEmployeeName = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    return (
      <span
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ImageLoaderForGrid
          apiPrefix="employee"
          value={row.data.employeePhoto}
          onLoad={() => {
            this.sizeToFit();
          }}
        />
        <Button
          fullWidth
          href={`/employees/${row.data._id}`}
          variant="outlined"
          startIcon={<Iconify icon="mingcute:external-link-line" />}
        >
          {row.value || row.data.employeeName}
        </Button>
      </span>
    );
  };
  renderPhone = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    if (!row.value) return null;
    return (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          //   height: "82px",
          alignItems: "center",
        }}
      >
        <Button
          fullWidth
          target="non_blank noopener noreferrer"
          href={`tel:${row.value}`}
          variant="outlined"
          startIcon={<Iconify icon="mingcute:phone-outgoing-line" />}
        >
          {row.value}
        </Button>
      </span>
    );
  };
  renderDOBNicely = (row) => {
    if (this.state.enableAdvancedFilter) {
      if (!row.value) return "";
      return niceDate(row.value);
    }
    if (!row.value) return "Not Entered";
    return `${niceDate(row.value)}`;
  };
  renderSendEmail = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    if (!row.value) return null;
    return (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          //    height: "82px",
          alignItems: "center",
        }}
      >
        <Button
          fullWidth
          target="non_blank noopener noreferrer"
          href={`mailto:${row.value}`}
          variant="outlined"
          startIcon={<Iconify icon="mingcute:mail-send-line" />}
        >
          {row.value}
        </Button>
      </span>
    );
  };
  renderCountry = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    return (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Iconify
          icon={`circle-flags:${row?.data?.employeeCountryShortCode?.toLowerCase()}`}
          sx={{ mr: 1 }}
        />
        {row.value}
      </span>
    );
  };
  renderGrid = () => {
    if (!this.state.columns || this.state.columns.length === 0) {
      return <NoResultView />;
    }
    let cs = "ag-theme-quartz";
    let theme = localStorage.getItem("themeMode");
    if (theme && theme === "dark") {
      cs = "ag-theme-quartz-dark";
    }
    let initialState = {};
    if (this.props.insideProtocol && _.isEmpty(this.props.filterSpec)) {
      initialState = {
        filter: {
          advancedFilterModel: this.props.filterSpec,
        },
      };
    }

    return (
      <div className={cs} style={{ height: "70vh" }}>
        <AgGridReact
          onGridReady={this.onGridReady}
          rowData={this.state.list}
          columnDefs={this.state.columns}
          defaultColDef={{
            sortable: true,
          }}
          autoSizeStrategy={{
            type: "fitCellContents",
          }}
          pagination={true}
          rowGroupPanelShow={
            this.state.enableAdvancedFilter ? "always" : "never"
          }
          aggFuncs={this.state.aggregations}
          suppressDragLeaveHidesColumns={true}
          rowSelection="multiple"
          rowDragManaged={true}
          enableCharts={true}
          enableRangeSelection={true}
          enableCellTextSelection={true}
          quickFilterText={this.state.quickSearchText}
          sideBar={true}
          animateRows={true}
          initialState={initialState}
          enableAdvancedFilter={this.state.enableAdvancedFilter}
          statusBar={{
            statusPanels: [
              {
                statusPanel: "agTotalAndFilteredRowCountComponent",
                align: "left",
              },
              {
                statusPanel: "agSelectedRowCountComponent",
                align: "left",
              },
              {
                statusPanel: "agAggregationComponent",
                align: "right",
              },
            ],
          }}
          defaultExcelExportParams={{
            author: "WorkCare",
            fileName: `Employee Data`,
            sheetName: `Employee Data`,
          }}
        />
      </div>
    );
  };
  renderIfInProtocol = () => {
    if (!this.props.insideProtocol) {
      return null;
    }
    if (!this.state.gridApi) {
      return null;
    }
    let e = this.state.gridApi.getAdvancedFilterModel();
    // if (this.props.filterSpec && !_.isEmpty(this.props.filterSpec)) {
    //   return (
    //     <LoadingButton
    //       loading={this.props.isSaving}
    //       disabled={this.state.isSearching || this.state.hasLoadingStarted}
    //       onClick={() => {
    //         let e = this.state.gridApi.getAdvancedFilterModel();
    //         if (!e) {
    //           cogoToast.error("No filter criteria set!");
    //           return;
    //         }
    //         this.props.onSetFilterSpec(e);
    //       }}
    //       variant="contained"
    //       startIcon={<Iconify icon="mingcute:add-line" />}
    //     >
    //       Update Criteria
    //     </LoadingButton>
    //   );
    // }

    if (this.props.protocol.isPool) {
      return (
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          key={this.props.protocol.poolIsCanonicalPopulationFrozen}
        >
          {this.props.protocol.poolIsCanonicalPopulationFrozen ? (
            <LoadingButton
              loading={this.props.isSaving}
              disabled={this.state.isSearching || this.state.hasLoadingStarted}
              onClick={() => {
                // let e = this.state.gridApi.getAdvancedFilterModel();
                // if (!e) {
                //   cogoToast.error("No filter criteria set!");
                //   return;
                // }
                // this.props.onSetFilterSpec(e);
                this.props.onUnfreezePool();
              }}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              Unfreeze Pool Population
            </LoadingButton>
          ) : (
            this.props.protocol?.companyProtocolCriteriaType !== "MANUAL" && (
              <LoadingButton
                loading={this.props.isSaving}
                disabled={
                  this.state.isSearching || this.state.hasLoadingStarted
                }
                onClick={() => {
                  let e = this.state.gridApi.getAdvancedFilterModel();
                  if (!e) {
                    cogoToast.error("No filter criteria set!");
                    return;
                  }
                  this.props.onSetFilterSpec(e);
                }}
                variant="contained"
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                Set Criteria & Confirm Pool Population
              </LoadingButton>
            )
          )}
        </Stack>
      );
    } else {
      return (
        <Stack direction="row" spacing={2} alignItems="center">
          <LoadingButton
            loading={this.props.isSaving}
            disabled={this.state.isSearching || this.state.hasLoadingStarted}
            onClick={() => {
              let e = this.state.gridApi.getAdvancedFilterModel();
              if (!e) {
                cogoToast.error("No filter criteria set!");
                return;
              }
              this.props.onSetFilterSpec(e);
            }}
            variant="contained"
            startIcon={<Iconify icon="mingcute:add-line" />}
          >
            Set Criteria
          </LoadingButton>
        </Stack>
      );
    }
  };
  render() {
    if (this.state.isSearching) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }

    return (
      <div>
        <ContactCreate
          creatingWhat="Employee"
          entityType="Company"
          entityId={this.props.id}
          createApiUrl={"/employee/createFromName"}
          isOpen={this.state.openEmployeeCreate}
          handleClose={() => {
            this.setState({ openEmployeeCreate: false });
          }}
          onCreate={(contact) => {
            this.setState({
              list: [contact, ...this.state.list],
              openEmployeeCreate: false,
            });
            window.open(`/employees/${contact._id}`, "non_blank");
          }}
        />
        <Stack spacing={3}>
          {this.renderHeader()}
          {this.renderGrid()}
        </Stack>
      </div>
    );
  }
}

export default WithAPICall(CompanyEmployees);
