import "src/global.css";
import "src/locales/i18n";
import Router from "src/routes/sections";
import ThemeProvider from "src/theme";
import { LocalizationProvider } from "src/locales";
import ProgressBar from "src/components/progress-bar";
import { MotionLazy } from "src/components/animate/motion-lazy";
import SnackbarProvider from "src/components/snackbar/snackbar-provider";
import { SettingsDrawer, SettingsProvider } from "src/components/settings";
import { SignedIn, SignedOut, ClerkProvider } from "@clerk/clerk-react";
import DashboardLayout from "./layouts/dashboard";
import { TokenProvider } from "./TokenProvider";
import { LicenseManager } from "ag-grid-enterprise";
import LoginView from "./micro/utils/LoginView";
import ExtRouter from "./routes/sections/ext-index";

import { Worker } from "@react-pdf-viewer/core";

const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key for Clerk");
}
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY);

export default function App() {
  let defaultSettings = {
    themeMode: "light",
    themeDirection: "ltr",
    themeContrast: "default",
    themeLayout: "vertical",
    themeColorPresets: "default",
    themeStretch: true,
  };

  return (
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <SignedOut>
          <SettingsProvider defaultSettings={defaultSettings}>
            <ThemeProvider>
              <ExtRouter />
              {/*  <LoginView /> */}
            </ThemeProvider>
          </SettingsProvider>
        </SignedOut>
        <SignedIn>
          <TokenProvider>
            <LocalizationProvider>
              <SettingsProvider defaultSettings={defaultSettings}>
                <ThemeProvider>
                  <MotionLazy>
                    <SnackbarProvider>
                      <SettingsDrawer />
                      <ProgressBar />
                      <DashboardLayout>
                        <Router />
                      </DashboardLayout>
                    </SnackbarProvider>
                  </MotionLazy>
                </ThemeProvider>
              </SettingsProvider>
            </LocalizationProvider>
          </TokenProvider>
        </SignedIn>
      </Worker>
    </ClerkProvider>
  );
}
