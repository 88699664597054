import { useParams } from "src/routes/hooks";

import AuthorizationLetter from "./AuthorizationLetter";

export default function AuthorizationLetterById() {
  const params = useParams();
  const { id } = params;
  return (
    <>
      <AuthorizationLetter id={id} />
    </>
  );
}
