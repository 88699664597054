import React from "react";
import { apiCallPostUnuath } from "../utils/fn";
import { Alert, Button, Card, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import DocumentGroup from "../Documents/DocumentGroup";
import _ from "lodash";
import { niceDateUS } from "../utils/fn";
import { ParentDataRenderer } from "../DataFiles/DataRenderers/ParentDataRenderer";

class PatientLetter extends React.Component {
  state = {
    isLoading: true,
    visitId: null,
    data: null,
    groups: null,
    isError: false,
  };

  handlePrint = () => {
    const style = document.createElement("style");
    style.innerHTML = `
      @media print {
        body * {
          visibility: hidden;
        }
        #printableArea, #printableArea * {
          visibility: visible;
        }
        #printableArea {
          margin: 25px;
          padding: 25px;
          position: absolute;
          left: 0;
          top: 0;
          max-width:1000px
        }
      }
    `;
    document.head.appendChild(style);
  };
  componentDidMount() {
    this.handlePrint();
    this.getVisitData();
    this.searchGroups();
  }
  getVisitData = async () => {
    try {
      let res = await apiCallPostUnuath("/visit/getVisitByIDForPrint", {
        visitId: this.props.id,
      });
      this.setState({
        data: res,
        isLoading: false,
      });
    } catch (err) {
      this.setState({
        isError: true,
        isLoading: false,
      });
      console.log(err);
    }
  };

  searchGroups = async () => {
    try {
      let res = await apiCallPostUnuath("/visit/getMedicalRecords", {
        visitId: this.props.id,
      });
      this.setState({
        groups: res,
        isLoading: false,
      });
    } catch (err) {
      this.setState({
        isError: true,
        isLoading: false,
      });
      console.log(err);
    }
  };

  renderGroups = () => {
    if (!this.state.groups) return null;
    if (this.state.groups.length === 0) return null;
    return <ParentDataRenderer listOfData={this.state.groups} />;
  };

  render() {
    return (
      <Paper
        sx={{
          p: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <div id="printableArea">
          <Stack spacing={1}>
            <Box
              component="img"
              sx={{
                // height: 150,
                width: "auto",
                // maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
              }}
              alt="Company Logo"
              src="/logo/crop_logo_full.png"
            />
            <Typography variant="body2" paragraph>
              300 S. Harbor Blvd., Suite 600, Anaheim, CA 92805
              <br />
              Ph: (800) 455-6155 Fax: (714) 456 2154
            </Typography>

            <Typography variant="body1" paragraph>
              {this.state.data?.employee.employeeName}
              <br />
              {this.state.data?.employee.employeeAddress}
              <br />
              {this.state.data?.employee.employeeCity},{" "}
              {this.state.data?.employee.employeeState},{" "}
              {this.state.data?.employee.employeeZipCode}
            </Typography>

            <Typography variant="body1" paragraph>
              Dear {this.state.data?.employee.employeeName},
            </Typography>

            <Typography variant="body1" paragraph>
              I am writing with regards to your recent examination on{" "}
              {niceDateUS(this.state.data?.providerEncounterOnDate)} which was
              performed only to assess whether you have any medical
              conditions/issues that could interfere with your ability to
              perform work. It is not meant to be a substitute for regular
              medical care substitute for regular medical care or a complete
              medical examination.
            </Typography>

            <Typography variant="body1" paragraph>
              If you have any questions that were not addressed by the examining
              physician or have any further questions, please contact WorkCare
              at the number listed above.
            </Typography>

            <Typography variant="body1" paragraph>
              In addition to the results, the following are additional comments
              regarding your medical examination. You should share this
              information with your personal physician:
            </Typography>

            {/* <Typography variant="body1" paragraph>
            {clearance.clearanceNote}
          </Typography> */}

            <Typography variant="body1" paragraph>
              As a result of the above medical conditions, the following work
              status and restrictions are being recommended to your employer:
            </Typography>

            {/* <Typography variant="body1" paragraph>
            {clearance.clearanceLanguage}
          </Typography> */}

            {/* <Typography variant="body1" paragraph>
            I appreciate the opportunity to review your examination for{" "}
            {company.name}. If you have any questions now or in the future,
            please feel free to call me at (800) 455-6155.
          </Typography> */}

            <Typography variant="body1" paragraph>
              Sincerely,
            </Typography>

            <Typography variant="body1" paragraph>
              WorkCare MD
              <br />
              Dr. Jeffrey Jacobs
            </Typography>

            <Typography variant="body2" paragraph>
              If you would like further information on the laboratory tests and
              a general explanation of test results, please visit our web site
              at http://www.workcare.com. Click on "Knowledge Center/Resources"
              and use the "Health Fact Sheets" link to access this information.
            </Typography>
            {this.renderGroups()}
          </Stack>
        </div>
      </Paper>
    );
  }
}

export default PatientLetter;
