import React, { useState } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
  Box,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";
import { Stack } from "@mui/system";
import cogoToast from "cogo-toast";
import Iconify from "src/components/iconify";

export const MedicalQuestionnaireDataViewer = ({ data_, onUpdate }) => {
  const theme = useTheme();
  const [data, setData] = useState(data_);
  const [abnormalQuestions, setAbnormalQuestions] = useState(
    data.content.filter((item) => item.isAbnormal).map((item) => item.question)
  );
  const [hasChange, setHasChange] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const handleSave = () => {
    setIsSaving(true);
    onUpdate(data);
    setIsSaving(false);
    setHasChange(false);
  };
  let saveElem = null;
  if (hasChange && !isSaving) {
    saveElem = (
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="overline">Unsaved Changes</Typography>
        <Button
          variant="contained"
          onClick={handleSave}
          startIcon={<Iconify icon="bx:save" />}
        >
          Save
        </Button>
      </Stack>
    );
  }
  if (isSaving) {
    saveElem = (
      <Stack direction="row" spacing={2}>
        <Typography variant="overline">Saving...</Typography>
        <Iconify icon="pulse-3" />
      </Stack>
    );
  }

  const handleChange = (field, value) => {
    if (!onUpdate) {
      cogoToast.error("Updates are restricted.");
      return;
    }
    const updatedData = { ...data, [field]: value };
    setData(updatedData);
    setHasChange(true);
    setIsSaving(false);
  };
  const toggleAbnormal = (question) => {
    const newAbnormalQuestions = abnormalQuestions.includes(question)
      ? abnormalQuestions.filter((q) => q !== question)
      : [...abnormalQuestions, question];
    setAbnormalQuestions(newAbnormalQuestions);

    const newContent = data.content.map((item) =>
      item.question === question
        ? { ...item, isAbnormal: !item.isAbnormal }
        : item
    );
    setData({ ...data, content: newContent });
  };

  const isAbnormal = (question) => abnormalQuestions.includes(question);

  const getTooltipText = (question) => {
    return isAbnormal(question)
      ? "This answer is potentially abnormal. Click to mark it as normal."
      : "This answer is normal. Click to mark it as potentially abnormal.";
  };

  const handleDelete = (index) => {
    const newContent = data.content.filter((_, i) => i !== index);
    setData({ ...data, content: newContent });
    setHasChange(true);
  };

  const handleAdd = () => {
    const newContent = [
      ...data.content,
      { question: "", answer: "", isAbnormal: false },
    ];
    setData({ ...data, content: newContent });
    setHasChange(true);
  };

  const renderQuestionRows = () => {
    if (data.content.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={3} align="center">
            <Typography variant="body2" color="textSecondary">
              No questions added
            </Typography>
          </TableCell>
        </TableRow>
      );
    }

    return data.content.map((item, index) => (
      <TableRow
        key={index}
        sx={{
          bgcolor: isAbnormal(item.question)
            ? theme.palette.warning.light
            : "inherit",
        }}
      >
        <TableCell>
          <TextField
            fullWidth
            size="small"
            value={item.question}
            onChange={(e) => handleChange(index, "question", e.target.value)}
          />
        </TableCell>
        <TableCell>
          <TextField
            fullWidth
            size="small"
            value={item.answer}
            onChange={(e) => handleChange(index, "answer", e.target.value)}
            multiline
            rows={2}
          />
        </TableCell>
        <TableCell>
          <Box display="flex" alignItems="center">
            <Tooltip title={getTooltipText(item.question)} arrow>
              <IconButton
                size="small"
                onClick={() => toggleAbnormal(item.question)}
              >
                <Iconify
                  icon={
                    isAbnormal(item.question) ? "ep:warning-filled" : "mdi:tick"
                  }
                />
              </IconButton>
            </Tooltip>
            {onUpdate && (
              <Tooltip title="Remove question" arrow>
                <IconButton size="small" onClick={() => handleDelete(index)}>
                  <Iconify icon="hugeicons:delete-02" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </TableCell>
      </TableRow>
    ));
  };

  const renderHeader = () => {
    if (abnormalQuestions.length === 0) {
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">Questionnaire</Typography>
          {saveElem}
        </Box>
      );
    }

    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{
          breakAfter: "avoid",
        }}
      >
        <Box display="flex" alignItems="center">
          <Iconify
            icon="mdi:warning"
            sx={{ mr: 1, color: theme.palette.error.main }}
          />
          <Typography variant="subtitle1">
            Questionnaire | Red Flags: {abnormalQuestions.join(", ")}
          </Typography>
        </Box>
        {saveElem}
      </Box>
    );
  };

  return (
    <div
      style={{
        backgroundColor: hasChange ? theme.palette.warning.lighter : "inherit",
      }}
    >
      <Card
        sx={{
          p: 2,
          m: 1,
          bgcolor:
            abnormalQuestions.length > 0
              ? theme.palette.error.light
              : theme.palette.info.lighter,
        }}
      >
        {renderHeader()}
      </Card>
      <Stack spacing={2} direction="row" alignItems="center">
        <Typography variant="subtitle1">Questions</Typography>
        <Tooltip title="Add question" arrow>
          <IconButton onClick={handleAdd}>
            <Iconify icon="simple-line-icons:plus" />
          </IconButton>
        </Tooltip>
      </Stack>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell>Answer</TableCell>
              {onUpdate && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>{renderQuestionRows()}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
