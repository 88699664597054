import { Helmet } from "react-helmet-async";
import { useParams } from "src/routes/hooks";
import EmployeeDetails from "./EmployeeDetails";

export default function EmployeeByID() {
  const params = useParams();
  const { id } = params;

  return (
    <>
      <Helmet>
        <title>Employee Details</title>
      </Helmet>
      <EmployeeDetails id={id} />
    </>
  );
}
