import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {
  FormControlLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { UploadAvatar } from "src/components/upload";
import CountrySelector from "../utils/CountrySelector";
import { WithAPICall } from "../utils/apiUtil";
import GMap from "../utils/GMap";
import UploadSnack from "../utils/UploadSnack";
import cogoToast from "cogo-toast";

class ProviderNetworkProfile extends React.Component {
  state = {
    isLoading: true,
  };
  NETWORK_TYPE = ["Lab", "Clinic"];
  componentDidMount() {
    this.setState(
      {
        ...this.props.data,
        isLoading: false,
      },
      () => {
        if (this.state.networkLogo) {
          this.getFile(this.state.networkLogo);
        }
      }
    );
  }
  updateScalarValue = async (key, value) => {
    if (value === null || value === undefined) {
      return;
    }
    if (this.props.data[key] === value) {
      return;
    }
    try {
      await this.props.apiCallPost(
        "/provider/providerNetworkUpdateScalarValue",
        {
          networkId: this.props.id,
          key: key,
          value: value,
        }
      );
    } catch (err) {
      cogoToast.error("Error Updating Provider Data");
      console.log(err);
    }
  };
  setLogo = async (file) => {
    try {
      const fd = new FormData();
      fd.append("file", file[0]);
      fd.append("networkId", this.props.id);
      fd.append("purpose", "networkLogo");
      const res = await this.props.apiCallPostUpload(
        "/provider/providerNetworkSetLogo",
        fd,
        (e) => {
          this.setState({
            uploadProgress: e,
          });
        }
      );
      this.setState({
        uploadProgress: null,
        networkLogoUrl: res,
      });
    } catch (err) {
      cogoToast.error("Error Uploading Logo");
      console.log(err);
    }
  };
  getFile = async (id) => {
    try {
      const res = await this.props.apiCallPost("/provider/getFile", {
        fileId: id,
      });
      this.setState({
        networkLogoUrl: res.psUrl,
      });
    } catch (err) {
      console.log(err);
    }
  };
  setPlace = (place) => {
    this.setState(
      {
        networkCity: place.city,
        networkCountry: place.country,
        networkCountryCallingCode: place.country === "United States" ? "1" : "",
        networkCountryShortCode: place.country === "United States" ? "US" : "",
        networkState: place.state,
        networkAddress: place.formatted_address,
        networkGeoLat: place.geolocation.lat,
        networkGeoLong: place.geolocation.lng,
        networkPostalCode: place.postal_code,
      },
      () => {
        this.updateScalarValue("networkCity", place.city);
        this.updateScalarValue("networkCountry", place.country);
        this.updateScalarValue(
          "networkCountryCallingCode",
          place.country === "United States" ? "1" : ""
        );
        this.updateScalarValue(
          "networkCountryShortCode",
          place.country === "United States" ? "US" : ""
        );
        this.updateScalarValue("networkState", place.state);
        this.updateScalarValue("networkAddress", place.formatted_address);
        this.updateScalarValue("networkGeoLat", place.geolocation.lat);
        this.updateScalarValue("networkGeoLong", place.geolocation.lng);
        this.updateScalarValue("networkPostalCode", place.postal_code);
      }
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }

    return (
      <div>
        <Grid container spacing={3}>
          <Grid xs={12} md={4}>
            <Card sx={{ pt: 3, pb: 3, px: 3, textAlign: "center" }}>
              <Stack spacing={2}>
                <UploadSnack uploadProgress={this.state.uploadProgress} />
                <UploadAvatar
                  onDrop={(e) => this.setLogo(e)}
                  file={this.state.networkLogoUrl}
                />
                <TextField
                  label="Network Name"
                  value={this.state.networkName}
                  onChange={(e) => {
                    this.setState({ networkName: e.target.value });
                  }}
                  onBlur={() => {
                    this.updateScalarValue(
                      "networkName",
                      this.state.networkName
                    );
                    this.props.onUpdate("networkName", this.state.networkName);
                  }}
                />
                <TextField
                  label="Informal Name"
                  value={this.state.networkInformalName}
                  onChange={(e) => {
                    this.setState({ networkInformalName: e.target.value });
                  }}
                  onBlur={() => {
                    this.updateScalarValue(
                      "networkInformalName",
                      this.state.networkInformalName
                    );
                    this.props.onUpdate(
                      "networkInformalName",
                      this.state.networkInformalName
                    );
                  }}
                />
                <TextField
                  label="About"
                  multiline
                  value={this.state.networkAbout}
                  onChange={(e) => {
                    this.setState({ networkAbout: e.target.value });
                  }}
                  onBlur={() => {
                    this.updateScalarValue(
                      "networkAbout",
                      this.state.networkAbout
                    );
                  }}
                />
                <FormControlLabel
                  label={"Type of Network"}
                  labelPlacement="start"
                  control={
                    <Select
                      sx={{ minWidth: 200 }}
                      value={this.state.networkProviderType}
                      onChange={(e) => {
                        this.setState(
                          {
                            networkProviderType: e.target.value,
                          },
                          () => {
                            this.updateScalarValue(
                              "networkProviderType",
                              this.state.networkProviderType
                            );
                            this.props.onUpdate(
                              "networkProviderType",
                              this.state.networkProviderType
                            );
                          }
                        );
                      }}
                    >
                      {this.NETWORK_TYPE.map((each) => (
                        <MenuItem value={each}>{each}</MenuItem>
                      ))}
                    </Select>
                  }
                  sx={{
                    m: 0,
                    width: 1,
                    justifyContent: "space-between",
                  }}
                />
              </Stack>
            </Card>
          </Grid>
          <Grid xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={2}>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  }}
                >
                  <PhoneInput
                    defaultCountry="US"
                    placeholder="Enter phone number"
                    value={this.state.networkPhone}
                    onChange={(e) => {
                      this.setState({ networkPhone: e });
                    }}
                    onBlur={() => {
                      this.updateScalarValue(
                        "networkPhone",
                        this.state.networkPhone
                      );
                    }}
                  />
                  <PhoneInput
                    defaultCountry="US"
                    placeholder="Enter fax number"
                    value={this.state.networkFax}
                    onChange={(e) => {
                      this.setState({ networkFax: e });
                    }}
                    onBlur={() => {
                      this.updateScalarValue(
                        "networkFax",
                        this.state.networkFax
                      );
                    }}
                  />
                  <TextField
                    label="Website"
                    value={this.state.networkWebsite}
                    onChange={(e) => {
                      this.setState({ networkWebsite: e.target.value });
                    }}
                    onBlur={() => {
                      let ws = this.state.networkWebsite;
                      if (
                        ws.startsWith("http://") ||
                        ws.startsWith("https://")
                      ) {
                        // Do nothing
                      } else {
                        ws = "https://" + ws;
                      }
                      if (this.state.networkWebsite) {
                        this.updateScalarValue("networkWebsite", ws);
                        this.setState({ networkWebsite: ws });
                      }
                    }}
                  />
                  <TextField
                    label="Email"
                    value={this.state.networkEmail}
                    onChange={(e) => {
                      this.setState({ networkEmail: e.target.value });
                    }}
                    onBlur={() => {
                      if (this.state.networkEmail) {
                        this.updateScalarValue(
                          "networkEmail",
                          this.state.networkEmail
                        );
                      }
                    }}
                  />
                </Box>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  }}
                ></Box>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  }}
                >
                  <Box
                    rowGap={3}
                    columnGap={2}
                    display="grid"
                    gridTemplateColumns={{
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(1, 1fr)",
                    }}
                  >
                    <GMap
                      onPlace={(r) => this.setPlace(r)}
                      geoObject={{
                        lat: this.state.networkGeoLat,
                        lng: this.state.networkGeoLong,
                      }}
                      onLatLng={(geo) => {
                        this.setState({
                          networkGeoLat: geo.lat,
                          networkGeoLong: geo.lng,
                        });
                        this.updateScalarValue("networkGeoLat", geo.lat);
                        this.updateScalarValue("networkGeoLong", geo.lng);
                      }}
                    />
                  </Box>
                  <Box
                    rowGap={1}
                    columnGap={1}
                    display="grid"
                    gridTemplateColumns={{
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(1, 1fr)",
                    }}
                  >
                    <CountrySelector
                      value={{
                        label: this.state.networkCountry || "",
                        code: this.state.networkCountryShortCode || "",
                        phone: this.state.networkCountryCallingCode || "",
                      }}
                      onChange={(e) => {
                        this.setState({
                          country: e,
                          networkCountry: e.label,
                          networkCountryShortCode: e.code,
                          networkCountryCallingCode: e.phone,
                        });
                        this.updateScalarValue("networkCountry", e.label);
                        this.updateScalarValue(
                          "networkCountryShortCode",
                          e.code
                        );
                        this.updateScalarValue(
                          "networkCountryCallingCode",
                          e.phone
                        );
                      }}
                    />

                    <TextField
                      InputLabelProps={{ shrink: this.state.networkAddress }}
                      label="Address Line 1"
                      multiline
                      value={this.state.networkAddress}
                      onChange={(e) => {
                        this.setState({
                          networkAddress: e.target.value,
                        });
                      }}
                      onBlur={() => {
                        this.updateScalarValue(
                          "networkAddress",
                          this.state.networkAddress
                        );
                      }}
                    />
                    <TextField
                      InputLabelProps={{
                        shrink: this.state.networkAddressQualilfier,
                      }}
                      label="Address Line 2"
                      multiline
                      value={this.state.networkAddressQualilfier}
                      onChange={(e) => {
                        this.setState({
                          networkAddressQualilfier: e.target.value,
                        });
                      }}
                      onBlur={() => {
                        this.updateScalarValue(
                          "networkAddressQualilfier",
                          this.state.networkAddressQualilfier
                        );
                      }}
                    />
                    <TextField
                      InputLabelProps={{
                        shrink: this.state.networkCity,
                      }}
                      label="City"
                      value={this.state.networkCity}
                      onChange={(e) => {
                        this.setState({ networkCity: e.target.value });
                      }}
                      onBlur={() => {
                        this.updateScalarValue(
                          "networkCity",
                          this.state.networkCity
                        );
                      }}
                    />
                    <TextField
                      InputLabelProps={{
                        shrink: this.state.networkState,
                      }}
                      label="State"
                      value={this.state.networkState}
                      onChange={(e) => {
                        this.setState({ networkState: e.target.value });
                      }}
                      onBlur={() => {
                        this.updateScalarValue(
                          "networkState",
                          this.state.networkState
                        );
                      }}
                    />
                    <TextField
                      InputLabelProps={{
                        shrink: this.state.networkPostalCode,
                      }}
                      label="Postal Code"
                      value={this.state.networkPostalCode}
                      onChange={(e) => {
                        this.setState({ networkPostalCode: e.target.value });
                      }}
                      onBlur={() => {
                        this.updateScalarValue(
                          "networkPostalCode",
                          this.state.networkPostalCode
                        );
                      }}
                    />
                  </Box>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default WithAPICall(ProviderNetworkProfile);
