import React from "react";
import { Button, LinearProgress, TextField } from "@mui/material";
import { NoResultView, View500 } from "src/sections/error";
import ContactCreate from "../utils/ContactCreate";
import Iconify from "src/components/iconify";
import { LoadingButton } from "@mui/lab";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { WithAPICall } from "../utils/apiUtil";
import { Stack } from "@mui/system";
const filter = createFilterOptions();

class EmployeeContacts extends React.Component {
  state = {
    query: "",
    isSearching: true,
    quickSearchText: "",
    enableAdvancedFilter: false,
    list: [],
    openContactCreate: false,
  };
  CONTACT_TYPES = [
    "Primary Contact",
    "Invoicing Contact",
    "Administrative Contact",
    "HR Contact",
    "Supervisor Contact",
    "Manager Contact",
    "DER Contact",
  ];
  componentDidMount() {
    this.search();
  }
  search = async () => {
    this.setState({ isSearching: true });
    try {
      const res = await this.props.apiCallPost("/contacts/getByEntityId", {
        entityType: "Employee",
        entityId: this.props.id,
      });
      this.setState({ list: res, isSearching: false });
    } catch (err) {
      this.setState({
        isSearching: false,
        isError: true,
      });
      console.log(err);
    }
    this.setState({ isSearching: false });
  };
  updateRow = async (rowIndex) => {
    try {
      let row = this.state.list[rowIndex];
      await this.props.apiCallPost("/contacts/editContact", {
        ...row,
        contactId: row._id,
        entityId: this.props.id,
      });
    } catch (err) {
      console.log(err);
    }
  };
  renderHeader = () => {
    return (
      <Stack direction="row" spacing={3}>
        <TextField
          disabled={this.state.isSearching}
          value={this.state.quickSearchText}
          label="Quick Search"
          onChange={(e) =>
            this.setState({
              quickSearchText: e.target.value,
            })
          }
        />
        <Button
          disabled={this.state.isSearching}
          variant="contained"
          startIcon={<Iconify icon="mingcute:filter-line" />}
          onClick={() => {
            this.setState({
              enableAdvancedFilter: !this.state.enableAdvancedFilter,
            });
          }}
        >
          {this.state.enableAdvancedFilter
            ? "Advanced Filters"
            : "Simple Filters"}
        </Button>{" "}
        <LoadingButton
          loading={this.state.isSearching}
          onClick={() => this.search()}
          variant="contained"
          startIcon={<Iconify icon="mingcute:refresh-anticlockwise-1-line" />}
        >
          Refresh Data
        </LoadingButton>
        <Button
          disabled={this.state.isSearching}
          onClick={() => this.setState({ openContactCreate: true })}
          variant="contained"
          startIcon={<Iconify icon="mingcute:add-line" />}
        >
          New Contact
        </Button>
      </Stack>
    );
  };
  onGridReady = (params) => {
    this.setState({ gridApi: params.api, columnApi: params.columnApi });
  };
  COLUMNS = [
    {
      headerName: "Name",
      field: "contactName",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
      cellRenderer: (row) => this.renderTextField(row, "contactName"),
    },
    {
      headerName: "Email",
      field: "emailAddress",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
      cellRenderer: (row) => this.renderTextField(row, "emailAddress"),
    },
    {
      headerName: "Phone (Office)",
      field: "officePhone",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.renderPhoneInput(row, "officePhone"),
    },
    {
      headerName: "Phone (Cell)",
      field: "cellPhone",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.renderPhoneInput(row, "cellPhone"),
    },
    {
      headerName: "Fax",
      field: "fax",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.renderPhoneInput(row, "fax"),
    },
    {
      headerName: "Type",
      field: "contactType",
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.renderContactTypeSelector(row, "contactType"),
    },
    {
      headerName: "Notes",
      field: "notes",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (row) => this.renderTextField(row, "notes"),
    },
  ];
  renderTextField = (row, field) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "80px",
          alignItems: "center",
        }}
      >
        <TextField
          fullWidth
          variant="standard"
          value={row.data[field]}
          onChange={(e) => {
            let list = this.state.list;
            list[row.rowIndex][field] = e.target.value;
            this.setState({ list });
          }}
          onBlur={() => {
            this.updateRow(row.rowIndex);
          }}
        />
      </div>
    );
  };
  renderPhoneInput = (row, field) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    return (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          height: "80px",
          alignItems: "center",
        }}
      >
        <PhoneInput
          defaultCountry="US"
          placeholder="Enter phone number"
          value={row.data[field]}
          onChange={(e) => {
            let list = this.state.list;
            list[row.rowIndex][field] = e;
            this.setState({ list });
          }}
          onBlur={() => {
            this.updateRow(row.rowIndex);
          }}
        />
      </span>
    );
  };
  renderContactTypeSelector = (row, field) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    return (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          height: "80px",
          alignItems: "center",
        }}
      >
        <Autocomplete
          disableClearable
          value={{ label: row.data[field] }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={this.CONTACT_TYPES.map((each, index) => {
            return { label: each, id: index };
          })}
          onChange={(_, newValue) => {
            if (typeof newValue === "string") {
              let list = this.state.list;
              list[row.rowIndex][field] = newValue;
              this.setState({ list }, () => {
                this.updateRow(row.rowIndex);
              });
            } else if (newValue && newValue.inputValue) {
              let list = this.state.list;
              list[row.rowIndex][field] = newValue.inputValue;
              this.setState({ list }, () => {
                this.updateRow(row.rowIndex);
              });
            } else {
              let list = this.state.list;
              list[row.rowIndex][field] = newValue.label;
              this.setState({ list }, () => {
                this.updateRow(row.rowIndex);
              });
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            const isExisting = options.some(
              (option) => inputValue === option.label
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                label: `Add "${inputValue}"`,
              });
            }
            return filtered;
          }}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.label;
          }}
          renderOption={(props, option) => <li {...props}>{option.label}</li>}
          sx={{ width: 300 }}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} label="Type of Contact" />
          )}
        />
      </span>
    );
  };
  renderGrid = () => {
    let list = this.state.list;
    if (!list || list.length === 0) {
      return <NoResultView />;
    }
    let cs = "ag-theme-quartz";
    let theme = localStorage.getItem("themeMode");
    if (theme && theme === "dark") {
      cs = "ag-theme-quartz-dark";
    }
    return (
      <div className={cs} style={{ height: "70vh" }}>
        <AgGridReact
          rowHeight={!this.state.enableAdvancedFilter ? 82 : undefined}
          onGridReady={this.onGridReady}
          rowData={this.state.list}
          columnDefs={this.COLUMNS}
          defaultColDef={{
            sortable: true,
          }}
          autoSizeStrategy={{
            type: "fitCellContents",
          }}
          pagination={true}
          rowGroupPanelShow={
            this.state.enableAdvancedFilter ? "always" : "never"
          }
          suppressDragLeaveHidesColumns={true}
          rowSelection="multiple"
          rowDragManaged={true}
          enableCharts={true}
          enableRangeSelection={true}
          enableCellTextSelection={true}
          quickFilterText={this.state.quickSearchText}
          sideBar={true}
          animateRows={true}
          enableAdvancedFilter={this.state.enableAdvancedFilter}
        />
      </div>
    );
  };
  render() {
    if (this.state.isSearching) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }

    return (
      <div>
        <ContactCreate
          entityType="Employee"
          entityId={this.props.id}
          createApiUrl={"/contacts/addToEntity"}
          isOpen={this.state.openContactCreate}
          handleClose={() => {
            this.setState({ openContactCreate: false });
          }}
          onCreate={(contact) => {
            this.setState({
              list: [contact, ...this.state.list],
              openContactCreate: false,
            });
          }}
        />
        <Stack spacing={3}>
          {this.renderHeader()}

          {this.renderGrid()}
        </Stack>
      </div>
    );
  }
}

export default WithAPICall(EmployeeContacts);
