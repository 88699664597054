import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
// import EditIcon from '@mui/icons-material/Edit';
import {
  Edit as EditIcon,
  PlusCircle as AddIcon,
  Trash2 as DeleteIcon,
} from "lucide-react";
import { WithAPICall } from "../utils/apiUtil";
import { nanoid } from "nanoid";
// interface EmailTemplate {
//   id: number;
//   scenario: string;
//   subject: string;
//   body: string;
// }

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const ModalContent = styled(Box)({
  backgroundColor: "white",
  borderRadius: "8px",
  padding: "32px",
  width: "80%",
  maxWidth: "800px",
  maxHeight: "90vh",
  overflow: "auto",
});

const EmailTemplatesEditor = ({ templates, onSave, onAdd, onDelete }) => {
  const [open, setOpen] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState(null);

  const handleOpen = (template) => {
    setEditingTemplate(
      template || { _id: 0, scenario: "", subject: "", body: "" }
    );
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingTemplate(null);
  };

  const handleSave = () => {
    if (editingTemplate) {
      if (editingTemplate._id === 0) {
        onAdd(editingTemplate);
      } else {
        onSave(editingTemplate);
      }
      handleClose();
    }
  };

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpen(null)}
          sx={{
            backgroundColor: "#4caf50",
            "&:hover": { backgroundColor: "#45a049" },
          }}
        >
          Add New Template
        </Button>
      </Box>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: 440, boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
              >
                Scenario
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
              >
                Subject
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {templates.map((template) => (
              <TableRow key={template._id} hover>
                <TableCell>{template.scenario}</TableCell>
                <TableCell>{template.subject}</TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton
                      onClick={() => handleOpen(template)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => onDelete(template._id)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <StyledModal open={open} onClose={handleClose}>
        <ModalContent>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontWeight: "bold", color: "#333" }}
          >
            {editingTemplate && editingTemplate._id !== 0
              ? "Edit Email Template"
              : "Add New Email Template"}
          </Typography>
          {editingTemplate && (
            <Box
              component="form"
              sx={{ display: "flex", flexDirection: "column", gap: 3 }}
            >
              <TextField
                label="Scenario"
                value={editingTemplate.scenario}
                onChange={(e) =>
                  setEditingTemplate({
                    ...editingTemplate,
                    scenario: e.target.value,
                  })
                }
                fullWidth
              />
              <TextField
                label="Subject"
                value={editingTemplate.subject}
                onChange={(e) =>
                  setEditingTemplate({
                    ...editingTemplate,
                    subject: e.target.value,
                  })
                }
                fullWidth
              />
              <TextField
                label="Body"
                value={editingTemplate.body}
                onChange={(e) =>
                  setEditingTemplate({
                    ...editingTemplate,
                    body: e.target.value,
                  })
                }
                multiline
                rows={6}
                fullWidth
              />
              <Button
                variant="contained"
                onClick={handleSave}
                sx={{
                  alignSelf: "flex-end",
                  backgroundColor: "#4caf50",
                  "&:hover": { backgroundColor: "#45a049" },
                }}
              >
                {editingTemplate._id !== 0 ? "Save Changes" : "Add Template"}
              </Button>
            </Box>
          )}
        </ModalContent>
      </StyledModal>
    </Box>
  );
};

// Dummy parent component for demonstration
const DummyParent = ({ id, data, onUpdate, apiCallPost }) => {
  const [templates, setTemplates] = useState(data.emailTemplates || []);

  const handleSave = async (updatedTemplate) => {
    setTemplates(
      templates.map((t) =>
        t._id === updatedTemplate._id ? updatedTemplate : t
      )
    );
  };

  const onAdd = (t) => {
    setTemplates([...templates, t]);
  };

  const onDelete = (_id) => {
    setTemplates(templates.filter((t) => t._id !== _id));
  };

  const writeBack = async () => {
    await apiCallPost("/company/updateScalarValue", {
      companyId: id,
      key: "emailTemplates",
      value: templates,
    });
    onUpdate("emailTemplates", templates);
  };

  useEffect(() => {
    // In a real application, you would call an API to persist the changes here
    writeBack();
  }, [templates]);

  return (
    <EmailTemplatesEditor
      templates={templates}
      onSave={handleSave}
      onAdd={onAdd}
      onDelete={onDelete}
    />
  );
};

export default WithAPICall(DummyParent);

// const CompanyEmailTemplates = ({ companyId, data, onUpdate, apiCallPost }) => {
//   const [rows, setRows] = useState(data.addOnFees || []);
//   const [openSnackbar, setOpenSnackbar] = useState(false);

//   const addRow = () => {
//     // Check if there's any row with an empty feeType
//     const hasEmptyFeeType = rows.some((row) => row.feeType === "");
//     if (hasEmptyFeeType) {
//       setOpenSnackbar(true);
//       return;
//     }
//     setRows([...rows, { addOnFeeType: "", addOnFeeUSD: "" }]);
//   };

//   const saveFees = async () => {
//     try {
//       cogoToast.loading("Updating Protocol Data...");
//       const res = await apiCallPost("/company/updateScalarValue", {
//         companyId: companyId,
//         key: "addOnFees",
//         value: rows.filter(
//           (row) => row.addOnFeeType !== "" && row.addOnFeeUSD > 0
//         ),
//       });
//       await onUpdate("addOnFees", res.addOnFees);
//       setOpenSnackbar(false);
//     } catch (err) {
//       console.log(err);
//       cogoToast.error("Error Updating Company Data");
//     }
//   };

//   const handleFeeTypeChange = (index, value) => {
//     // Prevent adding a fee type that already exists
//     if (rows.some((row) => row.addOnFeeType === value)) {
//       setOpenSnackbar(true);
//       return;
//     }
//     const newRows = [...rows];
//     newRows[index].addOnFeeType = value;
//     setRows(newRows);
//   };

//   const handleValueChange = (index, value) => {
//     const newRows = [...rows];
//     newRows[index].addOnFeeUSD = parseFloat(value);
//     setRows(newRows);
//   };

//   const handleCloseSnackbar = () => {
//     setOpenSnackbar(false);
//   };

//   return (
//     <div className="p-4">
//       <Snackbar
//         open={openSnackbar}
//         autoHideDuration={6000}
//         onClose={handleCloseSnackbar}
//         message="Cannot add duplicate or empty Fee Type"
//       />
//       <TableContainer component={Paper} className="mb-4">
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>Fee Type</TableCell>
//               <TableCell>Value</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {rows.map((row, index) => (
//               <TableRow key={index}>
//                 <TableCell>
//                   <Select
//                     value={row.addOnFeeType}
//                     onChange={(e) => handleFeeTypeChange(index, e.target.value)}
//                     fullWidth
//                   >
//                     {COMPANY_ADDON_FEE_TYPES.map((f) => (
//                       <MenuItem key={f.key} value={f.value}>
//                         {f.key}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </TableCell>
//                 <TableCell>
//                   <TextField
//                     value={row.addOnFeeUSD}
//                     onChange={(e) => handleValueChange(index, e.target.value)}
//                     fullWidth
//                   />
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <Grid container spacing={2} className="mt-4">
//         <Grid item>
//           <Button variant="contained" size="small" onClick={addRow}>
//             Add Row
//           </Button>
//         </Grid>
//         <Grid item>
//           <Button variant="contained" size="small" onClick={saveFees}>
//             Save
//           </Button>
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

// export default WithAPICall(CompanyAddonsFees);
