// 'use client';
import { Typography } from "@mui/material";
import { BarList, LineChart } from "@tremor/react";

const country = [
  {
    name: "Halliburton",
    value: 2422,
  },
  {
    name: "Verizon",
    value: 1560,
  },
  {
    name: "Acme",
    value: 680,
  },
  {
    name: "US Army",
    value: 580,
  },
];

const data = [
  {
    date: "Jan 23",
    Halliburton: 232,
    Verizon: 0,
    Acme: 49,
  },
  {
    date: "Feb 23",
    Halliburton: 241,
    Verizon: 0,
    Acme: 61,
  },
  {
    date: "Mar 23",
    Halliburton: 291,
    Verizon: 0,
    Acme: 55,
  },
  {
    date: "Apr 23",
    Halliburton: 101,
    Verizon: 0,
    Acme: 21,
  },
  {
    date: "May 23",
    Halliburton: 318,
    Verizon: 0,
    Acme: 66,
  },
  {
    date: "Jun 23",
    Halliburton: 205,
    Verizon: 0,
    Acme: 69,
  },
  {
    date: "Jul 23",
    Halliburton: 372,
    Verizon: 0,
    Acme: 55,
  },
  {
    date: "Aug 23",
    Halliburton: 341,
    Verizon: 0,
    Acme: 74,
  },
  {
    date: "Sep 23",
    Halliburton: 387,
    Verizon: 120,
    Acme: 190,
  },
  {
    date: "Oct 23",
    Halliburton: 220,
    Verizon: 0,
    Acme: 89,
  },
  {
    date: "Nov 23",
    Halliburton: 372,
    Verizon: 0,
    Acme: 44,
  },
  {
    date: "Dec 23",
    Halliburton: 321,
    Verizon: 0,
    Acme: 93,
  },
];

const valueFormatter = (number) =>
  `${Intl.NumberFormat("us").format(number).toString()}`;

export default function CompanyVisits() {
  return (
    <>
      <LineChart
        data={[]}
        index="date"
        categories={["Halliburton", "Verizon", "Acme"]}
        colors={["blue", "violet", "fuchsia"]}
        valueFormatter={valueFormatter}
        showLegend={false}
        showYAxis={false}
        startEndOnly={true}
        className="mt-6 h-32"
      />
      {/* <Typography variant="subtitle2" sx={{ mb: 1 }}>
        Top 4 companies
      </Typography>
      <BarList data={country} valueFormatter={valueFormatter} /> */}
    </>
  );
}
