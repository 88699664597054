import React from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Iconify from "src/components/iconify";
import { WithAPICall } from "../utils/apiUtil";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import { Box } from "@mui/system";
import { Button, LinearProgress } from "@mui/material";
import cogoToast from "cogo-toast";
import { View500 } from "src/sections/error";
import EmployeeProfile from "./EmployeeProfile";
import EmployeeSettings from "./EmployeeSettings";
import EmployeeNotes from "./EmployeeNotes";
import EmployeeContacts from "./EmployeeContacts";
import EmployeeSpecificFields from "./EmployeeSpecificFields";
import EmployeeTransfer from "./EmployeeTransfer";
import EmployeeVisits from "./EmployeeVisits";
import { addUrlParams, getUrlParams } from "src/utils/url-params";
import EmployeeData from "./EmployeeData";
import EmployeeProtocols from "./EmployeeProtocols";

class EmployeeDetails extends React.Component {
  state = {
    currentTab: "INFO",
    employeeData: null,
    companyData: null,
    employeeNotes: [],
    isLoading: false,
    isError: false,
  };
  async componentDidMount() {
    await this.getById();
    const urlParams = getUrlParams();
    if (urlParams.currentTab) {
      this.setState({ currentTab: urlParams.currentTab });
    }
  }
  getById = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost("/employee/getById", {
        _id: this.props.id,
      });
      this.setState({ employeeData: res }, () => {
        this.getCompanyDataByEmployeeId();
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Employee Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  getCompanyDataByEmployeeId = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost("/company/getById", {
        _id: this.state.employeeData.companyId,
      });
      this.setState({ companyData: res, isLoading: false });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Employer Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  tabs = [
    {
      value: "INFO",
      label: "Info",
      icon: <Iconify icon="solar:info-circle-broken" width={24} />,
    },
    {
      value: "SETTINGS",
      label: "General Information",
      icon: <Iconify icon="solar:settings-broken" width={24} />,
    },

    {
      value: "SPECIFIC_FIELDS",
      label: "Employer Specific Fields",
      icon: <Iconify icon="solar:text-field-focus-outline" width={24} />,
    },

    {
      value: "NOTES",
      label: "Notes & Documents",
      icon: <Iconify icon="solar:document-add-linear" width={24} />,
    },
    {
      value: "CONTACTS",
      label: "Contacts",
      icon: <Iconify icon="solar:user-circle-broken" width={24} />,
    },
    {
      value: "TRANSFER",
      label: "Transfer",
      icon: <Iconify icon="solar:transfer-horizontal-broken" width={24} />,
    },
    {
      value: "EE_PROTOCOLS",
      label: "Medical Surveillance",
      icon: <Iconify icon="solar:database-broken" width={24} />,
    },
    {
      value: "DATA",
      label: "Medical Records",
      icon: <Iconify icon="solar:database-broken" width={24} />,
    },
    {
      value: "VISITS",
      label: "All Visits",
      icon: (
        <Iconify icon="material-symbols:nest-doorbell-visitor" width={24} />
      ),
    },
  ];
  renderTabs = () => {
    return (
      <Tabs
        value={this.state.currentTab}
        onChange={(_, value) => {
          addUrlParams({ currentTab: value });
          this.setState({ currentTab: value });
        }}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        {this.tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            icon={tab.icon}
            value={tab.value}
          />
        ))}
      </Tabs>
    );
  };
  renderTab = () => {
    switch (this.state.currentTab) {
      case "INFO":
        return (
          <EmployeeProfile
            id={this.props.id}
            data={this.state.employeeData}
            onUpdate={(key, value) => {
              let employeeData = this.state.employeeData;
              employeeData[key] = value;
              this.setState({ employeeData });
            }}
          />
        );
      case "SETTINGS":
        return (
          <EmployeeSettings
            id={this.props.id}
            data={this.state.employeeData}
            onUpdate={(key, value) => {
              let employeeData = this.state.employeeData;
              employeeData[key] = value;
              this.setState({ employeeData });
            }}
          />
        );
      case "NOTES":
        return (
          <EmployeeNotes
            id={this.props.id}
            data={this.state.employeeData}
            onUpdate={(key, value) => {
              let employeeData = this.state.employeeData;
              employeeData[key] = value;
              this.setState({ employeeData });
            }}
          />
        );
      case "CONTACTS":
        return (
          <EmployeeContacts
            id={this.props.id}
            data={this.state.companyData}
            onUpdate={(key, value) => {
              let companyData = this.state.companyData;
              companyData[key] = value;
              this.setState({ companyData });
            }}
          />
        );
      case "SPECIFIC_FIELDS":
        return (
          <EmployeeSpecificFields
            id={this.props.id}
            data={this.state.employeeData}
            companyId={this.state.employeeData.companyId}
            onUpdate={(key, value) => {
              let employeeData = this.state.employeeData;
              employeeData[key] = value;
              this.setState({ employeeData });
            }}
          />
        );
      case "TRANSFER":
        return (
          <EmployeeTransfer
            id={this.props.id}
            data={this.state.employeeData}
            companyId={this.state.employeeData.companyId}
            onUpdate={(key, value) => {
              let employeeData = this.state.employeeData;
              employeeData[key] = value;
              this.setState({ employeeData });
            }}
          />
        );
      case "EE_PROTOCOLS":
        return (
          <EmployeeProtocols
            id={this.props.id}
            data={this.state.employeeData}
            companyId={this.state.employeeData.companyId}
            onUpdate={(key, value) => {
              let employeeData = this.state.employeeData;
              employeeData[key] = value;
              this.setState({ employeeData });
            }}
          />
        );
      case "VISITS":
        return <EmployeeVisits id={this.props.id} />;
      case "DATA":
        return (
          <EmployeeData id={this.props.id} data={this.state.employeeData} />
        );
      /*
      
      
     
      case "JOBS":
        return (
          <CompanyJobs
            id={this.props.id}
            data={this.state.companyData}
            onUpdate={(key, value) => {
              let companyData = this.state.companyData;
              companyData[key] = value;
              this.setState({ companyData });
            }}
          />
        );

    
      case "DATA":
        return (
          <CompanySpecificData
            id={this.props.id}
            data={this.state.companyData}
            onUpdate={(key, value) => {
              let companyData = this.state.companyData;
              companyData[key] = value;
              this.setState({ companyData });
            }}
          />
        ); */
      default:
        return <div>Not Live</div>;
    }
  };
  renderHeader = () => {
    let companyName =
      this.state.companyData?.companyInformalName ||
      this.state.companyData?.companyName ||
      "Company Details";
    let employeeName =
      this.state.employeeData?.employeePreferredName ||
      this.state.employeeData?.employeeName ||
      "Company Details";
    return (
      <CustomBreadcrumbs
        heading={employeeName}
        links={[
          { name: "Dashboard", href: "/" },
          {
            name: `${companyName}`,
            href: `/employers/${this.state.employeeData?.companyId}`,
          },
          { name: `Details: ${employeeName}` },
        ]}
        action={
          <Box>
            <Button
              disabled={this.state.isLoading}
              onClick={() => this.getById()}
              variant="contained"
              startIcon={
                <Iconify icon="mingcute:refresh-anticlockwise-1-line" />
              }
            >
              Refresh Data
            </Button>
          </Box>
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <>
        {this.renderHeader()}
        {this.renderTabs()}
        {this.renderTab()}
      </>
    );
  }
}

export default WithAPICall(EmployeeDetails);
