import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import { Box, Stack } from "@mui/system";
import GMap from "../utils/GMap";
import CountrySelector from "../utils/CountrySelector";
import { Alert, Button, LinearProgress, TextField } from "@mui/material";
import cogoToast from "cogo-toast";
import { View500 } from "src/sections/error";
import { LoadingButton } from "@mui/lab";

class VisitEmployeeLocation extends React.Component {
  state = {
    isLoading: true,
  };
  componentDidMount() {
    // this.setState(
    //   {
    //     ...this.props.employee,
    //     ...this.props.visit.employee,
    //   },
    //   () => {
    //     this.getById();
    //   }
    // );
    this.getById();
    console.log(this.props);
  }
  getById = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost("/employee/getById", {
        _id: this.props.employee._id,
      });
      this.setState({
        ...res,
        isLoading: false,
        employeeGeoLat: this.props.visit.employeeGeoLat
          ? this.props.visit.employeeGeoLat
          : res.employeeGeoLat,
        employeeGeoLong: this.props.visit.employeeGeoLong
          ? this.props.visit.employeeGeoLong
          : res.employeeGeoLong,
        ...this.props.visit,
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Employee Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  updateScalars = async (arr) => {
    try {
      let res = await this.props.apiCallPost("/employee/updateScalars", {
        _id: this.props.employee._id,
        ...arr,
      });
      this.setState({ ...res, isLoading: false });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Updating Employee Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  setPlace = (place) => {
    this.setState({
      employeeCity: place.city,
      employeeCountry: place.country,
      employeeCountryCallingCode: place.country === "United States" ? "1" : "",
      employeeCountryShortCode: place.country === "United States" ? "US" : "",
      employeeState: place.state,
      employeeAddress: place.formatted_address,
      employeeGeoLat: place.geolocation.lat,
      employeeGeoLong: place.geolocation.lng,
      employeePostalCode: place.postal_code,
    });
  };
  renderLocationBox = () => {
    return (
      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
        }}
      >
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
          }}
        >
          <Stack spacing={1}>
            <Alert severity="info">
              Please search for the address here. The data on the right side
              panel will be filled automatically based on search results.
            </Alert>
            <GMap
              onPlace={(r) => {
                this.setPlace(r);
                console.log({ r });
              }}
              geoObject={{
                lat: this.state.employeeGeoLat,
                lng: this.state.employeeGeoLong,
              }}
              onLatLng={(geo) => {
                this.setState({
                  employeeGeoLat: geo.lat,
                  employeeGeoLong: geo.lng,
                });
              }}
            />
          </Stack>
        </Box>
        <Box
          rowGap={1}
          columnGap={1}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
          }}
        >
          <TextField
            disabled
            InputLabelProps={{
              shrink: this.state.employeeAddress,
            }}
            label="Address Line 1"
            multiline
            value={
              this.state.employeeAddress || this.props.visit.employeeAddress
            }
          />
          <TextField
            disabled
            InputLabelProps={{
              shrink: this.state.employeeAddressQualilfier,
            }}
            label="Address Line 2"
            multiline
            value={
              this.state.employeeAddressQualilfier ||
              this.props.visit.employeeAddressQualilfier
            }
          />
          <TextField
            disabled
            InputLabelProps={{
              shrink: this.state.employeeCity,
            }}
            label="City"
            value={this.state.employeeCity || this.props.visit.employeeCity}
          />
          <TextField
            disabled
            InputLabelProps={{
              shrink: this.state.employeeState,
            }}
            label="State"
            value={this.state.employeeState || this.props.visit.employeeState}
          />
          <TextField
            disabled
            InputLabelProps={{
              shrink:
                this.props.visit.employeePostalCode ||
                this.state.employeePostalCode,
            }}
            label="Postal Code"
            value={
              this.state.employeePostalCode ||
              this.props.visit.employeePostalCode
            }
          />
        </Box>
      </Box>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <Stack spacing={2}>
        {this.renderLocationBox()}
        <LoadingButton
          loading={this.props.isSaving}
          disabled={!this.state.employeeGeoLat || !this.state.employeeGeoLong}
          variant="contained"
          onClick={() => {
            this.props.onUpdateLocation({
              employeeAddress: this.state.employeeAddress,
              employeeAddressQualilfier: this.state.employeeAddressQualilfier,
              employeeCity: this.state.employeeCity,
              employeeState: this.state.employeeState,
              employeePostalCode: this.state.employeePostalCode,
              employeeGeoLat: this.state.employeeGeoLat,
              employeeGeoLong: this.state.employeeGeoLong,
              employeeCountry: this.state.employeeCountry,
              employeeCountryCallingCode:
                this.state.employeeCountry === "United States" ? "1" : "",
              employeeCountryShortCode:
                this.state.employeeCountry === "United States" ? "US" : "",
            });
          }}
        >
          Confirm Location For This Visit
        </LoadingButton>
      </Stack>
    );
  }
}
export default WithAPICall(VisitEmployeeLocation);
