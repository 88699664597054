import { Grid, Select, MenuItem } from "@mui/material";
import { DonutChart } from "@tremor/react";
import { useState, useEffect, useContext } from "react";
import { WithAPICall } from "../utils/apiUtil";
import structureData from "./utils/massage-data";
import HOCContext from "./contexts/HOCContext";

const dataFormatter = (number) =>
  `${Intl.NumberFormat("us").format(number).toString()} Tasks`;

const data = {
  "2024-06-01": {
    VisitRequested: {
      _id: {
        date: "2024-06-01",
        taskInterpreter: "VisitRequested",
      },
      pendingTime: 22,
      count: 1,
      entries: ["66348ee4e23efc5944218705"],
      taskAssignees: [],
    },
    VisitScheduled: {
      _id: {
        date: "2024-06-01",
        taskInterpreter: "VisitScheduled",
      },
      pendingTime: 22,
      count: 1,
      entries: ["66348ee4e23efc5944218705"],
      taskAssignees: [],
    },
    VisitDocumentReview: {
      _id: {
        date: "2024-06-01",
        taskInterpreter: "VisitDocumentReview",
      },
      pendingTime: 22,
      count: 1,
      entries: ["66348ee4e23efc5944218705"],
      taskAssignees: [],
    },
  },
  "2024-06-02": {
    VisitRequested: {
      _id: {
        date: "2024-06-02",
        taskInterpreter: "VisitRequested",
      },
      pendingTime: 22,
      count: 1,
      entries: ["66348ee4e23efc5944218705"],
      taskAssignees: [],
    },
    VisitScheduled: {
      _id: {
        date: "2024-06-02",
        taskInterpreter: "VisitScheduled",
      },
      pendingTime: 22,
      count: 1,
      entries: ["66348ee4e23efc5944218705"],
      taskAssignees: [],
    },
    VisitDocumentReview: {
      _id: {
        date: "2024-06-02",
        taskInterpreter: "VisitDocumentReview",
      },
      pendingTime: 22,
      count: 1,
      entries: ["66348ee4e23efc5944218705"],
      taskAssignees: [],
    },
  },
  "2024-06-03": {
    VisitRequested: {
      _id: {
        date: "2024-06-03",
        taskInterpreter: "VisitRequested",
      },
      pendingTime: 22,
      count: 1,
      entries: ["66348ee4e23efc5944218705"],
      taskAssignees: [],
    },
    VisitScheduled: {
      _id: {
        date: "2024-06-03",
        taskInterpreter: "VisitScheduled",
      },
      pendingTime: 22,
      count: 1,
      entries: ["66348ee4e23efc5944218705"],
      taskAssignees: [],
    },
    VisitDocumentReview: {
      _id: {
        date: "2024-06-03",
        taskInterpreter: "VisitDocumentReview",
      },
      pendingTime: 22,
      count: 1,
      entries: ["66348ee4e23efc5944218705"],
      taskAssignees: [],
    },
  },
};

function structureDataNoApi(data) {
  const massagedData = [];
  let categories = [];
  try {
    for (const date of Array.from(Object.keys(data))) {
      let total = 0;
      const dayDate = {
        date,
      };
      for (const company of Array.from(Object.keys(data[date]))) {
        if (!categories.includes(company)) categories.push(company);
        dayDate[company] = data[date][company].count;
        total += data[date][company].count;
      }
      dayDate.Total = total;
      massagedData.push(dayDate);
    }
    for (const category of categories) {
      for (const dayDate of massagedData) {
        if (!(category in dayDate)) dayDate[category] = 0;
      }
    }
  } catch (error) {
    console.error("could not get data", error);
  }
  return { massagedData, categories };
}

export default WithAPICall(function TaskStatus({ apiCallPost }) {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const { from, to, companyIds } = useContext(HOCContext);

  useEffect(() => {
    const init = async () => {
      try {
        let categoryWise = {};
        let finalData = [];
        const { massagedData, categories } = structureDataNoApi(data);
        console.log({ massagedData });
        for (const date of Array.from(Object.keys(massagedData))) {
          for (const category of Array.from(Object.keys(massagedData[date]))) {
            // if (category === "Total") return;
            if (!(category in categoryWise)) {
              categoryWise[category] = 0;
            }
            categoryWise[category] =
              categoryWise[category] + massagedData[date][category];
          }
        }
        delete categoryWise.date;
        for (const key of Array.from(Object.keys(categoryWise))) {
          const d = {
            name: key,
            value: categoryWise[key],
          };
          finalData.push(d);
        }
        setData(finalData.filter((f) => f.name !== "Total"));
        setCategories(categories);
      } catch (error) {
        console.error("could not get data", error);
      }
    };
    init();
  }, [from, to, companyIds]);

  return (
    <>
      <Grid container>
        {/* <Grid item xs={12}>
          <Select
            value={taskType}
            onChange={(e) => {
              setTaskType(e.target.value);
            }}
            label="Task Type"
          >
            <MenuItem value="tt1">VisitSchedule</MenuItem>
            <MenuItem value="tt2">VisitDocumentChase</MenuItem>
            <MenuItem value="tt3">VisitClose</MenuItem>
          </Select>
        </Grid> */}
      </Grid>
      <div className="space-y-3">
        <div className="flex justify-center">
          <DonutChart
            data={data}
            // categories={categories}
            variant="pie"
            valueFormatter={dataFormatter}
            onValueChange={(v) => console.log(v)}
          />
        </div>
      </div>
    </>
  );
});
