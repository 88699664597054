import React, { useState, useMemo } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
  Box,
  Tooltip,
  IconButton,
  Switch,
  FormControlLabel,
  Chip,
  Button,
} from "@mui/material";
import Iconify from "src/components/iconify";
import { Stack } from "@mui/system";
import cogoToast from "cogo-toast";

// TODO: Replace with actual age
const EMPLOYEE_AGE = 40;

const frequencies = [500, 1000, 2000, 3000, 4000, 6000, 8000];

const getDecibelColor = (value) => {
  const numValue = Number(value);
  if (numValue > 85) return "error.darker";
  if (numValue > 70) return "error.dark";
  if (numValue > 55) return "error.main";
  if (numValue > 40) return "error.light";
  if (numValue > 25) return "error.lighter";
  return "inherit";
};

const calculateSTS = (currentData, baselineData, ear) => {
  const freqsToCheck = [2000, 3000, 4000];
  const currentAvg =
    freqsToCheck.reduce(
      (sum, freq) => sum + Number(currentData[`${ear}EarDbAt${freq}Hz`]),
      0
    ) / 3;
  const baselineAvg =
    freqsToCheck.reduce(
      (sum, freq) => sum + Number(baselineData[`${ear}EarDbAt${freq}Hz`]),
      0
    ) / 3;
  return currentAvg - baselineAvg;
};

export const AudiogramDataViewer = ({ data_, onUpdate }) => {
  const theme = useTheme();
  const [data, setData] = useState(data_);
  const [editMode, setEditMode] = useState(false);
  const [useOSHACorrection, setUseOSHACorrection] = useState(false);
  const [abnormalFields, setAbnormalFields] = useState(data.hasAbnormal || []);
  const [hasChange, setHasChange] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const handleSave = () => {
    setIsSaving(true);
    onUpdate(data);
    setIsSaving(false);
    setHasChange(false);
  };
  let saveElem = null;
  if (hasChange && !isSaving) {
    saveElem = (
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="overline">Unsaved Changes</Typography>
        <Button
          variant="contained"
          onClick={handleSave}
          startIcon={<Iconify icon="bx:save" />}
        >
          Save
        </Button>
      </Stack>
    );
  }
  if (isSaving) {
    saveElem = (
      <Stack direction="row" spacing={2}>
        <Typography variant="overline">Saving...</Typography>
        <Iconify icon="pulse-3" />
      </Stack>
    );
  }

  const handleChange = (field, value) => {
    if (!onUpdate) {
      cogoToast.error("Updates are restricted.");
      return;
    }
    const updatedData = { ...data, [field]: value };
    setData(updatedData);
    setHasChange(true);
    setIsSaving(false);
  };
  // TODO: Replace with actual historical data
  const historicalData = useMemo(
    () => [
      { date: new Date("2024-09-12"), ...data, isCurrent: true },
      { date: new Date("2023-01-01"), ...data },
      { date: new Date("2022-06-01"), ...data, baselineRight: true },
      { date: new Date("2022-01-01"), ...data, baselineLeft: true },
    ],
    [data]
  );

  const toggleAbnormal = (field) => {
    const newAbnormalFields = abnormalFields.includes(field)
      ? abnormalFields.filter((f) => f !== field)
      : [...abnormalFields, field];
    setAbnormalFields(newAbnormalFields);
    setData({ ...data, hasAbnormal: newAbnormalFields });
  };

  const isAbnormal = (field) => abnormalFields.includes(field);

  const getTooltipText = (field) => {
    return isAbnormal(field)
      ? "This result is potentially abnormal. Click to mark it as normal."
      : "This result is normal. Click to mark it as potentially abnormal.";
  };

  const renderHeader = () => {
    if (abnormalFields.length === 0) {
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">Audiogram</Typography>
          {saveElem}
        </Box>
      );
    }

    const abnormalFieldNames = abnormalFields.join(", ");

    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{
          breakAfter: "avoid",
        }}
      >
        <Box display="flex" alignItems="center">
          <Iconify
            icon="mdi:warning"
            sx={{ mr: 1, color: theme.palette.error.main }}
          />
          <Typography variant="subtitle1">
            Audiogram | Red Flags: {abnormalFieldNames}
          </Typography>
        </Box>
        {saveElem}
      </Box>
    );
  };

  const renderDecibelTable = () => {
    const baselineLeft = historicalData.find((d) => d.baselineLeft);
    const baselineRight = historicalData.find((d) => d.baselineRight);

    return (
      <TableContainer sx={{ maxWidth: "100%", overflowX: "auto" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell colSpan={frequencies.length}>LE</TableCell>
              <TableCell colSpan={frequencies.length}>RE</TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              {frequencies.map((freq) => (
                <TableCell key={`le-${freq}`} align="center">
                  {freq === 500 ? ".5k" : `${freq / 1000}k`}
                </TableCell>
              ))}
              {frequencies.map((freq) => (
                <TableCell key={`re-${freq}`} align="center">
                  {freq === 500 ? ".5k" : `${freq / 1000}k`}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {historicalData.map((rowData, rowIndex) => {
              const leftSTS = baselineLeft
                ? calculateSTS(rowData, baselineLeft, "left")
                : null;
              const rightSTS = baselineRight
                ? calculateSTS(rowData, baselineRight, "right")
                : null;

              return (
                <TableRow key={rowIndex}>
                  <TableCell>
                    {rowData.date.toLocaleDateString()}
                    <Box mt={1}>
                      {rowData.isCurrent && (
                        <Chip
                          label="Current"
                          color="primary"
                          size="small"
                          sx={{ mr: 0.5 }}
                        />
                      )}
                      {rowData.baselineLeft && (
                        <Chip
                          label="Baseline LE"
                          color="secondary"
                          size="small"
                          sx={{ mr: 0.5 }}
                        />
                      )}
                      {rowData.baselineRight && (
                        <Chip
                          label="Baseline RE"
                          color="secondary"
                          size="small"
                          sx={{ mr: 0.5 }}
                        />
                      )}
                    </Box>
                    {rowData.isCurrent && (
                      <Box mt={1}>
                        <Tooltip title="Mark as baseline for Left Ear">
                          <IconButton
                            size="small"
                            onClick={() => {
                              /* TODO: Implement marking as baseline */
                            }}
                          >
                            <Iconify icon="mdi:flag-variant" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Mark as baseline for Right Ear">
                          <IconButton
                            size="small"
                            onClick={() => {
                              /* TODO: Implement marking as baseline */
                            }}
                          >
                            <Iconify icon="mdi:flag-variant-outline" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  </TableCell>
                  {["left", "right"].map((ear) => (
                    <React.Fragment key={ear}>
                      {frequencies.map((freq) => {
                        const field = `${ear}EarDbAt${freq}Hz`;
                        const value = rowData[field];
                        const correctedValue = useOSHACorrection
                          ? Number(value) - (EMPLOYEE_AGE - 20) / 2
                          : value;
                        const sts = ear === "left" ? leftSTS : rightSTS;
                        return (
                          <TableCell
                            key={`${ear}-${freq}`}
                            align="center"
                            sx={{
                              bgcolor: getDecibelColor(correctedValue),
                              color:
                                Number(correctedValue) > 55
                                  ? "white"
                                  : "inherit",
                            }}
                          >
                            <Tooltip
                              title={
                                useOSHACorrection
                                  ? `Original: ${value}, Correction: ${((EMPLOYEE_AGE - 20) / 2).toFixed(1)}`
                                  : ""
                              }
                              arrow
                            >
                              <Box>
                                {rowData.isCurrent && editMode ? (
                                  <TextField
                                    size="small"
                                    value={value || ""}
                                    onChange={(e) =>
                                      handleChange(field, e.target.value)
                                    }
                                    sx={{ width: "50px" }}
                                  />
                                ) : (
                                  correctedValue
                                )}
                                {sts !== null && sts > 10 && freq === 2000 && (
                                  <Tooltip
                                    title={`STS: ${sts.toFixed(1)} dB`}
                                    arrow
                                  >
                                    <Box
                                      component="span"
                                      sx={{ color: "error.main", ml: 1 }}
                                    >
                                      *
                                    </Box>
                                  </Tooltip>
                                )}
                              </Box>
                            </Tooltip>
                          </TableCell>
                        );
                      })}
                    </React.Fragment>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div
      style={{
        backgroundColor: hasChange ? theme.palette.warning.lighter : "inherit",
      }}
    >
      <Card
        sx={{
          p: 2,
          m: 1,
          bgcolor:
            abnormalFields.length > 0
              ? theme.palette.error.light
              : theme.palette.info.lighter,
        }}
      >
        {renderHeader()}
      </Card>
      {onUpdate && (
        <Box mb={2}>
          <FormControlLabel
            control={
              <Switch
                checked={editMode}
                onChange={() => setEditMode(!editMode)}
              />
            }
            label="Edit Mode"
          />
          <FormControlLabel
            control={
              <Switch
                checked={useOSHACorrection}
                onChange={() => setUseOSHACorrection(!useOSHACorrection)}
              />
            }
            label="Use OSHA Age Correction"
          />
        </Box>
      )}
      <Typography variant="h6" gutterBottom>
        Decibel Thresholds
      </Typography>
      {renderDecibelTable()}
    </div>
  );
};
