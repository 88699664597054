import { LinearProgress, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import Iconify from "src/components/iconify";
import GenericNote from "../utils/GenericNote";
import { WithAPICall } from "../utils/apiUtil";
import cogoToast from "cogo-toast";
import { NoResultView, View500 } from "src/sections/error";
import { LoadingButton } from "@mui/lab";
import ReactList from "react-list";
import { QuickScore } from "quick-score";
import { debounce } from "lodash";

class ServiceNotes extends React.Component {
  state = {
    searchText: "",
    serviceNotes: [],
    filteredNotes: [],
    isLoading: true,
    isError: false,
    isCreating: false,
  };
  componentDidMount() {
    this.getByEntityId();
  }
  EXTRA_OPTIONS = ["Service Details", "Service Notes", "Process Notes"];
  getByEntityId = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost("/notes/getByEntityId", {
        entityId: this.props.id,
        entityType: "MasterService",
      });
      this.setState({
        serviceNotes: res,
        filteredNotes: res,
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Notes");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  addToEntity = async () => {
    try {
      let purpose = "General Note";
      this.setState({ isCreating: true });
      let res = await this.props.apiCallPost("/notes/addToEntity", {
        entityId: this.props.id,
        entityType: "MasterService",
        text: "",
        purpose: purpose,
      });
      this.setState({
        isCreating: false,
        serviceNotes: [res, ...this.state.serviceNotes],
        filteredNotes: [res, ...this.state.filteredNotes],
      });
      cogoToast.success("Note Added");
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Adding Note");
      this.setState({
        isCreating: false,
      });
    }
  };
  handleSearch = debounce((s) => {
    if (s === "") return null;
    if (!s) return null;
    if (s.length < 4) return null;
    let qs = new QuickScore(this.state.serviceNotes, ["purpose", "text"]);
    let res = qs.search(s);
    let it = res.map((each) => each.item);
    this.setState({
      filteredNotes: it,
    });
  }, 300);
  renderHeader = () => {
    return (
      <Stack direction="row" spacing={3}>
        <TextField
          placeholder="Type 3 Characters or more to search"
          label="Search"
          onChange={(e) => {
            let s = e.target.value;
            if (s.length > 3) {
              this.handleSearch(s);
            } else {
              this.setState({
                filteredNotes: this.state.serviceNotes,
              });
            }
          }}
        />
        <LoadingButton
          loading={this.state.isCreating}
          onClick={() => this.addToEntity()}
          variant="contained"
          startIcon={<Iconify icon="solar:add-square-broken" />}
        >
          Add Note
        </LoadingButton>
      </Stack>
    );
  };
  renderNotes = () => {
    if (this.state.isLoading) return null;
    if (this.state.isError) return null;
    if (!this.state.filteredNotes) {
      return null;
    }
    if (this.state.filteredNotes.length === 0) {
      return <NoResultView />;
    }
    let list = this.state.filteredNotes;
    return (
      <Stack spacing={2}>
        <ReactList
          pageSize={64}
          itemRenderer={(index, key) => {
            let each = list[index];
            return (
              <div key={`${key}__${each.updatedAt}`}>
                <GenericNote
                  key={each._id}
                  data={each}
                  type="NOTE"
                  extraOptions={this.EXTRA_OPTIONS}
                />
              </div>
            );
          }}
          length={list.length}
          type="simple"
        />
      </Stack>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <Stack spacing={2}>
        {this.renderHeader()}
        {this.renderNotes()}
      </Stack>
    );
  }
}

export default WithAPICall(ServiceNotes);
