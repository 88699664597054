import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Alert,
  Button,
  Card,
  Chip,
  Drawer,
  IconButton,
  LinearProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { LoadingButton } from "@mui/lab";
import Iconify from "src/components/iconify";
import AILoader from "../utils/AILoader";
import cogoToast from "cogo-toast";
import _ from "lodash";
import VisitList from "../Visit/VisitList";
import DataComponentGeneric from "./DataComponentGeneric";
import Magnifier from "react-magnifier";
import {
  SERVICE_DATA_TYPE_OPTIONS,
  TYPE_OF_DOCUMENT_OPTIONS,
} from "src/assets/data/service-data-type";
import DictaBox from "../utils/DictaBox";
import { format } from "date-fns";
import { niceDate } from "../utils/fn";

class DocumentGroup extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    needsReclassification: false,
    pagesInGroup: [],
    pageDataType: null,
    isTranscribing: false,
    isParsing: false,
    dataStructured: null,
    dataStructuredText: "",
    dataVisionReadout: "",
    isSaving: false,
    isSending: false,
    chainReaction: false,
    transcribeHidden: true,
    isDelinking: false,
    openDelinkAlert: false,
    isCalculatingSTS: false,
    notes: [],
  };
  componentDidMount() {
    this.setState(
      {
        ...this.props.data,
      },
      () => {
        this.getPages();
      }
    );
  }
  getPages = async () => {
    try {
      let res = await this.props.apiCallPost("/files/groups/getPages", {
        groupId: this.props.data._id,
      });

      this.setState({ pagesInGroup: res, isLoading: false });
    } catch (err) {
      cogoToast.error("Error Loading Group");
      console.log(err);
      this.setState({ isLoading: false, isError: true });
    }
  };

  sendAsEmailTo = async (recipient) => {
    try {
      this.setState({
        isSending: true,
      });
      let res = await this.props.apiCallPost("/files/sendCfioToWorkCare", {
        fileId: this.props.data.parentFile._id,
        recipient: recipient,
      });

      this.setState({
        isSending: false,
      });
    } catch (err) {
      cogoToast.error(`Error Sending to ${recipient}`);
      console.log(err);
      this.setState({
        isSending: false,
      });
    }
  };

  transcribeDocs = async () => {
    try {
      this.setState({
        transcribeHidden: false,
        dataVisionReadout: "",
        isTranscribing: true,
        currentAction: "Reading Document",
      });
      await this.props.apiCallPostStreamingForAI(
        "/external/pdf/transcribeDocs",
        {
          groupId: this.props.data._id,
          stream: true,
        },
        (data) => {
          this.setState({
            dataVisionReadout: this.state.dataVisionReadout + data,
          });
        },
        () => {
          this.onEndTranscription();
        }
      );
    } catch (err) {
      cogoToast.error("Error Reading File");
      console.log(err);
    }
  };
  onEndTranscription = async () => {
    // wait 2 seconds
    await new Promise((resolve) => setTimeout(resolve, 2000));
    this.setState(
      {
        isTranscribing: false,
        currentAction: null,
      },
      () => {
        let payload = {
          key: "dataVisionReadout",
          value: this.state.dataVisionReadout,
        };
        let ca = "Saving Transcription";
        this.updateGroupInfo([payload], ca);
      }
    );
  };
  parseDocs = async () => {
    try {
      this.setState({
        dataStructured: null,
        isParsing: true,
        currentAction: "Parsing Document",
      });
      await this.props.apiCallPostStreamingForAI(
        "/external/pdf/parseDocumentValues",
        {
          groupId: this.props.data._id,
          stream: true,
        },
        (data) => {
          this.setState({
            dataStructuredText: this.state.dataStructuredText + data,
          });
        },
        () => {
          this.onEndParsing();
        }
      );
    } catch (err) {
      cogoToast.error("Error Parsing File");
      console.log(err);
    }
  };
  onEndParsing = async () => {
    console.log("On End Parsing");
    // wait 2 seconds
    await new Promise((resolve) => setTimeout(resolve, 2000));
    let oo = this.state.dataStructuredText;
    let ooJSON = {};
    try {
      ooJSON = JSON.parse(oo);

      let data = ooJSON.data;
      if (_.isEmpty(data)) {
        this.setState({
          isParsing: false,
          currentAction: null,
        });
      } else {
        this.setState(
          {
            transcribeHidden: true,
            isParsing: false,
            currentAction: "Saving Parsed Data",
          },
          () => {
            let payload = {
              key: "dataStructured",
              value: data,
            };
            let ca = "Saving Parsed Data";
            this.updateGroupInfo([payload], ca);
          }
        );
      }
    } catch (err) {
      console.log(err);
      this.setState({
        isParsing: false,
        currentAction: null,
      });
    }
  };
  updateGroupInfo = async (uops, ca) => {
    try {
      this.setState({
        isSaving: true,
        currentAction: ca,
      });

      let res = await this.props.apiCallPost("/files/groups/updateInfo", {
        groupId: this.props.data._id,
        uops: uops,
      });

      this.setState(
        {
          isSaving: false,
          currentAction: null,
          ...res,
        },
        () => {
          if (this.state.chainReaction) {
            this.setState(
              {
                chainReaction: false,
              },
              () => {
                this.parseDocs();
              }
            );
          }
        }
      );
    } catch (err) {
      this.setState({
        isSaving: false,
        currentAction: null,
      });
      console.log(err);
      cogoToast.error("Error Updating Group Info");
    }
  };
  deleteStructuredResults = async (index) => {
    let data = this.state.dataStructured;
    data.splice(index, 1);
    let payload = {
      key: "dataStructured",
      value: data,
    };
    let ca = "Deleting Parsed Data";
    this.updateGroupInfo([payload], ca);
  };
  associateGroupWithVisit = async (
    visitId,
    protocolId,
    serviceId,
    documentType
  ) => {
    try {
      cogoToast.loading("Associating Data with Visit & Service");
      let res = await this.props.apiCallPost(
        "/files/groups/associateGroupWithVisit",
        {
          groupId: this.props.data._id,
          visitId: visitId,
          protocolId: protocolId,
          serviceId: serviceId,
          documentType,
        }
      );
      this.setState({
        associatingGroupId: null,
        ...res,
      });
      cogoToast.success("Associated Data with Visit");
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Associating Group with Visit");
    }
  };
  dissociateGroupWithVisit = async () => {
    try {
      this.setState({
        isDelinking: true,
      });
      cogoToast.loading("Delinking Data with Visit");
      let res = await this.props.apiCallPost(
        "/files/groups/dissociateGroupWithVisit",
        {
          groupId: this.props.data._id,
        }
      );
      this.setState(
        {
          ...res,
          isDelinking: false,
        },
        () => {
          if (this.props.fromVisitPage) {
            this.props.onDelink();
          }
        }
      );
      cogoToast.success("Delinked Data with Visit");
    } catch (err) {
      this.setState({
        isDelinking: false,
      });
      console.log(err);
      cogoToast.error("Error Delinking Data with Visit");
    }
  };
  renderCurrentAction = () => {
    if (this.props.isHistorical) {
      return null;
    }
    if (!this.state.currentAction) {
      return null;
    }
    return (
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <Chip
          label={this.state.currentAction}
          icon={<Iconify icon="svg-spinners:pulse-3" />}
        />
        {this.renderAILoader()}
      </Stack>
    );
  };
  renderAILoader = () => {
    if (this.state.isTranscribing) {
      return <AILoader text="READING" />;
    }
    if (this.state.isParsing) {
      return <AILoader text="PARSING" />;
    }

    return null;
  };
  renderGroupHeader = () => {
    let disabledConditions =
      this.state.isSending ||
      this.state.isSaving ||
      this.state.aiRunning ||
      this.state.isTranscribing ||
      this.state.isParsing;
    let icb = (
      <IconButton
        onClick={() => {
          this.props.onMaximise();
        }}
      >
        <Iconify icon="solar:maximize-bold-duotone" />
      </IconButton>
    );
    if (this.props.maximised) {
      icb = (
        <IconButton
          onClick={() => {
            this.props.onMinimise();
          }}
        >
          <Iconify icon="solar:minimize-bold-duotone" />
        </IconButton>
      );
    }
    if (!this.props.showMaxButton) {
      icb = null;
    }
    let actionBar = (
      <Stack direction="row" spacing={0.5} alignItems={"center"}>
        <Button
          variant="contained"
          color="error"
          startIcon={<Iconify icon="solar:trash-bin-minimalistic-broken" />}
          size="small"
          disabled={disabledConditions}
          onClick={() => {
            this.props.markAsIrrelevant();
          }}
        >
          Mark As Irrelevant
        </Button>

        <Button
          disabled={disabledConditions}
          variant="contained"
          color="warning"
          startIcon={<Iconify icon="mingcute:classify-2-fill" />}
          size="small"
          onClick={() => {
            this.setState({
              needsReclassification: !this.state.needsReclassification,
            });
          }}
        >
          Re-Classify File
        </Button>

        <Button
          disabled={disabledConditions}
          variant="contained"
          color="info"
          startIcon={<Iconify icon="ic:outline-email" />}
          size="small"
          onClick={() => {
            this.sendAsEmailTo("OHM");
          }}
        >
          Send to OHM
        </Button>

        <Button
          disabled={disabledConditions}
          variant="contained"
          color="info"
          startIcon={<Iconify icon="ic:outline-email" />}
          size="small"
          onClick={() => {
            this.sendAsEmailTo("ONCOURSE");
          }}
        >
          Send to OnCourse
        </Button>
      </Stack>
    );
    let fileLink = (
      <Tooltip title="Click to view source document">
        {" "}
        <IconButton href={this.props.data.parentPsUrl} target="_blank">
          <Iconify icon="quill:link-out" />
        </IconButton>
      </Tooltip>
    );
    let reclassifyWholeButton = (
      <Tooltip title="Choosing this will move all groups from the source document to the re-classification bin. Use this if you suspect pages are missing, or the current error in classification has potentially affected other data as well.">
        <Button
          variant="contained"
          color="error"
          size="small"
          disabled={disabledConditions}
          startIcon={<Iconify icon="mingcute:classify-3-line" />}
          onClick={() => {
            this.props.markAsReclassify();
          }}
        >
          Reclassify & Organize Source Document
        </Button>
      </Tooltip>
    );
    let selectorOfType = (
      <Select
        disabled={disabledConditions}
        sx={{
          minWidth: "100px",
        }}
        size="small"
        value={this.state.selectorType}
        onChange={(e) => {
          let v = e.target.value;
          this.setState({
            selectorType: v,
          });
        }}
      >
        {TYPE_OF_DOCUMENT_OPTIONS.map((opt, idx) => {
          return (
            <MenuItem value={opt} size="small" key={idx}>
              {opt}
            </MenuItem>
          );
        })}
      </Select>
    );
    let classifyOnlyThisPage = (
      <Tooltip title="Choosing this will only classify this page. Use this if you suspect the classification error is only on this page.">
        <LoadingButton
          loading={
            this.state.isSaving ||
            this.state.aiRunning ||
            !this.state.selectorType
          }
          variant="contained"
          color="warning"
          size="small"
          startIcon={<Iconify icon="mdi:magic" />}
          disabled={disabledConditions}
          onClick={() => {
            this.setState(
              {
                chainReaction: true,
              },
              () => {
                this.transcribeDocs();
              }
            );
          }}
        >
          Reprocess This Group
        </LoadingButton>
      </Tooltip>
    );
    let classifyOne = (
      <Stack direction="row" spacing={0.5} alignItems={"center"}>
        <Typography variant="overline">Classify Only This Page As</Typography>
        {selectorOfType}
        {classifyOnlyThisPage}
      </Stack>
    );
    if (!this.state.needsReclassification) {
      reclassifyWholeButton = null;
      classifyOnlyThisPage = null;
      classifyOne = null;
    }
    let typeElem = <Chip label={this.props.data.pageDataType} size="large" />;
    let vDate = (
      <Chip
        label={niceDate(this.props.data.vDate)}
        size="large"
        color="secondary"
      />
    );
    if (this.props.isHistorical) {
      actionBar = null;
      reclassifyWholeButton = null;
      classifyOne = null;
    }
    return (
      <Stack spacing={1} direction="row" alignItems={"center"}>
        {icb}
        {typeElem}
        {this.props.isHistorical ? vDate : null}
        {this.renderCurrentAction()}
        {actionBar}
        {fileLink}
        {reclassifyWholeButton}
        {classifyOne}
      </Stack>
    );
  };
  renderAssociationBar = () => {
    if (_.isEmpty(this.state.service)) {
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="overline">
            {this.state.employee
              ? this.state.employee.employeeName
              : this.state.extractedEENamee}
          </Typography>
          <Box>
            {this.props.hideLinkToVisit ? null : (
              <Button
                disabled={
                  this.state.isDelinking ||
                  this.state.isSaving ||
                  this.state.isTranscribing ||
                  this.state.isParsing ||
                  this.state.associatingGroupId ||
                  this.state.isCalculatingSTS ||
                  this.state.isAudiogramBaseline ||
                  this.state.aiRunning
                }
                variant="contained"
                color="secondary"
                startIcon={<Iconify icon="mdi:link-variant" />}
                size="small"
                onClick={() => {
                  this.setState({
                    associatingGroupId: this.props.data._id,
                  });
                }}
              >
                Link To Visit
              </Button>
            )}
          </Box>
        </Stack>
      );
    }
    let delinkElem = (
      <LoadingButton
        loading={this.state.isDelinking}
        size="small"
        onClick={() => {
          // this.dissociateGroupWithVisit();
          this.setState({ openDelinkAlert: true });
        }}
      >
        Delink from Visit
      </LoadingButton>
    );
    let employeeElem = (
      <Typography variant="overline">
        {this.state.employee?.employeeName} ({this.state.company?.companyName})
      </Typography>
    );
    let serviceElem = (
      <Typography variant="caption">
        {this.state.service?.serviceName}
      </Typography>
    );
    let visitButton = (
      <IconButton
        size="small"
        color="primary"
        href={`/visits/${this.state.visit}`}
        // target="non_blank"
        sx={{
          width: 24,
          height: 24,
          bgcolor: "primary.main",
          color: "primary.contrastText",
          "&:hover": {
            bgcolor: "primary.dark",
          },
        }}
      >
        <Iconify icon="akar-icons:link-out" />
      </IconButton>
    );
    if (this.props.isHistorical) {
      return null;
    }
    return (
      <Stack alignItems="center" direction="row" spacing={2}>
        {employeeElem} {serviceElem} {visitButton} {delinkElem}
      </Stack>
    );
  };
  renderPageThumbnails = () => {
    let pages = this.state.pagesInGroup;
    if (!pages || pages.length === 0) {
      return <Alert severity="warning">No Pages in Group</Alert>;
    }
    return (
      <Card
        sx={{
          p: 1,
          boxShadow: 2,
          textAlign: "center",
          bgcolor: "background.neutral",
        }}
      >
        <Box
          rowGap={2}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
            md: "repeat(1, 1fr)",
            lg: "repeat(1, 1fr)",
            xl: "repeat(1, 1fr)",
          }}
        >
          {pages.map((page, ix) => {
            return (
              <Stack spacing={0.5} key={ix}>
                <Magnifier
                  onClick={() => {
                    window.open(page.psUrl, "_blank");
                  }}
                  src={page.psUrl}
                  width={400}
                  height="auto"
                  mgWidth={400}
                  mgHeight={400}
                  zoomFactor={0.6}
                />
              </Stack>
            );
          })}
        </Box>
      </Card>
    );
  };
  renderdataVisionReadout = () => {
    if (this.state.isTranscribing && !this.state.dataVisionReadout) {
      return <LinearProgress />;
    }
    if (!this.state.dataVisionReadout) {
      return null;
    }
    let op = this.state.dataVisionReadout;
    let lines = op.split("\n");
    return (
      <Stack spacing={0}>
        <Typography variant="overline">Transcription Output</Typography>
        {lines.map((line) => {
          return <Typography variant="caption">{line}</Typography>;
        })}
      </Stack>
    );
  };
  renderdataStructuredText = () => {
    if (this.state.isParsing) {
      if (!this.state.dataStructuredText) {
        return <LinearProgress />;
      }
      let d = this.state.dataStructuredText;
      let countOfParams = d.split("},").length;
      let countOfParamsText = `Parsing Parameter ${countOfParams}`;
      return (
        <Chip
          label={countOfParamsText}
          icon={<Iconify icon="svg-spinners:pulse-3" />}
          color="secondary"
        />
      );
    }
    if (this.state.isSaving) {
      return <LinearProgress />;
    }
    if (!this.state.dataStructured) {
      return null;
    }
    if (_.isEmpty(this.state.dataStructured)) {
      return <Alert severity="warning">No Structured Data</Alert>;
    }
    let data = this.state.dataStructured;
    let dataType = this.props.data.pageDataType;
    return (
      <DataComponentGeneric
        renderType={this.props.renderType}
        showData={
          this.props.renderType === "patientLetter" ||
          !this.props.defaultCollapsed
            ? true
            : false
        }
        isHistorical={this.props.isHistorical}
        showMaxButton={this.props.showMaxButton}
        groupData={this.props.data}
        groupId={this.props.data._id}
        dataType={dataType}
        isSaving={this.state.isSaving}
        data={data}
        onDelete={(index) => {
          this.deleteStructuredResults(index);
        }}
        onSave={(data) => {
          let payload = {
            key: "dataStructured",
            value: data,
          };
          let ca = "Saving Parsed Data";
          this.updateGroupInfo([payload], ca);
        }}
      />
    );
  };
  renderAssociationDrawer = () => {
    if (!this.state.associatingGroupId) {
      return null;
    }
    if (this.props.isHistorical) {
      return null;
    }
    return (
      <Drawer
        open={this.state.associatingGroupId}
        anchor="right"
        onClose={() => {
          this.setState({
            associatingGroupId: null,
          });
        }}
      >
        <Card
          sx={{
            p: 2,
            width: "85vw",
            height: "100vh",
            overflow: "auto",
          }}
        >
          <VisitList
            fromAssociationPage={true}
            extractedEEName={
              this.state.employee
                ? this.state.employee.employeeName
                : this.state.extractedEEName
            }
            onAssociation={(visitId, protocolId, serviceId, documentType) => {
              this.associateGroupWithVisit(
                visitId,
                protocolId,
                serviceId,
                documentType
              );
            }}
          />
        </Card>
      </Drawer>
    );
  };
  renderVisitPageHeader = () => {
    let fileElem = (
      <Button
        size="small"
        href={`/documents/${this.props.data.parentFile._id}`}
        startIcon={<Iconify icon="akar-icons:link-out" />}
      >
        Open File
      </Button>
    );

    let deLinkElem = (
      <Button
        size="small"
        onClick={() => {
          // this.dissociateGroupWithVisit();
          this.setState({ openDelinkAlert: true });
        }}
        color="error"
        startIcon={<Iconify icon="akar-icons:circle-minus-fill" />}
      >
        Delink
      </Button>
    );
    if (this.props.isHistorical) {
      deLinkElem = null;
    }
    if (!this.props.data.parentFile) {
      fileElem = null;
      deLinkElem = null;
    }
    let dataType = this.props.data.pageDataType;
    return (
      <Stack
        direction="row"
        alignItems={"center"}
        spacing={1}
        sx={{
          background: "#b0b0b0",
          color: "black",
          padding: "0.5rem 0.2rem",
          borderRadius: "0.4rem",
        }}
      >
        <Typography variant="subtitle2">{dataType}</Typography>
        <Stack direction="row" spacing={1} sx={{ marginLeft: "auto" }}>
          {fileElem}
          {deLinkElem}
        </Stack>
      </Stack>
    );
  };
  renderDialog = () => {
    if (this.props.isHistorical) {
      return null;
    }
    return (
      <Dialog
        open={this.state.openDelinkAlert}
        onClose={() => {
          this.setState({ openDelinkAlert: false });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delink Document from Visit
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delink this document with the visit?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.setState({ openDelinkAlert: false });
            }}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              this.setState({ openDelinkAlert: false });
              this.dissociateGroupWithVisit();
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  renderNotes = () => {
    let noteCount = this.state.notes?.length || 0;
    let iconStr = "";
    if (!noteCount) {
      iconStr = "zondicons:add-solid";
    } else if (noteCount > 9) {
      iconStr = "mdi:number-9-plus-circle";
    } else {
      iconStr = "tabler:number-" + noteCount;
    }

    let icb = (
      <IconButton
        onClick={() => {
          this.setState({
            showNotes: true,
          });
        }}
      >
        <Iconify icon={iconStr} />
      </IconButton>
    );
    if (this.state.showNotes) {
      icb = (
        <IconButton
          onClick={() => {
            this.setState({
              showNotes: false,
            });
          }}
        >
          <Iconify icon="mingcute:arrows-up-fill" />
        </IconButton>
      );
    }
    return (
      <Stack spacing={1}>
        <Stack direction="row" alignItems={"center"} spacing={1}>
          <Typography variant="h6">Notes</Typography> {icb}
        </Stack>
        {this.state.showNotes && (
          <Stack spacing={1}>
            {this.renderNotesEntryBox()}
            <Stack spacing={0.5}>
              {this.state.notes?.map((note, index) => (
                <Card
                  sx={{
                    p: 1,
                    boxShadow: 2,
                    bgcolor:
                      index % 2 === 0
                        ? "background.paper"
                        : "background.neutral",
                  }}
                >
                  <Stack spacing={0.5} key={index}>
                    <Stack direction="row" spacing={0.5}>
                      <Typography variant="overline">
                        {note.createdByName}
                      </Typography>
                      <Typography variant="caption">
                        {format(new Date(note.createdAt), "PPpp")}
                      </Typography>
                    </Stack>
                    <Typography variant="caption">{note.text}</Typography>
                  </Stack>
                </Card>
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  };
  addNotes = async (text) => {
    try {
      this.setState({
        isSaving: true,
      });
      let res = await this.props.apiCallPost("/files/groups/addNotes", {
        groupId: this.props.data._id,
        text: text,
      });
      cogoToast.success("Note Added");
      this.setState({
        isSaving: false,
        notes: [...this.state.notes, res],
      });
    } catch (error) {
      this.setState({
        isSaving: false,
      });
      cogoToast.error("Could not add note. Please try again.");
    }
  };
  renderNotesEntryBox = () => {
    let disabledIf =
      this.state.isSaving ||
      this.state.aiRunning ||
      this.state.isTranscribing ||
      this.state.isParsing ||
      this.state.isDelinking ||
      this.props.isHistorical;
    return (
      <DictaBox
        onAction={(text) => {
          this.addNotes(text);
        }}
        disabled={disabledIf}
        entityType="DOCUMENT_GROUP"
        entityId={this.props.data._id}
        threadId={this.props.data._id}
        label="Add Notes"
      />
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <Alert severity="error">Error Loading Group</Alert>;
    }
    if (this.props.fromVisitPage) {
      return (
        <>
          {this.renderDialog()}
          <Stack spacing={2}>
            {this.renderVisitPageHeader()}
            {this.renderdataStructuredText()}
          </Stack>
        </>
      );
    }
    if (this.state.transcribeHidden) {
      if (this.props.showMaxButton && !this.props.maximised) {
        return (
          <>
            {this.renderDialog()}
            <Stack spacing={2}>
              {this.props.renderType === "patientLetter"
                ? null
                : !this.props.hideActions && this.renderGroupHeader()}{" "}
              {this.props.renderType === "patientLetter"
                ? null
                : !this.props.hideActions && this.renderAssociationBar()}{" "}
              {this.renderAssociationDrawer()}
              <Grid container spacing={1}>
                <Grid xs={12} md={12}>
                  <Stack spacing={2}>
                    {this.props.renderType === "patientLetter"
                      ? null
                      : this.renderNotes()}
                    {/* {this.renderNotes()} */}
                    {this.renderdataStructuredText()}
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </>
        );
      }
      return (
        <>
          {this.renderDialog()}
          <Stack spacing={2}>
            {this.props.renderType === "patientLetter"
              ? null
              : !this.props.hideActions && this.renderGroupHeader()}{" "}
            {!this.props.hideActions && this.renderAssociationBar()}{" "}
            {this.renderAssociationDrawer()}
            <Grid container spacing={1}>
              <Grid xs={12} md={this.props.showMaxButton ? 3 : 4}>
                {this.renderPageThumbnails()}
              </Grid>
              <Grid xs={12} md={this.props.showMaxButton ? 9 : 8}>
                <Stack spacing={2}>
                  {this.props.renderType === "patientLetter"
                    ? null
                    : this.renderNotes()}
                  {this.renderdataStructuredText()}
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </>
      );
    }
    return (
      <>
        {this.renderDialog()}
        <Stack spacing={2}>
          {this.props.renderType === "patientLetter"
            ? null
            : !this.props.hideActions && this.renderGroupHeader()}{" "}
          {!this.props.hideActions && this.renderAssociationBar()}{" "}
          <Grid container spacing={3}>
            {" "}
            <Grid xs={12} md={3}>
              {this.renderPageThumbnails()}
            </Grid>
            <Grid xs={12} md={3}>
              {this.renderdataVisionReadout()}
            </Grid>
            <Grid xs={12} md={6}>
              {this.renderdataStructuredText()}
            </Grid>
          </Grid>
        </Stack>
      </>
    );
  }
}

export default WithAPICall(DocumentGroup);
