import React, { useRef, useEffect, useState } from "react";
import { Alert, Chip } from "@mui/material";
import { PFTDataViewer } from "./pft-data-viewer";
import { ChainOfCustodyDataViewer } from "./chain-of-custody-data-viewer";
import { ChestXRayDataViewer } from "./cxr-data-viewer";
import { DOTCardViewer } from "./dot-card-data-viewer";
import { ECGDataViewer } from "./ecg-data-viewer";
import { LabReportDataViewer } from "./lab-report-data-viewer";
import { UrinalysisDataViewer } from "./urinanalysis-data-viewer";
import { VitalsDataViewer } from "./vitals-data-viewer";
import { VisualAcuityDataViewer } from "./vision-data-viewer";
import { AudiogramDataViewer } from "./audiogram-data-viewer";
import { BloodAlcoholDataViewer } from "./blood-alcohol-data-viewer";
import { MedicalQuestionnaireDataViewer } from "./questionnaire-data-viewer";
import { VaccinationDataViewer } from "./vaccination-data-viewer";
import { useDebounce } from "@uidotdev/usehooks";

export function GenericRenderer({ listOfData, onUpdate }) {
  const containerRef = useRef(null);
  const dataTypeRefs = useRef({});
  const chipRowRef = useRef(null);

  const [localData, setLocalData] = useState(listOfData);

  // const debouncedListOfData = useDebounce(listOfData, 1000);

  const updateLocalData = (index, newData) => {
    const newListOfData = [...localData];
    newListOfData[index] = newData;
    setLocalData(newListOfData);
    if (onUpdate) onUpdate(newListOfData);
  };

  // useEffect(() => {
  //   onUpdate(debouncedListOfData);
  // }, [debouncedListOfData]);

  const scrollToDataType = (dataType) => {
    if (
      dataTypeRefs.current[dataType] &&
      containerRef.current &&
      chipRowRef.current
    ) {
      const chipRowHeight = chipRowRef.current.offsetHeight;
      const elementPosition =
        dataTypeRefs.current[dataType].offsetTop - chipRowHeight;

      containerRef.current.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.height = "calc(100vh - 100px)";
      containerRef.current.style.overflowY = "auto";
    }
  }, []);

  const uniqueDataTypes = [...new Set(listOfData.map((item) => item.dataType))];

  return (
    <div className="relative w-full">
      <div
        ref={chipRowRef}
        className="sticky top-0 bg-white z-10 p-4 shadow-md"
      >
        <div className="flex flex-row gap-2 overflow-x-auto pb-2">
          {uniqueDataTypes.map((dataType, index) => (
            <Chip
              key={index}
              label={dataType}
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => scrollToDataType(dataType)}
            />
          ))}
        </div>
      </div>
      <div ref={containerRef} className="w-full p-4">
        {listOfData.map((each, index) => {
          if (!each) return null;
          delete each.serviceId;
          delete each.protocolId;
          delete each.isAbnormal;
          delete each._id;
          const Component = getComponentForDataType(each.dataType);
          return (
            <div
              key={index}
              className="mb-4"
              ref={(el) => {
                dataTypeRefs.current[each.dataType] = el;
              }}
            >
              <Component
                data_={each}
                onUpdate={
                  onUpdate ? (eachNew) => updateLocalData(index, eachNew) : null
                }
              />
              <hr className="my-4 border-t-2 border-gray-400" />
            </div>
          );
        })}
      </div>
    </div>
  );
}

function getComponentForDataType(dataType) {
  switch (dataType) {
    case "VACCINATION":
      return VaccinationDataViewer;
    case "PFT":
      return PFTDataViewer;
    case "CHAIN_OF_CUSTODY":
      return ChainOfCustodyDataViewer;
    case "CXR":
      return ChestXRayDataViewer;
    case "DOT_CARD":
      return DOTCardViewer;
    case "ECG":
      return ECGDataViewer;
    case "LAB_REPORT":
      return LabReportDataViewer;
    case "URINANALYSIS":
      return UrinalysisDataViewer;
    case "VITALS":
      return VitalsDataViewer;
    case "VISION":
      return VisualAcuityDataViewer;
    case "AUDIOGRAM":
      return AudiogramDataViewer;
    case "BAT":
      return BloodAlcoholDataViewer;
    case "QUESTIONNAIRE":
      return MedicalQuestionnaireDataViewer;
    default:
      return () => (
        <Alert severity="error">
          No data viewer found for this data type: {dataType}
        </Alert>
      );
  }
}
