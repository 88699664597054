import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import { DOCUMENT_GENERATOR_URL, TEMPLATE_ID } from "src/config-global";
import { LoadingButton as BaseLoadingButton } from "@mui/lab";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Paper, Typography, Stack, Chip, Grid, Button } from "@mui/material";
import Iconify from "src/components/iconify/iconify";
import { styled } from "@mui/system";
import { format } from "date-fns";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { debounce } from "lodash";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const LoadingButton = styled(BaseLoadingButton)(
  () => `
max-width: 200px
`
);

class PostVisitCompleted extends React.Component {
  state = {
    ...this.props.visit,
    options: [],
    loadingOptions: false,
    isLoading: true,
  };

  constructor(props) {
    super(props);
    this.debouncedFetchOptions = debounce(this.fetchOptions, 500);
  }

  fetchOptions = async (inputValue) => {
    this.setState({
      loadingOptions: true,
    });
    if (inputValue) {
      try {
        const res = await this.props.apiCallPost(
          "/master-service/masterServiceFts",
          {
            limit: 1000,
            query: inputValue,
            skip: 0,
          }
        );
        this.setState({ options: res });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      this.setState({ options: [] });
    }
    this.setState({
      loadingOptions: true,
    });
  };

  handleInputChange = (event, newInputValue) => {
    this.setState({ inputValue: newInputValue }, () => {
      this.debouncedFetchOptions(newInputValue);
    });
  };

  generatePatientLetter = async ({ download, email }) => {
    this.setState({ loadingPatientLetter: true });
    try {
      const files = await this.props.apiCallPost("/files/groups/search", {
        visitId: this.props.id,
      });
      const data = {
        employee: {
          name: this.state.employee.employeeName,
          number: this.state.employee.employeeNumber,
          dob: this.state.employee.employeeDoB,
          address: this.state.employee.employeeAddress,
          city: this.state.employee.employeeCity,
          state: this.state.employee.employeeState,
          zipCode: this.state.employee.employeePostalCode,
        },
        company: {
          name: this.state.company.companyName,
          division: "",
          location: this.state.company.companyAddress,
        },
        visit: {
          date: "",
        },
        tables: [],
        clearance: {
          clearanceLanguage: "",
          clearanceNote: "",
        },
      };
      for (const bookedService of this.state.bookedServices) {
        // if it has a file, get the details
        const serviceId = bookedService.serviceId;

        // check if there is a file for this
        const matchingFile = files.filter((f) => f.service?._id === serviceId);
        if (matchingFile.length > 0) {
          const file = matchingFile[0];
          const service = await this.props.apiCallPost(
            "/master-service/getMasterServiceById",
            {
              masterServiceId: bookedService.serviceId,
            }
          );
          const table = {
            name: service.serviceName,
            headers: ["Test Name", "Result", "Unit", "Reference Name"],
            data: [],
          };
          for (const structuredData of file.dataStructured) {
            table.data.push([
              structuredData.parameter || "",
              structuredData.result || "",
              structuredData.unit || "",
              structuredData["Reference Range"] || "",
            ]);
          }
          data.tables.push(table);
        }
      }

      if (download) {
        const response = await axios.post(
          DOCUMENT_GENERATOR_URL + "/api/templater/template",
          {
            fileId: TEMPLATE_ID.PATIENT_LETTER,
            data,
            convertTo: "pdf",
          },
          {
            responseType: "blob",
          }
        );

        // Create object URL for the blob
        const url = URL.createObjectURL(response.data);

        // Automatically trigger download
        const a = document.createElement("a");
        a.href = url;
        a.download = `clearance.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }

      if (email) {
        const response = await axios.post(
          DOCUMENT_GENERATOR_URL + "/api/templater/template",
          {
            fileId: TEMPLATE_ID.PATIENT_LETTER,
            data,
            convertTo: "pdf",
          },
          {
            responseType: "blob",
          }
        );
        const file = new File([response.data], "filename.pdf", {
          type: "application/pdf",
        });
        // Now you can include this file in your form data to send to the backend API
        const formData = new FormData();
        formData.append("files", file);

        const to = [];
        if (this.state.employee.employeePersonalEmail) {
          to.push({
            name: "",
            address: this.state.employee.employeePersonalEmail,
          });
        }
        if (this.state.employee.employeeOfficeEmail) {
          to.push({
            name: "",
            address: this.state.employee.employeeOfficeEmail,
          });
        }

        // Add other form fields
        formData.append("to", JSON.stringify(to));
        formData.append("cc", JSON.stringify([]));
        formData.append("html", "");
        formData.append("subject", "Patient Letter");

        // Send the form data to the backend API
        const emailRes = await this.props.apiCallPost(
          "/email/sendEmail",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }
    } catch (error) {
      console.error("could not load patient letter", error);
    }
    this.setState({ loadingPatientLetter: false });
  };

  generateClearance = async ({ download, email }) => {
    this.setState({ loadingClearancePdf: true });
    const payloads = [];
    try {
      const files = await this.props.apiCallPost("/files/groups/search", {
        visitId: this.props.id,
      });
      for (const bookedService of this.state.bookedServices) {
        // if it has a file, get the details
        const serviceId = bookedService.serviceId;

        // check if there is a file for this
        const matchingFile = files.filter((f) => {
          return f.service?._id === serviceId;
        });

        if (matchingFile.length > 0) {
          // get the protocol to see dot/non-dot
          const protocol = await this.props.apiCallPost(
            "/company-protocol/getCompanyProtocolById",
            {
              companyProtocolId: bookedService.parentProtocolId,
            }
          );
          // get service to see type of service
          const service = await this.props.apiCallPost(
            "/master-service/getMasterServiceById",
            {
              masterServiceId: bookedService.serviceId,
            }
          );

          const file = matchingFile[0];
          console.log({ file, protocol, service });
          if (service.serviceType === "DRUG") {
            const data = {
              certificationFor: service.serviceName,
              employee: {
                name: this.state.employee.employeeName,
                number: this.state.employee.employeeNumber,
                dob: this.state.employee.employeeDoB
                  ? format(new Date(this.state.employee.employeeDoB), "PP")
                  : "",
                id: this.state.employee.employeeNumber,
              },
              company: {
                name: this.state.company.companyName,
                division: "",
                location: this.state.company.companyAddress,
              },
              test: {
                collectionDate: "",
                collectionSite: "",
                testingLaboratory: "",
                reasonForTest: "",
                results: bookedService.clearance,
              },
              dot: {
                agency: protocol.regulatoryAuthority,
              },
              isDot: protocol.isDot,
              substancesTested: [],
            };
            for (const structuredData of file.dataStructured) {
              data.substancesTested.push(structuredData.parameter);
            }
            console.log({ data });
            payloads.push({
              fileId: TEMPLATE_ID.CLEARANCE_DRUG,
              data,
              convertTo: "pdf",
            });
          } else if (!protocol.isDot && service.serviceType === "VACCINE") {
            const data = {
              certificationFor: service.serviceName,
              employee: {
                name: this.state.employee.employeeName,
                number: this.state.employee.employeeNumber,
                dob: this.state.employee.employeeDoB
                  ? format(new Date(this.state.employee.employeeDoB), "PP")
                  : "",
              },
              company: {
                name: this.state.company.companyName,
                division: "",
                location: this.state.company.companyAddress,
              },
              table: {
                data: [],
              },
              clearanceLanguage: bookedService.clearance,
            };
            for (const structuredData of file.dataStructured) {
              data.table.data.push([structuredData.parameter]);
            }
            console.log({ data });
            payloads.push({
              fileId: TEMPLATE_ID.CLEARANCE_NON_DOT_VACCINE,
              data,
              convertTo: "pdf",
            });
          }
        }
      }

      if (payloads.length > 0) {
        if (download) {
          const response = await axios.post(
            DOCUMENT_GENERATOR_URL + "/api/templater/template-multiple-files",
            payloads,
            {
              responseType: "blob",
            }
          );

          // Create object URL for the blob
          const url = URL.createObjectURL(response.data);

          // Automatically trigger download
          const a = document.createElement("a");
          a.href = url;
          a.download = `clearance.pdf`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        if (email) {
          const response = await axios.post(
            DOCUMENT_GENERATOR_URL + "/api/templater/template-multiple-files",
            payloads,
            {
              responseType: "blob",
            }
          );
          const file = new File([response.data], "filename.pdf", {
            type: "application/pdf",
          });
          // Now you can include this file in your form data to send to the backend API
          const formData = new FormData();
          formData.append("files", file);

          const to = [];
          if (this.state.employee.employeePersonalEmail) {
            to.push({
              name: "",
              address: this.state.employee.employeePersonalEmail,
            });
          }
          if (this.state.employee.employeeOfficeEmail) {
            to.push({
              name: "",
              address: this.state.employee.employeeOfficeEmail,
            });
          }

          // Add other form fields
          formData.append("to", JSON.stringify(to));
          formData.append("cc", JSON.stringify([]));
          formData.append("html", "");
          formData.append("subject", "Clearance Letter");

          // Send the form data to the backend API
          const emailRes = await this.props.apiCallPost(
            "/email/sendEmail",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        }
      } else {
        cogoToast.error(
          "Please provide at least one protocol clearance to preview clearance."
        );
      }
    } catch (error) {
      console.error("could not generate clearance", error);
    }
    this.setState({ loadingClearancePdf: false });
    return payloads;
  };

  handleAddPostVisitService = async (serviceDetails) => {
    try {
      const postVisitService = {
        serviceDetails,
        bookedAt: Date.now(),
        serviceId: serviceDetails._id,
      };
      await this.props.apiCallPost("/visit/updateVisitScalars", {
        visitId: this.state._id,
        uops: [
          {
            key: "postVisitServices",
            value: [...(this.state.postVisitServices || []), postVisitService],
          },
        ],
      });
      cogoToast.success("Service has been added as a post visit service");
    } catch (error) {
      console.error("could not add post visit service", error);
    }
  };

  render() {
    return (
      <Stack spacing={2}>
        <Paper
          sx={{
            p: 2,
            bgcolor: "bg.neutral",
            boxShadow: 2,
          }}
        >
          <Stack spacing={2}>
            <Stack>
              <Typography variant="subtitle2">Clearance Letter</Typography>
              <Stack spacing={2}>
                <LoadingButton
                  loading={this.state.loadingClearancePdf}
                  onClick={() =>
                    this.generateClearance({ download: true, email: false })
                  }
                  variant="outlined"
                  startIcon={<Iconify icon="mdi:eye" />}
                  size="small"
                  color="info"
                >
                  Preview Clearance Letter
                </LoadingButton>
                <LoadingButton
                  loading={this.state.loadingClearancePdf}
                  onClick={() =>
                    this.generateClearance({ download: false, email: true })
                  }
                  variant="outlined"
                  startIcon={<Iconify icon="ic:baseline-email" />}
                  size="small"
                  color="info"
                >
                  Email Clearance Letter
                </LoadingButton>
              </Stack>
            </Stack>
            <Stack>
              <Typography variant="subtitle2">Patient Letter</Typography>
              <Stack spacing={2}>
                <LoadingButton
                  loading={this.state.loadingPatientLetter}
                  onClick={() =>
                    this.generatePatientLetter({ download: true, email: false })
                  }
                  variant="outlined"
                  startIcon={<Iconify icon="mdi:eye" />}
                  size="small"
                  color="info"
                >
                  Preview Patient Letter
                </LoadingButton>
                <LoadingButton
                  loading={this.state.loadingPatientLetter}
                  onClick={() =>
                    this.generatePatientLetter({ download: false, email: true })
                  }
                  variant="outlined"
                  startIcon={<Iconify icon="ic:baseline-email" />}
                  size="small"
                  color="info"
                >
                  Email Patient Letter
                </LoadingButton>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
        <Paper
          sx={{
            p: 2,
            bgcolor: "bg.neutral",
            boxShadow: 2,
          }}
        >
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Post Visit Services</Typography>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <Autocomplete
                options={this.state.options}
                getOptionLabel={(option) => option.serviceName}
                onInputChange={this.handleInputChange}
                onChange={(_, value) => {
                  console.log({ value });
                  this.handleAddPostVisitService(value);
                }}
                renderInput={(params) => (
                  <TextField
                    onClick={() => {
                      console.log({ params });
                    }}
                    label="Search for a Service"
                    {...params}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
          <TableContainer sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Service Name</TableCell>
                  <TableCell>Added On</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.postVisitServices.map((service, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{service.serviceDetails.serviceName}</TableCell>
                    <TableCell>
                      {format(new Date(service.bookedAt), "Pp")}
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        <Button size="small">Remove</Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Stack>
    );
  }
}

export default WithAPICall(PostVisitCompleted);
