import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Unstable_Grid2";
import ListItemText from "@mui/material/ListItemText";
import FormControlLabel from "@mui/material/FormControlLabel";

import React from "react";
import { MenuItem, TextField, Select } from "@mui/material";
import { WithAPICall } from "../utils/apiUtil";
import cogoToast from "cogo-toast";
import { ALL_LAB_NAMES } from "src/assets/data/lab-names";

class CompanySettings extends React.Component {
  state = {
    hasDemographicIntegration: false,
  };
  componentDidMount() {
    this.setState({ ...this.props.data });
  }
  SETTINGS = [
    {
      subheader: "Service Level Notes",
      caption: "Set Service Level Parameters",
      items: [
        {
          id: "slaNumOutreachAttempts",
          label: "Outreach Attempts - Scheduling",
          isSwitch: false,
        },
        {
          id: "slaInvoiceBy",
          label: "Billing Turn Around Time (Days)",
          isSwitch: false,
        },
        {
          id: "slaDrugAlcoholTAT",
          label: "Drug & Alcohol Turn Around Time (Days)",
          isSwitch: false,
        },
        {
          id: "slaMedExamTAT",
          label: "Medical Exam Turn Around Time (Days)",
          isSwitch: false,
        },
        {
          id: "slaRequiresConsolidatedInvoice",
          label: "Requires Consolidated Invoice",
          isSwitch: true,
        },
        {
          id: "defaultLab",
          label: "Default Lab",
          isSwitch: false,
          isLabSelector: true,
        },
      ],
    },
    {
      subheader: "Company Specific Toggles",
      caption: "Toggle the switches to enable or disable settings",
      items: [
        {
          id: "hasDemographicIntegration",
          label: "Demographic integrations",
          isSwitch: true,
        },
        {
          id: "hasCaptureMedMarijuanaCard",
          label: "Capture medical marijuana cards",
          isSwitch: true,
        },
        {
          id: "hasDOTExamNonReviewModel",
          label: "DOT Exam Non-Review Model",
          isSwitch: true,
        },
        {
          id: "hasIssueMedClearanceWODrugClearance",
          label: "Can issue medical clearance without drug clearance",
          isSwitch: true,
        },
        {
          id: "hasChainOfCustodyAvailableOnPortal",
          label: "Chain of custody available on portal",
          isSwitch: true,
        },
        {
          id: "hasDilutePolicy",
          label: "Has a dilute policy",
          isSwitch: true,
        },

        {
          id: "hasPositiveDrugScreensOnWeb",
          label: "Can see positive drug screens on portal",
          isSwitch: true,
        },
        {
          id: "isConsortiumMember",
          label: "Is a consortium member",
          isSwitch: true,
          nonEditable: true,
        },
        {
          id: "hasPersonalIdentifierOnClearance",
          label: "Show Personal Identifiers on Clearance",
          isSwitch: true,
        },
        {
          id: "disableAutomaticEmails",
          label:
            "Disable emails from being sent automatically (such as Authorizations to EE, Provider, etc.)",
          isSwitch: true,
        },
      ],
    },
    {
      subheader: "Personal Identifiers On Clearance",
      caption: "Select applicable personal identifiers on clearance",
      items: [
        {
          id: "hasSSNOnClearance",
          label: "Show Social Security Number on Clearance",
          isSwitch: true,
        },
        {
          id: "hasEmployeeNumberOnClearance",
          label: "Show Employee Number on Clearance",
          isSwitch: true,
        },
        {
          id: "hasDOBOnClearance",
          label: "Show Date of Birth on Clearance",
          isSwitch: true,
        },
        {
          id: "hasOrgStructureOnClearance",
          label: "Show Organisational Structure on Clearance",
          isSwitch: true,
        },
      ],
    },
  ];
  updateScalarValue = async (key, value) => {
    if (value === null || value === undefined) {
      return;
    }
    if (this.props.data[key] === value) {
      return;
    }
    try {
      await this.props.apiCallPost("/company/updateScalarValue", {
        companyId: this.props.data._id,
        key: key,
        value: value,
      });
    } catch (err) {
      cogoToast.error("Error Updating Employer Data");
      console.log(err);
    }
  };
  render() {
    return (
      <div>
        <Stack component={Card} spacing={3} sx={{ p: 3 }}>
          {this.SETTINGS.map((setting, index) => {
            if (index === 2 && !this.state.hasPersonalIdentifierOnClearance)
              return null;
            return (
              <Grid key={setting.subheader} container spacing={3}>
                <Grid xs={12} md={4}>
                  <ListItemText
                    primary={setting.subheader}
                    secondary={setting.caption}
                    primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
                    secondaryTypographyProps={{ component: "span" }}
                  />
                </Grid>
                <Grid xs={12} md={8}>
                  <Stack
                    spacing={1}
                    sx={{
                      p: 3,
                      borderRadius: 2,
                      bgcolor: "background.neutral",
                    }}
                  >
                    <>
                      {setting.items.map((item) => {
                        if (item.isLabSelector) {
                          return (
                            <FormControlLabel
                              label="Default Lab"
                              labelPlacement="start"
                              control={
                                <Select
                                  value={this.state.defaultLab}
                                  sx={{
                                    minWidth: "200px",
                                  }}
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        defaultLab: e.target.value,
                                      },
                                      () => {
                                        this.updateScalarValue(
                                          "defaultLab",
                                          e.target.value
                                        );
                                      }
                                    );
                                  }}
                                >
                                  {ALL_LAB_NAMES.map((ln) => (
                                    <MenuItem key={ln.key} value={ln.key}>
                                      {ln.value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              }
                              sx={{
                                m: 0,
                                width: 1,
                                justifyContent: "space-between",
                              }}
                            />
                          );
                        }
                        return (
                          <FormControlLabel
                            key={item.id}
                            label={item.label}
                            labelPlacement="start"
                            control={
                              item.isSwitch ? (
                                <Switch
                                  disabled={item.nonEditable}
                                  checked={this.state[item.id]}
                                  onChange={() => {
                                    this.setState(
                                      {
                                        [item.id]: !this.state[item.id],
                                      },
                                      () => {
                                        this.updateScalarValue(
                                          item.id,
                                          this.state[item.id]
                                        );
                                      }
                                    );
                                  }}
                                />
                              ) : (
                                <TextField
                                  value={this.state[item.id]}
                                  onChange={(e) => {
                                    this.setState({
                                      [item.id]: e.target.value,
                                    });
                                  }}
                                  onBlur={() => {
                                    this.updateScalarValue(
                                      item.id,
                                      this.state[item.id]
                                    );
                                  }}
                                />
                              )
                            }
                            sx={{
                              m: 0,
                              width: 1,
                              justifyContent: "space-between",
                            }}
                          />
                        );
                      })}
                    </>
                  </Stack>
                </Grid>
              </Grid>
            );
          })}
        </Stack>
      </div>
    );
  }
}
export default WithAPICall(CompanySettings);
