import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import { Alert, Card, LinearProgress, Typography } from "@mui/material";
import { View500 } from "src/sections/error";
import { Stack } from "@mui/system";
import cogoToast from "cogo-toast";
import _ from "lodash";
import VisitProviderInfoBox from "./VisitProviderInfoBox";
import Grid from "@mui/system/Unstable_Grid/Grid";
import VisitApptTimePicker from "./VisitApptTimePicker";
import { nanoid } from "nanoid";

class VisitAppointmentTime extends React.Component {
  state = {
    currentUser: null,
    isLoading: true,
    isError: false,
    clinicPSLs: [],
    serviceDetails: [],
  };
  componentDidMount() {
    this.getCurrentUser();
  }
  getCurrentUser = async () => {
    try {
      let res = await this.props.apiCallPost("/user/getCurrentUser");
      this.setState(
        {
          ...this.props.visit,
          currentUser: res,
        },
        () => {
          this.getServiceDetails();
        }
      );
    } catch (err) {
      cogoToast.error("Error Loading User Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  getServiceDetails = async () => {
    this.setState({ isLoading: true });
    let services = _.uniq(
      this.props.visit.bookedServices.map((x) => x.serviceId)
    );
    try {
      let res = await this.props.apiCallPost(
        "/master-service/getMasterServiceById",
        {
          masterServiceId: services,
        }
      );
      this.setState(
        {
          isError: false,
          isLoading: false,
          serviceDetails: res,
        },
        () => {
          this.groupServices();
        }
      );
    } catch (err) {
      console.log(err);
      this.setState({
        isError: true,
        isLoading: false,
      });
    }
    this.setState({ isLoading: false });
  };
  groupServices = () => {
    let internalServices = [];
    let questionnaireServices = [];
    let labServices = [];
    let clinicServices = [];
    let otherServices = [];
    let serviceDetails = this.state.serviceDetails;
    for (var i = 0; i < serviceDetails.length; i++) {
      let s = serviceDetails[i];
      if (s.serviceType === "QUESTIONNAIRE") {
        questionnaireServices.push(s);
        continue;
      }
      if (s.serviceType === "ADMINISTRATIVE") {
        internalServices.push(s);
        continue;
      }
      if (s.isHandledByWorkCare) {
        internalServices.push(s);
        continue;
      }
      if (s.serviceRequiresProviderType === "Lab") {
        labServices.push(s);
        continue;
      }
      if (s.serviceRequiresProviderType === "Clinic") {
        clinicServices.push(s);
        continue;
      }
      otherServices.push(s);
    }
    this.setState({
      internalServices,
      questionnaireServices,
      labServices,
      clinicServices,
      otherServices,
      isLoading: false,
    });
  };
  renderClinics = () => {
    let providerGroupBy = _.groupBy(this.props.visit.clinicPSLs, "providerId");
    let keys = Object.keys(providerGroupBy);

    return keys.map((providerId) => {
      let currentObj = providerGroupBy[providerId];
      let sampleObj = currentObj[0];
      let name = sampleObj.providerName;
      return (
        <Stack spacing={2}>
          <Card
            sx={{
              p: 2,
              rounded: 2,
              boxShadow: 3,
            }}
          >
            <Grid container spacing={3}>
              <Grid xs={12} md={4}>
                <Stack spacing={2}>
                  <Typography variant="h6">{name}</Typography>
                  {sampleObj.providerObj.providerReadableTimezone && (
                    <Alert severity="info">
                      All times for this provider are in{" "}
                      {sampleObj.providerObj.providerReadableTimezone}
                    </Alert>
                  )}
                  {this.renderProviderSchedulingInformation(providerId)}
                </Stack>
              </Grid>
              <Grid
                xs={12}
                md={8}
                sx={{
                  bgcolor: "background.neutral",
                }}
              >
                <VisitProviderInfoBox providerId={providerId} />{" "}
              </Grid>
            </Grid>
          </Card>
        </Stack>
      );
    });
  };
  renderProviderSchedulingInformation = (providerId) => {
    let providerGroupBy = _.groupBy(this.props.visit.clinicPSLs, "providerId");
    let currentObj = providerGroupBy[providerId];

    return currentObj.map((each, idx) => {
      if (!this.state.serviceDetails.length) return null;
      let pslName = each.pslName;
      let masterServices = each.masterServices;
      let masterServiceNames = masterServices
        .map((x) => {
          let service = this.state.serviceDetails.find((y) => y._id === x);
          return service;
        })
        .map((y) => y?.serviceName);
      return (
        <Stack spacing={2}>
          <Typography variant="overline">{pslName}</Typography>
          <Typography variant="caption">
            {masterServiceNames.join(", ")}
          </Typography>
          <VisitApptTimePicker
            idx={idx}
            visit={this.props.visit}
            clinicPSL={each}
            onSet={(key, val) => {
              this.props.onSet(key, val, each._id, this.state.currentUser._id);
            }}
            setAll={(date, time) => {
              this.props.setAll(
                date,
                time,
                each._id,
                this.state.currentUser._id
              );
            }}
          />
        </Stack>
      );
    });
  };
  renderEmployeePreference = () => {
    let empPrefElem = null;
    if (this.props.visit.employeeSchedulingPreference) {
      empPrefElem = (
        <Typography variant="caption">
          Employee Scheduling Preference:{" "}
          {this.props.visit.employeeSchedulingPreference}
        </Typography>
      );
    }
    return empPrefElem;
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    if (
      (this.state.clinicServices?.length > 0 ||
        this.state.clinicServices?.length > 0) &&
      !this.state.clinicFrozen
    ) {
      return (
        <Alert severity="warning">
          You can only schedule once the providers have been chosen.
        </Alert>
      );
    }
    if (
      this.state.clinicServices?.length === 0 &&
      this.state.labServices?.length === 0
    ) {
      return (
        <Alert severity="warning">
          You do not need to schedule a time for this visit.
        </Alert>
      );
    }
    return (
      <Stack spacing={2}>
        {this.renderEmployeePreference()}
        {this.renderClinics()}
      </Stack>
    );
  }
}

export default WithAPICall(VisitAppointmentTime);
